import { Epic } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { statisticsSetApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadValueAtRisks: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("valueAtRisk", "valueAtRiskLoadAll"),
        mergeMap(() => statisticsSetApi.getLastStatisticsPortfolioLevelAsync()),
        map(ActionFactories.valueAtRisk.valueAtRiskLoadedAll));

export const loadValueAtRiskChildren: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("valueAtRisk", "valueAtRiskLoadChildren"),
        mergeMap(portfolioId => statisticsSetApi.getLastStatisticsConstituentLevelAsync(({ portfolioId }))),
        map(ActionFactories.valueAtRisk.valueAtRiskLoadedChildren));

export const loadValueAtRiskBacktesting: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("valueAtRisk", "valueAtRiskLoadBacktesting"),
        mergeMap(portfolioId => statisticsSetApi.getStatisticsBacktestingAsync({ portfolioId })),
        map(ActionFactories.valueAtRisk.valueAtRiskLoadedBacktesting));

// FIXME Route is currently disabled because the component would need to be migrated
// export const onOpenScreenValueAtRiskBacktesting: Epic<IAnyAction>
//     = action$ => action$.pipe(
//         changedNavigation(({ screenKey }) => screenKey, ({ matchingSections }) => matchingSections?.detail?.id),
//         filterRoute("ValueAtRisk"),
//         map(({ matchingSections }) => tryParseNumber(matchingSections?.detail?.id) ?? 0),
//         map(portfolioId => ActionFactories.valueAtRisk.valueAtRiskLoadBacktesting(portfolioId as number)));