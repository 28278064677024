import { Box, Button, Card, CardActions, CardContent, CardMedia, LinearProgress, Typography, makeStyles } from "@material-ui/core";
import { Formik, FormikProps } from "formik";
import { ITenantSummaryModel } from "proxy/apiProxy";
import FieldBox from "tools/components/FieldBox";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps } from "tools/lib/utility";
import welcomePicture from "images/LogoFundProcess2021.svg"
import SendIcon from '@material-ui/icons/Send';
import ReadOnly from "tools/fieldComponents/ReadOnly";
const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: theme.shadows[24],
        width: 650,
        alignSelf: 'center'
    },
    media: {
        width: 650,
        height: 150,
        padding: 20
        // paddingTop: "10%" // 16:10
    },
    actions: {
        display: "flex",
    }
}));
export interface IFirstTenantProps {
    onValidate: (tenant: ITenantSummaryModel) => void;
    saving?: boolean;
}
export default function FirstTenant({ onValidate, saving = false }: IFirstTenantProps) {
    const classes = useStyles();
    const initialValues: ITenantSummaryModel = {
        defaultCurrencyCode: "EUR",
        key: "",
        name: ""
    };
    return <Formik onSubmit={onValidate} initialValues={initialValues} enableReinitialize={true} validateOnMount={true}>{renderForm}</Formik>
    function renderForm({ values: formValues, dirty, isValid, submitForm }: FormikProps<ITenantSummaryModel>) {
        return <Card className={classes.card}>
            <CardMedia className={classes.media} image={welcomePicture} />
            <LinearProgress hidden={!saving} variant="query" />
            <CardContent>
                <Typography variant="h4">
                    Create the first tenant.
                </Typography>
                <ReadOnly value={saving}>
                    <FieldBox display="flex" flexDirection="column">
                        <FormTextField name={oProps<ITenantSummaryModel>().path("key")} label="Code/Key" required={true} maxLength={50} />
                        <FormTextField name={oProps<ITenantSummaryModel>().path("name")} label="Name" required={true} maxLength={250} />
                        <FormTextField name={oProps<ITenantSummaryModel>().path("defaultCurrencyCode")} label="Default Currency Code" required={true} maxLength={3} />
                    </FieldBox>
                </ReadOnly>
            </CardContent>
            <CardActions className={classes.actions} disableSpacing={true}>
                <Box flexGrow="1" />
                <Button disabled={saving} onClick={submitForm} variant="contained" size="large" color="primary" endIcon={<SendIcon />} >Create</Button>
            </CardActions>
        </Card>
    }
}
