import { EntityModel, EntitySummaryModel, IEntityTypeModel } from "proxy/apiProxy";
// import { getEnumLabels } from "tools/lib/utility";

// const allEntityTypes = {
//     ...entityTypes,
//     [IEntityTypeModel.Cash]: { type: IEntityTypeModel.Cash, label: "Cash", parentKey: "Regular" }
// }
// const entityTypes = getEnumLabels(IEntityTypeModel);
export function getEntityTypeLabel(type: (EntitySummaryModel | EntityModel)["type"] | IEntityTypeModel): string;
export function getEntityTypeLabel(type: (EntitySummaryModel | EntityModel)["type"] | IEntityTypeModel | null): string | null;
export function getEntityTypeLabel(type: (EntitySummaryModel | EntityModel)["type"] | IEntityTypeModel | undefined): string | undefined;
export function getEntityTypeLabel(type: (EntitySummaryModel | EntityModel)["type"] | IEntityTypeModel | undefined | null): string | null | undefined;
export function getEntityTypeLabel(type: (EntitySummaryModel | EntityModel)["type"] | IEntityTypeModel | undefined | null): string | null | undefined {
    if (typeof (type) === "undefined") {
        return undefined;
    }
    if (!type) {
        return null;
    }
    switch (type) {
        case IEntityTypeModel.Company:
        case "CompanyModel":
        case "CompanySummaryModel": return "Company";
        case IEntityTypeModel.Person:
        case "PersonModel":
        case "PersonSummaryModel": return "Person";
        case IEntityTypeModel.Sicav:
        case "SicavModel":
        case "SicavSummaryModel": return "Sicav";
        case IEntityTypeModel.EntityGroup:
        case "EntityGroupModel":
        case "EntityGroupSummaryModel": return "Entity Group";
    }
}
