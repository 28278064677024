import { IClassificationTypeModel } from "proxy/apiProxy";
import { base64toBlob, oProps } from "tools/lib/utility";
import FormDropZoneField from "tools/fieldComponents/FormDropZoneField";
import { useField } from "formik";
import saveAs from "file-saver";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import ClassificationsData from "components/global/ClassificationsData";
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";
import { JsonEditor } from 'json-edit-react';
import { IDocumentData } from "../slice";
import { Grid } from "@material-ui/core";

export interface IDocumentDataProps {
    classificationTypes: IClassificationTypeModel[];
    onFileLoad: () => void;
}

export default function DocumentData({
    classificationTypes,
    onFileLoad
}: IDocumentDataProps) {
    const [{ value: file }] = useField<IDocumentData["file"]>(oProps<IDocumentData>().path("file"))
    const [{ value: fileName }] = useField<IDocumentData["document"]["name"]>(oProps<IDocumentData>().path("document", "name"))

    const [{ value: metadata }, { }, { setValue }] = useField<IDocumentData["document"]["metadata"]>(oProps<IDocumentData>().path("document", "metadata"));

    const handleViewFile = () => {
        if (file) {
            const blob = base64toBlob(file.content, file.mimeType);
            saveAs(blob, file.fileName);
        }
        else if (fileName) onFileLoad();
    }

    return <Grid container={true} spacing={4} style={{ padding: "10px" }}>
        <Grid item={true} md={3}>
            <FormTextField name={oProps<IDocumentData>().path("document", "alias")} label="Alias" required={true} maxLength={250} />
        </Grid>
        <Grid item={true} md={5}>
            <FormDatePickerField name={oProps<IDocumentData>().path("document", "startDate")} label="Start date" />
        </Grid>
        <Grid item={true} md={4}>
            <FormDatePickerField name={oProps<IDocumentData>().path("document", "expirationDate")} label="Expiration date" />
        </Grid>

        <Grid item={true} md={8}>
            <FormMultiCulturedTextField name={oProps<IDocumentData>().path("document", "description")} label="Description" />
        </Grid>

        <Grid item={true} md={8}>
            <ClassificationsData
                panelTitle="Classification"
                fieldName={oProps<IDocumentData>().path("document", "classifications")}
                classificationTypes={classificationTypes} />
        </Grid>

        <Grid item={true} spacing={10} container={true}>
            <Grid item={true} md={5}>
                <FormDropZoneField
                    name={oProps<IDocumentData>().path("document", "name")}
                    contentName={oProps<IDocumentData>().path("file")}
                    onClick={handleViewFile}
                    label="Drop the document here"
                    emptyValue={null}
                    minLength={1}
                    required={true}
                />
            </Grid>
            <Grid item={true} md={5}>
                <JsonEditor
                    rootName="Metadata"
                    data={metadata}
                    setData={setValue}
                    showErrorMessages={true}
                    showArrayIndices={false}
                    showCollectionCount={true}
                    showStringQuotes={true}
                    collapse={true}
                />
            </Grid>
        </Grid>
    </Grid >
}
