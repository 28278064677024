import * as React from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Grid, IconButton } from "@material-ui/core";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import { IBatchSummaryModel } from "proxy/apiProxy";
import { SummaryField } from "../global/SummaryField";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useField } from "formik";
import CloseIcon from '@material-ui/icons/Close';
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { ScreenLink } from "tools/routing/ScreenLink";

export interface IBatchSummaryProps {
    batch: IBatchSummaryModel;
    label?: string;
    name: string;
}
export function BatchSummary({ name: fieldName, batch: { id: batchId, code: internalCode, name }, label }: IBatchSummaryProps) {
    const [, , { setValue }] = useField<number | undefined>(fieldName);
    const [expanded, setExpanded] = React.useState(false);
    const handleSwitchExpand = () => setExpanded(!expanded);
    const handleSetUndefined = () => setValue(undefined);
    return <ReadOnlyContext.Consumer>{readOnly => <Card >
        <CardHeader
            action={<>
                {!readOnly && <IconButton onClick={handleSetUndefined}><CloseIcon /></IconButton>}
                <IconButton onClick={handleSwitchExpand}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </>}
            title={name}
            subheader={label} />
        <Collapse in={expanded} timeout="auto">
            <CardContent>
                <Grid container={true} spacing={2}>
                    <Grid xs={6} item={true}>
                        <SummaryField label="Code" value={internalCode} />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    component={ScreenLink}
                    destination={{ type: "Screen", key: "BatchDefinition", label: "Open Batch", params: { id: String(batchId) } }}
                    size="small" >
                    <OpenInBrowser />Open Batch
                </Button>
            </CardActions>
        </Collapse>
    </Card>}
    </ReadOnlyContext.Consumer>
}

export interface IBatchSummaryReadOnlyProps {
    batch: IBatchSummaryModel;
    label?: string;
}
export function BatchSummaryReadOnly({ batch: { id: batchId, code: internalCode, name }, label }: IBatchSummaryReadOnlyProps) {
    const [expanded, setExpanded] = React.useState(false);
    const handleSwitchExpand = () => setExpanded(!expanded);
    return <Card >
        <CardHeader
            action={<IconButton onClick={handleSwitchExpand}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>}
            title={name}
            subheader={label} />
        <Collapse in={expanded} timeout="auto">
            <CardContent>
                <Grid container={true} spacing={2}>
                    <Grid xs={6} item={true}>
                        <SummaryField label="Code" value={internalCode} />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    component={ScreenLink}
                    destination={{ type: "Screen", key: "BatchDefinition", label: "Open Batch", params: { id: String(batchId) } }}
                    size="small"><OpenInBrowser />
                    Open Batch
                </Button>
            </CardActions>
        </Collapse>
    </Card>
}
