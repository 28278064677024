import {
    IDailyDataSearchPortfolioTradesParameters,
    IOperationTypeModel,
    isDerivativeSummaryModel,
    isSecurityInstrumentSummaryModel,
    PortfolioTradeModel
} from "proxy/apiProxy";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { Formik, FormikHelpers, FormikProps } from "formik";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { IErrors, oProps } from "tools/lib/utility";
import FieldBox from "tools/components/FieldBox";
import { Typography } from "@material-ui/core";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import ManagedPortfolioMultiSelectField from "components/searchers/ManagedPortfolioMultiSelectField";
import { getEntityName } from "tools/lib/modelUtils";
import { getPortfolioTypeLabel } from "features/ManagedPortfolio/getPortfolioTypeLabel";
import { getPortfolioTradeTypeLabel } from "./getPortfolioTradeTypeLabel";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";

export const portfolioTradesScreen = {
    route: "/tradeblotter/trades",
    component: Trades,
    label: "Portfolio Trades"
}

function Trades() {
    const { tradeSearch, tradeAddPortfoliosToDictionary } = useReduxActions("portfolioTrades");
    const { tradesLoading, trades = [], dictionaries } = useReduxSelections("portfolioTrades");
    const { portfolios, securities, entities } = dictionaries;
    const navigate = useScreenNavigate();
    const { referenceCurrencies = {} } = useReduxSelections("reference");

    if (!referenceCurrencies) {
        return <Typography>Loading...</Typography>
    }
    const columns: IColumnDefinition[] = [
        { name: "Type", title: "Type", getCellValue: ({ type }: PortfolioTradeModel) => getPortfolioTradeTypeLabel(type), aggregationType: "count" },
        { name: "PortfolioName", title: "Prtf Name", getCellValue: ({ portfolioId }: PortfolioTradeModel) => portfolios[portfolioId].name, aggregationType: "count", filteringEnabled: true },
        { name: "PortfolioCode", title: "Prtf Code", getCellValue: ({ portfolioId }: PortfolioTradeModel) => portfolios[portfolioId].internalCode, filteringEnabled: true },
        { name: "PortfolioType", title: "Prtf Type", getCellValue: ({ portfolioId }: PortfolioTradeModel) => getPortfolioTypeLabel(portfolios[portfolioId].type), filteringEnabled: true },
        { name: "Date", title: "Date", getCellValue: ({ tradeDate }: PortfolioTradeModel) => tradeDate, columnType: "date" },
        { name: "TargetSecurityCode", title: "Sec Code", getCellValue: (trade: PortfolioTradeModel) => trade.type === "SecurityTradeModel" ? securities[trade.securityId].internalCode : undefined, filteringEnabled: true },
        { name: "TargetSecurityName", title: "Sec Name", getCellValue: (trade: PortfolioTradeModel) => trade.type === "SecurityTradeModel" ? securities[trade.securityId].name : undefined, filteringEnabled: true },
        { name: "TargetSecurityType", title: "Sec Type", getCellValue: (trade: PortfolioTradeModel) => trade.type === "SecurityTradeModel" ? getSecurityTypeLabel(securities[trade.securityId].type) : undefined },
        {
            name: "TargetSecurityMat", title: "Sec Mat", getCellValue: (trade: PortfolioTradeModel) => {
                if (trade.type === "FxTradeModel") {
                    return;
                }
                const secu = securities[trade.securityId];
                return (secu.type === "BondSummaryModel" || isDerivativeSummaryModel(secu)) ? secu.maturityDate : undefined;
            }, columnType: "date"
        },
        {
            name: "TargetSecurityCurrency", title: "Sec Ccy", getCellValue: (trade: PortfolioTradeModel) => {
                if (trade.type === "FxTradeModel") {
                    return;
                }
                const currencyId = securities[trade.securityId].currencyId;
                if (!currencyId) {
                    return
                }
                return referenceCurrencies[currencyId]?.isoCode;
            }
        },
        {
            name: "PortfolioCurrency", title: "Prtf Ccy", getCellValue: (trade: PortfolioTradeModel) => {
                if (trade.type === "FxTradeModel") {
                    return;
                }
                const currencyId = portfolios[trade.portfolioId].currencyId;
                if (!currencyId) {
                    return
                }
                return referenceCurrencies[currencyId]?.isoCode;
            }
        },
        {
            name: "TargetSecurityIsin", title: "Isin", getCellValue: (trade: PortfolioTradeModel) => {
                if (trade.type === "FxTradeModel") {
                    return;
                }
                const secu = securities[trade.securityId];
                return isSecurityInstrumentSummaryModel(secu) ? secu.isin : undefined
            }, filteringEnabled: true
        },
        {
            name: "Quantity", title: "Qty", getCellValue: (trade: PortfolioTradeModel) => {
                if (trade.type === "FxTradeModel") {
                    return;
                }
                const { quantity, buySell } = trade;
                return typeof (quantity) === "number" ? (buySell === IOperationTypeModel.Buy ? quantity : -quantity) : undefined;
            },
            columnType: "decimal"
        },
        {
            name: "MarketValueInPortfolioCurrency", title: "Amount Prtf Cur", getCellValue: (trade: PortfolioTradeModel) => {
                if (trade.type === "FxTradeModel") {
                    return;
                }
                const { amountInPtfCcy, buySell } = trade;
                return typeof (amountInPtfCcy) === "number" ? (buySell === IOperationTypeModel.Buy ? amountInPtfCcy : -amountInPtfCcy) : undefined;
            },
            columnType: "decimal",
            aggregationType: "sum"
        },










        {
            name: "SoldAmount", title: "Sold Amnt", getCellValue: (trade: PortfolioTradeModel) => {
                if (trade.type === "SecurityTradeModel") {
                    return;
                }
                return trade.soldAmount;
            },
            columnType: "decimal",
            aggregationType: "sum"
        },
        {
            name: "SoldCurrency", title: "Sold Ccy", getCellValue: (trade: PortfolioTradeModel) => {
                if (trade.type === "SecurityTradeModel") {
                    return;
                }
                return referenceCurrencies[trade.soldCurrencyId]?.isoCode;
            }
        },
        {
            name: "BuyAmount", title: "Buy Amnt", getCellValue: (trade: PortfolioTradeModel) => {
                if (trade.type === "SecurityTradeModel") {
                    return;
                }
                return trade.purchasedAmount;
            },
            columnType: "decimal",
            aggregationType: "sum"
        },
        {
            name: "BuyCurrency", title: "Buy Ccy", getCellValue: (trade: PortfolioTradeModel) => {
                if (trade.type === "SecurityTradeModel") {
                    return;
                }
                return referenceCurrencies[trade.purchasedCurrencyId]?.isoCode;
            }
        },













        { name: "PlacedBy", title: "Placed By", getCellValue: ({ placedById }: PortfolioTradeModel) => getEntityName(entities[placedById]) },
        {
            name: "BuySell", title: "Buy/Sell", getCellValue: (trade: PortfolioTradeModel) => {
                if (trade.type === "FxTradeModel") {
                    return;
                }
                const { buySell } = trade;
                return buySell;
            }, filteringEnabled: true
        },
        { name: "Status", title: "Status", getCellValue: ({ status }: PortfolioTradeModel) => status },
    ];
    const state: IGridState = {
        "PortfolioName": { width: 280 },
        "PortfolioCode": { width: 150, hidden: true },
        "PortfolioCurrency": { width: 150, hidden: true },
        "PortfolioType": { width: 120, hidden: true },
        "Date": { width: 120 },
        "TargetSecurityCode": { width: 150, hidden: true },
        "TargetSecurityName": { width: 280 },
        "TargetSecurityType": { width: 120, hidden: true },
        "TargetSecurityIssuer": { width: 200, hidden: true },
        "TargetSecurityMat": { width: 100, hidden: true },
        "TargetSecurityCurrency": { width: 130 },
        "TargetSecurityIsin": { width: 150 },
        "Quantity": { width: 180 },
        "MarketValueInPortfolioCurrency": { width: 180 },
        "SoldAmount": { width: 180 },
        "SoldCurrency": { width: 150 },
        "BuyAmount": { width: 180 },
        "BuyCurrency": { width: 150 },
        "PlacedBy": { width: 200, hidden: true }
    };

    const getRowKey = (row: PortfolioTradeModel) => row.id;

    const handleSubmit = (model: IDailyDataSearchPortfolioTradesParameters, { setSubmitting }: FormikHelpers<IDailyDataSearchPortfolioTradesParameters>) => {
        tradeSearch(model);
        setSubmitting(false);
    }
    const handleValidate = (values: IDailyDataSearchPortfolioTradesParameters) => {
        const errors: IErrors<IDailyDataSearchPortfolioTradesParameters> = {};
        if (!values.lastOnly) {
            if (!values.from) {
                errors.from = "From date is required";
            }
            if (values.to && values.from && values.from > values.to) {
                errors.to = "Must be greater than the start of period";
            }
        }
        return errors;
    }
    const handleRowSelect = (trade: PortfolioTradeModel) => {
        if (trade.type === "FxTradeModel") {
            return;
        }
        const securityId = trade.securityId;
        const { type } = securities[securityId];
        switch (type) {
            case "LoanSummaryModel":
                navigate("Loan", { id: securityId });
                break;
            case "CashSummaryModel":
                navigate("BankAccount", { id: securityId });
                break;
            default:
                navigate("Security", { id: securityId });
                break;
        }
    }

    const monthToDate = (function () {
        let date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    })();
    return <Formik onSubmit={handleSubmit} validate={handleValidate} initialValues={{ lastOnly: true, from: monthToDate } as IDailyDataSearchPortfolioTradesParameters} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;

    function renderForm({ isValid, submitForm, values: { lastOnly } }: FormikProps<IDailyDataSearchPortfolioTradesParameters>) {
        return (
            <SearchPanel
                isQuerying={tradesLoading}
                onSearchClick={submitForm}
                searchDisabled={!isValid}
                title="Trades"
                subTitle="Search by portfolio and time period"
                renderSearch={(<FieldBox display="flex" flexDirection="column">
                    <FieldBox display="flex" flexGrow="1">
                        <ManagedPortfolioMultiSelectField
                            name={oProps<IDailyDataSearchPortfolioTradesParameters>().path("portfolioIds")}
                            label="Portfolios"
                            dictionaries={dictionaries}
                            onSelected={tradeAddPortfoliosToDictionary} />
                    </FieldBox>
                    <FieldBox display="flex" flexDirection="row">
                        <FormCheckBoxField name={oProps<IDailyDataSearchPortfolioTradesParameters>().path("lastOnly")} label="Only Last Trade Dates" />
                        {(!lastOnly) && <FormDatePickerField name={oProps<IDailyDataSearchPortfolioTradesParameters>().path("from")} label="From" />}
                        {(!lastOnly) && <FormDatePickerField name={oProps<IDailyDataSearchPortfolioTradesParameters>().path("to")} label="To" />}
                    </FieldBox>
                </FieldBox>
                )}>
                <ExtendedGrid
                    getRowId={getRowKey}
                    columns={columns}
                    rows={trades}
                    onRowClick={handleRowSelect}
                    initialState={state}
                    userCanGroup={true}

                    defaultExportFileName="trades.xlsx" />
            </SearchPanel>
        );
    }
}
