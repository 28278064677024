import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFeatureModel, ILoanSummaryModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useCallback, useEffect } from "react";

export const loansScreen = {
    route: "/loans" as const,
    component: Loans,
    label: "Loans",
}

function Loans() {
    const { loans, loanAllLoading, dictionaries: { entities } } = useReduxSelections("loan");
    const { loanLoadAll } = useReduxActions("loan");
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    const navigate = useScreenNavigate();
    useEffect(() => {
        loanLoadAll()
    }, [loanLoadAll]);

    const handleRowClick = useCallback(({ id }: ILoanSummaryModel) => navigate("Loan", { id }), [navigate])
    const handleAddNewClick = useCallback(() => navigate("Loan", { id: 0 }), [navigate])

    const columns: IColumnDefinition[] = [
        { name: "InternalCode", title: "Internal Code", getCellValue: (row) => row.internalCode, filteringEnabled: true },
        { name: "Name", title: "Name", getCellValue: ({ name }: ILoanSummaryModel) => name, filteringEnabled: true },
        { name: "ShortName", title: "Short Name", getCellValue: ({ shortName }: ILoanSummaryModel) => shortName, filteringEnabled: true },
        { name: "Borrower", title: "Borrower", getCellValue: ({ borrowerId = 0 }: ILoanSummaryModel) => getEntityName(entities[borrowerId]), filteringEnabled: true },
        { name: "BorrowerCode", title: "Borrower Code", getCellValue: ({ borrowerId = 0 }: ILoanSummaryModel) => entities[borrowerId]?.internalCode, filteringEnabled: true },
        { name: "Lender", title: "Lender", getCellValue: ({ lenderId = 0 }: ILoanSummaryModel) => getEntityName(entities[lenderId]), filteringEnabled: true },
        { name: "LenderCode", title: "Lender Code", getCellValue: ({ lenderId = 0 }: ILoanSummaryModel) => entities[lenderId]?.internalCode, filteringEnabled: true },
        { name: "Currency", title: "Currency", getCellValue: ({ currencyId = 0 }: ILoanSummaryModel) => referenceCurrencies[currencyId]?.isoCode },
        { name: "Nominal", title: "Nominal", getCellValue: ({ nominal }: ILoanSummaryModel) => nominal, filteringEnabled: true, columnType: "preciseDecimal" },
        { name: "InterestRate", title: "Interest Rate", getCellValue: ({ interest }: ILoanSummaryModel) => interest, filteringEnabled: true, columnType: "preciseDecimal" },
        { name: "Date", title: "Date", getCellValue: ({ date }: ILoanSummaryModel) => date, filteringEnabled: true, columnType: "date" },
        { name: "MaturityDate", title: "Maturity Date", getCellValue: ({ maturityDate }: ILoanSummaryModel) => maturityDate, filteringEnabled: true, columnType: "date" },
    ];

    const state: IGridState = {
        "InternalCode": { width: 180 },
        "Name": { width: 200 },
        "ShortName": { width: 200 },
        "Borrower": { width: 200 },
        "BorrowerCode": { width: 180 },
        "Lender": { width: 200 },
        "LenderCode": { width: 180 },
        "Currency": { width: 180 },
        "Nominal": { width: 180 },
        "InterestRate": { width: 180 },
        "Date": { width: 120 },
        "MaturityDate": { width: 120 },
    };

    const getRowKey = ({ id = 0 }: ILoanSummaryModel) => id;

    return <SearchPanel title="Loans" isQuerying={loanAllLoading}
        addAllowed={IFeatureModel.ManagedPortfolioWrite}
        onAddClick={handleAddNewClick} >
        <ExtendedGrid
            rows={loans}
            columns={columns}
            getRowId={getRowKey}
            onRowClick={handleRowClick}
            initialState={state}
            userCanGroup={true}
            defaultExportFileName="loans.xlsx" />
    </SearchPanel>
}
