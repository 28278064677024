import portfolioTransaction from "immer";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import {
	IGetPortfolioTransactionsModel, IGetPortfolioTransactionModel, PortfolioTransactionModel, ISubFundSummaryModel,
	PortfolioTransactionSummaryModel, IDocumentSummaryModel, IGetDocumentsModel
} from "proxy/apiProxy";
import { IPortfolioTransactionsSearchParameters } from "proxy/apiProxy";
import {
	IGetRelationshipSummary,
	IGetPortfoliosSummary,
	IGetPortfolioSummary,
	IGetSecuritySummary
} from "features/Reference/slice";
import { GetAllSummaries } from "features/Document/utils";

export const ActionFactories = produceActionFactories({
	transactionLoad: (payload: (PortfolioTransactionModel | PortfolioTransactionSummaryModel)["type"] | number) => payload,
	transactionLoaded: (payload: IGetPortfolioTransactionModel) => payload,
	transactionSave: (payload: PortfolioTransactionModel) => payload,
	transactionSaved: (payload: PortfolioTransactionModel) => payload,
	transactionDelete: (payload: number) => payload,
	transactionDeleted: (payload: number) => payload,
	transactionsSearch: (payload: IPortfolioTransactionsSearchParameters) => payload,
	transactionsLoaded: (payload: IGetPortfolioTransactionsModel) => payload,
	transactionAddSecurityInDictionary: (payload: IGetSecuritySummary) => payload,
	transactionAddRelationshipInDictionary: (payload: IGetRelationshipSummary) => payload,
	transactionAddPortfolioInDictionary: (payload: IGetPortfolioSummary) => payload,
	transactionAddPortfoliosToDictionary: (payload: IGetPortfoliosSummary) => payload,

	transactionDocumentsLoad: (payload: number) => payload,
	transactionDocumentsLoaded: (payload: IGetDocumentsModel) => payload,
});

export interface IState {
	transactionsLoading: boolean;
	transactionLoading: boolean;
	transactionDeleting: boolean;
	transactions: (PortfolioTransactionSummaryModel | PortfolioTransactionModel)[];
	transactionCurrent?: PortfolioTransactionModel;
	transactionSaving: boolean;
	dictionaries: GetAllSummaries & {
		subFunds: Record<string | number, ISubFundSummaryModel>;
	};

	documents?: IDocumentSummaryModel[];
	documentsLoading: boolean;
}

export function reducer(
	state: IState = {
		transactionsLoading: false,
		transactionLoading: false,
		transactionSaving: false,
		transactionDeleting: false,
		transactions: [],
		dictionaries: {
			portfolios: {},
			subFunds: {},
			securities: {},
			entities: {},
			relationships: {},
			transactions: {},
			cashMovements: {}
		},
		documentsLoading: false
	},
	action: AnyActionOf<typeof ActionFactories>
): IState {
	return portfolioTransaction(state, draft => {
		switch (action.type) {
			case "transactionDocumentsLoad":
				draft.documentsLoading = true;
				break;
			case "transactionDocumentsLoaded":
				draft.documentsLoading = false;
				draft.documents = action.payload.documents;
				draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
				draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
				draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
				draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
				draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
				draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
				break;

			case "transactionAddSecurityInDictionary":
				draft.dictionaries.securities[action.payload.security.id] = action.payload.security;
				draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
				draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
				break;
			case "transactionAddRelationshipInDictionary":
				draft.dictionaries.relationships[action.payload.relationship.id] = action.payload.relationship;
				draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
				break;
			case "transactionAddPortfolioInDictionary":
				draft.dictionaries.portfolios[action.payload.portfolio.id] = action.payload.portfolio;
				draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
				break;
			case "transactionAddPortfoliosToDictionary":
				draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
				draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
				break;
			case "transactionsSearch":
				draft.transactionsLoading = true;
				break;
			case "transactionsLoaded":
				draft.transactionsLoading = false;
				const { transactions, ...dictionaries } = action.payload;
				draft.dictionaries = { ...draft.dictionaries, ...dictionaries };
				draft.transactions = action.payload.transactions;
				break;
			case "transactionLoad":
				draft.transactionLoading = true;
				draft.transactionCurrent = undefined;
				draft.documents = undefined;
				break;
			case "transactionLoaded":
				draft.transactionLoading = false;
				draft.transactionCurrent = action.payload.transaction;
				draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
				draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
				draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
				draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
				draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
				break;
			case "transactionDelete":
				draft.transactionDeleting = true;
				break;
			case "transactionDeleted":
				draft.transactionDeleting = false;
				const deletedId = action.payload;
				if (draft.transactionCurrent?.id === deletedId) {
					delete draft.transactionCurrent;
				}
				const idx = draft.transactions.findIndex(i => i.id === deletedId);
				if (idx >= 0) {
					draft.transactions.splice(idx, 1);
				}
				break;
			case "transactionSave":
				draft.transactionSaving = true;
				break;
			case "transactionSaved":
				draft.transactionSaving = false;
				const { comment, ...saved } = action.payload;
				const hasComment = (comment && Object.values(comment).filter(c => c && !!c && c.trim() !== "").length > 0) ?? false;
				const summarySaved = { ...saved, hasComment };
				if (draft.transactionCurrent) {
					draft.transactionCurrent = action.payload;
				}
				const existing = draft.transactions.find(i => i.id === saved.id);
				if (existing) {
					Object.assign(existing, summarySaved);
				}
				else {
					draft.transactions.push(summarySaved);
				}
				break;
		}
	});
}
