import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { map, mergeMap, share } from "rxjs/operators";
import { connectorsApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { parse as jsonParse } from "jsonc-parser";


export const loadConnector: Epic<IAnyAction>
    = (action$) => {
        const requestedId$ = action$.pipe(
            mapToPayload("connector", "connectorsLoad"),
            share()
        );

        return merge(
            requestedId$.pipe(
                mergeMap(connectorsApi.getScriptSchemaAsync),
                map(i => ActionFactories.connector.connectorsSchemaLoaded(jsonParse(i)))),
            requestedId$.pipe(
                mergeMap(connectorsApi.getAsync),
                map(ActionFactories.connector.connectorsLoaded)));
    };
export const saveConnector: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("connector", "connectorsSave"),
        mergeMap(model => connectorsApi.saveAsync({ connectorsModel: model, publish: true })),
        map(ActionFactories.connector.connectorsSaved));

export const testProcessor: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("connector", "connectorsProcessorTest"),
        mergeMap(code => connectorsApi.testProcessorAsync({ code }).then(result => ({ code, ...result }))),
        map(ActionFactories.connector.connectorsProcessorTested));

export const testProvider: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("connector", "connectorsProviderTest"),
        mergeMap(code => connectorsApi.testProviderAsync({ code }).then(result => ({ code, ...result }))),
        map(ActionFactories.connector.connectorsProviderTested));