import { DatasetResultModel, IExecuteModel, MacroSymbolModel } from "proxy/apiProxy";
import { toDictionary } from "tools/lib/utility";
import SimpleCodeEditor from "./SimpleCodeEditor";
import { Box, createStyles, TextField, withStyles } from "@material-ui/core";
import ReactJson from 'react-json-view'
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { useCallback, useContext, useMemo, useState } from "react";
import { INotControllableEditorProps } from "./INotControllableEditorProps";
import schema from "tools/dashboard/dashboardLayout.json"
// import DashboardRunner from "../../../tools/dashboard/DashboardRunner"
import { SplitPane } from "tools/splitPane/SplitPane";
import { DashboardViewer } from "tools/dashboard/DashboardViewer";
// import { ReportLink } from "tools/dashboard/ReportLink";
import { useInterval } from "./useInterval";
import { dashboardComponentsContext } from "tools/dashboardExternalComponents/DashboardComponents";
const ContainerBox = withStyles(theme =>
    createStyles({
        root: {
            // padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflowY: "auto"
        },
    })
)(Box);
export interface IFormLayoutEditorProps extends INotControllableEditorProps<string> {
    metadata: MacroSymbolModel;
    onCodeChanged?: (layout: string) => void;
    datasetResult: DatasetResultModel | undefined;
    input: IExecuteModel | undefined;
}
export default function FormLayoutEditor({ onCodeChanged, metadata, refToGetValue, datasetResult, input }: IFormLayoutEditorProps) {
    const readOnly = useContext(ReadOnlyContext);
    const [currentLayout, setCurrentLayout] = useState<string>();
    useInterval(() => {
        const value = refToGetValue.current.getValue();
        setCurrentLayout(value);
    }, 2000, [refToGetValue]);
    return <SplitPane defaultSize={1000}>
        <SplitPane defaultSize={500}>
            <SimpleCodeEditor
                onCodeChanged={onCodeChanged}
                refToGetValue={refToGetValue}
                readOnly={readOnly}
                jsonSchema={schema}
                language="json" />
            <>
                {(datasetResult?.type === "SuccessDatasetResultModel" || datasetResult === undefined) &&
                    <DashboardViewer
                        {...dashboardComponentsContext}
                        dataset={datasetResult?.result}
                        layoutDefinition={currentLayout}
                        datasetInput={input} />}
                {(datasetResult?.type !== "SuccessDatasetResultModel" && !!datasetResult) &&
                    <ReactJson
                        style={{ width: 600 }}
                        name={false}
                        collapsed={1}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        src={datasetResult ?? {}} />}
            </>
        </SplitPane>
        <MetadataTree metadata={metadata} />
    </SplitPane>
}
interface IMetadataTreeProps {
    metadata: MacroSymbolModel;
}
function MetadataTree({ metadata }: IMetadataTreeProps) {
    const [filter, setFilter] = useState("");
    const handleChangeFilter = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value), []);
    const filteredMetadata = useMemo(() => {
        if (!filter || filter === "") {
            return metadata;
        }
        return toDictionary(Object.values(metadata).filter(i => (i.name ?? "").toLowerCase().includes(filter.toLowerCase())), i => i.name ?? "");
    }, [metadata, filter]);
    return <ContainerBox>
        <TextField value={filter} onChange={handleChangeFilter} label="Filter" />
        <ReactJson
            style={{ width: "100%", overflowY: "auto" }}
            name="Referential"
            collapsed={1}
            displayDataTypes={false}
            displayObjectSize={false}
            src={filteredMetadata} />
    </ContainerBox>
}
