import React, { ChangeEventHandler, useCallback, useMemo } from "react";
import { matchSorter } from "match-sorter";
import { typedScreenDictionary } from "../../../tools/routing/screenRouteHooks";
import { createStyles, makeStyles, TextField } from "@material-ui/core";
import { MenuTargetSource } from "./MenuTargetSource";

const useStyles = makeStyles((theme) => createStyles({
    filterInput: {
        paddingBlockStart: theme.spacing(3),
        paddingInline: theme.spacing(1),
    },
    screenContainer:{
        overflowY: "auto", 
        width: "100%", 
        flexGrow: 1
    }
}));

export const TargetScreenSearch = () => {
    const [filter, setFilter] = React.useState<string>("");
    const handleFilterChange = useCallback<ChangeEventHandler<HTMLInputElement>>(event => {
        setFilter(event.target.value);
    }, [])

    const filteredOptions = useMemo(() => {
        return matchSorter(Object.entries(typedScreenDictionary), filter, {
            keys: [
                ([screenKey,]) => screenKey,
                ([, screen]) => screen.label,
                ([, screen]) => screen.keywords ?? "",
            ]
        })
    }, [filter]);

    const cls = useStyles();
    return <>
        <div className={cls.filterInput}>
            <TextField label="Find screen..." value={filter} onChange={handleFilterChange} fullWidth={true}/>
        </div>
        <div className={cls.screenContainer}>
            {filteredOptions.map(target =>
                <MenuTargetSource
                    screenKey={target[0]}
                    screenDef={target[1]}
                />
            )}
        </div>
    </>;
}