import React from 'react';
// import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useFieldEx, IFormField } from 'tools/lib/fieldHelpers';
import { ReadOnlyContext } from "./ReadOnlyContext";


// tslint:disable-next-line: no-empty-interface
export interface IFormCheckBoxFieldProps extends IFormField {
    canBeUndefined?: boolean;
    alignCheckboxToRight?: boolean;
    onValueChanged?: (checked: boolean | undefined) => void;
}

export default function FormCheckBoxField({ onValueChanged: notifyValueChanged, name, helperText, label, disabled, error, alignCheckboxToRight = false, canBeUndefined = false, ...requestedValidations }: IFormCheckBoxFieldProps) {
    const checkDirty = (o1: boolean | undefined, o2: boolean | undefined) => {
        if (canBeUndefined) {
            return o1 !== o2;
        }
        else {
            return (!!o1) !== (!!o2);
        }
    }

    const {
        // error: fieldError, 
        // helperText: fieldHelperText, 
        label: fieldLabel, onValueChanged, value } = useFieldEx<boolean, {}>({ name, helperText, label, error, checkDirty });
    const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (canBeUndefined) {
            if (value) {
                onValueChanged(undefined);
                if (notifyValueChanged) {
                    notifyValueChanged(undefined);
                }
            } else if (value === false) {
                onValueChanged(true);
                if (notifyValueChanged) {
                    notifyValueChanged(true);
                }
            } else {
                onValueChanged(false);
                if (notifyValueChanged) {
                    notifyValueChanged(false);
                }
            }
        } else {
            onValueChanged(!value);
            if (notifyValueChanged) {
                notifyValueChanged(!value);
            }
        }
    }
    // const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => onValueChanged(checked);
    return <ReadOnlyContext.Consumer>{readOnly => <FormControlLabel labelPlacement={alignCheckboxToRight ? "start" : "end"} control={<Checkbox
        id={name}
        disabled={disabled || readOnly}
        indeterminate={(typeof value === "undefined" || value === null) && !!canBeUndefined}
        checked={typeof value === "undefined" ? false : (value === true)}
        onChange={onChange}
        readOnly={readOnly} />}
        label={fieldLabel} />}
    </ReadOnlyContext.Consumer>;
}
