import { useDrop } from "react-dnd";
import {
    dndMenuNodeType,
    IDropResult,
    IMenuDefinitionTreeNodeLevel3,
    IMyDragObjectWithType,
} from "./menuTree";
import { Avatar, Box, IconButton, Paper, Typography } from "@material-ui/core"
import { MenuTargetNodeData } from "features/Parameters/menuConfig/MenuNodeData"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import EditIcon from "@material-ui/icons/Edit"
import CloseIcon from "@material-ui/icons/Close"
import { useCallback } from "react"
import { useReduxSelections } from "tools/lib/reduxStoreAccess"


export interface MenuHomeContainerProps {
    homeNode?: IMenuDefinitionTreeNodeLevel3;
    setHomeNode: (home: IMenuDefinitionTreeNodeLevel3 | undefined) => void;
    onEditTarget: (currentValue: IMenuDefinitionTreeNodeLevel3) => void;
}

export interface ICollectedProps {
    isOver: boolean;
    canDrop: boolean;
}

const useStyles = makeStyles((theme) => createStyles({
    target: {
        minHeight: 40,
        minWidth: 120,
        width: "fit-content",
        padding: 10,
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1)
    },
    validTarget: {
        borderColor: theme.palette.success.main,
    },
}))

export const MenuHomeContainer = ({ homeNode, setHomeNode, onEditTarget }: MenuHomeContainerProps) => {
    const [collect, homeDrop] = useDrop<IMyDragObjectWithType, IDropResult, ICollectedProps>({
        accept: dndMenuNodeType,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        } as ICollectedProps),
        canDrop: (item, monitor) => item.node.model.type !== "level1" && item.node.model.type !== "level2",
        drop: (item, monitor) => {
            if (item.node.model.type !== "level1" && item.node.model.type !== "level2") {
                setHomeNode(item.node.model)
                return item.node
            }
            return undefined
        },
        options: { dropEffect: "copy" }
    });

    const classes = useStyles()
    const hasTarget = !!homeNode;

    const handleEdit = useCallback(() => {
        if (hasTarget) onEditTarget(homeNode)
    }, [hasTarget, homeNode, onEditTarget])
    const handleDelete = useCallback(() => {
        setHomeNode(undefined)
    }, [setHomeNode])

    const { currentTenantImageUrl } = useReduxSelections("app");

    return <Box display>
        <Typography variant="subtitle2">Home:</Typography>
        <Paper ref={homeDrop}
               variant={(hasTarget || collect.isOver)&&!collect.canDrop ? "elevation" : "outlined"}
               elevation={2}
               className={clsx(
                   classes.target,
                   collect.canDrop && classes.validTarget
               )}>
            {hasTarget &&
                <>
                    <Avatar variant="square" alt="company logo" src={currentTenantImageUrl} sizes="small"/>
                    <MenuTargetNodeData node={homeNode} />
                    <IconButton size="small" onClick={handleEdit}><EditIcon fontSize="small" /></IconButton>
                    <IconButton size="small" onClick={handleDelete}><CloseIcon fontSize="small" /></IconButton>
                </>
            }
        </Paper>
    </Box>
}