import { RelationshipModel, RelationshipSummaryModel, ICountryModel, PortfolioSummaryModel, EntitySummaryModel, ICounterpartyRelationshipModel, ICounterpartyTypeModel, IEmirClassificationModel, IInvestorRelationshipModel, IClassificationTypeModel, IFrequencyTypeModel, IInvestorTypeModel, ICurrencyModel, IRoleRelationshipModel } from "proxy/apiProxy";
import FieldBox from "tools/components/FieldBox";
import { oProps, IDictionary, getEnumLabels } from "tools/lib/utility";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import { IGetEntitySummary, IGetRelationshipSummary } from "features/Reference/slice";
import EntitySummary from "components/summaries/EntitySummary";
import EntitySelectField from "components/searchers/EntitySelectField";
import { IPortfolioScopeFieldProps, PortfolioScopeField } from "components/searchers/PortfolioScopeField";
import { useField } from "formik";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import ClassificationsData from "components/global/ClassificationsData";
import RelationshipSelectField from "components/searchers/RelationshipSelectField";
import RelationshipSummary from "components/summaries/RelationshipSummary";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import { roleCollaborationTypes, roleDomains, rolePositions } from "./FormInterface";
import FormTextField from "tools/fieldComponents/FormTextField";

export interface IRelationshipDataProps {
    referenceCountries: IDictionary<ICountryModel>;
    referenceCurrencies: IDictionary<ICurrencyModel>;
    investorClassificationTypes: IClassificationTypeModel[];
    roleRelationshipClassificationTypes: IClassificationTypeModel[];

    dictionaries: {
        // roles: IDictionary<IRoleSummaryModel>;
        entities: Record<number, EntitySummaryModel>;
        portfolios: Record<number, PortfolioSummaryModel>;
        relationships: Record<number, RelationshipSummaryModel>;
    }
    onEntityLoaded: (entitySummary: IGetEntitySummary) => void;
    onPortfolioLoaded: (portfolioSummary: PortfolioSummaryModel) => void;
    onRelationshipLoaded: (roleSummary: IGetRelationshipSummary) => void;
    // hasScope: boolean;
    // type: IProcessDefinitionTypeModel;
}

const counterpartyTypes = getEnumLabels(ICounterpartyTypeModel);
const emirTypes = getEnumLabels(IEmirClassificationModel);
const frequencies = getEnumLabels(IFrequencyTypeModel);
const investorTypes = getEnumLabels(IInvestorTypeModel);

export default function RelationshipData({
    investorClassificationTypes,
    roleRelationshipClassificationTypes,
    referenceCountries,
    onEntityLoaded,
    onPortfolioLoaded,
    onRelationshipLoaded,
    dictionaries,
    // hasScope
}: IRelationshipDataProps) {
    const {
        portfolios,
        entities,
        relationships } = dictionaries;
    const [{ value: type }] = useField<RelationshipModel["type"]>(oProps<RelationshipModel>().path("type"));
    const portfolioSearch: IPortfolioScopeFieldProps["portfolioSearch"] | undefined = {
        fieldName: oProps<RelationshipModel>().path("portfolioIds"),
        onLoaded: onPortfolioLoaded,
        summaries: portfolios
    };
    const sicavSearch: IPortfolioScopeFieldProps["sicavSearch"] | undefined = {
        fieldName: oProps<RelationshipModel>().path("sicavIds"),
        onLoaded: onEntityLoaded,
        summaries: entities
    };
    const investorSearch: IPortfolioScopeFieldProps["investorSearch"] | undefined = {
        fieldName: oProps<RelationshipModel>().path("investorIds"),
        onLoaded: onRelationshipLoaded,
        summaries: relationships,
        entities: entities
    };
    return (
        <FieldBox display="flex" flexDirection="row" noBaseline={true}>
            <FieldBox display="flex" flexDirection="column">
                <FormTextField name={oProps<RelationshipModel>().path("title")} label="Title" />
                <EntitySelectField name={oProps<RelationshipModel>().path("entityId")} required={true} onSelected={onEntityLoaded} />
                <EntitySummary name={oProps<RelationshipModel>().path("entityId")} countries={referenceCountries} entities={entities} />
                <CurrencySelectField name={oProps<RelationshipModel>().path("currencyId")} label="Currency" />
                {type === "CounterpartyRelationshipModel" && <>
                    <FormSimpleSelectField name={oProps<ICounterpartyRelationshipModel>().path("counterpartyType")} label="Type" options={counterpartyTypes} />
                    <FormSimpleSelectField name={oProps<ICounterpartyRelationshipModel>().path("emirClassification")} label="Emir classification" options={emirTypes} />
                    <FormCheckBoxField name={oProps<ICounterpartyRelationshipModel>().path("isAuthorized")} label="Authorized" />
                    <FormDatePickerField name={oProps<ICounterpartyRelationshipModel>().path("lastAuthorizationChange")} label="Last authorization change" />
                </>}
                {type === "RoleRelationshipModel" && <>
                    <FormSimpleSelectField name={oProps<IRoleRelationshipModel>().path("domain")} label="Domain" options={roleDomains} required={true} />
                    <FormSimpleSelectField name={oProps<IRoleRelationshipModel>().path("position")} label="Position" options={rolePositions} required={true} />
                    <FormSimpleSelectField name={oProps<IRoleRelationshipModel>().path("collaborationType")} label="Collaboration Type" options={roleCollaborationTypes} required={true} />
                    <ClassificationsData
                        panelTitle="Classifications"
                        fieldName={oProps<IRoleRelationshipModel>().path("classifications")}
                        classificationTypes={roleRelationshipClassificationTypes} />
                </>}
                {type === "InvestorRelationshipModel" && <>
                    <FormSimpleSelectField name={oProps<IInvestorRelationshipModel>().path("investorType")} label="Type" options={investorTypes} />
                    <ClassificationsData
                        panelTitle="Classifications"
                        fieldName={oProps<IInvestorRelationshipModel>().path("classifications")}
                        classificationTypes={investorClassificationTypes} />
                    <RelationshipSelectField type="RoleRelationshipModel" label="Primary Advisor" name={oProps<IInvestorRelationshipModel>().path("primaryInternalAdvisorId")} onSelected={onRelationshipLoaded} />
                    <RelationshipSummary name={oProps<IInvestorRelationshipModel>().path("primaryInternalAdvisorId")} {...dictionaries} />
                    <RelationshipSelectField type="RoleRelationshipModel" label="Secondary Advisor" name={oProps<IInvestorRelationshipModel>().path("secondaryInternalAdvisorId")} onSelected={onRelationshipLoaded} />
                    <RelationshipSummary name={oProps<IInvestorRelationshipModel>().path("secondaryInternalAdvisorId")} {...dictionaries} />
                    <RelationshipSelectField type="RoleRelationshipModel" label="Intermediary" name={oProps<IInvestorRelationshipModel>().path("intermediaryId")} onSelected={onRelationshipLoaded} />
                    <RelationshipSummary name={oProps<IInvestorRelationshipModel>().path("intermediaryId")} {...dictionaries} />
                    <FormSimpleSelectField name={oProps<IInvestorRelationshipModel>().path("statementFrequency")} label="Statement frequency" options={frequencies} required={true} />
                    <FormCheckBoxField name={oProps<IInvestorRelationshipModel>().path("inDefault")} label="In Default" />
                </>}
            </FieldBox>
            <FieldBox display="flex" flexDirection="column">
                <FormDatePickerField name={oProps<RelationshipModel>().path("startDate")} label="Start date" />
                <FormDatePickerField name={oProps<RelationshipModel>().path("endDate")} label="End date" />
                <PortfolioScopeField
                    title="Related"
                    portfolioSearch={portfolioSearch}
                    sicavSearch={sicavSearch}
                    investorSearch={investorSearch}
                    fullScopeField={type !== "InvestorRelationshipModel" ? oProps<RelationshipModel>().path("fullScope") : undefined} />
            </FieldBox>
        </FieldBox>
    );
}
