import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface IAlertDialogProps {
  title: string;
  open: boolean;
  onClose: (response: boolean) => void;
  children: React.ReactNode;
}
export default function AlertDialog({ open, children, title, onClose }: IAlertDialogProps) {
  const handleCancel = () => onClose(false);
  const handleOk = () => onClose(true);
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" autoFocus={true}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
