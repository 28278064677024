import * as React from "react";
import { ISubFundSummaryModel, ICurrencyModel, IClassificationTypeModel, IPositionModel, SecuritySummaryModel, ICountryModel, isSecurityInstrumentSummaryModel, isDerivativeSummaryModel, isRegularSecuritySummaryModel, EntitySummaryModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFlatClassificationTypes } from "features/Classification/getFlatClassificationTypes";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";
import { toDictionary } from "tools/lib/utility";
import { getEntityName } from "tools/lib/modelUtils";

export interface IPortfolioCompositionGridProps {
    subFunds: Record<number | string, ISubFundSummaryModel>;
    securities: Record<number | string, SecuritySummaryModel>;
    entities: Record<number | string, EntitySummaryModel>;
    positions: IPositionModel[];
    referenceCurrencies: Record<number | string, ICurrencyModel>;
    referenceCountries: Record<number | string, ICountryModel>;
    classificationTypes: IClassificationTypeModel[];
    classificationTypesFlat: IFlatClassificationTypes;
    onTargetSecuritySelected?: (securityId: number) => void;
    editable?: boolean;
    onPositionChanged?: (position: IPositionModel) => void;
    onPositionDelete?: (positionId: number) => void;
}

export default function PortfolioCompositionGrid({
    subFunds,
    entities,
    securities,
    positions,
    referenceCurrencies,
    classificationTypes,
    classificationTypesFlat,
    onTargetSecuritySelected,
    referenceCountries,
    editable,
    onPositionChanged,
    onPositionDelete
}: IPortfolioCompositionGridProps) {

    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, ({ securityId }: IPositionModel) => {
        const secu = securities[securityId];
        if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
            const classifs = subFunds[secu.subFundId].classifications;
            if (classifs) {
                return classifs;
            }
        }
        return isSecurityInstrumentSummaryModel(secu) || secu.type === "RealEstateSummaryModel" ? secu.classifications : undefined;
    });

    const columns = React.useMemo(() => ([
        {
            name: "TargetSecurityCode", title: "Sec Code", getCellValue: ({ securityId }: IPositionModel) => securities[securityId]?.internalCode, filteringEnabled: true
        },
        {
            name: "TargetSecurityName", title: "Sec Name", getCellValue: ({ securityId }: IPositionModel) => securities[securityId]?.name, filteringEnabled: true
        },
        {
            name: "TargetSecurityType", title: "Sec Type", getCellValue: ({ securityId }: IPositionModel) => getSecurityTypeLabel(securities[securityId]?.type)
        },
        ...classificationTypesColumns,
        {
            name: "TargetSecurityMat", title: "Sec Mat", getCellValue: ({ securityId }: IPositionModel) => {
                const secu = securities[securityId];
                return (secu.type === "BondSummaryModel" || isDerivativeSummaryModel(secu)) ? secu.maturityDate : undefined;
            }, columnType: "date"
        },
        {
            name: "TargetSecurityCurrency",
            title: "Sec Ccy",
            getCellValue: ({ securityId }: IPositionModel) => {
                const currencyId = securities[securityId]?.currencyId;
                if (!currencyId) {
                    return;
                }
                return referenceCurrencies[currencyId]?.isoCode;
            }
        },
        {
            name: "TargetSecuritySubFund", title: "Sec Sub-fund", getCellValue: ({ securityId }: IPositionModel) => {
                const secu = securities[securityId];
                return secu.type === "ShareClassSummaryModel" && secu.subFundId ? subFunds[secu.subFundId].name : undefined;
            }, filteringEnabled: true
        },
        {
            name: "TargetSecuritySicav", title: "Sec Sicav", getCellValue: ({ securityId }: IPositionModel) => {
                const secu = securities[securityId];
                if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
                    const subFund = subFunds[secu.subFundId];
                    return subFund.sicavId ? getEntityName(entities[subFund.sicavId]) : undefined;
                }
                return undefined;
            }, filteringEnabled: true
        },
        {
            name: "TargetSecurityIssuer", title: "Sec Issuer", getCellValue: ({ securityId }: IPositionModel) => {
                const secu = securities[securityId];
                return isRegularSecuritySummaryModel(secu) && secu.issuerId !== undefined ? getEntityName(entities[secu.issuerId]) : undefined;
            }, filteringEnabled: true
        },
        {
            name: "TargetSecurityOwner", title: "Sec Owner", getCellValue: ({ securityId }: IPositionModel) => {
                const secu = securities[securityId];
                return secu.type === "RealEstateSummaryModel" && secu.ownerId !== undefined ? getEntityName(entities[secu.ownerId]) : undefined;
            }, filteringEnabled: true
        },
        {
            name: "Country", title: "Country", getCellValue: ({ securityId }: IPositionModel) => {
                const secu = securities[securityId];
                let countryId: number | undefined;
                if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
                    if (subFunds[secu.subFundId].countryId) {
                        countryId = subFunds[secu.subFundId].countryId
                    }
                }
                else if (isRegularSecuritySummaryModel(secu) || secu.type === "RealEstateSummaryModel") {
                    countryId = secu.countryId;
                }
                if (!countryId) {
                    return undefined;
                }
                const country = referenceCountries[countryId];
                if (!country) {
                    return "???";
                }
                return country.name?.en;
            }
        },
        {
            name: "TargetSecurityIsin", title: "Isin", getCellValue: ({ securityId }: IPositionModel) => {
                const secu = securities[securityId];
                return isSecurityInstrumentSummaryModel(secu) ? secu.isin : undefined;
            }, filteringEnabled: true
        },
        { name: "Quantity", title: "Qty", getCellValue: ({ quantity: value }: IPositionModel) => value, columnType: "decimal", editable, property: "quantity" },
        { name: "MarketValueInPortfolioCurrency", title: "Mark Val Prtf Cur", getCellValue: ({ marketValueInPortfolioCcy }: IPositionModel) => marketValueInPortfolioCcy, columnType: "decimal", aggregationType: "sum", editable, property: "marketValueInPortfolioCcy" },
        { name: "MarketValueInSecurityCurrency", title: "Mark Val Sec Cur", getCellValue: ({ marketValueInSecurityCcy }: IPositionModel) => marketValueInSecurityCcy, columnType: "decimal", aggregationType: "sum", editable, property: "marketValueInSecurityCcy" },
        { name: "Weight", title: "Weight", getCellValue: ({ weight }: IPositionModel) => weight, columnType: "precisePercentage2", aggregationType: "sum", editable, property: "weight" },
        { name: "PreciseWeight", title: "Weight (precise)", getCellValue: ({ weight }: IPositionModel) => weight, columnType: "precisePercentage4", aggregationType: "sum", editable, property: "weight" },
        { name: "AccruedInterestInPortfolioCcy", title: "Accr Int Portf Cur", getCellValue: ({ accruedInterestInPortfolioCcy }: IPositionModel) => accruedInterestInPortfolioCcy, columnType: "decimal", aggregationType: "sum", editable, property: "accruedInterestInPortfolioCcy" },
        { name: "AccruedInterestInSecurityCcy", title: "Accr Int Sec Cur", getCellValue: ({ accruedInterestInSecurityCcy }: IPositionModel) => accruedInterestInSecurityCcy, columnType: "decimal", aggregationType: "sum", editable, property: "accruedInterestInSecurityCcy" },
        { name: "BookCostInPortfolioCcy", title: "Book Cost Portf Cur", getCellValue: ({ bookCostInPortfolioCcy }: IPositionModel) => bookCostInPortfolioCcy, columnType: "decimal", aggregationType: "sum", editable, property: "bookCostInPortfolioCcy" },
        { name: "BookCostInSecurityCcy", title: "Book Cost Sec Cur", getCellValue: ({ bookCostInSecurityCcy }: IPositionModel) => bookCostInSecurityCcy, columnType: "decimal", aggregationType: "sum", editable, property: "bookCostInSecurityCcy" },
        { name: "ProfitLossOnMarketPortfolioCcy", title: "P & L On Market Portf Cur", getCellValue: ({ profitLossOnMarketPortfolioCcy }: IPositionModel) => profitLossOnMarketPortfolioCcy, columnType: "decimal", aggregationType: "sum", editable, property: "profitLossOnMarketPortfolioCcy" },
        { name: "ProfitLossOnFxPortfolioCcy", title: "P & L On Fx Portf Cur", getCellValue: ({ profitLossOnFxPortfolioCcy }: IPositionModel) => profitLossOnFxPortfolioCcy, columnType: "decimal", aggregationType: "sum", editable, property: "profitLossOnFxPortfolioCcy" },
        { name: "CostPrice", title: "Cost Price", getCellValue: ({ costPrice }: IPositionModel) => costPrice, columnType: "decimal", aggregationType: "sum", editable, property: "costPrice" },
        { name: "OwnershipPercentage", title: "Ownership Percentage", getCellValue: ({ ownershipPercentage }: IPositionModel) => ownershipPercentage, columnType: "precisePercentage2", aggregationType: "sum", editable, property: "ownershipPercentage" },
        { name: "ValuationPrice", title: "Valuation Price", getCellValue: ({ valuationPrice }: IPositionModel) => valuationPrice, columnType: "decimal", aggregationType: "sum", editable, property: "valuationPrice" },
        { name: "NbAccruedDays", title: "Nb Accr Days", getCellValue: ({ nbAccruedDays }: IPositionModel) => nbAccruedDays, columnType: "decimal", aggregationType: "sum", editable, property: "nbAccruedDays" },
    ] as (IColumnDefinition & { property?: keyof IPositionModel })[]),
        [classificationTypesColumns, editable, securities, referenceCurrencies, subFunds, entities, referenceCountries]);
    const columnsDictionary = React.useMemo(() => toDictionary(columns, i => i.name), [columns]);
    const state: IGridState = {
        "TargetSecurityCode": { width: 150 },
        ...classificationTypesState,
        "TargetSecurityName": { width: 320 },
        "TargetSecurityType": { width: 120 },
        "Country": { width: 120, hidden: true },
        "TargetSecuritySubFund": { width: 200, hidden: true },
        "TargetSecuritySicav": { width: 200, hidden: true },
        "TargetSecurityIssuer": { width: 200, hidden: true },
        "TargetSecurityOwner": { width: 200, hidden: true },
        "TargetSecurityMat": { width: 100, hidden: true },
        "TargetSecurityCurrency": { width: 130 },
        "TargetSecurityIsin": { width: 150, hidden: true },
        "Quantity": { width: 180 },
        "MarketValueInPortfolioCurrency": { width: 180, sortingPosition: -1 },
        "MarketValueInSecurityCurrency": { width: 180 },
        "Weight": { width: 150 },
        "OwnershipPercentage": { width: 110, hidden: true },
        "PreciseWeight": { width: 150, hidden: true },
        "AccruedInterestInPortfolioCcy": { width: 180, hidden: true },
        "BookCostInPortfolioCcy": { width: 180 },
        "BookCostInSecurityCcy": { width: 180 },
        "ProfitLossOnMarketPortfolioCcy": { width: 180, hidden: true },
        "ProfitLossOnFxPortfolioCcy": { width: 180, hidden: true },
        "CostPrice": { width: 180, hidden: true },
        "ValuationPrice": { width: 180, hidden: true },
        "NbAccruedDays": { width: 180, hidden: true }
    };

    const getRowKey = React.useCallback((row: IPositionModel) => row.id, []);

    const handleRowSelect = React.useCallback(({ securityId }: IPositionModel) => {
        if (onTargetSecuritySelected) {
            onTargetSecuritySelected(securityId);
        }
    }, [onTargetSecuritySelected])
    const handleCellValueChanged = React.useCallback((rowId: number | string, columnName: string, value: string) => {
        if (!onPositionChanged) {
            return;
        }
        const changed = positions.find(i => getRowKey(i) === Number(rowId));
        if (!changed) {
            return;
        }
        const newValue = Number(value);
        const property = columnsDictionary[columnName].property;
        if (property) {
            // (changed as any)[property] = newValue;
            onPositionChanged({ ...changed, [property]: newValue });
        }
    }, [columnsDictionary, getRowKey, onPositionChanged, positions])

    return <ExtendedGrid
        getRowId={getRowKey}
        columns={columns}
        rows={positions}
        initialState={state}
        userCanGroup={true}
        defaultExportFileName="composition.xlsx"
        onRowClick={onTargetSecuritySelected ? handleRowSelect : undefined}
        onRowDelete={onPositionDelete ? (row: IPositionModel) => onPositionDelete(row.id) : undefined}
        onCellValueChanged={handleCellValueChanged} />
}
