import { Epic } from "redux-observable";
import { filter, map, mergeMap, share, switchMap } from "rxjs/operators";
import { merge } from "rxjs";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import {
    IDevelopmentItemTypeModel,
    IProcessDefinitionModel,
    IQuestionnaireDevelopmentItemSummaryModel,
    processDefinitionsApi,
    studioDevelopmentItemsApi
} from "proxy/apiProxy";

export const loadProcessDefinitions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processDefinition", "processDefinitionLoadAll"),
        mergeMap(() => processDefinitionsApi.getAllAsync({})),
        map(ActionFactories.processDefinition.processDefinitionLoadedAll));

function createEmptyProcessDefinition(): IProcessDefinitionModel {
    return {
        id: 0,
        code: "",
        name: { "en": "" },
        classifications: {},
        tasks: []
    };
}
export const loadQuestionnaires: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processDefinition", "processDefinitionLoad"),
        mergeMap(() => studioDevelopmentItemsApi.getByTypeAsync({ type: IDevelopmentItemTypeModel.Questionnaire })),
        map(i => ActionFactories.processDefinition.processDefinitionQuestionnaireLoaded(i.developmentItems as IQuestionnaireDevelopmentItemSummaryModel[])));
export const loadProcessDefinition: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("processDefinition", "processDefinitionLoad"),
            share()
        );
        const existingProcessDefinition$ = requestedId$.pipe(
            filter(id => !!id),
            mergeMap(id => processDefinitionsApi.getAsync({ id })));
        const newProcessDefinition$ = requestedId$.pipe(
            filter(id => !id),
            map(() => createEmptyProcessDefinition()));
        return merge(newProcessDefinition$, existingProcessDefinition$).pipe(
            map(ActionFactories.processDefinition.processDefinitionLoaded)
        );
    };
export const saveProcessDefinition: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processDefinition", "processDefinitionSave"),
        mergeMap(model => processDefinitionsApi.saveAsync({ model })),
        map(ActionFactories.processDefinition.processDefinitionSaved));
export const deleteProcessDefinition: Epic<IAnyAction>
    = (action$) => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("processDefinition", "processDefinitionDelete"),
            switchMap(id => processDefinitionsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.processDefinition.processDefinitionDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
