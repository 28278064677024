import { SecurityModel, SecuritySummaryModel, ISecurityTypeModel } from "proxy/apiProxy";
import { getEnumLabels } from "tools/lib/utility";

// const allSecurityTypes = {
//     ...securityTypes,
//     [ISecurityTypeModel.Cash]: { type: ISecurityTypeModel.Cash, label: "Cash", parentKey: "Regular" }
// }
const securityTypes = getEnumLabels(ISecurityTypeModel);
export function getSecurityTypeLabel(type: (SecuritySummaryModel | SecurityModel)["type"] | ISecurityTypeModel): string;
export function getSecurityTypeLabel(type: (SecuritySummaryModel | SecurityModel)["type"] | ISecurityTypeModel | null): string | null;
export function getSecurityTypeLabel(type: (SecuritySummaryModel | SecurityModel)["type"] | ISecurityTypeModel | undefined): string | undefined;
export function getSecurityTypeLabel(type: (SecuritySummaryModel | SecurityModel)["type"] | ISecurityTypeModel | undefined | null): string | null | undefined;
export function getSecurityTypeLabel(type: (SecuritySummaryModel | SecurityModel)["type"] | ISecurityTypeModel | undefined | null): string | null | undefined {
    if (typeof (type) === "undefined") {
        return undefined;
    }
    if (!type) {
        return null;
    }
    switch (type) {
        case "LoanSummaryModel": return "Loan";
        case "CashSummaryModel": return "Cash";
        case ISecurityTypeModel.Bond:
        case "BondModel":
        case "BondSummaryModel": return securityTypes[ISecurityTypeModel.Bond];
        case ISecurityTypeModel.Cfd:
        case "CfdModel":
        case "CfdSummaryModel": return securityTypes[ISecurityTypeModel.Cfd];
        case ISecurityTypeModel.Commodity:
        case "CommodityModel":
        case "CommoditySummaryModel": return securityTypes[ISecurityTypeModel.Commodity];
        case ISecurityTypeModel.Equity:
        case "EquityModel":
        case "EquitySummaryModel": return securityTypes[ISecurityTypeModel.Equity];
        case ISecurityTypeModel.Etf:
        case "EtfModel":
        case "EtfSummaryModel": return securityTypes[ISecurityTypeModel.Etf];
        case ISecurityTypeModel.Future:
        case "FutureModel":
        case "FutureSummaryModel": return securityTypes[ISecurityTypeModel.Future];
        case ISecurityTypeModel.FxForward:
        case "FxForwardModel":
        case "FxForwardSummaryModel": return securityTypes[ISecurityTypeModel.FxForward];
        case ISecurityTypeModel.Option:
        case "OptionModel":
        case "OptionSummaryModel": return securityTypes[ISecurityTypeModel.Option];
        case ISecurityTypeModel.ShareClass:
        case "ShareClassModel":
        case "ShareClassSummaryModel": return securityTypes[ISecurityTypeModel.ShareClass];
        case ISecurityTypeModel.StructuredProduct:
        case "StructuredProductModel":
        case "StructuredProductSummaryModel": return securityTypes[ISecurityTypeModel.StructuredProduct];
        case ISecurityTypeModel.Swap:
        case "SwapModel":
        case "SwapSummaryModel": return securityTypes[ISecurityTypeModel.Swap];
        case ISecurityTypeModel.RealEstate:
        case "RealEstateModel":
        case "RealEstateSummaryModel": return securityTypes[ISecurityTypeModel.RealEstate];
        case ISecurityTypeModel.MiscellaneousGood:
        case "MiscellaneousGoodModel":
        case "MiscellaneousGoodSummaryModel": return securityTypes[ISecurityTypeModel.MiscellaneousGood];
        case ISecurityTypeModel.Instrument: return "Instrument";
        case ISecurityTypeModel.All: return "Any Security";
        default: return null;
    }
}
