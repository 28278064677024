function getPathValue(path: string, obj: any) {
    return path.split('.').reduce((res, key) => {
        if (res[key] || typeof (res[key]) === "number") {
            const ret = res[key];
            delete res[key];
            return ret;
        }
        else {
            return null;
        }
    }, obj);
}
function parseTemplate(template: string, map: Record<string, any>) {
    return template.replace(/{([^{]+[^}])}/g, (match) => {
        const path = match.substr(1, match.length - 2).trim();
        return getParam(getPathValue(path, map)) ?? "";
    });
}
function buildQueryParams(queryParameters: Record<string, any>): string | null {
    const keyValues = Object.keys(queryParameters).map(name => ({ name, value: queryParameters[name] })).reduce<{ name: string, value: any }[]>((a, { name, value }) => {
        const values = Array.isArray(value) ? value : [value];
        const ulrValues = values.filter(v => v !== null && typeof (v) !== "undefined").map(v => ({ name, value: v }));
        return [...a, ...ulrValues];
    }, []);
    if (!keyValues) {
        return null;
    }
    return keyValues.map(({ name, value }) => `${name}=${getParam(value)}`).join('&');
}
export default function buildUrl(templateUrl: string, pathParameters?: Record<string, any>, queryParameters?: Record<string, any>): string {
    if (templateUrl && templateUrl[templateUrl.length - 1] === '/') {
        templateUrl = templateUrl.slice(0, -1);
    }
    if (pathParameters) {
        templateUrl = parseTemplate(templateUrl, pathParameters);
    }
    if (queryParameters) {
        const queryParams = buildQueryParams(queryParameters);
        if (queryParams) {
            templateUrl += `?${queryParams}`;
        }
    }
    return templateUrl.replace(/\$\{.+?\}/gmi, "");
};
function getParam(queryParam: any): string | null {
    if (queryParam == null || typeof queryParam === "undefined") {
        return null;
    }
    return encodeURIComponent(String(queryParam).trim());
}
