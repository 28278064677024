import { IMacroScriptModel, IMacroScriptCategoryModel, IMonitoringMacroScriptModel, MacroScriptModel, IDataProcessorMacroScriptModel } from "proxy/apiProxy";
import FieldBox from "tools/components/FieldBox";
import { oProps, IDictionary } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import { useField } from "formik";
import { SummaryField } from "components/global/SummaryField";

export interface IMacroScriptDataProps {
    referenceMacroScriptCategories: IDictionary<IMacroScriptCategoryModel>
}

export default function MacroScriptData({ referenceMacroScriptCategories }: IMacroScriptDataProps) {
    const [{ value: type }] = useField<MacroScriptModel["type"]>(oProps<MacroScriptModel>().path("type"));
    const [{ value: singleScope }] = useField<MacroScriptModel["type"]>(oProps<IDataProcessorMacroScriptModel | IMonitoringMacroScriptModel>().path("singleScope"));
    return (
        <FieldBox display="flex" flexDirection="column">
            <FormTextField name={oProps<IMacroScriptModel>().path("code")} label="Code" fullWidth={false} required={true} />
            <FormTextField name={oProps<IMacroScriptModel>().path("name")} label="Name" required={true} />
            <FormSimpleSelectField name={oProps<IMacroScriptModel>().path("categoryId")} label="Category" options={referenceMacroScriptCategories} />
            <FormTextField name={oProps<IMacroScriptModel>().path("description")} label="Description" multiline={true} />
            {type === "MonitoringMacroScriptModel" && <FormCheckBoxField name={oProps<IMonitoringMacroScriptModel>().path("publishOnPortal")} label="Publish on portal" />}
            {(type === "MonitoringMacroScriptModel" || type === "DataProcessorMacroScriptModel") && <SummaryField label="Single Scope" value={singleScope} />}
        </FieldBox>
    );
}

