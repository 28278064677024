import { Epic } from "redux-observable";
import { filter, map, mergeMap, share } from "rxjs/operators";
import {
    IGetPortfolioTransactionModel,
    IPortfolioFxTransactionModel,
    IPortfolioSecurityTransactionModel,
    portfolioTransactionsApi
} from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { merge } from "rxjs";

export const loadTransactions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("portfolioTransaction", "transactionsSearch"),
        mergeMap(portfolioTransactionsApi.searchAsync),
        map(ActionFactories.portfolioTransaction.transactionsLoaded));

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("portfolioTransaction", "transactionDocumentsLoad"),
        mergeMap(id => portfolioTransactionsApi.getDocumentsAsync({ id })),
        map(ActionFactories.portfolioTransaction.transactionDocumentsLoaded));

export const loadTransaction: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("portfolioTransaction", "transactionLoad"),
            share());

        return merge(
            requestedId$.pipe(
                filter(id => typeof id === "number"),
                mergeMap(id => portfolioTransactionsApi.getAsync({ id: id as number }))),
            requestedId$.pipe(
                filter(id => typeof id === "string"),
                map(i => createEmptyTransaction(i as string)))
        ).pipe(
            map(ActionFactories.portfolioTransaction.transactionLoaded)
        );
    };
function createEmptyTransaction(type: string): IGetPortfolioTransactionModel {
    const transaction = (function () {
        switch (type) {
            case "cash":
            case "PortfolioFxTransactionModel":
            case "PortfolioFxTransactionSummaryModel":
                return {
                    type: "PortfolioFxTransactionModel",
                    description: "",
                    id: 0,
                    portfolioId: 0,
                    transactionCode: "",
                } as IPortfolioFxTransactionModel;
            // case "security":
            // case "PortfolioSecurityTransactionModel":
            // case "PortfolioSecurityTransactionSummaryModel":
            default:
                return {
                    type: "PortfolioSecurityTransactionModel",
                    description: "",
                    id: 0,
                    portfolioId: 0,
                    transactionCode: "",
                } as IPortfolioSecurityTransactionModel;
        }
    })();
    return {
        relationships: {},
        entities: {},
        portfolios: {},
        securities: {},
        subFunds: {},
        transaction
    };
}
export const saveTransaction: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("portfolioTransaction", "transactionSave"),
        mergeMap(model => portfolioTransactionsApi.saveAsync({ model })),
        map(ActionFactories.portfolioTransaction.transactionSaved));
export const deleteTransaction: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("portfolioTransaction", "transactionDelete"),
            mergeMap(id => portfolioTransactionsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.portfolioTransaction.transactionDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }