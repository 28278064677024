import produce from "immer";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { mergeDeep, toList } from "tools/lib/utility";
import { IGetStatisticsSetModel, IGetStatisticsBacktestingModel, IBacktestingSerieModel } from "proxy/apiProxy";
export interface IState {
    valueAtRiskAllLoading: boolean;
    valueAtRisks: IGetStatisticsSetModel;
    valueAtRiskChildrenLoading: boolean;
    valueAtRiskBacktestingLoading: boolean;
    valueAtRiskChildren: {
        [key: string]: IGetStatisticsSetModel;
        [key: number]: IGetStatisticsSetModel;
    }
    valueAtRiskBacktesting: IGetStatisticsBacktestingModel | undefined;
    valueAtRiskBacktestingChartData: any;
    valueAtRiskCurrentId?: number;

}

export const ActionFactories = produceActionFactories({
    valueAtRiskLoadAll: () => undefined,
    valueAtRiskLoadedAll: (payload: IGetStatisticsSetModel) => payload,
    valueAtRiskLoadChildren: (payload: number) => payload,
    valueAtRiskLoadedChildren: (payload: IGetStatisticsSetModel) => payload,
    valueAtRiskLoadBacktesting: (payload: number) => payload,
    valueAtRiskLoadedBacktesting: (payload: IGetStatisticsBacktestingModel) => payload
});


export const createValueKey = (key: "portfolio" | "benchmark", restSerie: any) => {
    return JSON.stringify({ key, ...restSerie });
}

const normalizeSerie = (cur: IBacktestingSerieModel) => {
    const { portfolioValues, benchmarkValues, ...restSerie } = cur;
    const curResult = Object.keys(portfolioValues).map(key => ({
        date: new Date(key),
        [createValueKey("portfolio", restSerie)]: portfolioValues[key]
    }));
    return curResult;
}

export function reducer(
    state: IState = {
        valueAtRiskAllLoading: false,
        valueAtRisks: {
            portfolios: {},
            benchmarkNames: {},
            securities: {},
            securityClassifications: {},
            statisticsSets: []
        },
        valueAtRiskChildrenLoading: false,
        valueAtRiskBacktestingLoading: false,
        valueAtRiskChildren: {},
        valueAtRiskBacktesting: undefined,
        valueAtRiskBacktestingChartData: undefined,
        valueAtRiskCurrentId: undefined
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "valueAtRiskLoadAll":
                draft.valueAtRiskAllLoading = true;
                break;
            case "valueAtRiskLoadedAll":
                draft.valueAtRiskAllLoading = false;
                draft.valueAtRisks = action.payload;
                break;
            case "valueAtRiskLoadChildren":
                draft.valueAtRiskChildrenLoading = true;
                break;
            case "valueAtRiskLoadedChildren":
                draft.valueAtRiskChildrenLoading = false;
                const portfolioId: number = action.payload.statisticsSets[0].portfolioId ?? 0;
                draft.valueAtRiskChildren[portfolioId] = action.payload;
                draft.valueAtRisks.statisticsSets = [...draft.valueAtRisks.statisticsSets, ...action.payload.statisticsSets];
                draft.valueAtRisks.portfolios = { ...draft.valueAtRisks.portfolios, ...action.payload.portfolios };
                draft.valueAtRisks.benchmarkNames = { ...draft.valueAtRisks.benchmarkNames, ...action.payload.benchmarkNames };
                draft.valueAtRisks.securities = { ...draft.valueAtRisks.securities, ...action.payload.securities };
                draft.valueAtRisks.securityClassifications = { ...draft.valueAtRisks.securityClassifications, ...action.payload.securityClassifications };
                break;
            case "valueAtRiskLoadBacktesting":
                draft.valueAtRiskBacktestingLoading = true;
                break;
            case "valueAtRiskLoadedBacktesting":
                draft.valueAtRiskBacktestingLoading = false;
                draft.valueAtRiskBacktesting = action.payload;

                const chartData = draft.valueAtRiskBacktesting?.series.reduce((acc: any, cur: IBacktestingSerieModel) => {
                    acc = acc ? acc : {};
                    const curResult = normalizeSerie(cur);
                    const target: Record<string, (typeof curResult)[number]> = {};
                    curResult.forEach(item => target[item.date.toString()] = item);
                    return mergeDeep(acc, target) as any;
                }, null);

                draft.valueAtRiskBacktestingChartData = toList(chartData);

                break;
        }
    });
}
