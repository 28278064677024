import { PortfolioTradeModel, TradeBookTradeModel } from "proxy/apiProxy";

export function getPortfolioTradeTypeLabel(type: PortfolioTradeModel["type"] | TradeBookTradeModel["type"]): string;
export function getPortfolioTradeTypeLabel(type: PortfolioTradeModel["type"] | TradeBookTradeModel["type"] | null): string | null;
export function getPortfolioTradeTypeLabel(type: PortfolioTradeModel["type"] | TradeBookTradeModel["type"] | undefined): string | undefined;
export function getPortfolioTradeTypeLabel(type: PortfolioTradeModel["type"] | TradeBookTradeModel["type"] | undefined | null): string | null | undefined;
export function getPortfolioTradeTypeLabel(type: PortfolioTradeModel["type"] | TradeBookTradeModel["type"] | undefined | null) {
    if (typeof (type) === "undefined") {
        return undefined;
    }
    if (!type) {
        return null;
    }
    switch (type) {
        case "TradeBookFxTradeModel":
        case "FxTradeModel": return "Fx" as string;
        case "TradeBookSecurityTradeModel":
        case "SecurityTradeModel": return "Security" as string;
    }
}
