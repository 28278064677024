import { Grid } from "@material-ui/core";
import { DocumentsGrid, IDocumentsGridProps } from "./DocumentsGrid";
import FormDropZoneField from "tools/fieldComponents/FormDropZoneField";

interface IExtendedDocumentsGridProps extends IDocumentsGridProps {
    name: string;
    contentName: string;
}

export function ExtendedDocumentsGrid({ documents, dictionaries, name, contentName }: IExtendedDocumentsGridProps) {
    return <Grid container={true}>
        <DocumentsGrid
            documents={documents}
            dictionaries={dictionaries} />
        <Grid item={true} md={3}>
            <FormDropZoneField
                label="Drop the document here"
                emptyValue={null}
                minLength={1}
                contentName={contentName}
                name={name} />
        </Grid>
    </Grid>
}
