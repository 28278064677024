import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import autobind from 'autobind-decorator';
import * as React from 'react';
import { InputAdornment } from '@material-ui/core';
import LanguageDropdown from 'tools/components/LanguageDropdown';
import { LanguageCode } from 'tools/lib/utility';

export interface ICulturedText {
    [cultureCode: string]: string;
}

export type IMultiCulturedTextFieldProps = TextFieldProps & {
    language?: LanguageCode;
    onValueChanged?: (value: ICulturedText) => void;
    readOnly?: boolean;
}

@autobind
class MultiCulturedTextField extends React.PureComponent<IMultiCulturedTextFieldProps, { language: LanguageCode }>{
    constructor(props: IMultiCulturedTextFieldProps) {
        super(props);
        this.state = { language: props.language || "en" };
    }
    public render() {
        const {
            label,
            helperText,
            value,
            onValueChanged,
            readOnly,
            ...textFieldProps
        } = this.props;
        const { language } = this.state;
        const inputProps = {
            endAdornment: (
                <InputAdornment position="end" style={{ paddingBottom: 10 }}>
                    <LanguageDropdown language={language} onChange={this.handleCultureChange} />
                </InputAdornment>
            ),
            readOnly,
            disabled: readOnly
        };
        const helperValue = (value as any) || {};
        let helperTextValue = helperText;
        if (!helperTextValue) {
            const filledCultures = Object.keys(helperValue).filter(cultureCode => helperValue[cultureCode]);
            if (filledCultures.length > 0) {
                if (filledCultures.length === 1) {
                    helperTextValue = `${filledCultures[0]} translation is filled`;
                }
                else {
                    const lastElement = filledCultures.pop();
                    helperTextValue = `${filledCultures.join(", ")} and ${lastElement} translations are filled`;
                }
            }
        }
        return <TextField InputProps={inputProps} {...textFieldProps} label={label} helperText={helperTextValue} value={helperValue[language] || ""} onChange={this.mappedOnChange} />;
    }
    private handleCultureChange(language: LanguageCode) {
        this.setState({ language });
    }
    private mappedOnChange(i: React.ChangeEvent<HTMLInputElement>) {
        const { language } = this.state;
        const { onValueChanged } = this.props;
        const value = { ...((this.props.value || {}) as ICulturedText), [language]: i.target.value } as ICulturedText;
        if (onValueChanged) {
            onValueChanged(value);
        }
    }
}

export default MultiCulturedTextField;