import React from 'react';
import MultiSelect from '../components/MultiSelect';
import * as Uuid from 'uuid';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { useFieldEx, IFormField, ValidatorParams } from 'tools/lib/fieldHelpers';
import { IDictionary } from 'tools/lib/utility';
import { ReadOnlyContext } from "./ReadOnlyContext";

const validators = {
    required: (v: string[] | number[] | undefined, isTrue?: boolean) => isTrue && (!v || (Array.isArray(v) && v.length === 0)) ? 'Required' : undefined,
    minElements: (v: string[] | number[] | undefined, min?: number) => !!min && (!v || (Array.isArray(v) && v.length < min)) ? `At least ${min} elements` : undefined,
    maxElements: (v: string[] | number[] | undefined, max?: number) => !!max && (!v || (Array.isArray(v) && v.length > max)) ? `Maximum ${max} elements` : undefined
}

export interface IFormMultiSelectFieldProps<V extends string | number = number, T extends any | { id?: V } = any> extends IFormField, ValidatorParams<typeof validators> {
    fullWidth?: boolean;
    options: IDictionary<T> | T[];
    getValue?: (row: T) => V;
    getPrimaryContent?: (row: T) => React.ReactNode;
    getSecondaryContent?: (row: T) => React.ReactNode;
    onSelectionChanged?: (value: V[]) => void;
}

export default function FormMultiSelectField<V extends string | number = number, T extends any | { id?: V } = any>(
    { name, label, helperText, disabled, error = false, fullWidth = true, onSelectionChanged, options, getValue,
        getPrimaryContent,
        getSecondaryContent, ...requestedValidations }: IFormMultiSelectFieldProps<V, T>) {

    const checkDirty = (o1: (V[] | undefined) = [], o2: (V[] | undefined) = []) => {
        for (const p in o1) {
            if (o1.hasOwnProperty(p)) {
                if (o1[p] !== o2[p]) {
                    return true;
                }
            }
        }
        for (const p in o2) {
            if (o2.hasOwnProperty(p)) {
                if (o1[p] !== o2[p]) {
                    return true;
                }
            }
        }
        return false;
    }

    const [idComponent] = React.useState(Uuid.v1());
    const { error: fieldError, helperText: fieldHelperText, label: fieldLabel, onValueChanged, value } = useFieldEx<V[], typeof validators>({ name, helperText, label, required: requestedValidations.required, error, requestedValidations, validators, checkDirty });
    const onChange = (newValue: V[] | undefined) => {
        onValueChanged(newValue);
        if (onSelectionChanged) {
            onSelectionChanged(newValue ?? []);
        }
    }

    return <ReadOnlyContext.Consumer>
        {readOnly => <FormControl fullWidth={fullWidth} error={fieldError} >
            <InputLabel htmlFor={idComponent}>{fieldLabel}</InputLabel>
            <MultiSelect<V, T>
                id={name}
                required={requestedValidations.required}
                readOnly={readOnly}
                error={fieldError}
                values={value as V[]}
                disabled={disabled || readOnly}
                options={options}
                getValue={getValue}
                getPrimaryContent={getPrimaryContent}
                getSecondaryContent={getSecondaryContent}
                inputProps={{ id: idComponent }}
                margin="dense"
                onSelectionChanged={onChange}
                fullWidth={fullWidth} />
            {fieldHelperText && <FormHelperText error={fieldError}>{fieldHelperText}</FormHelperText>}
        </FormControl>}
    </ReadOnlyContext.Consumer>
}

