import {
    SecurityModel, ICountryModel, ICurrencyModel, IFrequencyTypeModel, IInvestorTypeModel,
    IClassificationTypeModel, IDividendDistributionPolicyModel, IOptionTypeModel, IPutCallModel, EntitySummaryModel, IMarketDataProviderModel,
    RelationshipSummaryModel, isOptionFutureModel, isStandardDerivativeModel,
    isDerivativeModel, isRegularSecurityModel, IShareClassModel, RegularSecurityModel, IBondModel, DerivativeModel, StandardDerivativeModel,
    IFxForwardModel, IOptionModel, OptionFutureModel, IBondIssuerTypeModel, ISubFundSummaryModel, ICouponTypeModel, isTangibleAssetModel, TangibleAssetModel, IRealEstateModel, IExtensionFieldModel
} from "proxy/apiProxy";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormSwitchField from "tools/fieldComponents/FormSwitchField";
import FieldBox from "tools/components/FieldBox";
import { getEnumLabels, oProps } from "tools/lib/utility";
import SubFundSummary from "components/summaries/SubFundSummary";
import RelationshipSelectField from "components/searchers/RelationshipSelectField";
import RelationshipSummary from "components/summaries/RelationshipSummary";
import ClassificationsData from "components/global/ClassificationsData";
import { Card, CardContent, Chip, Grid, Typography } from "@material-ui/core";
import SubFundSelectField from "components/searchers/SubFundSelectField";
import { IGetSubFundSummary, IGetRelationshipSummary, IGetEntitySummary } from "features/Reference/slice";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EntitySummary from "components/summaries/EntitySummary";
import EntitySelectField from "components/searchers/EntitySelectField";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import { CountrySelectField } from "components/searchers/CountrySelectField";
import ExtensionFields from "features/ManagedPortfolio/ExtensionFields";
import DataProviderCodes from "./DataProviderCodes";
// import { useField } from "formik";

export interface ISecurityDataProps {
    formFieldName: string;
    referenceCountries: Record<number, ICountryModel>;
    referenceCurrencies: Record<number, ICurrencyModel>;
    formValues: SecurityModel;
    previousSubFundId?: number;
    securityExtensionFields: IExtensionFieldModel[];
    parametersMarketDataProviders: IMarketDataProviderModel[];
    classificationTypes: IClassificationTypeModel[];
    dictionaries: {
        subFunds: Record<string | number, ISubFundSummaryModel>;
        entities: Record<string | number, EntitySummaryModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
        // processDefinitions: Record<string | number, IProcessDefinitionSummaryModel>;
    }
    onRelationshipLoaded: (found: IGetRelationshipSummary) => void;
    onSubFundLoaded: (found: IGetSubFundSummary) => void;
    onEntityLoaded: (found: IGetEntitySummary) => void;
}

const couponsTypes = getEnumLabels(ICouponTypeModel);
const frequencyTypes = getEnumLabels(IFrequencyTypeModel);

const putCalls = getEnumLabels(IPutCallModel);

const investorTypes = getEnumLabels(IInvestorTypeModel);

const dividendDistributionPolicies = getEnumLabels(IDividendDistributionPolicyModel);

const optionTypes = getEnumLabels(IOptionTypeModel);
const issuerTypes = getEnumLabels(IBondIssuerTypeModel);


export default function SecurityData({
    formFieldName,
    referenceCurrencies,
    referenceCountries,
    classificationTypes,
    parametersMarketDataProviders,
    formValues,
    dictionaries,
    previousSubFundId,
    onRelationshipLoaded,
    onSubFundLoaded: onPortfolioLoaded,
    onEntityLoaded,
    securityExtensionFields
}: ISecurityDataProps) {
    const {
        subFunds,
        entities
    } = dictionaries;
    // const isFxForward = formValues.type === "FxForwardModel";
    // const isShareClass = formValues.type === "ShareClassModel";
    // const isOptionFuture = isOptionFutureModel(formValues);
    // const isOption = formValues.type === "OptionModel";
    // const isStandardDerivative = isStandardDerivativeModel(formValues);
    // const isDerivative = isDerivativeModel(formValues);
    // const isRegularSecurity = isRegularSecurityModel(formValues);
    const getSubFundHelperText = () => {
        const classifications = formValues.classifications ?? {};
        if (formValues.type === "ShareClassModel" && Object.keys(classifications).map(key => classifications[key]).filter(i => i).length && !previousSubFundId && formValues.subFundId) {
            return "Classifications will be removed";
        }
        return;
    }
    const currencyCode = (formValues.currencyId && referenceCurrencies) ? referenceCurrencies[formValues.currencyId]?.isoCode : undefined;
    const subFundHelperText = getSubFundHelperText();
    // https://stackoverflow.com/questions/6913512/how-to-sort-an-array-of-objects-by-multiple-fields
    return <FieldBox display="flex" flexDirection="column">
        <Card >
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    Codes
                </Typography>
                <Grid container={true} spacing={1} alignItems="center">
                    <Grid item={true} xs={3}>
                        <FormTextField name={oProps<SecurityModel>(formFieldName).path("internalCode")} label="Internal Code" required={true} maxLength={100} />
                    </Grid>
                    {!isTangibleAssetModel(formValues) && <>
                        <DataProviderCodes
                            formFieldName={oProps<SecurityModel>(formFieldName).path("dataProviderCodes")}
                            isinFieldName={oProps<SecurityModel>(formFieldName).path("isin")}
                            valorFieldName={oProps<SecurityModel>(formFieldName).path("valor")}
                            wknFieldName={oProps<SecurityModel>(formFieldName).path("wkn")}
                            cusipFieldName={oProps<SecurityModel>(formFieldName).path("cusip")}
                            parametersMarketDataProviders={parametersMarketDataProviders} />
                    </>}
                </Grid>
            </CardContent>
        </Card>
        {(formValues.type === "ShareClassModel" && formValues.isUnderManagement) && <Chip icon={<AssignmentIndIcon />} label="Under management" />}
        <FieldBox display="flex" flexDirection="row">
            <FormTextField name={oProps<SecurityModel>(formFieldName).path("name")} label="Name" required={true} maxLength={250} />
            <FormTextField name={oProps<SecurityModel>(formFieldName).path("shortName")} label="Short Name" maxLength={50} />
            <CurrencySelectField name={oProps<SecurityModel>(formFieldName).path("currencyId")} label="Currency" />
            {formValues.type === "ShareClassModel" && <FormSwitchField name={oProps<IShareClassModel>(formFieldName).path("isOpenForInvestment")} label="Is Open For Investment" />}
            {formValues.type === "BondModel" && <FormCheckBoxField name={oProps<IBondModel>(formFieldName).path("isConvertible")} label="Is Convertible" />}
        </FieldBox>

        <ExtensionFields extensionFields={securityExtensionFields} pathToExtensionFields={oProps<SecurityModel>(formFieldName).path("securityExtensionFieldsValues")} />
        <ClassificationsData
            panelTitle="Classifications"
            fieldName={oProps<SecurityModel>(formFieldName).path("classifications")}
            classificationTypes={classificationTypes} />
        {formValues.type === "ShareClassModel" && <FieldBox display="flex" flexDirection="row">
            <FieldBox display="flex" flexDirection="column">
                <SubFundSelectField name={oProps<IShareClassModel>(formFieldName).path("subFundId")} label="Sub Fund" onSelected={onPortfolioLoaded} />
                {!!subFundHelperText && <Typography style={{ color: "red" }} >{subFundHelperText}</Typography>}
                <SubFundSummary name={oProps<IShareClassModel>(formFieldName).path("subFundId")} currencies={referenceCurrencies} subFunds={subFunds} />
            </FieldBox>
            <FieldBox display="flex" flexDirection="column">
                <FormCheckBoxField name={oProps<IShareClassModel>(formFieldName).path("isHedged")} label="Is Hedged" />
                <FormSimpleSelectField name={oProps<IShareClassModel>(formFieldName).path("dividendDistributionPolicy")} label="Dividend Type" options={dividendDistributionPolicies} />
                <FormSimpleSelectField name={oProps<IShareClassModel>(formFieldName).path("dividendPeriodicity")} label="Dividend Periodicity" options={frequencyTypes} disabled={formValues.dividendDistributionPolicy === IDividendDistributionPolicyModel.Accumulation} />
                <FormSimpleSelectField name={oProps<IShareClassModel>(formFieldName).path("investorType")} label="Investor Type" options={investorTypes} />
                <FormDatePickerField name={oProps<IShareClassModel>(formFieldName).path("inceptionDate")} label="Inception Date" />
                <FormDatePickerField name={oProps<IShareClassModel>(formFieldName).path("closingDate")} label="Closing Date" />
            </FieldBox>
            <FieldBox display="flex" flexDirection="column">
                <FormTextField name={oProps<IShareClassModel>(formFieldName).path("minimumInitialInvestment")} label="Minimum Initial Investment" isNumber={true} adornment={currencyCode} />
                <FormTextField name={oProps<IShareClassModel>(formFieldName).path("minimumSubscriptionAmount")} label="Minimum Subscription Amount" isNumber={true} adornment={currencyCode} />
                <FormTextField name={oProps<IShareClassModel>(formFieldName).path("entryFee")} label="Entry Fee" isPercentage={true} />
                <FormTextField name={oProps<IShareClassModel>(formFieldName).path("exitFee")} label="Exit Fee" isPercentage={true} />
                <FormTextField name={oProps<IShareClassModel>(formFieldName).path("maximumSubscriptionFee")} label="Maximum Subscription Fee" isPercentage={true} />
                <FormTextField name={oProps<IShareClassModel>(formFieldName).path("managementFee")} label="Management Fee" isPercentage={true} />
                {formValues.type === "ShareClassModel" && <FormTextField name={oProps<IShareClassModel>(formFieldName).path("minimumHoldingAmount")} label="Minimum Holding Amount" />}

                {/* <FormTextField name={fieldof<ISecurityModel>("performanceFee")} label="Performance Fee" isPercentage={true} /> */}
            </FieldBox>
        </FieldBox>}

        {isTangibleAssetModel(formValues) && <FieldBox display="flex">
            <FormDatePickerField name={oProps<TangibleAssetModel>(formFieldName).path("purchaseDate")} label="Purchase Date" />
            <FormTextField name={oProps<TangibleAssetModel>(formFieldName).path("purchasePrice")} label="Purchase Price" isNumber={true} />
            <FormTextField name={oProps<TangibleAssetModel>(formFieldName).path("purchaseFees")} label="Purchase Fees" isNumber={true} />
            {formValues.type === "RealEstateModel" && <FormTextField name={oProps<IRealEstateModel>(formFieldName).path("location")} label="Location" multiline={true} />
            }
        </FieldBox>}

        {isRegularSecurityModel(formValues) && <>
            <CountrySelectField name={oProps<RegularSecurityModel>(formFieldName).path("countryId")} label="Country" />
            <FormSimpleSelectField name={oProps<RegularSecurityModel>(formFieldName).path("pricingFrequency")} label="Pricing Frequency" options={frequencyTypes} required={formValues.type !== "EquityModel"} />
            <EntitySelectField name={oProps<RegularSecurityModel>(formFieldName).path("issuerId")} label="Issuer" onSelected={onEntityLoaded} />
            <EntitySummary name={oProps<RegularSecurityModel>(formFieldName).path("issuerId")} label="Issuer" countries={referenceCountries} entities={entities} />
        </>}

        {formValues.type === "RealEstateModel" && <Grid container={true} spacing={1}>
            <Grid item={true} sm={6}>
                <RelationshipSelectField type="RoleRelationshipModel" label="Appraiser" name={oProps<IRealEstateModel>(formFieldName).path("appraiserId")} onSelected={onRelationshipLoaded} />
                <RelationshipSummary name={oProps<IRealEstateModel>(formFieldName).path("appraiserId")} {...dictionaries} />
            </Grid>
            <Grid item={true} sm={6}>
                <EntitySelectField name={oProps<IRealEstateModel>(formFieldName).path("ownerId")} label="Owner" onSelected={onEntityLoaded} />
                <EntitySummary name={oProps<IRealEstateModel>(formFieldName).path("ownerId")} label="Owner" countries={referenceCountries} entities={entities} />
            </Grid>
            <Grid item={true} sm={6} container={true} spacing={1}>
                <Grid item={true} sm={6}>
                    <FormTextField name={oProps<IRealEstateModel>(formFieldName).path("totalRentalArea")} label="Total Rental Area" isNumber={true} />
                </Grid>
                <Grid item={true} sm={6}>
                    <FormTextField name={oProps<IRealEstateModel>(formFieldName).path("numberOfUnits")} label="Number Of Units" isNumber={true} />
                </Grid>
                <Grid item={true} sm={6}>
                    <FormCheckBoxField name={oProps<IRealEstateModel>(formFieldName).path("isPropertyUnderDevelopment")} label="Property Under Development" canBeUndefined={true} />
                </Grid>
                <Grid item={true} sm={6}>
                    <FormCheckBoxField name={oProps<IRealEstateModel>(formFieldName).path("isPropertyForOwnUse")} label="Property For Own Use" canBeUndefined={true} />
                </Grid>
            </Grid>
            <Grid item={true} sm={6} container={true} spacing={1}>
                <Grid item={true} sm={12}>
                    <FormTextField name={oProps<IRealEstateModel>(formFieldName).path("streetAddress")} label="Street Address" />
                </Grid>
                <Grid item={true} sm={2}>
                    <FormTextField name={oProps<IRealEstateModel>(formFieldName).path("zipCode")} label="Zip Code" />
                </Grid>
                <Grid item={true} sm={5}>
                    <FormTextField name={oProps<IRealEstateModel>(formFieldName).path("location")} label="Location" />
                </Grid>
                <Grid item={true} sm={5}>
                    <CountrySelectField name={oProps<IRealEstateModel>(formFieldName).path("countryId")} label="Country" />
                </Grid>
            </Grid>
        </Grid>}
        {formValues.type === "BondModel" && <Grid container={true} spacing={1}>
            <Grid item={true} sm={4}>
                <FormSimpleSelectField name={oProps<IBondModel>(formFieldName).path("couponType")} label="Coupon Type" required={true} options={couponsTypes} />
            </Grid>
            <Grid item={true} sm={4}>
                <FormTextField name={oProps<IBondModel>(formFieldName).path("couponRate")} label="Coupon Rate" isPercentage={true} />
            </Grid>
            <Grid item={true} sm={4}>
                <FormTextField name={oProps<IBondModel>(formFieldName).path("faceValue")} label="Face Value" isNumber={true} />
            </Grid>
            <Grid item={true} sm={4}>
                <FormCheckBoxField name={oProps<IBondModel>(formFieldName).path("isPerpetual")} label="Is Perpetual" />
            </Grid>
            <Grid item={true} sm={4}>
                <FormCheckBoxField name={oProps<IBondModel>(formFieldName).path("isCallable")} label="Is Callable" />
            </Grid>
            <Grid item={true} sm={4}>
                <FormDatePickerField name={oProps<IBondModel>(formFieldName).path("nextCallDate")} label="Next Call Date" />
            </Grid>
            <Grid item={true} sm={4}>
                <FormDatePickerField name={oProps<IBondModel>(formFieldName).path("firstPaymentDate")} label="First Payment Date" />
            </Grid>
            <Grid item={true} sm={4}>
                <FormSimpleSelectField name={oProps<IBondModel>(formFieldName).path("couponFrequency")} label="Coupon Frequency" options={frequencyTypes} />
            </Grid>
            <Grid item={true} sm={4}>
                <FormDatePickerField name={oProps<IBondModel>(formFieldName).path("previousCouponDate")} label="Previous Coupon Date" />
            </Grid>
            <Grid item={true} sm={4}>
                <FormDatePickerField name={oProps<IBondModel>(formFieldName).path("nextCouponDate")} label="Next Coupon Date" />
            </Grid>
            <Grid item={true} sm={4}>
                <FormTextField name={oProps<IBondModel>(formFieldName).path("issueAmount")} label="Issue Amount" isNumber={true} />
            </Grid>
            <Grid item={true} sm={4}>
                <FormSimpleSelectField name={oProps<IBondModel>(formFieldName).path("issuerType")} label="Issuer Type" options={issuerTypes} />
            </Grid>
            <Grid item={true} sm={4}>
                <FormDatePickerField name={oProps<IBondModel>(formFieldName).path("issueDate")} label="Issue Date" />
            </Grid>
            <Grid item={true} sm={4}>
                <FormDatePickerField name={oProps<IBondModel>(formFieldName).path("maturityDate")} label="Maturity Date" />
            </Grid>
        </Grid>}

        {isDerivativeModel(formValues) && <>
            <RelationshipSelectField type="CounterpartyRelationshipModel" label="Counterparty" name={oProps<DerivativeModel>(formFieldName).path("counterpartyId")} onSelected={onRelationshipLoaded} />
            <RelationshipSummary name={oProps<DerivativeModel>(formFieldName).path("counterpartyId")} {...dictionaries} />
            <FormDatePickerField name={oProps<DerivativeModel>(formFieldName).path("maturityDate")} label="Maturity Date" required={true} />
            <FormCheckBoxField name={oProps<DerivativeModel>(formFieldName).path("isOtc")} label="OTC" />
        </>}

        {formValues.type === "FxForwardModel" && <>
            <FormTextField name={oProps<IFxForwardModel>(formFieldName).path("buyAmount")} label="Buy Amount" required={true} isNumber={true} minValue={0} />
            <FormTextField name={oProps<IFxForwardModel>(formFieldName).path("sellAmount")} label="Sell Amount" required={true} isNumber={true} minValue={0} />
            <CurrencySelectField name={oProps<IFxForwardModel>(formFieldName).path("sellCurrencyId")} label="Sell Currency" required={true} />
        </>}

        {isStandardDerivativeModel(formValues) && <>
            <FormTextField name={oProps<StandardDerivativeModel>(formFieldName).path("nominal")} label="Nominal" isNumber={true} />
            <FormTextField name={oProps<StandardDerivativeModel>(formFieldName).path("strikePrice")} label="Strike Price" isNumber={true} />
            <FormTextField name={oProps<StandardDerivativeModel>(formFieldName).path("contractSize")} label="Contract Size" isNumber={true} />
        </>}
        {/* {isStandardDerivative && <FormSimpleSelectField name={fieldof<ISecurityModel>("ca")} label="Coupon Frequency" innerProps={{ fullWidth: true, options: frequencyTypes }} />} */}

        {formValues.type === "OptionModel" && <FormSimpleSelectField name={oProps<IOptionModel>(formFieldName).path("putCall")} label="Put/Call" options={putCalls} required={true} />}
        {isOptionFutureModel(formValues) && <FormTextField name={oProps<OptionFutureModel>(formFieldName).path("underlyingIsin")} label="Underlying Isin" required={true} maxLength={12} />}

        {formValues.type === "OptionModel" && <FormSimpleSelectField name={oProps<IOptionModel>(formFieldName).path("optionType")} label="Option Type" options={optionTypes} />}
    </FieldBox>
}
