import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { HotKeys, KeyMap } from "react-hotkeys";
import { ApplicationMenu } from "./menu/ApplicationMenu";
import ApplicationBar from "./menu/ApplicationBar";
import { Box, makeStyles } from "@material-ui/core";
import { createBrowserRouter, Outlet, RouteObject, RouterProvider, useNavigate } from "react-router-dom";
import {
    buildRouteId,
    // isTabbedScreen,
    typedScreenDictionary,
    useNavigationNavigate
} from "tools/routing/screenRouteHooks";
import { isRouteErrorResponse, useLocation, useRouteError } from "react-router";
import { convertMinHeightIntoMarginTop } from "layout/App";
import { generatePath } from "react-router";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
    },
    content: {
        flexGrow: 1,
        display: "block",
        position: "relative",
        overflowY: "auto",
        overflowX: "hidden",
        padding: theme.spacing(3),
        ...convertMinHeightIntoMarginTop(theme.mixins.toolbar)
    },
}));

const keyMap: KeyMap = {
    APPLICATION_SEARCH: "ctrl+shift+f"
}

/**
 * @deprecated Compatibility layer for deprecated navigation state
 */
function useCompatibilityNavigation() {
    // Use for backward compatibility for old epics using changedNavigation
    const { shouldNavigateTo } = useReduxSelections("navigation")
    const { stopShouldNavigate } = useReduxActions("navigation")
    const navigationNavigate = useNavigationNavigate()
    useEffect(() => {
        if (shouldNavigateTo) {
            stopShouldNavigate()
            navigationNavigate(shouldNavigateTo)
        }
    }, [navigationNavigate, shouldNavigateTo, stopShouldNavigate]);
}

function AppLayout({ children }: PropsWithChildren) {
    const classes = useStyles();
    const { searchShortcut, initialUrlClear } = useReduxActions("app");
    const { initialUrl, applicationMenu } = useReduxSelections("app");
    const shortcutHandlers = useMemo(() => ({ APPLICATION_SEARCH: (_?: KeyboardEvent) => searchShortcut() }), [searchShortcut]);

    // TODO Rework the authentication flow to use react-router native action or redirect
    // https://reactrouter.com/en/main/hooks/use-navigate
    const navigate = useNavigate()
    useEffect(() => {
        if (initialUrl)
            navigate(initialUrl, { replace: true })
        initialUrlClear()
    }, [initialUrl, initialUrlClear, navigate]);

    const location = useLocation()
    useEffect(() => {
        const targetHome = applicationMenu?.home
        if (location.pathname === "/" && targetHome && !initialUrl) {
            navigate(generatePath(targetHome.urlTemplate, targetHome.params), { replace: true })
        }
    }, [applicationMenu?.home, initialUrl, location.pathname, navigate]);

    useCompatibilityNavigation();

    return <HotKeys keyMap={keyMap} handlers={shortcutHandlers} className={classes.root}>
        <ApplicationMenu />
        <ApplicationBar />
        <main className={classes.content}>
            {children}
        </main>
    </HotKeys>
}

function AppPage() {
    return <AppLayout><Outlet /></AppLayout>
}


function ErrorPage() {
    const error = useRouteError();
    console.error(error);
    return <AppLayout>
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <Box paddingTop={5}>
                {isRouteErrorResponse(error) &&
                    <p><i>{error.statusText || error.status}</i></p>
                }
                {error instanceof Error &&
                    <>
                        <p><code>{error.message}</code></p>
                        <p><code>
                            <pre>{error.stack}</pre>
                        </code></p>
                    </>}
            </Box>

        </div>
    </AppLayout>
}

function generateRoutes(): RouteObject[] {
    let rootChildren: RouteObject[] = []
    for (const [screenDictionaryKey, screen] of Object.entries(typedScreenDictionary)) {
        let route: RouteObject = {
            id: buildRouteId(screenDictionaryKey),
            path: screen.route,
            Component: screen.component,
        }

        // if (isTabbedScreen(screen)) {
        //     route.children = []
        //     for (const [tabKey, tabUrl] of Object.entries(screen.tabs)) {
        //         route.children.push({
        //             id: buildRouteId(screenDictionaryKey, tabKey),
        //             path: `${screen.route}/${tabUrl}`,
        //         })
        //     }
        // }

        rootChildren.push(route)
    }
    const rootRoute: RouteObject = {
        id: "LoadedApplication",
        path: "/",
        element: <AppPage />,
        children: rootChildren,
        errorElement: <ErrorPage />,
    }
    return [rootRoute]
}


const router = createBrowserRouter(generateRoutes());

export function LoadedApplication() {
    return <RouterProvider router={router} />
}

// TODO When we will need to dispatch in loader, move generation inside
// function LoadedApplication() {
//     const dispatch = useDispatch();
//     return <RouterProvider router={router}/>;
// }