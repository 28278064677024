import { ITenantSummaryModel } from "proxy/apiProxy";
import { useCallback, useEffect, useState } from "react";
import SearchPanel from "tools/components/SearchPanel";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { List } from "@material-ui/core";
import { TenantItem } from "layout/TenantsDialog";
import { CreateTenant } from "./CreateTenant";


export const tenantsAdministrationScreen = {
    route: "/tenantsAdministration",
    component: TenantsAdministration,
    label: "Tenants Administration"
}

export default function TenantsAdministration() {
    const { allLoading, tenantCreating, tenantsAll, tenantsImageUrls } = useReduxSelections("tenantsAdministration");
    const {tenantAdministrationCreate, tenantAdministrationLoad} = useReduxActions("tenantsAdministration");

    useEffect(() => {
        tenantAdministrationLoad()
    }, [tenantAdministrationLoad]);

    const [isNewOpened, setIsNewOpened] = useState(false);
    const handleCancel = useCallback(() => void setIsNewOpened(false), []);
    const handleAddClick = useCallback(() => setIsNewOpened(true), []);
    const handleValidate = useCallback((tenant: ITenantSummaryModel) => {
        setIsNewOpened(false);
        tenantAdministrationCreate(tenant);
    }, [tenantAdministrationCreate]);
    return <SearchPanel title="Tenants" isQuerying={allLoading || tenantCreating} onAddClick={handleAddClick}>
        <CreateTenant isOpened={isNewOpened} onValidate={handleValidate} onCancel={handleCancel} />
        <List component="nav">
            {tenantsAll.map(tenant => <TenantItem key={tenant.id} tenant={tenant} imageUrl={tenantsImageUrls[tenant.id]} />)}
        </List>
    </SearchPanel>
}


