import DetailPanel from "tools/components/DetailPanel";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import MonitoringResultGroups, { MonitoringResult } from "components/global/MonitoringResults";
import { IFeatureModel, IGetMonitoringResultGroupModel } from "proxy/apiProxy";
import { Typography } from "@material-ui/core";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";
import { useNumber, isNumber } from "tools/lib/utility";

export const dashboardScreen = {
    route: "/dashboardsResult/:id" as const,
    component: Dashboard,
    label: "Dashboard"
}

function Dashboard() {
    const { dashboardResultLoading, dashboardResult } = useReduxSelections("dashboard");
    const { dashboardMonitoringResultLoad } = useReduxActions("dashboard");

    const { id } = useScreenParams<typeof dashboardScreen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (isNumber(idNum))
            dashboardMonitoringResultLoad(idNum)
    }, [dashboardMonitoringResultLoad, idNum]);

    const isGranted = useGrants();
    if (!isGranted(IFeatureModel.MacroExecutionSetupAndDashboardExecute)) {
        return <DetailPanel title="Monitoring">
            <Typography variant={"h6"} gutterBottom={true}>You are not granted to view dashboards</Typography>
        </DetailPanel>
    }
    const handleRefresh = () => {
        if ((dashboardResult as IGetMonitoringResultGroupModel)?.dashboard?.id) {
            dashboardMonitoringResultLoad((dashboardResult as IGetMonitoringResultGroupModel).dashboard.id);
        }
    }
    if (dashboardResult && dashboardResult.monitoringResultGroups.length === 1) {
        const monitoringResult = dashboardResult.monitoringResultGroups[0];
        return <DetailPanel
            isQuerying={dashboardResultLoading || !dashboardResult}
            title={dashboardResult?.dashboard?.name ?? "Monitoring"}
            subTitle={monitoringResult.name}
            onRefreshClick={handleRefresh}>
            <MonitoringResult result={monitoringResult.result} />
        </DetailPanel>
    }
    else
        return <DetailPanel
            isQuerying={dashboardResultLoading || !dashboardResult}
            title={dashboardResult?.dashboard?.name ?? "Monitoring"}
            onRefreshClick={handleRefresh}>
            {dashboardResult && <MonitoringResultGroups resultGroups={dashboardResult.monitoringResultGroups} />}
        </DetailPanel>
}
