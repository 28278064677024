import * as React from "react";
import { Typography, Badge, Box } from "@material-ui/core";
import { Theme, withStyles, createStyles } from "@material-ui/core/styles";
import { DataType } from 'csstype';
import styled from '@emotion/styled';

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            right: -8,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: "0 4px"
        }
    })
)(Badge);

const ValueContainer = withStyles(theme =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&>:first-child": {
                marginRight: theme.spacing(1)
            },
        }
    })
)(Box);

const StyledTypography = withStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.disabled,
            fontWeight: "normal"
        }
    })
)(Typography);

const StyledBox = styled(Box)(({ noUnderline, width }: { noUnderline?: boolean, width?: number }) => {
    if (!noUnderline) {
        return {
            borderBottom: 1,
            borderBottomColor: "rgba(0, 0, 0, 0.42)",
            borderBottomStyle: "solid",
            overflowX: "hidden",
            width
        }
    }
    return {
        overflowX: "hidden",
        width
    }
});

export interface ISummaryFieldProps {
    label: React.ReactNode;
    value: React.ReactNode | undefined | null;
    bold?: boolean;
    italic?: boolean;
    error?: boolean;
    badge?: React.ReactNode;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    noUnderline?: boolean;
    rightAlign?: boolean;
    color?: DataType.Color;
    breakAll?: boolean;
    foregroundColor?: string;
    backgroundColor?: string;
    width?: number;
}

export function SummaryField(props: ISummaryFieldProps) {
    const {
        label,
        value,
        error,
        noUnderline = false,
        width
    } = props;
    if (typeof value === "undefined" || value === null) {
        return <></>;
    }
    return <SummaryContainer label={label} error={error} noUnderline={noUnderline} color={props.foregroundColor} backgroundColor={props.backgroundColor} width={width}>
        <InnerSummaryField {...props} />
    </SummaryContainer>
}
export interface ISummaryContainerProps {
    label: React.ReactNode;
    children: React.ReactNode;
    noUnderline?: boolean;
    error?: boolean;
    color?: string;
    backgroundColor?: string;
    width?: number,
}
export function SummaryContainer({ label, children, noUnderline, error, color, backgroundColor, width }: ISummaryContainerProps) {
    return <StyledBox noUnderline={noUnderline} width={width}>
        <Typography variant="caption" color="textSecondary"
            style={{ color: error ? "red" : color, backgroundColor: backgroundColor }}
            gutterBottom={true}>
            {label}
        </Typography>
        {children}
    </StyledBox>
}

export interface IInnerSummaryFieldProps {
    value: React.ReactNode | undefined | null;
    error?: boolean;
    badge?: React.ReactNode;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    rightAlign?: boolean;
    color?: DataType.Color;
    noPadding?: boolean;
    breakAll?: boolean;
    backgroundColor?: string;
    bold?: boolean;
    italic?: boolean;
}

export function InnerSummaryField({
    value,
    error,
    badge,
    endAdornment,
    startAdornment,
    rightAlign = false,
    color,
    noPadding = false,
    breakAll = false,
    backgroundColor,
    italic,
    bold
}: IInnerSummaryFieldProps) {
    const textStyle: React.CSSProperties = React.useMemo(() => ({
        color: error ? "red" : color,
        backgroundColor: backgroundColor,
        wordBreak: breakAll ? "break-all" : undefined,
        fontWeight: bold ? "bold" : undefined,
        fontStyle: italic ? "italic" : undefined
    }), [backgroundColor, bold, breakAll, color, error, italic])
    const noPaddingStyle: React.CSSProperties = React.useMemo(() => ({
        marginBottom: 0,
        marginTop: 0,
    }), []);
    const combinedTextStyle = React.useMemo(() => noPadding
        ? { ...textStyle, ...noPaddingStyle }
        : { ...textStyle }, [noPadding, noPaddingStyle, textStyle])

    return <ValueContainer>
        {!!startAdornment && <StyledTypography>{startAdornment}</StyledTypography>}
        {!!rightAlign && <Box flexGrow={1}>&nbsp;</Box>}
        {!!badge ? (
            <StyledBadge badgeContent={badge} color="primary" max={9999999999}>
                <Typography variant="body1" style={combinedTextStyle} gutterBottom={true}>
                    {value}
                </Typography>
            </StyledBadge>
        ) : (
            <Typography variant="body1" style={combinedTextStyle} gutterBottom={true}>
                {value}
            </Typography>
        )}
        {!rightAlign && <Box flexGrow={1}>&nbsp;</Box>}
        {!!endAdornment && <StyledTypography>{endAdornment}</StyledTypography>}
    </ValueContainer>
}