export type AbsoluteReference = `@.${string}`;
export type RelativeReference = `.${string}`;
export type SelectionReference = `$.${string}`;
export type ExpressionReference = `#.${string}`;
export type Color = string;
export type FieldSorting = RelativeReference | `${RelativeReference} desc` | ExpressionReference;
export type EqualityFilter = `${RelativeReference | AbsoluteReference | SelectionReference}=${AbsoluteReference | RelativeReference | SelectionReference}`;
export type NonStrictInclusionFilter = `${RelativeReference} in ${SelectionReference | AbsoluteReference}`;
export type StrictInclusionFilter = `${RelativeReference} ins ${SelectionReference | AbsoluteReference}`;
export type Filter = EqualityFilter | StrictInclusionFilter | NonStrictInclusionFilter | ExpressionReference;
export type BindableType<T> = RelativeReference | AbsoluteReference | ExpressionReference | T;
export type ColorDefinition = BindableType<Color> | { value: number, color: BindableType<Color> }[];

export interface IGridContainerDisplay {
    templateColumns?: string;
    templateRows?: string;
    fullHeight?: boolean;
    templateAreas?: string[][];
    columnGap?: number;
    rowGap?: number;
    justifyItems?: "start" | "end" | "center" | "stretch";
    justifyContent?: "start" | "end" | "center" | "space-around" | "space-between" | "space-evenly" | "stretch";
    alignItems?: "start" | "end" | "center" | "stretch";
    alignContent?: "start" | "end" | "center" | "stretch" | "space-around" | "space-between" | "space-evenly";
    autoColumns?: number;
    autoRows?: number;
    autoFlow?: "row" | "column" | "row dense" | "column dense";
}
export interface ITabContainerDisplay {
    fullHeight?: boolean;
}
export interface IGridItemDisplay {
    column?: string;
    row?: string;
    area?: string;
    justifySelf?: "start" | "end" | "center" | "stretch";
    alignSelf?: "start" | "end" | "center" | "stretch";
}

export interface IFlexContainerDisplay {
    fullHeight?: boolean;
    direction?: "row" | "row-reverse" | "column" | "column-reverse";
    wrap?: "nowrap" | "wrap" | "wrap-reverse";
    justifyContent?: "flex-start" | "flex-end" | "center" | "space-around" | "space-between" | "space-evenly";
    alignItems?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
    alignContent?: "flex-start" | "flex-end" | "center" | "stretch" | "space-around" | "space-between" | "space-evenly";
    gap?: number;
    // columnGap?: number;
    // rowGap?: number;
}
export interface IFlexItemDisplay {
    grow?: number;
    shrink?: number;
    basis?: string;
    alignSelf?: "auto" | "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
}
// export type ContainerDisplay = ContainerDisplay;
export type ItemDisplay = IGridItemDisplay | IFlexItemDisplay;
export type ContainerDisplay = IGridContainerDisplay | IFlexContainerDisplay | ITabContainerDisplay;
export type ItemControl<TItemDisplay extends ItemDisplay> = FieldControl<TItemDisplay> | Container<TItemDisplay> | ArrayControl<TItemDisplay>;
export interface IContainer<TContainerDisplay extends ContainerDisplay, TItemDisplay extends ItemDisplay> extends IControlBase<TItemDisplay> {
    containerLayout?: TContainerDisplay;
    collapsed?: BindableType<boolean>;
}

export interface ITabsContainer<TItemDisplay extends ItemDisplay> extends IControlBase<TItemDisplay>, IRelativeContext {
    type: "tabsContainer";
    items: (Container<IFlexItemDisplay> | ArrayControl<IFlexItemDisplay>)[];
    collapsed?: BindableType<boolean>;
    containerLayout?: ITabContainerDisplay;
}
export interface IFlexContainer<TItemDisplay extends ItemDisplay> extends IContainer<IFlexContainerDisplay, TItemDisplay>, IRelativeContext {
    type: "flexContainer";
    items: (FieldControl<IFlexItemDisplay> | Container<IFlexItemDisplay> | ArrayControl<IFlexItemDisplay>)[];
}
export interface IGridContainer<TItemDisplay extends ItemDisplay> extends IContainer<IGridContainerDisplay, TItemDisplay>, IRelativeContext {
    type: "gridContainer";
    items: (FieldControl<IGridItemDisplay> | Container<IGridItemDisplay> | ArrayControl<IGridItemDisplay>)[];
}
export interface IFlexDetailContainer<TItemDisplay extends ItemDisplay> extends IContainer<IFlexContainerDisplay, TItemDisplay>, IArrayRelativeContext {
    type: "flexDetailContainer";
    items: Container<IFlexItemDisplay>;
}
export interface IGridDetailContainer<TItemDisplay extends ItemDisplay> extends IContainer<IGridContainerDisplay, TItemDisplay>, IArrayRelativeContext {
    type: "gridDetailContainer";
    items: Container<IGridItemDisplay>;
}
export interface IDirectedGraphDetailContainer<TItemDisplay extends ItemDisplay> extends IContainer<IFlexContainerDisplay, TItemDisplay>, IArrayRelativeContext {
    type: "directedGraphDetailContainer";
    items: BindableType<string> | FieldControl<never> | Container<never> | ArrayControl<never>;
    children: BindableType<(string | number)[]>;
    itemsLayout: IGraphItemLayout;
    rowKey?: RelativeReference;
    targetSelection?: SelectionReference;
}
export interface IGraphItemLayout {
    width: BindableType<number>;
    height?: BindableType<number>;
}
export interface IHierarchyGraphDetailContainer<TItemDisplay extends ItemDisplay> extends IContainer<IGridContainerDisplay, TItemDisplay>, IArrayRelativeContext {
    type: "hierarchyGraphDetailContainer";
    items: BindableType<string> | FieldControl<never> | Container<never> | ArrayControl<never>;
    itemsLayout: IGraphItemLayout;
    parent: BindableType<string | number>;
    rowKey?: RelativeReference;
    targetSelection?: SelectionReference;
}
// export type IItem<TItemDisplay> = { subPath: string[], layout: TItemDisplay, } | string | string[];
export type Container<TItemDisplay extends ItemDisplay> = IGridContainer<TItemDisplay> | IFlexContainer<TItemDisplay> | IGridDetailContainer<TItemDisplay> | IFlexDetailContainer<TItemDisplay> | IDirectedGraphDetailContainer<TItemDisplay> | IHierarchyGraphDetailContainer<TItemDisplay> | ITabsContainer<TItemDisplay>;

export type DashboardLayout = Pick<IGridContainer<never>, "type" | "containerLayout" | "items"> | Pick<IFlexContainer<never>, "type" | "containerLayout" | "items"> | Pick<ITabsContainer<never>, "type" | "items">;





// export type BindableType<T> = `@${string}` | T;

export interface IControlBase<TItemDisplay extends ItemDisplay> {
    itemLayout?: TItemDisplay;
    label?: BindableType<string>;
    hidden?: BindableType<boolean>;
    visible?: BindableType<boolean>;
    disabled?: BindableType<boolean>;
    enabled?: BindableType<boolean>;
}
export interface IFieldControlBase<TItemDisplay extends ItemDisplay, T> extends IControlBase<TItemDisplay> {
    value: BindableType<T>;
    backgroundColor?: ColorDefinition;
    foregroundColor?: ColorDefinition;
    foregroundColorRatio?: BindableType<number>;
    backgroundColorRatio?: BindableType<number>;
    width?: number;
    italic?: BindableType<boolean>;
    bold?: BindableType<boolean>;
}
export interface NumberFieldControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, number> {
    type: "number" | "percentage";
    format?: "string";
    precision?: number;
    humanize?: boolean;
    suffix?: BindableType<string>;
}
export interface DateTimeFieldControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, Date> {
    type: "date" | "dateTime";
    format?: "string";
}
export interface StringFieldControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, string> {
    type: "string";
}
export interface TitleFieldControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, string> {
    type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "button" | "caption" | "subtitle1" | "subtitle2" | "body1" | "body2";
}
export interface IconFieldControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, string> {
    type: "icon";
}
export interface BooleanFieldControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, boolean> {
    type: "boolean";
}
export interface MarkdownFieldControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, string> {
    type: "markdown";
}
export interface ReportLinkControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, string>, IExecutionParameters {
    type: "linkToReport";
    templateName: BindableType<string>;
    datasetName: BindableType<string>;
    fileType?: BindableType<"pdf" | "docx" | "xlsx" | "xls">,
}
export interface DashboardLinkControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, string>, IExecutionParameters {
    type: "linkToDashboard";
    layoutName: string;
    datasetName: BindableType<string>;
    screenTitle?: BindableType<string>;
}
export interface ScreenLinkControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, string> {
    type: "linkToScreen";
    target: BindableType<ScreenLinkType>;
    tab?: BindableType<string>;
    parameters?: Record<string, BindableType<string>>;
}
export interface ExecuteEtlControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, string>, IExecutionParameters {
    type: "executeEtl";
    inputConnector?: BindableType<string>;
    etlName: BindableType<string>;
    noDownload?: BindableType<boolean>;
}
export interface QuestionnaireControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, string>, IExecutionParameters {
    type: "questionnaire";
    questionnaireName: BindableType<string>;
    getPreviousAnswers?: BindableType<boolean>;
}
export interface ProcessTaskQuestionnaireControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, string> {
    type: "processTaskQuestionnaire";
    taskCode: BindableType<string>;
    processExecutionId: BindableType<number>;
}
export interface ProcessTaskFileControl<TItemDisplay extends ItemDisplay> extends IFieldControlBase<TItemDisplay, string> {
    type: "processTaskFile";
    taskCode: BindableType<string>;
    currentFileName?: BindableType<string>;
    processExecutionId: BindableType<number>;
}
export interface IExecutionParameters {
    target?: {
        taskCode?: BindableType<string>;
        entityCode?: BindableType<string>;
        entityId?: BindableType<number>;
        portfolioCode?: BindableType<string>;
        portfolioId?: BindableType<number>;
        securityCode?: BindableType<string>;
        securityId?: BindableType<number>;
        processExecutionId?: BindableType<number>;
        relationshipId?: BindableType<number>;
    };
    parameters?: Record<string, BindableType<string>>;
    culture?: BindableType<string>;
    asOfDate?: BindableType<Date> | BindableType<number>

}
export enum ScreenLinkType {
    Person = "person",
    People = "people",
    Company = "company",
    Companies = "companies",
    EntityGroup = "entityGroup",
    EntityGroups = "entityGroups",
    ManagedSicav = "managedSicav",
    ManagedSicavs = "managedSicavs",
    Portfolio = "portfolio",
    Portfolios = "portfolios",
    ShareClass = "shareClass",
    ShareClasses = "shareClasses",
    Sicav = "sicav",
    Sicavs = "sicavs",
    SubFund = "subFund",
    SubFunds = "subFunds",
    Security = "security",
    Securities = "securities",
    Investor = "investor",
    Investors = "investors",
    Role = "role",
    Roles = "roles",
    Counterparty = "counterparty",
    Counterparties = "counterparties",
    Index = "index",
    Indexes = "indexes",
    Movement = "movement",
    Movements = "movements",
    PortfolioTransaction = "portfolioTransaction",
    PortfolioTransactions = "portfolioTransactions",
    BankAccount = "bankAccount",
    BankAccounts = "bankAccounts",
    ProcessExecution = "processExecution",
    ProcessExecutions = "processExecutions",
    Dashboard = "dashboard",
    Loan = "loan",
    Document = "document",
}

export type FieldControl<TItemDisplay extends ItemDisplay> =
    NumberFieldControl<TItemDisplay>
    | DateTimeFieldControl<TItemDisplay>
    | StringFieldControl<TItemDisplay>
    | BooleanFieldControl<TItemDisplay>
    | MarkdownFieldControl<TItemDisplay>
    | DashboardLinkControl<TItemDisplay>
    | ReportLinkControl<TItemDisplay>
    | ScreenLinkControl<TItemDisplay>
    | ExecuteEtlControl<TItemDisplay>
    | QuestionnaireControl<TItemDisplay>
    | ProcessTaskQuestionnaireControl<TItemDisplay>
    | ProcessTaskFileControl<TItemDisplay>
    | IconFieldControl<TItemDisplay>
    | TitleFieldControl<TItemDisplay>;

export interface IRelativeContext {
    context?: BindableType<object>;
}
export interface IArrayRelativeContext {
    context?: BindableType<object[]>;
    sorts?: FieldSorting[] | FieldSorting;
    valuesFilter?: Filter;
}
export interface IArrayControlBase<TItemDisplay extends ItemDisplay> extends IControlBase<TItemDisplay>, IArrayRelativeContext {
    rowKey?: RelativeReference;
    targetSelection?: SelectionReference;
    multiSelect?: boolean;
}
export interface IGridArrayControl<TItemDisplay extends ItemDisplay> extends IArrayControlBase<TItemDisplay> {
    type: "dataGrid";
    fixedLeft?: BindableType<string>[] | BindableType<string> | number,
    fixedRight?: BindableType<string>[] | BindableType<string> | number,
    items: FieldControl<never>[];
    sorts?: FieldSorting[] | FieldSorting;
    groups?: RelativeReference[] | RelativeReference;
}
export interface ITableArrayControl<TItemDisplay extends ItemDisplay> extends IArrayControlBase<TItemDisplay> {
    type: "dataTable";
    hideHeader?: boolean;
    items: (FieldControl<never> | (Container<never> & { width: number, alignment?: "left" | "center" | "right" }))[];
    sorts?: FieldSorting[] | FieldSorting;
}
export interface ISlimContainer<TContainerDisplay extends ContainerDisplay, TSubItemDisplay extends ItemDisplay> {
    containerLayout?: TContainerDisplay;
    items: (FieldControl<TSubItemDisplay> | Container<TSubItemDisplay> | ArrayControl<TSubItemDisplay>)[];
    collapsed?: BindableType<boolean>;
}
// export interface IFlexSlimContainer extends ISlimContainer<IFlexContainerDisplay, IFlexItemDisplay> {
//     type: "flexContainer";
// }
// export interface IGridSlimContainer extends ISlimContainer<IGridContainerDisplay, IGridItemDisplay> {
//     type: "gridContainer";
// }

// export type SlimContainer = IGridSlimContainer | IFlexSlimContainer;

// export interface IDetailArrayControl<TItemDisplay extends ItemDisplay> extends IArrayControlBase<TItemDisplay> {
//     type: "details";
//     sorts?: FieldSorting[] | FieldSorting;
//     items: SlimContainer;
// }
export interface IPieArrayControl<TItemDisplay extends ItemDisplay> extends IArrayControlBase<TItemDisplay> {
    type: "pie";
    pivots?: RelativeReference | RelativeReference[];
    labels?: RelativeReference;
    precision?: number;
    isPercentage?: boolean;
    measures: RelativeReference | RelativeReference[];
}
export type ArrayControl<TItemDisplay extends ItemDisplay> = IGridArrayControl<TItemDisplay> | ITableArrayControl<TItemDisplay> | IPieArrayControl<TItemDisplay>;
