import produce from "immer";
import { ICompanySummaryModel, ITenantSummaryModel } from "proxy/apiProxy"
import { produceActionFactories, AnyActionOf } from "tools/lib/store";

export interface ITenantImageLoadedPayload {
    tenantId: number;
    imageUrl?: string;
}
export const ActionFactories = produceActionFactories({
    tenantAdministrationLoad: () => undefined,
    tenantAdministrationLoaded: (payload: ICompanySummaryModel[]) => payload,
    tenantAdministrationImageLoaded: (payload: ITenantImageLoadedPayload) => payload,
    tenantAdministrationCreate: (payload: ITenantSummaryModel) => payload,
    tenantAdministrationCreated: (payload: ICompanySummaryModel) => payload,
});

export interface IState {
    tenantsAll: ICompanySummaryModel[];
    tenantsImageUrls: Record<number, string>;
    tenantCreating: boolean;
    allLoading: boolean;
}
export type Action = AnyActionOf<typeof ActionFactories>;

export function reducer(
    state: IState = {
        tenantsImageUrls: {},
        tenantCreating: false,
        allLoading: false,
        tenantsAll: []
    },
    action: Action
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "tenantAdministrationCreate":
                draft.tenantCreating = true;
                break;
            case "tenantAdministrationCreated":
                draft.tenantsAll.push(action.payload);
                draft.tenantCreating = false;
                break;
            case "tenantAdministrationLoad":
                draft.allLoading = true;
                break;
            case "tenantAdministrationLoaded":
                draft.allLoading = false;
                draft.tenantsAll = action.payload;
                break;
            case "tenantAdministrationImageLoaded":
                if (action.payload.imageUrl) draft.tenantsImageUrls[action.payload.tenantId] = action.payload.imageUrl;
                else delete draft.tenantsImageUrls[action.payload.tenantId];
                break;
        }
    });
}
