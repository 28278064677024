import { KeyboardDatePicker } from '@material-ui/pickers';
import { DateType } from "@date-io/type";
import { LocaleDateFormat, isValidDate } from 'tools/lib/utility';
import { useFieldEx, IFormField, ValidatorParams } from 'tools/lib/fieldHelpers';
import { ReadOnlyContext } from "./ReadOnlyContext";

const validators = {
    required: (v: DateType | undefined, isTrue?: boolean) => isTrue && !v ? `Required` : undefined,
    minValue: (v: DateType | undefined, min?: DateType) => typeof (min) !== "undefined" && v && isValidDate(v) && v < min ? `Must be after ${min}` : undefined,
    maxValue: (v: DateType | undefined, max?: DateType) => typeof (max) !== "undefined" && v && isValidDate(v) && v > max ? `Must be before ${max}` : undefined,
    disabledDates: (v: DateType | undefined, shouldDisableDate: (date: DateType) => boolean) => undefined
}

export interface IFormDatePickerFieldProps extends IFormField, ValidatorParams<typeof validators> {
    fullWidth?: boolean;
    disableToolbar?: boolean;
}

export default function FormDatePickerField({ name, helperText, label, disabled, error, disableToolbar, fullWidth = true, ...requestedValidations }: IFormDatePickerFieldProps) {
    const { error: fieldError, helperText: fieldHelperText, label: fieldLabel, onValueChanged, value } = useFieldEx<DateType, typeof validators>({ name, helperText, label, required: requestedValidations.required, error, validators, requestedValidations });
    const onChange = (date: DateType | null, _?: string | null) => {
        if (date) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        }
        if (!date || isValidDate(date)) {
            onValueChanged(date ?? undefined);
        }
    };
    const handleShouldDisableDate = !requestedValidations.disabledDates ? undefined : (date: DateType | null) => {
        if (!requestedValidations?.disabledDates) {
            return false;
        }
        return date ? requestedValidations.disabledDates(date) : true;
    }
    return <ReadOnlyContext.Consumer>
        {readOnly => <KeyboardDatePicker
            id={name}
            readOnly={readOnly}
            disableToolbar={disableToolbar}
            fullWidth={fullWidth}
            autoOk={true}
            placeholder={LocaleDateFormat}
            format={LocaleDateFormat}
            animateYearScrolling={true}
            error={fieldError}
            helperText={fieldHelperText}
            clearable={!requestedValidations.required}
            showTodayButton={true}
            margin="dense"
            shouldDisableDate={handleShouldDisableDate}
            minDate={requestedValidations.minValue}
            maxDate={requestedValidations.maxValue}
            disabled={disabled || readOnly}
            label={fieldLabel}
            value={(value ?? "") === "" ? null : value ?? null}
            onChange={onChange} />}
    </ReadOnlyContext.Consumer>
}
