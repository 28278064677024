import DialogPanel from "tools/components/DialogPanel";
import { Formik, FormikHelpers, FormikProps } from "formik";
import FieldBox from "tools/components/FieldBox";
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";
import { oProps } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import { ITreeItemModel } from "./treeTypes";

export interface IClassificationProps {
    classification?: ITreeItemModel;
    onClassificationChanged: (classification: ITreeItemModel) => void;
    onCancel: () => void;
}

interface IFormValues {
    code: string;
    name: { [key: string]: string; };
    description?: { [key: string]: string; };
}
const emptyClassification = { code: "", name: {}, id: 0, classifications: [] };
export default function ClassificationDialog({ onCancel, classification, onClassificationChanged }: IClassificationProps) {
    const handleSubmit = (values: ITreeItemModel, { setSubmitting }: FormikHelpers<ITreeItemModel>) => {
        onClassificationChanged({ ...classification, ...values });
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={classification ?? emptyClassification} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<ITreeItemModel>) {
        return <DialogPanel
            onBackClick={onCancel}
            title="Classification"
            isOpened={!!classification}
            actions={[{
                disabled: !isValid || !dirty,
                label: "Ok",
                onClick: submitForm
            }]}>
            <FieldBox display="flex" flexDirection="column">
                <FormTextField name={oProps<IFormValues>().path("code")} label="Code" required={true} />
                <FormMultiCulturedTextField name={oProps<IFormValues>().path("name")} label="Name" required={["en"]} />
                <FormMultiCulturedTextField name={oProps<IFormValues>().path("description")} label="Description" multiline={true} />
            </FieldBox>
        </DialogPanel>;
    }
}
