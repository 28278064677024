import * as React from "react";
import { IDocumentAllRelatedModel, IEntityTypeModel, IPortfolioTypeModel, ISecurityTypeModel, RelationshipModel } from "proxy/apiProxy";
import { useField } from 'formik';
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { toDictionary } from "tools/lib/utility";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';
import { getEntityName } from "tools/lib/modelUtils";
import { toLower } from "lodash";
import { isEntityScreenLinkType, isRelationshipScreenLinkType, isSecurityScreenLinkType, isPortfolioScreenLinkType, ScreenLinkType } from "tools/objectViewer/DashboardFormattingContracts";
import { Box, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import AsyncMultiSelect from "tools/components/AsyncMultiSelect";
import { GetAllSummaries, toTitleCase } from "../utils";

export interface IMultiSelectRelatedFieldProps {
    name: string;
    required?: boolean;
    onSelected?: (found: GetAllSummaries) => void;
    dictionaries: GetAllSummaries;
}

function useToDictionarySingleInput<T extends { id: number }>(result: T[]): { [key: number]: T } {
    return React.useMemo(() => toDictionary(result, i => i.id), [result]);
}

function useToArrayIdsSingleInput<T extends { id: number }>(result: T[]): number[] {
    return React.useMemo(() => result.map(i => i.id), [result]);
}

export default function MultiSelectRelatedField({
    name,
    required,
    onSelected,
    dictionaries }: IMultiSelectRelatedFieldProps) {
    const {
        referenceManagedPortfolioSearch, referenceSecuritySearch,
        referenceEntitySearch, referenceRelationshipSearch,
        referencePortfolioTransactionSearch, referenceCashMovementSearch
    } = useReduxActions("reference");

    const {
        referenceManagedPortfolioSearching, referenceManagedPortfoliosSearched,
        referenceSecuritySearching, referenceSecuritiesSearched,
        referenceEntitySearching, referenceEntitiesSearched,
        referenceRelationshipSearching, referenceRelationshipsSearched,
        referencePortfolioTransactionSearching, referencePortfolioTransactionsSearched,
        referenceCashMovementSearching, referenceCashMovementsSearched,
    } = useReduxSelections("reference");

    const foundElements: GetAllSummaries = {
        portfolios: useToDictionarySingleInput(referenceManagedPortfoliosSearched.portfolios),
        entities: useToDictionarySingleInput(referenceEntitiesSearched.all),
        securities: useToDictionarySingleInput(referenceSecuritiesSearched.securities),
        relationships: useToDictionarySingleInput(referenceRelationshipsSearched.all),
        transactions: useToDictionarySingleInput(referencePortfolioTransactionsSearched.transactions),
        cashMovements: useToDictionarySingleInput(referenceCashMovementsSearched.cashMovements)
    };

    const foundGetAllSummaryIds: IDocumentAllRelatedModel = {
        portfolioIds: useToArrayIdsSingleInput(referenceManagedPortfoliosSearched.portfolios),
        entityIds: useToArrayIdsSingleInput(referenceEntitiesSearched.all),
        securityIds: useToArrayIdsSingleInput(referenceSecuritiesSearched.securities),
        relationshipIds: useToArrayIdsSingleInput(referenceRelationshipsSearched.all),
        transactionIds: useToArrayIdsSingleInput(referencePortfolioTransactionsSearched.transactions),
        cashMovementIds: useToArrayIdsSingleInput(referenceCashMovementsSearched.cashMovements)
    };

    const emptyRelatedModel: IDocumentAllRelatedModel = {
        securityIds: [],
        entityIds: [],
        portfolioIds: [],
        relationshipIds: [],
        transactionIds: [],
        cashMovementIds: []
    };

    const validate = (elementId: number[] | undefined) => {
        if (!required) return;
        return (elementId?.length ?? 0) === 0 ? "At least one required" : undefined;
    }

    const [, { value = emptyRelatedModel, error, initialValue }, { setValue }] = useField<IDocumentAllRelatedModel | undefined>({ name, validate });

    const [type, setType] = React.useState<ScreenLinkType>(ScreenLinkType.Person);

    const handleGetOptionLabel = (fromSearchResults: boolean, option: number): string => {
        const defaultLabel = "...";

        if (isEntityScreenLinkType(type)) {
            const entity = (fromSearchResults ? foundElements.entities : dictionaries.entities)[option];
            return getEntityName(entity) ?? defaultLabel;
        }
        else if (isRelationshipScreenLinkType(type)) {
            const relationship = (fromSearchResults ? foundElements.relationships : dictionaries.relationships)[option];
            return `${getEntityName(dictionaries.entities[relationship.entityId])} as ${relationship?.title}`;
        }
        else if (isSecurityScreenLinkType(type)) {
            const security = (fromSearchResults ? foundElements.securities : dictionaries.securities)[option];
            return security?.name ?? defaultLabel;
        }
        else if (isPortfolioScreenLinkType(type)) {
            return (fromSearchResults ? foundElements.portfolios : dictionaries.portfolios)[option]?.name ?? defaultLabel;
        }
        else if (type === ScreenLinkType.Movement) {
            const cashMovement = (fromSearchResults ? foundElements.cashMovements : dictionaries.cashMovements)[option];
            return cashMovement?.movementCode ?? defaultLabel;
        }
        else if (type === ScreenLinkType.PortfolioTransaction) {
            const transaction = (fromSearchResults ? foundElements.transactions : dictionaries.transactions)[option];
            return transaction?.transactionCode ?? defaultLabel;
        }
        return defaultLabel;
    }

    const handleGetOptionSubLabel = (fromSearchResults: boolean, option: number): string => {
        const defaultLabel = "";

        if (isEntityScreenLinkType(type)) {
            const entity = (fromSearchResults ? foundElements.entities : dictionaries.entities)[option];
            return entity?.internalCode ?? defaultLabel;
        }
        else if (isRelationshipScreenLinkType(type)) {
            const relationship = (fromSearchResults ? foundElements.relationships : dictionaries.relationships)[option];
            return relationship?.title ?? defaultLabel;
        }
        else if (isSecurityScreenLinkType(type)) {
            const security = (fromSearchResults ? foundElements.securities : dictionaries.securities)[option];
            return security?.internalCode ?? defaultLabel;
        }
        else if (isPortfolioScreenLinkType(type)) {
            const portfolio = (fromSearchResults ? foundElements.portfolios : dictionaries.portfolios)[option];
            return portfolio?.internalCode ?? defaultLabel;
        }
        return defaultLabel;
    }

    const handleGetLabel = () => {
        const titleCasedType = toTitleCase(type);
        const label = <><SearchIcon fontSize="small" /> {titleCasedType}: Type criteria to find a {toLower(titleCasedType)} {required ? "*" : ""}</>;
        if ((initialValue || null) !== (value || null)) {
            return <b>{label}</b>;
        }
        return label;
    }

    const handleValueSelected = (elementIds: number[]) => {
        if (onSelected === undefined) return;

        const typeSummaryModel = getSummaryModel(type);

        if (isEntityScreenLinkType(type)) {
            const otherEntities = value.entityIds.filter(id => dictionaries.entities[id]?.type !== typeSummaryModel);
            elementIds = elementIds.concat(otherEntities);
            onSelected({
                entities: toDictionary(elementIds.map(id => foundElements.entities[id]).filter(i => !!i), i => i.id),
                portfolios: {},
                securities: {},
                relationships: {},
                transactions: {},
                cashMovements: {}
            });
            setValue({ ...value, entityIds: elementIds });
        }
        else if (isRelationshipScreenLinkType(type)) {
            const otherRelationships = value.relationshipIds.filter(id => dictionaries.relationships[id]?.type !== typeSummaryModel);
            elementIds = elementIds.concat(otherRelationships);
            onSelected({
                entities: {},
                portfolios: {},
                securities: {},
                relationships: toDictionary(elementIds.map(id => foundElements.relationships[id]).filter(i => !!i), i => i.id),
                transactions: {},
                cashMovements: {}
            });
            setValue({ ...value, relationshipIds: elementIds });
        }
        else if (isSecurityScreenLinkType(type)) {
            if (type !== ScreenLinkType.Security) {
                const otherSecurities = value.securityIds.filter(id => dictionaries.securities[id]?.type !== typeSummaryModel);
                elementIds = elementIds.concat(otherSecurities);
            }

            onSelected({
                entities: {},
                portfolios: {},
                securities: toDictionary(elementIds.map(id => foundElements.securities[id]).filter(i => !!i), i => i.id),
                relationships: {},
                transactions: {},
                cashMovements: {}
            });
            setValue({ ...value, securityIds: elementIds });
        }
        else if (isPortfolioScreenLinkType(type)) {
            const otherPortfolios = value.portfolioIds.filter(id => dictionaries.portfolios[id]?.type !== typeSummaryModel);
            elementIds = elementIds.concat(otherPortfolios);
            onSelected({
                entities: {},
                portfolios: toDictionary(elementIds.map(id => foundElements.portfolios[id]).filter(i => !!i), i => i.id),
                securities: {},
                relationships: {},
                transactions: {},
                cashMovements: {}
            });
            setValue({ ...value, portfolioIds: elementIds });
        }
        else if (type === ScreenLinkType.Movement) {
            onSelected({
                entities: {},
                portfolios: {},
                securities: {},
                relationships: {},
                transactions: {},
                cashMovements: toDictionary(elementIds.map(id => foundElements.cashMovements[id]).filter(i => !!i), i => i.id)
            });
            setValue({ ...value, cashMovementIds: elementIds });
        }
        else if (type === ScreenLinkType.PortfolioTransaction) {
            onSelected({
                entities: {},
                portfolios: {},
                securities: {},
                relationships: {},
                transactions: toDictionary(elementIds.map(id => foundElements.transactions[id]).filter(i => !!i), i => i.id),
                cashMovements: {}
            });
            setValue({ ...value, transactionIds: elementIds });
        }
    }

    const handleOnSearchOptions = (criterias: string) => {
        if (!criterias.length) return;

        if (isPortfolioScreenLinkType(type)) {
            referenceManagedPortfolioSearch({ criterias, type: screenTypeToPortfolioType(type) });
        }
        else if (isSecurityScreenLinkType(type)) {
            switch (type) {
                case ScreenLinkType.Loan:
                    referenceSecuritySearch({ criterias, type: ISecurityTypeModel.Loan });
                    break;
                case ScreenLinkType.Cash:
                    referenceSecuritySearch({ criterias, type: ISecurityTypeModel.Cash });
                    break;
                default:
                    referenceSecuritySearch({ criterias });
                    break;
            }
        }
        else if (isEntityScreenLinkType(type)) {
            referenceEntitySearch({ criterias, type: screenTypeToEntityType(type) });
        }
        else if (isRelationshipScreenLinkType(type)) {
            referenceRelationshipSearch({ criterias, type: screenTypeToRelationshipType(type) });
        }
        else if (type === ScreenLinkType.PortfolioTransaction) {
            referencePortfolioTransactionSearch({ criterias, fxRelated: true, securityRelated: true });
        }
        else if (type === ScreenLinkType.Movement) {
            referenceCashMovementSearch({ criterias });
        }
    }

    const getOptions = (): number[] => {
        const typeSummaryModel = getSummaryModel(type);

        if (isEntityScreenLinkType(type)) {
            return foundGetAllSummaryIds.entityIds.filter(id => foundElements.entities[id]?.type === typeSummaryModel
                && !value.entityIds.includes(id));
        }
        else if (isRelationshipScreenLinkType(type)) {
            return foundGetAllSummaryIds.relationshipIds.filter(id => foundElements.relationships[id]?.type === typeSummaryModel
                && !value.relationshipIds.includes(id));
        }
        else if (isSecurityScreenLinkType(type)) {
            switch (type) {
                case ScreenLinkType.Loan:
                case ScreenLinkType.Cash:
                case ScreenLinkType.ShareClass:
                    return foundGetAllSummaryIds.securityIds.filter(id => foundElements.securities[id]?.type === typeSummaryModel
                        && !value.securityIds.includes(id));
                default:
                    return foundGetAllSummaryIds.securityIds.filter(id => foundElements.securities[id]?.type !== "LoanSummaryModel"
                        && foundElements.securities[id]?.type !== "CashSummaryModel" && foundElements.securities[id]?.type !== "ShareClassSummaryModel"
                        && !value.securityIds.includes(id));
            }
        }
        else if (isPortfolioScreenLinkType(type)) {
            return foundGetAllSummaryIds.portfolioIds.filter(id => foundElements.portfolios[id]?.type === typeSummaryModel
                && !value.portfolioIds.includes(id));
        }
        else if (type === ScreenLinkType.Movement) {
            return foundGetAllSummaryIds.cashMovementIds;
        }
        else if (type === ScreenLinkType.PortfolioTransaction) {
            return foundGetAllSummaryIds.transactionIds;
        }
        return [];
    }

    const getValues = (): number[] => {
        const typeSummaryModel = getSummaryModel(type);

        if (!typeSummaryModel) return [];

        if (isEntityScreenLinkType(type)) {
            return value.entityIds.filter(id => dictionaries.entities[id]?.type === typeSummaryModel);
        }
        else if (isRelationshipScreenLinkType(type)) {
            return value.relationshipIds.filter(id => dictionaries.relationships[id]?.type === typeSummaryModel);
        }
        else if (isSecurityScreenLinkType(type)) {
            return value.securityIds.filter(id => dictionaries.securities[id]?.type === typeSummaryModel);
        }
        else if (isPortfolioScreenLinkType(type)) {
            return value.portfolioIds.filter(id => dictionaries.portfolios[id]?.type === typeSummaryModel);
        }
        else if (type === ScreenLinkType.Movement) {
            return value.cashMovementIds;
        }
        else if (type === ScreenLinkType.PortfolioTransaction) {
            return value.transactionIds;
        }
        return [];
    }

    const handleTypeChange = (event: any, newValue: ScreenLinkType) => setType(newValue);
    const renderType = (params: any) => <TextField {...params} label="Type" />;

    return <ReadOnlyContext.Consumer>{
        readOnly => readOnly ? null :
            <Box style={{ display: "inline-block", width: "100%" }} >
                <Autocomplete style={{ width: "40%" }}
                    disableClearable
                    options={[ScreenLinkType.Person, ScreenLinkType.Company, ScreenLinkType.EntityGroup, ScreenLinkType.Sicav, ScreenLinkType.SubFund, ScreenLinkType.Security, ScreenLinkType.Investor, ScreenLinkType.Role, ScreenLinkType.Counterparty, ScreenLinkType.Portfolio, ScreenLinkType.PortfolioTransaction, ScreenLinkType.Movement, ScreenLinkType.Cash, ScreenLinkType.Loan, ScreenLinkType.ShareClass]}
                    renderInput={renderType}
                    onChange={handleTypeChange}
                    getOptionLabel={option => toTitleCase(option)}
                    value={type}
                />
                <AsyncMultiSelect
                    fullWidth={true}
                    onSearchOptions={handleOnSearchOptions}
                    onValueSelected={handleValueSelected}
                    options={getOptions()}
                    values={getValues()}
                    getLabelValue={handleGetOptionLabel}
                    getSubLabelValue={handleGetOptionSubLabel}
                    searching={referenceManagedPortfolioSearching || referenceSecuritySearching || referenceEntitySearching || referenceRelationshipSearching || referencePortfolioTransactionSearching || referenceCashMovementSearching}
                    label={handleGetLabel()}
                    error={!!error}
                    helperText={error} />
            </Box>
    }
    </ReadOnlyContext.Consumer>
}

const getSummaryModel = (type: ScreenLinkType): string | undefined => {
    switch (type) {
        case ScreenLinkType.Person:
            return "PersonSummaryModel";
        case ScreenLinkType.Company:
            return "CompanySummaryModel";
        case ScreenLinkType.EntityGroup:
            return "EntityGroupSummaryModel";
        case ScreenLinkType.Sicav:
            return "SicavSummaryModel";
        case ScreenLinkType.Security:
            return "SecuritySummaryModel";
        case ScreenLinkType.Investor:
            return "InvestorRelationshipSummaryModel";
        case ScreenLinkType.Role:
            return "RoleRelationshipSummaryModel";
        case ScreenLinkType.Counterparty:
            return "CounterpartyRelationshipSummaryModel";
        case ScreenLinkType.Portfolio:
            return "DiscretionaryPortfolioSummaryModel";
        case ScreenLinkType.PortfolioTransaction:
            return "PortfolioTransactionSummaryModel";
        case ScreenLinkType.Movement:
            return "CashMovementSummaryModel";
        case ScreenLinkType.Cash:
            return "CashSummaryModel";
        case ScreenLinkType.Loan:
            return "LoanSummaryModel";
        case ScreenLinkType.SubFund:
            return "SubFundSummaryModel";
        case ScreenLinkType.ShareClass:
            return "ShareClassSummaryModel";
        default:
            return undefined;
    }
}

const screenTypeToEntityType = (type: ScreenLinkType): IEntityTypeModel | undefined => {
    switch (type) {
        case ScreenLinkType.Person:
            return IEntityTypeModel.Person;
        case ScreenLinkType.Company:
            return IEntityTypeModel.Company;
        case ScreenLinkType.EntityGroup:
            return IEntityTypeModel.EntityGroup;
        case ScreenLinkType.Sicav:
            return IEntityTypeModel.Sicav;
        default:
            return undefined;
    }
}

const screenTypeToRelationshipType = (type: ScreenLinkType): RelationshipModel["type"] => {
    switch (type) {
        case ScreenLinkType.Investor:
            return "InvestorRelationshipModel";
        case ScreenLinkType.Counterparty:
            return "CounterpartyRelationshipModel";
        default:
            return "RoleRelationshipModel";
    }
}

const screenTypeToPortfolioType = (type: ScreenLinkType): IPortfolioTypeModel | undefined => {
    switch (type) {
        case ScreenLinkType.Portfolio:
            return IPortfolioTypeModel.Portfolio;
        case ScreenLinkType.SubFund:
            return IPortfolioTypeModel.SubFund;
        default:
            return undefined;
    }
}
