import * as React from "react";
import { useCallback, useEffect } from "react";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IColumnState, IGridState } from "tools/components/ExtendedGrid";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ITradeDateSummaryModel, ITradeStatusModel } from "proxy/apiProxy";
import { splitPascalCase, today } from "tools/lib/utility";
import TodayIcon from '@material-ui/icons/Today';
import { IActionButton } from "tools/components/FabContainer";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { formatDate } from "tools/utility";

export const tradeDatesScreen = {
    component: TradeDates,
    label: "Trade Dates",
    route: "/tradeblotter/tradebooks",
}

// const tradeStatus = getEnumLabels(ITradeStatusModel);
const tradeStatuses = Object.keys(ITradeStatusModel).map(k => ({ name: k as ITradeStatusModel, label: splitPascalCase(ITradeStatusModel[k as keyof (typeof ITradeStatusModel)]) }));
const statePosition: Record<ITradeStatusModel, number> = {
    [ITradeStatusModel.New]: 1,
    [ITradeStatusModel.CheckedAndSending]: 2,
    [ITradeStatusModel.CheckedAndSent]: 3,
    [ITradeStatusModel.Reconciling]: 4,
    [ITradeStatusModel.Reconciled]: 5,
    [ITradeStatusModel.Settled]: 6,
};

function TradeDates() {
    const { all: tradeDates = [], allLoading } = useReduxSelections("tradeDates");
    const { tradeDateAllLoad } = useReduxActions("tradeDates");

    useEffect(() => {
        tradeDateAllLoad()
    }, [tradeDateAllLoad]);

    const navigate = useScreenNavigate();
    const { columns, state } = React.useMemo(() => {
        const cs = [
            { name: "Date", title: "Date", getCellValue: (row: ITradeDateSummaryModel) => row.date, columnType: "date" },
            ...tradeStatuses
                .sort(({ name: nameA }, { name: nameB }) => statePosition[nameA] - statePosition[nameB])
                .map(({ name, label }) => ({
                    name,
                    title: label,
                    getCellValue: (row: ITradeDateSummaryModel) => row.statuses[name],
                    columnType: "integer",
                    filteringEnabled: true
                }))
        ] as IColumnDefinition[];
        const state = {
            "Date": { width: 150, sortingPosition: -1 },
            ...tradeStatuses.reduce((a, v) => {
                a[v.name] = { width: 170 };
                return a;
            }, {} as Record<string, IColumnState>)
        } as IGridState;

        return {
            columns: cs,
            state
        };
    }, []);
    const getRowKey = (row: ITradeDateSummaryModel) => formatDate(row.date);
    const handleRowClick = useCallback((row: ITradeDateSummaryModel) => navigate("TradeDate", { tradedate: formatDate(row.date), tab: undefined }), [navigate])
    const handleTradeToday = useCallback(() => navigate("TradeDate", { tradedate: formatDate(today()), tab: undefined }), [navigate])
    const extraActions: IActionButton[] = [{
        label: "Today",
        icon: TodayIcon,
        onClick: handleTradeToday
    }];

    return <SearchPanel title="Trading activity" isQuerying={allLoading} actions={extraActions}>
        <ExtendedGrid
            getRowId={getRowKey}
            columns={columns}
            rows={tradeDates}
            onRowClick={handleRowClick}
            initialState={state}
            userCanGroup={true}
            defaultExportFileName={`TradingActivity.xlsx`} />
    </SearchPanel>
}
