import { Box, Fab, TextField } from "@material-ui/core";
import { useField } from "formik";
import React from "react";
import { IDocumentDefinitionModel, DocumentElementModel, INoteModel, INoteDocumentElementModel, IParagraphDocumentElementModel } from "proxy/apiProxy";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { oProps, toDictionary } from "tools/lib/utility";
import * as Uuid from 'uuid';
import MuiSortableTreeWithChildren, { IRowMovedEventArgs } from "tools/components/MuiSortableTreeWithChildren";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { wrapNoteNodeRenderer } from "./NoteNodeRenderer";
import NoteSource, { dndNoteNodeType } from "./NoteSource";
import ParagraphDialog from "./ParagraphDialog";
import StickyContainer from "tools/components/StickyContainer";
import AddIcon from '@material-ui/icons/Add';

export interface IDocumentStructureProps {
    notes: INoteModel[];
}
export default function DocumentStructure({ notes }: IDocumentStructureProps) {
    const [{ value: content }, , { setValue: setContent }] = useField<IDocumentDefinitionModel["content"]>(oProps<IDocumentDefinitionModel>().path("content"));
    const [, , { setValue: setNewVersion }] = useField<string>("_version");
    const [editingParagraph, setEditingParagraph] = React.useState<IParagraphDocumentElementModel | undefined>();
    const [addingParagraph, setAddingParagraph] = React.useState<IParagraphDocumentElementModel | undefined>();
    const notesDictionary = toDictionary(notes, i => i.code);
    const readOnly = React.useContext(ReadOnlyContext);
    const [filterNotes, setFilterNotes] = React.useState<string>("");
    const handleFilterNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilterNotes(e.target.value);
    const handleParagraphChanging = (paragraph: IParagraphDocumentElementModel) => setEditingParagraph(paragraph);
    const handleParagraphChanged = (row: IParagraphDocumentElementModel) => {
        if (editingParagraph) {
            Object.assign(editingParagraph, row);
            setContent([...content]);
        }
        else if (addingParagraph) {
            setContent([...content, { ...addingParagraph, ...row, content: [] }]);
        }
        hideEdit();
        setNewVersion(Uuid.v1());
    }

    const handleNoteDropped = (note: INoteModel) => {
        console.log("handleNoteDropped", note);
    }
    const hideEdit = () => {
        setEditingParagraph(undefined);
        setAddingParagraph(undefined);
    }
    const handleCancelEditParagraph = hideEdit;

    const handleDelete = (row: DocumentElementModel, parentRow: IParagraphDocumentElementModel) => {
        const previousParentChildren = parentRow ? (parentRow.content || []) : content;
        previousParentChildren.splice(previousParentChildren.indexOf(row), 1);
        setNewVersion(Uuid.v1());
        setContent([...content]);
    }
    const handleAddNodeClick = () => setAddingParagraph({ type: "ParagraphDocumentElementModel", title: {}, content: [] });
    const nodeContentRenderer = wrapNoteNodeRenderer(notesDictionary, handleParagraphChanging, handleDelete, readOnly);
    const getChildren = (row: DocumentElementModel | undefined) => {
        if (!row) {
            return content;
        }
        if (row.type === "NoteDocumentElementModel") {
            return [];
        }
        return row.content;
    }
    const handleNodeMoved = (args: IRowMovedEventArgs<DocumentElementModel>) => {
        const { row, parentRow, previousParentRow, targetPosition, fromOutside } = args;
        if (parentRow && parentRow.type !== "ParagraphDocumentElementModel") {
            return;
        }
        if (previousParentRow && previousParentRow.type === "NoteDocumentElementModel") {
            return;
        }
        if (!fromOutside) {
            const previousParentChildren = previousParentRow ? (previousParentRow.content || []) : content;
            previousParentChildren.splice(previousParentChildren.indexOf(row), 1);
        }
        if (parentRow) {
            if (!parentRow.content) {
                parentRow.content = [row];
            }
            else {
                parentRow.content.splice(targetPosition, 0, row);
            }
        }
        else {
            content.splice(targetPosition, 0, row);
        }
        setNewVersion(Uuid.v1());
        setContent([...content]);
    }
    const handleCanDrop = (row: DocumentElementModel, target: DocumentElementModel | undefined) => !target || target.type === "ParagraphDocumentElementModel";
    const availableNotes = React.useMemo(() => {
        const foundNotes: INoteDocumentElementModel[] = [];
        getNotes(content);

        return notes.filter(i => !foundNotes.find(c => c.noteCode === i.code) && (filterNotes === "" || i.label.toLowerCase().includes(filterNotes.toLowerCase()) || i.code.toLowerCase().includes(filterNotes.toLowerCase())));
        function getNotes(documentElementModels: DocumentElementModel[]) {
            for (const elt of documentElementModels) {
                if (elt.type === "NoteDocumentElementModel") {
                    foundNotes.push(elt);
                }
                else {
                    getNotes(elt.content);
                }
            }
        }
    }, [notes, content, filterNotes]);

    return <Box display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}>
        <ParagraphDialog
            paragraph={editingParagraph ?? addingParagraph}
            onCancel={handleCancelEditParagraph}
            onParagraphChanged={handleParagraphChanged} />
        {/* @ts-ignore FIXME Upgrade */}
        <DndProvider backend={HTML5Backend}>
            <div style={{ height: "100%", width: "calc(100% - 400px)", overflowY: "auto" }}>
                <MuiSortableTreeWithChildren
                    canDrop={handleCanDrop}
                    readOnly={readOnly}
                    getChildren={getChildren}
                    onRowMoved={handleNodeMoved}
                    nodeDefinition={nodeContentRenderer}
                    dndType={dndNoteNodeType}
                    keyForUpdate={content} />
                {!readOnly && <StickyContainer>
                    <Fab color="primary" aria-label="Add" onClick={handleAddNodeClick}>
                        <AddIcon />
                    </Fab>
                </StickyContainer>}
            </div>
            <div style={{ height: "100%", width: 400, display: "flex", flexDirection: "column" }}>
                <div style={{ padding: 10 }}>
                    <TextField label="Find..." value={filterNotes} onChange={handleFilterNotesChange} fullWidth={true} />
                </div>
                <div style={{ overflowY: "auto", width: "100%", flexGrow: 1 }}>
                    {availableNotes.map(note => <NoteSource key={note.id} note={note} onDropped={handleNoteDropped} />)}
                </div>
            </div>
        </DndProvider>
    </Box>
}
