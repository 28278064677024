import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFeatureModel, ITenanciesSearchParameters, ITenancyModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Grid } from "@material-ui/core";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps } from "tools/lib/utility";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";

export const tenanciesScreen = {
    route: "/tenancies",
    component: Tenancies,
    label: "Tenancies"
}

function Tenancies() {
    const { tenancies, tenancyAllLoading, dictionaries: { entities, securities } } = useReduxSelections("tenancy");
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    const navigate = useScreenNavigate();
    const { tenancyLoadAll } = useReduxActions("tenancy");

    const handleRowClick = ({ id }: ITenancyModel) => navigate("Tenancy", { id });
    const handleAddNewClick = () => navigate("Tenancy", { id: 0 });

    const columns: IColumnDefinition[] = [
        { name: "RealEstateCode", title: "Real Estate Code", getCellValue: (row: ITenancyModel) => securities[row.realEstateId]?.internalCode, filteringEnabled: true },
        { name: "RealEstate", title: "Real Estate Name", getCellValue: (row: ITenancyModel) => securities[row.realEstateId]?.name, filteringEnabled: true },
        { name: "LeaseHolderCode", title: "LeaseHolderCode", getCellValue: ({ leaseHolderId = 0 }: ITenancyModel) => entities[leaseHolderId]?.internalCode, filteringEnabled: true },
        { name: "LeaseHolderName", title: "LeaseHolderName", getCellValue: ({ leaseHolderId = 0 }: ITenancyModel) => getEntityName(entities[leaseHolderId]), filteringEnabled: true },
        { name: "LeaseCurrency", title: "Lease Currency", getCellValue: ({ leaseCurrencyId = 0 }: ITenancyModel) => referenceCurrencies[leaseCurrencyId]?.isoCode },
        { name: "BreakOption", title: "Break Option", getCellValue: ({ breakOptionDate }: ITenancyModel) => breakOptionDate, filteringEnabled: true, columnType: "date" },
        { name: "StartDate", title: "Start Date", getCellValue: ({ leaseStartDate }: ITenancyModel) => leaseStartDate, filteringEnabled: true, columnType: "date" },
        { name: "EndDate", title: "End Date", getCellValue: ({ leaseEndDate }: ITenancyModel) => leaseEndDate, filteringEnabled: true, columnType: "date" },
        { name: "EstimatedRentalValue", title: "Estimated Rental Value", getCellValue: ({ estimatedRentalValue }: ITenancyModel) => estimatedRentalValue, filteringEnabled: true, columnType: "decimal", aggregationType: "sum" },
        { name: "LocatedAreaSqm", title: "Located Area Sqm", getCellValue: ({ locatedAreaSqm }: ITenancyModel) => locatedAreaSqm, filteringEnabled: true, columnType: "decimal", aggregationType: "sum" },
        { name: "PassingRent", title: "Passing Rent", getCellValue: ({ passingRent }: ITenancyModel) => passingRent, filteringEnabled: true, columnType: "decimal", aggregationType: "sum" },
    ];

    const state: IGridState = {
        "RealEstateCode": { width: 180 },
        "RealEstate": { width: 200 },
        "LeaseHolderCode": { width: 180 },
        "LeaseHolderName": { width: 200 },
        "LeaseCurrency": { width: 120 },
        "BreakOption": { width: 180 },
        "StartDate": { width: 180 },
        "EndDate": { width: 180 },
        "EstimatedRentalValue": { width: 180 },
        "LocatedAreaSqm": { width: 180 },
        "PassingRent": { width: 180 },
    };

    const getRowKey = ({ id = 0 }: ITenancyModel) => id;

    const handleSubmit = (values: Omit<ITenanciesSearchParameters, "type">, { setSubmitting }: FormikHelpers<ITenanciesSearchParameters>) => {
        tenancyLoadAll(values);
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={{ onlyActive: true } as ITenanciesSearchParameters} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;
    function renderForm({ isValid, submitForm }: FormikProps<ITenanciesSearchParameters>) {

        return <SearchPanel title="Tenancies" isQuerying={tenancyAllLoading}
            searchDisabled={!isValid}
            onSearchClick={submitForm}
            addAllowed={IFeatureModel.ManagedPortfolioWrite}
            onAddClick={handleAddNewClick}
            renderSearch={<Grid container={true} spacing={1}>
                <Grid item={true} md={9}>
                    <FormTextField name={oProps<ITenanciesSearchParameters>().path("criterias")} label="Keywords (lease holder, real estate)" />
                </Grid>
                <Grid item={true} md={3}>
                    <FormCheckBoxField name={oProps<ITenanciesSearchParameters>().path("onlyActive")} label="Only active tenants" />
                </Grid>
            </Grid>} >
            <ExtendedGrid
                rows={tenancies}
                columns={columns}
                getRowId={getRowKey}
                onRowClick={handleRowClick}
                initialState={state}
                userCanGroup={true}
                defaultExportFileName="tenancies.xlsx" />
        </SearchPanel>
    }
}
