import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, IMonitoringMacroCallModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import MonitoringMacroCallData from "./MonitoringMacroCallData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";
import { isNumber, useNumber } from "../../tools/lib/utility";
import { useEffect } from "react";

export const monitoringMacroCallScreen = {
    route: "/config/computations/calls/:id" as const,
    component: MonitoringMacroCall,
    label: "Monitoring Macro Call",
}

function MonitoringMacroCall() {
    const {
        monitoringMacroCallSave,
        monitoringMacroCallDelete,
        monitoringMacroCallSelectMonitoringMacro,
        monitoringMacroCallLoad
    } = useReduxActions("monitoringMacroCall");
    const { monitoringMacroCallCurrent, monitoringMacroCallLoading, monitoringMacroCallMetadataSearchLoading,
        monitoringMacroCallParameterMetadata = [], monitoringMacroCallMonitoringMacros = []
    } = useReduxSelections("monitoringMacroCall");

    const navigate = useScreenNavigate();
    const { id } = useScreenParams<typeof monitoringMacroCallScreen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (isNumber(idNum))
            monitoringMacroCallLoad(idNum)
    }, [idNum, monitoringMacroCallLoad]);

    if (!monitoringMacroCallCurrent) {
        return <DetailPanelLoading hasSubTitle={false} />;
    }

    const handleDelete = () => {
        if (monitoringMacroCallCurrent?.id) {
            monitoringMacroCallDelete(monitoringMacroCallCurrent?.id);
        }
    }

    const handleSubmit = (values: IMonitoringMacroCallModel, { setSubmitting }: FormikHelpers<IMonitoringMacroCallModel>) => {
        monitoringMacroCallSave(values);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={monitoringMacroCallCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;

    function renderForm(formikProps: FormikProps<IMonitoringMacroCallModel>) {
        if (!monitoringMacroCallCurrent) {
            return;
        }
        const { dirty, isValid, submitForm, values } = formikProps;
        const { id, name, macroScriptId } = values;
        const title = !!id ? (name) : 'New Monitoring Macro Call';
        const handleOpenMonitoringMacro = () => navigate("Macro", { id: macroScriptId })

        return <WriteAccess value={IFeatureModel.MacroExecutionSetupAndDashboardWrite}>
            <DetailPanel
                isQuerying={monitoringMacroCallLoading || monitoringMacroCallMetadataSearchLoading}
                title={title}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!id}
                onDeleteClick={handleDelete}
                saveAllowed={IFeatureModel.MacroExecutionSetupAndDashboardWrite}
                deleteAllowed={IFeatureModel.MacroExecutionSetupAndDashboardDelete}
                badge={!id ? "new" : undefined}>
                <MonitoringMacroCallData
                    parameterMetadata={monitoringMacroCallParameterMetadata}
                    macroScripts={monitoringMacroCallMonitoringMacros}
                    onOpenMonitoringMacro={handleOpenMonitoringMacro}
                    onSelectMonitoringMacro={monitoringMacroCallSelectMonitoringMacro} />
            </DetailPanel></WriteAccess>
    }
}
