import { Box, BoxProps, makeStyles } from "@material-ui/core";
import { Container, ContainerDisplay, IFlexContainerDisplay, IGridContainerDisplay, ItemDisplay, IFlexItemDisplay, IGridItemDisplay, ITabContainerDisplay } from "./DashboardFormattingContracts";
import { CSSProperties, useMemo } from "react";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: theme.shadows[5],
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch"
    },
    cardHeader: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
        padding: theme.spacing(1),
        display: "flex",
        alignItems: "center"
    },
    cardContent: {
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        // padding: theme.spacing(1)
    },
    cardContentHidden: {
        visibility: "hidden"
    },
    dashboardLink: {
        textAlign: "left"
    },
    disabledLink: {
        "&:hover": {
            textDecoration: "initial",
        },
        cursor: "not-allowed",
    }
}));

// children: React.ReactNode;

export interface IContainerComponentProps extends BoxProps {
    type: Container<any>["type"];
    layout: ContainerDisplay | undefined;
    children: React.ReactNode;
}

export function ContainerBox({ children, layout = {}, type, ...extraProps }: IContainerComponentProps) {
    const boxProperties = useMemo(() => buildContainerProps(type, layout ?? {}), [layout, type])
    const styles = useStyles();
    return <Box className={clsx(styles.cardContent)} {...extraProps} style={boxProperties} padding={1}>
        {children}
    </Box>
}
export interface IItemBoxProps extends BoxProps {
    type: Container<any>["type"];
    layout: ItemDisplay | undefined;
    children: React.ReactNode;
    hidden?: boolean;
}
export function ItemBox({ type, hidden, layout = {}, children, ...extraProps }: IItemBoxProps) {
    const boxProperties = useMemo(() => {
        const prps = buildItemProps(type, layout ?? {});
        if (hidden) {
            prps.display = "none";
        }
        prps.height = "100%";
        prps.overflowY = "auto";
        return prps;
    }, [type, layout, hidden])
    return <Box {...extraProps} style={boxProperties}>
        {children}
    </Box>
}



export function buildContainerProps(containerType: Container<any>["type"], display: ContainerDisplay): CSSProperties {
    switch (containerType) {
        case "flexContainer":
        case "flexDetailContainer":
            const flexDisplay = display as IFlexContainerDisplay;
            return {
                display: "flex",
                rowGap: flexDisplay.gap ?? 8,
                columnGap: flexDisplay.gap ?? 8,
                // flexColumnGap: flexDisplay.columnGap ?? 38,
                // flexRowGap: flexDisplay.rowGap ?? 38,
                flexDirection: flexDisplay.direction,
                flexWrap: flexDisplay.wrap,
                justifyContent: flexDisplay.justifyContent,
                alignItems: flexDisplay.alignItems,
                alignContent: flexDisplay.alignContent,
                height: flexDisplay.fullHeight ? "100%" : undefined,
                overflowY: flexDisplay.fullHeight ? "auto" : undefined,
            };
        case "gridContainer":
        case "gridDetailContainer":
            const gridDisplay = display as IGridContainerDisplay;
            return {
                display: "grid",
                gridTemplateColumns: gridDisplay.templateColumns,
                gridTemplateRows: gridDisplay.templateRows,
                gridTemplateAreas: (gridDisplay.templateAreas ?? []).map(i => `'${i.join(" ")}'`).join(" "),
                gridColumnGap: gridDisplay.columnGap ?? 8,
                gridRowGap: gridDisplay.rowGap ?? 8,
                justifyItems: gridDisplay.justifyItems,
                justifyContent: gridDisplay.justifyContent,
                alignItems: gridDisplay.alignItems,
                alignContent: gridDisplay.alignContent,
                gridAutoColumns: gridDisplay.autoColumns,
                gridAutoRows: gridDisplay.autoRows,
                gridAutoFlow: gridDisplay.autoFlow,
                height: gridDisplay.fullHeight ? "100%" : undefined,
                overflowY: gridDisplay.fullHeight ? "auto" : undefined,
            };
        case "directedGraphDetailContainer":
        case "hierarchyGraphDetailContainer":
            return {}
        case "tabsContainer":
            const tabDisplay = display as ITabContainerDisplay;
            return {
                height: tabDisplay.fullHeight ? "100%" : undefined,
                overflowY: tabDisplay.fullHeight ? "auto" : undefined,
            };
    }
}
export function buildItemProps(containerType: Container<any>["type"], display: ItemDisplay): CSSProperties {
    switch (containerType) {
        case "flexContainer":
        case "flexDetailContainer":
            const flexDisplay = display as IFlexItemDisplay;
            return {
                flexGrow: flexDisplay.grow,
                flexShrink: flexDisplay.shrink,
                flexBasis: flexDisplay.basis,
                alignSelf: flexDisplay.alignSelf,
            };
        case "gridContainer":
        case "gridDetailContainer":
            const gridDisplay = display as IGridItemDisplay;
            return {
                gridColumn: gridDisplay.column,
                gridRow: gridDisplay.row,
                gridArea: gridDisplay.area,
                justifySelf: gridDisplay.justifySelf,
                alignSelf: gridDisplay.alignSelf,
            };
        case "directedGraphDetailContainer":
        case "hierarchyGraphDetailContainer":
        case "tabsContainer":
            const tabDisplay = display as ITabContainerDisplay;
            return {
                overflowY: tabDisplay.fullHeight ? "auto" : undefined,
            };
    }
}
