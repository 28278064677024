import DetailPanel from "tools/components/DetailPanel";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import ProcessExecutionData from "./ProcessExecutionData";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { IProcessExecutionSavePayload, ProcessExecutionLoadPayload } from "./slice";
import { useCallback, useEffect, useMemo } from "react";
import { IActionButton } from "tools/components/FabContainer";
import { oProps, useNumber } from "tools/lib/utility";
import CertificateOutlineIcon from "mdi-material-ui/CertificateOutline";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { useScreenNavigate, useScreenParams } from "../../tools/routing/screenRouteHooks";

export const processExecutionScreen = {
    label: "Process Execution",
    route: "/processexecutions/:id" as const,
    component: ProcessExecution,
}

function ProcessExecution() {
    const { id } = useScreenParams<typeof processExecutionScreen>()
    const idNum = useNumber(id)
    const payload = { id: idNum } as ProcessExecutionLoadPayload
    return <ProcessExecutionBase payload={payload} />
}

export const processExecutionRenewScreen = {
    label: "Process Execution Renewal",
    route: "/processexecutions/:id/renew" as const,
    component: ProcessExecutionRenew,
}

function ProcessExecutionRenew() {
    const { id } = useScreenParams<typeof processExecutionRenewScreen>()
    const idNum = useNumber(id)
    const payload = { renewId: idNum } as ProcessExecutionLoadPayload
    return <ProcessExecutionBase payload={payload} />
}

export const processExecutionNewScreen = {
    label: "Process Execution Renewal",
    route: "/processexecutions/:type/:targetId" as const,
    component: ProcessExecutionNew,
}

function ProcessExecutionNew() {
    const { type, targetId } = useScreenParams<typeof processExecutionNewScreen>()
    const idNum = useNumber(targetId)
    const payload = { type: type, targetId: idNum } as ProcessExecutionLoadPayload
    return <ProcessExecutionBase payload={payload} />
}

function ProcessExecutionBase({ payload }: { payload: ProcessExecutionLoadPayload }) {
    const { currentUser } = useReduxSelections("app");
    const navigate = useScreenNavigate()
    const {
        processExecutionLoad,
        processExecutionDelete,
        processExecutionSave,
        processExecutionLoadFile,
        processExecutionLoadedRelationship,
        processExecutionLoadedEntity,
        processExecutionLoadQuestionnaire,
        processExecutionReloadQuestionnaire
    } = useReduxActions("processExecution");
    const {
        processExecutionCurrent,
        processExecutionLoading,
        dictionaries,
        processTaskState,
        processExecutionSaving,
        processExecutionQuestionnaires,
        processExecutionQuestionnaireDetail
    } = useReduxSelections("processExecution");
    const { classificationTypes } = useReduxSelections("processClassificationType");

    const title = "Process execution";

    useEffect(() => {
        processExecutionLoad(payload)
    }, [payload, processExecutionLoad]);

    const handleDelete = useCallback(() => {
        if (processExecutionCurrent?.id) {
            processExecutionDelete(processExecutionCurrent?.id);
        }
    }, [processExecutionCurrent?.id, processExecutionDelete]);

    const handleSubmit = useCallback((values: IProcessExecutionSavePayload, { setSubmitting }: FormikHelpers<IProcessExecutionSavePayload>) => {
        processExecutionSave(values);
        setSubmitting(false);
    }, [processExecutionSave])

    const formValues: IProcessExecutionSavePayload | undefined = useMemo(() => {
        if (!processExecutionCurrent) {
            return undefined;
        }
        const ret: IProcessExecutionSavePayload = {
            execution: processExecutionCurrent,
            files: {},
            questionnaires: {}
        };
        return ret;
    }, [processExecutionCurrent]);

    const handleRequestFileLoad = useCallback((taskCode: string) => {
        if (formValues?.execution) {
            processExecutionLoadFile({ id: formValues?.execution.id, taskCode, type: formValues?.execution.type });
        }
    }, [formValues?.execution, processExecutionLoadFile]);

    const handleRenewExecutionProcess = useCallback(() => {
        if (formValues?.execution.id)
            navigate("ProcessExecutionRenew", { id: formValues.execution.id })
    }, [formValues?.execution.id, navigate])

    if (!formValues) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={false} />;
    }

    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values, setFieldValue }: FormikProps<IProcessExecutionSavePayload>) {

        const handleApprove = () => {
            if (!currentUser) {
                return;
            }
            processExecutionLoadedEntity({ entities: {}, entity: { type: "PersonSummaryModel", internalCode: "", firstName: "", lastName: "", classifications: {}, entityExtensionFieldsValues: {}, ...currentUser } })
            setFieldValue(
                oProps<IProcessExecutionSavePayload>().path("execution", "approvedById"),
                values?.execution?.approvedById ? undefined : currentUser.id, true);
        }

        const extraActionButtons: IActionButton[] = [{
            label: "Change approval status",
            onClick: handleApprove,
            icon: CertificateOutlineIcon,
        }, {
            label: "Renew",
            onClick: handleRenewExecutionProcess,
            icon: AutorenewIcon,
            disabled: !values?.execution.id || dirty
        }];

        if (!processExecutionCurrent) {
            return null;
        }

        return <WriteAccess value={true}><DetailPanel
            isQuerying={processExecutionLoading || processExecutionSaving}
            title={title}
            canSave={(dirty || !values.execution.id) && isValid}
            onSaveClick={submitForm}
            actions={extraActionButtons}
            canDelete={!!processExecutionCurrent.id}
            onDeleteClick={handleDelete}
            badge={!processExecutionCurrent.id ? "new" : undefined}
        // saveAllowed={IFeatureModel.ProcessExecutionWrite}
        // deleteAllowed={IFeatureModel.ProcessExecutionWrite}
        >
            <ProcessExecutionData
                classificationTypes={classificationTypes}
                dictionaries={dictionaries}
                taskLoading={processTaskState.loading}
                taskSaving={processTaskState.saving}
                processExecution={values.execution}
                processFiles={values.files}
                onRequestFileLoad={handleRequestFileLoad}
                onRelationshipLoaded={processExecutionLoadedRelationship}
                questionnaires={processExecutionQuestionnaires}
                onLoadQuestionnaire={processExecutionLoadQuestionnaire}
                onReloadQuestionnaire={processExecutionReloadQuestionnaire}
                questionnaireContents={processExecutionQuestionnaireDetail} />
            {/* {(tabValue === "detail") && <ProcessExecutionData
                classificationTypes={classificationTypes}
                dictionaries={dictionaries}
                fileLoading={processFileState.fileLoading}
                fileSaving={processFileState.fileSaving}
                processExecution={values.execution}
                processFiles={values.files}
                onRequestFileLoad={handleRequestFileLoad}
                onRelationshipLoaded={processExecutionLoadedRelationship} />} */}
            {/* {(tabValue === "tasks") && <ProcessExecutionTasksData questionnaires={processExecutionQuestionnaires} />} */}
        </DetailPanel></WriteAccess>
    }
}
