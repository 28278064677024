import * as React from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, makeStyles, Box } from "@material-ui/core";
import { Size } from "@material-ui/core/Table";
import { toDictionary } from "tools/lib/utility";
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
export interface IColumnDefinition<TRow = any> {
    label?: string;
    getValue: (row: TRow, index: number) => any;
    contentRenderer?: (value: any, row: TRow, index: number) => React.ReactNode;
    getColors?: (value: any, row: TRow, index: number) => { color?: string, backgroundColor?: string } | undefined;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    sortable?: boolean;
    customSortComparer?: IComparer<TRow>;
    width?: number;
}
interface IPositionedColumnDefinition<TRow = any> extends IColumnDefinition<TRow> {
    position: number;
}
type IComparer<TRow = any> = (left: TRow, right: TRow) => number;

const useStyles = makeStyles(theme => ({
    tableCellHeader: {
        position: "sticky",
        top: 0,
        backgroundColor: theme.palette.background.paper,
        fontWeight: "bold"
    }
}))
function useSort<TRow>(columns: IColumnDefinition<TRow>[], sorts: Record<number, number>, setSorts: React.Dispatch<React.SetStateAction<Record<number, number>>>) {
    const handleColumnHeaderClick = React.useCallback((definition: IPositionedColumnDefinition<TRow>) => {
        if (Math.abs(sorts[definition.position]) === 1) {
            setSorts(() => ({ ...sorts, [definition.position]: -sorts[definition.position] }));
        }
        else {
            const newSorts = toDictionary(Object.keys(sorts)
                .map(Number)
                .map((columnPosition) => {
                    const order = Math.abs(sorts[columnPosition]);
                    const direction = Math.abs(sorts[columnPosition]) / sorts[columnPosition];
                    return {
                        position: columnPosition,
                        sort: direction * (columnPosition === definition.position ? 1 : order + 1),
                    };
                }), i => i.position, i => i.sort);
            if (!newSorts[definition.position]) {
                newSorts[definition.position] = 1;
            }
            setSorts(() => newSorts);
        }
    }, [setSorts, sorts]);
    return [handleColumnHeaderClick, getComparer(columns, sorts)] as [(definition: IColumnDefinition<TRow>) => void, IComparer<TRow> | undefined];
}
function getComparer<TRow>(columns: IColumnDefinition<TRow>[], sorts: Record<number, number>): IComparer<TRow> | undefined {
    const comparers = Object
        .keys(sorts)
        .map(Number)
        .map(k => ({ direction: sorts[k], column: columns[k - 1] }))
        .filter(i => !!i.direction)
        .sort((l, r) => Math.abs(l.direction) - Math.abs(r.direction))
        .map(getSimpleComparer);

    if (!comparers.length) {
        return;
    }

    return (left: TRow, right: TRow): number => {
        for (const compare of comparers) {
            const ret = compare(left, right);
            if (ret) {
                return ret;
            }
        }
        return 0;
    }
    function getSimpleComparer(def: { direction: number, column: IColumnDefinition<TRow> }): IComparer<TRow> {
        const customSortComparer = def.column.customSortComparer;
        const direction = def.direction > 0 ? 1 : -1;
        if (customSortComparer) {
            return (left: TRow, right: TRow) => customSortComparer(left, right) * direction;
        }
        else {
            const getValue = def.column.getValue;
            return (left: TRow, right: TRow) => {
                const leftValue = getValue(left, 0);
                const rightValue = getValue(right, 0);
                if (!leftValue && !rightValue) {
                    return 0;
                }
                else if (leftValue && !rightValue) {
                    return direction;
                }
                else if (!leftValue && rightValue) {
                    return -direction;
                }
                else {
                    return leftValue === rightValue ? 0 : leftValue > rightValue ? direction : -direction;
                }
            };
        }
    }
}

export interface IDataGridProps<TRow = any> {
    selected?: TRow[];
    onSelectedChanged?: (selected: TRow[]) => void;
    getRowKey?: (row: TRow, idx: number) => string | number;
    rows: TRow[];
    columns: IColumnDefinition<TRow>[];
    size?: Size;
    sorts?: Record<number, number>;
    onSortsChanged?: React.Dispatch<React.SetStateAction<Record<number, number>>>;
    multiSelect?: boolean;
    hideHeader?: boolean;
}
export default function DataTable<TRow = any>({ hideHeader, columns, getRowKey = (row: TRow, idx: number) => idx, size, rows, selected, multiSelect, onSelectedChanged, sorts, onSortsChanged }: IDataGridProps<TRow>) {
    const [internalSorts, setInternalSorts] = React.useState<Record<number, number>>({});
    const [handleColumnHeaderClick, comparer] = useSort<TRow>(columns, sorts ?? internalSorts, onSortsChanged ?? setInternalSorts);
    const columnsArray: IPositionedColumnDefinition<TRow>[] = React.useMemo(() => columns.map((columnDefinition, index) => ({ ...columnDefinition, position: index + 1 })), [columns]);
    const sortedRows = React.useMemo(() => {
        if (comparer) {
            const rowCopy = [...rows];
            return rowCopy.map((data, index) => ({ data, index })).sort((a, b) => comparer(a.data, b.data));
        }
        else {
            return rows.map((data, index) => ({ data, index }));
        }
    }, [comparer, rows]);
    const handleRowClick = React.useCallback((row: TRow, index: number) => {
        if (!onSelectedChanged) {
            return;
        }
        if (multiSelect) {
            const selection = [...selected ?? []];
            const pos = selection.findIndex(s => getRowKey(s, 0) === getRowKey(row, 0));
            if (pos >= 0) {
                selection.splice(pos, 1);
            }
            else {
                selection.push(row);
            }
            onSelectedChanged(selection);
        }
        else {
            const pos = (selected ?? []).findIndex(s => getRowKey(s, 0) === getRowKey(row, 0));
            if (pos < 0) onSelectedChanged([row]);
            else onSelectedChanged([]);
        }
    }, [getRowKey, multiSelect, onSelectedChanged, selected]);
    const selectedKeys = React.useMemo(() => toDictionary(selected ?? [], i => getRowKey(i, 0), () => true), [getRowKey, selected]);
    return <Box style={{ overflowX: "auto", width: "100%" }}>
        <Table stickyHeader size={size}>
            {!hideHeader && <TableHead>
                <TableRow>
                    {(!!onSelectedChanged || !!selected) && <TableCell padding="checkbox" />}
                    {columnsArray.map((columnDefinition, columnIndex) => <TableCellHeader
                        key={columnDefinition.position}
                        definition={columnDefinition}
                        onClick={handleColumnHeaderClick}
                        sorts={sorts ?? internalSorts} />)}
                </TableRow>
            </TableHead>}
            <TableBody>
                {sortedRows.map((row, index) => <InnerTableRow
                    key={getRowKey(row.data, index)}
                    selected={selectedKeys[getRowKey(row.data, 0)]}
                    columns={columnsArray}
                    row={row.data}
                    rowIndex={row.index}
                    onClick={handleRowClick}
                    selectable={!!onSelectedChanged || !!selected} />)}
            </TableBody>
        </Table>
    </Box>
}
interface ITableCellHeaderProps<TRow = any> {
    sorts: Record<number, number>;
    definition: IPositionedColumnDefinition<TRow>;
    onClick: (definition: IPositionedColumnDefinition<TRow>) => void;
}
function TableCellHeader<TRow = any>({ definition, sorts, onClick }: ITableCellHeaderProps<TRow>) {
    const sortDirection = React.useMemo(() => {
        switch (sorts[definition.position]) {
            case -1: return "desc";
            case 1: return "asc";
        }
        return undefined;
    }, [definition.position, sorts]);
    const classes = useStyles();
    const handleClick = React.useCallback(() => onClick(definition), [definition, onClick]);
    return (<TableCell className={classes.tableCellHeader} width={definition.width} align={definition.align} sortDirection={sortDirection}>
        {(!!definition.sortable) && <TableSortLabel active={!!sortDirection} onClick={handleClick} direction={sortDirection}>{definition.label}</TableSortLabel>}
        {(!definition.sortable) && <>{definition.label}</>}
    </TableCell>);
}

interface IInnerTableRowProps<TRow = any> {
    selected?: boolean;
    columns: IPositionedColumnDefinition<TRow>[];
    row: TRow;
    rowIndex: number;
    onClick: (row: TRow, index: number) => void;
    selectable: boolean;
}
function InnerTableRow<TRow = any>({ selected, row, columns, onClick, rowIndex, selectable }: IInnerTableRowProps<TRow>) {
    const handleClick = React.useCallback(() => onClick(row, rowIndex), [onClick, row, rowIndex]);
    return <TableRow selected={selected} hover={selectable}>
        {selectable && <TableCell padding="checkbox" onClick={handleClick}>
            {selected ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
        </TableCell>}
        {columns.map((columnDefinition, columnIndex) => <InnerTableCell column={columnDefinition} rowIndex={rowIndex} onClick={handleClick} row={row} key={columnIndex} />)}
    </TableRow>
}
interface IInnerTableCellProps<TRow = any> {
    column: IPositionedColumnDefinition<TRow>;
    row: TRow;
    onClick: () => void;
    rowIndex: number;
}
function InnerTableCell<TRow = any>({ column, row, onClick, rowIndex }: IInnerTableCellProps<TRow>) {
    const handleClick = React.useCallback(() => onClick(), [onClick]);
    // const color=useMemo(()=>column&&column.getColor(value, row, rowIndex))
    const { content, color, backgroundColor } = React.useMemo(() => {
        const value = column.getValue(row, rowIndex);
        if (column.contentRenderer) {
            return {
                content: column.contentRenderer(value, row, rowIndex),
                ...((column.getColors && column.getColors(value, row, rowIndex)) ?? { color: undefined, backgroundColor: undefined }),
            };
        }
        else {
            return { content: <>{value}</>, color: undefined, backgroundColor: undefined };
        }
    }, [column, rowIndex, row]);
    return <TableCell align={column.align} onClick={handleClick} style={{ backgroundColor, color }}>
        {content}
    </TableCell>
}
