import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFeatureModel, IReportTemplateCategoryModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useCallback, useEffect } from "react";

export const reportTemplateCategoriesScreen = {
    route: "/config/reporting/templatecategories" as const,
    label: "Report Template Categories",
    component: ReportTemplateCategories,
}

function ReportTemplateCategories() {
    const {
        reportTemplateCategories = [],
        reportTemplateCategoryAllLoading
    } = useReduxSelections("reportTemplateCategory");
    const { reportTemplateCategoryLoadAll } = useReduxActions("reportTemplateCategory")
    const navigate = useScreenNavigate();

    useEffect(() => {
        reportTemplateCategoryLoadAll()
    }, [reportTemplateCategoryLoadAll]);

    const columns: IColumnDefinition[] = [
        {
            name: "Name",
            title: "Name",
            getCellValue: ({ name }: IReportTemplateCategoryModel) => name,
            filteringEnabled: true
        }
    ];
    const state: IGridState = {
        "Name": { width: 280 }
    };

    const getRowKey = ({ id = 0 }: IReportTemplateCategoryModel) => id;

    const handleRowClick = useCallback(({ id }: IReportTemplateCategoryModel) => navigate("TemplateCategory", { id: id }), [navigate]);

    const handleAddNewClick = useCallback(() => navigate("TemplateCategory", { id: 0 }), [navigate]);

    return (<SearchPanel title="Report Template Categories" isQuerying={reportTemplateCategoryAllLoading}
        addAllowed={IFeatureModel.ReportTemplateWrite}
        onAddClick={handleAddNewClick}>
        <ExtendedGrid
            rows={reportTemplateCategories}
            columns={columns}
            getRowId={getRowKey}
            onRowClick={handleRowClick}
            initialState={state} />
    </SearchPanel>);
}
