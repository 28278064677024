import React from 'react';
import { useField } from 'formik';
export interface IFieldExProps<V, R extends IValidator> {
    name: string;
    helperText?: React.ReactNode;
    label?: React.ReactNode;
    required?: boolean;
    error?: boolean;
    validators?: R;
    requestedValidations?: ValidatorParams<R>;
    checkDirty?: (initialValue: V | undefined, value: V | undefined) => boolean;
}
export function useFieldEx<V, R extends IValidator>({ name, helperText: inputHelperText, label, required, error = false, validators, requestedValidations, checkDirty }: IFieldExProps<V, R>) {

    const validate = (v: V | undefined) => (validators && requestedValidations) ? validateRule<V, R>(validators, v, requestedValidations) : undefined;

    const [, { value, error: errorText, initialValue }, { setValue }] = useField<V | undefined>({ name, validate });
    const isDirty = checkDirty ? checkDirty(initialValue, value) : (initialValue ?? null) !== (value ?? null);
    const onValueChanged = (newValue: V | undefined) => setValue(newValue);
    return {
        value,
        isDirty,
        error: error || !!errorText,
        helperText: errorText ?? inputHelperText,
        onValueChanged,
        label: renderLabel(isDirty, required ?? false, label)
    }
}

function renderLabel(isDirty: boolean, isRequired: boolean, label: React.ReactNode | undefined): React.ReactNode {
    if (!label) {
        return null;
    }
    if (isRequired) {
        label = <>{label} *</>;
    }
    if (isDirty) {
        label = <b>{label}</b>
    }
    return label;
}

export interface IFormField {
    name: string;
    label?: React.ReactNode;
    helperText?: React.ReactNode;
    disabled?: boolean;
    error?: boolean;
}

type ValidatorFunction = (v: any, p?: any) => string | undefined;
interface IValidator {
    [key: string]: ValidatorFunction;
}
export type ValidatorParams<V extends IValidator> = Partial<{
    [P in keyof V]: Parameters<V[P]>[1];
}>

function validateRule<V, R extends IValidator>(validators: R, value: V | undefined, requestedValidations: ValidatorParams<R>): string | undefined {
    const validationsToRun = Object.keys(requestedValidations).map(key => ({ validateParam: requestedValidations[key], validator: validators[key] as ValidatorFunction }));
    for (const iterator of validationsToRun) {
        const err = iterator.validator(value, iterator.validateParam);
        if (err) {
            return err;
        }
    }
    return;
}
