import React, { useCallback, useMemo } from "react";
import Autocomplete, { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { Icon, ListItem, ListItemIcon, ListItemText, Popper, PopperProps } from "@material-ui/core";
import { FilterOptionsState } from "@material-ui/lab";
import { matchSorter, MatchSorterOptions } from "match-sorter";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";
// import { IMenuTarget } from "./menuContracts";
// import { typedScreenDictionary } from "tools/routing/screenRouteHooks";
// import { IMenuLocation3, useMenuTargetLevel3 } from "./menuHooks";
import { IScreenMenuState } from "../slice";
import { ScreenLink } from "tools/routing/ScreenLink";
import { useNavigate } from "react-router";
import { generatePath } from "react-router";

const SearchInput = withStyles((theme) =>
    createStyles({
        root: {
            marginLeft: theme.spacing(1),
            flex: 1
        }
    })
)(InputBase);

const StyledSearchIcon = withStyles((theme) =>
    createStyles({
        root: {
            color: theme.palette.grey[500]
        }
    })
)(SearchIcon);

const SearchPaper = withStyles((theme) =>
    createStyles({
        root: {
            padding: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 250,

        }
    })
)(Paper);

export interface ISearchOption {
    target: IScreenMenuState;
    // targetLocation: IMenuLocation3;
    parent1MenuLabel: string;
    parent2MenuLabel: string;
    menuLabel: string;
    screenLabel: string;
    menuIcon: string;
    keywords?: string;
}
function toArray<T>(i: T | T[] | undefined): T[] {
    if (!i) return [];
    else if (Array.isArray(i)) return i;
    return [i];
}
function useSearchOptions(): ISearchOption[] {
    const { applicationMenu } = useReduxSelections("app")
    return useMemo(() => applicationMenu.categories.flatMap((category, index1) =>
        category.subMenus.flatMap((subMenu, index2) =>
            toArray(subMenu.screens).map((screen, index3) => {
                const opt: ISearchOption = {
                    menuIcon: subMenu.icon,
                    menuLabel: screen.label,
                    parent1MenuLabel: category.label,
                    parent2MenuLabel: subMenu.label,
                    screenLabel: screen.label,
                    target: screen,
                    keywords: screen.keywords
                };
                return opt;
            }))), [applicationMenu])
}

const StyledPopper = function (props: PopperProps) {
    return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
};

function filterOptions(
    options: ISearchOption[],
    { inputValue }: FilterOptionsState<ISearchOption>
) {
    if (!inputValue || !inputValue.length) {
        return [];
    }

    const terms = inputValue.split(" ");
    if (!terms) {
        return [];
    }

    // reduceRight will mean sorting is done by score for the _first_ entered word.
    return terms.reduceRight((results, term) => {
        return matchSorter<ISearchOption>(results, term, {
            keys: [
                ({ menuLabel }) => menuLabel,
                ({ screenLabel }) => screenLabel,
                ({ parent2MenuLabel }) => parent2MenuLabel,
                ({ parent1MenuLabel }) => parent1MenuLabel,
                ({ keywords }) => keywords,
            ]
        } as MatchSorterOptions<ISearchOption>);
    }, options);
}

export default function AppSearchBar() {

    const options = useSearchOptions()

    const { searchShortcutGuid } = useReduxSelections("app");
    const inputRef = React.useRef<HTMLInputElement>();
    React.useEffect(() => {
        if (!searchShortcutGuid || !inputRef?.current) {
            return;
        }
        inputRef.current.focus();
    }, [searchShortcutGuid, inputRef])
    const renderOption = useCallback(({ menuIcon, menuLabel, parent2MenuLabel, parent1MenuLabel, target }: ISearchOption) =>
        <ListItem
            dense={true} button={false} style={{ padding: 0 }} component={ScreenLink} destination={target}>
            <ListItemIcon><Icon>{menuIcon}</Icon></ListItemIcon>
            <ListItemText primary={menuLabel} secondary={`${parent1MenuLabel}/${parent2MenuLabel}`} />
        </ListItem>, [])

    const getLabel = useCallback((option: ISearchOption) => option.menuLabel ?? "", [])
    const navigate = useNavigate();
    // const navigate = useMenuTargetLevel3()

    const handleOnSelected = (j: React.ChangeEvent<{}>, searchOption: ISearchOption | null) => {
        searchOption && navigate(generatePath(searchOption.target.urlTemplate, searchOption.target.params));
    };

    const renderInput = (params: AutocompleteRenderInputParams) => {
        return <SearchPaper ref={params.InputProps.ref}>
            <SearchInput
                placeholder="Find screen"
                inputRef={inputRef}
                inputProps={params.inputProps} />
            <StyledSearchIcon />
        </SearchPaper>;
    }

    return <Autocomplete
        PopperComponent={StyledPopper}
        filterOptions={filterOptions}
        options={options}
        // value={currentValue}
        onChange={handleOnSelected}
        autoHighlight={true}
        renderOption={renderOption}
        renderInput={renderInput}
        getOptionLabel={getLabel}
    />
}
