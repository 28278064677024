import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { IDocumentDefinitionModel, IFeatureModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import DocumentDefinitionData from "./DocumentDefinitionData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import DocumentStructure from "./DocumentStructure";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { isNumber, useNumber } from "../../tools/lib/utility";
import { useEffect } from "react";

export const documentDefinitionScreen = {
    route: "/documentDefinitions/:id/:tab?" as const,
    component: DocumentDefinition,
    label: "Document Definition",
    tabs: {
        detail: "details",
        definition: "definition"
    },
}


function DocumentDefinition() {
    const { classificationTypes } = useReduxSelections("noteClassificationType");
    const { referenceReportTemplateCategories = {} } = useReduxSelections("reference");
    const {
        documentDefinition,
        documentDefinitionLoading,
        documentDefinitionReportTemplates = []
    } = useReduxSelections("documentDefinition");
    const {
        all: notes
    } = useReduxSelections("note");
    const {
        documentDefinitionDelete,
        documentDefinitionSave,
        documentDefinitionLoad
    } = useReduxActions("documentDefinition");

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof documentDefinitionScreen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (isNumber(idNum))
            documentDefinitionLoad(idNum)
    }, [idNum, documentDefinitionLoad]);


    if (!documentDefinition) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={false} />;
    }
    const title = !!documentDefinition.id ? (documentDefinition.name || "") : `New Definition`;
    const tabs: ISubMenuTabs<typeof documentDefinitionScreen>[] = [{
        label: "Data",
        value: "detail"
    }, {
        label: "Definition",
        value: "definition"
    }];

    const handleDelete = () => {
        if (documentDefinition?.id) {
            documentDefinitionDelete(documentDefinition.id);
        }
    }
    const handleSubmit = (values: IDocumentDefinitionModel, { setSubmitting }: FormikHelpers<IDocumentDefinitionModel>) => {
        documentDefinitionSave(values);
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={documentDefinition} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<IDocumentDefinitionModel>) {
        return <WriteAccess value={IFeatureModel.MarketDataWrite}><DetailPanel
            isQuerying={documentDefinitionLoading}
            title={title}
            canSave={dirty && isValid}
            noPadding={tabValue === "definition"}
            onSaveClick={submitForm}
            canDelete={!!values.id}
            onDeleteClick={handleDelete}
            saveAllowed={IFeatureModel.MarketDataWrite}
            deleteAllowed={IFeatureModel.MarketDataWrite}
            badge={!values.id ? "new" : undefined}
            tabs={tabs}
            tabValue={tabValue}>
            {(tabValue === "detail") && <DocumentDefinitionData
                reportTemplateCategories={referenceReportTemplateCategories}
                classificationTypes={classificationTypes}
                reportTemplates={documentDefinitionReportTemplates} />}
            {(tabValue === "definition") && <DocumentStructure notes={notes} />}
        </DetailPanel></WriteAccess>
    }
}
