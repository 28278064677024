import produce from "immer";
import { ReportModel, IGetReportModel, IGetReportsModel, IParameterMetadataModel, IReportTemplateSummaryModel, IReportSummaryModel, IDocumentDefinitionSummaryModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IState {
    reportAllLoading: boolean;
    reportLoading: boolean;
    reports: IReportSummaryModel[];
    // reports: IGetReportsModel;
    reportCurrent?: IGetReportModel;
    reportSaving: boolean;
    reportDeleting: boolean;
    reportTemplateMetadataSearchLoading: boolean;
    reportParameterMetadata?: IParameterMetadataModel[];
    reportReportTemplates?: IReportTemplateSummaryModel[];
    reportDocumentDefinitions?: IDocumentDefinitionSummaryModel[];
    reportGenerating: boolean;
    dictionaries: {
        reportTemplates: Record<string | number, IReportTemplateSummaryModel>;
        documentDefinitions: Record<string | number, IDocumentDefinitionSummaryModel>;
    }
}
export const ActionFactories = produceActionFactories({
    reportGenerate: (payload: number) => payload,
    reportGenerated: () => undefined,
    reportLoad: (payload: number) => payload,
    reportLoaded: (payload: IGetReportModel) => payload,
    reportSave: (payload: ReportModel) => payload,
    reportSaved: (payload: ReportModel) => payload,
    reportDelete: (payload: number) => payload,
    reportDeleted: (payload: number) => payload,
    reportLoadAll: () => undefined,
    reportLoadedAll: (payload: IGetReportsModel) => payload,
    reportReportTemplateLoadedAll: (payload: IReportTemplateSummaryModel[]) => payload,
    reportDocumentDefinitionsLoadedAll: (payload: IDocumentDefinitionSummaryModel[]) => payload,
    reportTemplateMetadataSearchLoad: (payload: number) => payload,
    reportTemplateMetadataSearchLoaded: (payload: IParameterMetadataModel[]) => payload,
    reportDocumentMetadataSearchLoad: (payload: number) => payload,
    reportDocumentMetadataSearchLoaded: (payload: IParameterMetadataModel[]) => payload,
    reportSelectReportTemplate: (payload: number | undefined) => payload,
    reportSelectReportDocument: (payload: number | undefined) => payload,
});

export function reducer(
    state: IState = {
        reportGenerating: false,
        reportLoading: false,
        reportAllLoading: false,
        reportSaving: false,
        reportDeleting: false,
        reportTemplateMetadataSearchLoading: false,
        reports: [],
        dictionaries: {
            reportTemplates: {},
            documentDefinitions: {}
        }
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "reportGenerate":
                draft.reportGenerating = true;
                break;
            case "reportGenerated":
                draft.reportGenerating = false;
                break;
            case "reportReportTemplateLoadedAll":
                draft.reportReportTemplates = action.payload;
                break;
            case "reportDocumentDefinitionsLoadedAll":
                draft.reportDocumentDefinitions = action.payload;
                break;
            case "reportTemplateMetadataSearchLoad":
                draft.reportTemplateMetadataSearchLoading = true;
                draft.reportParameterMetadata = undefined;
                break;
            case "reportTemplateMetadataSearchLoaded":
                draft.reportTemplateMetadataSearchLoading = false;
                draft.reportParameterMetadata = action.payload;
                break;
            case "reportDocumentMetadataSearchLoad":
                draft.reportTemplateMetadataSearchLoading = true;
                draft.reportParameterMetadata = undefined;
                break;
            case "reportDocumentMetadataSearchLoaded":
                draft.reportTemplateMetadataSearchLoading = false;
                draft.reportParameterMetadata = action.payload;
                break;
            case "reportLoadAll":
                draft.reportAllLoading = true;
                break;
            case "reportLoadedAll":
                draft.reportAllLoading = false;
                const { reports, ...dictionaries } = action.payload;
                draft.reports = reports;
                draft.dictionaries = { ...draft.dictionaries, ...dictionaries };
                break;
            case "reportLoad":
                draft.reportLoading = true;
                draft.reportCurrent = undefined;
                break;
            case "reportLoaded":
                draft.reportLoading = false;
                draft.reportCurrent = action.payload;
                break;
            case "reportSave":
                draft.reportSaving = true;
                break;
            case "reportSaved":
                draft.reportSaving = false;
                const saved = action.payload;
                if (draft.reportCurrent) {
                    draft.reportCurrent.report = saved;
                }
                const existing = draft.reports.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.reports.push(saved);
                }
                break;
            case "reportDelete":
                draft.reportDeleting = true;
                break;
            case "reportDeleted":
                draft.reportDeleting = false;
                const deletedId = action.payload;
                if (draft.reportCurrent?.report?.id === deletedId) {
                    delete draft.reportCurrent;
                }
                const idx = draft.reports.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.reports.splice(idx, 1);
                }
                break;
        }
    });
}
