import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box, { BoxProps } from "@material-ui/core/Box";
import clsx from "clsx";

const useStyle = makeStyles(theme => createStyles({
    verticalDirection: {
        width: "100%",
        "& > *:not(:first-child)": {
            marginTop: theme.spacing(2)
        }
    },
    horizontalDirection: {
        width: "100%",
        alignItems: "baseline",
        "& > *:not(:first-child)": {
            marginLeft: theme.spacing(2)
        }
    },
    horizontalDirectionNoBaseline: {
        width: "100%",
        "& > *:not(:first-child)": {
            marginLeft: theme.spacing(2)
        }
    }
}))

interface FieldBoxProps extends BoxProps {
    noBaseline?: boolean;
}

export default function FieldBox({children, noBaseline, ...boxProps}: FieldBoxProps) {
    const classes = useStyle()
    const getClassName = () => {
        const {flexDirection, display} = boxProps;
        if (display === "flex") {
            if (flexDirection === "column") {
                return classes.verticalDirection;
            } else if (noBaseline) {
                return classes.horizontalDirectionNoBaseline;
            } else {
                return classes.horizontalDirection;
            }
        }
        return;
    };

    return <Box className={clsx("FieldBox", getClassName())} {...boxProps}>
        {children}
    </Box>;
};

