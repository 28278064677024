import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, IReportTemplateCategoryModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import FieldBox from "tools/components/FieldBox";
import { isNumber, oProps, useNumber } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";

export const reportTemplateCategoryScreen = {
    route: "/config/reporting/templatecategories/:id" as const,
    label: "Report Template Category",
    component: ReportTemplateCategory,
}
export default function ReportTemplateCategory() {
    const {
        reportTemplateCategoryCurrent,
        reportTemplateCategoryLoading,
        reportTemplateCategorySaving
    } = useReduxSelections("reportTemplateCategory");
    const {
        reportTemplateCategoryDelete,
        reportTemplateCategorySave,
        reportTemplateCategoryLoad
    } = useReduxActions("reportTemplateCategory");

    const {id} = useScreenParams<typeof reportTemplateCategoryScreen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (isNumber(idNum))
            reportTemplateCategoryLoad(idNum)
    }, [idNum, reportTemplateCategoryLoad]);

    if (!reportTemplateCategoryCurrent) {
        return <DetailPanelLoading hasSubTitle={false}/>;
    }
    const handleDelete = () => {
        if (reportTemplateCategoryCurrent?.id) {
            reportTemplateCategoryDelete(reportTemplateCategoryCurrent.id);
        }
    }

    const handleSubmit = (values: IReportTemplateCategoryModel, {setSubmitting}: FormikHelpers<IReportTemplateCategoryModel>) => {
        reportTemplateCategorySave(values);
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={reportTemplateCategoryCurrent} enableReinitialize={true}
                   validateOnMount={true}>{renderForm}</Formik>;

    function renderForm(formikProps: FormikProps<IReportTemplateCategoryModel>) {
        if (!reportTemplateCategoryCurrent) {
            return;
        }
        const {dirty, isValid, submitForm, values: {id, name}} = formikProps;

        const title = !!id ? name : 'New report Template Category';

        return <WriteAccess value={IFeatureModel.ReportTemplateWrite}>
            <DetailPanel
                isQuerying={reportTemplateCategoryLoading || reportTemplateCategorySaving}
                title={title}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!id}
                onDeleteClick={handleDelete}
                saveAllowed={IFeatureModel.ReportTemplateWrite}
                deleteAllowed={IFeatureModel.ReportTemplateWrite}
                badge={!id ? "new" : undefined}>
                <FieldBox display="flex" flexDirection="column">
                    <FormTextField name={oProps<IReportTemplateCategoryModel>().path("name")} label="Name"
                                   required={true} maxLength={250}/>
                </FieldBox>
            </DetailPanel>
        </WriteAccess>
    }
}
