import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { useCallback, useEffect, useMemo } from "react";
import { IFeatureModel } from "proxy/apiProxy";
import { useScreenParams  } from "tools/routing/screenRouteHooks";
import { isNumber, oProps, useNumber } from "tools/lib/utility";
import DocumentRelated from "features/Document/DocumentScreen/DocumentRelated";
import DocumentData from "features/Document/DocumentScreen/DocumentData";
import { IDocumentData } from "../slice";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import RelatedDialog from "./RelatedDialog";

export const documentScreen = {
    route: "/documents/:id/:tab?" as const,
    component: Document,
    label: "Document",
    tabs: {
        detail: "details",
        related: "related"
    }
}

export default function Document() {
    const {id, tab: tabValue = "detail"} = useScreenParams<typeof documentScreen>();
    const idNum = useNumber(id);

    const { documentDelete, documentSave, documentLoadFile } = useReduxActions("document");
    const { documentCurrent, documentLoading, documentSaving, dictionaries } = useReduxSelections("document");
    const { classificationTypes } = useReduxSelections("documentClassificationType");

    const { documentLoad, documentAddRelatedToDictionary } = useReduxActions("document");

    const [openRelatedDialog, setOpenRelatedDialog] = React.useState(false);

    useEffect(() => {
        if (isNumber(idNum)) documentLoad(idNum)
    }, [documentLoad, idNum]);

    const handleFileLoad = () => {
        if (!documentCurrent) return;
        documentLoadFile(documentCurrent.id);
    }

    const handleDelete = useCallback(() => {
        if (documentCurrent?.id) {
            documentDelete(documentCurrent?.id);
        }
    }, [documentCurrent?.id, documentDelete]);

    const handleSubmit = (values: IDocumentData, { setSubmitting }: FormikHelpers<IDocumentData>) => {
        documentSave(values);
        setSubmitting(false);
    }

    const formValues: IDocumentData | undefined = useMemo(() => {
        if (!documentCurrent) {
            return undefined;
        }
        const ret: IDocumentData = {
            document: documentCurrent
        };
        return ret;
    }, [documentCurrent]);

    if (!formValues) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={false} />;
    }

    const handleOpenRelatedDialog = () => setOpenRelatedDialog(true);
    const handleCloseRelatedDialog = () => setOpenRelatedDialog(false);

    const extraActionButtons =
        (tabValue === "related")
            ? [{
                onClick: handleOpenRelatedDialog,
                label: "Add related",
                icon: AddIcon,
            }]
            : [];

    const title = "Document";

    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm }: FormikProps<IDocumentData>) {
        const tabs: ISubMenuTabs<typeof documentScreen>[] = [
            {
                label: "Data",
                value: "detail"
            },
            {
                label: "Related",
                value: "related"
            }
        ];

        if (!documentCurrent) return null;

        return <WriteAccess value={IFeatureModel.DocumentWrite}>
            {openRelatedDialog && <RelatedDialog
                title="Add related"
                showSummary={false}
                name={oProps<IDocumentData>().path("document", "related")}
                isOpened={openRelatedDialog}
                onCancel={handleCloseRelatedDialog}
                dictionaries={dictionaries}
                onSelected={documentAddRelatedToDictionary} />}
            <DetailPanel
                isQuerying={documentLoading || documentSaving}
                title={title}
                actions={extraActionButtons}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!documentCurrent.id}
                onDeleteClick={handleDelete}
                badge={!documentCurrent.id ? "new" : undefined}
                saveAllowed={IFeatureModel.DocumentWrite}
                deleteAllowed={IFeatureModel.DocumentWrite}
                tabs={tabs}
                tabValue={tabValue}>
                {(tabValue === "detail") && <DocumentData
                    classificationTypes={classificationTypes}
                    onFileLoad={handleFileLoad}
                />}
                {(tabValue === "related") && <DocumentRelated
                    dictionaries={dictionaries}
                />}
            </DetailPanel>
        </WriteAccess>
    }
}
