import * as React from "react";
import { IIndexSummaryModel } from "proxy/apiProxy";
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';

export interface IIndexSearchProps {
    label?: React.ReactNode;
    onSelected?: (found: IIndexSummaryModel) => void;
}
export function IndexSearch({ label, onSelected }: IIndexSearchProps) {
    const { referenceIndexSearch } = useReduxActions("reference");
    const { referenceIndexSearched, referenceIndexSearching } = useReduxSelections("reference");
    const getOptionLabel = (index: IIndexSummaryModel) => index.name;
    label = <><SearchIcon fontSize="small"/> {label ?? "Index"}: Type criteria to find an index</>;
    const handleValueSelected = (index: IIndexSummaryModel) => {
        if (onSelected) {
            onSelected(index)
        }
    }
    return <ReadOnlyContext.Consumer>{readOnly => readOnly ? null :
        <AsyncSelect onSearchOptions={referenceIndexSearch} fullWidth={true} options={referenceIndexSearched}
            getOptionLabel={getOptionLabel} searching={referenceIndexSearching} label={label} onRequiredValueSelected={handleValueSelected} />}
    </ReadOnlyContext.Consumer>
}
