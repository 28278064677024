import * as React from "react";
import autobind from "autobind-decorator";
import { withStyles, Theme, StyleRules, createStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules => createStyles({
    sticky: {
        // margin: theme.spacing(1),
        position: "sticky",
        bottom: 0,
        right: 0,
        zIndex: 1200,
        display: "flex",
        gap: theme.spacing(1)
    }
});

type IProps = React.HTMLAttributes<HTMLDivElement> & WithStyles;

@autobind
class StickyContainer extends React.PureComponent<IProps> {
    public render() {
        const { children, classes, ...props } = this.props;
        return <div {...props} className={classes.sticky}>
            {children}
        </div>;
    }
}

export default withStyles(styles)(StickyContainer);
