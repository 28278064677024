import * as React from "react";
import { useEffect } from "react";
import {
    DevelopmentItemModel,
    DevelopmentItemSummaryModel,
    IDevelopmentItemModel,
    IDevelopmentItemSummaryModel,
    IDevelopmentItemTypeModel,
    IFeatureModel
} from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import SearchPanel from "tools/components/SearchPanel";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IActionButton } from "tools/components/FabContainer";
import AddIcon from '@material-ui/icons/Add';
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import { getDevelopmentItemTypeLabel } from "./getDevelopmentItemTypeLabel";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";


export const developmentItemsScreen = {
    route: "/developmentItems",
    label: "Development Items",
    keywords: "code c# cs csharp dotnet import upload etl custom screen",
    component: DevelopmentItems,
}

function DevelopmentItems() {

    const { developmentItemList, developmentItemStates: { listLoading: developmentItemListLoading } } = useReduxSelections("developmentItem");
    const {
        classificationTypes,
        classificationTypesFlat
    } = useReduxSelections("developmentItemClassificationType");
    const { developmentItemLoadAll } = useReduxActions("developmentItem")
    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, ({ classifications }: (IDevelopmentItemModel | IDevelopmentItemSummaryModel)) => classifications);

    useEffect(() => {
        developmentItemLoadAll()
    }, [developmentItemLoadAll]);

    const navigate = useScreenNavigate();
    const handleRowSelect = ({ id }: (DevelopmentItemSummaryModel | DevelopmentItemModel)) =>
        navigate("DevelopmentItem", { id });
    const isGranted = useGrants();
    const columns: IColumnDefinition[] = [
        {
            name: "Name",
            title: "Name",
            getCellValue: (row: (DevelopmentItemSummaryModel | DevelopmentItemModel)) => row.name,
            positionToKeep: "left",
            filteringEnabled: true
        },
        {
            name: "Type",
            title: "Type",
            getCellValue: (row: (DevelopmentItemSummaryModel | DevelopmentItemModel)) => getDevelopmentItemTypeLabel(row.type),
            positionToKeep: "left"
        },
        {
            name: "Version",
            title: "Version",
            getCellValue: (row: (DevelopmentItemSummaryModel | DevelopmentItemModel)) => row.versionInfo?.number,
        },
        {
            name: "LastUpdate",
            title: "Last update",
            getCellValue: (row: (DevelopmentItemSummaryModel | DevelopmentItemModel)) => row.versionInfo?.updatedOn,
            columnType: "dateTime"
        },
        ...classificationTypesColumns
    ];

    const state: IGridState = {
        "Name": { width: 320 },
        "Type": { width: 120, groupingPosition: 1 },
        "Version": { width: 120 },
        "LastUpdate": { width: 140 },
        ...classificationTypesState
    };

    const getRowKey = (row: (DevelopmentItemSummaryModel | DevelopmentItemModel)) => row.id || 0;
    const extraActionButtons: IActionButton[] = React.useMemo(() => {
        const actions = Object.values(IDevelopmentItemTypeModel)
            .map(key => ({
                label: getDevelopmentItemTypeLabel(key),
                onClick: () => navigate("DevelopmentItem", { id: key })
            }));
        if (!actions.length) {
            return [];
        }
        return [{
            label: "Add macro",
            icon: AddIcon,
            actions: actions,
            disabled: !isGranted(IFeatureModel.DevelopmentItemsWrite)
        }];
    }, [isGranted, navigate]);

    return <>

        <SearchPanel
            title="Development Items"
            isQuerying={developmentItemListLoading}
            actions={extraActionButtons}
        >
            <ExtendedGrid
                rows={developmentItemList}
                columns={columns}
                getRowId={getRowKey}
                onRowClick={handleRowSelect}
                initialState={state}
                userCanGroup={true}
                defaultExportFileName="Macros.xlsx" />
        </SearchPanel>
    </>
}

