import {
    RegisterTransactionSummaryModel,
    RegisterTransactionModel,
    IRegisterTradeTypeModel,
    isSecurityInstrumentSummaryModel,
    IFeatureModel,
    IRegisterShareClassTransactionSummaryModel,
    IRegisterShareClassTransactionModel,
    ITransactionBaseTypeModel,
    IRegisterTransactionsSearchParameters
} from "proxy/apiProxy";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { FormikProps, Formik, FormikHelpers } from "formik";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { getEnumLabels, IErrors, oProps } from "tools/lib/utility";
import FieldBox from "tools/components/FieldBox";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FormTextField from "tools/fieldComponents/FormTextField";
import CashUsdOutlineIcon from 'mdi-material-ui/Cash';
import CheckbookIcon from 'mdi-material-ui/Checkbook';
import ManagedPortfolioMultiSelectField from "components/searchers/ManagedPortfolioMultiSelectField";
import AddIcon from '@material-ui/icons/Add';
import { IActionButton, IMenuActionButton } from "tools/components/FabContainer";
import { getPortfolioTypeLabel } from "features/ManagedPortfolio/getPortfolioTypeLabel";
import { getRegisterTransactionTypeLabel } from "./getRegisterTransactionTypeLabel";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";

const registerTradeTypes = getEnumLabels(IRegisterTradeTypeModel);
const transactionBaseTypes = getEnumLabels(ITransactionBaseTypeModel);

export const registerTransactionsScreen = {
    route: "/portfoliodata/registertransactions",
    component: RegisterTransactions,
    label: "Register Transactions",
    keywords: "sub red",
}

function RegisterTransactions() {
    const {
        registerTransactionsSearch,
        registerTransactionAddPortfoliosToDictionary
    } = useReduxActions("registerTransaction");
    const { transactionsLoading, dictionaries, transactions = [] } = useReduxSelections("registerTransaction");
    const { portfolios, securities, relationships, entities } = dictionaries;
    const navigate = useScreenNavigate();
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    const getRelationshipLabel = (relationshipId: number | undefined) => {
        if (!relationshipId) {
            return undefined;
        }
        const relationship = relationships[relationshipId];
        if (!relationship) {
            return undefined;
        }
        const entity = entities[relationship.entityId];
        if (!entity) {
            return undefined;
        }
        if (entity.type === "PersonSummaryModel") {
            return `${entity.firstName} ${entity.lastName}`;
        }
        return entity.name;
    }
    const columns: IColumnDefinition[] = [
        {
            name: "Type",
            title: "Type",
            getCellValue: ({ type }: RegisterTransactionSummaryModel | RegisterTransactionModel) => getRegisterTransactionTypeLabel(type)
        },
        {
            name: "PortfolioName",
            title: "Prtf Name",
            getCellValue: ({ portfolioId }: RegisterTransactionSummaryModel | RegisterTransactionModel) => portfolios[portfolioId].name,
            aggregationType: "count",
            filteringEnabled: true
        },
        {
            name: "PortfolioCode",
            title: "Prtf Code",
            getCellValue: ({ portfolioId }: RegisterTransactionSummaryModel | RegisterTransactionModel) => portfolios[portfolioId].internalCode,
            filteringEnabled: true
        },
        {
            name: "PortfolioType",
            title: "Prtf Type",
            getCellValue: ({ portfolioId }: RegisterTransactionSummaryModel | RegisterTransactionModel) => getPortfolioTypeLabel(portfolios[portfolioId].type)
        },
        {
            name: "PortfolioCcy",
            title: "Prtf Ccy",
            getCellValue: ({ portfolioId }: RegisterTransactionSummaryModel | RegisterTransactionModel) => referenceCurrencies[portfolios[portfolioId].currencyId ?? 0].isoCode
        },
        {
            name: "Code",
            title: "Transaction Code",
            getCellValue: ({ transactionCode }: RegisterTransactionSummaryModel | RegisterTransactionModel) => transactionCode,
            filteringEnabled: true
        },
        {
            name: "Description",
            title: "Description",
            getCellValue: ({ description }: RegisterTransactionSummaryModel | RegisterTransactionModel) => description,
            filteringEnabled: true
        },
        {
            name: "TradeDate",
            title: "Trade Date",
            getCellValue: ({ tradeDate }: RegisterTransactionSummaryModel | RegisterTransactionModel) => tradeDate,
            columnType: "date"
        },
        {
            name: "NavDate",
            title: "Nav Date",
            getCellValue: ({ navDate }: RegisterTransactionSummaryModel | RegisterTransactionModel) => navDate,
            columnType: "date"
        },
        {
            name: "Commented",
            title: "Commented",
            getCellValue: (t: RegisterTransactionSummaryModel | RegisterTransactionModel) => (t.type === "RegisterPortfolioTransactionSummaryModel" || t.type === "RegisterShareClassTransactionSummaryModel") ? t.hasComment : Object.keys(t.comment ?? {}).length,
            columnType: "boolean"
        },
        {
            name: "Cancelled",
            title: "Cancelled",
            getCellValue: ({ canceled }: RegisterTransactionSummaryModel | RegisterTransactionModel) => !!canceled,
            columnType: "boolean"
        },


        {
            name: "ShareClassCode",
            title: "Sc Code",
            getCellValue: ({ shareClassId }: IRegisterShareClassTransactionSummaryModel | IRegisterShareClassTransactionModel) => (!shareClassId) ? undefined : securities[shareClassId]?.internalCode,
            filteringEnabled: true
        },
        {
            name: "ShareClassName",
            title: "Sc Name",
            getCellValue: ({ shareClassId }: IRegisterShareClassTransactionSummaryModel | IRegisterShareClassTransactionModel) => (!shareClassId) ? undefined : securities[shareClassId]?.name,
            filteringEnabled: true
        },
        {
            name: "SecurityCcy",
            title: "Sec Ccy",
            getCellValue: ({ shareClassId }: IRegisterShareClassTransactionSummaryModel | IRegisterShareClassTransactionModel) => (!shareClassId) ? undefined : referenceCurrencies[securities[shareClassId].currencyId ?? 0]?.isoCode
        },
        {
            name: "ShareClassIsin",
            title: "Sc Isin",
            getCellValue: ({ shareClassId }: IRegisterShareClassTransactionSummaryModel | IRegisterShareClassTransactionModel) => {
                if (!shareClassId) {
                    return undefined;
                }
                const secu = securities[shareClassId];
                return isSecurityInstrumentSummaryModel(secu) ? secu.isin : undefined
            },
            filteringEnabled: true
        },
        {
            name: "Investor",
            title: "Investor",
            getCellValue: ({ investorRelationshipId }: RegisterTransactionSummaryModel | RegisterTransactionModel) => getRelationshipLabel(investorRelationshipId),
            filteringEnabled: true
        },
        {
            name: "Intermediary",
            title: "Intermediary",
            getCellValue: ({ intermediaryId }: RegisterTransactionSummaryModel | RegisterTransactionModel) => getRelationshipLabel(intermediaryId),
            filteringEnabled: true
        },
        {
            name: "OrderType",
            title: "Order Type",
            getCellValue: ({ orderType }: RegisterTransactionSummaryModel | RegisterTransactionModel) => registerTradeTypes[orderType]
        },
        {
            name: "TransactionBaseType",
            title: "Transaction Base Type",
            getCellValue: ({ transactionBaseType }: RegisterTransactionSummaryModel | RegisterTransactionModel) => transactionBaseTypes[transactionBaseType]
        },
        {
            name: "NbShares",
            title: "Nb Shares",
            getCellValue: ({ nbShares }: RegisterTransactionSummaryModel | RegisterTransactionModel) => nbShares,
            columnType: "decimal",
            aggregationType: "sum"
        },
        {
            name: "Nav",
            title: "Nav",
            getCellValue: ({ nav }: RegisterTransactionSummaryModel | RegisterTransactionModel) => nav,
            columnType: "decimal"
        },
        {
            name: "GrossAmount",
            title: "Gross Amnt",
            getCellValue: ({ grossAmount }: RegisterTransactionSummaryModel | RegisterTransactionModel) => grossAmount,
            columnType: "decimal",
            aggregationType: "sum"
        },
        {
            name: "NetAmount",
            title: "NetAmnt",
            getCellValue: ({ netAmount }: RegisterTransactionSummaryModel | RegisterTransactionModel) => netAmount,
            columnType: "decimal",
            aggregationType: "sum"
        },
        {
            name: "Equalisation",
            title: "Equalisation",
            getCellValue: ({ equalisation }: RegisterTransactionSummaryModel | RegisterTransactionModel) => equalisation,
            columnType: "decimal",
            aggregationType: "sum"
        },


    ];
    const state: IGridState = {
        "Type": { width: 120, hidden: true },
        "PortfolioName": { width: 280 },
        "PortfolioCode": { width: 150, hidden: true },
        "PortfolioType": { width: 120, hidden: true },
        "PortfolioCcy": { width: 150, hidden: true },
        "Code": { width: 150 },
        "Description": { width: 150 },
        "TradeDate": { width: 100 },
        "NavDate": { width: 100, hidden: true },
        "Commented": { width: 120 },
        "Cancelled": { width: 120 },
        "ShareClassCode": { width: 150, hidden: true },
        "ShareClassName": { width: 280 },
        "ShareClassCcy": { width: 120 },
        "ShareClassIsin": { width: 150, hidden: true },
        "Investor": { width: 200 },
        "Intermediary": { width: 200, hidden: true },
        "OrderType": { width: 100 },
        "TransactionBaseType": { width: 100 },
        "NbShares": { width: 100 },
        "Nav": { width: 100 },
        "GrossAmount": { width: 120 },
        "NetAmount": { width: 120 },
        "Equalisation": { width: 100 },

    };

    const getRowKey = (row: RegisterTransactionSummaryModel | RegisterTransactionModel) => row.id;

    const handleSubmit = (model: IRegisterTransactionsSearchParameters, { setSubmitting }: FormikHelpers<IRegisterTransactionsSearchParameters>) => {
        registerTransactionsSearch(model);
        setSubmitting(false);
    }
    const handleValidate = (values: IRegisterTransactionsSearchParameters) => {
        const errors: IErrors<IRegisterTransactionsSearchParameters> = {};
        if (!values.lastOnly) {
            if (!values.from) {
                errors.from = "From date is required";
            }
            if (values.to && values.from && values.from > values.to) {
                errors.to = "Must be greater than the start of period";
            }
        }
        return errors;
    }
    const handleRowSelect = ({ id }: RegisterTransactionSummaryModel | RegisterTransactionModel) =>
        navigate("RegisterTransaction", { id });
    const handleAddPortfolioTransaction = () =>
        navigate("RegisterTransaction", { id: "portfolio" });
    const handleAddShareClassTransaction = () =>
        navigate("RegisterTransaction", { id: "shareclass" });

    const monthToDate = (function () {
        let date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    })();

    return <Formik onSubmit={handleSubmit} validate={handleValidate} initialValues={{
        portfolioRelated: true,
        shareClassRelated: true,
        lastOnly: true,
        from: monthToDate
    } as IRegisterTransactionsSearchParameters} enableReinitialize={true} validateOnMount={true}>{renderForm}</Formik>;

    function renderForm({ isValid, submitForm, values: { lastOnly } }: FormikProps<IRegisterTransactionsSearchParameters>) {
        const extraActionButtons: IActionButton[] = [{
            label: "Add transaction",
            icon: AddIcon,
            feature: IFeatureModel.ManagedPortfolioWrite,
            actions: [{
                label: "Add portfolio transaction",
                onClick: handleAddPortfolioTransaction,
                icon: CashUsdOutlineIcon,
            }, {
                label: "Add shareclass transaction",
                onClick: handleAddShareClassTransaction,
                icon: CheckbookIcon,
            }]
        } as IMenuActionButton];
        return (
            <SearchPanel
                isQuerying={transactionsLoading}
                onSearchClick={submitForm}
                searchDisabled={!isValid}
                actions={extraActionButtons}
                title="Register Transactions"
                subTitle="Search by portfolio and time period"
                renderSearch={(<FieldBox display="flex" flexDirection="column">
                    <FieldBox display="flex" flexGrow="1">
                        <ManagedPortfolioMultiSelectField
                            name={oProps<IRegisterTransactionsSearchParameters>().path("portfolioIds")}
                            label="Portfolios"
                            dictionaries={dictionaries}
                            onSelected={registerTransactionAddPortfoliosToDictionary} />
                    </FieldBox>
                    <FieldBox display="flex" flexDirection="row">
                        <FormTextField name={oProps<IRegisterTransactionsSearchParameters>().path("criterias")}
                            label="Code" />
                        <FormCheckBoxField
                            name={oProps<IRegisterTransactionsSearchParameters>().path("portfolioRelated")}
                            label="Portfolio" />
                        <FormCheckBoxField
                            name={oProps<IRegisterTransactionsSearchParameters>().path("shareClassRelated")}
                            label="ShareClass" />
                    </FieldBox>
                    <FieldBox display="flex" flexDirection="row">
                        <FormCheckBoxField name={oProps<IRegisterTransactionsSearchParameters>().path("lastOnly")}
                            label="Only Last Transactions" />
                        {(!lastOnly) &&
                            <FormDatePickerField name={oProps<IRegisterTransactionsSearchParameters>().path("from")}
                                label="From" required={true} />}
                        {(!lastOnly) &&
                            <FormDatePickerField name={oProps<IRegisterTransactionsSearchParameters>().path("to")}
                                label="To" />}
                    </FieldBox>
                </FieldBox>
                )}>
                <ExtendedGrid
                    getRowId={getRowKey}
                    columns={columns}
                    rows={transactions}
                    onRowClick={handleRowSelect}
                    initialState={state}
                    userCanGroup={true}
                    defaultExportFileName="transactions.xlsx" />
            </SearchPanel>
        );
    }
}
