import { IDocumentAllRelatedModel } from "proxy/apiProxy";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { useField } from "formik";
import { cashMovementLinks, entityLinks, GetAllSummaries, portfolioLinks, relationshipLinks, securityLinks, toTitleCase, transactionLinks } from "../utils";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import CloseIcon from '@material-ui/icons/Close';
import { LinkOff } from "@material-ui/icons";
import { isEntityScreenLinkType, isPortfolioScreenLinkType, isRelationshipScreenLinkType, isSecurityScreenLinkType, ScreenLinkType } from "tools/objectViewer/DashboardFormattingContracts";

interface IDocumentRelatedGridProps {
    name: string;
    dictionaries: GetAllSummaries;
}

interface TypedRelated {
    type: ScreenLinkType;
    id: number;
}

export function DocumentRelatedGrid({ name, dictionaries }: IDocumentRelatedGridProps) {
    const [, { value }, { setValue }] = useField<IDocumentAllRelatedModel | undefined>({ name });

    const related: TypedRelated[] = [];
    if (value?.entityIds) {
        const personIds = value.entityIds.filter(id => dictionaries.entities[id].type === "PersonSummaryModel");
        const companyIds = value.entityIds.filter(id => dictionaries.entities[id].type === "CompanySummaryModel");
        const entityGroupIds = value.entityIds.filter(id => dictionaries.entities[id].type === "EntityGroupSummaryModel");
        const sicavIds = value.entityIds.filter(id => dictionaries.entities[id].type === "SicavSummaryModel");

        related.push(...personIds.map(id => ({ type: ScreenLinkType.Person, id })));
        related.push(...companyIds.map(id => ({ type: ScreenLinkType.Company, id })));
        related.push(...entityGroupIds.map(id => ({ type: ScreenLinkType.EntityGroup, id })));
        related.push(...sicavIds.map(id => ({ type: ScreenLinkType.Sicav, id })));
    }
    if (value?.securityIds) {
        const cashIds = value.securityIds.filter(id => dictionaries.securities[id].type === "CashSummaryModel");
        const loanIds = value.securityIds.filter(id => dictionaries.securities[id].type === "LoanSummaryModel");
        const securityIds = value.securityIds.filter(id => dictionaries.securities[id].type !== "CashSummaryModel"
            && dictionaries.securities[id].type !== "LoanSummaryModel");

        related.push(...cashIds.map(id => ({ type: ScreenLinkType.Cash, id })));
        related.push(...loanIds.map(id => ({ type: ScreenLinkType.Loan, id })));
        related.push(...securityIds.map(id => ({ type: ScreenLinkType.Security, id })));
    }
    if (value?.portfolioIds) {
        const portfolioIds = value.portfolioIds.filter(id => dictionaries.portfolios[id].type === "DiscretionaryPortfolioSummaryModel");
        const subFundIds = value.portfolioIds.filter(id => dictionaries.portfolios[id].type === "SubFundSummaryModel");
        related.push(...portfolioIds.map(id => ({ type: ScreenLinkType.Portfolio, id })));
        related.push(...subFundIds.map(id => ({ type: ScreenLinkType.SubFund, id })));
    }
    if (value?.relationshipIds) {
        const roleIds = value.relationshipIds.filter(id => dictionaries.relationships[id].type === "RoleRelationshipSummaryModel");
        const investorIds = value.relationshipIds.filter(id => dictionaries.relationships[id].type === "InvestorRelationshipSummaryModel");
        const counterpartyIds = value.relationshipIds.filter(id => dictionaries.relationships[id].type === "CounterpartyRelationshipSummaryModel");

        related.push(...roleIds.map(id => ({ type: ScreenLinkType.Role, id })));
        related.push(...investorIds.map(id => ({ type: ScreenLinkType.Investor, id })));
        related.push(...counterpartyIds.map(id => ({ type: ScreenLinkType.Counterparty, id })));
    }
    if (value?.cashMovementIds) {
        related.push(...value.cashMovementIds.map(id => ({ type: ScreenLinkType.Movement, id })));
    }
    if (value?.transactionIds) {
        related.push(...value.transactionIds.map(id => ({ type: ScreenLinkType.PortfolioTransaction, id })));
    }

    const isEmpty = related.length === 0;

    return (<ReadOnlyContext.Consumer>
        {readOnly => <>
            {isEmpty && <Typography> <LinkOff style={{ verticalAlign: "middle" }} /> No related</Typography>}
            {!isEmpty && <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Related</TableCell>
                        {!readOnly && <TableCell />}
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>  {
                    related.map((row, idx) => {
                        return <TableRow key={idx}>
                            <TableCell>{toTitleCase(row.type)}</TableCell>
                            <TableCell>
                                {isEntityScreenLinkType(row.type) && entityLinks([row.id], dictionaries.entities)}
                                {isRelationshipScreenLinkType(row.type) && relationshipLinks([row.id], dictionaries.relationships, dictionaries.entities)}
                                {isSecurityScreenLinkType(row.type) && securityLinks([row.id], dictionaries.securities)}
                                {isPortfolioScreenLinkType(row.type) && portfolioLinks([row.id], dictionaries.portfolios)}
                                {row.type === ScreenLinkType.Movement && cashMovementLinks([row.id], dictionaries.cashMovements)}
                                {row.type === ScreenLinkType.PortfolioTransaction && transactionLinks([row.id], dictionaries.transactions)}
                            </TableCell>
                            <TableCell>
                                <Tooltip title="Unlink">
                                    <IconButton
                                        size="small"
                                        aria-label="Unlink"
                                        style={{ verticalAlign: "right", float: "right" }}
                                        onClick={() => {
                                            if (value === undefined) return;

                                            const newValue = { ...value };
                                            if (isEntityScreenLinkType(row.type)) {
                                                newValue.entityIds = newValue.entityIds?.filter(id => id !== row.id);
                                            }
                                            else if (isRelationshipScreenLinkType(row.type)) {
                                                newValue.relationshipIds = newValue.relationshipIds?.filter(id => id !== row.id);
                                            }
                                            else if (isSecurityScreenLinkType(row.type)) {
                                                newValue.securityIds = newValue.securityIds?.filter(id => id !== row.id);
                                            }
                                            else if (isPortfolioScreenLinkType(row.type)) {
                                                newValue.portfolioIds = newValue.portfolioIds?.filter(id => id !== row.id);
                                            }
                                            else if (row.type === ScreenLinkType.Movement) {
                                                newValue.cashMovementIds = newValue.cashMovementIds?.filter(id => id !== row.id);
                                            }
                                            else if (row.type === ScreenLinkType.PortfolioTransaction) {
                                                newValue.transactionIds = newValue.transactionIds?.filter(id => id !== row.id);
                                            }
                                            setValue(newValue);
                                        }} >
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>}
        </>}
    </ReadOnlyContext.Consumer>);
}
