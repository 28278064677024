import { Card, CardContent, CardMedia, Typography, makeStyles, LinearProgress } from "@material-ui/core";
import welcomePicture from "images/key_small.jpg"


const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: theme.shadows[24],
        width: 650,
        alignSelf: 'center'
    },
    media: {
        height: 0,
        paddingTop: "40%" // 16:10
    }
}));
export default function Loading() {
    const classes = useStyles();
    return <Card className={classes.card}>
        <CardMedia className={classes.media} image={welcomePicture} />
        <LinearProgress variant="query" />
        <CardContent>
            <Typography component="h5" variant="h5">
                Welcome to PMS.
            </Typography>
            <Typography component="p">
                Loading...
            </Typography>
        </CardContent>
    </Card>
}
