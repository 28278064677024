import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { IConnectorsModel, IFeatureModel } from "proxy/apiProxy";
import { FormikProps, Formik, FormikHelpers } from 'formik';
import ConnectorsScript from "./ConnectorsScript";
import ConnectorsVisualization from "./ConnectorsVisualization";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IActionButton } from "tools/components/FabContainer";
import NotGranted from 'components/global/NotGranted';
import { getConfig } from "lib/userManager";
import { useScreenParams } from "../../tools/routing/screenRouteHooks";
import { useEffect } from "react";

export const connectorsScreen = {
    component: Connectors,
    route: "/config/connectors/:tab?" as const,
    label: "Connectors",
    keywords: "ftp mail sftp ftps folders files",
    tabs: {
        visualRepresentation: "definition/visual",
        codeRepresentation: "definition",
    },
}

const { general: { disableSudo } } = getConfig();

function Connectors() {
    const {
        connectorsSave,
        connectorsProcessorTest,
        connectorsProviderTest,
        connectorsLoad
    } = useReduxActions("connector");
    const { connectorsLoading, connectorsSaving, connectors, connectorsScriptSchema = {}, connectorsState } = useReduxSelections("connector");
    const { tab: tabValue = "visualRepresentation" } = useScreenParams<typeof connectorsScreen>()
    const isGranted = useGrants();
    const title = "Connectors";

    useEffect(() => {
        connectorsLoad()
    }, [connectorsLoad]);

    if (!isGranted(IFeatureModel.ConnectorsWrite)) {
        return <DetailPanel title={title}>
            <NotGranted />
        </DetailPanel>
    }
    const handleSubmit = (model: IConnectorsModel, { setSubmitting }: FormikHelpers<IConnectorsModel>) => {
        connectorsSave(model);
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={connectors} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm, values: { jsonConfig } }: FormikProps<IConnectorsModel>) {
        const tabs :ISubMenuTabs<typeof connectorsScreen>[]= [{
            value: "visualRepresentation",
            label: "Visualization"
        }, {
            value: "codeRepresentation",
            label: "Definition"
        }];
        const extraActionButtons: IActionButton[] | undefined = undefined;
        const handleSaveClick = () => {
            if (isValid && dirty)
                submitForm();
        }
        return <DetailPanel
            isQuerying={connectorsLoading || connectorsSaving}
            title={title}
            tabs={tabs}
            tabValue={tabValue}
            noPadding={tabValue === "codeRepresentation"}
            canSave={dirty && isValid}
            onSaveClick={handleSaveClick}
            saveMustBeConfirmed={!disableSudo}
            actions={extraActionButtons}>
            {tabValue === "codeRepresentation" && <ConnectorsScript jsonSchema={connectorsScriptSchema} onSaveRequest={handleSaveClick} />}
            {tabValue === "visualRepresentation" && <ConnectorsVisualization
                testDisabled={dirty}
                script={jsonConfig}
                connectorStates={connectorsState}
                onProcessorTestRequest={connectorsProcessorTest}
                onProviderTestRequest={connectorsProviderTest} />}
        </DetailPanel>
    }
}
