import DataStreamVisualization from "components/global/DataStreamVisualization";
import { IResultErrorModel, ITaskDefinitionStructureModel } from "proxy/apiProxy";
import { Box } from "@material-ui/core";
export interface IMacroScriptVisualEtlProps {
    definitionStructure: ITaskDefinitionStructureModel;
    counters?: Record<string | number, number>;
    error?: IResultErrorModel;
}
export default function MacroScriptVisualEtl({ definitionStructure: { links, nodes }, counters, error }: IMacroScriptVisualEtlProps) {
    return <Box display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}><DataStreamVisualization
        links={links}
        nodes={nodes}
        counters={counters}
        error={error} /></Box>
}
