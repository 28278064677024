import produce from "immer";
import { DatasetResultModel, IExecuteModel, IStudioDevelopmentItemsRunCompleteQuestionnaireParameters } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";

export interface IGenericScreenDatasetLoadPayload extends IExecuteModel {
    questionnaireName: string;
}
export const ActionFactories = produceActionFactories({
    genericScreenLoad: (payload: IGenericScreenDatasetLoadPayload) => payload,
    genericScreenLayoutLoaded: (payload: object) => payload,
    genericScreenDatasetLoad: (payload: IGenericScreenDatasetLoadPayload) => payload,
    genericScreenDatasetLoaded: (payload: object) => payload,
    genericScreenSave: (payload: IStudioDevelopmentItemsRunCompleteQuestionnaireParameters) => payload,
    genericScreenSaved: (payload: DatasetResultModel) => payload,
});

export interface IState {
    genericScreenLayoutLoading: boolean;
    genericScreenSaving: boolean;
    genericScreenLayout?: object;
    genericScreenDatasetResultLoading: boolean;
    genericScreenDatasetResult?: object;
}
export type Action = AnyActionOf<typeof ActionFactories>;

export const reducer = (
    state: IState = {
        genericScreenLayoutLoading: false,
        genericScreenDatasetResultLoading: false,
        genericScreenSaving: false
    },
    action: Action
) => produce(state, draft => {
    switch (action.type) {
        case "genericScreenSave":
            draft.genericScreenSaving = true;
            break;
        case "genericScreenSaved":
            draft.genericScreenSaving = false;
            break;
        case "genericScreenLoad":
            draft.genericScreenLayoutLoading = true;
            delete draft.genericScreenLayout;
            delete draft.genericScreenDatasetResult;
            break;
        case "genericScreenLayoutLoaded":
            draft.genericScreenLayoutLoading = false;
            draft.genericScreenLayout = action.payload;
            break;
        case "genericScreenDatasetLoad":
            draft.genericScreenDatasetResultLoading = true;
            break;
        case "genericScreenDatasetLoaded":
            draft.genericScreenDatasetResultLoading = false;
            draft.genericScreenDatasetResult = action.payload;
            break;
    }
});
