import produce from "immer";
import { IEntityMetadataModel, IMacroScriptCheckResultModel, IPortfolioMacroModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IMacroScriptMetadata {
    [key: string]: IEntityMetadataModel;
    [key: number]: IEntityMetadataModel;
}
export interface IState {
    globalPortfolioDealingMacroLoading: boolean;
    globalPortfolioDealingMacro: IPortfolioMacroModel;
    globalPortfolioDealingMacroSaving: boolean;
    globalPortfolioDealingMacroCheckResult?: IMacroScriptCheckResultModel;
    globalPortfolioDealingMacroValidateScriptMetadata: IMacroScriptMetadata;
}
export const ActionFactories = produceActionFactories({
    globalPortfolioDealingLoad: () => undefined,
    globalPortfolioDealingLoaded: (payload: IPortfolioMacroModel) => payload,
    globalPortfolioDealingSave: (payload: IPortfolioMacroModel) => payload,
    globalPortfolioDealingSaved: (payload: IPortfolioMacroModel) => payload,
    globalPortfolioDealingValidateScript: (payload: string | undefined) => payload,
    globalPortfolioDealingValidatedScript: (payload: IMacroScriptCheckResultModel) => payload,
    globalPortfolioDealingMetadataLoaded: (payload: IMacroScriptMetadata) => payload
});

export function reducer(
    state: IState = {
        globalPortfolioDealingMacroLoading: false,
        globalPortfolioDealingMacro: { script: "" },
        globalPortfolioDealingMacroSaving: false,
        globalPortfolioDealingMacroValidateScriptMetadata: {}
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "globalPortfolioDealingLoad":
                draft.globalPortfolioDealingMacroLoading = true;
                draft.globalPortfolioDealingMacro = { script: "" };
                break;
            case "globalPortfolioDealingLoaded":
                draft.globalPortfolioDealingMacroLoading = false;
                draft.globalPortfolioDealingMacro = action.payload;
                break;
            case "globalPortfolioDealingMetadataLoaded":
                draft.globalPortfolioDealingMacroValidateScriptMetadata = action.payload;
                break;
            case "globalPortfolioDealingSave":
                draft.globalPortfolioDealingMacroSaving = true;
                break;
            case "globalPortfolioDealingSaved":
                draft.globalPortfolioDealingMacroSaving = false;
                draft.globalPortfolioDealingMacro = action.payload;
                break;
            case "globalPortfolioDealingValidatedScript":
                draft.globalPortfolioDealingMacroCheckResult = action.payload;
                break;
        }
    });
}
