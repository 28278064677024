import ReportTemplateReducer from "immer";
import {
    IReportTemplateSummaryModel, IGetReportTemplateContentModel,
    IReportTemplateAnalysisModel, IDataPreviewRequest, IUniverseScopeTypeModel, IReportTemplatesGenerateReportParameters
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IReportTemplateGenerate extends IReportTemplatesGenerateReportParameters {
    scopeType: IUniverseScopeTypeModel;
}

export const ActionFactories = produceActionFactories({
    reportTemplateDelete: (payload: number) => payload,
    reportTemplateDeleted: (payload: number) => payload,
    reportTemplateLoad: (payload: number) => payload,
    reportTemplateLoadForDuplicate: (payload: number) => payload,
    reportTemplateLoaded: (payload: IGetReportTemplateContentModel) => payload,
    reportTemplateSave: (payload: IGetReportTemplateContentModel) => payload,
    reportTemplateSaved: (payload: IGetReportTemplateContentModel) => payload,
    reportTemplateLoadAll: () => undefined,
    reportTemplateLoadedAll: (payload: IReportTemplateSummaryModel[]) => payload,
    reportTemplateAnalyse: (payload: string) => payload,
    reportTemplateAnalysed: (payload: IReportTemplateAnalysisModel) => payload,
    reportTemplateDataPreviewLoad: (payload: IDataPreviewRequest) => payload,
    reportTemplateDataPreviewLoaded: (payload: Record<number | string, any>) => payload,
    reportTemplateGenerate: (payload: IReportTemplateGenerate) => payload,
    reportTemplateGenerated: (payload: IUniverseScopeTypeModel) => payload
    // reportTemplateDictionaryDataLoaded: (payload: IReportDictionaryDataLoadedPayload) => payload,
    // reportTemplateDictionaryDataLoad: (payload: IReportDictionaryDataLoadPayload) => payload,
    // reportTemplateDictionaryRequestDataLoad: (payload: boolean) => payload,
});
export interface IState {
    reportTemplateListLoading: boolean;
    reportTemplateLoading: boolean;
    reportTemplateSaving: boolean;
    reportTemplateDeleting: boolean;
    reportTemplateList: IReportTemplateSummaryModel[];
    reportTemplateCurrent?: IGetReportTemplateContentModel;
    reportTemplateAnalysis?: IReportTemplateAnalysisModel;
    templateAnalyzing: boolean;
    referentialDictionary?: Record<number | string, any>;
    dataPreviewLoading: boolean;
    // generating: { [scope: IUniverseScopeTypeModel]: boolean }
    generating: { [scope: string]: boolean }

    // reportTemplateDictionaryRequesting: boolean;
    // reportTemplateDictionaryLoading: boolean;
    // reportTemplateDictionaries: {
    //     [IReportTemplateTypeModel.ShareClass]?: IShareClassReportDictionary;
    // },
}
// IShareClassReportDictionary
export const reducer = (
    state: IState = {
        // reportTemplateDictionaryRequesting: false,
        reportTemplateListLoading: false,
        reportTemplateLoading: false,
        reportTemplateDeleting: false,
        reportTemplateSaving: false,
        reportTemplateList: [],
        dataPreviewLoading: false,
        templateAnalyzing: false,
        generating: {}
        // reportTemplateDictionaryLoading: false,
        // reportTemplateDictionaries: {},
    },
    action: AnyActionOf<typeof ActionFactories>
) => ReportTemplateReducer(state, draft => {
    switch (action.type) {
        // case "reportTemplateDictionaryDataLoaded":
        //     draft.reportTemplateDictionaryLoading = false;
        //     draft.reportTemplateDictionaries[action.payload.templateType] = action.payload.dataDictionary;
        //     // switch (action.payload.templateType) {
        //     //     case IReportTemplateTypeModel.ShareClass:
        //     //         draft.reportDictionaries[action.payload.templateType] = action.payload.dataDictionary;
        //     //         break;
        //     // }
        //     break;
        // case "reportTemplateDictionaryRequestDataLoad":
        //     draft.reportTemplateDictionaryRequesting = action.payload;
        //     break;
        // case "reportTemplateDictionaryDataLoad":
        //     draft.reportTemplateDictionaryRequesting = false;
        //     draft.reportTemplateDictionaryLoading = true;
        //     break;
        case "reportTemplateGenerate":
            draft.generating[action.payload.scopeType] = true;
            break;
        case "reportTemplateGenerated":
            draft.generating[action.payload] = false;
            break;
        case "reportTemplateDataPreviewLoad":
            draft.dataPreviewLoading = true;
            break;
        case "reportTemplateDataPreviewLoaded":
            draft.dataPreviewLoading = false;
            draft.referentialDictionary = action.payload;
            break;
        case "reportTemplateAnalyse":
            draft.templateAnalyzing = true;
            break;
        case "reportTemplateAnalysed":
            draft.reportTemplateAnalysis = action.payload;
            draft.templateAnalyzing = false;
            break;
        case "reportTemplateLoadAll":
            draft.reportTemplateListLoading = true;
            break;
        case "reportTemplateLoadedAll":
            draft.reportTemplateListLoading = false;
            draft.reportTemplateList = action.payload;
            break;
        case "reportTemplateLoad":
        case "reportTemplateLoadForDuplicate":
            draft.reportTemplateLoading = true;
            draft.reportTemplateCurrent = undefined;
            break;
        case "reportTemplateLoaded": {
            draft.reportTemplateAnalysis = undefined;
            draft.referentialDictionary = undefined;
            draft.reportTemplateLoading = false;
            draft.reportTemplateCurrent = action.payload;
            break;
        }
        case "reportTemplateSave":
            draft.reportTemplateSaving = true;
            break;
        case "reportTemplateSaved": {
            draft.reportTemplateSaving = false;
            if (draft.reportTemplateCurrent) {
                draft.reportTemplateCurrent = action.payload;
            }

            const templateDefinition = action.payload.definition;
            const existing = draft.reportTemplateList.find(i => i.id === templateDefinition.id);
            if (existing) {
                Object.assign(existing, action.payload.definition);
            }
            else {
                draft.reportTemplateList.push(templateDefinition);
            }
            break;
        }
        case "reportTemplateDelete":
            draft.reportTemplateDeleting = true;
            break;
        case "reportTemplateDeleted":
            draft.reportTemplateDeleting = false;
            const deletedId = action.payload;
            if (draft.reportTemplateCurrent && draft.reportTemplateCurrent.definition.id === deletedId) {
                delete draft.reportTemplateCurrent;
            }
            const idx = draft.reportTemplateList.findIndex(i => i.id === deletedId);
            if (idx >= 0) {
                draft.reportTemplateList.splice(idx, 1);
            }
            break;
    }
});
