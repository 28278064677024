import { typedScreenDictionary, useScreenKey, useScreenParams } from "tools/routing/screenRouteHooks";
import { IMenuDefinition, IMenuTarget, ILevel1MenuItem, ILevel2MenuItem } from "./menu/menuContracts";
import { IApplicationMenuState, IScreenMenuState, ICategoryMenuState, IModuleMenuState } from "./slice";
import sortKeys from "sort-keys-recursive";
import { useLocation } from "react-router";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";
import { useCallback, useMemo } from "react";
import { matchPath } from "react-router";

/**
 * Get menu state.
 * @see useCurrentScreenMenuState When you need a `IScreenMenuState`representing the current screen that may not be in the menu. 
 */
export function useMenu() {
    const location = useLocation();
    const { applicationMenu } = useReduxSelections("app");
    const isCurrentScreen = useCallback(({ urlTemplate, params }: IScreenMenuState) => {
        const match = matchPath(urlTemplate, location.pathname);
        if (!match) return false;
        if (!params) return true;
        // eslint-disable-next-line eqeqeq
        return Object.entries(params).every(([key, value]) => match.params[key] == value);
    }, [location.pathname]);
    const isCurrentModule = useCallback(({ screens }: IModuleMenuState) => !!screens.find(isCurrentScreen) || !!screens.flatMap(screen => screen.children).find(isCurrentScreen), [isCurrentScreen]);
    const isCurrentCategory = useCallback(({ subMenus }: ICategoryMenuState) => !!subMenus.find(isCurrentModule), [isCurrentModule]);
    const currentCategory = useMemo(() => applicationMenu.categories.findLast(isCurrentCategory), [applicationMenu.categories, isCurrentCategory]);
    const currentModule = useMemo(() => (currentCategory?.subMenus ?? []).findLast(isCurrentModule), [currentCategory?.subMenus, isCurrentModule]);
    const currentScreen = useMemo(() => (currentModule?.screens ?? []).findLast(isCurrentScreen) ?? (currentModule?.screens ?? []).flatMap(screen => screen.children).find(isCurrentScreen), [currentModule?.screens, isCurrentScreen]);
    return useMemo(() => ({
        isCurrentScreen,
        isCurrentModule,
        isCurrentCategory,
        currentCategory,
        currentModule,
        currentScreen,
    }), [isCurrentScreen, isCurrentModule, isCurrentCategory, currentCategory, currentModule, currentScreen]);
}

/**
 * Get the current ScreenMenuState even if the current screen is not present in the menu.
 */
export function useCurrentScreenMenuState() {
    const { currentScreen } = useMenu();
    const screenKey = useScreenKey();
    const params = useScreenParams()

    if (currentScreen) return currentScreen;

    const screen = typedScreenDictionary[screenKey];
    return {
        label: screen.label,
        urlTemplate: screen.route,
        params: params,
    } as IScreenMenuState;
}

export function decodeInput<T extends object>(input: string): T {
    return JSON.parse(atob(decodeURIComponent(input))) as T;
}
export function encodeInput(menuSetup: object): string {
    return encodeURIComponent(btoa(JSON.stringify(sortKeys(JSON.parse(JSON.stringify(menuSetup))))));
}
export function compareInputs(input1: string | object, input2: string | object) {
    if (typeof input1 !== "string") {
        input1 = encodeInput(input1);
    }
    if (typeof input2 !== "string") {
        input2 = encodeInput(input2);
    }
    return input1 === input2;
}

export function convertToApplicationMenu(definition: IMenuDefinition): IApplicationMenuState {
    return {
        home: convertToScreenMenu(definition.home),
        categories: definition.menu.map(convertToCategoryMenu)
    };
}
function makeCopy<T>(obj: T) {
    return JSON.parse(JSON.stringify(obj)) as T;
}

export function convertToScreenMenu(target: IMenuTarget): IScreenMenuState & { tab: string };
export function convertToScreenMenu(target: IMenuTarget | undefined): IScreenMenuState & { tab?: string } | undefined;
export function convertToScreenMenu(target: IMenuTarget | undefined): IScreenMenuState & { tab?: string } | undefined {
    if (!target) {
        return undefined;
    }
    const targetUrlParams = getTemplateParams(target);
    // const targetUrl = generatePath(targetUrlParams.urlTemplate, targetUrlParams.params);
    const ret = {
        label: target.label,
        keywords: target.keywords,
        children: computeChildren(targetUrlParams.urlTemplate, target) ?? [],
        parent: undefined,
        ...targetUrlParams
    };
    ret.children.forEach(child => { child.parent = makeCopy(ret); });
    return ret;
}
function computeChildren(targetUrl: string, target: IMenuTarget): IScreenMenuState[] {
    switch (target.type) {
        case "Screen": return Object
            .values(typedScreenDictionary)
            .filter(def => def.route.startsWith(targetUrl) && def.route !== targetUrl)
            .toSorted((a, b) => a.route.length - b.route.length)
            .map(def => {
                const ret: IScreenMenuState = {
                    label: def.label,
                    parent: undefined, // Parents are not  set at this level
                    children: [], // No sub children. At least for now.
                    urlTemplate: def.route,
                    keywords: def.keywords,
                    params: target.params,
                }
                return ret;
            });
        case "Questionnaire": return (target.detail ? [target.detail] : [])
            .map(i => convertToScreenMenu(i));
        case "Dashboard": return (target.detail ? [target.detail] : [])
            .map(i => convertToScreenMenu(i));
    }
}
// export function computeUrl(target: IScreenMenuState): string  {
//     return generatePath()
//     switch (target.type) {
//         case "Screen": return buildScreenUrl(target.key as ScreenKey, target.params);
//         case "Dashboard": return buildScreenUrl("GenericDashboard", { input: encodeInput(target) });
//     }
// }
// export function getTemplateParams(target: IScreenMenuState): string  {
//     return generatePath()
//     switch (target.type) {
//         case "Screen": return buildScreenUrl(target.key as ScreenKey, target.params);
//         case "Dashboard": return buildScreenUrl("GenericDashboard", { input: encodeInput(target) });
//     }
// }
interface IRouteTemplateParams {
    urlTemplate: string;
    params?: Record<string, string | number>;
    tab?: string; //FIXME: Could be integrated into params
}
export function getTemplateParams(target: IMenuTarget): IRouteTemplateParams;
export function getTemplateParams(target: IMenuTarget | undefined): IRouteTemplateParams | undefined;
export function getTemplateParams(target: IMenuTarget | undefined): IRouteTemplateParams | undefined {
    if (!target) {
        return undefined;
    }
    switch (target.type) {
        case "Screen":
            if (!target.key) {
                return undefined;
            }
            return {
                urlTemplate: typedScreenDictionary[target.key].route,
                params: target.params,
                tab: target.tab
            };
        case "Dashboard": return {
            urlTemplate: typedScreenDictionary["GenericDashboard"].route,
            params: { input: encodeInput(target) }
        }
        case "Questionnaire":
            return {
                urlTemplate: typedScreenDictionary["GenericScreen"].route,
                params: { input: encodeInput(target) }
            }
    }
}
function convertToCategoryMenu({ label, children }: ILevel1MenuItem): ICategoryMenuState {
    return {
        label,
        subMenus: children.map(convertToModule)
    };
}
function convertToModule({ icon, children, label }: ILevel2MenuItem): IModuleMenuState {
    const screens = children.map(i => convertToScreenMenu(i));
    return { icon, label, screens };
}
