import { SecuritySummaryModel, ICurrencyModel, IDividendDistributionPolicyModel, ISecurityTypeModel } from "proxy/apiProxy";
import { Table, TableHead, TableRow, TableCell, TableBody, Radio, Tooltip, IconButton, Typography } from "@material-ui/core";
import { getEnumLabels } from "tools/lib/utility";
import SecuritySearch from "components/searchers/SecuritySearch";
import CloseIcon from '@material-ui/icons/Close';
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import { useField } from "formik";
import { IGetSecuritySummary } from "features/Reference/slice";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";

const dividendDistributionPolicies = getEnumLabels(IDividendDistributionPolicyModel);
export interface IProps {
    shareClassesIdField: string;
    primaryShareClassIdField: string;
    referenceCurrencies: Record<number, ICurrencyModel>;
    securities: Record<number, SecuritySummaryModel>;
    filter?: (s: SecuritySummaryModel) => boolean;
    onShareClassLoaded: (security: IGetSecuritySummary) => void;
    onShareClassClick?: (securityId: number) => void;
};
export default function PortfolioShareClasses({
    shareClassesIdField, primaryShareClassIdField, securities, referenceCurrencies, onShareClassLoaded, onShareClassClick, filter }: IProps) {
    const [, { value: shareClassesId = [] }, { setValue: setShareClassesIdValue }] = useField<number[] | undefined>(shareClassesIdField);
    const [, { value: primaryShareClassId }, { setValue: setPrimaryShareClassIdValue }] = useField<number | undefined>(primaryShareClassIdField);

    const handleRadioCheck = (shareClassId: number) => setPrimaryShareClassIdValue(shareClassId);
    const handleShareClassSelected = (found: IGetSecuritySummary) => {
        const { security } = found;
        setShareClassesIdValue([...shareClassesId, security.id]);
        onShareClassLoaded(found);
    }
    const handleRemoveShareClass = (index: number) => {
        const ids = [...shareClassesId];
        if (primaryShareClassId === ids[index]) {
            setPrimaryShareClassIdValue(undefined);
        }
        ids.splice(index, 1);
        setShareClassesIdValue([...ids]);
    }
    const handleShareClassView = (id: number) => {
        if (onShareClassClick) {
            onShareClassClick(id);
        }
    }
    return (<ReadOnlyContext.Consumer>
        {readOnly => <>
            {!shareClassesId.length && <Typography>No shareclass</Typography>}
            {!!shareClassesId.length && <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell>Primary</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Internal Code</TableCell>
                        <TableCell>ISIN</TableCell>
                        <TableCell>Currency</TableCell>
                        <TableCell>Distribution Type</TableCell>
                        <TableCell />
                        {!readOnly && <TableCell />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {shareClassesId.map(shareClassId => securities[shareClassId]).filter(i => !!i).map((shareClass, index) => (
                        <TableRow key={shareClass.id}>
                            <TableCell><Radio readOnly={readOnly} disabled={readOnly} checked={shareClass.id === primaryShareClassId} onChange={handleRadioCheck.bind(null, shareClass.id || 0)} /></TableCell>
                            <TableCell>{shareClass.name}</TableCell>
                            <TableCell>{shareClass.internalCode}</TableCell>
                            <TableCell>{shareClass.type === "ShareClassSummaryModel" ? shareClass.isin : undefined}</TableCell>
                            <TableCell>{shareClass.currencyId && referenceCurrencies[shareClass.currencyId] && referenceCurrencies[shareClass.currencyId].isoCode}</TableCell>
                            <TableCell>{shareClass.type === "ShareClassSummaryModel" ? (shareClass.dividendDistributionPolicy && dividendDistributionPolicies[shareClass.dividendDistributionPolicy]) : undefined}</TableCell>
                            {!readOnly && <TableCell align="right">
                                <Tooltip title="Unlink">
                                    <IconButton
                                        size="small"
                                        aria-label="Unlink"
                                        style={{ verticalAlign: "middle" }}
                                        onClick={handleRemoveShareClass.bind(null, index)} >
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>}
                            <TableCell align="right">
                                <Tooltip title="Open">
                                    <IconButton
                                        size="small"
                                        aria-label="Open"
                                        style={{ verticalAlign: "middle" }}
                                        onClick={handleShareClassView.bind(null, shareClass.id || 0)} >
                                        <OpenInBrowser />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>}
            {!readOnly && <SecuritySearch label="Add ShareClass" filter={filter} onSelected={handleShareClassSelected} type={ISecurityTypeModel.ShareClass} />}
        </>}
    </ReadOnlyContext.Consumer>);
}

