import * as React from "react";
import { ExpandCollapseButton } from "components/global/ExpandCollapseButton";
import { Box, Link, makeStyles, Typography } from "@material-ui/core";
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import ArrowUpBoldIcon from 'mdi-material-ui/ArrowUpBold';
import ArrowDownBoldIcon from 'mdi-material-ui/ArrowDownBold';
import { DataType } from 'csstype';
import ReactHtmlParser from 'react-html-parser';
import showdown from "showdown";
import { SummaryField } from "components/global/SummaryField";
import {
    formatDate,
    formatDateTime,
    formatDecimal,
    formatInteger,
    formatPercentage,
    formatPreciseDecimal,
    formatPrecisePercentage
} from "tools/lib/utility";
import clsx from "clsx";
import { ScreenLinkType } from "./DashboardFormattingContracts";
import { useReduxActions } from "tools/lib/reduxStoreAccess";
import { IFileTypeModel } from "proxy/apiProxy";
import { ScreenLink } from "tools/routing/ScreenLink";
import { ScreenKey } from "tools/routing/screenRouteHooks";
import { IMenuTargetScreen } from "features/App/menu/menuContracts";

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: theme.shadows[5],
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch"
    },
    cardHeader: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
        padding: theme.spacing(1),
        display: "flex",
        alignItems: "center"
    },
    cardContent: {
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        flexGrow: 1,
        flexDirection: "column",
        display: "flex",
        "&>*": {
            flexGrow: 1
        },
        overflowX: "auto"
    },
    cardContentHidden: {
        visibility: "hidden"
    },
    dashboardLink: {
        textAlign: "left"
    },
    disabledLink: {
        "&:hover": {
            textDecoration: "initial",
        },
        cursor: "not-allowed",
    }
}));
export interface ICollapsiblePanelProps {
    children: React.ReactNode;
    collapsedByDefault: boolean;
    title?: string;
    gridArea?: string;
}
export function CollapsiblePanel({ children, collapsedByDefault, title, gridArea }: ICollapsiblePanelProps) {
    const [expanded, setExpanded] = React.useState(!collapsedByDefault);
    const styles = useStyles();
    const handleOnExpandChanged = React.useCallback((exp: boolean) => void setExpanded(exp), []);
    return <Box className={clsx(styles.card)} gridArea={gridArea}>
        <Box className={clsx(styles.cardHeader)}>
            <Typography variant="h5">{title}</Typography>
            <Box flexGrow={1} />
            <ExpandCollapseButton expanded={expanded} onChanged={handleOnExpandChanged} />
        </Box>
        {expanded && <Box className={clsx(styles.cardContent)}>
            <div>{children}</div>
        </Box>}
        {/* <Box className={clsx(styles.cardContent, !expanded && styles.cardContentHidden)}>
            <div>{children}</div>
        </Box> */}
    </Box>;
}













export type IMonitoringResultValueDateTypeModel = "Date" | "DateTime";


export type ISentimentModel = "Neutral" | "Positive" | "Warning" | "Negative";

export type IProgressionLevelModel = "Still" | "Raise" | "LargeRaise" | "LargeDrop" | "Drop";

export type IMonitoringResultValueNumberTypeModel = "Number2" | "Number4" | "Integer" | "Percentage" | "Percentage2" | "Percentage4";








interface IDirectionComponentProps {
    direction?: IProgressionLevelModel
    sentiment?: ISentimentModel;
}
function getSentimentColor(sentiment: ISentimentModel | undefined): DataType.Color | undefined {
    switch (sentiment) {
        case "Negative": return "red";
        case "Warning": return "orange";
        case "Positive": return "green";
    }
    return;
}
function DirectionComponent({ direction, sentiment }: IDirectionComponentProps) {
    if (typeof (direction) === "undefined") {
        return null;
    }

    const sentimentColor = getSentimentColor(sentiment);

    switch (direction) {
        case "LargeRaise": return <ArrowUpBoldIcon style={{ color: sentimentColor }} />;
        case "Raise": return <TrendingUpIcon style={{ color: sentimentColor }} />;
        case "Still": return <TrendingFlatIcon style={{ color: sentimentColor }} />;
        case "Drop": return <TrendingDownIcon style={{ color: sentimentColor }} />;
        case "LargeDrop": return <ArrowDownBoldIcon style={{ color: sentimentColor }} />;
    }
}


function getNumberTextValue(value: number | undefined, numberType: IMonitoringResultValueNumberTypeModel): string {
    switch (numberType) {
        case "Integer": return formatInteger(value);
        case "Number2": return formatDecimal(value);
        case "Number4": return formatPreciseDecimal(value);
        case "Percentage": return formatPercentage(value);
        case "Percentage2": return formatPrecisePercentage(value);
        case "Percentage4": return formatPrecisePercentage(value, 4);
    }
}


export interface ICheckComponentProps {
    checked?: boolean
}
export function CheckComponent({ checked }: ICheckComponentProps) {
    if (typeof (checked) === "undefined") {
        return null;
    }
    if (checked) {
        return <CheckBoxOutlinedIcon />;
    }
    return <CheckBoxOutlineBlankOutlinedIcon />;
}
export interface IValueResultNumberComponentProps {
    numberType: IMonitoringResultValueNumberTypeModel;
    value?: number;
    sentiment?: ISentimentModel;
    direction?: IProgressionLevelModel;
    label: string;
}
export function ValueResultNumberComponent({ label, value, direction, sentiment, numberType }: IValueResultNumberComponentProps) {
    const textValue = getNumberTextValue(value, numberType);

    return <SummaryField
        label={label}
        value={textValue}
        rightAlign={true}
        color={getSentimentColor(sentiment)}
        startAdornment={(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />} />
}
export interface IValueResultCheckComponentProps {
    value?: boolean;
    sentiment?: ISentimentModel;
    direction?: IProgressionLevelModel;
    label: string;

}
export function ValueResultCheckComponent({ label, value, direction, sentiment }: IValueResultCheckComponentProps) {
    return <SummaryField
        label={null}
        value={label}
        color={getSentimentColor(sentiment)}
        startAdornment={<>
            {(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
            {<CheckComponent checked={value} />}
        </>
        }
    />
}
function getDateTimeTextValue(value: Date | undefined, dateType: IMonitoringResultValueDateTypeModel): string {
    switch (dateType) {
        case "Date": return formatDate(value);
        case "DateTime": return formatDateTime(value);
    }
}

export interface IValueResultDateComponentProps {
    dateType: IMonitoringResultValueDateTypeModel;
    value?: Date;
    sentiment?: ISentimentModel;
    direction?: IProgressionLevelModel;
    label: string;
}
export function ValueResultDateComponent({ label, value, direction, sentiment, dateType }: IValueResultDateComponentProps) {
    const textValue = getDateTimeTextValue(value, dateType);

    return <SummaryField
        label={label}
        value={textValue}
        color={getSentimentColor(sentiment)}
        startAdornment={(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
    />
}
export interface IValueResultStringComponentProps {
    value?: string;
    sentiment?: ISentimentModel;
    direction?: IProgressionLevelModel;
    label: string;
}
export function ValueResultStringComponent({ label, value, direction, sentiment }: IValueResultStringComponentProps) {
    return <SummaryField
        label={label}
        value={value}
        color={getSentimentColor(sentiment)}
        startAdornment={(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
    />
}
export interface IValueResultMarkdownComponentProps {
    value: string;
}
export function ValueResultMarkdownComponent({ value }: IValueResultMarkdownComponentProps) {
    return <MarkDown markDown={value} />
}
interface IMarkDownProps {
    markDown: string;
}
function MarkDown({ markDown }: IMarkDownProps) {
    const converter = new showdown.Converter();
    converter.setFlavor("github")
    const htmlText = converter.makeHtml(markDown);
    return <div className="markdown-body">{ReactHtmlParser(htmlText)}</div>
}

function getScreenKey(referenceType: ScreenLinkType): ScreenKey {
    switch (referenceType) {
        case ScreenLinkType.Person: return "Person";
        case ScreenLinkType.Company: return "Company";
        case ScreenLinkType.EntityGroup: return "EntityGroup";
        case ScreenLinkType.ManagedSicav: return "MySicav";
        case ScreenLinkType.Portfolio: return "MyPortfolio";
        case ScreenLinkType.ShareClass: return "MyShareClass";
        case ScreenLinkType.Sicav: return "Sicav";
        case ScreenLinkType.SubFund: return "SubFund";
        case ScreenLinkType.Security: return "Security";
        case ScreenLinkType.Investor: return "Investor";
        case ScreenLinkType.Role: return "ServiceProvidersCollaborator";
        case ScreenLinkType.Counterparty: return "Counterparty";
        case ScreenLinkType.Index: return "Index";
        case ScreenLinkType.Movement: return "CashMovement";
        case ScreenLinkType.PortfolioTransaction: return "PortfolioTransaction";
        case ScreenLinkType.Cash: return "BankAccount";
        case ScreenLinkType.ProcessExecution: return "ProcessExecution";
        case ScreenLinkType.Loan: return "Loan";
        case ScreenLinkType.Document: return "Document";
    }
}

export interface IScreenLinkProps {
    id: number | undefined;
    textValue: string;
    referenceType: ScreenLinkType;
}

export function DashboardLink({ id, textValue, referenceType }: IScreenLinkProps) {
    const destination: IMenuTargetScreen = React.useMemo(() => ({
        type: "Screen",
        key: getScreenKey(referenceType),
        label: textValue,
        params: { id: String(id) }
    }), [id, referenceType, textValue]);
    return <Link component={ScreenLink}
        destination={destination}>
        {textValue}
    </Link>;
}

export interface IReportLinkProps {
    id: number | undefined;
    textValue: string;
    targetType?: "processExecution" | "entity" | "relationship" | "portfolio" | "security";
    taskCode?: string;
    reportTemplateName: string;
    datasetName: string;
    fileType?: "pdf" | "docx" | "xlsx" | "xls";
    culture?: string;
    asOfDate?: Date;
}
export function ReportLink({ id, textValue, reportTemplateName, datasetName, fileType, targetType, taskCode, culture, asOfDate }: IReportLinkProps) {
    const { developmentItemReportTemplateGenerate } = useReduxActions("developmentItem");

    const handleClick = React.useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        if (!id) return
        developmentItemReportTemplateGenerate({
            body: {
                datasetName,
                templateName: reportTemplateName,
                // exportType:fileType
                exportType: getFileTypeModel(fileType) ?? IFileTypeModel.Pdf,
                related: {
                    entityId: targetType === "entity" ? id : undefined,
                    portfolioId: targetType === "portfolio" ? id : undefined,
                    processExecutionId: targetType === "processExecution" ? id : undefined,
                    relationshipId: targetType === "relationship" ? id : undefined,
                    securityId: targetType === "security" ? id : undefined,
                    processExecutionTaskCode: taskCode,
                },
                type: "GenerateReportModel",
                asOfDate,
                culture
            }
        });
    }, [id, developmentItemReportTemplateGenerate, datasetName, reportTemplateName, fileType, targetType, taskCode, culture, asOfDate]);

    const style = useStyles();
    return <Link
        className={clsx(style.dashboardLink, !id && style.disabledLink)}
        component="button"
        onClick={handleClick}>
        {textValue}
    </Link>;
}

function getFileTypeModel(fileType?: "pdf" | "docx" | "xlsx" | "xls"): IFileTypeModel | undefined {
    switch (fileType) {
        case "docx": return IFileTypeModel.Docx;
        case "pdf": return IFileTypeModel.Pdf;
        case "xlsx": return IFileTypeModel.Xlsx;
        case "xls": return IFileTypeModel.Xls;
    }
    return undefined;
}