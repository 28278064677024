import * as React from "react";
import { IBankAccountSummaryModel, ICurrencyModel } from "proxy/apiProxy";
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Grid, IconButton } from "@material-ui/core";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { SummaryField } from "../global/SummaryField";
import { useField } from "formik";
import CloseIcon from '@material-ui/icons/Close';
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { ScreenLink } from "tools/routing/ScreenLink";

export interface IBankAccountSummaryProps {
  bankAccounts: Record<number, IBankAccountSummaryModel>;
  currencies: Record<number, ICurrencyModel>;
  label?: string;
  name: string;
}

export default function BankAccountSummary({ name: fieldName, bankAccounts, currencies, label }: IBankAccountSummaryProps) {
  const [{ value: bankAccountId }, , { setValue }] = useField<number | undefined>(fieldName);
  const [expanded, setExpanded] = React.useState(false);
  if (!bankAccountId) {
    return null;
  }
  const bankAccount = bankAccounts[bankAccountId];
  if (!bankAccount) {
    return null;
  }
  const { id, name, currencyId, internalCode, iban } = bankAccount;
  const currency = currencies[currencyId ?? 0];
  const handleSwitchExpand = () => setExpanded(!expanded);
  const handleSetUndefined = () => setValue(undefined);
  return <ReadOnlyContext.Consumer>{readOnly => <Card >
    <CardHeader
      action={<>
        {!readOnly && <IconButton onClick={handleSetUndefined}><CloseIcon /></IconButton>}
        <IconButton onClick={handleSwitchExpand}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </>}
      title={name} />
    <Collapse in={expanded} timeout="auto">
      <CardContent>
        <Grid container={true} spacing={2}>
          {!!currencyId && currency && <Grid xs={6} item={true}>
            <SummaryField label="Currency" value={`${currency.isoCode} - ${currency.name.en}`} />
          </Grid>}
          <Grid xs={6} item={true}>
            <SummaryField label="Internal Code" value={internalCode} />
          </Grid>
          {iban && <Grid xs={6} item={true}>
            <SummaryField label="Iban" value={iban} />
          </Grid>}
        </Grid>
      </CardContent>
      <CardActions>
        <Button 
          component={ScreenLink}
          destination={{ type: "Screen", key: "BankAccount", label: "Open Bank Account", params: { id: String(id) } }}
          size="small">
          <OpenInBrowser />
          Open Bank Account
        </Button>
      </CardActions>
    </Collapse>
  </Card>}
  </ReadOnlyContext.Consumer>
}
