import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { DocumentsGrid } from "./DocumentsGrid";
import SearchPanel from "tools/components/SearchPanel";
import AddIcon from '@material-ui/icons/Add';
import { IDocumentsSearchParameters, IFeatureModel } from "proxy/apiProxy";
import { IActionButton } from "tools/components/FabContainer";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import FieldBox from "tools/components/FieldBox";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import { IErrors, oProps } from "tools/lib/utility";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { Button } from "@material-ui/core";
import { OpenInNew } from "mdi-material-ui";
import RelatedDialog from "../DocumentScreen/RelatedDialog";
import FormTextField from "tools/fieldComponents/FormTextField";

export const documentsScreen = {
    route: "/documents" as const,
    component: Documents,
    label: "Documents"
}

export default function Documents() {
    const { documents = [], documentAllLoading, dictionaries } = useReduxSelections("document");

    const { documentSearch, documentAddRelatedToDictionary } = useReduxActions("document");

    const navigate = useScreenNavigate();
    const handleAddDocument = () => navigate("Document", { id: 0 });

    const extraActionButtons: IActionButton[] = [
        {
            label: "Add document",
            icon: AddIcon,
            onClick: handleAddDocument,
            feature: IFeatureModel.DocumentWrite
        }
    ];
    const handleValidate = (values: IDocumentsSearchParameters) => {
        const errors: IErrors<IDocumentsSearchParameters> = {};
        if (values.expirationDate && values.startDate && values.startDate > values.expirationDate) {
            errors.expirationDate = "Must be greater than the start of period";
        }
        return errors;
    }

    const handleSubmit = (model: IDocumentsSearchParameters, { setSubmitting }: FormikHelpers<IDocumentsSearchParameters>) => {
        documentSearch(model);
        setSubmitting(false);
    }

    const [openRelatedDialog, setOpenRelatedDialog] = React.useState(false);
    const handleOpenRelatedDialog = () => setOpenRelatedDialog(true);
    const handleCloseRelatedDialog = () => setOpenRelatedDialog(false);

    return <Formik onSubmit={handleSubmit} validate={handleValidate}
        initialValues={{ onlyActive: true, excludeCashMovDocs: true } as IDocumentsSearchParameters} validateOnMount={true}>{renderForm}</Formik>;
    function renderForm({ isValid, submitForm, values: { onlyActive, related } }: FormikProps<IDocumentsSearchParameters>) {

        const relatedIsEmpty = () => {
            if (!related) return true;
            return ![
                related.entityIds, related.relationshipIds, related.portfolioIds,
                related.securityIds, related.transactionIds, related.cashMovementIds
            ].some(ids => ids.length > 0);
        }

        return <SearchPanel
            title="Documents"
            isQuerying={documentAllLoading}
            searchDisabled={!isValid}
            onSearchClick={submitForm}
            actions={extraActionButtons}
            renderSearch={
                <FieldBox display="flex" flexDirection="column">
                    <FieldBox display="flex" flexDirection="row">
                        <FormTextField name={oProps<IDocumentsSearchParameters>().path("criterias")}
                            label="Name or Alias" />

                        <FormCheckBoxField
                            name={oProps<IDocumentsSearchParameters>().path("excludeCashMovDocs")}
                            label="Exclude cash movement" />

                        <FormCheckBoxField
                            name={oProps<IDocumentsSearchParameters>().path("onlyActive")}
                            label="Only active" />

                        <Button size="small" onClick={handleOpenRelatedDialog} color={relatedIsEmpty() ? "default" : "primary"} startIcon={<OpenInNew />}>
                            Filter related
                        </Button>
                    </FieldBox>
                    {openRelatedDialog && <RelatedDialog
                        title="Filter Related"
                        name={oProps<IDocumentsSearchParameters>().path("related")}
                        showSummary={true}
                        isOpened={openRelatedDialog}
                        onCancel={handleCloseRelatedDialog}
                        dictionaries={dictionaries}
                        onSelected={documentAddRelatedToDictionary} />}

                    {(!onlyActive) &&
                        <FieldBox display="flex" flexDirection="row">
                            <FormDatePickerField name={oProps<IDocumentsSearchParameters>().path("startDate")}
                                label="From" />
                            <FormDatePickerField name={oProps<IDocumentsSearchParameters>().path("expirationDate")}
                                label="To" />
                        </FieldBox>
                    }
                </FieldBox>
            }>
            <DocumentsGrid
                documents={documents}
                dictionaries={dictionaries} />
        </SearchPanel>;
    }
}
