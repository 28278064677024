import produce from "immer";
import { IStatisticDefinitionSetModel, IStatisticDefinitionSetSummaryModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IState {
    statisticDefinitionSetAllLoading: boolean;
    statisticDefinitionSetLoading: boolean;
    statisticDefinitionSets?: IStatisticDefinitionSetSummaryModel[];
    statisticDefinitionSetCurrent?: IStatisticDefinitionSetModel;
    statisticDefinitionSetSaving: boolean;
    statisticDefinitionSetDeleting: boolean;
    statisticDefinitionSetScriptSchema?: object;
}
export const ActionFactories = produceActionFactories({
    statisticDefinitionSetLoad: (payload: number) => payload,
    statisticDefinitionSetLoaded: (payload: IStatisticDefinitionSetModel) => payload,
    statisticDefinitionSetSave: (payload: IStatisticDefinitionSetModel) => payload,
    statisticDefinitionSetSaved: (payload: IStatisticDefinitionSetModel) => payload,
    statisticDefinitionSetDelete: (payload: number) => payload,
    statisticDefinitionSetDeleted: (payload: number) => payload,
    statisticDefinitionSetLoadAll: () => undefined,
    statisticDefinitionSetLoadedAll: (payload: IStatisticDefinitionSetSummaryModel[]) => payload,
    statisticDefinitionSetSchemaLoaded: (schema: object) => schema
});

export function reducer(
    state: IState = {
        statisticDefinitionSetLoading: false,
        statisticDefinitionSetAllLoading: false,
        statisticDefinitionSetSaving: false,
        statisticDefinitionSetDeleting: false,
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "statisticDefinitionSetLoadAll":
                draft.statisticDefinitionSetAllLoading = true;
                break;
            case "statisticDefinitionSetLoadedAll":
                draft.statisticDefinitionSetAllLoading = false;
                draft.statisticDefinitionSets = action.payload;
                break;
            case "statisticDefinitionSetLoad":
                draft.statisticDefinitionSetLoading = true;
                draft.statisticDefinitionSetCurrent = undefined;
                break;
            case "statisticDefinitionSetLoaded":
                draft.statisticDefinitionSetLoading = false;
                draft.statisticDefinitionSetCurrent = action.payload;
                break;
            case "statisticDefinitionSetSchemaLoaded":
                draft.statisticDefinitionSetScriptSchema = action.payload;
                break;
            case "statisticDefinitionSetSave":
                draft.statisticDefinitionSetSaving = true;
                break;
            case "statisticDefinitionSetSaved":
                draft.statisticDefinitionSetSaving = false;
                const saved = action.payload;
                if (draft.statisticDefinitionSetCurrent) {
                    draft.statisticDefinitionSetCurrent = saved;
                }
                if (draft.statisticDefinitionSets) {
                    const existing = draft.statisticDefinitionSets.find(i => i.id === saved.id);
                    if (existing) {
                        Object.assign(existing, saved);
                    }
                    else {
                        draft.statisticDefinitionSets.push(saved);
                    }
                }
                break;
            case "statisticDefinitionSetDelete":
                draft.statisticDefinitionSetDeleting = true;
                break;
            case "statisticDefinitionSetDeleted":
                draft.statisticDefinitionSetDeleting = false;
                const deletedId = action.payload;
                if (draft.statisticDefinitionSetCurrent && draft.statisticDefinitionSetCurrent.id === deletedId) {
                    delete draft.statisticDefinitionSetCurrent;
                }
                if (draft.statisticDefinitionSets) {
                    const idx = draft.statisticDefinitionSets.findIndex(i => i.id === deletedId);
                    if (idx >= 0) {
                        draft.statisticDefinitionSets.splice(idx, 1);
                    }
                }
                break;
        }
    });
}
