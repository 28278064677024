import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Fab, SvgIconTypeMap, makeStyles } from "@material-ui/core";
import * as React from "react";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

const useStyles = makeStyles((theme) => ({
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));
export interface IFabDropdownProps<T extends keyof any> {
    icon: OverridableComponent<SvgIconTypeMap>;
    label: string;
    options: Record<T, string>;
    onSelect: (value: T) => void;
}
export function FabDropdown<T extends keyof any>({ icon, label, options, onSelect }: IFabDropdownProps<T>) {
    const classes = useStyles();
    const MenuIcon = icon;
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | undefined>();
    const menuOpened = !!anchorEl;
    const handleOpenMenu = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => void setAnchorEl(event.currentTarget), []);
    const handleCloseMenu = React.useCallback(() => void setAnchorEl(undefined), []);
    const handleSelect = React.useCallback((value: T) => {
        onSelect(value as T);
        setAnchorEl(undefined);
    }, [onSelect]);
    return <>
        <Fab size="small" variant="extended" onClick={handleOpenMenu}>
            <MenuIcon className={classes.extendedIcon} />
            {label}
        </Fab>
        <Popper open={menuOpened} anchorEl={anchorEl} transition={true} disablePortal={true} placement="bottom-end">
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: (placement === 'bottom' || placement === 'bottom-end' || placement === 'bottom-start') ? 'right top' : 'right bottom' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleCloseMenu}>
                            <MenuList>
                                {Object.keys(options).map(key => <FabMenuItem key={key} label={options[key as T]} value={key as T} onClicked={handleSelect} />)}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>
}
interface IFabMenuItemProps<T extends keyof any> {
    value: T;
    label: string;
    onClicked: (value: T) => void;
}
function FabMenuItem<T extends keyof any>({ value, label, onClicked }: IFabMenuItemProps<T>) {
    const handleClicked = React.useCallback(() => onClicked(value), [onClicked, value]);
    return <MenuItem onClick={handleClicked}>{label}</MenuItem>
}
