import { ITenantSummaryModel } from "proxy/apiProxy";
import DialogPanel from "tools/components/DialogPanel";
import { Formik, FormikProps } from "formik";
import FieldBox from "tools/components/FieldBox";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps } from "tools/lib/utility";

interface ICreateTenantProps {
    isOpened: boolean;
    onCancel?: () => void;
    onValidate: (tenant: ITenantSummaryModel) => void;
}
export function CreateTenant({ isOpened, onCancel, onValidate }: ICreateTenantProps) {
    const initialValues: ITenantSummaryModel = {
        defaultCurrencyCode: "EUR",
        key: "",
        name: ""
    };
    return <Formik onSubmit={onValidate} initialValues={initialValues} enableReinitialize={true} validateOnMount={true}>{renderForm}</Formik>;
    function renderForm({ values: formValues, dirty, isValid, submitForm }: FormikProps<ITenantSummaryModel>) {
        return <DialogPanel
            onBackClick={onCancel}
            title="Create tenant"
            actions={[{ label: "Create the tenant", onClick: submitForm }]}
            isOpened={isOpened}>
            <FieldBox display="flex" flexDirection="column">
                <FormTextField name={oProps<ITenantSummaryModel>().path("key")} label="Code/Key" required={true} maxLength={50} />
                <FormTextField name={oProps<ITenantSummaryModel>().path("name")} label="Name" required={true} maxLength={250} />
                <FormTextField name={oProps<ITenantSummaryModel>().path("defaultCurrencyCode")} label="Default Currency Code" required={true} maxLength={3} />
            </FieldBox>
        </DialogPanel>;
    }
}
