import React from 'react';
import TreeViewSelect from 'tools/components/TreeViewSelect';
import { useFieldEx, IFormField, ValidatorParams } from 'tools/lib/fieldHelpers';
import { ReadOnlyContext } from "./ReadOnlyContext";

const validators = {
    required: (v: string | undefined, isTrue?: boolean) => isTrue && (typeof (v) === "undefined" || (v ?? "") === "") ? "Required" : undefined,
}

type KeyType = string | number;

export interface IFormSimpleSelectFieldProps<T, K extends KeyType> extends IFormField, ValidatorParams<typeof validators> {
    fullWidth?: boolean;
    onSelectionChanged?: (value: K | undefined) => void;
    options: T[];
    getChildren: (element?: T) => T[];
    getKey: (element: T) => K;
    getPrimaryContent: (row: T) => React.ReactNode;
}

export default function FormTreeViewSelectField<T, K extends KeyType>({ name, label, helperText, disabled, error = false, fullWidth = true,
    onSelectionChanged,
    options,
    getChildren,
    getKey,
    getPrimaryContent,
    ...requestedValidations
}: IFormSimpleSelectFieldProps<T, K>) {


    const { error: fieldError, helperText: fieldHelperText, label: fieldLabel, onValueChanged, value } = useFieldEx<K, typeof validators>({ name, helperText, label, required: requestedValidations.required, error, requestedValidations, validators });

    const handleSelectionChanged = (newValue: K | undefined) => {
        onValueChanged(newValue);
        if (onSelectionChanged) {
            onSelectionChanged(newValue);
        }
    }

    return <ReadOnlyContext.Consumer>
        {readOnly => <TreeViewSelect<T, K>
            getKey={getKey}
            readOnly={readOnly}
            hideClearButton={requestedValidations.required}
            error={fieldError}
            helperText={fieldHelperText}
            label={fieldLabel}
            value={!value ? undefined : value}
            disabled={disabled || readOnly}
            getChildren={getChildren}
            options={options}
            getPrimaryContent={getPrimaryContent}
            onSelectionChanged={handleSelectionChanged}
            fullWidth={fullWidth} />}
    </ReadOnlyContext.Consumer>
}
