import { Epic } from "redux-observable";
import { forkJoin, from, merge, of } from "rxjs";
import { filter, map, mergeMap, share, toArray } from "rxjs/operators";
import { customScreensApi, IGetSicavModel, ISicavModel, macroScriptsApi, managedSicavsApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { ApiError } from "lib/dataAccess";

export const loadSicavs: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedSicav", "managedSicavLoadAll"),
        mergeMap(() => managedSicavsApi.getAllAsync({})),
        map(ActionFactories.managedSicav.managedSicavLoadedAll));


export const loadProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedSicav", "managedSicavProcessExecutionsLoad"),
        mergeMap(id => managedSicavsApi.getProcessesAsync({ id })),
        map(ActionFactories.managedSicav.managedSicavProcessExecutionsLoaded));

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedSicav", "managedSicavDocumentsLoad"),
        mergeMap(id => managedSicavsApi.getDocumentsAsync({ id })),
        map(ActionFactories.managedSicav.managedSicavDocumentsLoaded));

function createEmptySicav(): ISicavModel {
    return {
        type: "SicavModel",
        id: 0,
        internalCode: "",
        name: "",
        isUnderManagement: true,
        classifications: {},
        entityExtensionFieldsValues: {},
        hasPersonalPortalTheme: false,
        entitiesId: []
    };
}

export const submitCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedSicav", "managedSicavCustomScreenDataSubmit"),
        mergeMap(managedSicavsApi.submitCustomScreenDataAsync),
        map(ActionFactories.managedSicav.managedSicavCustomScreenDataSubmitted));
export const loadCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedSicav", "managedSicavCustomScreenDatasLoad"),
        mergeMap(id => managedSicavsApi.getLastCustomScreenDatasAsync({ id })),
        map(ActionFactories.managedSicav.managedSicavCustomScreenDatasLoaded));

export const loadSicav: Epic<IAnyAction>
    = (action$) => {
        const requestedId$ = action$.pipe(
            mapToPayload("managedSicav", "managedSicavLoad"),
            share()
        );
        const existingSicav$ = requestedId$.pipe(
            filter(id => !!id),
            map(id => id as number),
            mergeMap(id => {
                const entity$ = of(id).pipe(
                    mergeMap(id => managedSicavsApi.getAsync({ id: id as number })));

                const entityImage$ = of(id).pipe(
                    mergeMap(id => managedSicavsApi.getImageAsync({ id }).catch(err => {
                        if (err instanceof ApiError && err.status === 404) {
                            return undefined;
                        }
                        throw err;
                    })),
                    share());
                return forkJoin([entity$, entityImage$]).pipe(
                    map(([loadedEntity, loadedImage]) => ({
                        ...loadedEntity,
                        entityPicture: loadedImage ? {
                            content: loadedImage.data,
                            mimeType: loadedImage.mimeType,
                            fileName: loadedImage.name
                        } : undefined
                    })));
            }));

        const newSicav$ = requestedId$.pipe(
            filter(i => !i),
            map(() => ({
                sicav: createEmptySicav(),
                entities: {},
                processDefinitions: {},
                relationships: {}
            } as IGetSicavModel)));
        const sicavAction$ = merge(existingSicav$, newSicav$).pipe(map(ActionFactories.managedSicav.managedSicavLoaded))

        const customScreens$ = requestedId$.pipe(
            mergeMap(() => customScreensApi.getAllAsync({})),
            map(customScreens => customScreens.filter(customScreen => customScreen.type === "ManagedSicavCustomScreenSummaryModel")),
            mergeMap(customScreens => from(customScreens).pipe(
                mergeMap(({ id }) => customScreensApi.getAsync(({ id }))),
                toArray(),
                map(ActionFactories.managedSicav.managedSicavCustomScreensLoaded))));

        return merge(
            requestedId$.pipe(
                filter(id => typeof id === "number" && !!id),
                map(id => ActionFactories.managedSicav.managedSicavCustomScreenDatasLoad(typeof id === "number" ? id : 0))),
            customScreens$,
            sicavAction$);
    };
export const getMonitoringResultLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedSicav", "managedSicavMonitoringResultLoad"),
        mergeMap(macroScriptsApi.getMonitoringResultForTargetAsync),
        map(ActionFactories.managedSicav.managedSicavMonitoringResultLoaded));

export const saveSicav: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedSicav", "managedSicavSave"),
        mergeMap(({ sicav, imageFile }) => {
            return of(sicav).pipe(
                mergeMap(model => managedSicavsApi.saveAsync({ model })),
                mergeMap(savedSicav => {
                    if (imageFile) {
                        return of(imageFile).pipe(
                            mergeMap(({ content, mimeType, fileName }) => managedSicavsApi.saveImageAsync(({
                                id: savedSicav.id,
                                fileModel: {
                                    data: content,
                                    mimeType,
                                    name: fileName
                                }
                            }))),
                            map(() => ActionFactories.managedSicav.managedSicavSaved(savedSicav)));
                    }
                    else {
                        return of(savedSicav).pipe(
                            mergeMap(({ id }) => managedSicavsApi.deleteImageAsync({ id })),
                            map(() => ActionFactories.managedSicav.managedSicavSaved(savedSicav)));
                    }
                }));
        }));

export const deleteSicav: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("managedSicav", "managedSicavDelete"),
            mergeMap(id => managedSicavsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.managedSicav.managedSicavDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
