import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { useCallback, useRef } from "react";

import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { INotControllableEditorProps, useNotControllableEditor } from "./INotControllableEditorProps";
import { Base, Serializer, setLicenseKey } from "survey-core";

setLicenseKey("YjRhNWFkNWItNDMyMi00MGM0LTg3OGItYTZkZjc3MmRjMmYwOzE9MjAyNS0wNC0wMiwyPTIwMjUtMDQtMDI=");

export interface ISurveyDesignerProps extends INotControllableEditorProps<string> {
}

Serializer.addProperty("question", {
    name: "scoreWeight",
    displayName: "Score Weight",
    type: "number",
    category: "general",
    visibleIndex: 100,
    onSetValue: (survey: Base, value: number) => {
        survey.setPropertyValue("scoreWeight", value)
    }
})

Serializer.addProperty("itemvalue", {
    name: "score:number"
})

Serializer.addProperty("boolean", {
    name: "scoreTrue",
    displayName: "Scores for \"True\"",
    type: "number",
    category: "general",
    visibleIndex: 101,
    onSetValue: (survey: Base, value: number) => {
        survey.setPropertyValue("scoreTrue", value)
    }
})

Serializer.addProperty("boolean", {
    name: "scoreFalse",
    displayName: "Score for \"False\"",
    type: "number",
    category: "general",
    visibleIndex: 102,
    onSetValue: (survey: Base, value: number) => {
        survey.setPropertyValue("scoreFalse", value)
    }
})

export default function SurveyDesigner(props: ISurveyDesignerProps) {
    const creatorRef = useRef(new SurveyCreator({
        showLogicTab: true,
        isAutoSave: true,
        showJSONEditorTab: true,
        showTranslationTab: true,
        showThemeTab: true
    }));

    const setter = useCallback((v?: string) => {
        creatorRef.current.JSON = JSON.parse(v ?? "{}");
    }, []);
    const getter = useCallback(() => JSON.stringify(creatorRef.current?.JSON), []);
    useNotControllableEditor(props, setter, getter);

    return <SurveyCreatorComponent creator={creatorRef.current} />
}
