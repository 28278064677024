import * as React from "react";
import { ICurrencyModel, isSecurityInstrumentSummaryModel, SecuritySummaryModel } from "proxy/apiProxy";
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Grid, IconButton } from "@material-ui/core";
import { IDictionary } from "tools/lib/utility";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { SummaryField } from "../global/SummaryField";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";
import { useField } from "formik";
import CloseIcon from '@material-ui/icons/Close';
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { ScreenLink } from "tools/routing/ScreenLink";

export interface ISecuritySummaryProps {
  securities: Record<number, SecuritySummaryModel>;
  currencies: Record<number, ICurrencyModel>;
  label?: string;
  name: string;
}

export default function SecuritySummary({ name: fieldName, securities, currencies, label }: ISecuritySummaryProps) {
  const [expanded, setExpanded] = React.useState(false);
  const [{ value: securityId }, , { setValue }] = useField<number | undefined>(fieldName);
  if (!securityId) {
    return null;
  }
  const security = securities[securityId];
  if (!security) {
    return null;
  }
  const { id, type, name, currencyId = 0, internalCode } = security;
  const currency = currencies[currencyId];
  const isin = isSecurityInstrumentSummaryModel(security) ? security.isin : undefined;
  const handleSwitchExpand = () => setExpanded(!expanded);
  const typeLabel = getSecurityTypeLabel(type);

  const handleSetUndefined = () => setValue(undefined);
  return <ReadOnlyContext.Consumer>{readOnly => <Card >
    <CardHeader
      action={<>
        {!readOnly && <IconButton onClick={handleSetUndefined}><CloseIcon /></IconButton>}
        <IconButton onClick={handleSwitchExpand}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </>}
      subheader={label ?? typeLabel}
      title={name} />
    <Collapse in={expanded} timeout="auto">
      <CardContent>
        <Grid container={true} spacing={2}>
          {!!currencyId && currency && <Grid xs={6} item={true}>
            <SummaryField label="Currency" value={`${currency.isoCode} - ${currency.name.en}`} />
          </Grid>}
          <Grid xs={6} item={true}>
            <SummaryField label="Internal Code" value={internalCode} />
          </Grid>
          {isin && <Grid xs={6} item={true}>
            <SummaryField label="Isin" value={isin} />
          </Grid>}
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="small" component={ScreenLink}
          destination={{ type: "Screen", key: "Security", label: "Open Security", params: { id: String(id) } }}        >
          <OpenInBrowser />
          Open Security
        </Button>
      </CardActions>
    </Collapse>
  </Card>}
  </ReadOnlyContext.Consumer>
}



export interface ISecuritySummaryReadOnlyProps {
  security?: SecuritySummaryModel;
  currencies: IDictionary<ICurrencyModel>;
  label?: string;
  securityId?: number;
  securities?: Record<number | string, SecuritySummaryModel>;
}

export function SecuritySummaryReadOnly({ security, currencies, label, securities, securityId }: ISecuritySummaryReadOnlyProps) {
  const { id, type, name, currencyId = 0, internalCode } = security ?? (securities ?? {})[securityId ?? 0];
  const currency = currencies[currencyId];
  const isin = isSecurityInstrumentSummaryModel(security) ? security.isin : undefined;
  const [expanded, setExpanded] = React.useState(false);
  const handleSwitchExpand = () => setExpanded(!expanded);
  const typeLabel = getSecurityTypeLabel(type);

  return <Card >
    <CardHeader
      action={<IconButton onClick={handleSwitchExpand}>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>}
      subheader={label ?? typeLabel}
      title={name} />
    <Collapse in={expanded} timeout="auto">
      <CardContent>
        <Grid container={true} spacing={2}>
          {!!currencyId && currency && <Grid xs={6} item={true}>
            <SummaryField label="Currency" value={`${currency.isoCode} - ${currency.name.en}`} />
          </Grid>}
          <Grid xs={6} item={true}>
            <SummaryField label="Internal Code" value={internalCode} />
          </Grid>
          {isin && <Grid xs={6} item={true}>
            <SummaryField label="Isin" value={isin} />
          </Grid>}
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="small" component={ScreenLink}
          destination={{ type: "Screen", key: "Security", label: "Open Security", params: { id: String(id) } }}        >
          <OpenInBrowser />
          Open Security
        </Button>
      </CardActions>
    </Collapse>
  </Card>
}
