import { AnyActionOf, produceActionFactories } from "tools/lib/store";
import produce from "immer";
import { ScreenKey } from "tools/routing/screenRouteHooks";

export interface IGoToScreenSectionPayload {
    screenKey?: ScreenKey;
    sectionKey?: string;
    parameters?: {
        [parameterName: string]: any;
    }
}

export const ActionFactories = produceActionFactories({
    /**
     * @deprecated Prefer taking with the router directly, from within your components using `<ScreenLink>` or `useScreenNavigate`.
     * To be able to remove it, we need to find a way of navigating at the end of epics.
     * @see useNavigationNavigate
     */
    navigationNavigate: (payload?: IGoToScreenSectionPayload) => payload,
    /**
     * @deprecated Prefer taking with the router directly
     * @see navigationNavigate
     */
    stopShouldNavigate: () => undefined,
});

export interface IState {
    /**
     * @deprecated Use instead `useScreenNavigate`
     */
    shouldNavigateTo?: IGoToScreenSectionPayload;
}

export function reducer(state: IState = {}, action: (AnyActionOf<typeof ActionFactories>)): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "navigationNavigate":
                draft.shouldNavigateTo = action.payload
                break
            case "stopShouldNavigate":
                draft.shouldNavigateTo = undefined
                break
        }
    });
}
