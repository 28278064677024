import { IExecuteModel } from "proxy/apiProxy";
import DialogPanel from "tools/components/DialogPanel";
import { Formik, FormikProps } from "formik";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormTextField from "tools/fieldComponents/FormTextField";
import { Grid } from "@material-ui/core";
import { useMemo } from "react";
import { oProps } from "tools/lib/utility";
import { StringToStringDictionaryField } from "tools/fieldComponents/StringToStringDictionaryField";

export interface IDevelopmentItemParametersDialogProps {
    onValidated: (parameters: IExecuteModel) => void;
    onCancel: () => void;
    initialParameters: IExecuteModel;
    children?: React.ReactNode;
}
export function DevelopmentItemParametersDialog({ onValidated, onCancel, initialParameters, children }: IDevelopmentItemParametersDialogProps) {
    return <Formik onSubmit={onValidated} initialValues={initialParameters} enableReinitialize={true} validateOnMount={true}>
        {formikProps => <DevelopmentItemParametersForm form={formikProps} onCancel={onCancel} children={children} />}
    </Formik>
}
interface IDevelopmentItemParametersFormProps {
    form: FormikProps<IExecuteModel>;
    onCancel: () => void;
    children?: React.ReactNode;
}
function DevelopmentItemParametersForm({ form: { submitForm, isValid }, onCancel, children }: IDevelopmentItemParametersFormProps) {
    const actions = useMemo(() => [{
        disabled: !isValid,
        label: "Ok",
        onClick: submitForm
    }], [isValid, submitForm]);
    return <DialogPanel onBackClick={onCancel} actions={actions} maxWidth="sm" title="Execution parameters" isOpened>
        <Grid container spacing={1}>
            <Grid item xs={6} >
                <FormDatePickerField name={oProps<IExecuteModel>().path("asOfDate")} label="As Of" />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("culture")} label="Culture" />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "entityCode")} label="Entity Code" />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "entityId")} label="Entity Id" isNumber />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "portfolioCode")} label="Portfolio Code" />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "portfolioId")} label="Portfolio Id" isNumber />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "securityCode")} label="Security Code" />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "securityId")} label="Security Id" isNumber />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "relationshipId")} label="Relationship Id" isNumber />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "processExecutionId")} label="Process Execution Id" isNumber />
            </Grid>
            <Grid item xs={6}>
                <FormTextField name={oProps<IExecuteModel>().path("related", "processExecutionTaskCode")} label="Process Execution Task Code" />
            </Grid>
            {children}
            <Grid item xs={12}>
                <StringToStringDictionaryField label="Parameters" path={oProps<IExecuteModel>().path("parameters")} />
            </Grid>
        </Grid>
    </DialogPanel>
}
