import produce from "immer";
import { IReportTemplateCategoryModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IState {
    reportTemplateCategoryAllLoading: boolean;
    reportTemplateCategoryLoading: boolean;
    reportTemplateCategories: IReportTemplateCategoryModel[];
    reportTemplateCategoryCurrent?: IReportTemplateCategoryModel;
    reportTemplateCategorySaving: boolean;
    reportTemplateCategoryDeleting: boolean;
}
export const ActionFactories = produceActionFactories({
    reportTemplateCategoryLoad: (payload: number) => payload,
    reportTemplateCategoryLoaded: (payload: IReportTemplateCategoryModel) => payload,
    reportTemplateCategorySave: (payload: IReportTemplateCategoryModel) => payload,
    reportTemplateCategorySaved: (payload: IReportTemplateCategoryModel) => payload,
    reportTemplateCategoryDelete: (payload: number) => payload,
    reportTemplateCategoryDeleted: (payload: number) => payload,
    reportTemplateCategoryLoadAll: () => undefined,
    reportTemplateCategoryLoadedAll: (payload: IReportTemplateCategoryModel[]) => payload,
});

export function reducer(
    state: IState = {
        reportTemplateCategoryLoading: false,
        reportTemplateCategoryAllLoading: false,
        reportTemplateCategorySaving: false,
        reportTemplateCategoryDeleting: false,
        reportTemplateCategories: []
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "reportTemplateCategoryLoadAll":
                draft.reportTemplateCategoryAllLoading = true;
                break;
            case "reportTemplateCategoryLoadedAll":
                draft.reportTemplateCategoryAllLoading = false;
                draft.reportTemplateCategories = action.payload;
                break;
            case "reportTemplateCategoryLoad":
                draft.reportTemplateCategoryLoading = true;
                draft.reportTemplateCategoryCurrent = undefined;
                break;
            case "reportTemplateCategoryLoaded":
                draft.reportTemplateCategoryLoading = false;
                draft.reportTemplateCategoryCurrent = action.payload;
                break;
            case "reportTemplateCategorySave":
                draft.reportTemplateCategorySaving = true;
                break;
            case "reportTemplateCategorySaved":
                draft.reportTemplateCategorySaving = false;
                const saved = action.payload;
                if (draft.reportTemplateCategoryCurrent) {
                    draft.reportTemplateCategoryCurrent = saved;
                }
                const existing = draft.reportTemplateCategories.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.reportTemplateCategories.push(saved);
                }
                break;
            case "reportTemplateCategoryDelete":
                draft.reportTemplateCategoryDeleting = true;
                break;
            case "reportTemplateCategoryDeleted":
                draft.reportTemplateCategoryDeleting = false;
                const deletedId = action.payload;
                if (draft.reportTemplateCategoryCurrent && draft.reportTemplateCategoryCurrent.id === deletedId) {
                    delete draft.reportTemplateCategoryCurrent;
                }
                const idx = draft.reportTemplateCategories.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.reportTemplateCategories.splice(idx, 1);
                }
                break;
        }
    });
}
