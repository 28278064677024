import { Epic } from "redux-observable";
import { concat, from, merge } from "rxjs";
import { map, mergeMap, share, switchMap } from "rxjs/operators";
import { dailyDataApi, managedPortfoliosApi, portfolioComplianceApi, tradeDatesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";


export const loadTradeDates: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tradeDates", "tradeDateAllLoad"),
        mergeMap(() => tradeDatesApi.getAllAsync()),
        map(ActionFactories.tradeDates.tradeDateAllLoaded));

export const loadTradeEpic: Epic<IAnyAction>
    = action$ => {
        const tradeDateLoad$ = action$.pipe(
            mapToPayload("tradeDates", "tradeDateLoad"),
            share());
        return tradeDateLoad$.pipe(
            switchMap(tradeDate => {
                const tradeDateLoaded$ = from(tradeDatesApi.getAsync({ tradeDate })).pipe(
                    map(ActionFactories.tradeDates.tradeDateLoaded));
                const lastCompositionsLoaded$ = from(dailyDataApi.searchPositionsAsync({ lastOnly: true, lastAsOfDate: tradeDate })).pipe(
                    map(ActionFactories.tradeDates.tradeDateCompositionsLoaded));
                const portfolios$ = from(managedPortfoliosApi.getAllAsync({})).pipe(
                    map(ActionFactories.tradeDates.tradePortfoliosLoaded));
                return concat(
                    tradeDateLoaded$,
                    lastCompositionsLoaded$,
                    portfolios$
                );
            })
        )
    }
export const saveTradeDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tradeDates", "tradeDateSave"),
        mergeMap(model => tradeDatesApi.saveAsync({ model })),
        map(ActionFactories.tradeDates.tradeDateSaved));

export const submitTradeDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tradeDates", "tradeDateSubmit"),
        mergeMap(tradeDate => tradeDatesApi.submitAsync({ tradeDate }).then(() => tradeDate)),
        map(ActionFactories.tradeDates.tradeDateSubmitted));

export const getConfirmationTradeDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tradeDates", "tradeDateConfirmationsGet"),
        mergeMap(tradeDate => tradeDatesApi.getTradesConfirmationAsync({ tradeDate }).then(() => tradeDate)),
        map(ActionFactories.tradeDates.tradeDateConfirmationsGet));
export const getGenerationTradeDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tradeDates", "tradeDateGenerationLoad"),
        mergeMap(tradeDatesApi.getGenerationAsync),
        map(ActionFactories.tradeDates.tradeDateGenerationLoaded));
export const mustCheckSimulation: Epic<IAnyAction>
    = action$ => merge(
        action$.pipe(mapToPayload("tradeDates", "tradeDateLoad")),
        action$.pipe(mapToPayload("tradeDates", "tradeDateSaved"), map(({ date }) => date)))
        .pipe(map(ActionFactories.tradeDates.tradeDateCheck));

export const checkSimulation: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("tradeDates", "tradeDateCheck"),
        mergeMap(tradeDate => portfolioComplianceApi.checkComplianceOnSimulationAsync({ tradeDate, tradeBooksOnly: true }).catch(() => ({ failureDetail: "macro failure during check" }))),
        map(ActionFactories.tradeDates.tradeDateChecked));
