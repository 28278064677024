import { RelationshipModel, RelationshipSummaryModel } from "proxy/apiProxy";

export function getRelationshipTypeLabel(type: (RelationshipModel | RelationshipSummaryModel)["type"]): string;
export function getRelationshipTypeLabel(type: (RelationshipModel | RelationshipSummaryModel)["type"] | null): string | null;
export function getRelationshipTypeLabel(type: (RelationshipModel | RelationshipSummaryModel)["type"] | undefined): string | undefined;
export function getRelationshipTypeLabel(type: (RelationshipModel | RelationshipSummaryModel)["type"] | undefined | null): string | null | undefined;
export function getRelationshipTypeLabel(type: (RelationshipModel | RelationshipSummaryModel)["type"] | undefined | null): string | null | undefined {
    if (typeof (type) === "undefined") {
        return undefined;
    }
    if (!type) {
        return null;
    }
    switch (type) {
        case "CounterpartyRelationshipModel":
        case "CounterpartyRelationshipSummaryModel": return "Counterparty";
        case "InvestorRelationshipModel":
        case "InvestorRelationshipSummaryModel": return "Investor";
        case "RoleRelationshipModel":
        case "RoleRelationshipSummaryModel": return "Role";
    }
}
