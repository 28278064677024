import {
    isDerivativeSummaryModel, isRegularSecuritySummaryModel, IOperationTypeModel, ICurrencyModel, PortfolioSummaryModel, SecuritySummaryModel, isSecurityInstrumentSummaryModel, ISubFundSummaryModel,
    TradeBookTradeModel, ITradeBookFxTradeModel, ITradeBookSecurityTradeModel, ICountryModel, ITradeStatusModel, IFeatureModel, IOrderTypeModel
} from "proxy/apiProxy";
import * as React from "react";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { useField } from "formik";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";
import { getPortfolioTypeLabel } from "features/ManagedPortfolio/getPortfolioTypeLabel";
import { IPosition } from "./slice";
import { isCurrencyPosition } from "./isCurrencyPosition";
import { useGrants, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import { Box, Button, createStyles, Paper, withStyles, Divider, Badge, Typography } from "@material-ui/core";
import TradeDetails from "./TradeDetails";
import MenuDropdown, { IMenuItemDropdown } from "tools/components/MenuDropdown";
import CashUsdOutlineIcon from 'mdi-material-ui/Cash';
import CheckbookIcon from 'mdi-material-ui/Checkbook';
import { oProps } from "tools/lib/utility";

export interface ITradeBooksTradesEditProps {
    dictionaries: {
        portfolios: Record<string | number, PortfolioSummaryModel>;
        subFunds: Record<string | number, ISubFundSummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
    };
    referenceCurrencies: Record<string | number, ICurrencyModel>;
    referenceCountries: Record<string | number, ICountryModel>;
    positions: IPosition[];
    tradesField: string;
    onAddTrade: (trade: TradeBookTradeModel) => void;
    onEditTrade: (tradeIndex: number) => void;
    onDeleteTrade: (tradeIndex: number) => void;
    expandedDetailRowIds: (number | string)[];
    onExpandedDetailRowIdsChange: (expandedRowIds: (number | string)[]) => void;
}
const StyledPaper = withStyles(theme =>
    createStyles({
        root: {
            width: 600
        }
    })
)(Paper);

const StyledBox = withStyles(theme =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        }
    })
)(Box);
const StyledBox2 = withStyles(theme =>
    createStyles({
        root: {
            padding: theme.spacing(1),
            alignItems: "baseline",
            "& > *:not(:first-child)": {
                marginLeft: theme.spacing(2)
            }
        }
    })
)(Box);

interface ITradeReference<T extends TradeBookTradeModel> {
    index: number;
    trade: T;
}
interface IGroupedTrades {
    [portfolioId: number]: {
        buyCurrency: { [currencyId: number]: ITradeReference<ITradeBookFxTradeModel>[] },
        sellCurrency: { [currencyId: number]: ITradeReference<ITradeBookFxTradeModel>[] },
        buySecurity: { [securityId: number]: ITradeReference<ITradeBookSecurityTradeModel>[] },
        sellSecurity: { [securityId: number]: ITradeReference<ITradeBookSecurityTradeModel>[] },
        sellAllSecurity: { [securityId: number]: ITradeReference<ITradeBookSecurityTradeModel>[] }
    }
}
function groupTrades(trades: TradeBookTradeModel[]): IGroupedTrades {
    const response: IGroupedTrades = {};

    for (let index = 0; index < trades.length; index++) {
        const trade = trades[index];
        if (!response[trade.portfolioId]) {
            response[trade.portfolioId] = {
                buyCurrency: {},
                sellCurrency: {},
                buySecurity: {},
                sellSecurity: {},
                sellAllSecurity: {},
            };
        }
        if (trade.type === "TradeBookFxTradeModel") {
            if (!response[trade.portfolioId].buyCurrency[trade.purchasedCurrencyId]) {
                response[trade.portfolioId].buyCurrency[trade.purchasedCurrencyId] = [];
            }
            response[trade.portfolioId].buyCurrency[trade.purchasedCurrencyId].push({ trade, index });
            if (!response[trade.portfolioId].sellCurrency[trade.soldCurrencyId]) {
                response[trade.portfolioId].sellCurrency[trade.soldCurrencyId] = [];
            }
            response[trade.portfolioId].sellCurrency[trade.soldCurrencyId].push({ trade, index });
        }
        else {
            switch (trade.buySell) {
                case IOperationTypeModel.TotalSell:
                    if (!response[trade.portfolioId].sellAllSecurity[trade.securityId]) {
                        response[trade.portfolioId].sellAllSecurity[trade.securityId] = [];
                    }
                    response[trade.portfolioId].sellAllSecurity[trade.securityId].push({ trade, index });
                    break;
                case IOperationTypeModel.Sell:
                    if (!response[trade.portfolioId].sellSecurity[trade.securityId]) {
                        response[trade.portfolioId].sellSecurity[trade.securityId] = [];
                    }
                    response[trade.portfolioId].sellSecurity[trade.securityId].push({ trade, index });
                    break;
                case IOperationTypeModel.Buy:
                    if (!response[trade.portfolioId].buySecurity[trade.securityId]) {
                        response[trade.portfolioId].buySecurity[trade.securityId] = [];
                    }
                    response[trade.portfolioId].buySecurity[trade.securityId].push({ trade, index });
                    break;
            }
        }
    }
    return response;
}

function TradeBooksTradesEdit({ dictionaries, referenceCurrencies, referenceCountries, tradesField, positions, onAddTrade, onEditTrade, onDeleteTrade, expandedDetailRowIds, onExpandedDetailRowIdsChange }: ITradeBooksTradesEditProps) {
    const { portfolios, securities } = dictionaries;
    const [, { value: trades }] = useField<TradeBookTradeModel[]>(tradesField);
    const groupedTrades = React.useMemo(() => groupTrades(trades), [trades]);
    const getRowKey = ({ id }: IPosition) => id;
    const isGranted = useGrants();
    const editAllowed = isGranted(IFeatureModel.TradeBookWrite);


    const {
        classificationTypes,
        classificationTypesFlat
    } = useReduxSelections("securityClassificationType");

    const { classificationTypesColumns, classificationTypesState } = React.useMemo(() => getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, (position: IPosition) => {
        if (isCurrencyPosition(position)) {
            return;
        }
        if (!position.securityId) {
            return;
        }
        const security = dictionaries.securities[position.securityId];
        if (!security) {
            return;
        }
        if (isSecurityInstrumentSummaryModel(security)) {
            return security.classifications;
        }
        return;
    }), [dictionaries.securities, classificationTypes, classificationTypesFlat]);
    const relatedTrades = (position: IPosition, gt: IGroupedTrades) => {
        if (!gt[position.portfolioId]) {
            return undefined;
        }
        if (isCurrencyPosition(position)) {
            const rt = [
                ...gt[position.portfolioId].buyCurrency[position.currencyId] ?? [],
                ...gt[position.portfolioId].sellCurrency[position.currencyId] ?? []];
            return !!rt.length ? rt.length : undefined;
        }
        else {
            const rt = [
                ...gt[position.portfolioId].sellAllSecurity[position.securityId] ?? [],
                ...gt[position.portfolioId].sellSecurity[position.securityId] ?? [],
                ...gt[position.portfolioId].buySecurity[position.securityId] ?? []];
            return !!rt.length ? rt.length : undefined;
        }
    }
    const columns: IColumnDefinition[] = React.useMemo(() => [
        {
            name: "AddTrade", title: "+", getCellValue: (position: IPosition) => {
                if (isCurrencyPosition(position)) {
                    const handleAddBuyTrade = () => {
                        onAddTrade({
                            type: "TradeBookFxTradeModel",
                            disabled: false,
                            id: 0,
                            portfolioId: position.portfolioId,
                            status: ITradeStatusModel.New,
                            purchasedCurrencyId: position.currencyId,
                            orderType: IOrderTypeModel.AtMarket
                        } as ITradeBookFxTradeModel);
                    }
                    const handleAddSellTrade = () => {
                        onAddTrade({
                            type: "TradeBookFxTradeModel",
                            disabled: false,
                            id: 0,
                            portfolioId: position.portfolioId,
                            status: ITradeStatusModel.New,
                            soldCurrencyId: position.currencyId,
                            orderType: IOrderTypeModel.AtMarket
                        } as ITradeBookFxTradeModel);
                    }
                    const items: IMenuItemDropdown[] = [
                        { content: "Add Sell Trade", onClick: handleAddSellTrade },
                        { content: "Add Buy Trade", onClick: handleAddBuyTrade },
                    ];
                    return <MenuDropdown icon={<Badge badgeContent={relatedTrades(position, groupedTrades)} color="primary"><CashUsdOutlineIcon /></Badge>} items={items} size="small" />
                }
                else {
                    const handleAddBuyTrade = () => {
                        onAddTrade({
                            type: "TradeBookSecurityTradeModel",
                            disabled: false,
                            id: 0,
                            portfolioId: position.portfolioId,
                            status: ITradeStatusModel.New,
                            securityId: position.securityId,
                            buySell: IOperationTypeModel.Buy,
                            orderType: IOrderTypeModel.AtMarket
                        } as ITradeBookSecurityTradeModel);
                    }
                    const handleAddSellTrade = () => {
                        onAddTrade({
                            type: "TradeBookSecurityTradeModel",
                            disabled: false,
                            id: 0,
                            portfolioId: position.portfolioId,
                            status: ITradeStatusModel.New,
                            securityId: position.securityId,
                            buySell: IOperationTypeModel.Sell,
                            orderType: IOrderTypeModel.AtMarket
                        } as ITradeBookSecurityTradeModel);
                    }
                    const handleAddSellAllTrade = () => {
                        onAddTrade({
                            type: "TradeBookSecurityTradeModel",
                            disabled: false,
                            id: 0,
                            portfolioId: position.portfolioId,
                            status: ITradeStatusModel.New,
                            securityId: position.securityId,
                            buySell: IOperationTypeModel.TotalSell,
                            orderType: IOrderTypeModel.AtMarket
                        } as ITradeBookSecurityTradeModel);
                    }
                    const items: IMenuItemDropdown[] = [
                        { content: "Add Sell All Trade", onClick: handleAddSellAllTrade },
                        { content: "Add Sell Trade", onClick: handleAddSellTrade },
                        { content: "Add Buy Trade", onClick: handleAddBuyTrade },
                    ];
                    return <MenuDropdown icon={<Badge badgeContent={relatedTrades(position, groupedTrades)} color="primary"><CheckbookIcon /></Badge>} items={items} size="small" />
                }
            }
        },
        {
            name: "NbTrades", title: "Nb Trades", getCellValue: (position: IPosition) => relatedTrades(position, groupedTrades), filteringEnabled: true, columnType: "integer"
        },
        { name: "PortfolioName", title: "Prtf Name", getCellValue: ({ portfolioId }: IPosition) => portfolios[portfolioId].name, aggregationType: "count", filteringEnabled: true },
        { name: "PortfolioCode", title: "Prtf Code", getCellValue: ({ portfolioId }: IPosition) => portfolios[portfolioId].internalCode, filteringEnabled: true },
        { name: "PortfolioType", title: "Prtf Type", getCellValue: ({ portfolioId }: IPosition) => getPortfolioTypeLabel(portfolios[portfolioId].type) },
        { name: "Date", title: "Date", getCellValue: ({ date }: IPosition) => date, columnType: "date" },
        { name: "TargetSecurityCode", title: "Sec Code", getCellValue: (position: IPosition) => isCurrencyPosition(position) ? referenceCurrencies[position.currencyId]?.isoCode : securities[position.securityId].internalCode, filteringEnabled: true },
        { name: "TargetSecurityName", title: "Sec Name", getCellValue: (position: IPosition) => isCurrencyPosition(position) ? referenceCurrencies[position.currencyId]?.name?.en : securities[position.securityId].name, filteringEnabled: true },
        { name: "TargetSecurityType", title: "Sec Type", getCellValue: (position: IPosition) => isCurrencyPosition(position) ? "Cash" : getSecurityTypeLabel(securities[position.securityId].type) },
        {
            name: "TargetSecurityMat", title: "Sec Mat", getCellValue: (position: IPosition) => {
                if (isCurrencyPosition(position)) {
                    return;
                }
                const secu = securities[position.securityId];
                return (secu.type === "BondSummaryModel" || isDerivativeSummaryModel(secu)) ? secu.maturityDate : undefined;
            }, columnType: "date"
        },
        {
            name: "TargetSecurityCurrency", title: "Sec Ccy", getCellValue: (position: IPosition) => {
                const currencyId = isCurrencyPosition(position) ? position.currencyId : securities[position.securityId].currencyId;
                if (!currencyId) {
                    return
                }
                return referenceCurrencies[currencyId]?.isoCode;
            }
        },
        {
            name: "Country", title: "Country", getCellValue: (position: IPosition) => {
                if (isCurrencyPosition(position)) {
                    return;
                }
                const secu = securities[position.securityId];
                let countryId: number | undefined;
                if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
                    if (dictionaries.subFunds[secu.subFundId].countryId) {
                        countryId = dictionaries.subFunds[secu.subFundId].countryId
                    }
                }
                else if (isRegularSecuritySummaryModel(secu)) {
                    countryId = secu.countryId;
                }
                if (!countryId) {
                    return undefined;
                }
                const country = referenceCountries[countryId];
                if (!country) {
                    return "???";
                }
                return country.name?.en;
            }
        },
        {
            name: "TargetSecurityIsin", title: "Isin", getCellValue: (position: IPosition) => {
                if (isCurrencyPosition(position)) {
                    return;
                }
                const secu = securities[position.securityId];
                return isSecurityInstrumentSummaryModel(secu) ? secu.isin : undefined
            }, filteringEnabled: true
        },
        { name: "Quantity", title: "Qty", getCellValue: (position: IPosition) => !isCurrencyPosition(position) ? position.quantity : undefined, columnType: "decimal", filteringEnabled: true },
        { name: "MarketValueInPortfolioCurrency", title: "Mark Val Prtf Cur", getCellValue: ({ marketValueInPortfolioCcy }: IPosition) => marketValueInPortfolioCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "MarketValueInSecurityCurrency", title: "Mark Val Sec Cur", getCellValue: ({ marketValueInSecurityCcy }: IPosition) => marketValueInSecurityCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "Weight", title: "Weight", getCellValue: ({ weight }: IPosition) => weight, columnType: "precisePercentage2", aggregationType: "sum", filteringEnabled: true },
        { name: "AccruedInterestInPortfolioCcy", title: "Accr Int Portf Cur", getCellValue: ({ accruedInterestInPortfolioCcy }: IPosition) => accruedInterestInPortfolioCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "BookCostInPortfolioCcy", title: "Book Cost Portf Cur", getCellValue: ({ bookCostInPortfolioCcy }: IPosition) => bookCostInPortfolioCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "ProfitLossOnMarketPortfolioCcy", title: "P & L On Market Portf Cur", getCellValue: ({ profitLossOnMarketPortfolioCcy }: IPosition) => profitLossOnMarketPortfolioCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "ProfitLossOnFxPortfolioCcy", title: "P & L On Fx Portf Cur", getCellValue: ({ profitLossOnFxPortfolioCcy }: IPosition) => profitLossOnFxPortfolioCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "CostPrice", title: "Cost Price", getCellValue: ({ costPrice }: IPosition) => costPrice, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "ValuationPrice", title: "Valuation Price", getCellValue: ({ valuationPrice }: IPosition) => valuationPrice, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "NbAccruedDays", title: "Nb Accr Days", getCellValue: ({ nbAccruedDays }: IPosition) => nbAccruedDays, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        ...classificationTypesColumns
    ], [classificationTypesColumns, groupedTrades, onAddTrade, portfolios, referenceCurrencies, securities, referenceCountries, dictionaries.subFunds]);
    const state: IGridState = {
        "AddTrade": { width: 100 },
        "Nb Trades": { width: 160 },
        "PortfolioName": { width: 280, groupingPosition: 1 },
        "PortfolioCode": { width: 150, hidden: true },
        "PortfolioType": { width: 120, hidden: true },
        "Date": { width: 120 },
        "Country": { width: 120, hidden: true },
        "TargetSecurityCode": { width: 150, hidden: true },
        "TargetSecurityName": { width: 280 },
        "TargetSecurityType": { width: 120 },
        "TargetSecurityMat": { width: 100, hidden: true },
        "TargetSecurityCurrency": { width: 130 },
        "TargetSecurityIsin": { width: 150 },
        "Quantity": { width: 180 },
        "MarketValueInPortfolioCurrency": { width: 180 },
        "Weight": { width: 110 },
        "AccruedInterestInPortfolioCcy": { width: 180, hidden: true },
        "BookCostInPortfolioCcy": { width: 180, hidden: true },
        "ProfitLossOnMarketPortfolioCcy": { width: 180, hidden: true },
        "MarketValueInSecurityCurrency": { width: 180, hidden: true },
        "ProfitLossOnFxPortfolioCcy": { width: 180, hidden: true },
        "CostPrice": { width: 180, hidden: true },
        "ValuationPrice": { width: 180, hidden: true },
        "NbAccruedDays": { width: 180, hidden: true },
        ...classificationTypesState
    };
    // @ts-ignore
    const detailComponent: React.ComponentType<{ row: IPosition }> = React.memo(({ row: position }) => {
        const relatedTrades: (ITradeReference<ITradeBookFxTradeModel> | ITradeReference<ITradeBookSecurityTradeModel>)[] = groupedTrades[position.portfolioId]
            ? isCurrencyPosition(position)
                ? [
                    ...groupedTrades[position.portfolioId].buyCurrency[position.currencyId] ?? [],
                    ...groupedTrades[position.portfolioId].sellCurrency[position.currencyId] ?? []]
                : [
                    ...groupedTrades[position.portfolioId].sellAllSecurity[position.securityId] ?? [],
                    ...groupedTrades[position.portfolioId].sellSecurity[position.securityId] ?? [],
                    ...groupedTrades[position.portfolioId].buySecurity[position.securityId] ?? []]
            : [];
        if (!relatedTrades.length) {
            return <Typography>No related trade</Typography>
        }
        return <>
            {relatedTrades.map(({ index, trade }) => <StyledPaper>
                <StyledBox>
                    <TradeDetails
                        dictionaries={dictionaries}
                        referenceCurrencies={referenceCurrencies}
                        field={oProps<TradeBookTradeModel[]>(tradesField).path(index)}
                        positions={[position]}
                        trade={trade}
                        key={index}
                        fullMode={false} />

                </StyledBox>
                {(editAllowed && trade.status === ITradeStatusModel.New) && <>
                    <Divider />
                    <StyledBox2 display="flex">
                        <Box flexGrow={1} />
                        <Button onClick={onDeleteTrade.bind(null, index)}>Delete</Button>
                        <Button onClick={onEditTrade.bind(null, index)}>Edit</Button>
                    </StyledBox2>
                </>}
            </StyledPaper>)}
        </>;
    });

    return <ExtendedGrid
        getRowId={getRowKey}
        columns={columns}
        rows={positions}
        initialState={state}
        userCanGroup={true}
        detailComponent={detailComponent}
        expandedDetailRowIds={expandedDetailRowIds}
        onExpandedDetailRowIdsChange={onExpandedDetailRowIdsChange}
    />
}
export default React.memo(TradeBooksTradesEdit);
