import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { CircularProgress, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
	createStyles({
		link: {
			flex: 1,
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			color: theme.palette.text.primary,
			"& > *:not(:first-child)": {
				marginLeft: theme.spacing(1)
			}
		},
		root: {
			padding: theme.spacing(1),
			borderWidth: 2,
			borderRadius: 2,
			borderColor: "#eeeeee",
			borderStyle: "dashed",
			backgroundColor: "#fafafa",
			color: "#bdbdbd",
			outline: "none",
			transition: "border .24s ease-in-out",

			width: 150,
			height: 150,
			boxSizing: "border-box",
			position: "relative"
		},
		acceptStyle: {
			boxShadow: theme.shadows[6],
			borderColor: "transparent",
			borderStyle: "solid",
			marginTop: 2,
			marginLeft: 2,
			marginBottom: -2,
			marginRight: -2
		},
		rejectStyle: {
			borderColor: "#ff1744"
		},
		disabled: {
			backgroundColor: "inherit",
			borderStyle: "dotted"
		},
		img: {
			width: "100%",
			height: "100%",
			objectFit: "contain",
			cursor: "pointer"
		},
		deleteButton: {
			position: "absolute",
			top: 5,
			right: 5
		},
		loading: {
			position: "absolute",
			top: "calc(50% - 18px)",
			left: "calc(50% - 18px)"
		}
	})
);

export interface ISelectedPictureProps {
	onImageSelected: (file: File) => void;
	onClick: () => void;
	onClear?: () => void;
	file: File | undefined;
	emptyLabel?: React.ReactNode;
	disabled?: boolean;
	loading?: boolean;
}
export default function SelectPicture({
	onClick,
	onImageSelected,
	onClear,
	file,
	emptyLabel = <>no picture</>,
	disabled,
	loading
}: ISelectedPictureProps) {
	const [filePreview, setFilePreview] = useState<string | undefined>();
	const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
		accept: "image/*",
		multiple: false,
		disabled: disabled || loading,
		onDrop: ([file], [rejectedFile]) => {
			if (!!file && !rejectedFile) {
				onImageSelected(file);
			}
		}
	});
	useEffect(() => {
		if (filePreview) {
			URL.revokeObjectURL(filePreview);
		}
		if (file) {
			setFilePreview(URL.createObjectURL(file));
		} else {
			setFilePreview(undefined);
		}
		return () => {
			if (filePreview) {
				URL.revokeObjectURL(filePreview);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file]);
	const classes = useStyles();
	const handleOnClick = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		if (onClick) {
			onClick();
		}
	};
	const handleClear = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		if (onClear) {
			onClear();
		}
	};
	const className = classNames(classes.root, {
		[classes.acceptStyle]: isDragAccept,
		[classes.rejectStyle]: isDragReject,
		[classes.disabled]: disabled || loading
	});
	return <div {...getRootProps({ className })}>
		{loading && <CircularProgress className={classes.loading} />}
		{!file && emptyLabel}
		<input {...getInputProps()} />
		{!!file && <>
			<img alt="missing" src={filePreview} className={classes.img} onClick={handleOnClick} />
			{onClear && !loading && <Tooltip className={classes.deleteButton} title="Clear">
				<IconButton size="small" disabled={disabled} onClick={handleClear}              >
					<CloseIcon />
				</IconButton>
			</Tooltip>}
		</>}
	</div>;
}
