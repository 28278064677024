import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { IBatchModel, IFeatureModel } from "proxy/apiProxy";
import { Formik, FormikHelpers } from 'formik';
import BatchData from "./BatchData";
import BatchScript from "./BatchScript";
import BatchScriptVisualization from "./BatchScriptVisualization";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import BatchConditionalScriptEditor from "./BatchConditionalScriptEditor";
import { isNumber, oProps, useNumber } from "tools/lib/utility";
import { getConfig } from "lib/userManager";
import { IActionButton, IMenuActionButton, ISimpleActionButton } from "../../tools/components/FabContainer";
import { Button, Snackbar } from "@material-ui/core";
import BatchConfigureRunModal from "./BatchConfigureRunModal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import { ScreenLink } from "tools/routing/ScreenLink";
import { useScreenParams } from "tools/routing/screenRouteHooks";

export const batchScreen = {
    component: Batch,
    label: "Batches",
    route: "/config/batches/definitions/:id/:tab?" as const,
    tabs: {
        detail: "detail",
        structure: "structure",
        visualRepresentation: "visual",
        conditionalScript: "conditionalScript",
    },
}

const { general: { disableSudo } } = getConfig();

function Batch() {
    const {
        batchScriptSchema,
        batchCurrent,
        batchLoading,
        dictionaries,
        batchConditionalScriptMetadata,
        batchConditionalScriptCheckResult = { errors: [] },
        batchExecutionRequested
    } = useReduxSelections("batch");
    const {
        batchSave,
        batchRequestExecution,
        batchStop,
        batchDelete,
        batchAddEntityInDictionary,
        batchConditionalScriptValidate,
        batchCloseExecutionRequested,
        batchLoad
    } = useReduxActions("batch");
    const { referenceMacroScriptCategories = {} } = useReduxSelections("reference");
    const isGranted = useGrants();
    const [configurationOpen, setConfigurationOpen] = useState<boolean>(false)

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof batchScreen>()
    const idNum = useNumber(id)

    useEffect(() => {
        if (isNumber(idNum))
            batchLoad(idNum)
    }, [batchLoad, idNum]);

    if (!batchCurrent) {
        return <DetailPanelLoading tabNumber={3} />;
    }


    const handleExecute = (asOfDate?: Date) => {
        if (batchCurrent?.id) {
            setConfigurationOpen(false)
            batchRequestExecution({ id: batchCurrent?.id, asOfDate });
        }
    }


    const handleConfigureExecution = () => {
        if (batchCurrent?.id) {
            setConfigurationOpen(true);
        }
    }

    const handleCloseConfigureExecution = () => {
        if (batchCurrent?.id) {
            setConfigurationOpen(false)
        }
    }

    function handleCloseExecutingNotification() {
        if (batchCurrent?.id)
            batchCloseExecutionRequested(batchCurrent?.id)
    }

    const handleOnStop = () => {
        if (batchCurrent?.id) {
            batchStop(batchCurrent.id);
        }
    }
    const handleDelete = () => {
        if (batchCurrent?.id) {
            batchDelete(batchCurrent.id);
        }
    }

    const handleSubmit = (values: IBatchModel, { setSubmitting }: FormikHelpers<IBatchModel>) => {
        batchSave(values)
        setSubmitting(false);
    }

    const title = !!batchCurrent.id ? batchCurrent.name : 'New batch';
    const tabs: ISubMenuTabs<typeof batchScreen>[] = [{
        value: "detail",
        label: "Data"
    }, {
        value: "conditionalScript",
        label: "Condition"
    }, {
        value: "structure",
        label: "Definition"
    }, {
        value: "visualRepresentation",
        label: "Visualization"
    }];
    const extraActionButtons: IActionButton[] = (!!batchCurrent.id && isGranted(IFeatureModel.BatchExecute))
        ? [
            {
                label: "Execute",
                icon: PlayArrowIcon,
                actions: [
                    {
                        label: "Run",
                        onClick: handleExecute
                    },
                    {
                        label: "Configure run",
                        onClick: handleConfigureExecution
                    }
                ]
            } as IMenuActionButton, {
                label: "Stop all executing jobs",
                onClick: handleOnStop,
                icon: StopIcon
            } as ISimpleActionButton
        ]
        : [];

    return <Formik onSubmit={handleSubmit}
        initialValues={batchCurrent}
        enableReinitialize={true}
        validateOnMount={true}>
        {({
            dirty,
            isValid,
            submitForm,
            values
        }) => (
            <WriteAccess value={IFeatureModel.BatchWrite}>
                <DetailPanel
                    isQuerying={batchLoading}
                    title={title}
                    tabs={tabs}
                    tabValue={tabValue}
                    noPadding={tabValue === "structure" || tabValue === "conditionalScript"}
                    canSave={dirty && isValid}
                    onSaveClick={submitForm}
                    canDelete={!!values.id}
                    onDeleteClick={handleDelete}
                    actions={extraActionButtons}
                    saveAllowed={IFeatureModel.BatchWrite}
                    deleteAllowed={IFeatureModel.BatchWrite}
                    saveMustBeConfirmed={!disableSudo}
                    badge={!values.id ? "new" : undefined}>
                    {tabValue === "visualRepresentation" && <BatchScriptVisualization script={values.script} />}
                    {tabValue === "structure" &&
                        <BatchScript jsonSchema={batchScriptSchema} onSaveRequest={submitForm} />}
                    {tabValue === "detail" &&
                        <BatchData batch={values} onEntityLoaded={batchAddEntityInDictionary}
                            dictionaries={dictionaries}
                            referenceMacroScriptCategories={referenceMacroScriptCategories} />}
                    {tabValue === "conditionalScript" && <BatchConditionalScriptEditor
                        fieldName={oProps<IBatchModel>().path("conditionalScript")}
                        metadata={batchConditionalScriptMetadata}
                        onSaveRequest={submitForm}
                        onScriptChanged={batchConditionalScriptValidate}
                        scriptErrors={batchConditionalScriptCheckResult.errors} />}
                    <Snackbar
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        open={batchExecutionRequested}
                        message="Execution started..."
                        action={
                            <>
                                <Button
                                    component={ScreenLink}
                                    destination={{ type: "Screen", key: "BatchExecutions", label: "OPEN" }}
                                    // screenKey="BatchExecutions"
                                    color="secondary" size="small">
                                    OPEN
                                </Button>
                                <IconButton size="small" aria-label="close" color="inherit"
                                    onClick={handleCloseExecutingNotification}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </>
                        }
                    />
                    <BatchConfigureRunModal
                        handleCancel={handleCloseConfigureExecution}
                        handleRun={handleExecute}
                        open={configurationOpen}
                        name={batchCurrent.code} />
                </DetailPanel>
            </WriteAccess>
        )}
    </Formik>;

}
