import { InputAdornment, TextField } from "@material-ui/core";
import { useField } from "formik";
import { ICurrencyModel } from "proxy/apiProxy";
import * as React from "react";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";
// import { useFieldEx, IFormField, ValidatorParams } from 'tools/lib/fieldHelpers';


export interface ICurrencySelectFieldProps {
    fullWidth?: boolean;
    name: string;
    label?: React.ReactNode;
    disabled?: boolean;
    required?: boolean;
    readOnly?: boolean;
}

function normalizeIdValue(value: any) {
    if (typeof value !== "number")
        return;
    if (value === 0)
        return undefined;
    return value;
}
export function CurrencySelectField({ name, label, disabled, fullWidth = true, required, readOnly }: ICurrencySelectFieldProps) {
    const validate = (value: any) => {
        if (!required) {
            return;
        }
        if (required && !normalizeIdValue(value)) {
            return "Required";
        }
        return;
    }

    const { referenceCurrencies: currenciesById = {} } = useReduxSelections("reference");
    const currenciesByCode = React.useMemo(() => Object.keys(currenciesById).reduce((a, v) => { a[currenciesById[v].isoCode.toUpperCase()] = currenciesById[v]; return a; }, {} as Record<string, ICurrencyModel>), [currenciesById]);
    const [{ value }, { error: errorText, initialValue }, { setValue }] = useField<number | undefined>({ name, validate });
    const [textValue, setTextValue] = React.useState("");
    React.useEffect(() => {
        const idValue = normalizeIdValue(value);
        if (idValue && !!currenciesById[idValue]) {
            setTextValue(currenciesById[idValue].isoCode)
        }
    }, [value, currenciesById])
    // React.useEffect(() => {
    //     const idValue = normalizeIdValue(value);
    //     if ((!textValue || typeof textValue === "undefined" || textValue.trim() === "") && idValue && !!currenciesById[idValue]) {
    //         setTextValue(currenciesById[idValue].isoCode)
    //     }
    // }, [textValue, setTextValue, value, currenciesById])
    const isDirty = (initialValue ?? null) !== (value ?? null);
    // const { error: fieldError, helperText: fieldHelperText, label: fieldLabel, onValueChanged, value } = useFieldEx<string | number, typeof validators>({ name, helperText, label, required: requestedValidations.required, error, validators, requestedValidations });
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = e.target.value;
        if (!targetValue || targetValue.trim() === "") {
            setValue(undefined);
        }
        else {
            if (!!currenciesByCode[targetValue.toUpperCase()]) {
                setValue(currenciesByCode[targetValue.toUpperCase()].id);
            } else {
                setValue(undefined);
            }
        }
        setTextValue(targetValue)
    }
    return <ReadOnlyContext.Consumer>
        {readOnly => <TextField
            InputProps={{
                endAdornment: !!value ? <InputAdornment position="end">{currenciesById[value]?.name?.en}</InputAdornment> : undefined,
                readOnly
            }}
            id={name}
            fullWidth={fullWidth}
            helperText={errorText}
            disabled={disabled}
            label={renderLabel(isDirty, required ?? false, label)}
            margin="dense"
            value={textValue}
            error={!!errorText}
            onChange={onChange} />}
    </ReadOnlyContext.Consumer>

}


function renderLabel(isDirty: boolean, isRequired: boolean, label: React.ReactNode | undefined): React.ReactNode {
    if (!label) {
        return null;
    }
    if (isRequired) {
        label = <>{label} *</>;
    }
    if (isDirty) {
        label = <b>{label}</b>
    }
    return label;
}
