import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ProcessExecutionList } from "./ProcessExecutionList";
import SearchPanel from "tools/components/SearchPanel";
import { useEffect } from "react";

export const processExecutionsScreen = {
    component: ProcessExecutions,
    route: "/processexecutions" as const,
    label: "Process Executions"
}

function ProcessExecutions() {
    const { processExecutions = [], processExecutionAllLoading, dictionaries } = useReduxSelections("processExecution");
    const {processExecutionLoadAll} = useReduxActions("processExecution");

    useEffect(() => {
        processExecutionLoadAll()
    }, [processExecutionLoadAll]);

    return <SearchPanel
        title="Process Executions"
        isQuerying={processExecutionAllLoading}>
        <ProcessExecutionList
            showContextualColumns
            processExecutions={processExecutions}
            dictionaries={dictionaries} />
    </SearchPanel>
}