import SearchPanel from "tools/components/SearchPanel";
import { IFeatureModel, IIndexesGetAllParameters, IIndexSummaryModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { Formik, FormikHelpers, FormikProps } from "formik";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps } from "tools/lib/utility";
import { Grid, Typography } from "@material-ui/core";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";

export const indexesScreen = {
    route: "/marketdata/indexes",
    component: Indexes,
    label: "Indices",
    keywords: "Index MSCI",
}

function Indexes() {
    const {
        referenceCurrencies,
        referenceCountries
    } = useReduxSelections("reference");
    const {
        indexes,
        indexAllLoading
    } = useReduxSelections("indexes");
    const { indexSearch } = useReduxActions("indexes");

    const navigate = useScreenNavigate()

    if (!referenceCurrencies || !referenceCountries) {
        return <Typography>References loading...</Typography>
    }

    const columns: IColumnDefinition[] = [
        { name: "Name", title: "Name", getCellValue: (row: IIndexSummaryModel) => row.name, positionToKeep: "left", filteringEnabled: true },
        { name: "Code", title: "Code", getCellValue: (row: IIndexSummaryModel) => row.internalCode, filteringEnabled: true },
        { name: "Currency", title: "Currency", getCellValue: (row: IIndexSummaryModel) => row.referenceCurrencyId && referenceCurrencies[row.referenceCurrencyId].isoCode },
        { name: "Country", title: "Country", getCellValue: (row: IIndexSummaryModel) => row.referenceCountryId && referenceCountries[row.referenceCountryId].isoCode3 },
        { name: "IsCurrencyRiskFree", title: "Ccy Risk Free", getCellValue: (row: IIndexSummaryModel) => row.isCurrencyRiskFree, columnType: "boolean" },
        { name: "IsRateValues", title: "Rate Values", getCellValue: (row: IIndexSummaryModel) => row.isRateValues, columnType: "boolean" },
    ];



    const state: IGridState = {
        "Name": { width: 320 },
        "Code": { width: 150 },
        "Currency": { width: 130 },
        "Country": { width: 130 },
        "IsCurrencyRiskFree": { width: 160, hidden: true },
        "IsRateValues": { width: 160, hidden: true }
    };
    const handleAddNewClick = () => navigate("Index", { id: 0 });
    const handleRowSelect = (row: IIndexSummaryModel) => navigate("Index", { id: row.id });

    const getRowKey = (row: IIndexSummaryModel) => row.id || 0;
    const handleSubmit = (values: IIndexesGetAllParameters, { setSubmitting }: FormikHelpers<IIndexesGetAllParameters>) => {
        indexSearch(values);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={{} as IIndexesGetAllParameters} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;

    function renderForm({ isValid, submitForm }: FormikProps<IIndexesGetAllParameters>) {
        return <SearchPanel
            isQuerying={indexAllLoading}
            onSearchClick={submitForm}
            searchDisabled={!isValid}
            subTitle="Search indices"
            title="Indices"
            onAddClick={handleAddNewClick}
            addAllowed={IFeatureModel.MarketDataWrite}
            renderSearch={<Grid container={true} spacing={1}>
                <Grid item={true} sm={12} md={6} lg={4}>
                    <FormTextField name={oProps<IIndexesGetAllParameters>().path("criterias")} label="Keywords (name, codes...)" />
                </Grid>
            </Grid>}>
            <ExtendedGrid
                rows={indexes}
                columns={columns}
                getRowId={getRowKey}
                initialState={state}
                onRowClick={handleRowSelect}
                defaultExportFileName="Indexes.xlsx"
                userCanGroup={true} />
        </SearchPanel>
    }
}
