import * as React from "react";
import { useReduxSelections, useReduxActions } from "tools/lib/reduxStoreAccess";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { Survey } from "survey-react-ui";
import { useEffect } from "react";
import DetailPanel from "tools/components/DetailPanel";
import { Box } from "@material-ui/core";
import { IMenuTargetQuestionnaire } from "features/App/menu/menuContracts";
import { decodeInput } from "features/App/menuTools";
import { IGenericScreenDatasetLoadPayload } from "./slice";
import { setLicenseKey, SurveyModel, ValueChangedEvent, PageVisibleChangedEvent } from "survey-core";
setLicenseKey("YjRhNWFkNWItNDMyMi00MGM0LTg3OGItYTZkZjc3MmRjMmYwOzE9MjAyNS0wNC0wMiwyPTIwMjUtMDQtMDI=");
type OnValueChangedHandler = (sender: SurveyModel, options: ValueChangedEvent) => any;
type OnPageVisibleChangedHandler = (sender: SurveyModel, options: PageVisibleChangedEvent) => any;
function setCurrentPage(model: SurveyModel, tabParameter: string | undefined) {
    const pageNumber = Number(tabParameter);
    if (isNaN(pageNumber)) {
        model.currentPageNo = 0;
    }
    else {
        model.currentPageNo = pageNumber;
    }
}
function extractResults(model: SurveyModel) {
    const plainData = model.getPlainData({
        includeQuestionTypes: true,
        includeValues: true,
        includeEmpty: true,
        calculations: [{ propertyName: "visible" }, { propertyName: "isRequired" }]
    })
    console.log("SurveyResults", model.data, plainData)
    return { data: model.data, plainData }
}

export const genericScreenScreen = {
    route: "/genericScreen/:input/:tab?" as const,
    label: "Generic Screen",
    tabs: {} as Record<string, string>,
    component: GenericScreen,
}
function GenericScreen() {
    const { genericScreenDatasetResultLoading,
        genericScreenLayoutLoading,
        genericScreenLayout,
        genericScreenDatasetResult
    } = useReduxSelections("genericScreen");
    const { genericScreenDatasetLoad, genericScreenLoad, genericScreenSave } = useReduxActions("genericScreen");
    const [visibleTabs, setVisibleTabs] = React.useState<{ label: string, value: string }[]>([]);
    const [isValid, setIsValid] = React.useState(false);

    // const { responses, template } = questionnaireDetails ?? {}

    const { tab: tabParameter, input } = useScreenParams<typeof genericScreenScreen>();
    const handleValueChanged = React.useCallback<OnValueChangedHandler>((model) => {
        setIsValid(() => {
            return model.validate(false, false);
            // model.calcIsCompleteButtonVisible();
        });
    }, [])
    const handlePageVisibleChanged = React.useCallback<OnPageVisibleChangedHandler>((model) => {
        setVisibleTabs(model.visiblePages.map(i => ({ label: i.title === '' ? i.name : i.title, value: String(i.num - 1) })));
    }, [])

    const screenExecuteOnLoadPayload: IGenericScreenDatasetLoadPayload & { label: string } | undefined = React.useMemo(() => {
        if (!input) {
            return undefined;
        }
        const decodedInput = decodeInput<IMenuTargetQuestionnaire>(input);
        console.log("Screen input:", decodedInput);
        return {
            questionnaireName: decodedInput.questionnaireName,
            asOfDate: decodedInput.executionModel?.asOfDate,
            culture: decodedInput.executionModel?.culture,
            parameters: decodedInput.executionModel?.parameters,
            related: decodedInput.executionModel?.related,
            label: decodedInput.label
        };
    }, [input]);
    useEffect(() => {
        if (!screenExecuteOnLoadPayload) {
            return;
        }
        genericScreenLoad(screenExecuteOnLoadPayload);
    }, [genericScreenLoad, screenExecuteOnLoadPayload]);
    const surveyModel = React.useMemo(() => {
        if (!genericScreenLayout) {
            return undefined;
        }
        const modelTemplateString = typeof genericScreenLayout === "string" ? genericScreenLayout : JSON.stringify(genericScreenLayout)
        const model = new SurveyModel(modelTemplateString)
        model.showCompleteButton = false;
        model.showCompletedPage = false;
        model.showPageNumbers = false;
        model.showPageNumbers = false;
        model.backgroundOpacity = 0;
        model.showNavigationButtons = false;
        model.showPrevButton = false;
        model.showPreviewBeforeComplete = "noPreview";
        model.showProgressBar = "off";
        model.showTitle = false;
        model.onValueChanged.add(handleValueChanged);
        model.onPageVisibleChanged.add(handlePageVisibleChanged);
        setIsValid(model.calcIsCompleteButtonVisible());
        return model;
    }, [genericScreenLayout, handlePageVisibleChanged, handleValueChanged]);
    useEffect(() => {
        if (!surveyModel) {
            return;
        }
        if (genericScreenDatasetResult) {
            surveyModel.data = genericScreenDatasetResult;
        }
        else {
            surveyModel.data = {};
        }
    }, [genericScreenDatasetResult, surveyModel]);
    useEffect(() => {
        surveyModel && setVisibleTabs(surveyModel.visiblePages.map(i => ({ label: i.title === '' ? i.name : i.title, value: String(i.num - 1) })));
    }, [surveyModel]);
    useEffect(() => {
        surveyModel && setCurrentPage(surveyModel, tabParameter);
    }, [surveyModel, tabParameter])
    const handleSave = React.useCallback(() => {
        if (isValid && surveyModel && screenExecuteOnLoadPayload) {
            const { questionnaireName, ...executionParams } = screenExecuteOnLoadPayload;
            const { data, plainData } = extractResults(surveyModel);
            genericScreenSave({
                questionnaireName: screenExecuteOnLoadPayload.questionnaireName, executeSimpleQuestionnaireOnCompleteModel: {
                    responseData: data,
                    responsePlainData: plainData,
                    type: "ExecuteSimpleQuestionnaireOnCompleteModel",
                    ...executionParams
                }
            });
        }
    }, [genericScreenSave, isValid, screenExecuteOnLoadPayload, surveyModel]);
    const handleRefresh = React.useCallback(() => {
        screenExecuteOnLoadPayload && genericScreenDatasetLoad(screenExecuteOnLoadPayload);
    }, [screenExecuteOnLoadPayload, genericScreenDatasetLoad]);
    if (!screenExecuteOnLoadPayload) {
        return null;
    }
    return <DetailPanel
        isQuerying={(genericScreenDatasetResultLoading || genericScreenLayoutLoading) && !surveyModel}
        title={screenExecuteOnLoadPayload.label}
        tabs={visibleTabs}
        tabValue={tabParameter ?? "0"}
        canSave={isValid}
        onSaveClick={handleSave}
        onRefreshClick={handleRefresh}>
        <Box height="100%">
            {surveyModel && <Survey model={surveyModel} />}
        </Box>
    </DetailPanel>
}
