import { IAccountBalanceModel } from "proxy/apiProxy";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { Formik, FormikHelpers, FormikProps } from "formik";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { IErrors, oProps, toList } from "tools/lib/utility";
import FieldBox from "tools/components/FieldBox";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IDailyDataSearchAccountBalancesParameters } from "proxy/apiProxy";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import ManagedPortfolioMultiSelectField from "components/searchers/ManagedPortfolioMultiSelectField";
import ClassificationsData, { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";

export const accountBalancesScreen = {
    route: "/portfoliodata/accountBalances",
    component: AccountBalances,
    label: "Account Balances"
}

interface ISearchAccountBalanceForm extends Omit<IDailyDataSearchAccountBalancesParameters, "classificationIds"> {
    classifications?: {
        [key: string]: number;
        [key: number]: number;
    };
}

function AccountBalances() {
    const { accountBalancesSearch, accountBalancesAddPortfoliosToDictionary } = useReduxActions("accountBalance");
    const { accountBalances, dictionaries, accountBalancesLoading } = useReduxSelections("accountBalance");
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    const {
        classificationTypes: movementClassificationTypes,
        classificationTypesFlat: movementClassificationTypesFlat
    } = useReduxSelections("movementClassificationType");
    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(movementClassificationTypes, movementClassificationTypesFlat, ({ classifications }: IAccountBalanceModel) => classifications);
    const columns: IColumnDefinition[] = [
        { name: "PortfolioId", title: "Portfolio", getCellValue: ({ portfolioId }: IAccountBalanceModel) => dictionaries.portfolios[portfolioId ?? 0]?.name, filteringEnabled: true },
        { name: "Date", title: "Date", getCellValue: ({ date }: IAccountBalanceModel) => date, columnType: "date" },
        { name: "CurrencyId", title: "Currency", getCellValue: ({ currencyId }: IAccountBalanceModel) => referenceCurrencies[currencyId]?.isoCode },
        { name: "Comment", title: "Comment", getCellValue: ({ comment }: IAccountBalanceModel) => comment, filteringEnabled: true },
        { name: "Balance", title: "Balance", getCellValue: ({ balance }: IAccountBalanceModel) => balance, columnType: "decimal", aggregationType: "sum" },
        { name: "BalanceInPortfolioCcy", title: "Balance Prtf Ccy", getCellValue: ({ balanceInPortfolioCcy }: IAccountBalanceModel) => balanceInPortfolioCcy, columnType: "decimal", aggregationType: "sum" },
        ...classificationTypesColumns
    ];
    const state: IGridState = {
        "PortfolioId": { width: 280 },
        "Date": { width: 120 },
        "CurrencyId": { width: 120 },
        "Comment": { width: 280, hidden: true },
        "Balance": { width: 180, },
        "BalanceInPortfolioCcy": { width: 180 },
        ...classificationTypesState
    };

    const getRowKey = (row: IAccountBalanceModel) => row.id;

    const handleSubmit = ({ classifications, ...values }: ISearchAccountBalanceForm, { setSubmitting }: FormikHelpers<ISearchAccountBalanceForm>) => {
        const classificationIds = toList(classifications);
        accountBalancesSearch({ ...values, classificationIds });
        setSubmitting(false);
    }
    const handleValidate = (values: ISearchAccountBalanceForm) => {
        const errors: IErrors<ISearchAccountBalanceForm> = {};
        if (!values.lastOnly) {
            if (!values.from) {
                errors.from = "From date is required";
            }
            if (values.to && values.from && values.from > values.to) {
                errors.to = "Must be greater than the start of period";
            }
        }
        return errors;
    }

    return <Formik onSubmit={handleSubmit} validate={handleValidate} initialValues={{ lastOnly: true } as ISearchAccountBalanceForm} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;

    function renderForm({ isValid, submitForm, values: { lastOnly } }: FormikProps<ISearchAccountBalanceForm>) {
        return (
            <SearchPanel
                isQuerying={accountBalancesLoading}
                onSearchClick={submitForm}
                searchDisabled={!isValid}
                title="Account Balances"
                subTitle="Search by portfolio and time period"
                renderSearch={(<FieldBox display="flex" flexDirection="column">
                    <FieldBox display="flex" flexGrow="1">
                        <ManagedPortfolioMultiSelectField
                            name={oProps<ISearchAccountBalanceForm>().path("portfolioIds")}
                            label="Portfolios"
                            dictionaries={dictionaries}
                            onSelected={accountBalancesAddPortfoliosToDictionary} />
                    </FieldBox>
                    <FieldBox display="flex" flexDirection="column">
                        <ClassificationsData panelTitle="Classifications" fieldName={oProps<ISearchAccountBalanceForm>().path("classifications")} classificationTypes={movementClassificationTypes} />
                    </FieldBox>
                    <FieldBox display="flex" flexDirection="row">
                        <FormCheckBoxField name={oProps<ISearchAccountBalanceForm>().path("lastOnly")} label="Only Last positions" />
                        {(!lastOnly) && <FormDatePickerField name={oProps<ISearchAccountBalanceForm>().path("from")} label="From" />}
                        {(!lastOnly) && <FormDatePickerField name={oProps<ISearchAccountBalanceForm>().path("to")} label="To" />}
                    </FieldBox>
                </FieldBox>)}>
                <ExtendedGrid
                    getRowId={getRowKey}
                    columns={columns}
                    rows={accountBalances}
                    initialState={state}
                    userCanGroup={true}
                    defaultExportFileName="positions.xlsx" />
            </SearchPanel>
        );
    }
}