import { IHistoricalValueModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { ArgumentAxis, Chart, LineSeries, ValueAxis } from '@devexpress/dx-react-chart-material-ui';
import { ArgumentScale, FactoryFn } from '@devexpress/dx-react-chart';
import { oProps } from "tools/lib/utility";
import { scaleTime } from 'd3-scale';
import { Box, FormControlLabel, Switch } from "@material-ui/core";
import FieldBox from "tools/components/FieldBox";

export type VisualizationType = "GRID" | "CHART";

export interface IHistoricalValueProps {
    onValueChanged?: (date: Date, value: number | undefined) => void;
    editable?: boolean;
    historicalValues?: IHistoricalValueModel[];
    visualizationType: VisualizationType;
    onVisualizationTypeChanged?: (visualizationType: VisualizationType) => void;
}
interface IChartViewProps {
    historicalValues: IHistoricalValueModel[];
}
function ChartView({ historicalValues }: IChartViewProps) {

    // @ts-ignore FIXME upgrade to 3.0.6
    return <Chart data={historicalValues}>
        <ArgumentScale factory={scaleTime as unknown as FactoryFn} />
        <ArgumentAxis />
        <ValueAxis />
        <LineSeries
            name="Values"
            valueField={oProps<IHistoricalValueModel>().path("value")}
            argumentField={oProps<IHistoricalValueModel>().path("date")} />
    </Chart>;
}
interface IGridViewProps {
    onValueChanged?: (date: Date, value: number | undefined) => void;
    editable?: boolean;
    historicalValues: IHistoricalValueModel[];
    preciseDecimal?: boolean;
}
function GridView({ historicalValues, preciseDecimal, onValueChanged, editable }: IGridViewProps) {
    const columns: IColumnDefinition[] = [{
        name: "date",
        title: "Date",
        getCellValue: (row: IHistoricalValueModel) => row.date,
        columnType: "date",
        filteringEnabled: true
    }, {
        name: "value",
        title: "Value",
        getCellValue: (row: IHistoricalValueModel) => row.value,
        columnType: preciseDecimal ? "preciseDecimal" : "decimal",
        editable
    }];
    const state: IGridState = {
        "date": { width: 120, sortingPosition: -1 }
    };

    const handleCellValueChanged = (rowId: number | string, columnName: string, value: string) => {
        if (!onValueChanged) {
            return;
        }
        const changed = historicalValues.find(i => getRowKey(i) === rowId);
        if (!changed) {
            return;
        }
        const newValue = Number(value);
        onValueChanged(changed.date, isNaN(newValue) ? undefined : newValue);
    }
    const getRowKey = (row: IHistoricalValueModel) => (row.date && row.date.toDateString()) || "";
    return <ExtendedGrid
        getRowId={getRowKey}
        defaultExportFileName="HistoricalValues.xlsx"
        columns={columns}
        rows={historicalValues}
        initialState={state}
        defaultColumnWidth={120}
        onCellValueChanged={handleCellValueChanged} />;
}
interface IHistoricalDataProps {
    onValueChanged?: (date: Date, value: number | undefined) => void;
    editable?: boolean;
    historicalValues: IHistoricalValueModel[];
    visualizationType: VisualizationType;
}

function HistoricalData({ editable, onValueChanged, historicalValues, visualizationType }: IHistoricalDataProps) {
    switch (visualizationType) {
        case "CHART": return <ChartView historicalValues={historicalValues} />;
        case "GRID": return <GridView historicalValues={historicalValues} preciseDecimal={true} editable={editable} onValueChanged={onValueChanged}/>;
    }
}

export default function HistoricalValue({
                                            editable,
                                            onValueChanged,
                                            visualizationType,
                                            onVisualizationTypeChanged,
                                            historicalValues = []
                                        }: IHistoricalValueProps) {
    if (!onVisualizationTypeChanged) {
        return <HistoricalData visualizationType={visualizationType} historicalValues={historicalValues} />;
    }
    const handleHandleChangeVisualizationType = () => onVisualizationTypeChanged(visualizationType === "CHART" ? "GRID" : "CHART");
    return <Box display="flex" flexDirection="column" height="100%">
        <FieldBox display="flex" flexDirection="row">
            <FormControlLabel
                control={<Switch checked={visualizationType === "CHART"} onChange={handleHandleChangeVisualizationType} />}
                label="Graph" />
            <Box flexGrow={1} />
        </FieldBox>
        <Box flexGrow={1} height="1px">
            <HistoricalData 
                visualizationType={visualizationType} 
                historicalValues={historicalValues}
                editable={editable}
                onValueChanged={onValueChanged} />
        </Box>
    </Box>;
}
