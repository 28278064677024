import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { IFeatureModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import NoteData from "./NoteData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { NoteVersions } from "./NoteVersions";
import { isNumber, oProps, useNumber } from "tools/lib/utility";
import React, { useEffect } from "react";
import { NoteSaveForm } from "./NoteSaveForm";
import { useScreenParams } from "tools/routing/screenRouteHooks";

export const noteScreen = {
    route: "/notes/notes/:id/:tab?" as const,
    component: Note,
    label: "Note",
    tabs: {
        detail: "details",
        versions: "versions"
    }
}

function Note() {
    const {
        noteDeleteCurrent,
        noteRelationshipSelected,
        noteEntitySelected,
        notePortfolioSelected,
        noteSecuritySelected,
        noteLoad
    } = useReduxActions("note");
    const {
        current,
        loading,
        loadingVersions,
        saving,
        currentVersions,
        dictionaries,
        noteVersionContents
    } = useReduxSelections("note");
    const currentForm = React.useMemo(() => {
        if (!current) {
            return null;
        }
        return ({ note: current, versionsToDelete: [], versionsToSave: {} } as NoteSaveForm)
    }, [current]);
    const {
        noteVersionContentLoad,
        noteSave
    } = useReduxActions("note");
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    const { classificationTypes } = useReduxSelections("noteClassificationType");

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof noteScreen>()
    const idNum = useNumber(id)

    useEffect(() => {
        if (isNumber(idNum))
            noteLoad(idNum)
    }, [idNum, noteLoad]);

    if (!currentForm) {
        return <DetailPanelLoading hasSubTitle={false} />;
    }
    const handleDelete = () => {
        const { id } = currentForm.note;
        if (id) {
            noteDeleteCurrent(id);
        }
    }

    const handleSubmit = ({
        note,
        versionsToDelete,
        versionsToSave
    }: NoteSaveForm, { setSubmitting }: FormikHelpers<NoteSaveForm>) => {
        noteSave({
            note,
            versionsToDelete,
            versionsToSave: Object.values(versionsToSave)
        });
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={currentForm} enableReinitialize={true}
        validateOnMount={true}>{renderForm}</Formik>;

    function renderForm(formikProps: FormikProps<NoteSaveForm>) {
        const tabs: ISubMenuTabs<typeof noteScreen>[] | undefined = [{
            value: "detail",
            label: "Data",
        }, {
            value: "versions",
            label: "Versions",
        }];

        if (!current) {
            return;
        }
        const { dirty, isValid, submitForm, values: { note: { id } } } = formikProps;
        // const { id, entityId } = values.relationship;
        const title = !!id ? "Note" : 'New Note';

        const handleSave = () => submitForm();
        return <WriteAccess value={[IFeatureModel.GeneralNoteWrite, IFeatureModel.ManagedPortfolioWrite]}>
            <DetailPanel
                tabs={tabs}
                tabValue={tabValue}
                isQuerying={loading || saving || loadingVersions}
                title={title}
                canSave={dirty && isValid}
                onSaveClick={handleSave}
                canDelete={!!id}
                saveAllowed={[IFeatureModel.GeneralNoteWrite, IFeatureModel.ManagedPortfolioWrite]}
                deleteAllowed={[IFeatureModel.GeneralNoteWrite, IFeatureModel.ManagedPortfolioWrite]}
                onDeleteClick={handleDelete}
                badge={!id ? "new" : undefined}>
                {(tabValue === "detail") && <NoteData
                    noteField={oProps<NoteSaveForm>().path("note")}
                    classificationTypes={classificationTypes} />}
                {(tabValue === "versions") && <NoteVersions
                    // pathToVersionsToDelete={oProps<NoteSaveForm>().path("versionsToDelete")}
                    // pathToVersionsToSave={oProps<NoteSaveForm>().path("versionsToSave")}
                    currentVersions={currentVersions}
                    dictionaries={dictionaries}
                    onNoteVersionRequestLoad={noteVersionContentLoad}
                    versionContents={noteVersionContents}
                    currencies={referenceCurrencies}
                    onRelationshipSelected={noteRelationshipSelected}
                    onEntitySelected={noteEntitySelected}
                    onPortfolioSelected={notePortfolioSelected}
                    onSecuritySelected={noteSecuritySelected}
                />}
            </DetailPanel>
        </WriteAccess>
    }
}
