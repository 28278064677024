import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { IFeatureModel, IHistoricalValueSetModel, IIndexModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import HistoricalValues, { IDataSeries } from "components/global/HistoricalValues";
import IndexData from "./IndexData";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import * as React from "react";
import { useEffect } from "react";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { AddHistoricalValue } from "./AddHistoricalValue";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { useNumber } from "../../tools/lib/utility";
import { isNumber } from "lodash";

export const indexScreen = {
    route: "/marketdata/indexes/:id/:tab?" as const,
    component: Index,
    label: "Index",
    tabs: {
        detail: "details",
        historicalValues: "historicalvalues"
    },
}
export default function Index() {
    const { classificationTypes } = useReduxSelections("securityClassificationType");
    const {
        index,
        indexLoading,
        indexHistoricalLoading,
        indexHistoricalValues,
        indexHistoricalValuesVisualizationType
    } = useReduxSelections("indexes");
    const {
        parametersMarketDataProviders,
    } = useReduxSelections("parameters");
    const {
        indexDelete,
        indexSave,
        indexHistoricalValuesVisualizationTypeChanged,
        indexHistoricalValueSave,
        indexLoad,
        indexHistoricalValuesLoad
    } = useReduxActions("indexes");
    const isGranted = useGrants();

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof indexScreen>()
    const idNum = useNumber(id)

    useEffect(() => {
        if (isNumber(idNum))
            indexLoad(idNum)
    }, [idNum, indexLoad]);

    useEffect(() => {
        if (isNumber(idNum))
            indexHistoricalValuesLoad(idNum)
    }, [idNum, indexHistoricalValuesLoad]);

    const [addingNewHistValue, setAddingNewHistValue] = React.useState(false);

    if (!index) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={false} />;
    }
    const title = !!index.id ? (index.name || "") : `New index`;
    const tabs: ISubMenuTabs<typeof indexScreen>[] = [{
        label: "Data",
        value: "detail"
    }];
    if (index.id) {
        tabs.push({
            label: "Historical series",
            value: "historicalValues"
        });
    }

    const parametersMarketDataProvidersInUse = parametersMarketDataProviders;
    // .filter(parametersMarketDataProvider => Object.keys(parameters.marketDataProviderConnectionSetups ?? {}).indexOf(parametersMarketDataProvider.marketDataProviderCode) >= 0);

    const handleDelete = () => {
        if (index?.id) {
            indexDelete(index.id);
        }
    }
    const handleValueChanged = (date: Date, type: string, value: number | undefined) => {
        if (index?.id) {
            indexHistoricalValueSave({
                historicalValue: {
                    date,
                    type,
                    value
                },
                indexId: index.id
            });
        }
    }
    const handleSubmit = (values: IIndexModel, { setSubmitting }: FormikHelpers<IIndexModel>) => {
        indexSave(values);
        setSubmitting(false);
    }
    const properties = {
        MKT: { title: "Value", columnType: "preciseDecimal" }
    } as IDataSeries;


    const handleAddHistValue = () => setAddingNewHistValue(true);
    const handleCloseNewHistValue = (hv: IHistoricalValueSetModel) => {
        setAddingNewHistValue(false);
        if (index?.id) {
            indexHistoricalValueSave({
                historicalValue: hv,
                indexId: index.id
            });
        }
    };
    const handleCloseNewHistValueCancel = () => setAddingNewHistValue(false);
    const extraActionButtons =
        (tabValue === "historicalValues")
            ? [{
                onClick: handleAddHistValue,
                label: "Add new Historical Value",
                icon: PlaylistAddIcon
            }]
            : [];
    const defaultEmptyHistoricalValue = { type: "MKT" } as IHistoricalValueSetModel;
    const historicalValueTypes: IDataSeries = { "MKT": "Market Value" };

    return <Formik onSubmit={handleSubmit} initialValues={index} enableReinitialize={true} validateOnMount={true}>
        {({ dirty, isValid, submitForm, values }: FormikProps<IIndexModel>) =>
            <WriteAccess value={IFeatureModel.MarketDataWrite}>
                {addingNewHistValue &&
                    <AddHistoricalValue
                        isOpened={addingNewHistValue}
                        onAdd={handleCloseNewHistValue}
                        onCancel={handleCloseNewHistValueCancel}
                        initialHistoricalValue={defaultEmptyHistoricalValue}
                        typeDescriptions={historicalValueTypes}
                    />}
                <DetailPanel
                    isQuerying={indexLoading || indexHistoricalLoading}
                    title={title}
                    canSave={dirty && isValid}
                    onSaveClick={submitForm}
                    canDelete={!!values.id}
                    onDeleteClick={handleDelete}
                    saveAllowed={IFeatureModel.MarketDataWrite}
                    deleteAllowed={IFeatureModel.MarketDataWrite}
                    badge={!values.id ? "new" : undefined}
                    tabs={tabs}
                    tabValue={tabValue}
                    actions={extraActionButtons}>
                    {(tabValue === "detail") && <IndexData
                        classificationTypes={classificationTypes}
                        parametersMarketDataProviders={parametersMarketDataProvidersInUse} />}
                    {(tabValue === "historicalValues") && <HistoricalValues
                        properties={properties}
                        historicalValues={indexHistoricalValues}
                        onVisualizationTypeChanged={indexHistoricalValuesVisualizationTypeChanged}
                        visualizationType={indexHistoricalValuesVisualizationType}
                        editable={isGranted(IFeatureModel.MarketDataWrite)}
                        onValueChanged={handleValueChanged}
                    />}
                </DetailPanel></WriteAccess>
        }
    </Formik>;
}
