import { Epic } from "redux-observable";
import { filter, map, mergeMap, share, tap } from "rxjs/operators";
import { IReportTemplateModel, reportTemplatesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { todayString } from "tools/lib/utility";
import { merge } from "rxjs";
import saveAs from "file-saver";

export const onRequestAnalysis: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateAnalyse"),
        mergeMap(content => reportTemplatesApi.analyzeTemplatePOSTAsync({ content: { content } })),
        map(ActionFactories.reportTemplate.reportTemplateAnalysed));

export const onLoadDataPreview: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateDataPreviewLoad"),
        mergeMap(dataPreviewRequest => reportTemplatesApi.generateReferentialAsync({ dataPreviewRequest })),
        map(ActionFactories.reportTemplate.reportTemplateDataPreviewLoaded));


export const loadReportTemplates: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateLoadAll"),
        mergeMap(() => reportTemplatesApi.getAllAsync({})),
        map(ActionFactories.reportTemplate.reportTemplateLoadedAll));
export const deleteTemplate: Epic<IAnyAction>
    = action$ => {
        const deleted$ = action$.pipe(
            mapToPayload("reportTemplate", "reportTemplateDelete"),
            mergeMap(id => reportTemplatesApi.deleteAsync({ id }).then(() => id)),
            share());
        return merge(
            deleted$.pipe(map(ActionFactories.reportTemplate.reportTemplateDeleted)),
            deleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined)))
        );
    }
export const duplicateTemplate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateLoadForDuplicate"),
        mergeMap(async id => Promise.all([
            reportTemplatesApi.getAsync({ id }),
            reportTemplatesApi.getTemplateContentAsync({ id })])),
        map(([{ id, name, code, ...definition }, { content }]) => ({ content, definition: { ...definition, id: 0, code: `${code}${todayString()}`, name: `${name} (Duplicate)` } })),
        map(ActionFactories.reportTemplate.reportTemplateLoaded));
export const loadTemplate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateLoad"),
        filter(i => !!i),
        mergeMap(async id => Promise.all([
            reportTemplatesApi.getAsync({ id }),
            reportTemplatesApi.getTemplateContentAsync({ id })])),
        map(([definition, { content }]) => ({ content, definition })),
        map(ActionFactories.reportTemplate.reportTemplateLoaded));
export const newTemplate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateLoad"),
        filter(i => !i),
        mergeMap(() => reportTemplatesApi.getEmptyTemplateAsync()),
        map(({ content }) => ({ content, definition: { id: 0, name: "", publishOnPortal: false } as IReportTemplateModel })),
        map(ActionFactories.reportTemplate.reportTemplateLoaded));
export const generateReport: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateGenerate"),
        mergeMap(({ scopeType, ...pars }) => reportTemplatesApi.generateReportAsync(pars).then(file => ({ scopeType, file }))),
        tap(({ file: { blob, fileName } }) => saveAs(blob, fileName)),
        map(({ scopeType }) => ActionFactories.reportTemplate.reportTemplateGenerated(scopeType)));
export const saveReportTemplate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("reportTemplate", "reportTemplateSave"),
        mergeMap(async ({ content, definition }) => {
            const saved = await reportTemplatesApi.saveAsync({ model: definition });
            await reportTemplatesApi.saveTemplateContentAsync({ id: saved.id, content: { content } });
            return { definition: saved, content };
        }),
        map(ActionFactories.reportTemplate.reportTemplateSaved));
