import { DefaultLinkModel, DiagramModel, NodeModel } from '@projectstorm/react-diagrams';
import { BaseModel } from '@projectstorm/react-canvas-core';
import BodyWidget from './BodyWidget';
import { useMemo } from 'react';
import { EtlOperatorNodeModel, IGraphNodeProperties } from './EtlOperatorNode';

export interface IGraphLink {
  fromId: string | number;
  toId: string | number;
}

export interface IErrorNodes {
  [nodeId: string]: string;
}
export interface ICounterNodes {
  [nodeId: string]: number;
}
export type IGraphNode = Omit<IGraphNodeProperties, "error" | "counter">;
export interface IStreamsGraphProps {
  nodes: IGraphNode[];
  links: IGraphLink[];
  errors?: IErrorNodes;
  counters?: ICounterNodes;
  isLocked?: boolean;
}

export default function StreamsGraph({ nodes, links, isLocked, errors, counters }: IStreamsGraphProps) {
  const model = useMemo(() => {
    const nodesDico = nodes.map(element => {
      const tmp = {
        id: element.id,
        node: new EtlOperatorNodeModel({
          properties: {
            ...element,
            error: errors ? errors[element.id] : undefined,
            counter: counters ? counters[element.id] : undefined
          }
        })
      };
      return tmp;
    }).reduce((a, { id, node }) => {
      a[id] = node;
      return a;
    }, {} as Record<string | number, NodeModel>);

    const linksItems = links.map(l => {
      const from = nodesDico[l.fromId];
      if (!from) {
        return null;
      }
      const to = nodesDico[l.toId];
      if (!to) {
        return null;
      }
      const link = new DefaultLinkModel();
      link.setSourcePort(from.getPort('out'));
      link.setTargetPort(to.getPort('in'));
      // link.setLocked(true);
      return link;
    })
      .filter(i => !!i) as BaseModel[];
    const model = new DiagramModel();
    model.addAll(...Object.values(nodesDico), ...linksItems);
    model.setLocked(isLocked ?? false);
    model.setZoomLevel(50);
    return model;
  }, [nodes, links, isLocked, errors, counters]);

  // TODO Bring back events if we have usage for them, otherwise remove
  // const eventDidFire: DiagramListener["eventDidFire"] = useCallback(event => {
  //   if (event.function === "offsetUpdated") return null;
  //   return null;
  //   // console.log(event);
  // }, []);
  // const eventWillFire: DiagramListener["eventWillFire"] = useCallback(event => {
  //   if (event.function === "offsetUpdated") return null;
  //   return null;
  //   // console.log(event);
  // }, []);
  // const linksUpdated: DiagramListener["linksUpdated"] = useCallback(event => {
  //   // console.log(event);
  // }, []);
  // const lockChanged: DiagramListener["lockChanged"] = useCallback(event => {
  //   // console.log(event);
  // }, []);
  // const nodesUpdated: DiagramListener["nodesUpdated"] = useCallback(event => {
  //   // console.log(event);
  // }, []);
  // useEffect(() => {
  //   const listener: DiagramListener = {
  //     eventDidFire,
  //     eventWillFire,
  //     linksUpdated,
  //     lockChanged,
  //     nodesUpdated
  //   } as DiagramListener;
  //   model.registerListener(listener);
  // }, [eventDidFire, eventWillFire, linksUpdated, lockChanged, model, nodesUpdated]);

  return <BodyWidget model={model} handleLinks={false} direction="TB" />
}








