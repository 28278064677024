import * as React from "react";
import { IClassificationTypeModel, IClassificationModel, IClassificationWithChildrenModel } from "proxy/apiProxy";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import FormTreeViewSelectField from "tools/fieldComponents/FormTreeViewSelectField";
import { IColumnDefinition, IColumnState } from "tools/components/ExtendedGrid";
import { toDictionary } from "tools/lib/utility";
import { IFlatClassificationTypes } from "features/Classification/getFlatClassificationTypes";
import { useField } from "formik";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
export interface IClassificationsDataProps extends IClassificationsProps {
    panelTitle: string;
};

interface IExpandCollapseButtonProps {
    expanded: boolean;
    onChanged: (expanded: boolean) => void;
}
function MoreLessButton({ expanded, onChanged }: IExpandCollapseButtonProps) {
    const handleClick = () => onChanged(!expanded);
    return <Button
        variant="contained"
        onClick={handleClick}
        startIcon={expanded ? <ExpandLessIcon /> : <MoreHorizIcon />} >
        {expanded ? "Show Less" : "Edit More"}
    </Button>
}

export interface IClassificationsProps {
    fieldName: string;
    classificationTypes: IClassificationTypeModel[];
}
function Classifications({ classificationTypes, fieldName }: IClassificationsProps) {
    const [expanded, setExpanded] = React.useState(false);
    const [{ value = {} }] = useField<Record<number, number> | undefined>(fieldName);
    return <Grid container={true} spacing={1} alignItems="center">
        {classificationTypes.filter(ct => expanded || !!value[ct.id]).map(ct => <Grid item={true} sm={12} md={6} lg={4} key={ct.id}>
            <MemoizedClassificationType classificationType={ct} fieldName={fieldName} />
        </Grid>)}
        <Grid item={true}>
            <MoreLessButton expanded={expanded} onChanged={setExpanded} />
        </Grid>
    </Grid>
}


interface IClassificationTypeProps {
    fieldName: string;
    classificationType: IClassificationTypeModel;
}
function ClassificationType({ fieldName, classificationType: { classifications, id: classificationTypeId, name: classificationTypeName } }: IClassificationTypeProps) {
    const handleGetClassificationPrimaryContent = ({ name }: IClassificationWithChildrenModel) => name["en"];
    const getChildren = (row?: IClassificationWithChildrenModel) => !!row ? row.classifications : classifications;
    const getKey = ({ id }: IClassificationModel) => id;
    return <>
        <FormTreeViewSelectField
            name={`${fieldName}.${classificationTypeId}`}
            label={classificationTypeName["en"]}
            getChildren={getChildren}
            getPrimaryContent={handleGetClassificationPrimaryContent}
            getKey={getKey}
            options={classifications} />
    </>
}
export function getClassificationTypesColumnDefinitions<T>(classificationTypes: IClassificationTypeModel[], classificationTypesFlat: IFlatClassificationTypes, getClassification: (elt: T) => Record<string | number, number> | undefined) {
    return {
        classificationTypesColumns: classificationTypes.map(classificationType => ({
            name: classificationType.code,
            title: classificationType.name["en"],
            getCellValue: (elt: T) => {
                const classifications = getClassification(elt);
                if (!classifications) {
                    return;
                }
                const classificationId = classifications[classificationType.id];
                if (!classificationId) {
                    return;
                }
                const classif = classificationTypesFlat[classificationType.id][classificationId];
                if (!classif) {
                    return;
                }
                return classif[classif.length - 1].name["en"] ?? classif[classif.length - 1].code ?? "";
            },
            filteringEnabled: true
        } as IColumnDefinition)),
        classificationTypesState: toDictionary(classificationTypes.map((ct) => ({ name: ct.code, width: 280, hidden: true } as IColumnState & { name: string })), i => i.name)
    };
}

const MemoizedClassificationType = React.memo(ClassificationType);
export default function ClassificationsData(props: IClassificationsDataProps) {
    if (!props.classificationTypes.length) {
        return null;
    }
    return <Card >
        <CardContent>
            <Typography gutterBottom={true} variant="h5" component="h2">
                Classifications
            </Typography>
            <Classifications {...props} />
        </CardContent>
    </Card>
}
