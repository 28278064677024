import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, IconButton, ListItemIcon, ListSubheader } from "@material-ui/core";
import autobind from "autobind-decorator";
import * as React from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';

export interface IProps {
    disabled?: boolean;
    icon?: React.ReactElement;
    items: IMenuItemDropdown[] | IMenuItemCategoryDropdown[];
    size?: 'small' | 'medium';
}
export interface IMenuItemCategoryDropdown {
    content: React.ReactNode;
    items: IMenuItemDropdown[];
}
export interface IMenuItemDropdown {
    content: React.ReactNode;
    icon?: React.ReactElement;
    onClick: () => void;
}

function itemsIsCategories(items: IMenuItemDropdown[] | IMenuItemCategoryDropdown[]): items is IMenuItemCategoryDropdown[] {
    return !!(items.length && (items as IMenuItemCategoryDropdown[])[0].items);
}

@autobind
class MenuDropdown extends React.PureComponent<IProps, { anchorEl: HTMLButtonElement | undefined }>{
    private moreVertIcon = <MoreVertIcon />;
    constructor(props: IProps) {
        super(props);
        this.state = { anchorEl: undefined };
    }
    public render() {
        const { icon = this.moreVertIcon, items, disabled, size } = this.props;
        const menuOpened = !!this.state.anchorEl;
        // const isCategories=itemsIsCategories(items);
        return <>
            <IconButton size={size} onClick={this.handleOpenMenu} color="inherit" disabled={!!disabled}>
                {icon}
            </IconButton>
            <Popper open={menuOpened} anchorEl={this.state.anchorEl} transition={true} disablePortal={true} style={{ zIndex: 2000 }}>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: (placement === 'bottom' || placement === 'bottom-end' || placement === 'bottom-start') ? 'right top' : 'right bottom' }} >
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleCloseMenu}>
                                <MenuList>
                                    {this.renderList(items)}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    }

    private renderList(items: IMenuItemDropdown[] | IMenuItemCategoryDropdown[]) {
        if (itemsIsCategories(items)) {
            return this.renderListCategories(items);
        }
        else {
            return this.renderListItems(0, items);
        }
    }
    private renderListItems(subKey: number, items: IMenuItemDropdown[]) {
        return items.map(({ content, onClick, icon: menuIcon }, idx) => <MenuItem key={`${subKey}-${idx}`} onClick={this.handleClick.bind(null, onClick)}>
            {menuIcon && <ListItemIcon>
                {menuIcon}
            </ListItemIcon>}
            {content}
        </MenuItem>);
    }
    private renderListCategories(categories: IMenuItemCategoryDropdown[]) {
        return categories.map(({ content, items }, idx) => <>
            <ListSubheader key={idx}>{content}</ListSubheader>
            {this.renderListItems(idx, items)}
        </>);
    }
    private handleClick(onClick: () => void) {
        this.setState({ anchorEl: undefined });
        onClick();
    }
    private handleOpenMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ anchorEl: event.currentTarget });
    }

    private handleCloseMenu() {
        this.setState({ anchorEl: undefined });
    }
}

export default MenuDropdown;