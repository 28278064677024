import SearchPanel from "tools/components/SearchPanel";
import { ICurrencyModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { Typography } from "@material-ui/core";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";

export const fxRatesScreen = {
    route: "/marketdata/fxrates",
    component: FxRates,
    label: "Fx Rates",
    keywords: "Fx Forex Rates",
}

function FxRates() {
    const { referenceCurrencies } = useReduxSelections("reference");
    const navigate = useScreenNavigate();

    useEffect(() => {

    }, []);

    if (!referenceCurrencies) {
        return <Typography> loading...</Typography>
    }
    const currencies = Object.values(referenceCurrencies);
    const columns: IColumnDefinition[] = [
        { name: "Code", title: "Code", getCellValue: (row: ICurrencyModel) => row.isoCode, filteringEnabled: true },
        { name: "Name", title: "Name", getCellValue: (row: ICurrencyModel) => row.name?.en, filteringEnabled: true },
        // { name: "Common", title: "Is Common", getCellValue: (row: ICurrencyModel) => row.isCommon, columnType: "boolean" },
    ];
    const state: IGridState = {
        "Code": { width: 150 },
        "Name": { width: 280 },
    };
    const handleRowSelect = (row: ICurrencyModel) => navigate("FxRate", { id: row.id })

    const getRowKey = (row: ICurrencyModel) => row.id || 0;
    return <SearchPanel
        subTitle="Target Currencies"
        title="Fx Rates">
        <ExtendedGrid
            rows={currencies}
            columns={columns}
            getRowId={getRowKey}
            initialState={state}
            onRowClick={handleRowSelect}
            defaultExportFileName="FxRates.xlsx"
            userCanGroup={true} />
    </SearchPanel>
}
