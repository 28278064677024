import produce from "immer";
import { IEntityMetadataModel, IMacroScriptCheckResultModel, IPortfolioMacroModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IMacroScriptMetadata {
    [key: string]: IEntityMetadataModel;
    [key: number]: IEntityMetadataModel;
}
export interface IState {
    globalPortfolioComplianceMacroLoading: boolean;
    globalPortfolioComplianceMacro: IPortfolioMacroModel;
    globalPortfolioComplianceMacroSaving: boolean;
    globalPortfolioComplianceMacroCheckResult?: IMacroScriptCheckResultModel;
    globalPortfolioComplianceMacroValidateScriptMetadata: IMacroScriptMetadata;
}
export const ActionFactories = produceActionFactories({
    globalPortfolioComplianceLoad: () => undefined,
    globalPortfolioComplianceLoaded: (payload: IPortfolioMacroModel) => payload,
    globalPortfolioComplianceSave: (payload: IPortfolioMacroModel) => payload,
    globalPortfolioComplianceSaved: (payload: IPortfolioMacroModel) => payload,
    globalPortfolioComplianceValidateScript: (payload: string | undefined) => payload,
    globalPortfolioComplianceValidatedScript: (payload: IMacroScriptCheckResultModel) => payload,
    globalPortfolioComplianceMetadataLoaded: (payload: IMacroScriptMetadata) => payload
});

export function reducer(
    state: IState = {
        globalPortfolioComplianceMacroLoading: false,
        globalPortfolioComplianceMacro: { script: "" },
        globalPortfolioComplianceMacroSaving: false,
        globalPortfolioComplianceMacroValidateScriptMetadata: {}
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "globalPortfolioComplianceLoad":
                draft.globalPortfolioComplianceMacroLoading = true;
                draft.globalPortfolioComplianceMacro = { script: "" };
                break;
            case "globalPortfolioComplianceLoaded":
                draft.globalPortfolioComplianceMacroLoading = false;
                draft.globalPortfolioComplianceMacro = action.payload;
                break;
            case "globalPortfolioComplianceMetadataLoaded":
                draft.globalPortfolioComplianceMacroValidateScriptMetadata = action.payload;
                break;
            case "globalPortfolioComplianceSave":
                draft.globalPortfolioComplianceMacroSaving = true;
                break;
            case "globalPortfolioComplianceSaved":
                draft.globalPortfolioComplianceMacroSaving = false;
                draft.globalPortfolioComplianceMacro = action.payload;
                break;
            case "globalPortfolioComplianceValidatedScript":
                draft.globalPortfolioComplianceMacroCheckResult = action.payload;
                break;
        }
    });
}
