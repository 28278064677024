import * as React from "react";
import { isRelativeReference, RelativeValueProvider, useColor, useValue, useVisible } from "./ValueProvider";
import { Container, FieldControl, FieldSorting, ITableArrayControl } from "./DashboardFormattingContracts";
import DataTable, { IColumnDefinition } from "./DataTable";
import { toDictionary } from "tools/lib/utility";
import { FieldContentComponent, getContentColor, getDefaultAlignment } from "./FieldComponent";
import { useSelection } from "./SelectionProvider";
import { ContainerComponent } from "./ContainerComponent";

export interface IDataTableComponentProps {
    item: ITableArrayControl<any>;
}
export function DataTableComponent({ item }: IDataTableComponentProps) {
    const { items, sorts: initialSorts, multiSelect, hideHeader } = item;
    const getValue = useValue();
    const getVisible = useVisible();
    const getColor = useColor();
    const { rows, getRowKey, onSelectionChanged, selectedRows } = useSelection(item);
    const [sorts, onSortsChanged] = useSorts(initialSorts, items);
    const columns: IColumnDefinition<object>[] = React.useMemo(() => items
        .filter(i => getVisible(i))
        .map(item => {
            switch (item.type) {
                case "directedGraphDetailContainer":
                case "flexContainer":
                case "flexDetailContainer":
                case "gridContainer":
                case "gridDetailContainer":
                case "tabsContainer":
                case "hierarchyGraphDetailContainer":
                    const { label, ...itemWithoutLabel } = item;
                    return {
                        sortable: false,
                        getValue: row => 0,
                        align: item.alignment,
                        label: getValue("string", item.label),
                        width: item.width,
                        contentRenderer: (value, row) => <RelativeValueProvider value={row}>
                            <ContainerComponent definition={itemWithoutLabel} itemDisplayType={item.itemLayout} />
                        </RelativeValueProvider>
                    } as IColumnDefinition<object>;
                default:
                    return {
                        sortable: true,
                        getValue: row => getValue("stringNumberDateBoolean", item.value, row),
                        align: getDefaultAlignment(item.type),
                        label: getValue("string", item.label),
                        width: item.width,
                        contentRenderer: (value, row) => <FieldContentComponent item={item} row={row} />,
                        getColors: (value, row) => getContentColor(item, row, getValue, getColor)
                    } as IColumnDefinition<object>;
            }
        }), [getColor, getValue, getVisible, items]);
    return <DataTable
        selected={selectedRows}
        onSelectedChanged={onSelectionChanged}
        size="small"
        multiSelect={multiSelect}
        rows={rows}
        getRowKey={getRowKey}
        columns={columns}
        sorts={sorts}
        hideHeader={hideHeader}
        onSortsChanged={onSortsChanged} />
}
function useSorts(sorts: FieldSorting[] | FieldSorting | undefined = [], items: (FieldControl<never> | Container<never>)[]) {
    const newSorts = React.useMemo(() => {
        let sortsArray = sorts;
        if (!Array.isArray(sortsArray)) {
            sortsArray = [sortsArray];
        }
        const sortDefinitions = sortsArray.map((sort, index) => {
            const [field, direction] = getSortDefinition(sort);
            return { field, sort: (index + 1) * direction };
        })
        const fieldsSorts = toDictionary(sortDefinitions, i => i.field, i => i.sort);
        const idxWithDirection = items.map((item, idx) => {
            switch (item.type) {
                case "directedGraphDetailContainer":
                case "flexContainer":
                case "flexDetailContainer":
                case "gridContainer":
                case "gridDetailContainer":
                case "tabsContainer":
                case "hierarchyGraphDetailContainer":
                    return { position: idx + 1, direction: undefined };
            }
            if (item.value && isRelativeReference(item.value)) {
                return { position: idx + 1, direction: fieldsSorts[item.value] };
            }
            return { position: idx + 1, direction: undefined };
        });
        return toDictionary(idxWithDirection.filter(i => !!i.direction), i => i.position, i => i.direction as number);
    }, [sorts, items]);
    return React.useState<Record<number, number>>(newSorts);
}
function getSortDefinition(sort: FieldSorting) {
    const [field, isDesc] = sort.split(" ");
    return [field, isDesc === "desc" ? -1 : 1] as [string, number];
}
