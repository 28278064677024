import { Epic } from "redux-observable";
import { map, mergeMap, tap } from "rxjs/operators";
import { reportTemplatesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { saveAs } from "file-saver";
import { from, merge, of } from "rxjs";

export const loadMetadata: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("externalTemplate", "externalTemplateMetadataLoad"),
        mergeMap(() => merge(
            from(reportTemplatesApi.generateFullMetadataAsync()).pipe(map(ActionFactories.externalTemplate.externalTemplateMetadataLoaded)),
            from(reportTemplatesApi.getAllAsync({})).pipe(map(ActionFactories.externalTemplate.externalTemplateLoadedAll)))));

export const loadTemplateBuildSet: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("externalTemplate", "externalTemplateBuildSetLoad"),
        mergeMap(reportBuildSetRequest => reportTemplatesApi.generateReportBuildSetAsync({ reportBuildSetRequest })),
        tap(i => saveAs(i.blob, i.fileName)),
        map(ActionFactories.externalTemplate.externalTemplateBuildSetLoaded));

export const loadTemplateAnalysis: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("externalTemplate", "externalTemplateAnalyze"),
        mergeMap(reportTemplateId => {
            if (reportTemplateId) {
                return from(reportTemplatesApi.analyzeTemplateGETAsync({ id: reportTemplateId }).then(r => ({ ...r, reportTemplateId })));
            }
            else {
                return of(undefined)
            }
        }),
        map(ActionFactories.externalTemplate.externalTemplateAnalyzed));