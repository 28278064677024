import { IClassificationTypeModel, INoteModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import ClassificationsData from "components/global/ClassificationsData";
import { Grid } from "@material-ui/core";

export interface INoteDataProps {
    classificationTypes: IClassificationTypeModel[];
    noteField: string;
}

export default function NoteData({
    classificationTypes,
    noteField
}: INoteDataProps) {
    return <Grid container={true} spacing={1}>
        <Grid item={true} xs={5}>
            <FormTextField name={oProps<INoteModel>(noteField).path("code")} label="code" required={true} maxLength={50} />
        </Grid>
        <Grid item={true} xs={7}>
            <FormTextField name={oProps<INoteModel>(noteField).path("label")} label="label" required={true} maxLength={250} />
        </Grid>
        <Grid item={true} xs={12}>
            <ClassificationsData
                panelTitle="Classifications"
                fieldName={oProps<INoteModel>(noteField).path("classifications")}
                classificationTypes={classificationTypes} />
        </Grid>
        {/* <Grid item={true} xs={12}>
            <FormMultiCulturedTextField name={oProps<INoteModel>().path("content")} label="Text" multiline={true} />
        </Grid> */}
    </Grid>
}
