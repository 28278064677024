import autobind from "autobind-decorator";
import * as React from 'react';
import { Plugin, Template, TemplatePlaceholder, TemplateConnector } from '@devexpress/dx-react-core';
import IconButton from '@material-ui/core/IconButton';
import FileExcelOutlineIcon from "mdi-material-ui/FileExcelOutline";
import { Tooltip } from '@material-ui/core';
import * as XLSX from 'xlsx';
import {IToolbarExportData} from "./ToolbarExportState";
import {IButtonLinkProps, isButtonLink} from "./ExtendedGrid";

const pluginDependencies = [
    { name: 'Toolbar' },
    { name: 'ToolbarExportState' },
];

export interface IToolbarExportProps {
    defaultFileName: string;
    sheetName?: string;
}
@autobind
export class ToolbarExport extends React.PureComponent<IToolbarExportProps> {
    public render() {
        return (
            <Plugin name="ToolbarExport" dependencies={pluginDependencies}>
                <Template name="toolbarContent" >
                    <TemplatePlaceholder />
                    <TemplateConnector>
                        {({ toolbarExportStateData }) => <Tooltip title="Export Data to Excel">
                            <IconButton onClick={this.handleClickExport.bind(null, toolbarExportStateData)}>
                                <FileExcelOutlineIcon />
                            </IconButton>
                        </Tooltip>}
                    </TemplateConnector>
                </Template>
            </Plugin>
        );
    }
    private handleClickExport(toolbarExportData: IToolbarExportData) {
        const { defaultFileName, sheetName = "Sheet1" } = this.props;
        const [outputData, header] = this.buildOutputArray(toolbarExportData);
        const workSheet = XLSX.utils.json_to_sheet(outputData, { header });
        const workBook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
        XLSX.writeFile(workBook, defaultFileName);
    }
    private buildOutputArray({ columns, getCellValue, rows }: IToolbarExportData): [any[], string[]] {
        return [
            rows.map(row => columns.reduce((newRow, { title, name }) =>
            {
                const cellValue = getCellValue(row, name) as React.Component<IButtonLinkProps> | string;
                newRow[title || name] = isButtonLink(cellValue) ? cellValue.props.textValue : cellValue;
                return newRow;
            }, {} as any)),
            columns.map(({ title, name }) => title || name)
        ];
    }
}
