import { Chip, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import autobind from "autobind-decorator";
import LanguageIcon from '@material-ui/icons/Language';
import * as React from "react";
import { LanguageCode, cultures } from "tools/lib/utility";

export interface IProps {
    onChange?: (language: LanguageCode) => void;
    language: string;
}

@autobind
class LanguageDropdown extends React.PureComponent<IProps, { anchorEl: HTMLInputElement | undefined }>{
    constructor(props: IProps) {
        super(props);
        this.state = { anchorEl: undefined };
    }
    public render() {
        const menuOpened = !!this.state.anchorEl;
        return <>
            <Chip
                icon={<LanguageIcon />}
                aria-owns={menuOpened ? 'menu-culture' : undefined}
                aria-haspopup="true"
                label={this.props.language}
                onClick={this.handleOpenMenu}
                variant="outlined"
            />
            <Popper open={menuOpened} anchorEl={this.state.anchorEl} transition={true} disablePortal={true} style={{ zIndex: 999999 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: (placement === 'bottom' || placement === 'bottom-end' || placement === 'bottom-start') ? 'right top' : 'right bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleCloseMenu}>
                                <MenuList>
                                    {cultures.map(i => (<MenuItem key={i.code} onClick={this.handleHandleChange(i.code)}>{i.label}</MenuItem>))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    }

    private handleHandleChange(language: LanguageCode) {
        return () => {
            this.setState({ anchorEl: undefined });
            if (this.props.onChange) {
                this.props.onChange(language);
            }
        }
    }

    private handleOpenMenu(event: React.MouseEvent<HTMLInputElement>) {
        this.setState({ anchorEl: event.currentTarget });
    }

    private handleCloseMenu() {
        this.setState({ anchorEl: undefined });
    }
}

export default LanguageDropdown;