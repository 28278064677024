import * as React from "react";
import { useEffect } from "react";
import { IFeatureModel, IMacroScriptTypeModel, MacroScriptModel, MacroScriptSummaryModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import SearchPanel from "tools/components/SearchPanel";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IActionButton } from "tools/components/FabContainer";
import AddIcon from '@material-ui/icons/Add';
import { getMacroScriptTypeLabel } from "./getMacroScriptTypeLabel";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";

export const macroScriptsScreen = {
    route: "/config/macros/scripts",
    label: "Macros",
    keywords: "code c# cs csharp dotnet import upload etl",
    component: MacroScripts,
}

function getRelatedFeature(type: IMacroScriptTypeModel): IFeatureModel {
    switch (type) {
        case IMacroScriptTypeModel.DataProcessor: return IFeatureModel.DataProcessorMacroWrite;
        case IMacroScriptTypeModel.FileProcessor: return IFeatureModel.ImportExportProcessMacroWrite;
        case IMacroScriptTypeModel.FileRetriever: return IFeatureModel.ImportExportProcessMacroWrite;
        case IMacroScriptTypeModel.MarketDataSelector: return IFeatureModel.MarketDataSelectorMacroWrite;
        case IMacroScriptTypeModel.Monitoring: return IFeatureModel.MonitoringMacroWrite;
        case IMacroScriptTypeModel.SubMacro: return IFeatureModel.SubMacroWrite;
    }
}

function MacroScripts() {

    const { macroScriptList, macroScriptListLoading } = useReduxSelections("macroScript");
    const { macroScriptLoadAll } = useReduxActions("macroScript");
    const { referenceMacroScriptCategories = {} } = useReduxSelections("reference");

    const navigate = useScreenNavigate();

    useEffect(() => {
        macroScriptLoadAll()
    }, [macroScriptLoadAll]);

    const handleRowSelect = ({ id }: (MacroScriptSummaryModel | MacroScriptModel)) => navigate("Macro", { id });
    const isGranted = useGrants();
    const columns: IColumnDefinition[] = [
        {
            name: "Name",
            title: "Name",
            getCellValue: (row: (MacroScriptSummaryModel | MacroScriptModel)) => row.name,
            positionToKeep: "left",
            filteringEnabled: true
        },
        {
            name: "Code",
            title: "Code",
            getCellValue: (row: (MacroScriptSummaryModel | MacroScriptModel)) => row.code,
            positionToKeep: "left",
            filteringEnabled: true
        },
        {
            name: "Category",
            title: "Category",
            getCellValue: (row: (MacroScriptSummaryModel | MacroScriptModel)) => referenceMacroScriptCategories[row.categoryId ?? 0]?.name
        },
        {
            name: "Type",
            title: "Type",
            getCellValue: (row: (MacroScriptSummaryModel | MacroScriptModel)) => getMacroScriptTypeLabel(row.type),
            positionToKeep: "left"
        },
        {
            name: "Scope",
            title: "Scope",
            getCellValue: (row: (MacroScriptSummaryModel | MacroScriptModel)) => {
                if (row.type === "DataProcessorMacroScriptModel" || row.type === "DataProcessorMacroScriptSummaryModel"
                    || row.type === "MonitoringMacroScriptModel" || row.type === "MonitoringMacroScriptSummaryModel") {
                    return row.singleScope;
                }
                return undefined;
            },
            positionToKeep: "left"
        },
        {
            name: "Version",
            title: "Version",
            getCellValue: (row: (MacroScriptSummaryModel | MacroScriptModel)) => row.publishedVersion,
        },
        {
            name: "PublishOnPortal",
            title: "Publish on portal",
            getCellValue: (row: (MacroScriptSummaryModel | MacroScriptModel)) => (row.type === "MonitoringMacroScriptSummaryModel" || row.type === "MonitoringMacroScriptModel") ? row.publishOnPortal : false,
            columnType: "boolean",
            positionToKeep: "left"
        },
        {
            name: "LastUpdate",
            title: "Last update",
            getCellValue: (row: (MacroScriptSummaryModel | MacroScriptModel)) => row.updatedOn,
            columnType: "dateTime"
        }
    ];

    const state: IGridState = {
        "Name": { width: 320 },
        "Code": { width: 280 },
        "Category": { width: 200 },
        "Type": { width: 120, groupingPosition: 1 },
        "Scope": { width: 120 },
        "Version": { width: 120 },
        "LastUpdate": { width: 140 }
    };

    const getRowKey = (row: (MacroScriptSummaryModel | MacroScriptModel)) => row.id || 0;
    const extraActionButtons: IActionButton[] = React.useMemo(() => {
        const actions = Object.values(IMacroScriptTypeModel)
            .filter(i => isGranted(getRelatedFeature(i)) && i !== IMacroScriptTypeModel.SubMacro)
            .map(key => ({
                label: getMacroScriptTypeLabel(key),
                onClick: () => navigate("Macro", { id: key }),
            }));
        if (!actions.length) {
            return [];
        }
        return [{
            label: "Add macro",
            icon: AddIcon,
            actions: actions
        }];
    }, [isGranted, navigate]);

    return <>

        <SearchPanel
            title="Macros"
            isQuerying={macroScriptListLoading}
            actions={extraActionButtons}
        >
            <ExtendedGrid
                rows={macroScriptList}
                columns={columns}
                getRowId={getRowKey}
                onRowClick={handleRowSelect}
                initialState={state}
                userCanGroup={true}
                defaultExportFileName="Macros.xlsx" />
        </SearchPanel>
    </>
}

