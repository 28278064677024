import { Chip, ChipTypeMap } from "@material-ui/core";
import { ITasksStatusModel } from "proxy/apiProxy";
import { splitPascalCase } from "tools/lib/utility";

export function getBatchInstanceStatusColor(status: ITasksStatusModel) {
    switch (status) {
        case ITasksStatusModel.Awaiting: return;
        case ITasksStatusModel.Processing: return "#8CBDFE";
        case ITasksStatusModel.Error: return "#cc0000";
        case ITasksStatusModel.Success: return "#7CC488";
        case ITasksStatusModel.InitializationError: return "#cc0000";
        case ITasksStatusModel.Cancelled: return;
    }
}
export function BatchInstanceStatusBadge({ status, size }: { status?: ITasksStatusModel; } & Pick<ChipTypeMap["props"], "size">) {
    if (typeof status === "undefined") {
        return null;
    }
    const backgroundColor = getBatchInstanceStatusColor(status);
    return <Chip size={size} variant={(!backgroundColor) ? "outlined" : undefined} style={{ backgroundColor }} label={splitPascalCase(status)} />;
}
