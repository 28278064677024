import produce from "immer";
import { IMacroScriptCategoryModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IState {
    macroScriptCategoryAllLoading: boolean;
    macroScriptCategoryLoading: boolean;
    macroScriptCategories: IMacroScriptCategoryModel[];
    macroScriptCategoryCurrent?: IMacroScriptCategoryModel;
    macroScriptCategorySaving: boolean;
    macroScriptCategoryDeleting: boolean;
}
export const ActionFactories = produceActionFactories({
    macroScriptCategoryLoad: (payload: number) => payload,
    macroScriptCategoryLoaded: (payload: IMacroScriptCategoryModel) => payload,
    macroScriptCategorySave: (payload: IMacroScriptCategoryModel) => payload,
    macroScriptCategorySaved: (payload: IMacroScriptCategoryModel) => payload,
    macroScriptCategoryDelete: (payload: number) => payload,
    macroScriptCategoryDeleted: (payload: number) => payload,
    macroScriptCategoryLoadAll: () => undefined,
    macroScriptCategoryLoadedAll: (payload: IMacroScriptCategoryModel[]) => payload,
});

export function reducer(
    state: IState = {
        macroScriptCategoryLoading: false,
        macroScriptCategoryAllLoading: false,
        macroScriptCategorySaving: false,
        macroScriptCategoryDeleting: false,
        macroScriptCategories: []
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "macroScriptCategoryLoadAll":
                draft.macroScriptCategoryAllLoading = true;
                break;
            case "macroScriptCategoryLoadedAll":
                draft.macroScriptCategoryAllLoading = false;
                draft.macroScriptCategories = action.payload;
                break;
            case "macroScriptCategoryLoad":
                draft.macroScriptCategoryLoading = true;
                draft.macroScriptCategoryCurrent = undefined;
                break;
            case "macroScriptCategoryLoaded":
                draft.macroScriptCategoryLoading = false;
                draft.macroScriptCategoryCurrent = action.payload;
                break;
            case "macroScriptCategorySave":
                draft.macroScriptCategorySaving = true;
                break;
            case "macroScriptCategorySaved":
                draft.macroScriptCategorySaving = false;
                const saved = action.payload;
                if (draft.macroScriptCategoryCurrent) {
                    draft.macroScriptCategoryCurrent = saved;
                }
                const existing = draft.macroScriptCategories.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.macroScriptCategories.push(saved);
                }
                break;
            case "macroScriptCategoryDelete":
                draft.macroScriptCategoryDeleting = true;
                break;
            case "macroScriptCategoryDeleted":
                draft.macroScriptCategoryDeleting = false;
                const deletedId = action.payload;
                if (draft.macroScriptCategoryCurrent && draft.macroScriptCategoryCurrent.id === deletedId) {
                    delete draft.macroScriptCategoryCurrent;
                }
                const idx = draft.macroScriptCategories.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.macroScriptCategories.splice(idx, 1);
                }
                break;
        }
    });
}
