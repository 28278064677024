import { createContext, useContext, useMemo } from "react";
import ReactJson from "react-json-view";
import { ScreenLinkType } from "./DashboardFormattingContracts";

export interface IReportLinkProps extends IExecutionProps {
    textValue: string;
    templateName: string;
    datasetName: string;
    color?: string;
    backgroundColor?: string;
    fileType?: "pdf" | "docx" | "xlsx" | "xls",
    disabled?: boolean;
}
export interface IExecutionProps {
    target?: {
        taskCode?: string;
        entityCode?: string;
        entityId?: number;
        portfolioCode?: string;
        portfolioId?: number;
        securityCode?: string;
        securityId?: number;
        processExecutionId?: number;
        relationshipId?: number;
    };
    parameters?: Record<string, string>;
    culture?: string;
    asOfDate?: Date | number
    disabled?: boolean;
}
export interface IDashboardLinkProps extends IExecutionProps {
    textValue: string;
    layoutName: string;
    datasetName: string;
    color?: string;
    backgroundColor?: string;
    disabled?: boolean;
    screenTitle?: string;
}
export interface IScreenLinkProps {
    textValue: string;
    target: ScreenLinkType;
    params?: Record<string, string>;
    tab?: string;
    color?: string;
    backgroundColor?: string;
    disabled?: boolean;
}

export interface IExecuteEtlProps extends IExecutionProps {
    textValue: string;
    etlName: string;
    inputConnector?: string;
    color?: string;
    backgroundColor?: string;
    noDownload?: boolean;
    disabled?: boolean;
}
export interface IQuestionnaireProps extends IExecutionProps {
    textValue: string;
    questionnaireName: string;
    getPreviousAnswers?: boolean;
    color?: string;
    backgroundColor?: string;
    disabled?: boolean;
}
export interface IProcessTaskQuestionnaireProps {
    color?: string;
    backgroundColor?: string;
    textValue: string;
    taskCode: string;
    processExecutionId: number;
    disabled?: boolean;
}
export interface IProcessTaskFileProps {
    textValue: string;
    taskCode: string;
    processExecutionId: number;
    currentFileName?: string;
    disabled?: boolean;
}

export interface IComponentsContext {
    reportLinkComponent?: React.ComponentType<IReportLinkProps>;
    dashboardLinkComponent?: React.ComponentType<IDashboardLinkProps>;
    screenLinkComponent?: React.ComponentType<IScreenLinkProps>;
    executeEtlComponent?: React.ComponentType<IExecuteEtlProps>;
    questionnaireComponent?: React.ComponentType<IQuestionnaireProps>;
    processTaskQuestionnaireComponent?: React.ComponentType<IProcessTaskQuestionnaireProps>;
    processTaskFileComponent?: React.ComponentType<IProcessTaskFileProps>;
}
const ComponentsContext = createContext<IComponentsContext>({});
export interface IComponentProviderProps extends IComponentsContext {
    children: React.ReactNode;
}
export function ComponentProvider({ children, ...componentsContext }: IComponentProviderProps) {
    return <ComponentsContext.Provider value={componentsContext}>
        {children}
    </ComponentsContext.Provider>
}
const EmptyControl = (p: object) => <ReactJson src={p} />;
export function useDashboardComponents() {
    const context = useContext(ComponentsContext);
    return useMemo(() => ({
        ReportLink: context.reportLinkComponent ?? EmptyControl,
        DashboardLink: context.dashboardLinkComponent ?? EmptyControl,
        ScreenLink: context.screenLinkComponent ?? EmptyControl,
        ExecuteEtl: context.executeEtlComponent ?? EmptyControl,
        Questionnaire: context.questionnaireComponent ?? EmptyControl,
        ProcessTaskQuestionnaire: context.processTaskQuestionnaireComponent ?? EmptyControl,
        ProcessTaskFile: context.processTaskFileComponent ?? EmptyControl,
    }), [
        context.reportLinkComponent,
        context.dashboardLinkComponent,
        context.screenLinkComponent,
        context.executeEtlComponent,
        context.questionnaireComponent,
        context.processTaskQuestionnaireComponent,
        context.processTaskFileComponent
    ]);
}