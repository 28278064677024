import * as React from "react";
import { useEffect } from "react";
import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { IFeatureModel, ISubFundModel, IUniverseScopeTypeModel } from "proxy/apiProxy";
import SubFundData from "./SubFundData";
import HistoricalValues, { IDataSeries } from "components/global/HistoricalValues";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import CustomViewDatas from "features/Relationship/CustomViewDatas";
import useScopeButtonsMenu from "components/global/useScopeButtonsMenu";
import MonitoringResultGroups from "components/global/MonitoringResults";
import BriefcaseAccountIcon from "mdi-material-ui/BriefcaseAccount";
import { Chip } from "@material-ui/core";
import PortfolioComposition from "features/ManagedPortfolio/PortfolioComposition";
import { ProcessExecutionList } from "features/ProcessExecution/ProcessExecutionList";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";
import { useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";
import { isNumber, useNumber } from "tools/lib/utility";
import { DocumentsGrid } from "features/Document/DocumentsScreen/DocumentsGrid";

export const subFundScreen = {
    route: "/securitymanagement/subfunds/:id/:tab?" as const,
    tabs: {
        detail: "details",
        historicalValues: "historicalvalues",
        customViews: "customScreens",
        dashboard: "dashboard",
        composition: "composition",
        processes: "processes",
        documents: "documents"
    },
    component: SubFund,
    label: "Sub-fund"
}

function SubFund() {
    const {
        current: subfundCurrent,
        historicalValues: subfundHistoricalValues = [],
        loading: subfundLoading,
        historicalValuesLoading: subfundHistoricalValuesLoading,
        subfundHistoricalValuesVisualizationType: historicalValuesVisualizationType,
        dictionaries,
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        compositionVisualizationType,
        composition,
        pricingDateLoading,
        pricingDatesLoading,
        pricingDates,
        currentPricingDate,
        processExecutionsLoading,
        processExecutions,
        documentsLoading,
        documents
    } = useReduxSelections("subFund");
    const { macroScriptList } = useReduxSelections("macroScript");
    const { parameters: { subFundExtensionFields = [] } } = useReduxSelections("parameters");
    const monitoringMacros = React.useMemo(() => macroScriptList.filter(i => (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel") && i.singleScope === IUniverseScopeTypeModel.SubFund), [macroScriptList]);
    const {
        subfundAddSecurityInDictionary,
        subfundSave,
        subfundAddEntityInDictionary,
        subfundDelete,
        subfundHistoricalValuesVisualizationTypeChange,
        subfundCustomScreenDataSubmit,
        subfundMonitoringResultLoad,
        subfundCompositionVisualizationTypeChange,
        subfundPricingDateLoad,
        subfundLoad,
        subfundProcessExecutionsLoad,
        subfundHistoricalValuesLoad,
        subfundPricingDatesLoad,
        subfundDocumentsLoad
    } = useReduxActions("subFund");

    const {
        referenceCurrencies = {},
        referenceCountries = {},
    } = useReduxSelections("reference");
    const { macroScriptLoadAll } = useReduxActions("macroScript");
    const {
        classificationTypes: securityClassificationTypes,
        classificationTypesFlat: securityClassificationTypesFlat
    } = useReduxSelections("securityClassificationType");

    const navigate = useScreenNavigate()

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof subFundScreen>()
    const idNum = useNumber(id)

    useEffect(() => {
        if (isNumber(idNum)) {
            subfundLoad(idNum)
            subfundPricingDatesLoad(idNum)
        }
        macroScriptLoadAll()
    }, [idNum, macroScriptLoadAll, subfundLoad, subfundPricingDatesLoad]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "processes" && !processExecutions)
            subfundProcessExecutionsLoad(idNum)
    }, [idNum, processExecutions, subfundProcessExecutionsLoad, tabValue]);

    useEffect(() => {
        if (idNum && tabValue === "documents" && !documentsLoading && !documents)
            subfundDocumentsLoad(idNum)
    }, [idNum, subfundDocumentsLoad, documents, documentsLoading, tabValue]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "historicalValues" && subfundHistoricalValues.length === 0)
            subfundHistoricalValuesLoad(idNum)
    }, [idNum, subfundHistoricalValues?.length, subfundHistoricalValuesLoad, tabValue]);

    const isGranted = useGrants();
    const handleNewExecutionProcess = React.useCallback(() => {
        navigate("ProcessExecutionNew", {
            type: "PortfolioProcessExecutionModel",
            targetId: subfundCurrent?.id ?? 0
        });
    }, [subfundCurrent?.id, navigate])

    const extraReportActionButtons = useScopeButtonsMenu(IUniverseScopeTypeModel.SubFund, subfundCurrent?.id, subfundCurrent?.isUnderManagement);
    const extraActionButtons = React.useMemo(() => {
        const ret = [...extraReportActionButtons];

        ret.push({
            label: "Add Process Execution",
            icon: NotebookCheckOutlineIcon,
            onClick: handleNewExecutionProcess,
            feature: IFeatureModel.UniverseDueDiligenceWrite,
        });
        return ret;
    }, [extraReportActionButtons, handleNewExecutionProcess]);
    if (!subfundCurrent) {
        return <DetailPanelLoading tabNumber={6} hasSubTitle={true} />;
    }
    const title = !!subfundCurrent.id ? (subfundCurrent.name || "") : "New subfund";
    const tabs: ISubMenuTabs<typeof subFundScreen>[] = [{
        label: "Data",
        value: "detail"
    }];
    if (!!monitoringMacros.length) {
        tabs.push({
            label: "Dashboard",
            value: "dashboard"
        });
    }

    if (subfundCurrent.id && !subfundCurrent.isUnderManagement && composition) {
        tabs.push({
            label: "Positions",
            value: "composition"
        });
    }
    if (subfundCurrent.id) {
        tabs.push({
            label: "Historical series",
            value: "historicalValues"
        });
    }
    if (subfundCurrent.id) {
        tabs.push({
            label: "Processes",
            value: "processes"
        });
        tabs.push({
            label: "Documents",
            value: "documents"
        });
    }

    const handleSecuritySelected = (securityId: number) => {
        const { type } = dictionaries.securities[securityId];
        switch (type) {
            case "LoanSummaryModel":
                navigate("Loan", { id: securityId });
                break;
            case "CashSummaryModel":
                navigate("BankAccount", { id: securityId });
                break;
            default:
                navigate("Security", { id: securityId });
                break;
        }
    }
    const handleLoadResult = (id: number) => subfundMonitoringResultLoad({ id, targetId: subfundCurrent.id });

    const handleDelete = () => {
        if (subfundCurrent?.id) {
            subfundDelete(subfundCurrent.id);
        }
    }
    const handleSubmit = (values: ISubFundModel, { setSubmitting }: FormikHelpers<ISubFundModel>) => {
        subfundSave(values);
        setSubmitting(false);
    }

    if (subfundCurrent?.id && !!customScreens && customScreens.length) {
        tabs.push({
            label: "Custom Data",
            value: "customViews"
        });
    }
    const historicalValueProperties = {
        TNA: "Total net asset",
        SUB: "Subscription",
        RED: "Redemption",
        TER: "Total expense ratio",
        OGC: "Ongoing charges"
    } as IDataSeries;
    const graphHistoricalValueProperties = {
        TNA: "Total net asset",
    } as IDataSeries;

    const handleDataSubmit = (customScreenId: number, values: any) => subfundCustomScreenDataSubmit({ id: subfundCurrent.id, customScreenId, values });
    const canEditCustomData = isGranted(IFeatureModel.UniverseCustomDataWrite);

    return <Formik onSubmit={handleSubmit} initialValues={subfundCurrent} enableReinitialize={true} validateOnMount={true}>{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<ISubFundModel>) {
        if (!values) {
            return null;
        }
        return <WriteAccess value={values.isUnderManagement ? false : IFeatureModel.UniverseWrite}>
            <DetailPanel
                endAdornment={values.isUnderManagement ? <Chip icon={<BriefcaseAccountIcon />} size="small" label="Under management" /> : undefined}
                isQuerying={subfundLoading || subfundHistoricalValuesLoading || customScreenDataLoading
                    || customScreenDataSubmitting || pricingDateLoading || pricingDatesLoading || processExecutionsLoading}
                title={title}
                subTitle="SubFund"
                noPadding={["dashboardAnalytics", "composition", "complianceMacro"].includes(tabValue ?? "")}
                canSave={dirty && isValid}
                onSaveClick={values.isUnderManagement ? undefined : submitForm}
                canDelete={!!values.id}
                onDeleteClick={values.isUnderManagement ? undefined : handleDelete}
                saveAllowed={IFeatureModel.UniverseWrite}
                deleteAllowed={IFeatureModel.UniverseDelete}
                badge={!values.id ? "new" : undefined}
                tabs={tabs}
                tabValue={tabValue}
                actions={extraActionButtons}>
                {(tabValue === "detail") && <SubFundData
                    onEntityLoaded={subfundAddEntityInDictionary}
                    dictionaries={dictionaries}
                    subFundExtensionFields={subFundExtensionFields}
                    referenceCurrencies={referenceCurrencies}
                    referenceCountries={referenceCountries}
                    onShareClassLoaded={subfundAddSecurityInDictionary}
                    onShareClassClick={handleSecuritySelected}
                    classificationTypes={securityClassificationTypes}
                    currentValues={values} />}
                {(tabValue === "composition" && composition) && <PortfolioComposition
                    dictionaries={dictionaries}
                    composition={composition}
                    classificationTypes={securityClassificationTypes}
                    classificationTypesFlat={securityClassificationTypesFlat}
                    referenceCurrencies={referenceCurrencies}
                    pricingDate={currentPricingDate}
                    pricingDates={pricingDates}
                    referenceCountries={referenceCountries}
                    onTargetSecuritySelected={handleSecuritySelected}
                    pricingDatesLoading={pricingDatesLoading}
                    onPricingDateChanged={subfundPricingDateLoad}
                    onVisualizationTypeChanged={subfundCompositionVisualizationTypeChange}
                    visualizationType={compositionVisualizationType}
                    editable={true} />}
                {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
                    monitoringMacros={monitoringMacros}
                    onLoadResult={handleLoadResult}
                    resultGroups={monitoringResults} />}
                {(tabValue === "historicalValues") && <HistoricalValues
                    historicalValues={subfundHistoricalValues}
                    properties={historicalValueProperties}
                    graphProperties={graphHistoricalValueProperties}
                    onVisualizationTypeChanged={subfundHistoricalValuesVisualizationTypeChange}
                    visualizationType={historicalValuesVisualizationType} />}
                {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) && <CustomViewDatas
                    canEdit={canEditCustomData}
                    dictionaries={dictionaries}
                    customScreenLoading={customScreenDataLoading}
                    dataSubmitting={customScreenDataSubmitting}
                    customScreens={customScreens}
                    onDataSubmit={handleDataSubmit}
                    customScreenDatas={customScreenDatas} />}
                {(tabValue === "processes") && <ProcessExecutionList
                    processExecutions={processExecutions}
                    dictionaries={dictionaries} />}
                {(tabValue === "documents") && <DocumentsGrid
                    documents={documents ?? []}
                    dictionaries={dictionaries} />}
            </DetailPanel>
        </WriteAccess>
    }
}
