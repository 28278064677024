import { Epic } from "redux-observable";
import { forkJoin, from, merge, Observable, of } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import {
    customScreensApi,
    IBondModel,
    ICfdModel,
    ICommodityModel,
    ICouponTypeModel,
    IEquityModel,
    IEtfModel,
    IFrequencyTypeModel,
    IFutureModel,
    IFxForwardModel,
    IGetSecurityModel,
    IGetSecurityProxyModel,
    IMiscellaneousGoodModel,
    IOptionModel,
    IOptionTypeModel,
    IProxyPositionModel,
    IPutCallModel,
    IRealEstateModel,
    ISecurityTypeModel,
    IShareClassModel,
    IStructuredProductModel,
    ISwapModel,
    macroScriptsApi,
    securitiesApi,
    SecurityModel
} from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload, onlyNotNull } from "lib/rxJsUtility";

export const loadSecurities: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securitySearch"),
        mergeMap(securitiesApi.searchAsync),
        map(ActionFactories.security.securityLoadedAll));

export const loadProcessExecutions: Epic<IAnyAction>
    = (action$, state$) => {
        return action$.pipe(
            mapToPayload("security", "securityProcessExecutionsLoad"),
            mergeMap(id => securitiesApi.getProcessesAsync({ id })),
            map(ActionFactories.security.securityProcessExecutionsLoaded));
    };

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityDocumentsLoad"),
        mergeMap(id => securitiesApi.getDocumentsAsync({ id })),
        map(ActionFactories.security.securityDocumentsLoaded));

export const saveHistoricalValue: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securitySaveHistoricalValueSave"),
        mergeMap(historicalValuePayload =>
            securitiesApi.saveHistoricalValueAsync({ id: historicalValuePayload.securityId, historicalValueSet: historicalValuePayload.historicalValue }).then(() => historicalValuePayload)),
        map(ActionFactories.security.securitySaveHistoricalValueSaved));

export const loadPricingDates: Epic<IAnyAction>
    = (action$, state$) => action$.pipe(
        mapToPayload("security", "securityPricingDatesLoad"),
        mergeMap(id => securitiesApi.getPricingDatesAsync({ id })),
        map(ActionFactories.security.securityPricingDatesLoaded));
export const loadSecurityHistoricalValues: Epic<IAnyAction>
    = (action$, state$) => action$.pipe(
        mapToPayload("security", "securityHistoricalValuesLoad"),
        mergeMap(id => securitiesApi.getHistoricalValuesAsync({ id })),
        map(ActionFactories.security.securityHistoricalValuesLoaded));
export const loadSecurityRatings: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityRatingsLoad"),
        mergeMap(id => securitiesApi.getBondRatingsAsync({ id })),
        map(ActionFactories.security.securityRatingsLoaded));





export const submitCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityCustomScreenDataSubmit"),
        mergeMap(securitiesApi.submitCustomScreenDataAsync),
        map(ActionFactories.security.securityCustomScreenDataSubmitted));
export const loadCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityCustomScreenDatasLoad"),
        mergeMap(id => securitiesApi.getLastCustomScreenDatasAsync({ id })),
        map(ActionFactories.security.securityCustomScreenDatasLoaded));


async function getSecurityAsync(id: number | ISecurityTypeModel) {
    if (typeof id === "number") {
        const [loadedEntity] = await Promise.all([
            securitiesApi.getAsync({ id: id as number })]);
        return {
            ...loadedEntity
        };
    }
    else {
        return { security: createEmptySecurity(id), entities: {}, relationships: {}, subFunds: {}, processDefinitions: {}, indexes: {} } as IGetSecurityModel;
    }
}



export const loadSecurity: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("security", "securityLoad"),
            share());

        const security$ = requestedId$.pipe(
            mergeMap(getSecurityAsync),
            share());

        const customScreens$ = requestedId$.pipe(
            mergeMap(() => customScreensApi.getAllAsync({})),
            map(customScreens => customScreens.filter(customScreen => customScreen.type === "SecurityInvestmentCustomScreenSummaryModel")),
            mergeMap(async customScreens => Promise.all(customScreens.map(({ id }) => customScreensApi.getAsync({ id })))),
            map(ActionFactories.security.securityCustomScreensLoaded));


        const securityProxy$ = merge(
            requestedId$.pipe(
                filter(id => typeof id === "number"),
                mergeMap(id => securitiesApi.getProxyAsync({ id: id as number }))),
            requestedId$.pipe(
                filter(id => typeof id === "string"),
                map(type => ({ proxyPositions: [], securities: {}, indexes: {} } as IGetSecurityProxyModel)))
        ).pipe(share());

        return merge(
            requestedId$.pipe(
                filter(id => typeof id === "number" && !!id),
                map(id => ActionFactories.security.securityCustomScreenDatasLoad(typeof id === "number" ? id : 0))),
            customScreens$,

            security$.pipe(map(ActionFactories.security.securityLoaded)),
            securityProxy$.pipe(map(ActionFactories.security.securityProxyLoaded)),
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad())),
            requestedId$.pipe(filter(i => typeof i === "number"), map(id => ActionFactories.security.securityRatingsLoad(id as number))),
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad())),
        );
    };
export const getMonitoringResultLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityMonitoringResultLoad"),
        mergeMap(macroScriptsApi.getMonitoringResultForTargetAsync),
        map(ActionFactories.security.securityMonitoringResultLoaded));

function createEmptySecurity(type: ISecurityTypeModel): SecurityModel {
    switch (type) {
        case ISecurityTypeModel.ShareClass: return { id: 0, type: "ShareClassModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", isUnderManagement: false } as IShareClassModel;
        case ISecurityTypeModel.Bond: return { id: 0, type: "BondModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", couponType: ICouponTypeModel.CommercialPaper, isCallable: false, isPerpetual: false, pricingFrequency: IFrequencyTypeModel.Daily, isConvertible: false } as IBondModel;
        case ISecurityTypeModel.Commodity: return { id: 0, type: "CommodityModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily } as ICommodityModel;
        case ISecurityTypeModel.Cfd: return { id: 0, type: "CfdModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily, isOtc: false } as ICfdModel;
        case ISecurityTypeModel.Future: return { id: 0, type: "FutureModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", isOtc: false, pricingFrequency: IFrequencyTypeModel.Daily } as IFutureModel;
        case ISecurityTypeModel.Option: return { id: 0, type: "OptionModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", isOtc: false, optionType: IOptionTypeModel.European, pricingFrequency: IFrequencyTypeModel.Daily, putCall: IPutCallModel.Put } as IOptionModel;
        case ISecurityTypeModel.Swap: return { id: 0, type: "SwapModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", isOtc: false, pricingFrequency: IFrequencyTypeModel.Daily } as ISwapModel;
        case ISecurityTypeModel.Equity: return { id: 0, type: "EquityModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "" } as IEquityModel;
        case ISecurityTypeModel.StructuredProduct: return { id: 0, type: "StructuredProductModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily } as IStructuredProductModel;
        case ISecurityTypeModel.FxForward: return { id: 0, type: "FxForwardModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily, buyAmount: 0, sellAmount: 0, sellCurrencyId: 0, isOtc: false } as IFxForwardModel;
        case ISecurityTypeModel.Etf: return { id: 0, type: "EtfModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily } as IEtfModel;
        case ISecurityTypeModel.MiscellaneousGood: return { id: 0, type: "MiscellaneousGoodModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily, isOtc: false } as IMiscellaneousGoodModel;
        case ISecurityTypeModel.RealEstate: return { id: 0, type: "RealEstateModel", securityExtensionFieldsValues: {}, classifications: {}, dataProviderCodes: {}, proxyPositions: [], name: "", shortName: "", internalCode: "", pricingFrequency: IFrequencyTypeModel.Daily } as IRealEstateModel;
        case ISecurityTypeModel.Instrument: throw new Error(`extensionFieldsValues:${type}, can't create an instrument; create an underlying type instead`);
        case ISecurityTypeModel.All: throw new Error(`extensionFieldsValues:${type}, can't create an instrument; create an underlying type instead`);
        default: throw new Error(`extensionFieldsValues:${type}, invalid type`);
    }
}

function saveSecurity(
    savedSecurity: SecurityModel,
    proxy: IProxyPositionModel[] | undefined) {
    const { id: securityId } = savedSecurity;

    const merges$: Observable<IAnyAction>[] = []; // [of(savedEntity).pipe(map(ActionFactories.entity.entitySaved))];

    const toWait$: Observable<any>[] = [];

    merges$.push(of(proxy).pipe(
        onlyNotNull(),
        mergeMap(model => securitiesApi.saveProxyAsync({ id: securityId, model })),
        map(() => ActionFactories.security.securityProxyLoaded({ proxyPositions: proxy ?? [], indexes: {}, securities: {} })),
        map(() => ActionFactories.security.securitySaved({security: savedSecurity}))
    ));

    if (toWait$.length) {
        return forkJoin(toWait$).pipe(mergeMap(() => merge(...merges$)));
    }
    else {
        return merge(...merges$);
    }
}

export const saveSecurityEpic: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securitySave"),
        mergeMap(({ security, proxy }) =>
            from(securitiesApi.saveAsync({ model: security })).pipe(
                mergeMap(savedSecurity => saveSecurity(savedSecurity, proxy)))));

export const deleteSecurity: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("security", "securityDelete"),
            mergeMap(id => securitiesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.security.securityDeleted),
            share()
        );
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
