import { IClassificationTypeModel, IClassificationModel, IClassificationWithChildrenModel } from "proxy/apiProxy";

export type IFlatClassificationTypes = Record<number, Record<number, IClassificationModel[]>>;

export default function getFlatClassificationTypes(classificationTypes: IClassificationTypeModel[]): IFlatClassificationTypes {
    const flattenClassificationTypes = {} as IFlatClassificationTypes;
    for (const classificationType of classificationTypes) {
        flattenClassificationTypes[classificationType.id] = getFlatClassifications(classificationType);
    }
    return flattenClassificationTypes;
}
function getFlatClassifications({ classifications }: IClassificationTypeModel): Record<number, IClassificationModel[]> {
    const flatClassifications = {} as Record<number, IClassificationModel[]>;
    for (const classification of classifications) {
        getFlattenClassifications(flatClassifications, [], classification);
    }
    return flatClassifications;
}
function getFlattenClassifications(flatClassifications: Record<number, IClassificationModel[]>, parentClassifications: IClassificationModel[], classification: IClassificationWithChildrenModel) {
    const classificationPath = [...parentClassifications, classification];
    flatClassifications[classification.id] = classificationPath;
    for (const subClassification of classification.classifications) {
        getFlattenClassifications(flatClassifications, classificationPath, subClassification);
    }
}
