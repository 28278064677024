import produce from "immer";
import { IGetMonitoringResultGroupModel, IMonitoringMacroCallSummaryModel, IDocumentDefinitionSummaryModel, IGetMonitoringMacroCallsModel, MacroScriptSummaryModel, IBatchSummaryModel, IGetReportsModel, IExecutionStatusResultModel, IReportTemplateSummaryModel, ReportSummaryModel, IGetBatchesModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IMacroScriptExecuteScript } from "../MacroScript/slice";
export interface IState {
    dashboardResultLoading: boolean;
    dashboardResultLoadingAll: boolean;
    dashboardResult?: IGetMonitoringResultGroupModel;
    dashboards: IMonitoringMacroCallSummaryModel[];
    // newDashboards?: IDashboardMacroDevelopmentItemSummaryModel[];
    dictionaries: {
        macroScripts: Record<string | number, MacroScriptSummaryModel>;
        reportTemplates: Record<string | number, IReportTemplateSummaryModel>;
        documentDefinitions: Record<string | number, IDocumentDefinitionSummaryModel>;
    }

    macroScripts?: MacroScriptSummaryModel[];
    macroScriptsLoadingAll: boolean;
    macroScriptRunning: {
        [id: number]: boolean;
    }

    batches?: IBatchSummaryModel[];
    batchesLoadingAll: boolean;
    batchLaunching: {
        [id: number]: boolean;
    }

    reports?: ReportSummaryModel[];
    reportsLoadingAll: boolean;
    reportIssuing: {
        [id: number]: boolean;
    }
}
export interface IMacroScriptExecuted extends IExecutionStatusResultModel {
    id: number;
}
export const ActionFactories = produceActionFactories({
    dashboardMonitoringLoadAll: () => undefined,
    dashboardMonitoringLoadedAll: (payload: IGetMonitoringMacroCallsModel) => payload,
    dashboardMonitoringResultLoad: (payload: number) => payload,
    dashboardMonitoringResultLoaded: (payload: IGetMonitoringResultGroupModel) => payload,

    dashboardMacroLoadAll: () => undefined,
    dashboardMacroLoadedAll: (payload: MacroScriptSummaryModel[]) => payload,
    dashboardMacroScriptExecute: (payload: IMacroScriptExecuteScript) => payload,
    dashboardMacroScriptExecuted: (payload: IMacroScriptExecuted) => payload,

    dashboardBatchLoadAll: () => undefined,
    dashboardBatchLoadedAll: (payload: IGetBatchesModel) => payload,
    dashboardBatchRequestExecution: (payload: number) => payload,
    dashboardBatchRequestedExecution: (payload: number) => payload,

    dashboardReportLoadAll: () => undefined,
    dashboardReportLoadedAll: (payload: IGetReportsModel) => payload,
    dashboardReportGenerate: (payload: number) => payload,
    dashboardReportGenerated: (payload: number) => payload,
});
export function reducer(
    state: IState = {
        dashboardResultLoading: false,
        dashboardResultLoadingAll: false,
        dashboards: [],
        dictionaries: {
            macroScripts: {},
            reportTemplates: {},
            documentDefinitions: {}
        },
        batchLaunching: {},
        reportIssuing: {},
        macroScriptRunning: {},
        macroScriptsLoadingAll: false,
        batchesLoadingAll: false,
        reportsLoadingAll: false
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "dashboardMonitoringResultLoad":
                draft.dashboardResultLoading = true;
                break;
            case "dashboardMonitoringResultLoaded":
                draft.dashboardResult = action.payload;
                draft.dashboardResultLoading = false;
                break;
            case "dashboardMonitoringLoadAll":
                draft.dashboardResultLoadingAll = true;
                break;
            case "dashboardMonitoringLoadedAll":
                draft.dashboardResultLoadingAll = false;
                draft.dashboards = action.payload.macros;
                draft.dictionaries.macroScripts = { ...draft.dictionaries.macroScripts, ...action.payload.macroScripts };
                break;

            case "dashboardMacroLoadAll":
                draft.macroScriptsLoadingAll = true;
                break;
            case "dashboardMacroLoadedAll":
                draft.macroScriptsLoadingAll = false;
                draft.macroScripts = action.payload;
                break;
            case "dashboardMacroScriptExecute":
                draft.macroScriptRunning[action.payload.id] = true;
                break;
            case "dashboardMacroScriptExecuted":
                draft.macroScriptRunning[action.payload.id] = false;
                break;
            case "dashboardBatchLoadAll":
                draft.batchesLoadingAll = true;
                break;
            case "dashboardBatchLoadedAll":
                draft.batchesLoadingAll = false;
                draft.batches = action.payload.batches;
                break;
            case "dashboardBatchRequestExecution":
                draft.batchLaunching[action.payload] = true;
                break;
            case "dashboardBatchRequestedExecution":
                draft.batchLaunching[action.payload] = false;
                break;
            case "dashboardReportLoadAll":
                draft.reportsLoadingAll = true;
                break;
            case "dashboardReportLoadedAll":
                draft.reportsLoadingAll = false;
                draft.reports = action.payload.reports;
                draft.dictionaries.reportTemplates = { ...draft.dictionaries.reportTemplates, ...action.payload.reportTemplates };
                draft.dictionaries.documentDefinitions = { ...draft.dictionaries.documentDefinitions, ...action.payload.documentDefinitions };
                break;
            case "dashboardReportGenerate":
                draft.reportIssuing[action.payload] = true;
                break;
            case "dashboardReportGenerated":
                draft.reportIssuing[action.payload] = false;
                break;
        }
    });
}
