import * as React from "react";
import { ISubFundSummaryModel, ICurrencyModel, IClassificationTypeModel, IEntityPositionModel, SecuritySummaryModel, ICountryModel, isSecurityInstrumentSummaryModel, isDerivativeSummaryModel, isRegularSecuritySummaryModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFlatClassificationTypes } from "features/Classification/getFlatClassificationTypes";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
export interface IEntityCompositionGridProps {
    subFunds: Record<number | string, ISubFundSummaryModel>;
    securities: Record<number | string, SecuritySummaryModel>;
    positions: IEntityPositionModel[];
    referenceCurrencies: Record<number | string, ICurrencyModel>;
    referenceCountries: Record<number | string, ICountryModel>;
    classificationTypes: IClassificationTypeModel[];
    classificationTypesFlat: IFlatClassificationTypes;
    onTargetSecuritySelected?: (securityId: number) => void;
    onEdit: (position: IEntityPositionModel) => void;
    onDelete: (position: IEntityPositionModel) => void;
}

export default function PortfolioCompositionGrid({
    subFunds,
    securities,
    positions,
    referenceCurrencies,
    classificationTypes,
    classificationTypesFlat,
    onTargetSecuritySelected,
    referenceCountries,
    onDelete,
    onEdit
}: IEntityCompositionGridProps) {

    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, ({ securityId }: IEntityPositionModel) => {
        const secu = securities[securityId];
        if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
            const classifs = subFunds[secu.subFundId].classifications;
            if (classifs) {
                return classifs;
            }
        }
        return isSecurityInstrumentSummaryModel(secu) ? secu.classifications : undefined;
    });
    const columns = React.useMemo(() => ([
        {
            name: "TargetSecurityCode", title: "Sec Code", getCellValue: ({ securityId }: IEntityPositionModel) => securities[securityId]?.internalCode, filteringEnabled: true
        },
        {
            name: "TargetSecurityName", title: "Sec Name", getCellValue: ({ securityId }: IEntityPositionModel) => securities[securityId]?.name, filteringEnabled: true
        },
        {
            name: "TargetSecurityType", title: "Sec Type", getCellValue: ({ securityId }: IEntityPositionModel) => getSecurityTypeLabel(securities[securityId]?.type)
        },
        ...classificationTypesColumns,
        {
            name: "TargetSecurityMat", title: "Sec Mat", getCellValue: ({ securityId }: IEntityPositionModel) => {
                const secu = securities[securityId];
                return (secu.type === "BondSummaryModel" || isDerivativeSummaryModel(secu)) ? secu.maturityDate : undefined;
            }, columnType: "date"
        },
        {
            name: "TargetSecurityCurrency",
            title: "Sec Ccy",
            getCellValue: ({ securityId }: IEntityPositionModel) => {
                const currencyId = securities[securityId]?.currencyId;
                if (!currencyId) {
                    return;
                }
                return referenceCurrencies[currencyId]?.isoCode;
            }
        },
        {
            name: "Country", title: "Country", getCellValue: ({ securityId }: IEntityPositionModel) => {
                const secu = securities[securityId];
                let countryId: number | undefined;
                if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
                    if (subFunds[secu.subFundId].countryId) {
                        countryId = subFunds[secu.subFundId].countryId
                    }
                }
                else if (isRegularSecuritySummaryModel(secu)) {
                    countryId = secu.countryId;
                }
                if (!countryId) {
                    return undefined;
                }
                const country = referenceCountries[countryId];
                if (!country) {
                    return "???";
                }
                return country.name?.en;
            }
        },
        {
            name: "TargetSecurityIsin", title: "Isin", getCellValue: ({ securityId }: IEntityPositionModel) => {
                const secu = securities[securityId];
                return isSecurityInstrumentSummaryModel(secu) ? secu.isin : undefined;
            }, filteringEnabled: true
        },
        { name: "Quantity", title: "Qty", getCellValue: ({ quantity: value }: IEntityPositionModel) => value, columnType: "decimal" },
        { name: "MarketValueInPortfolioCurrency", title: "Mark Val Prtf Cur", getCellValue: ({ marketValueInPortfolioCcy }: IEntityPositionModel) => marketValueInPortfolioCcy, columnType: "decimal", aggregationType: "sum" },
        { name: "MarketValueInSecurityCurrency", title: "Mark Val Sec Cur", getCellValue: ({ marketValueInSecurityCcy }: IEntityPositionModel) => marketValueInSecurityCcy, columnType: "decimal", aggregationType: "sum" },
        { name: "PreciseWeight", title: "Weight (precise)", getCellValue: ({ weight }: IEntityPositionModel) => weight, columnType: "precisePercentage4", aggregationType: "sum" },
        {
            name: "Actions", title: " ", getCellValue: (p: IEntityPositionModel) => <Box display="flex" gridGap={16} ><Tooltip title="Delete"><IconButton size="small" onClick={onDelete.bind(null, p)}>
                <DeleteIcon fontSize="small" />
            </IconButton></Tooltip><Tooltip title="Edit"><IconButton size="small" onClick={onEdit.bind(null, p)}>
                <EditIcon fontSize="small" />
            </IconButton></Tooltip></Box>
        }
    ] as IColumnDefinition[]), [securities, referenceCurrencies, classificationTypesColumns, subFunds, referenceCountries, onDelete, onEdit]);
    const state: IGridState = {
        "TargetSecurityCode": { width: 150, hidden: true },
        ...classificationTypesState,
        "TargetSecurityName": { width: 320 },
        "TargetSecurityType": { width: 120 },
        "Country": { width: 120, hidden: true },
        "TargetSecurityIssuer": { width: 200, hidden: true },
        "TargetSecurityMat": { width: 100, hidden: true },
        "TargetSecurityCurrency": { width: 130 },
        "TargetSecurityIsin": { width: 150 },
        "Quantity": { width: 180 },
        "MarketValueInPortfolioCurrency": { width: 180, sortingPosition: -1 },
        "MarketValueInSecurityCurrency": { width: 180, hidden: true },
        "PreciseWeight": { width: 150, hidden: true },
        "Actions": { width: 120 },
    };

    const getRowKey = (row: IEntityPositionModel) => row.id;

    const handleRowSelect = ({ securityId }: IEntityPositionModel) => {
        if (onTargetSecuritySelected) {
            onTargetSecuritySelected(securityId);
        }
    }

    return <ExtendedGrid
        getRowId={getRowKey}
        columns={columns}
        rows={positions}
        initialState={state}
        userCanGroup={true}
        defaultExportFileName="composition.xlsx"
        onRowClick={onTargetSecuritySelected ? handleRowSelect : undefined} />
}
