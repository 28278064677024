import {
    ICurrencyModel, ICountryModel, IClassificationTypeModel, ISubFundSummaryModel, SecuritySummaryModel, isRegularSecuritySummaryModel, BenchmarkPositionModel, IIndexSummaryModel
    , isSecurityInstrumentSummaryModel
} from "proxy/apiProxy";
import { formatPrecisePercentage } from "tools/lib/utility";
import { CardHeader, CardContent, Card, Grid } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { GridSize } from "@material-ui/core/Grid";
import SimplePieChart, { ISimplePieChartArgs } from "tools/components/SimplePieChart";
import { IFlatClassificationTypes } from "features/Classification/getFlatClassificationTypes";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";

export interface IPortfolioBenchmarkCompositionGridProps {
    subFunds: Record<number | string, ISubFundSummaryModel>;
    indexes: Record<number | string, IIndexSummaryModel>;
    securities: Record<number | string, SecuritySummaryModel>;
    positions: BenchmarkPositionModel[];
    referenceCountries: Record<number | string, ICountryModel>;
    referenceCurrencies: Record<number | string, ICurrencyModel>;
    classificationTypesFlat: IFlatClassificationTypes;
    classificationTypes: IClassificationTypeModel[];
}

interface IChartDefinition<T> extends Omit<ISimplePieChartArgs<T>, "rows"> {
    label: string;
}

interface ICompositionChartArgs<T> {
    rows: T[];
    definition: IChartDefinition<T>;
}

function CompositionChart<T>({ definition: { label, ...definition }, rows }: ICompositionChartArgs<T>) {
    return <Card>
        <CardHeader title={label} />
        <CardContent style={{ height: 300 }}>
            <SimplePieChart {...definition} rows={rows} getSliceLabel={formatPrecisePercentage} />
        </CardContent>
    </Card>;
}

export default function PortfolioBenchmarkCompositionChart({
    subFunds,
    securities,
    positions,
    referenceCountries,
    referenceCurrencies,
    classificationTypesFlat,
    classificationTypes,
    indexes
}: IPortfolioBenchmarkCompositionGridProps) {
    const layoutSizes: Partial<Record<Breakpoint, boolean | GridSize>> = { xl: 4, lg: 4, md: 6, sm: 6, xs: 12 };
    // {...layoutSizes}
    const getChartDefinitions = () => {
        const getValue = ({ weight = 0 }: BenchmarkPositionModel) => weight;
        return [
            {
                label: "Type",
                getArgument: p => p.type === "BenchmarkSecurityPositionModel" ? getSecurityTypeLabel(securities[p.securityId]?.type) : "Index",
                getValue
            } as IChartDefinition<BenchmarkPositionModel>,
            {
                label: "Currency",
                getArgument: p => {
                    switch (p.type) {
                        case "BenchmarkIndexPositionModel":
                            const bCurrencyId = indexes[p.indexId]?.referenceCurrencyId;
                            if (!bCurrencyId) {
                                return;
                            }
                            return referenceCurrencies[bCurrencyId]?.isoCode ?? "???";
                        case "BenchmarkSecurityPositionModel":
                            const currencyId = securities[p.securityId]?.currencyId;
                            if (!currencyId) {
                                return;
                            }
                            return referenceCurrencies[currencyId]?.isoCode ?? "???";
                    }
                },
                getValue,
            } as IChartDefinition<BenchmarkPositionModel>,
            {
                label: "Country",
                getArgument: p => {
                    switch (p.type) {
                        case "BenchmarkSecurityPositionModel":
                            const secu = securities[p.securityId];
                            let countryId: number | undefined;
                            if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
                                if (subFunds[secu.subFundId].countryId) {
                                    countryId = subFunds[secu.subFundId].countryId
                                }
                            }
                            else if (isRegularSecuritySummaryModel(secu)) {
                                countryId = secu.countryId;
                            }
                            if (!countryId) {
                                return null;
                            }
                            const country = referenceCountries[countryId];
                            if (!country) {
                                return "???";
                            }
                            return country.name?.en;
                        case "BenchmarkIndexPositionModel":
                            const bCountryId = indexes[p.indexId]?.referenceCountryId;
                            if (!bCountryId) {
                                return null;
                            }
                            return referenceCurrencies[bCountryId]?.name?.en ?? "???";
                    }
                },
                getValue,
            } as IChartDefinition<BenchmarkPositionModel>,
            ...classificationTypes.map(classificationType => ({
                label: classificationType.name["en"],
                getArgument: p => {
                    const classifications = (function () {
                        switch (p.type) {
                            case "BenchmarkSecurityPositionModel":
                                const secu = securities[p.securityId];
                                if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
                                    const classifs = subFunds[secu.subFundId].classifications;
                                    if (classifs) {
                                        return classifs;
                                    }
                                }
                                if (isSecurityInstrumentSummaryModel(secu)) {
                                    return secu.classifications;
                                }
                                break;
                            case "BenchmarkIndexPositionModel":
                                const idx = indexes[p.indexId];
                                return idx.classifications;
                        }
                        return undefined;
                    })();
                    if (!classifications) {
                        return;
                    }
                    const classificationId = classifications[classificationType.id];
                    const classificationPath = classificationTypesFlat[classificationType.id][classificationId];
                    if (!classificationPath) {
                        return;
                    }
                    return classificationPath[0].name["en"];
                },
                getValue
            } as IChartDefinition<BenchmarkPositionModel>)),
        ];
    }
    const chartDefinitions = getChartDefinitions();
    return <Grid container={true} spacing={3} style={{ height: "100%", overflowY: "auto" }}>
        {chartDefinitions.filter(chartDefinition => positions.some(chartDefinition.getArgument)).map((chartDefinition, idx) => <Grid key={idx} {...layoutSizes} item={true}>
            <CompositionChart rows={positions} definition={chartDefinition} />
        </Grid>)}
    </Grid>;
}
