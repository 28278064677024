import produce from "immer";
import { IMonitoringMacroCallModel, IGetMonitoringMacroCallModel, IGetMonitoringMacroCallsModel, IParameterMetadataModel, IMacroScriptSummaryModel, IMonitoringMacroCallSummaryModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IState {
    monitoringMacroCallAllLoading: boolean;
    monitoringMacroCallLoading: boolean;
    monitoringMacroCalls: IMonitoringMacroCallSummaryModel[];
    monitoringMacroCallCurrent?: IMonitoringMacroCallModel;
    monitoringMacroCallSaving: boolean;
    monitoringMacroCallDeleting: boolean;
    monitoringMacroCallMetadataSearchLoading: boolean;
    monitoringMacroCallParameterMetadata?: IParameterMetadataModel[];
    monitoringMacroCallMonitoringMacros?: IMacroScriptSummaryModel[];
    dictionaries: {
        macroScripts: Record<string | number, IMacroScriptSummaryModel>;
    }
}
export const ActionFactories = produceActionFactories({
    monitoringMacroCallLoad: (payload: number) => payload,
    monitoringMacroCallLoaded: (payload: IGetMonitoringMacroCallModel) => payload,
    monitoringMacroCallSave: (payload: IMonitoringMacroCallModel) => payload,
    monitoringMacroCallSaved: (payload: IMonitoringMacroCallModel) => payload,
    monitoringMacroCallDelete: (payload: number) => payload,
    monitoringMacroCallDeleted: (payload: number) => payload,
    monitoringMacroCallLoadAll: () => undefined,
    monitoringMacroCallLoadedAll: (payload: IGetMonitoringMacroCallsModel) => payload,
    monitoringMacroCallMonitoringMacroLoadedAll: (payload: IMacroScriptSummaryModel[]) => payload,
    monitoringMacroCallMetadataSearchLoad: (payload: number) => payload,
    monitoringMacroCallMetadataSearchLoaded: (payload: IParameterMetadataModel[]) => payload,
    monitoringMacroCallSelectMonitoringMacro: (payload: number) => payload
});

export function reducer(
    state: IState = {
        monitoringMacroCallLoading: false,
        monitoringMacroCallAllLoading: false,
        monitoringMacroCallSaving: false,
        monitoringMacroCallDeleting: false,
        monitoringMacroCallMetadataSearchLoading: false,
        monitoringMacroCalls: [],
        dictionaries: {
            macroScripts: {},
        }
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "monitoringMacroCallMetadataSearchLoad":
                draft.monitoringMacroCallMetadataSearchLoading = true;
                break;
            case "monitoringMacroCallMonitoringMacroLoadedAll":
                draft.monitoringMacroCallMonitoringMacros = action.payload;
                break;
            case "monitoringMacroCallMetadataSearchLoaded":
                draft.monitoringMacroCallMetadataSearchLoading = false;
                draft.monitoringMacroCallParameterMetadata = action.payload;
                break;
            case "monitoringMacroCallLoadAll":
                draft.monitoringMacroCallAllLoading = true;
                break;
            case "monitoringMacroCallLoadedAll":
                draft.monitoringMacroCallAllLoading = false;
                draft.monitoringMacroCalls = action.payload.macros;
                draft.dictionaries.macroScripts = action.payload.macroScripts;
                break;
            case "monitoringMacroCallLoad":
                draft.monitoringMacroCallCurrent = undefined;
                draft.monitoringMacroCallLoading = true;
                break;
            case "monitoringMacroCallLoaded":
                draft.monitoringMacroCallLoading = false;
                draft.monitoringMacroCallCurrent = action.payload.macro;
                draft.dictionaries.macroScripts = { ...draft.dictionaries.macroScripts, ...action.payload.macroScripts };
                break;
            case "monitoringMacroCallSave":
                draft.monitoringMacroCallSaving = true;
                break;
            case "monitoringMacroCallSaved":
                draft.monitoringMacroCallSaving = false;
                const saved = action.payload;
                draft.monitoringMacroCallCurrent = saved;
                const existing = draft.monitoringMacroCalls.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.monitoringMacroCalls.push(saved);
                }
                break;
            case "monitoringMacroCallDelete":
                draft.monitoringMacroCallDeleting = true;
                break;
            case "monitoringMacroCallDeleted":
                draft.monitoringMacroCallDeleting = false;
                const deletedId = action.payload;
                if (draft.monitoringMacroCallCurrent?.id === deletedId) {
                    delete draft.monitoringMacroCallCurrent;
                }
                const idx = draft.monitoringMacroCalls.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.monitoringMacroCalls.splice(idx, 1);
                }
                break;
        }
    });
}
