import { Epic } from "redux-observable";
import { filter, map, mergeMap, share } from "rxjs/operators";
import {
    IGetRegisterTransactionModel,
    IRegisterPortfolioTransactionModel,
    IRegisterShareClassTransactionModel,
    registerTransactionsApi
} from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { merge } from "rxjs";

export const loadTransactions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("registerTransaction", "registerTransactionsSearch"),
        mergeMap(registerTransactionsApi.searchAsync),
        map(ActionFactories.registerTransaction.registerTransactionsLoaded));


export const loadTransaction: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("registerTransaction", "registerTransactionLoad"),
            share());

        return merge(
            requestedId$.pipe(
                filter(id => typeof id === "number"),
                mergeMap(id => registerTransactionsApi.getAsync({ id: id as number }))),
            requestedId$.pipe(
                filter(id => typeof id === "string"),
                map(i => createEmptyTransaction(i as string)))
        ).pipe(
            map(ActionFactories.registerTransaction.registerTransactionLoaded));
    };
function createEmptyTransaction(type: string): IGetRegisterTransactionModel {
    const transaction = (function () {
        switch (type) {
            case "shareclass":
            case "RegisterShareClassTransactionModel":
            case "RegisterShareClassTransactionSummaryModel":
                return {
                    type: "RegisterShareClassTransactionModel",
                    description: "",
                    id: 0,
                    portfolioId: 0,
                    transactionCode: "",
                } as IRegisterShareClassTransactionModel;
            // case "security":
            // case "PortfolioSecurityTransactionModel":
            // case "PortfolioSecurityTransactionSummaryModel":
            default:
                return {
                    type: "RegisterPortfolioTransactionModel",
                    description: "",
                    id: 0,
                    portfolioId: 0,
                    transactionCode: "",
                } as IRegisterPortfolioTransactionModel;
        }
    })();
    return {
        relationships: {},
        entities: {},
        portfolios: {},
        securities: {},
        subFunds: {},
        transaction
    };
}
export const saveTransaction: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("registerTransaction", "registerTransactionSave"),
        mergeMap(model => registerTransactionsApi.saveAsync({ model })),
        map(ActionFactories.registerTransaction.registerTransactionSaved));
export const deleteTransaction: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("registerTransaction", "registerTransactionDelete"),
            mergeMap(id => registerTransactionsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.registerTransaction.registerTransactionDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }