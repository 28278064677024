import { DefaultLinkModel, DiagramListener, DiagramModel, NodeModel } from '@projectstorm/react-diagrams';
import { BaseModel } from '@projectstorm/react-canvas-core';
import BodyWidget from './BodyWidget';
import { useMemo } from 'react';
import { JsxNodeModel } from './JsxNode';

export interface IElement {
  id: string | number;
  content: JSX.Element;
}
export interface ILink {
  fromId: string | number;
  toId: string | number;
}
export interface IFlowGraphProps {
  contents: IElement[];
  links: ILink[];
  isLocked?: boolean;
}

export default function FlowGraph({ contents: elements, links, isLocked }: IFlowGraphProps) {
  const model = useMemo(() => {
    const nodesDico = elements.map(element => {
      const tmp = {
        id: element.id,
        node: new JsxNodeModel({ element: element.content ?? <>no content</> })
      };
      return tmp;
    }).reduce((a, { id, node }) => {
      a[id] = node;
      return a;
    }, {} as Record<string | number, NodeModel>);

    const linksItems = links.map(l => {
      const from = nodesDico[l.fromId];
      if (!from) {
        return null;
      }
      const to = nodesDico[l.toId];
      if (!to) {
        return null;
      }
      const link = new DefaultLinkModel();
      link.setSourcePort(from.getPort('out'));
      link.setTargetPort(to.getPort('in'));
      // link.setLocked(true);
      return link;
    })
      .filter(i => !!i) as BaseModel[];
    const model = new DiagramModel();
    model.addAll(...Object.values(nodesDico), ...linksItems);
    model.setLocked(isLocked ?? false);
    model.setZoomLevel(50);
    return model;
  }, [elements, links, isLocked]);
  const eventDidFire: DiagramListener["eventDidFire"] = (event) => {
    if (event.function === "offsetUpdated") return null;
    return null;
    // console.log(event);
  }
  const eventWillFire: DiagramListener["eventWillFire"] = (event) => {
    if (event.function === "offsetUpdated") return null;
    return null;
    // console.log(event);
  }
  const linksUpdated: DiagramListener["linksUpdated"] = (event) => {
    // console.log(event);
  }
  const lockChanged: DiagramListener["lockChanged"] = (event) => {
    // console.log(event);
  }
  const nodesUpdated: DiagramListener["nodesUpdated"] = (event) => {
    // console.log(event);
  }
  const listener: DiagramListener = {
    eventDidFire,
    eventWillFire,
    linksUpdated,
    lockChanged,
    nodesUpdated
  } as DiagramListener;
  model.registerListener(listener);
  return <BodyWidget model={model} direction="LR" />
}
