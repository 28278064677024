import * as React from "react";
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Paper, LinearProgress } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { useCurrentScreenMenuState } from "features/App/menuTools";
import { IconButton, Typography, Tabs, Tab } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IScreenMenuState } from 'features/App/slice';
import { ScreenLink } from 'tools/routing/ScreenLink';

const useHeaderStyles = makeStyles(theme => createStyles({
    detailTitle: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(2),
    },
    tabContainer: {
        backgroundColor: theme.palette.primary.dark
    },
}));

export interface IHeaderTitleLoadingProps {
    tabNumber?: number;
    hasSubTitle?: boolean;
    parentScreen?: IScreenMenuState;
}
function range(count: number) {
    const elements: number[] = [];
    for (let index = 0; index < count; index++) {
        elements.push(index + 1);
    }
    return elements;
}

function HeaderTitleLoading({ tabNumber, hasSubTitle = true, parentScreen }: IHeaderTitleLoadingProps) {
    const classes = useHeaderStyles();
    const tabs = range(tabNumber ?? 0);
    return <Box display="flex" flexDirection="column">
        <Box className={classes.detailTitle} alignItems="center" display="flex">
            {(!!parentScreen) && <IconButton aria-label="Back" style={{ verticalAlign: "middle" }} component={ScreenLink} destination={parentScreen} >
                <ArrowBackIcon />
            </IconButton>}
            <Box>
                <Typography variant="h6" color="inherit" noWrap={true}>
                    <Skeleton width="200px" animation="wave" />
                </Typography>
                {hasSubTitle && <Typography variant="subtitle1" color="inherit" noWrap={true}>
                    <Skeleton width="150px" animation="wave" />
                </Typography>}
            </Box>
        </Box>
        {!!tabNumber && <Box display="flex"><Tabs style={{ flexGrow: 1 }}
            value="1"
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabContainer}>
            {tabs.map((idx) => (<Tab key={idx} value={idx.toString()} label={<Skeleton width="100px" animation="wave" />} />))}
        </Tabs><div style={{ width: 200 }} className={classes.tabContainer} /></Box>}
    </Box>;
}

const useStyles = makeStyles(theme => createStyles({
    detailContent: {
        padding: theme.spacing(2)
    },
    detailContentNoPadding: {
        padding: 0
    },
}));

const StyledPaper = withStyles(theme =>
    createStyles({
        root: {
            height: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column"
        }
    })
)(Paper);

export interface IDetailPanelLoadingProps {
    tabNumber?: number;
    hasSubTitle?: boolean;
}
function DetailPanelLoading({ tabNumber, hasSubTitle = true }: React.PropsWithChildren<IDetailPanelLoadingProps>) {
    const currentScreen = useCurrentScreenMenuState();
    const classes = useStyles();
    const virtualElements = React.useMemo(() => range(Math.floor(Math.random() * 10) + 1).map(i =>
        `${(Math.floor(Math.random() * 9) + 1) * 10}%`
    ), []);
    return (
        <StyledPaper>
            <LinearProgress variant="query" />
            <HeaderTitleLoading
                hasSubTitle={hasSubTitle}
                parentScreen={currentScreen?.parent}
                tabNumber={tabNumber} />
            <Box className={classes.detailContent} flexGrow="1" overflow="auto" height="100%" position="relative">
                {virtualElements.map((i, idx) => <Skeleton key={idx} width={i} animation="wave" height="50px" />)}
            </Box>
        </StyledPaper>
    );
}
export default React.memo(DetailPanelLoading);