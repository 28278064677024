import FieldBox from "tools/components/FieldBox";
import FormTextField from "tools/fieldComponents/FormTextField";
import { IMonitoringMacroCallModel, IMacroScriptSummaryModel, IParameterMetadataModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import ScopeDescriptionData from "features/Report/ScopeDescriptionData";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import { StringToStringDictionaryField } from "tools/fieldComponents/StringToStringDictionaryField";

export interface IMonitoringMacroCallDataProps {
    macroScripts: IMacroScriptSummaryModel[];
    onSelectMonitoringMacro: (reportTemplateId: number) => void;
    onOpenMonitoringMacro: () => void;
    parameterMetadata: IParameterMetadataModel[];
}

function MonitoringMacroCallData({ macroScripts, parameterMetadata, onSelectMonitoringMacro }: IMonitoringMacroCallDataProps) {
    const handleMonitoringMacroChanged = (id: number | string | undefined) => typeof (id) === "number" && onSelectMonitoringMacro(id);
    const handlePrimaryContent = (i: IMacroScriptSummaryModel) => i.name;

    return (
        <FieldBox display="flex" flexDirection="column" >
            <FieldBox display="flex" flexDirection="row">
                <FormTextField name={oProps<IMonitoringMacroCallModel>().path("code")} label="Code" maxLength={100} required={true} />
                <FormTextField name={oProps<IMonitoringMacroCallModel>().path("name")} label="Name" maxLength={250} required={true} />
            </FieldBox>
            <FieldBox display="flex" flexDirection="row">
                <FormSimpleSelectField name={oProps<IMonitoringMacroCallModel>().path("macroScriptId")} label="Monitoring Macro" options={macroScripts} onSelectionChanged={handleMonitoringMacroChanged} getPrimaryContent={handlePrimaryContent} required={true} />
                <FormCheckBoxField name={oProps<IMonitoringMacroCallModel>().path("isDashboard")} label="Is Dashboard" />
            </FieldBox>
            <ScopeDescriptionData
                formFieldName={oProps<IMonitoringMacroCallModel>().path("scopeDescription")}
                parameterMetadatas={parameterMetadata} />
            <StringToStringDictionaryField path={oProps<IMonitoringMacroCallModel>().path("parameterValues")} label="Parameters" />
        </FieldBox>
    );
}

export default MonitoringMacroCallData;
