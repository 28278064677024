import produce from "immer";
import { CustomScreenModel, CustomScreenSummaryModel, IEntityMetadataModel, IMacroScriptCheckResultModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IState {
    customScreenAllLoading: boolean;
    customScreenLoading: boolean;
    customScreens: (CustomScreenSummaryModel | CustomScreenModel)[];
    customScreenCurrent?: CustomScreenModel;
    customScreenSaving: boolean;
    customScreenDeleting: boolean;
    customScreenScriptMetadata: IMacroScriptMetadata;
    customScreenScriptCheckResult?: IMacroScriptCheckResultModel;
}
export interface IMacroScriptMetadata {
    [key: string]: IEntityMetadataModel;
    [key: number]: IEntityMetadataModel;
}
export interface IMacroScriptLoadedPayload {
    customScreen: CustomScreenModel,
    customScreenMacroCheckResult: IMacroScriptCheckResultModel
}
export const ActionFactories = produceActionFactories({
    customScreenNew: (payload: CustomScreenModel["type"]) => payload,
    customScreenLoad: (payload: number) => payload,
    customScreenLoaded: (payload: IMacroScriptLoadedPayload) => payload,
    customScreenSave: (payload: CustomScreenModel) => payload,
    customScreenSaved: (payload: CustomScreenModel) => payload,
    customScreenDelete: (payload: number) => payload,
    customScreenDeleted: (payload: number) => payload,
    customScreenLoadAll: () => undefined,
    customScreenLoadedAll: (payload: CustomScreenSummaryModel[]) => payload,
    customScreenMetadataLoad: () => undefined,
    customScreenMetadataLoaded: (payload: IMacroScriptMetadata) => payload,
    customScreenValidateScript: (payload: string) => payload,
    customScreenValidatedScript: (payload: IMacroScriptCheckResultModel) => payload,
});

export function reducer(
    state: IState = {
        customScreenLoading: false,
        customScreenAllLoading: false,
        customScreenSaving: false,
        customScreenDeleting: false,
        customScreens: [],
        customScreenScriptMetadata: {}
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "customScreenMetadataLoad":
                draft.customScreenScriptMetadata = {};
                break;
            case "customScreenMetadataLoaded":
                draft.customScreenScriptMetadata = action.payload;
                break;
            case "customScreenLoadAll":
                draft.customScreenAllLoading = true;
                break;
            case "customScreenLoadedAll":
                draft.customScreenAllLoading = false;
                draft.customScreens = action.payload;
                break;
            case "customScreenValidatedScript":
                draft.customScreenScriptCheckResult = action.payload;
                break;
            case "customScreenNew":
            case "customScreenLoad":
                draft.customScreenLoading = true;
                draft.customScreenCurrent = undefined;
                draft.customScreenScriptCheckResult = undefined;
                break;
            case "customScreenLoaded":
                draft.customScreenLoading = false;
                draft.customScreenCurrent = action.payload.customScreen;
                draft.customScreenScriptCheckResult = action.payload.customScreenMacroCheckResult;
                break;
            case "customScreenSave":
                draft.customScreenSaving = true;
                break;
            case "customScreenSaved":
                draft.customScreenSaving = false;
                const saved = action.payload;
                if (draft.customScreenCurrent) {
                    draft.customScreenCurrent = saved;
                }
                const existing = draft.customScreens.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.customScreens.push(saved);
                }
                break;
            case "customScreenDelete":
                draft.customScreenDeleting = true;
                break;
            case "customScreenDeleted":
                draft.customScreenDeleting = false;
                const deletedId = action.payload;
                if (draft.customScreenCurrent?.id === deletedId) {
                    delete draft.customScreenCurrent;
                }
                const idx = draft.customScreens.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.customScreens.splice(idx, 1);
                }
                break;
        }
    });
}
