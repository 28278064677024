import {
    LinkProps, useHref
    // , useLinkClickHandler 
} from "react-router-dom";
// import { buildScreenUrl as buildScreenUrl, ScreenInputParams, ScreenKey, ScreenTab } from "./screenRouteHooks";
// import { IMenuLocation } from "features/App/menu/menuHooks";
// import { useReduxActions } from "tools/lib/reduxStoreAccess";
import React, {
    ForwardedRef, forwardRef
    // , MouseEventHandler, useCallback 
} from "react";
import { IMenuTarget } from "features/App/menu/menuContracts";
import { IScreenMenuState } from "features/App/slice";
// import { computeUrl } from "features/App/menuTools";
import { Link } from "react-router-dom";
// import { buildUrl } from "./screenRouteHooks";
import { generatePath, useParams } from "react-router";
import { convertToScreenMenu } from "features/App/menuTools";

// To work with generic forward ref of ScreenLink
// Source: https://fettblog.eu/typescript-react-generic-forward-refs/
declare module "react" {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}
export interface ScreenPropsBase {
    destination: IMenuTarget | (IScreenMenuState & { tab?: string });
}
function isMenuTarget(destination: IMenuTarget | IScreenMenuState): destination is IMenuTarget {
    return !!(destination as IMenuTarget)?.type;
}
export interface ScreenProps extends ScreenPropsBase, Omit<LinkProps, "to"> {
    tab?: string;
}

function ScreenLinkInner(
    {
        destination,
        // menuLocation,
        onClick,
        replace = false,
        state,
        target,
        children,
        ...props
    }: ScreenProps,
    ref: ForwardedRef<HTMLAnchorElement>
) {
    const params = useParams();
    const screen = isMenuTarget(destination)
        ? { ...convertToScreenMenu(destination)}
        : destination;
    const to = generatePath(screen.urlTemplate, { ...params, ...screen.params, tab: screen.tab });
    const href = useHref(to);

    return <Link
        {...props}
        to={href}
        ref={ref}
        target={target}>
        {children}
    </Link>
}

export const ScreenLink = forwardRef(ScreenLinkInner)
