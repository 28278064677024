import produce from "immer";
import { IConnectorsModel, IConnectorTestResultModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IConnectorStates {
    providers: { [code: string]: IConnectorTestResult };
    processors: { [code: string]: IConnectorTestResult };
}
export interface IConnectorTestResult extends Partial<IConnectorTestResultModel> {
    testing?: boolean;
}
export interface IState {
    connectorsLoading: boolean;
    connectors: IConnectorsModel;
    connectorsSaving: boolean;
    connectorsScriptSchema?: object;
    connectorsState: IConnectorStates;
}
export interface ITestedConnector extends IConnectorTestResultModel {
    code: string;
}
export const ActionFactories = produceActionFactories({
    connectorsLoad: () => undefined,
    connectorsLoaded: (payload: IConnectorsModel) => payload,
    connectorsSave: (payload: IConnectorsModel) => payload,
    connectorsSaved: (payload: IConnectorsModel) => payload,
    connectorsSchemaLoaded: (payload: object) => payload,
    connectorsProviderTest: (payload: string) => payload,
    connectorsProviderTested: (payload: ITestedConnector) => payload,
    connectorsProcessorTest: (payload: string) => payload,
    connectorsProcessorTested: (payload: ITestedConnector) => payload,
});

export function reducer(
    state: IState = {
        connectorsLoading: false,
        connectorsSaving: false,
        connectors: { jsonConfig: "{}" },
        connectorsState: {
            processors: {},
            providers: {}
        }
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "connectorsLoad":
                draft.connectorsLoading = true;
                break;
            case "connectorsLoaded":
                draft.connectorsLoading = false;
                draft.connectors = action.payload;
                break;
            case "connectorsSchemaLoaded":
                draft.connectorsScriptSchema = action.payload;
                break;
            case "connectorsSave":
                draft.connectorsSaving = true;
                break;
            case "connectorsSaved":
                draft.connectorsSaving = false;
                const saved = action.payload;
                draft.connectors = saved;
                draft.connectorsState = {
                    processors: {},
                    providers: {}
                };
                break;
            case "connectorsProcessorTest":
                draft.connectorsState.processors[action.payload] = { testing: true }
                break;
            case "connectorsProviderTest":
                draft.connectorsState.providers[action.payload] = { testing: true }
                break;
            case "connectorsProcessorTested":
                {
                    const { code, ...vals } = action.payload;
                    draft.connectorsState.processors[action.payload.code] = { testing: false, ...vals };
                }
                break;
            case "connectorsProviderTested":
                {
                    const { code, ...vals } = action.payload;
                    draft.connectorsState.providers[action.payload.code] = { testing: false, ...vals };
                }
                break;
        }
    });
}
