import produce from "immer";
import { IEntityLoginModel } from "proxy/apiProxy";

import { produceActionFactories, AnyActionOf } from "tools/lib/store";

export interface ILoginSwitchingPayload {
    entityId: number;
    isAdmin: boolean;
}

export const ActionFactories = produceActionFactories({
    tenantManagerLoginsLoad: () => undefined,
    tenantManagerLoginsLoaded: (payload: IEntityLoginModel[]) => payload,
    tenantManagerLoginSwitch: (payload: ILoginSwitchingPayload) => payload,
    tenantManagerLoginSwitched: (payload: ILoginSwitchingPayload) => payload
});

export interface IState {
    logins: IEntityLoginModel[];
    loginsLoading: boolean;
    loginsSwitching: Record<number, boolean>;
}
export type Action = AnyActionOf<typeof ActionFactories>;

export function reducer(
    state: IState = {
        logins: [],
        loginsLoading: false,
        loginsSwitching: {}
    },
    action: Action
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "tenantManagerLoginsLoad":
                draft.loginsLoading = true;
                break;
            case "tenantManagerLoginsLoaded":
                draft.loginsLoading = false;
                draft.logins = action.payload;
                break;
            case "tenantManagerLoginSwitch":
                draft.loginsSwitching[action.payload.entityId] = true;
                break;
            case "tenantManagerLoginSwitched":
                draft.loginsSwitching[action.payload.entityId] = false;
                const entity = draft.logins.find(i => i.entity.id === action.payload.entityId);
                if (entity) {
                    entity.isTenantAdministrator = action.payload.isAdmin;
                }
                break;
        }
    });
}
