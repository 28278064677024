import { Epic, ofType } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { IStatisticDefinitionSetModel, statisticDefinitionSetsApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { parse as jsonParse } from "jsonc-parser";


export const loadStatisticDefinitionSets: Epic<IAnyAction>
    = action$ =>
        merge(
            action$.pipe(ofType("applicationLoaded"), map(i => null)),
            action$.pipe(ofType("statisticDefinitionSetLoadAll"), map(i => null)),
            action$.pipe(ofType("statisticDefinitionSetSaved"), map(i => null)),
            action$.pipe(ofType("referenceRefreshAll"), map(i => null))
        ).pipe(
            mergeMap(() => statisticDefinitionSetsApi.getAllAsync({})),
            map(ActionFactories.statisticDefinitionSet.statisticDefinitionSetLoadedAll));
export const loadStatisticDefinitionSet: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("statisticDefinitionSet", "statisticDefinitionSetLoad"),
            share());

        return merge(
            requestedId$.pipe(
                mergeMap(() => statisticDefinitionSetsApi.getStatisticsDefinitionSetSchemaAsync()),
                map(i => ActionFactories.statisticDefinitionSet.statisticDefinitionSetSchemaLoaded(jsonParse(i)))),
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => statisticDefinitionSetsApi.getAsync({ id })),
                map(ActionFactories.statisticDefinitionSet.statisticDefinitionSetLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    id: 0,
                    name: "",
                    code: "",
                    definitions: "[]",
                    publishedVersion: 0
                } as IStatisticDefinitionSetModel)),
                map(ActionFactories.statisticDefinitionSet.statisticDefinitionSetLoaded)));
    }
export const saveStatisticDefinitionSet: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("statisticDefinitionSet", "statisticDefinitionSetSave"),
        mergeMap(model => statisticDefinitionSetsApi.saveAsync(({ model }))),
        map(ActionFactories.statisticDefinitionSet.statisticDefinitionSetSaved));

export const deleteStatisticDefinitionSet: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("statisticDefinitionSet", "statisticDefinitionSetDelete"),
            mergeMap(id => statisticDefinitionSetsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.statisticDefinitionSet.statisticDefinitionSetDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
