import { EntityModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import { useField } from "formik";
import { Box, Grid } from "@material-ui/core";
import JsonScriptEditor from "../Parameters/JsonScriptEditor";

export default function EntityWhiteLabelData() {
    const [{ value: hasPersonalPortalTheme }] = useField<EntityModel["hasPersonalPortalTheme"]>(oProps<EntityModel>("entity").path("hasPersonalPortalTheme"));
    return <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
            <FormCheckBoxField name={oProps<EntityModel>("entity").path("hasPersonalPortalTheme")} label="Has Personal Portal Theme" />
        </Grid>
        {!!hasPersonalPortalTheme && <Grid item={true} xs={12} >
            <Box height="300px" width="100%"><JsonScriptEditor fieldName={oProps<EntityModel>("entity").path("themeConfig")} language="json" /></Box>
        </Grid>}
    </Grid>
}