import * as React from "react";
import { useEffect } from "react";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColorStep, IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IStatisticsSetObjectModel, IStatisticsSetObjectTypeModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";

export const valueAtRiskScreen = {
    route: "/valueatrisk" as const,
    component: ValueAtRisk,
    label: "Value at Risk"
}

interface IRowIdType {
    portfolioId: number;
    type: IStatisticsSetObjectTypeModel;
    classificationId?: number;
    securityId?: number;
};

const historicalValuesColumns = [{
    name: "Perf1M",
    title: "Perf. 1M",
    historicalValueType: "PRF",
    columnType: "precisePercentage2",
    highIsGood: true
}, {
    name: "Ctp",
    title: "Contrib. to Perf. 1M",
    historicalValueType: "CTP",
    columnType: "precisePercentage2",
    highIsGood: true
}, {
    name: "SvVolatility1M1YE",
    title: "Ann. Volatility 1W1YE",
    historicalValueType: "VOLA",
    columnType: "precisePercentage2",
    highIsGood: false
}, {
    name: "SvCtvVolatility1M1YE",
    title: "Contrib. Volatility 1W1YE",
    historicalValueType: "MVOL",
    columnType: "precisePercentage2",
    highIsGood: false
}, {
    name: "SvGaussianVaR1M",
    title: "Gauss VaR 1M1Y",
    historicalValueType: "VARG",
    columnType: "precisePercentage2",
    highIsGood: false
}, {
    name: "GaussianES",
    title: "Gauss ES 1M1Y",
    historicalValueType: "ESFG",
    columnType: "precisePercentage2",
    highIsGood: false
}, {
    name: "SvMargGaussVaR1M",
    title: "Marg. Gauss VaR 1M1Y",
    historicalValueType: "MGV",
    columnType: "precisePercentage2",
    highIsGood: false
}, {
    name: "SvModifiedVaR1M",
    title: "Modified VaR 1M1Y",
    historicalValueType: "VARM",
    columnType: "precisePercentage2",
    highIsGood: true
}, {
    name: "ModifiedES",
    title: "Modified ES 1M1Y",
    historicalValueType: "ESFM",
    columnType: "precisePercentage2",
    highIsGood: true
}, {
    name: "SvMargModifiedVaR1M",
    title: "Marg. Modified VaR 1M1Y",
    historicalValueType: "MMV",
    columnType: "precisePercentage2",
    highIsGood: true
}, {
    name: "MDD",
    title: "MDD 5Y",
    historicalValueType: "MDD",
    columnType: "precisePercentage2",
    highIsGood: false
}, {
    name: "SharpeRatio",
    title: "Ann. Sharpe Ratio 1W1YN",
    historicalValueType: "SHPE",
    columnType: "decimal",
    highIsGood: true
}, {
    name: "InfoRatio",
    title: "Ann. IR 1W1YN",
    historicalValueType: "INFO",
    columnType: "decimal",
    highIsGood: true
}, {
    name: "TrackError",
    title: "Ann. Track. Error 1W1YN",
    historicalValueType: "TERR",
    columnType: "precisePercentage2",
}, {
    name: "Beta",
    title: "Beta 1W1YN",
    historicalValueType: "BETA",
    columnType: "decimal",
}, {
    name: "RSquared",
    title: "RSquared 1W1YN",
    historicalValueType: "RSQ",
    columnType: "decimal",
}];

function ValueAtRisk() {
    const {valueAtRiskLoadChildren, valueAtRiskLoadAll} = useReduxActions("valueAtRisk");
    const { valueAtRiskChildren, valueAtRiskAllLoading, valueAtRiskChildrenLoading, valueAtRisks: { statisticsSets, portfolios = {}, securities, securityClassifications } } = useReduxSelections("valueAtRisk");
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    const [expandedRowIds, setExpandedRowIds] = React.useState<string[]>([]);

    useEffect(() => {
        valueAtRiskLoadAll()
    }, [valueAtRiskLoadAll]);

    const defaultColumns = React.useMemo(() => {
        const getDataSet = (row: IStatisticsSetObjectModel) => {
            switch (row.type) {
                case IStatisticsSetObjectTypeModel.Portfolio: return portfolios[row.portfolioId];
                case IStatisticsSetObjectTypeModel.Classification: return securityClassifications[row.classificationId ?? 0];
                case IStatisticsSetObjectTypeModel.Benchmark: return { ...portfolios[row.portfolioId], name: `${portfolios[row.portfolioId].name} Benchmark` };
                case IStatisticsSetObjectTypeModel.Position: return securities[row.securityId ?? 0];
            }
            return;
        }
        return [{
            name: "Name",
            title: "Name",
            getCellValue: (row: IStatisticsSetObjectModel) => {
                const datasetName = getDataSet(row)?.name;
                if (typeof datasetName === "undefined") {
                    return;
                }
                else if (typeof datasetName === "string") {
                    return datasetName;
                }
                else {
                    return datasetName.en;
                }
            },
            filteringEnabled: true,
            positionToKeep: "left"
        }, {
            name: "Type",
            title: "Type",
            getCellValue: ({ type }: IStatisticsSetObjectModel) => type as string, filteringEnabled: true
        }, {
            name: "InternalCode",
            title: "Internal Code",
            getCellValue: (row: IStatisticsSetObjectModel) => {
                const dataset = getDataSet(row);
                if (hasInternalCode(dataset)) return dataset.internalCode;
                return;
            },
            filteringEnabled: true
        }, {
            name: "CurrencyId",
            title: "CCY",
            getCellValue: (row: IStatisticsSetObjectModel) => {
                const dataset = getDataSet(row);
                if (hasCurrency(dataset)) return referenceCurrencies[dataset.currencyId]?.isoCode;
                return;
            }, filteringEnabled: true
        }, {
            name: "Date", title: "Date",
            getCellValue: ({ date }: IStatisticsSetObjectModel) => date, columnType: "date"
        }, {
            name: "MarketValue", title: "Market Value",
            getCellValue: (row: IStatisticsSetObjectModel) => row.marketValue, columnType: "decimal"
        }, {
            name: "Weight",
            title: "Weight",
            getCellValue: ({ weight }: IStatisticsSetObjectModel) => weight, columnType: "precisePercentage2"
        }] as IColumnDefinition[];
    }, [referenceCurrencies, portfolios, securityClassifications, securities]);

    // const [columns, setColumns] = React.useState<IColumnDefinition[]>(defaultColumns);

    const getColorSetup = (statSet: IStatisticsSetObjectModel[], hvType: string, highIsGood: boolean | undefined) => {
        const vals = statSet.map(({ statisticalValues }) => statisticalValues && statisticalValues[hvType]).filter(i => typeof (i) === "number");
        const min = vals.length === 0 ? 0 : Math.min(...vals);
        const max = vals.length === 0 ? 1 : Math.max(...vals);
        if (highIsGood) {
            return [
                { color: "#ffffff", value: max },
                { color: "#d9575", value: min }
            ] as IColorStep[];
        }
        else {
            return [
                { color: "#ffffff", value: min },
                { color: "#d9575", value: max }
            ] as IColorStep[];
        }
    }

    const columns = React.useMemo(() => [
        ...defaultColumns,
        ...historicalValuesColumns.map(({ name, title, historicalValueType, columnType, highIsGood }) => ({
            name,
            title,
            getCellValue: ({ statisticalValues }: IStatisticsSetObjectModel) => statisticalValues && statisticalValues[historicalValueType],
            columnType,
            backgroundColor: getColorSetup(statisticsSets, historicalValueType, highIsGood)
        } as IColumnDefinition))
    ] as IColumnDefinition[], [statisticsSets, defaultColumns]);

    const handleRowSelect = (row: IStatisticsSetObjectModel) => {
        // FIXME Route is currently disabled because the component would need to be migrated
        //  navigate("ValueAtRisk", "detail", {id: row.portfolioId});
    };
    const handleExpandRowIds = (newExpandedRowIds: React.ReactText[]) => {
        const ner = newExpandedRowIds as string[];
        ner
            .filter(i =>
                expandedRowIds.indexOf(i) < 0
                && typeof (i) === "string"
                && getIdLevel(i) === 0)
            .map(i => parseRowId(i as string).portfolioId)
            .filter(pId => !valueAtRiskChildren[pId])
            .forEach(valueAtRiskLoadChildren);
        setExpandedRowIds(ner);
    }

    const getChildRows = (currentRow: IStatisticsSetObjectModel, rootRows: IStatisticsSetObjectModel[]) => {
        if (!currentRow) {
            return rootRows.filter(({ type }) => type === IStatisticsSetObjectTypeModel.Portfolio);
        }
        switch (currentRow.type) {
            case IStatisticsSetObjectTypeModel.Portfolio:
                return rootRows.filter(({ type, portfolioId }) => (portfolioId === currentRow.portfolioId && type === IStatisticsSetObjectTypeModel.Classification) || type === IStatisticsSetObjectTypeModel.Benchmark);
            case IStatisticsSetObjectTypeModel.Benchmark:
                //const benchmarkChildren = rootRows.filter(({ type, portfolioId }) => portfolioId === currentRow.portfolioId && type === IStatisticsSetObjectTypeModel.Position);
                //return benchmarkChildren.length > 0 ? benchmarkChildren : null;
                return null;
            case IStatisticsSetObjectTypeModel.Classification:
                const classificationChildren = rootRows.filter(({ type, portfolioId, classificationId }) => portfolioId === currentRow.portfolioId && classificationId === currentRow.classificationId && type === IStatisticsSetObjectTypeModel.Position);
                return classificationChildren.length > 0 ? classificationChildren : null;
        }
        return null;
    }
    const parseRowId = (id: string) => JSON.parse(id) as IRowIdType;

    const createRowId = (row: IStatisticsSetObjectModel) => JSON.stringify({
        portfolioId: row.portfolioId,
        type: row.type,
        classificationId: row.classificationId,
        securityId: row.securityId
    } as IRowIdType);

    const getIdLevel = (id: string) => {
        const idParts = parseRowId(id);
        switch (idParts.type) {
            case IStatisticsSetObjectTypeModel.Portfolio: return 0;
            case IStatisticsSetObjectTypeModel.Classification: return 1;
            case IStatisticsSetObjectTypeModel.Benchmark: return 1;
            case IStatisticsSetObjectTypeModel.Position: return 2;
        }
        return;
    };

    const state: IGridState = React.useMemo(() => ({
        "Name": { width: 400 },
        "Type": { width: 120 },
        "InternalCode": { width: 100 },
        // "CurrencyId": { width: 70, hidden: false },
        "Date": { width: 120, hidden: false },
        "MarketValue": { width: 100, hidden: false },
        "Weight": { width: 90, hidden: false },

        "Perf1M": { width: 100 },
        "Ctp": { width: 100 },

        "SvVolatility1M1YE": { width: 100 },
        "SvCtvVolatility1M1YE": { width: 100 },

        "SvGaussianVaR1M": { width: 100 },
        "GaussianES": { width: 100, hidden: false },
        "SvMargGaussVaR1M": { width: 100, hidden: false },

        "SvModifiedVaR1M": { width: 100 },
        "ModifiedES": { width: 100, hidden: false },
        "SvMargModifiedVaR1M": { width: 100, hidden: false },

        "MDD": { width: 100, hidden: false },
        "SharpeRatio": { width: 100 },
        "InfoRatio": { width: 100 },
        "TrackError": { width: 100 },
        "Beta": { width: 80 },
        "RSquared": { width: 80 },
        "ActionFactories": { width: 70 },
    }), []);
    return (
        <SearchPanel title="Statistics" isQuerying={valueAtRiskAllLoading || valueAtRiskChildrenLoading}>
            {!!(statisticsSets?.length) && <ExtendedGrid
                getRowId={createRowId}
                columns={columns}
                rows={statisticsSets}
                initialState={state}
                onRowClick={handleRowSelect}
                getChildRows={getChildRows}
                onExpandedRowIds={handleExpandRowIds}
                expandedRowIds={expandedRowIds}
                treeColumnName={"Name"}
                defaultExportFileName="Statistics.xlsx"
                useMultilineHeader={true}
            />}
        </SearchPanel>
    );
}

function hasCurrency(row: any): row is { currencyId: number } {
    return !!(row as { currencyId: number })?.currencyId;
}
function hasInternalCode(row: any): row is { internalCode: string } {
    return !!(row as { internalCode: string })?.internalCode;
}
