import {
    RelationshipSummaryModel,
    ICurrencyModel,
    EntitySummaryModel,
    IRegisterTradeTypeModel,
    PortfolioSummaryModel,
    SecuritySummaryModel,
    RegisterTransactionModel,
    ITransactionBaseTypeModel,
    IRegisterShareClassTransactionModel,
    ISecurityTypeModel
} from "proxy/apiProxy";
import { oProps, IDictionary, getEnumLabels } from "tools/lib/utility";
import ManagedPortfolioSummary from "components/summaries/ManagedPortfolioSummary";
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";
import SecuritySummary from "components/summaries/SecuritySummary";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import { IGetRelationshipSummary, IGetPortfolioSummary, IGetSecuritySummary } from "features/Reference/slice";
import SecuritySelectField from "components/searchers/SecuritySelectField";
import RelationshipSelectField from "components/searchers/RelationshipSelectField";
import RelationshipSummary from "components/summaries/RelationshipSummary";
import ManagedPortfolioSelectField from "components/searchers/ManagedPortfolioSelectField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import { Grid } from "@material-ui/core";

export interface IRegisterTransactionDataProps {
    transaction: RegisterTransactionModel;
    dictionaries: {
        portfolios: Record<string | number, PortfolioSummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
        entities: Record<string | number, EntitySummaryModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
    }
    currencies: IDictionary<ICurrencyModel>;
    onSecurityLoaded: (security: IGetSecuritySummary) => void;
    onRelationshipLoaded: (found: IGetRelationshipSummary) => void;
    onPortfolioLoaded: (found: IGetPortfolioSummary) => void;
}

const registerTradeTypes = getEnumLabels(IRegisterTradeTypeModel);
const transactionBaseTypes = getEnumLabels(ITransactionBaseTypeModel);
export default function RegisterTransactionData({
                                                    onSecurityLoaded,
                                                    onRelationshipLoaded,
                                                    onPortfolioLoaded,
                                                    currencies,
                                                    transaction,
                                                    dictionaries
                                                }: IRegisterTransactionDataProps) {
    const {portfolios, securities} = dictionaries;
    return <Grid container={true} spacing={1}>
        <Grid container={true} item={true} spacing={1}>
            <Grid item={true} sm={6}>
                <ManagedPortfolioSelectField name={oProps<RegisterTransactionModel>().path("portfolioId")}
                                             onSelected={onPortfolioLoaded}/>
                <ManagedPortfolioSummary name={oProps<RegisterTransactionModel>().path("portfolioId")}
                                         portfolios={portfolios} currencies={currencies}/>
            </Grid>
            {transaction.type === "RegisterShareClassTransactionModel" && <Grid item={true} sm={6}>
                <SecuritySelectField label="Shareclass" required={true} type={ISecurityTypeModel.ShareClass}
                                     name={oProps<IRegisterShareClassTransactionModel>().path("shareClassId")}
                                     onSelected={onSecurityLoaded}/>
                <SecuritySummary name={oProps<IRegisterShareClassTransactionModel>().path("shareClassId")}
                                 currencies={currencies} securities={securities}/>
            </Grid>}
        </Grid>
        <Grid container={true} spacing={1} item={true}>
            <Grid item={true} sm={12}>
                <FormMultiCulturedTextField name={oProps<RegisterTransactionModel>().path("comment")} label="Comment"
                                            multiline={true}/>
            </Grid>
            <Grid item={true} sm={12}>
                <FormTextField name={oProps<RegisterTransactionModel>().path("description")} label="Description"/>
            </Grid>
            <Grid item={true} sm={6}>
                <FormTextField label="Code" name={oProps<RegisterTransactionModel>().path("transactionCode")}
                               required={true}/>
            </Grid>
            <Grid item={true} sm={6}>
                <FormTextField label="Register Number"
                               name={oProps<RegisterTransactionModel>().path("registerNumber")}/>
            </Grid>
            <Grid item={true} sm={4}>
                <FormDatePickerField label="Trade Date" name={oProps<RegisterTransactionModel>().path("tradeDate")}
                                     required={true}/>
            </Grid>
            <Grid item={true} sm={4}>
                <FormDatePickerField label="Settlement Date"
                                     name={oProps<RegisterTransactionModel>().path("settlementDate")}/>
            </Grid>
            <Grid item={true} sm={4}>
                <FormCheckBoxField name={oProps<RegisterTransactionModel>().path("canceled")} label="Cancelled"/>
            </Grid>
            <Grid item={true} sm={6}>
                <FormSimpleSelectField label="Order Type" options={registerTradeTypes}
                                       name={oProps<RegisterTransactionModel>().path("orderType")} required={true}/>
            </Grid>
            <Grid item={true} sm={6}>
                <FormSimpleSelectField label="Transaction Base Type" options={transactionBaseTypes}
                                       name={oProps<RegisterTransactionModel>().path("transactionBaseType")}
                                       required={true}/>
            </Grid>
            <Grid item={true} sm={4}>
                <FormDatePickerField label="Nav Date" name={oProps<RegisterTransactionModel>().path("navDate")}
                                     required={true}/>
            </Grid>
            <Grid item={true} sm={4}>
                <FormTextField label="Nb Shares" name={oProps<RegisterTransactionModel>().path("nbShares")}
                               isNumber={true}/>
            </Grid>
            <Grid item={true} sm={4}>
                <FormTextField label="Nav" name={oProps<RegisterTransactionModel>().path("nav")} isNumber={true}/>
            </Grid>
            <Grid item={true} sm={4}>
                <FormTextField label="Gross Amount" name={oProps<RegisterTransactionModel>().path("grossAmount")}
                               isNumber={true}/>
            </Grid>
            <Grid item={true} sm={4}>
                <FormTextField label="Net Amount" name={oProps<RegisterTransactionModel>().path("netAmount")}
                               isNumber={true}/>
            </Grid>
            <Grid item={true} sm={4}>
                <FormTextField label="Equalisation" name={oProps<RegisterTransactionModel>().path("equalisation")}
                               isNumber={true}/>
            </Grid>
            <Grid item={true} sm={6}>
                <RelationshipSelectField type="InvestorRelationshipModel" label="Investor"
                                         name={oProps<RegisterTransactionModel>().path("investorRelationshipId")}
                                         onSelected={onRelationshipLoaded}/>
                <RelationshipSummary
                    name={oProps<RegisterTransactionModel>().path("investorRelationshipId")} {...dictionaries} />
            </Grid>
            <Grid item={true} sm={6}>
                <RelationshipSelectField type="RoleRelationshipModel" label="Intermediary"
                                         name={oProps<RegisterTransactionModel>().path("intermediaryId")}
                                         onSelected={onRelationshipLoaded}/>
                <RelationshipSummary
                    name={oProps<RegisterTransactionModel>().path("intermediaryId")} {...dictionaries} />
            </Grid>
        </Grid>
    </Grid>
}
