import * as React from "react";
import SearchPanel from "tools/components/SearchPanel";
import {
    ISecurityTypeModel,
    isRegularSecuritySummaryModel,
    isSecurityInstrumentSummaryModel,
    SecurityModel,
    SecuritySummaryModel
} from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { Formik, FormikHelpers, FormikProps } from "formik";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps, toList } from "tools/lib/utility";
import { Grid } from "@material-ui/core";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import ClassificationsData, { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import { getSecurityTypeLabel } from "./getSecurityTypeLabel";
import AddIcon from '@material-ui/icons/Add';
import { IActionButton } from "tools/components/FabContainer";
import { getEntityName } from "tools/lib/modelUtils";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";

export const securitiesScreen = {
    route: "/securitymanagement/securities" as const,
    component: Securities,
    label: "Securities",
    keywords: "Universe",
}

interface ISearchSecurityForm {
    criterias?: string;
    classifications?: {
        [key: string]: number;
        [key: number]: number;
    };
}
export default function Securities() {
    const {
        referenceCurrencies = {},
        referenceCountries = {},

    } = useReduxSelections("reference");
    const {
        all: securities,
        allLoading: securityAllLoading,
        dictionaries: {
            subFunds,
            entities
        }
    } = useReduxSelections("security");
    const { securitySearch } = useReduxActions("security");
    const navigate = useScreenNavigate()
    const {
        classificationTypes,
        classificationTypesFlat
    } = useReduxSelections("securityClassificationType");
    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, (secu: SecuritySummaryModel) => isSecurityInstrumentSummaryModel(secu) ? secu.classifications : undefined);

    const columns: IColumnDefinition[] = [
        { name: "Name", title: "Name", getCellValue: (row: SecuritySummaryModel) => row.name, positionToKeep: "left", filteringEnabled: true },
        { name: "InternalCode", title: "Internal Code", getCellValue: (row: SecuritySummaryModel) => row.internalCode, filteringEnabled: true },
        { name: "UnderManagement", title: "Under Management", getCellValue: (row: SecuritySummaryModel) => row.type === "ShareClassSummaryModel" && row.isUnderManagement, columnType: "boolean" },
        { name: "Currency", title: "Currency", getCellValue: (row: SecuritySummaryModel) => row.currencyId && referenceCurrencies[row.currencyId]?.isoCode },
        {
            name: "Country", title: "Country", getCellValue: (secu: SecuritySummaryModel) => {
                let countryId: number | undefined;
                if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
                    if (subFunds[secu.subFundId].countryId) {
                        countryId = subFunds[secu.subFundId].countryId
                    }
                }
                else if (isRegularSecuritySummaryModel(secu)) {
                    countryId = secu.countryId;
                }
                if (!countryId) {
                    return undefined;
                }
                const country = referenceCountries[countryId];
                if (!country) {
                    return "???";
                }
                return country.name?.en;
            }, filteringEnabled: true
        },
        { name: "Isin", title: "Isin", getCellValue: (secu: SecuritySummaryModel) => isSecurityInstrumentSummaryModel(secu) ? secu.isin : undefined, filteringEnabled: true },
        {
            name: "SubFund", title: "SubFund", getCellValue: (secu: SecuritySummaryModel) => {
                return secu.type === "ShareClassSummaryModel" && secu.subFundId ? subFunds[secu.subFundId].name : undefined;
            }, filteringEnabled: true
        },
        {
            name: "Issuer", title: "Issuer", getCellValue: (secu: SecuritySummaryModel) => {
                return isRegularSecuritySummaryModel(secu) && secu.issuerId !== undefined ? getEntityName(entities[secu.issuerId]) : undefined;
            }, filteringEnabled: true
        },
        { name: "Type", title: "Type", getCellValue: (row: SecuritySummaryModel) => getSecurityTypeLabel(row.type) ?? "", filteringEnabled: true },
        ...classificationTypesColumns
    ];
    const state: IGridState = {
        "Name": { width: 300 },
        "InternalCode": { width: 150 },
        "Currency": { width: 130 },
        "Country": { width: 170 },
        "Isin": { width: 150 },
        "SubFund": { width: 200, hidden: true },
        "Issuer": { width: 200, hidden: true },
        "Type": { width: 120 },
        ...classificationTypesState
    };
    const handleRowSelect = (row: SecuritySummaryModel | SecurityModel) => {
        // if ((row.type === "ShareClassModel" || row.type === "ShareClassSummaryModel") && row.isUnderManagement) {
        //     navigate({ screenKey: "MyShareClasses", sectionKey: "detail", parameters: { id: row.id } });
        // }
        // else {
        navigate("Security", { id: row.id });
        // }
    }

    const extraActionButtons: IActionButton[] = React.useMemo(() => ([{
        label: "Add security",
        icon: AddIcon,
        actions: Object.values(ISecurityTypeModel)
            .filter(key => key !== ISecurityTypeModel.Instrument)
            .map(key => ({
                label: getSecurityTypeLabel(key),
                onClick: () => navigate("Security", { id: key }),
            })
            )
    }]), [navigate]);

    const getRowKey = (row: SecuritySummaryModel | SecurityModel) => row.id || 0;
    const handleSubmit = ({ classifications, ...values }: ISearchSecurityForm, { setSubmitting }: FormikHelpers<ISearchSecurityForm>) => {
        const classificationIds = toList(classifications);
        securitySearch({ ...values, classificationIds });
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={{} as ISearchSecurityForm} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;

    function renderForm({ isValid, submitForm }: FormikProps<ISearchSecurityForm>) {
        return <SearchPanel
            isQuerying={securityAllLoading}
            onSearchClick={submitForm}
            searchDisabled={!isValid}
            subTitle="Search securities"
            actions={extraActionButtons}
            title="Securities"
            // onAddClick={handleAddNewClick}
            renderSearch={<Grid container={true} spacing={1}>
                <Grid item={true} sm={12} md={6} lg={4}>
                    <FormTextField name={oProps<ISearchSecurityForm>().path("criterias")} label="Keywords (name, ISIN, internal code)" />
                </Grid>
                <Grid item={true} sm={12} md={12} lg={12}>
                    <ClassificationsData panelTitle="Classifications" fieldName={oProps<ISearchSecurityForm>().path("classifications")} classificationTypes={classificationTypes} />
                </Grid>
            </Grid>}>
            <ExtendedGrid
                rows={securities}
                columns={columns}
                getRowId={getRowKey}
                initialState={state}
                onRowClick={handleRowSelect}
                defaultExportFileName="Securities.xlsx"
                userCanGroup={true} />
        </SearchPanel>
    }
}
