import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import ProcessDefinitionData from "./ProcessDefinitionData";
import ProcessDefinitionTasksData from "./ProcessDefinitionTasksData";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IFeatureModel, IProcessDefinitionModel } from "proxy/apiProxy";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";
import { useNumber } from "tools/lib/utility";
import { isNumber } from "lodash";

export const processDefinitionScreen = {
    route: "/config/processes/definitions/:id/:tab?" as const,
    component: ProcessDefinition,
    label: "Process Definition",
    tabs: {
        "detail": "details",
        "tasks": "tasks"
    }
}

function ProcessDefinition() {
    const {
        processDefinitionDelete,
        processDefinitionSave,
        processDefinitionLoad
    } = useReduxActions("processDefinition");
    const { processDefinitionCurrent, processDefinitionLoading, processDefinitionQuestionnaires } = useReduxSelections("processDefinition");
    const { classificationTypes } = useReduxSelections("processClassificationType");

    const {id, tab: tabValue = "detail"} = useScreenParams<typeof processDefinitionScreen>();
    const idNum = useNumber(id);

    useEffect(() => {
        if (isNumber(idNum)) processDefinitionLoad(idNum);
    }, [idNum, processDefinitionLoad]);

    if (!processDefinitionCurrent) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={false} />;
    }
    const title = !!processDefinitionCurrent.id ? (processDefinitionCurrent.name["en"] ?? processDefinitionCurrent.name.en) : `New Process Definition`;

    const tabs: ISubMenuTabs<typeof processDefinitionScreen>[] = [{
        label: "Data",
        value: "detail"
    }, {
        label: "Tasks",
        value: "tasks"
    }];
    const handleDelete = () => {
        if (processDefinitionCurrent.id) {
            processDefinitionDelete(processDefinitionCurrent.id);
        }
    }

    const handleSubmit = (values: IProcessDefinitionModel, { setSubmitting }: FormikHelpers<IProcessDefinitionModel>) => {
        processDefinitionSave(values);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={processDefinitionCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm }: FormikProps<IProcessDefinitionModel>) {
        if (!processDefinitionCurrent) {
            return null;
        }

        return <WriteAccess value={IFeatureModel.ProcessDefinitionWrite}><DetailPanel
            isQuerying={processDefinitionLoading}
            title={title}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!processDefinitionCurrent.id}
            onDeleteClick={handleDelete}
            badge={!processDefinitionCurrent.id ? "new" : undefined}
            saveAllowed={IFeatureModel.ProcessDefinitionWrite}
            deleteAllowed={IFeatureModel.ProcessDefinitionWrite}
            tabs={tabs}
            tabValue={tabValue}>
            {(tabValue === "detail") && <ProcessDefinitionData classificationTypes={classificationTypes} />}
            {(tabValue === "tasks") && <ProcessDefinitionTasksData questionnaires={processDefinitionQuestionnaires} />}
        </DetailPanel></WriteAccess>
    }
}
