import { IConnectorsModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import { useField } from "formik";
import CodeEditor from "tools/components/CodeEditor";


// https://developers.google.com/fonts/docs/getting_started
export interface IConnectorsScriptProps {
    jsonSchema: object | undefined;
    onSaveRequest: () => void;
};

export default function ConnectorsScript({ jsonSchema, onSaveRequest }: IConnectorsScriptProps) {
    // const modelUri= monaco.Uri.parse("a://b/foo.json")
    const [, { value: script }, { setValue: setScript }] = useField<IConnectorsModel["jsonConfig"]>(oProps<IConnectorsModel>().path("jsonConfig"));
    return <CodeEditor
        code={script}
        onSave={onSaveRequest}
        onCodeChanged={setScript}
        jsonSchema={jsonSchema}
        language="json" />;
}
