import { Box, Paper } from "@material-ui/core";
import { DocumentElementModel, INoteModel } from "proxy/apiProxy";
import { DragObjectWithType } from "react-dnd";
import { TreeItem } from 'react-sortable-tree';
import { useDrag } from 'react-dnd'
import styled from '@emotion/styled';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import NoteText from "./NoteText";

export const dndNoteNodeType = "dndNoteNodeType";

interface IDropResult {
}
interface ICollectedProps {
    isDragging: boolean;
}
interface ITreeItem extends TreeItem {
    row: DocumentElementModel;
    fromOutside?: boolean;
}
interface IMyDragObjectWithType extends DragObjectWithType {
    node: ITreeItem;
}
const NodePaper = styled(Paper)({
    margin: 10,
    padding: 10,
    cursor: "move"
});

interface INoteSourceProps {
    note: INoteModel;
    onDropped: (note: INoteModel) => void;
}
export default function NoteSource({ note, onDropped }: INoteSourceProps) {
    const handleEnd = (elt?: IMyDragObjectWithType) => {
        if (elt) {
            onDropped(note);
        }
    }
    const [collected, drag, dragPreview] = useDrag<IMyDragObjectWithType, IDropResult, ICollectedProps>({
        item: {
            type: dndNoteNodeType,
            node: {
                expanded: false,
                subtitle: "",
                title: "",
                row: {
                    type: "NoteDocumentElementModel",
                    noteCode: note.code
                },
                fromOutside: true,
                children: [],
            },
        },
        options: {
            dropEffect: "move"
        },
        end: handleEnd
    });
    return collected?.isDragging
        ? <div ref={dragPreview} />
        : <NodePaper ref={drag} elevation={2} {...collected}>
            <Box display="flex" alignItems="baseline" gridGap={5} >
                <div style={{ cursor: "move", color: "lightgray" }}><DragIndicatorIcon /></div>
                <NoteText note={note} />
            </Box>
        </NodePaper>
}
