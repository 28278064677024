import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { AutocompleteGetTagProps } from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Chip, Grid, Typography } from "@material-ui/core";

export interface IAsyncMultiSelectProps {
    onSearchOptions: (criteria: string) => void;
    options: number[];
    values: number[];
    label: React.ReactNode;
    getLabelValue: (fromSearchResults: boolean, option: number) => string;
    getSubLabelValue?: (fromSearchResults: boolean, option: number) => string;
    searching?: boolean;
    onValueSelected?: (option: number[]) => void;
    fullWidth?: boolean;
    disabled?: boolean;
    error?: boolean;
    helperText?: React.ReactNode;
}

export default function AsyncMultiSelect({
    label,
    getLabelValue,
    getSubLabelValue,
    searching,
    options,
    onValueSelected,
    onSearchOptions,
    values,
    fullWidth,
    disabled,
    error,
    helperText
}: IAsyncMultiSelectProps) {
    const [open, setOpen] = React.useState(false);
    const [stateOptions, setStateOptions] = React.useState<number[]>([]);
    const [inputValue, setInputValue] = React.useState("");

    React.useEffect(() => {
        setStateOptions(options);
    }, [options, setStateOptions]);

    React.useEffect(() => {
        if (!open) {
            setStateOptions([]);
        }
    }, [open, setStateOptions]);
    const handleOnOpen = () => setOpen(true);
    const handleOnClose = () => setOpen(false);
    const handleChange = (event: object, newValue: number | number[] | null, reason: string) => {
        newValue = newValue ?? [];
        if (!Array.isArray(newValue)) {
            newValue = [newValue];
        }
        if (onValueSelected) {
            onValueSelected(newValue);
        }
        setStateOptions([]);
        setInputValue("");
    };
    const handleInputChange = (event: object, inputValue: string, reason: string) =>
        onSearchOptions(inputValue);
    const renderInput = (params: any) => <TextField
        {...params}
        label={label}
        margin="dense"
        onChange={i => setInputValue(i.target.value)}
        InputProps={{
            ...params.InputProps,
            endAdornment: (<>
                {searching ? (
                    <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
            </>)
        }}
        error={error}
        helperText={helperText} />;

    const handleGetOptionLabel = getLabelValue.bind(null, true);
    const handleGetOptionSubLabel = getSubLabelValue?.bind(null, true);
    const renderOption = (option: number, state: any) => {
        return <Grid>
            {handleGetOptionLabel(option)}
            {handleGetOptionSubLabel && (
                <Typography display="block" variant="overline">
                    {handleGetOptionSubLabel(option)}
                </Typography>
            )}
        </Grid>;
    }

    const handleGetChipLabel = getLabelValue.bind(null, false);
    const renderTags = (tagValue: number[], getTagProps: AutocompleteGetTagProps) =>
        tagValue.map((option, index) => <Chip label={handleGetChipLabel(option)} {...getTagProps({ index })} />);

    const getOptionSelected = (option: any, currentValue: any) => option === currentValue;
    return <Autocomplete
        style={fullWidth ? { width: "100%" } : undefined}
        multiple={true}
        open={open}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        getOptionSelected={getOptionSelected}
        options={stateOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        loading={searching}
        inputValue={inputValue}
        value={values ?? null}
        renderInput={renderInput}
        getOptionLabel={handleGetOptionLabel}
        renderOption={renderOption}
        renderTags={renderTags}
        disabled={disabled} />;
}
