import { MacroScriptModel, MacroScriptSummaryModel, IMacroScriptTypeModel } from "proxy/apiProxy";
import { getEnumLabels } from "tools/lib/utility";

const macroScriptTypes = getEnumLabels(IMacroScriptTypeModel);
export function getMacroScriptTypeLabel(type: MacroScriptModel["type"] | MacroScriptSummaryModel["type"] | IMacroScriptTypeModel): string;
export function getMacroScriptTypeLabel(type: MacroScriptModel["type"] | MacroScriptSummaryModel["type"] | IMacroScriptTypeModel | null): string | null;
export function getMacroScriptTypeLabel(type: MacroScriptModel["type"] | MacroScriptSummaryModel["type"] | IMacroScriptTypeModel | undefined): string | undefined;
export function getMacroScriptTypeLabel(type: MacroScriptModel["type"] | MacroScriptSummaryModel["type"] | IMacroScriptTypeModel | undefined | null): string | null | undefined;
export function getMacroScriptTypeLabel(type: MacroScriptModel["type"] | MacroScriptSummaryModel["type"] | IMacroScriptTypeModel | undefined | null) {
    if (typeof (type) === "undefined") {
        return undefined;
    }
    if (!type) {
        return null;
    }
    switch (type) {
        case IMacroScriptTypeModel.Monitoring:
        case "MonitoringMacroScriptModel":
        case "MonitoringMacroScriptSummaryModel":
            return macroScriptTypes[IMacroScriptTypeModel.Monitoring];
        case IMacroScriptTypeModel.FileProcessor:
        case "FileProcessorMacroScriptModel":
        case "FileProcessorMacroScriptSummaryModel":
            return macroScriptTypes[IMacroScriptTypeModel.FileProcessor];
        case IMacroScriptTypeModel.FileRetriever:
        case "FileRetrieverMacroScriptModel":
        case "FileRetrieverMacroScriptSummaryModel":
            return macroScriptTypes[IMacroScriptTypeModel.FileRetriever];
        case IMacroScriptTypeModel.DataProcessor:
        case "DataProcessorMacroScriptModel":
        case "DataProcessorMacroScriptSummaryModel":
            return macroScriptTypes[IMacroScriptTypeModel.DataProcessor];
        case IMacroScriptTypeModel.MarketDataSelector:
        case "MarketDataSelectorMacroScriptModel":
        case "MarketDataSelectorMacroScriptSummaryModel":
            return macroScriptTypes[IMacroScriptTypeModel.MarketDataSelector];
        case IMacroScriptTypeModel.SubMacro:
        case "SubMacroScriptModel":
        case "SubMacroScriptSummaryModel":
            return macroScriptTypes[IMacroScriptTypeModel.SubMacro];
    }
}
