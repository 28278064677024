import { AnyActionOf } from "tools/lib/store";
import * as AllReducers from './AllReducers';
import {
    reducer as appReducer,
    Action as AppAction,
    ActionFactories as AppActionFactories,
    IState as IAppState
} from 'features/App/slice';
import {
    reducer as tenantManagerReducer,
    Action as TenantManagerAction,
    ActionFactories as TenantManagerActionFactories,
    IState as ITenantManagerState
} from 'features/TenantManager/slice';
import {
    reducer as tenantsAdministrationReducer,
    Action as TenantsAdministrationAction,
    ActionFactories as TenantsAdministrationActionFactories,
    IState as ITenantsAdministrationState
} from 'features/TenantsAdministration/slice';

const aggregatedReducers = Object.keys(AllReducers)
    .sort((a, b) => a > b ? 1 : -1)
    .reduce((a, v) => {
        //@ts-ignore
        a[v] = AllReducers[v].reducer;
        return a;
    }, {} as { [K in keyof typeof AllReducers]: (typeof AllReducers)[K]["reducer"] });

export const reducer = {
    ...aggregatedReducers,
    app: appReducer,
    tenantManager: tenantManagerReducer,
    tenantsAdministration: tenantsAdministrationReducer
};

const aggregatedFactories = Object.keys(AllReducers)
    .reduce((a, v) => {
        //@ts-ignore
        a[v] = AllReducers[v].ActionFactories;
        return a;
    }, {}) as { [K in keyof typeof AllReducers]: (typeof AllReducers)[K]["ActionFactories"] };

export const ActionFactories = {
    ...aggregatedFactories,
    app: AppActionFactories,
    tenantManager: TenantManagerActionFactories,
    tenantsAdministration:TenantsAdministrationActionFactories
};

type aggregatedStates = { [K in keyof typeof AllReducers]: ReturnType<(typeof AllReducers)[K]["reducer"]> };

export type IState = aggregatedStates & {
    app: IAppState,
    tenantManager: ITenantManagerState,
    tenantsAdministration:ITenantsAdministrationState
};

type GroupedActionFactories = { [K in keyof typeof AllReducers]: AnyActionOf<(typeof AllReducers)[K]["ActionFactories"]> };
type AggregatedActionFactories = GroupedActionFactories[keyof GroupedActionFactories];

export type IAnyAction = AggregatedActionFactories
    | AppAction
    | TenantManagerAction
    | TenantsAdministrationAction
