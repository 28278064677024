import produce from "immer";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetPortfolioHistoricalValuesModel, IPortfolioHistoricalValueModel, PortfolioSummaryModel, SecuritySummaryModel, EntitySummaryModel } from "proxy/apiProxy";
import { IDailyDataSearchHistoricalValuesParameters } from "proxy/apiProxy";
import { IGetPortfoliosSummary } from "features/Reference/slice";

export const ActionFactories = produceActionFactories({
  portfolioHistoricalValueSearch: (payload: IDailyDataSearchHistoricalValuesParameters) => payload,
  portfolioHistoricalValueLoaded: (payload: IGetPortfolioHistoricalValuesModel) => payload,
  portfolioHistoricalValueAddPortfoliosToDictionary: (payload: IGetPortfoliosSummary) => payload
});
// export type ICounter2Action = IPayloadAction<ActionType, IChangeCounter2Payload>;

export interface IState {
  portfolioHistoricalValuesLoading: boolean;
  portfolioHistoricalValues: IPortfolioHistoricalValueModel[];
  dictionaries: {
    entities: Record<string | number, EntitySummaryModel>;
    portfolios: Record<string | number, PortfolioSummaryModel>;
    securities: Record<string | number, SecuritySummaryModel>;
  }
}

export function reducer(
  state: IState = {
    portfolioHistoricalValuesLoading: false,
    dictionaries: {
      entities: {},
      portfolios: {},
      securities: {}
    },
    portfolioHistoricalValues: []
  },
  action: AnyActionOf<typeof ActionFactories>
): IState {
  return produce(state, draft => {
    switch (action.type) {
      case "portfolioHistoricalValueSearch":
        draft.portfolioHistoricalValuesLoading = true;
        break;
      case "portfolioHistoricalValueLoaded":
        draft.portfolioHistoricalValuesLoading = false;
        const { historicalValues, ...dictionaries } = action.payload;
        draft.dictionaries = { ...draft.dictionaries, ...dictionaries };
        draft.portfolioHistoricalValues = historicalValues;
        break;
      case 'portfolioHistoricalValueAddPortfoliosToDictionary':
        draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
        draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
        break;
    }
  });
}
