import { useCallback, useEffect, useRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor';
import 'stimulsoft-reports-js/Css/stimulsoft.designer.office2013.whiteblue.css';
import 'stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css';
import { ISuccessDatasetResultModel, IValueMacroTypeModel, MacroSymbolModel } from 'proxy/apiProxy';
import { INotControllableEditorProps, useNotControllableEditor } from "./INotControllableEditorProps";

export interface IReportDesignerProps extends INotControllableEditorProps<string> {
    onPreviewDataRequest?: () => void;
    dataResult?: ISuccessDatasetResultModel;
    // previewData?: any;
    // dictionary?: MacroSymbolModel;
}
export default function ReportDesigner(props: IReportDesignerProps) {
    const { onPreviewDataRequest, dataResult } = props;
    const designerInstanceRef = useRef<Stimulsoft.Designer.StiDesigner | undefined>();
    const reportRef = useRef<Stimulsoft.Report.StiReport | undefined>();
    useEffect(() => {
        if (designerInstanceRef.current) {
            return;
        }
        setupStimulsoft();
        const options = new Stimulsoft.Designer.StiDesignerOptions();
        options.height = "100%";
        options.appearance.showSaveDialog = false;
        options.toolbar.showFileMenu = true;
        options.appearance.allowChangeWindowTitle = false;

        const designer = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);
        designer.renderHtml(STIMULSOFT_REPORT_DESIGNER_ID);
        const report = new Stimulsoft.Report.StiReport();
        reportRef.current = report;
        designer.report = report;

        designerInstanceRef.current = designer;
        return () => {
            designerInstanceRef.current = undefined;
            // reportRef.current = undefined;
        }
    }, []);
    useEffect(() => {
        const designer = designerInstanceRef.current;
        if (designer == null) {
            return;
        }
        designer.onOpenedReport = ({ report }) => {
            reportRef.current = report;
        };

        if (onPreviewDataRequest) {
            designer.onPreviewReport = (_, __) => onPreviewDataRequest();
        }
        else {
            designer.onPreviewReport = () => { };
        }
        return () => {
            designer.onPreviewReport = () => { };
            // reportRef.current = undefined;
        }
    }, [onPreviewDataRequest, reportRef]);
    useEffect(() => {
        const designer = designerInstanceRef.current;
        if (designer == null) {
            return;
        }
        // if (previewData && designer) {
        //     dataSet.readJson(previewData);
        //     // dataSet.readJson(JSON.stringify(previewData));
        //     // designer.report.dictionary.dataSources.clear();
        //     designer.report.dictionary.databases.clear();
        //     designer.report.regData(dataSet.dataSetName, dataSet.dataSetName, dataSet);
        // }
        if (reportRef.current && dataResult?.returnType && dataResult.result) {
            reportRef.current.dictionary.databases.clear();
            reportRef.current.dictionary.dataSources.clear();
            const dataSet = new Stimulsoft.System.Data.DataSet('DataUniverse');
            const dataTables = createDataTables(dataResult.returnType);
            dataSet.tables.addRange(dataTables);
            dataSet.readJson(dataResult.result);
            reportRef.current.regData("DataUniverse", "DataUniverse", dataSet);
            reportRef.current.dictionary.synchronize();

            // for (const dataTable of dataSet) {
            //     reportRef.current.dictionary.dataSources.add(dataTable);
            //     reportRef.current.dictionary.synchronize();
            // }
        }
        return () => {

        }
    }, [dataResult]);
    const setter = useCallback((v?: string) => {
        const report = new Stimulsoft.Report.StiReport();
        if (v !== "") {
            report.load(v);
        }
        reportRef.current = report;
        if (designerInstanceRef.current && designerInstanceRef.current.report.reportGuid !== report.reportGuid) {
            designerInstanceRef.current.report = report;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getter = useCallback(() => {
        const ret = reportRef.current?.saveToJsonString();
        return ret;
    }, []);
    useNotControllableEditor(props, setter, getter);

    return <div id={STIMULSOFT_REPORT_DESIGNER_ID} style={{ height: "100%" }}>
        <CircularProgress />Loading the designer...
    </div>;
}

const STIMULSOFT_REPORT_DESIGNER_ID = "stimulsoftReportDesigner";


function setupStimulsoft() {
    Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHlqGYrMYOCgyJZDRydZwecgtyVDLAAgKLWgo9+QqEAHZNIieS" +
        "9a6oI/EyGl1aJrmumDxCh27KXqc6jez3Q5WCRxXFP+vnBW+Wt8kO1b1qRFOsF1BxNavi+2tbch3UQmU8X05qgtWDVe" +
        "6ptHvsVTiuiPRhxkDEjv9+Xab/EuYBKsj3YkH59xLGWV99dtOrHLnN7ohut08dUqGB6bYEraBfn8xkUr7TAFfn9SVS" +
        "bSgNXjB55XsVzCd9eRS6253dK/DlmwLNoDmqNkhCzFBgxtPugRqJVbjG8Hpz9Qsl/DkvuI32bzyPoqsNu0rR+iMj2O" +
        "JGdFsyZxXvNu+4hy8Fvo/gQXr4lwY6xaPmjahv/1GlxW+0pYyTmGj6/RiP0qXZpi6927r0pLa0ErN0Sc03b5soo/1H" +
        "xA3O3mYctAY0sV+MCaZ9nQHbBDNRIdWvqEIW0OyiI2Ze38Hw2ZvgaSKVUC0bqobraYsdiZwmkoByFjPqRTA7/e5+px" +
        "Zj0orHWYHUfptgsfzMF8H177EGYkmREVBulDJYr9Snjjj5ZteSXNQUiMg+ilfHVjRul4W2UG8tBbRNa3OMoYVmz02R" +
        "lu2H1EO1Dk/cjc1pDrvVYt8q4ZbFp4bRK3y3eOQazJmblk6atPEd8BHTHjsxMZvbUfVb7PX1ch";
    const stringCategory = "Strings";

    const getTranslationParamNames = ["culturedString"];
    const getTranslationParamTypes = [String];
    const getTranslationParamDescriptions = ["Fund process cultured string in JSON format"];

    Stimulsoft.Report.Dictionary.StiFunctions.removeFunction("GetTranslation");
    Stimulsoft.Report.Dictionary.StiFunctions.addFunction(stringCategory, "GetTranslation", "GetTranslation", "Get the translation from a FundProcess JSON cultured string.",
        "", String, "Translation", getTranslationParamTypes, getTranslationParamNames, getTranslationParamDescriptions, (culturedString: string) => {
            const language = "en";
            if (!culturedString) {
                return "";
            }
            try {
                const dico = JSON.parse(culturedString);
                if (!dico) {
                    return "";
                }
                return dico[language] ?? dico.en ?? (Object.values(dico).length ? Object.values(dico)[0] : "");
            }
            catch {
                return "";
            }
        });
    Stimulsoft.Report.Dictionary.StiFunctions.removeFunction("GetEnumValue");


    const getEnumValueParamNames = ["enumeration", "value", "translations"];
    const getEnumValueParamTypes = [String, Number, String];
    const getEnumValueParamDescriptions = ["Name of the enumeration to get the label from", "Value you want to get the label for", "The translations definitions that contains the expected label"];

    Stimulsoft.Report.Dictionary.StiFunctions.addFunction(stringCategory, "GetEnumValue", "GetEnumValue", "Get the text label of an enumeration.",
        "", String, "TextLabel", getEnumValueParamTypes, getEnumValueParamNames, getEnumValueParamDescriptions, (enumeration: string, value: number, translations: string) => {
            const language = "en";
            if (!translations) {
                return "";
            }
            try {
                const dico = JSON.parse(translations);
                if (!dico) {
                    return "";
                }
                const enumDefinition = dico[enumeration];
                if (!enumDefinition) {
                    return "";
                }
                const valueDefinition = enumDefinition[value];
                if (!valueDefinition) {
                    return "";
                }
                return valueDefinition[language] ?? valueDefinition.en ?? "";
            }
            catch {
                return "";
            }
        });
}
function createStiDataTable(dataTableName: string, enumeratedTypeProperties: Record<string | number, MacroSymbolModel>): Stimulsoft.System.Data.DataTable {
    const dataTable = new Stimulsoft.System.Data.DataTable(dataTableName);
    for (const key in enumeratedTypeProperties) {
        if (Object.prototype.hasOwnProperty.call(enumeratedTypeProperties, key)) {
            const element = enumeratedTypeProperties[key];
            const type = (function () {
                if (element.type !== "ValueMacroSymbolModel") {
                    return null;
                }
                switch (element.valueType) {
                    case IValueMacroTypeModel.Boolean: return element.nullable ? Stimulsoft.System.NullableBoolean : Stimulsoft.System.StiBoolean;
                    case IValueMacroTypeModel.Byte: return element.nullable ? Stimulsoft.System.NullableByte : Stimulsoft.System.Byte;
                    case IValueMacroTypeModel.Char: return element.nullable ? Stimulsoft.System.NullableChar : Stimulsoft.System.Char;
                    case IValueMacroTypeModel.DateTime: return element.nullable ? Stimulsoft.System.NullableDateTime : Stimulsoft.System.DateTime;
                    case IValueMacroTypeModel.Decimal: return element.nullable ? Stimulsoft.System.NullableDecimal : Stimulsoft.System.Decimal;
                    case IValueMacroTypeModel.Double: return element.nullable ? Stimulsoft.System.NullableDouble : Stimulsoft.System.Double;
                    case IValueMacroTypeModel.Guid: return element.nullable ? Stimulsoft.System.NullableGuid : Stimulsoft.System.Guid;
                    case IValueMacroTypeModel.Int16: return element.nullable ? Stimulsoft.System.NullableInt16 : Stimulsoft.System.Int16;
                    case IValueMacroTypeModel.Int32: return element.nullable ? Stimulsoft.System.NullableInt32 : Stimulsoft.System.Int32;
                    case IValueMacroTypeModel.Int64: return element.nullable ? Stimulsoft.System.NullableInt64 : Stimulsoft.System.Int64;
                    case IValueMacroTypeModel.SByte: return element.nullable ? Stimulsoft.System.NullableSByte : Stimulsoft.System.SByte;
                    case IValueMacroTypeModel.Single: return element.nullable ? Stimulsoft.System.NullableSingle : Stimulsoft.System.Single;
                    case IValueMacroTypeModel.String: return element.nullable ? Stimulsoft.System.StiString : Stimulsoft.System.StiString;
                    case IValueMacroTypeModel.UInt16: return element.nullable ? Stimulsoft.System.NullableUInt16 : Stimulsoft.System.UInt16;
                    case IValueMacroTypeModel.UInt32: return element.nullable ? Stimulsoft.System.NullableUInt32 : Stimulsoft.System.UInt32;
                    case IValueMacroTypeModel.UInt64: return element.nullable ? Stimulsoft.System.NullableUInt64 : Stimulsoft.System.UInt64;
                    default: return null;
                }
            })();
            if (type !== null) {
                dataTable.columns.add(new Stimulsoft.System.Data.DataColumn(key, type, key));
            }
        }
    }
    return dataTable;
}
function createDataTables(macroSymbolModel?: MacroSymbolModel): Stimulsoft.System.Data.DataTable[] {

    const dataSet: Stimulsoft.System.Data.DataTable[] = [];
    if (macroSymbolModel?.type === "ObjectMacroSymbolModel") {
        for (const key in macroSymbolModel.properties) {
            if (Object.prototype.hasOwnProperty.call(macroSymbolModel.properties, key)) {
                const element = macroSymbolModel.properties[key];
                if (element?.type === "EnumerableMacroSymbolModel" && element.enumeratedType?.type === "ObjectMacroSymbolModel") {
                    dataSet.push(createStiDataTable(key, element.enumeratedType.properties));
                }
            }
        }
    }
    return dataSet;
}
