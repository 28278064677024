import { useCallback } from "react"
import { Button, Card, CardActions, CardContent, Fab } from "@material-ui/core";
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps } from "tools/lib/utility";
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import AddIcon from '@material-ui/icons/Add';
import StickyContainer from "tools/components/StickyContainer";
import FieldBox from "tools/components/FieldBox";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import {
    IProcessDefinitionModel,
    IQuestionnaireDevelopmentItemSummaryModel,
    IQuestionnaireProcessDefinitionTaskModel,
    ProcessDefinitionTaskModel
} from "proxy/apiProxy";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import DeleteIcon from "@material-ui/icons/Delete"
import UpIcon from "@material-ui/icons/ArrowUpward"
import DownIcon from "@material-ui/icons/ArrowDownward"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"


export interface IProcessDefinitionTasksDataProps {
    questionnaires: IQuestionnaireDevelopmentItemSummaryModel[]
}

export default function ProcessDefinitionTasksData({ questionnaires }: IProcessDefinitionTasksDataProps) {
    const [{ value: tasks = [] }] = useField<ProcessDefinitionTaskModel[]>(oProps<IProcessDefinitionModel>().path("tasks"));

    return <FieldArray name={oProps<IProcessDefinitionModel>().path("tasks")} render={renderTasksContent}
                       validateOnChange={true} />;

    function renderTasksContent({ push, remove, swap }: FieldArrayRenderProps): React.ReactNode {

        const handlePush = () => {
            push({
                code: "",
                name: {},
                isMandatory: true,
                isPortalTask: false,
                type: "SimpleProcessDefinitionTaskModel"
            } as ProcessDefinitionTaskModel);
        }
        return (<ReadOnlyContext.Consumer>{readOnly => <>
            {tasks.map((_, index) => <DueDiligenceTask
                key={index}
                index={index}
                onDelete={remove}
                readOnly={readOnly}
                task={tasks[index]}
                questionnaires={questionnaires}
                enableUp={index > 0}
                enableDown={index < tasks.length - 1}
                swap={swap}
            />)}
            {!readOnly && <StickyContainer>
                <Fab color="primary" aria-label="Add" onClick={handlePush}>
                    <AddIcon />
                </Fab>
            </StickyContainer>}
        </>}</ReadOnlyContext.Consumer>);
    }
}
const taskTypes: Record<ProcessDefinitionTaskModel["type"], string> = {
    "DocumentProcessDefinitionTaskModel": "Document",
    "QuestionnaireProcessDefinitionTaskModel": "Questionnaire",
    "SimpleProcessDefinitionTaskModel": "Simple check"
}

interface IDueDiligenceTaskProps {
    index: number;
    onDelete: (index: number) => void;
    readOnly: boolean;
    task: ProcessDefinitionTaskModel;
    questionnaires: IQuestionnaireDevelopmentItemSummaryModel[];
    enableUp: boolean;
    enableDown: boolean;
    swap: (indexA: number, indexB: number) => void;
}

const useStyles = makeStyles(theme => ({
    hiddenButton: {
        visibility: "hidden"
    }
}))

function DueDiligenceTask(
    { index, onDelete, readOnly, task: { type }, questionnaires, swap, enableUp, enableDown }: IDueDiligenceTaskProps) {

    const handleDelete = useCallback(() => onDelete(index), [index, onDelete])
    const handleUp = useCallback(() => enableUp && swap(index, index - 1), [enableUp, index, swap])
    const handleDown = useCallback(() => enableDown && swap(index, index + 1), [enableDown, index, swap])

    const classes = useStyles()

    const path = oProps<IProcessDefinitionModel>().path("tasks", index);
    return <Card style={{ marginTop: 16 }} elevation={3}>
        <CardContent>
            <FieldBox display="flex" flexDirection="column">
                <FieldBox display="flex" flexDirection="row">
                    <FormSimpleSelectField name={oProps<ProcessDefinitionTaskModel>(path).path("type")} label="Type" options={taskTypes} required />
                    <FormTextField name={oProps<ProcessDefinitionTaskModel>(path).path("code")} label="Code" required={true} maxLength={100} />
                    <FormMultiCulturedTextField name={oProps<ProcessDefinitionTaskModel>(path).path("name")} label="Name" required={["en"]} />
                </FieldBox>
                <FieldBox display="flex" flexDirection="row">
                    {type === "QuestionnaireProcessDefinitionTaskModel" && <FormSimpleSelectField name={oProps<IQuestionnaireProcessDefinitionTaskModel>(path).path("questionnaireId")} label="Questionnaire" options={questionnaires} required />}
                    <FormCheckBoxField name={oProps<ProcessDefinitionTaskModel>(path).path("isMandatory")} label="Mandatory" />
                    <FormCheckBoxField name={oProps<ProcessDefinitionTaskModel>(path).path("isPortalTask")} label="Portal Task" />
                </FieldBox>
                <FormMultiCulturedTextField name={oProps<ProcessDefinitionTaskModel>(path).path("description")} label="Description" multiline={true} />
            </FieldBox>
        </CardContent>
        {!readOnly && <CardActions>
            <Button startIcon={<DeleteIcon />} onClick={handleDelete}>Delete</Button>
            <Button startIcon={<UpIcon />}
                    className={clsx(classes.hiddenButton)}
                    onClick={handleUp}>
                Move Before
            </Button>
            <Button startIcon={<DownIcon />}
                    className={clsx(classes.hiddenButton)}
                    onClick={handleDown}>
                Move After
            </Button>
        </CardActions>}
    </Card>;
}