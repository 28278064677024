import { Epic } from "redux-observable";
import { merge, of } from "rxjs";
import { filter, map, mergeMap, share, switchMap } from "rxjs/operators";
import {
    customScreensApi,
    IFrequencyTypeModel,
    IGetSubFundModel,
    ISubFundModel,
    macroScriptsApi,
    subFundsApi
} from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload, withLatestFromBuffer } from "lib/rxJsUtility";

export const loadEntities: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("subFund", "subfundLoadAll"),
        mergeMap(subFundsApi.getAllAsync),
        map(ActionFactories.subFund.subfundLoadedAll));

export const loadEntityHistoricalValues: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("subFund", "subfundHistoricalValuesLoad"),
        mergeMap(id => subFundsApi.getHistoricalValuesAsync({ id, mainShareClass: false })),
        map(ActionFactories.subFund.subfundHistoricalValuesLoaded));

export const loadProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("subFund", "subfundProcessExecutionsLoad"),
        mergeMap(id => subFundsApi.getProcessesAsync({ id })),
        map(ActionFactories.subFund.subfundProcessExecutionsLoaded));

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("subFund", "subfundDocumentsLoad"),
        mergeMap(id => subFundsApi.getDocumentsAsync({ id })),
        map(ActionFactories.cashMovement.cashMovementDocumentsLoaded));

export const submitCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("subFund", "subfundCustomScreenDataSubmit"),
        mergeMap(subFundsApi.submitCustomScreenDataAsync),
        map(ActionFactories.subFund.subfundCustomScreenDataSubmitted));
export const loadCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("subFund", "subfundCustomScreenDatasLoad"),
        mergeMap(id => subFundsApi.getLastCustomScreenDatasAsync({ id })),
        map(ActionFactories.subFund.subfundCustomScreenDatasLoaded));
export const loadPortfolio: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("subFund", "subfundLoad"),
            share());

        const customScreens$ = requestedId$.pipe(
            mergeMap(() => customScreensApi.getAllAsync({})),
            map(customScreens => customScreens.filter(customScreen => customScreen.type === "SubFundCustomScreenSummaryModel")),
            mergeMap(customScreens => Promise.all(customScreens.map(({ id }) => customScreensApi.getAsync({ id })))),
            map(ActionFactories.subFund.subfundCustomScreensLoaded));

        return merge(
            requestedId$.pipe(
                filter(id => typeof id === "number" && !!id),
                map(id => ActionFactories.subFund.subfundCustomScreenDatasLoad(typeof id === "number" ? id : 0))),
            customScreens$,
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => subFundsApi.getAsync({ id })),
                map(ActionFactories.subFund.subfundLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    subFund: createEmptySubfund(),
                    entities: {},
                    securities: {}
                } as IGetSubFundModel)),
                map(ActionFactories.subFund.subfundLoaded)));
    }

export const getMonitoringResultLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("subFund", "subfundMonitoringResultLoad"),
        mergeMap(macroScriptsApi.getMonitoringResultForTargetAsync),
        map(ActionFactories.subFund.subfundMonitoringResultLoaded));


function createEmptySubfund(): ISubFundModel {
    return { id: 0, internalCode: "", name: "", shortName: "", classifications: {}, shareClassesId: [], pricingFrequency: IFrequencyTypeModel.Daily, isUnderManagement: false, subFundExtensionFieldsValues: {} };
}

export const savePortfolio: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("subFund", "subfundSave"),
        mergeMap(model => subFundsApi.saveAsync({ model })),
        map(ActionFactories.subFund.subfundSaved));

export const deletePortfolio: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("subFund", "subfundDelete"),
            mergeMap(id => subFundsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.subFund.subfundDeleted));
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }

export const loadPricingDates: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("subFund", "subfundPricingDatesLoad"),
        mergeMap(id => subFundsApi.getPricingDatesAsync({ id })),
        map(ActionFactories.subFund.subfundPricingDatesLoaded));

export const loadPricingDateData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("subFund", "subfundPricingDatesLoaded"),
        map(pricingDates => {
            if (pricingDates.length === 0) {
                return;
            }
            return pricingDates.reduce((agg, v) => {
                if ((agg ? agg.getTime() : 0) - (v ? v.getTime() : 0) > 0) {
                    return agg;
                }
                return v;
            }, undefined as Date | undefined);
        }),
        filter(i => !!i),
        map(date => ActionFactories.subFund.subfundPricingDateLoad(date as Date)));

export const loadPricingDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("subFund", "subfundPricingDateLoad"),
        withLatestFromBuffer(action$.pipe(mapToPayload("subFund", "subfundLoad"), filter(id => typeof id === "number"))),
        switchMap(p => {
            const [pricingDate, key] = p;
            const id = key as number;
            const subfundComposition$ = of({ pricingDate, id }).pipe(mergeMap(subFundsApi.getCompositionAsync));
            return merge(
                subfundComposition$.pipe(map(() => ActionFactories.subFund.subfundPricingDateLoaded(pricingDate))),
                subfundComposition$.pipe(map(ActionFactories.subFund.subfundCompositionLoaded)));
        }));
