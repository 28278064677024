/**
 * Formats a Date object into a string in 'YYYYMMDD' format.
 *
 * @param {Date} d - The Date object to format.
 * @returns {string} The formatted date string in 'YYYYMMDD' format.
 */
export function formatDate(d: Date): string {
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');

    return `${year}${month}${day}`;
}