import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { map, mergeMap, share } from "rxjs/operators";
import { studioTradeDatesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";


export const loadConnector: Epic<IAnyAction>
    = (action$) => {
        const requestedId$ = action$.pipe(
            mapToPayload("globalPortfolioDealing", "globalPortfolioDealingLoad"),
            share());
        const portfolioDealingMacro$ = requestedId$.pipe(
            mergeMap(() => studioTradeDatesApi.getGlobalMacroAsync()),
            share());
        return merge(
            portfolioDealingMacro$.pipe(map(ActionFactories.globalPortfolioDealing.globalPortfolioDealingLoaded)),
            portfolioDealingMacro$.pipe(map(({ script }) => ActionFactories.globalPortfolioDealing.globalPortfolioDealingValidateScript(script))),
            requestedId$.pipe(
                mergeMap(() => studioTradeDatesApi.getUniverseStructureAsync()),
                map(ActionFactories.globalPortfolioDealing.globalPortfolioDealingMetadataLoaded)));
    };
export const saveConnector: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("globalPortfolioDealing", "globalPortfolioDealingSave"),
        mergeMap(model => studioTradeDatesApi.saveGlobalMacroAsync({ dealingDefinition: model, publish: true })),
        map(ActionFactories.globalPortfolioDealing.globalPortfolioDealingSaved));

export const checkMacro: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("globalPortfolioDealing", "globalPortfolioDealingValidateScript"),
        mergeMap(script => studioTradeDatesApi.checkScriptAsync({ textModel: { text: script } })),
        map(result => ActionFactories.globalPortfolioDealing.globalPortfolioDealingValidatedScript(result)));
