import { ICashMovementModel, IFeatureModel } from "proxy/apiProxy";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { ICashMovementsSearchParameters } from "proxy/apiProxy";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { IErrors, oProps, toList } from "tools/lib/utility";
import FieldBox from "tools/components/FieldBox";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import ManagedPortfolioMultiSelectField from "components/searchers/ManagedPortfolioMultiSelectField";
import ClassificationsData, { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import { getEntityName } from "tools/lib/modelUtils";
import React from "react";
import { useScreenNavigate } from "../../tools/routing/screenRouteHooks";

export const cashMovementsScreen = {
    component: CashMovements,
    label: "Cash Movements",
    route: "/portfoliodata/cashMovements" as const,
}

interface ISearchCashMovementForm extends Omit<ICashMovementsSearchParameters, "classificationIds"> {
    classifications?: {
        [key: string]: number;
        [key: number]: number;
    };
}

function CashMovements() {
    const { cashMovementsSearch, cashMovementsAddPortfoliosToDictionary } = useReduxActions("cashMovement");
    const { cashMovements, dictionaries, cashMovementsLoading } = useReduxSelections("cashMovement");
    const { portfolios, securities, entities } = dictionaries;
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    const {
        classificationTypes: movementClassificationTypes,
        classificationTypesFlat: movementClassificationTypesFlat
    } = useReduxSelections("movementClassificationType");
    const navigate = useScreenNavigate();
    const { classificationTypesColumns, classificationTypesState } = React.useMemo(() => getClassificationTypesColumnDefinitions(movementClassificationTypes, movementClassificationTypesFlat, ({ classifications }: ICashMovementModel) => classifications), [movementClassificationTypes, movementClassificationTypesFlat]);
    const columns: IColumnDefinition[] = [
        { name: "PortfolioId", title: "Portfolio", getCellValue: ({ portfolioId }: ICashMovementModel) => portfolios[portfolioId ?? 0]?.name, filteringEnabled: true },
        { name: "PortfolioCode", title: "Portfolio Code", getCellValue: ({ portfolioId }: ICashMovementModel) => portfolios[portfolioId ?? 0]?.internalCode, filteringEnabled: true },
        { name: "ValueDate", title: "Value Date", getCellValue: ({ valueDate }: ICashMovementModel) => valueDate, columnType: "date", filteringEnabled: true },
        { name: "CurrencyId", title: "Currency", getCellValue: ({ currencyId }: ICashMovementModel) => referenceCurrencies[currencyId ?? 0]?.isoCode, filteringEnabled: true },
        { name: "CounterpartyId", title: "Counterparty", getCellValue: ({ counterpartyId }: ICashMovementModel) => getEntityName(entities[counterpartyId ?? 0]), filteringEnabled: true },
        { name: "CashId", title: "Cash Account", getCellValue: ({ cashId }: ICashMovementModel) => securities[cashId ?? 0]?.name, filteringEnabled: true },
        { name: "ClosingDate", title: "Closing Date", getCellValue: ({ closingDate }: ICashMovementModel) => closingDate, columnType: "date", filteringEnabled: true },
        { name: "TradeDate", title: "Trade Date", getCellValue: ({ tradeDate }: ICashMovementModel) => tradeDate, columnType: "date", filteringEnabled: true },
        { name: "Description", title: "Description", getCellValue: ({ description }: ICashMovementModel) => description, filteringEnabled: true },
        { name: "ExternalTransactionCode", title: "External Transaction Code", getCellValue: ({ externalTransactionCode }: ICashMovementModel) => externalTransactionCode, filteringEnabled: true },
        { name: "BrokerageFeesInSecurityCcy", title: "Brokerage Fees Sec Ccy", getCellValue: ({ brokerageFeesInSecurityCcy }: ICashMovementModel) => brokerageFeesInSecurityCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "FeesInSecurityCcy", title: "Fees Sec Ccy", getCellValue: ({ feesInSecurityCcy }: ICashMovementModel) => feesInSecurityCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "GrossAmountInSecurityCcy", title: "Gross Amount Sec Ccy", getCellValue: ({ grossAmountInSecurityCcy }: ICashMovementModel) => grossAmountInSecurityCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "GrossAmountInPortfolioCcy", title: "Gross Amount Prtf Ccy", getCellValue: ({ grossAmountInPortfolioCcy }: ICashMovementModel) => grossAmountInPortfolioCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "NetAmountInSecurityCcy", title: "Net Amount Sec Ccy", getCellValue: ({ netAmountInSecurityCcy }: ICashMovementModel) => netAmountInSecurityCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "NetAmountInPortfolioCcy", title: "Net Amount Prtf Ccy", getCellValue: ({ netAmountInPortfolioCcy }: ICashMovementModel) => netAmountInPortfolioCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "PriceInSecurityCcy", title: "Price Sec Ccy", getCellValue: ({ priceInSecurityCcy }: ICashMovementModel) => priceInSecurityCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "Quantity", title: "Quantity", getCellValue: ({ quantity }: ICashMovementModel) => quantity, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "Reversal", title: "Reversal", getCellValue: ({ reversal }: ICashMovementModel) => reversal, columnType: "boolean", filteringEnabled: true },
        { name: "TaxesInSecurityCcy", title: "Taxes Sec Ccy", getCellValue: ({ taxesInSecurityCcy }: ICashMovementModel) => taxesInSecurityCcy, columnType: "decimal", aggregationType: "sum", filteringEnabled: true },
        { name: "TransactionCode", title: "Transaction Code", getCellValue: ({ transactionCode }: ICashMovementModel) => transactionCode, filteringEnabled: true },
        { name: "MovementCode", title: "Movement Code", getCellValue: ({ movementCode }: ICashMovementModel) => movementCode, filteringEnabled: true },
        { name: "UnderlyingSecurityId", title: "Underlying Security", getCellValue: ({ underlyingSecurityId }: ICashMovementModel) => securities[underlyingSecurityId ?? 0]?.name, filteringEnabled: true },
        ...classificationTypesColumns
    ];
    const state: IGridState = {
        "PortfolioId": { width: 200 },
        "PortfolioCode": { width: 150 },
        "MovementCode": { width: 150 },
        "TransactionCode": { width: 150, hidden: true },
        "TransactionType": { width: 180, hidden: true },
        "Description": { width: 280 },
        "TradeDate": { width: 100 },
        "ValueDate": { width: 100, hidden: true },
        "ClosingDate": { width: 100, hidden: true },
        "CurrencyId": { width: 120 },
        "CounterpartyId": { width: 280 },
        "NetAmountInSecurityCcy": { width: 180 },
        "NetAmountInPortfolioCcy": { width: 180 },
        "UnderlyingSecurityId": { width: 280 },
        "CashId": { width: 200, hidden: true },
        "Reversal": { width: 75, hidden: true },
        "GrossAmountInSecurityCcy": { width: 180, hidden: true },
        "GrossAmountInPortfolioCcy": { width: 180, hidden: true },
        "ExternalTransactionCode": { width: 150, hidden: true },
        "BrokerageFeesInSecurityCcy": { width: 200, hidden: true },
        "FeesInSecurityCcy": { width: 180, hidden: true },
        "PriceInSecurityCcy": { width: 180, hidden: true },
        "Quantity": { width: 180, hidden: true },
        "TaxesInSecurityCcy": { width: 180, hidden: true },
        ...classificationTypesState
    };

    const getRowKey = (row: ICashMovementModel) => row.id;

    const handleSubmit = ({ classifications, ...values }: ISearchCashMovementForm, { setSubmitting }: FormikHelpers<ISearchCashMovementForm>) => {
        const classificationIds = toList(classifications);
        cashMovementsSearch({ ...values, classificationIds });
        setSubmitting(false);
    }
    const handleValidate = (values: ISearchCashMovementForm) => {
        const errors: IErrors<ISearchCashMovementForm> = {};
        if (!values.lastOnly) {
            if (!values.from) {
                errors.from = "From date is required";
            }
            if (values.to && values.from && values.from > values.to) {
                errors.to = "Must be greater than the start of period";
            }
        }
        return errors;
    }

    const handleAddNewClick = () => navigate("CashMovement", { id: 0 });
    const handleRowSelect = (row: ICashMovementModel) => navigate("CashMovement", { id: row.id });
    const monthToDate = (function () {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    })();

    return <Formik onSubmit={handleSubmit} validate={handleValidate} initialValues={{ lastOnly: true, from: monthToDate } as ISearchCashMovementForm} enableReinitialize={true} validateOnMount={true}>{renderForm}</Formik>;
    function renderForm({ isValid, submitForm, values: { lastOnly } }: FormikProps<ISearchCashMovementForm>) {
        return <SearchPanel
            isQuerying={cashMovementsLoading}
            onSearchClick={submitForm}
            onAddClick={handleAddNewClick}
            addAllowed={IFeatureModel.ManagedPortfolioWrite}
            searchDisabled={!isValid}
            title="Cash Movements"
            subTitle="Search by portfolio and time period"
            renderSearch={(<FieldBox display="flex" flexDirection="column">
                <FieldBox display="flex" flexGrow="1">
                    <ManagedPortfolioMultiSelectField
                        name={oProps<ISearchCashMovementForm>().path("portfolioIds")}
                        label="Portfolios"
                        dictionaries={dictionaries}
                        onSelected={cashMovementsAddPortfoliosToDictionary} />
                </FieldBox>
                <FieldBox display="flex" flexDirection="column">
                    <ClassificationsData panelTitle="Classifications" fieldName={oProps<ISearchCashMovementForm>().path("classifications")} classificationTypes={movementClassificationTypes} />
                </FieldBox>
                <FieldBox display="flex" flexDirection="row">
                    <FormCheckBoxField name={oProps<ISearchCashMovementForm>().path("lastOnly")} label="Only last movements" />
                    {(!lastOnly) && <FormDatePickerField name={oProps<ISearchCashMovementForm>().path("from")} label="From" />}
                    {(!lastOnly) && <FormDatePickerField name={oProps<ISearchCashMovementForm>().path("to")} label="To" />}
                </FieldBox>
            </FieldBox>)}>
            <ExtendedGrid
                getRowId={getRowKey}
                columns={columns}
                rows={cashMovements}
                initialState={state}
                onRowClick={handleRowSelect}
                userCanGroup={true}
                defaultExportFileName="positions.xlsx" />
        </SearchPanel>
    }
}
