import * as React from "react";
import { useEffect } from "react";
import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import ReportDesigner from "tools/components/ReportDesigner";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { IFeatureModel, IGetReportTemplateContentModel, IScopeDescriptionModel } from "proxy/apiProxy";
import ReportTemplateData from "./ReportTemplateData";
import DatabaseArrowDownIcon from 'mdi-material-ui/DatabaseArrowDown';
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { isNumber, oProps, useNumber } from "tools/lib/utility";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import DialogPanel from "tools/components/DialogPanel";
import ScopeDescriptionData from "features/Report/ScopeDescriptionData";
import { useLocalStorage } from "tools/lib/useLocalStorage";
import { ScreenTab, useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";

export const reportTemplateScreen = {
    route: "/config/reporting/templates/:id/:tab?" as const,
    tabs: {
        detail: "details",
        designer: "content",
    },
    component: ReportTemplate,
    label: "Report Template"
}

function ReportTemplate() {
    const { reportTemplateCurrent, reportTemplateLoading, reportTemplateSaving, referentialDictionary, reportTemplateAnalysis, dataPreviewLoading, templateAnalyzing } = useReduxSelections("reportTemplate");
    const { referenceReportTemplateCategories = {} } = useReduxSelections("reference");
    const {
        reportTemplateSave,
        reportTemplateDelete,
        reportTemplateAnalyse,
        reportTemplateDataPreviewLoad,
        reportTemplateLoad,
        reportTemplateLoadForDuplicate
    } = useReduxActions("reportTemplate")
    const navigate = useScreenNavigate()

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof reportTemplateScreen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (isNumber(idNum)) {
            reportTemplateLoad(idNum)
        }
        const prefix = "duplicate";
        if (id?.startsWith(prefix)) {
            const idToDuplicate = Number(id.slice(prefix.length));
            if (isNumber(idToDuplicate))
                reportTemplateLoadForDuplicate(idToDuplicate)
        }
    }, [id, idNum, reportTemplateLoad, reportTemplateLoadForDuplicate]);

    const [isDataPreviewRequestOpened, setIsDataPreviewRequestOpened] = React.useState(false);
    const isGranted = useGrants();

    const [lastReportScopeDescription, setLastReportScopeDescription] = useLocalStorage<IScopeDescriptionModel>("lastReportScopeDescription");

    const initialScope = React.useMemo<IScopeDescriptionModel>(() => lastReportScopeDescription ?? {
        subScopes: {},
        extraParameters: {},
        groupLabelExpression: "{group}"
    }, [lastReportScopeDescription]);

    if (!reportTemplateCurrent) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={false} />;
    }

    const handleDeleteClick = () => {
        if (reportTemplateCurrent?.definition?.id) {
            reportTemplateDelete(reportTemplateCurrent.definition.id);
        };
    }

    const handleSubmit = ({ content, definition }: IGetReportTemplateContentModel, { setSubmitting }: FormikHelpers<IGetReportTemplateContentModel>) => {
        // ReportDesigner.DesignerInstance?.report.isModified
        if (ReportDesigner.DesignerInstance) {
            reportTemplateSave({ definition, content: ReportDesigner.DesignerInstance.report.saveToJsonString() });
        }
        else {
            reportTemplateSave({ definition, content });
        }
        setSubmitting(false);
    }

    const handleRequestPreview = () => {
        reportTemplateAnalyse(ReportDesigner.DesignerInstance ? ReportDesigner.DesignerInstance.report.saveToJsonString() : reportTemplateCurrent.content);
        setIsDataPreviewRequestOpened(true)
    }
    const handleCloseRequestPreview = () => {
        setIsDataPreviewRequestOpened(false)
    }

    const handleSubmitGetDataForPreview = (scopeDescription: IScopeDescriptionModel) => {
        setLastReportScopeDescription(scopeDescription);
        scopeDescription.groupLabelExpression = "dummy";
        if (reportTemplateAnalysis) {
            reportTemplateDataPreviewLoad({
                scopeDescription,
                content: ReportDesigner.DesignerInstance ? ReportDesigner.DesignerInstance.report.saveToJsonString() : reportTemplateCurrent.content
            });
        }
        setIsDataPreviewRequestOpened(false);
    }

    return <>
        <Formik onSubmit={handleSubmitGetDataForPreview} initialValues={initialScope} enableReinitialize={true} validateOnMount={true} >{renderScopeForm}</Formik>
        <Formik onSubmit={handleSubmit} initialValues={reportTemplateCurrent} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>
    </>;
    function renderScopeForm({ submitForm, isValid }: FormikProps<IScopeDescriptionModel>) {
        return <DialogPanel
            maxWidth="md"
            isOpened={isDataPreviewRequestOpened}
            title="Request data preview"
            isQuerying={templateAnalyzing}
            onBackClick={handleCloseRequestPreview}
            actions={[{ label: "Load data", onClick: submitForm, disabled: !isValid }]}>
            <ScopeDescriptionData parameterMetadatas={reportTemplateAnalysis?.parameters} hideGroupNameExpression={true} />
        </DialogPanel>
    }
    function renderForm({ submitForm, setFieldValue, isValid, values: { content: formContent, definition: formDefinition } }: FormikProps<IGetReportTemplateContentModel>) {

        const tabs: ISubMenuTabs<typeof reportTemplateScreen>[] | undefined = [{
            label: "Data",
            value: "detail"
        }, {
            label: "Template",
            value: "designer"
        }];
        const extraActionButtons = [{
            label: "Get data for preview",
            onClick: handleRequestPreview,
            icon: DatabaseArrowDownIcon
        }];

        const handleTabValueChanged = (value: any) => {
            const tabValue = value as ScreenTab<"Template">;
            setFieldValue(oProps<IGetReportTemplateContentModel>().path("content"), ReportDesigner.DesignerInstance ? ReportDesigner.DesignerInstance.report.saveToJsonString() : formContent);
            if (id) navigate("Template", { id, tab: tabValue });
        }

        const handleSaveClick = () => {
            if (isGranted(IFeatureModel.ReportTemplateWrite)) {
                setFieldValue(oProps<IGetReportTemplateContentModel>().path("content"), ReportDesigner.DesignerInstance ? ReportDesigner.DesignerInstance.report.saveToJsonString() : formContent);
                submitForm();
            }
        };
        return <WriteAccess value={IFeatureModel.ReportTemplateWrite}>
            <DetailPanel
                isQuerying={reportTemplateLoading || reportTemplateSaving || dataPreviewLoading}
                tabs={tabs}
                title={formDefinition.name}
                subTitle={`Version ${formDefinition.publishedVersion}`}
                onSaveClick={handleSaveClick}
                canSave={isValid}
                onDeleteClick={handleDeleteClick}
                canDelete={formDefinition.id !== 0}
                saveAllowed={IFeatureModel.ReportTemplateWrite}
                deleteAllowed={IFeatureModel.ReportTemplateWrite}
                actions={extraActionButtons}
                noPadding={tabValue === "designer"}
                tabValue={tabValue}
                onTabValueChanged={handleTabValueChanged} >
                {(tabValue === "detail") && <ReportTemplateData fieldName={oProps<IGetReportTemplateContentModel>().path("definition")} referenceReportTemplateCategories={referenceReportTemplateCategories} />}
                {(tabValue === "designer") && <ReportDesigner template={formContent} data={referentialDictionary} />}
            </DetailPanel>
        </WriteAccess>
    }
}
