import * as React from "react";
import { FormikHelpers, Formik } from "formik";
import {
    DevelopmentItemModel,
    IReportTemplateDevelopmentItemModel,
    IQuestionnaireDevelopmentItemModel,
    IEtlMacroDevelopmentItemModel,
    IDevelopmentItemTypeModel,
    IMacroModel,
    IDashboardLayoutDevelopmentItemModel,
    IDatasetMacroDevelopmentItemModel
} from "proxy/apiProxy";
import { useReduxSelections, useReduxActions } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { useEditorRef } from "./Designers/INotControllableEditorProps";
import { DevelopmentItemForm } from "./DevelopmentItemForm";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";
import { isNumber, useNumber } from "tools/lib/utility";

export const developmentItemScreen = {
    route: "/developmentItems/:id/:tab?" as const,
    label: "Development Item",
    component: DevelopmentItemEdit,
    tabs: {
        detail: "details",
        macro: "macro",
        visualization: "visual",
        questionnaire: "questionnaire",
        template: "template",
        loadMacro: "loadmacro",
        completeMacro: "completemacro",
        layout: "dashboardlayout",
        structure: "structure"
    },
}

function createEditableValues(developmentItemCurrent: DevelopmentItemModel | undefined) {
    switch (developmentItemCurrent?.type) {
        case "QuestionnaireDevelopmentItemModel": {
            const { templateContent, onCompleteMacro, onLoadMacro, ...values } = developmentItemCurrent;
            return values;
        }
        case "ReportTemplateDevelopmentItemModel": {
            const { templateContent, ...values } = developmentItemCurrent;
            return values;
        }
        case "EtlMacroDevelopmentItemModel": {
            const { macro, ...values } = developmentItemCurrent;
            return values;
        }
        case "DashboardLayoutDevelopmentItemModel": {
            const { layout, ...values } = developmentItemCurrent;
            return values;
        }
        case "DatasetMacroDevelopmentItemModel": {
            const { macro, ...values } = developmentItemCurrent;
            return values;
        }
        default:
            return developmentItemCurrent;
    }
}
export type FormValues = NonNullable<ReturnType<typeof createEditableValues>>;

function isDevelopmentItemTypeModel(value: string): value is IDevelopmentItemTypeModel {
    return value in IDevelopmentItemTypeModel;
}

export const convertToNullIfMacroContentEmpty = (model?: IMacroModel) => {
    if (!model || !model.content) return undefined
    return model
}


function DevelopmentItemEdit() {
    const { developmentItemCurrent } = useReduxSelections("developmentItem");
    const { developmentItemSave, developmentItemLoad, developmentItemNew } = useReduxActions("developmentItem");

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof developmentItemScreen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (isNumber(idNum))
            developmentItemLoad(idNum)
        if (id && isDevelopmentItemTypeModel(id)) {
            developmentItemNew(id)
        }
    }, [developmentItemLoad, developmentItemNew, id, idNum]);

    const developmentItemValues = React.useMemo(() => createEditableValues(developmentItemCurrent), [developmentItemCurrent]);
    const dashboardLayoutRef = useEditorRef((developmentItemCurrent as IDashboardLayoutDevelopmentItemModel)?.layout);
    const reportTemplateRef = useEditorRef((developmentItemCurrent as IReportTemplateDevelopmentItemModel)?.templateContent);
    const questionnaireTemplateRef = useEditorRef((developmentItemCurrent as IQuestionnaireDevelopmentItemModel)?.templateContent);
    const etlMacroRef = useEditorRef((developmentItemCurrent as IEtlMacroDevelopmentItemModel)?.macro);
    const datasetMacroRef = useEditorRef((developmentItemCurrent as IDatasetMacroDevelopmentItemModel)?.macro);
    const questionnaireLoadMacroRef = useEditorRef((developmentItemCurrent as IQuestionnaireDevelopmentItemModel)?.onLoadMacro);
    const questionnaireCompleteMacroRef = useEditorRef((developmentItemCurrent as IQuestionnaireDevelopmentItemModel)?.onCompleteMacro);

    const handleSubmit = React.useCallback(async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        switch (values.type) {
            case "QuestionnaireDevelopmentItemModel":
                developmentItemSave({
                    ...values,
                    templateContent: questionnaireTemplateRef.current.getValue() ?? "{}",
                    onCompleteMacro: convertToNullIfMacroContentEmpty(questionnaireCompleteMacroRef.current.getValue()),
                    onLoadMacro: convertToNullIfMacroContentEmpty(questionnaireLoadMacroRef.current.getValue())
                });
                break;
            case "ReportTemplateDevelopmentItemModel":
                developmentItemSave({
                    ...values,
                    templateContent: reportTemplateRef.current.getValue() ?? "{}"
                });
                break;
            case "EtlMacroDevelopmentItemModel":
                developmentItemSave({
                    ...values,
                    macro: convertToNullIfMacroContentEmpty(etlMacroRef.current.getValue())
                });
                break;
            case "DatasetMacroDevelopmentItemModel":
                developmentItemSave({
                    ...values,
                    macro: datasetMacroRef.current.getValue()
                });
                break;
            case "DashboardLayoutDevelopmentItemModel":
                developmentItemSave({
                    ...values,
                    layout: dashboardLayoutRef.current.getValue()
                });
                break;
            default:
                developmentItemSave(values);
                break;
        }
        setSubmitting(false);
    }, [developmentItemSave, questionnaireTemplateRef, questionnaireCompleteMacroRef, questionnaireLoadMacroRef, reportTemplateRef, etlMacroRef, datasetMacroRef, dashboardLayoutRef]);

    if (!developmentItemValues || !id) {
        return <DetailPanelLoading tabNumber={3} hasSubTitle={false} />;
    }
    return <Formik onSubmit={handleSubmit} initialValues={developmentItemValues} enableReinitialize={true} validateOnMount={true}>
        {(formikProps => <DevelopmentItemForm
            id={id}
            tab={tabValue}
            form={formikProps}
            questionnaireTemplateRef={questionnaireTemplateRef}
            reportTemplateRef={reportTemplateRef}
            etlMacroRef={etlMacroRef}
            questionnaireCompleteMacroRef={questionnaireCompleteMacroRef}
            questionnaireLoadMacroRef={questionnaireLoadMacroRef}
            dashboardLayoutRef={dashboardLayoutRef}
            datasetMacroRef={datasetMacroRef} />)}
    </Formik>;
}
