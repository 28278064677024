import { Epic } from "redux-observable";
import { forkJoin, from, merge, Observable, of } from "rxjs";
import { filter, map, mergeMap, share, toArray } from "rxjs/operators";
import { customScreensApi, IGetSicavModel, ISicavModel, macroScriptsApi, sicavsApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { IBase64File } from "tools/lib/utility";
import { ApiError } from "lib/dataAccess";

export const loadEntities: Epic<IAnyAction>
    = (action$) =>
        action$.pipe(
            mapToPayload("sicav", "sicavLoadAll"),
            mergeMap(() => sicavsApi.getAllAsync({})),
            map(ActionFactories.sicav.sicavLoadedAll));

export const loadProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("sicav", "sicavProcessExecutionsLoad"),
        mergeMap(id => sicavsApi.getProcessesAsync({ id })),
        map(ActionFactories.sicav.sicavProcessExecutionsLoaded));

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("sicav", "sicavDocumentsLoad"),
        mergeMap(id => sicavsApi.getDocumentsAsync({ id })),
        map(ActionFactories.sicav.sicavDocumentsLoaded));

function createEmptyEntity(): ISicavModel {
    return {
        type: "SicavModel",
        id: 0,
        internalCode: "",
        name: "",
        isUnderManagement: false,
        classifications: {},
        entityExtensionFieldsValues: {},
        hasPersonalPortalTheme: false,
        entitiesId: []
    };
}

export const submitCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("sicav", "sicavCustomScreenDataSubmit"),
        mergeMap(sicavsApi.submitCustomScreenDataAsync),
        map(ActionFactories.sicav.sicavCustomScreenDataSubmitted));
export const loadCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("sicav", "sicavCustomScreenDatasLoad"),
        mergeMap(id => sicavsApi.getLastCustomScreenDatasAsync({ id })),
        map(ActionFactories.sicav.sicavCustomScreenDatasLoaded));

export const loadSicavEpic: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("sicav", "sicavLoad"),
            share());

        const sicav$ = merge(
            requestedId$.pipe(
                filter(i => !!i),

                map(id => id as number),
                mergeMap(async id => {
                    const [entity, entityImage] = await Promise.all([
                        sicavsApi.getAsync({ id: id as number }),
                        sicavsApi.getImageAsync({ id }).catch(err => {
                            if (err instanceof ApiError && err.status === 404) {
                                return undefined;
                            }
                            throw err;
                        })
                    ]);
                    return {
                        ...entity,
                        entityPicture: entityImage ? {
                            content: entityImage.data,
                            mimeType: entityImage.mimeType,
                            fileName: entityImage.name
                        } : undefined
                    };
                })),
            requestedId$.pipe(
                filter(i => !i),
                map(() => ({ sicav: createEmptyEntity(), entities: {} }) as IGetSicavModel))
        ).pipe(share());

        const customScreens$ = requestedId$.pipe(
            mergeMap(() => customScreensApi.getAllAsync({})),
            map(customScreens => customScreens.filter(customScreen => customScreen.type === "SicavCustomScreenSummaryModel")),
            mergeMap(customScreens => from(customScreens).pipe(
                mergeMap(({ id }) => customScreensApi.getAsync(({ id }))),
                toArray(),
                map(ActionFactories.sicav.sicavCustomScreensLoaded))));

        return merge(
            requestedId$.pipe(
                filter(id => typeof id === "number" && !!id),
                map(id => ActionFactories.sicav.sicavCustomScreenDatasLoad(typeof id === "number" ? id : 0))),
            customScreens$,
            sicav$.pipe(map(ActionFactories.sicav.sicavLoaded)),
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad())));
    };

export const getMonitoringResultLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("sicav", "sicavMonitoringResultLoad"),
        mergeMap(macroScriptsApi.getMonitoringResultForTargetAsync),
        map(ActionFactories.sicav.sicavMonitoringResultLoaded));

function saveSicav(
    savedSicav: ISicavModel,
    imageFile: IBase64File | undefined) {
    const { id: sicavId } = savedSicav;
    const merges$: Observable<IAnyAction>[] = [];

    const toWait$: Observable<any>[] = [];

    const savedEntity$ = (function () {
        if (imageFile) {
            const toSave = {
                id: sicavId,
                fileModel: {
                    data: imageFile.content,
                    mimeType: imageFile.mimeType,
                    name: imageFile.fileName
                }
            };
            return from(sicavsApi.saveImageAsync(toSave).then(() => savedSicav));
        }
        else {
            return from(sicavsApi.deleteImageAsync({ id: savedSicav.id }).then(() => savedSicav));
        }
    })();

    merges$.push(savedEntity$.pipe(map(() => ActionFactories.sicav.sicavSaved(savedSicav))));

    if (toWait$.length) {
        return forkJoin(toWait$).pipe(mergeMap(() => merge(...merges$)));
    }
    else {
        return merge(...merges$);
    }
}

export const saveSicavEpic: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("sicav", "sicavSave"),
        mergeMap(({ sicav, imageFile }) =>
            of(sicav).pipe(
                mergeMap(model => sicavsApi.saveAsync({ model })),
                mergeMap(savedSicav => saveSicav(savedSicav, imageFile)))));

export const deleteEntity: Epic<IAnyAction>
    = (action$) => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("sicav", "sicavDelete"),
            mergeMap(id => sicavsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.sicav.sicavDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
