import { Box, Typography } from "@material-ui/core";
import { BindableType, FieldControl, IExecutionParameters } from "./DashboardFormattingContracts";
import { formatItem, formatNumber, useColor, useEnabled, useValue, useVisible } from "./ValueProvider";
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import Icon from '@material-ui/core/Icon';
import Showdown from "showdown";
import ReactHtmlParser from 'react-html-parser';
import { IExecutionProps, useDashboardComponents } from "./ComponentProvider";
import { toDictionary } from "tools/lib/utility";
import { SummaryContainer, SummaryField } from "components/global/SummaryField";
import 'github-markdown-css/github-markdown-light.css'
import { PropsWithChildren, useMemo } from "react";
import { DataType } from 'csstype';

// https://docs.npmjs.com/cli/v8/configuring-npm/package-json#local-paths


interface IMarkDownProps {
    markDown: string;
}
function MarkDown({ markDown }: IMarkDownProps) {
    const converter = new Showdown.Converter();
    converter.setFlavor("github")
    const htmlText = converter.makeHtml(markDown);
    return <div className="markdown-body">{ReactHtmlParser(htmlText)}</div>
}
export function getDefaultAlignment(type: FieldControl<any>["type"]): "left" | "center" | "right" {
    switch (type) {
        case "icon":
        case "boolean":
            return "center";
        case "date":
        case "dateTime":
        case "number":
        case "percentage":
            return "right";
        default:
            return "left";
    }
}

export function getContentColor(item: FieldControl<any>, row: any, getValue: ReturnType<typeof useValue>, getColor: ReturnType<typeof useColor>) {
    const fColor = item.foregroundColor ? getColor(item.foregroundColor, item.foregroundColorRatio, row) : undefined;
    const bColor = item.backgroundColor ? getColor(item.backgroundColor, item.backgroundColorRatio, row) : undefined;

    switch (item.type) {
        case "percentage": return {};
        default: return {
            color: fColor,
            backgroundColor: bColor
        };
    }
}

export interface IFieldComponentProps {
    item: FieldControl<any>;
    row?: any;
}
// export function getFieldValue(item: FieldControl<object>, getValue: <T, >(v: BindableType<T>) => T | undefined) {
//     return getValue(item.value);
// }
export function FieldComponent({ item, row }: IFieldComponentProps) {
    const { ReportLink, DashboardLink, ScreenLink, ExecuteEtl, ProcessTaskFile, ProcessTaskQuestionnaire, Questionnaire } = useDashboardComponents();
    const getVisible = useVisible();
    const getEnabled = useEnabled();
    const getValue = useValue();
    const getColor = useColor();
    const isEnabled = getEnabled(item);
    if (!getVisible(item)) {
        return null;
    }
    const fColor = item.foregroundColor ? getColor(item.foregroundColor, item.foregroundColorRatio, row) : undefined;
    const bColor = item.backgroundColor ? getColor(item.backgroundColor, item.backgroundColorRatio, row) : undefined;
    const isBold = getValue("boolean", item.bold, row);
    const isItalic = getValue("boolean", item.italic, row);
    const label = getValue("string", item.label, row);
    switch (item.type) {
        case "boolean": {
            const value = getValue("boolean", item.value, row);
            // const color=getValue(item.foregroundColor);
            return <SummaryField
                noUnderline
                label={null}
                value={label}
                foregroundColor={fColor}
                // color={getSentimentColor(sentiment)}
                startAdornment={value ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />} backgroundColor={bColor} color={fColor} />
        }
        case "date": {
            const value = getValue("date", item.value, row);
            return <SummaryField
                noUnderline
                foregroundColor={fColor}
                bold={isBold}
                italic={isItalic}
                label={label} value={formatItem(value, item.format)} backgroundColor={bColor} color={fColor} />
        }
        case "dateTime": {
            const value = getValue("date", item.value, row);
            return <SummaryField
                noUnderline
                foregroundColor={fColor}
                bold={isBold}
                italic={isItalic}
                label={label} value={formatItem(value, item.format ?? "yyyy-MM-dd HH:mm")} backgroundColor={bColor} color={fColor} />
        }
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
        case "button":
        case "caption":
        case "subtitle1":
        case "subtitle2":
        case "body1":
        case "body2": {
            const value = getValue("any", item.value, row);
            return <Typography variant={item.type} gutterBottom style={{ color: fColor, width: item.width }}>{value}</Typography>
        }
        case "icon": {
            const value = getValue("string", item.value, row);
            if (!value) {
                return null;
            }
            return <Icon fontSize="large" style={{ color: fColor }} >{value}</Icon>
        }
        case "linkToReport": {
            const templateName = getValue("string", item.templateName, row);
            if (!templateName) return null;
            const datasetName = getValue("string", item.datasetName, row);
            if (!datasetName) return null;
            const executionParams = getExecutionValues(item, row, getValue);
            const fileType = getValue("string", item.fileType, row);
            return <ReportLink
                disabled={!isEnabled}
                datasetName={datasetName}
                templateName={templateName}
                fileType={fileType}
                color={fColor}
                backgroundColor={bColor}
                {...executionParams} />
        }
        case "linkToDashboard": {
            const screenTitle = getValue("string", item.screenTitle, row);
            const layoutName = getValue("string", item.layoutName, row);
            if (!layoutName) return null;
            const datasetName = getValue("string", item.datasetName, row);
            if (!datasetName) return null;
            const executionParams = getExecutionValues(item, row, getValue);
            return <DashboardLink
                disabled={!isEnabled}
                datasetName={datasetName}
                layoutName={layoutName}
                screenTitle={screenTitle}
                color={fColor}
                backgroundColor={bColor}
                {...executionParams} />
        }
        case "processTaskFile": {
            const processExecutionId = getValue("number", item.processExecutionId, row);
            const currentFileName = getValue("string", item.currentFileName, row);
            if (!processExecutionId) return null;
            const taskCode = getValue("string", item.taskCode, row);
            if (!taskCode) return null;
            const textValue = getValue("string", item.value, row) ?? "Label";
            return <ProcessTaskFile
                disabled={!isEnabled}
                processExecutionId={processExecutionId}
                taskCode={taskCode}
                textValue={textValue}
                currentFileName={currentFileName} />
        }
        case "processTaskQuestionnaire": {
            const processExecutionId = getValue("number", item.processExecutionId, row);
            if (!processExecutionId) return null;
            const taskCode = getValue("string", item.taskCode, row);
            if (!taskCode) return null;
            const textValue = getValue("string", item.value, row) ?? "Label";
            return <ProcessTaskQuestionnaire
                disabled={!isEnabled}
                processExecutionId={processExecutionId}
                taskCode={taskCode}
                textValue={textValue} />
        }
        case "questionnaire": {
            const questionnaireName = getValue("string", item.questionnaireName, row);
            if (!questionnaireName) return null;
            const getPreviousAnswers = getValue("boolean", item.getPreviousAnswers, row);
            const executionParams = getExecutionValues(item, row, getValue);
            return <Questionnaire
                disabled={!isEnabled}
                questionnaireName={questionnaireName}
                getPreviousAnswers={getPreviousAnswers}
                color={fColor}
                backgroundColor={bColor}
                {...executionParams} />
        }
        case "executeEtl": {
            const etlName = getValue("string", item.etlName, row);
            const noDownloadValue = getValue("boolean", item.noDownload, row);
            if (!etlName) return null;
            const inputConnector = getValue("string", item.inputConnector, row);
            const executionParams = getExecutionValues(item, row, getValue);
            return <ExecuteEtl
                disabled={!isEnabled}
                etlName={etlName}
                inputConnector={inputConnector}
                color={fColor}
                noDownload={noDownloadValue}
                backgroundColor={bColor}
                {...executionParams} />
        }
        case "linkToScreen": {
            const label = getValue("string", item.value, row) ?? "Navigate";
            const target = getValue("string", item.target, row);
            const tab = getValue("string", item.tab, row);
            if (!target) return null;
            const params = item.parameters
                ? toDictionary(Object.keys(item.parameters).map(key => ({ key, value: String(getValue("any", item.parameters![key], row)) })).filter(i => !!i.value), i => i.key, i => i.value!)
                : undefined;
            return <ScreenLink
                disabled={!isEnabled}
                tab={tab} params={params} target={target} textValue={label} />;
        }
        case "markdown": {
            const value = getValue("string", item.value, row);
            if (!value) {
                return null;
            }
            return <MarkDown markDown={value} />
        }
        case "number": {
            const value = getValue("number", item.value, row);
            return <SummaryField
                noUnderline
                foregroundColor={fColor}
                bold={isBold}
                italic={isItalic}
                label={label} value={item.format ? formatItem(value, item.format) : formatNumber(value, item.precision, false, item.humanize)} backgroundColor={bColor} color={fColor} width={item.width} />
        }
        case "percentage": {
            const value = getValue("number", item.value, row);
            const backgroundColor = typeof value === "undefined" ? "lightgray" : getColor(item.backgroundColor ?? "lightgray", item.backgroundColorRatio ?? item.value, row);
            const foregroundColor = typeof value === "undefined" ? "lightgray" : getColor(item.foregroundColor ?? "black", item.foregroundColorRatio ?? item.value, row);
            return <SummaryContainer label={label} width={item.width} noUnderline>
                <Box width="100%" height="2em">
                    {typeof value === "number" && <div style={{
                        width: `${value * 100}%`,
                        backgroundColor: backgroundColor,
                        color: foregroundColor,
                        textAlign: "center"
                    }}>
                        {item.format ? formatItem(value, item.format) : formatNumber(value, item.precision, true)}
                    </div>}
                </Box>
            </SummaryContainer>
        }
        case "string": {
            const value = getValue("string", item.value, row);
            return <SummaryField
                noUnderline
                foregroundColor={fColor}
                bold={isBold}
                italic={isItalic}
                label={label} value={value} backgroundColor={bColor} color={fColor} width={item.width} />
        }
    }
}
function getExecutionValues(item: IExecutionParameters & { value: BindableType<string> }, row: object, getValue: ReturnType<typeof useValue>): IExecutionProps & { textValue: string } {
    const target = {
        taskCode: getValue("string", item.target?.taskCode, row),
        entityCode: getValue("string", item.target?.entityCode, row),
        entityId: getValue("number", item.target?.entityId, row),
        portfolioCode: getValue("string", item.target?.portfolioCode, row),
        portfolioId: getValue("number", item.target?.portfolioId, row),
        securityCode: getValue("string", item.target?.securityCode, row),
        securityId: getValue("number", item.target?.securityId, row),
        processExecutionId: getValue("number", item.target?.processExecutionId, row),
        relationshipId: getValue("number", item.target?.relationshipId, row),
    }
    const culture = getValue("string", item.culture, row);
    const asOfDate = getValue("dateNumber", item.asOfDate, row);
    const parameters = item.parameters
        ? toDictionary(Object.keys(item.parameters).map(key => ({ key, value: String(getValue("any", item.parameters![key], row)) })).filter(i => !!i.value), i => i.key, i => i.value!)
        : undefined;
    const textValue = getValue("string", item.value, row) ?? "Label";
    return {
        target,
        culture,
        asOfDate,
        parameters,
        textValue
    };
}
export function FieldContentComponent({ item, row }: IFieldComponentProps) {
    const getVisible = useVisible()
    const getValue = useValue()
    const getColor = useColor();
    const getEnabled = useEnabled();
    const isEnabled = getEnabled(item);

    const { ReportLink, DashboardLink, ScreenLink, ExecuteEtl, ProcessTaskFile, ProcessTaskQuestionnaire, Questionnaire } = useDashboardComponents();

    if (!getVisible(item)) {
        return null;
    }

    const fColor = item.foregroundColor ? getColor(item.foregroundColor, item.foregroundColorRatio, row) : undefined;
    const bColor = item.backgroundColor ? getColor(item.backgroundColor, item.backgroundColorRatio, row) : undefined;
    const isBold = getValue("boolean", item.bold, row);
    const isItalic = getValue("boolean", item.italic, row);

    switch (item.type) {
        case "boolean": {
            const value = getValue("boolean", item.value, row);
            return value ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />
        }
        case "date": {
            const value = getValue("date", item.value, row);
            return <TextWrapper bold={isBold} italic={isItalic} color={fColor}>{formatItem(value, item.format)}</TextWrapper>
        }
        case "dateTime": {
            const value = getValue("date", item.value, row);
            return <TextWrapper bold={isBold} italic={isItalic} color={fColor}> {formatItem(value, item.format ?? "yyyy-MM-dd HH:mm")}</TextWrapper>
        }
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
        case "button":
        case "caption":
        case "subtitle1":
        case "subtitle2":
        case "body1":
        case "body2": {
            const value = getValue("string", item.value, row);
            return <Typography variant="h6" gutterBottom>{value}</Typography>
        }
        case "icon": {
            const value = getValue("string", item.value, row);
            if (!value) {
                return null;
            }
            return <Icon>{value}</Icon>
        }
        case "linkToReport": {
            const templateName = getValue("string", item.templateName, row);
            if (!templateName) return null;
            const datasetName = getValue("string", item.datasetName, row);
            if (!datasetName) return null;
            const executionParams = getExecutionValues(item, row, getValue);
            const fileType = getValue("string", item.fileType, row);
            return <ReportLink
                disabled={!isEnabled}
                datasetName={datasetName}
                templateName={templateName}
                fileType={fileType}
                color={fColor}
                backgroundColor={bColor}
                {...executionParams} />
        }
        case "linkToDashboard": {
            const screenTitle = getValue("string", item.screenTitle, row);
            const layoutName = getValue("string", item.layoutName, row);
            if (!layoutName) return null;
            const datasetName = getValue("string", item.datasetName, row);
            if (!datasetName) return null;
            const executionParams = getExecutionValues(item, row, getValue);
            return <DashboardLink
                disabled={!isEnabled}
                datasetName={datasetName}
                layoutName={layoutName}
                color={fColor}
                screenTitle={screenTitle}
                backgroundColor={bColor}
                {...executionParams} />
        }
        case "linkToScreen": {
            const label = getValue("string", item.value, row) ?? "Navigate";
            const target = getValue("string", item.target, row);
            const tab = getValue("string", item.tab, row);
            if (!target) return null;
            const params = item.parameters
                ? toDictionary(Object.keys(item.parameters).map(key => ({ key, value: String(getValue("any", item.parameters![key], row)) })).filter(i => !!i.value), i => i.key, i => i.value!)
                : undefined;
            return <ScreenLink
                disabled={!isEnabled}
                tab={tab} params={params} target={target} textValue={label}
                color={fColor} backgroundColor={bColor} />;
        }
        case "processTaskFile": {
            const processExecutionId = getValue("number", item.processExecutionId, row);
            const currentFileName = getValue("string", item.currentFileName, row);
            if (!processExecutionId) return null;
            const taskCode = getValue("string", item.taskCode, row);
            if (!taskCode) return null;
            const textValue = getValue("string", item.value, row) ?? "Label";
            return <ProcessTaskFile
                disabled={!isEnabled}
                textValue={textValue}
                processExecutionId={processExecutionId}
                taskCode={taskCode}
                currentFileName={currentFileName} />
        }
        case "processTaskQuestionnaire": {
            const processExecutionId = getValue("number", item.processExecutionId, row);
            if (!processExecutionId) return null;
            const taskCode = getValue("string", item.taskCode, row);
            if (!taskCode) return null;
            const textValue = getValue("string", item.value, row) ?? "Label";
            return <ProcessTaskQuestionnaire
                disabled={!isEnabled}
                textValue={textValue}
                processExecutionId={processExecutionId}
                taskCode={taskCode} />
        }
        case "questionnaire": {
            const questionnaireName = getValue("string", item.questionnaireName, row);
            if (!questionnaireName) return null;
            const getPreviousAnswers = getValue("boolean", item.getPreviousAnswers, row);
            const executionParams = getExecutionValues(item, row, getValue);
            return <Questionnaire
                disabled={!isEnabled}
                questionnaireName={questionnaireName}
                getPreviousAnswers={getPreviousAnswers}
                color={fColor}
                backgroundColor={bColor}
                {...executionParams} />
        }
        case "executeEtl": {
            const etlName = getValue("string", item.etlName, row);
            if (!etlName) return null;
            const inputConnector = getValue("string", item.inputConnector, row);
            const executionParams = getExecutionValues(item, row, getValue);
            return <ExecuteEtl
                disabled={!isEnabled}
                etlName={etlName}
                inputConnector={inputConnector}
                color={fColor}
                backgroundColor={bColor}
                {...executionParams} />
        }
        case "markdown": {
            const value = getValue("string", item.value, row);
            if (!value) {
                return null;
            }
            return <MarkDown markDown={value} />
        }
        case "number": {
            const value = getValue("number", item.value, row);
            return <TextWrapper bold={isBold} italic={isItalic} color={fColor}>{item.format ? formatItem(value, item.format) : formatNumber(value, item.precision, false, item.humanize)}</TextWrapper>
        }
        case "percentage": {
            const value = getValue("number", item.value, row);
            const backgroundColor = typeof value === "undefined" ? "lightgray" : getColor(item.backgroundColor ?? "lightgray", item.backgroundColorRatio ?? item.value, row);
            const foregroundColor = typeof value === "undefined" ? "lightgray" : getColor(item.foregroundColor ?? "black", item.foregroundColorRatio ?? item.value, row);
            return <Box width="100%" height="2em">
                {typeof value === "number" && <div style={{
                    width: `${value * 100}%`,
                    backgroundColor: backgroundColor,
                    color: foregroundColor,
                    textAlign: "center"
                }}>
                    {item.format ? formatItem(value, item.format) : formatNumber(value, item.precision, true)}
                </div>}
            </Box>
        }
        case "string": {
            const value = getValue("string", item.value, row);
            return <TextWrapper bold={isBold} italic={isItalic} color={fColor}>{value ?? null}</TextWrapper>
        }
    }
}
interface ITextWrapperProps {
    bold?: boolean;
    italic?: boolean;
    color?: DataType.Color;
}

function TextWrapper({ bold, italic, color, children }: PropsWithChildren<ITextWrapperProps>) {
    const textStyle: React.CSSProperties = useMemo(() => ({
        color: color,
        fontWeight: bold ? "bold" : undefined,
        fontStyle: italic ? "italic" : undefined
    }), [bold, color, italic])
    if (!bold && !italic && !color) {
        return children;
    }
    return <span style={textStyle}>{children}</span>
}
