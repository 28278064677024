import React from "react";
import { useDropzone } from "react-dropzone";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { LinearProgress, Link, SvgIconProps } from "@material-ui/core";
import { Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        link: {
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: theme.palette.text.primary,
            "& > *:not(:first-child)": {
                marginLeft: theme.spacing(1)
            }
        },
        outRoot: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderRadius: theme.shape.borderRadius,
            borderColor: "#eeeeee",
            borderStyle: "none",
            outline: "none",
            transition: "border .24s ease-in-out",
            overflow: "hidden"
        },
        root: {
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: theme.spacing(1),
            backgroundColor: "#f2f2f2",
            color: "#bdbdbd"
        },
        acceptStyle: {
            boxShadow: theme.shadows[6],
            borderColor: "transparent",
            borderStyle: "solid"
            // marginTop: 2,
            // marginLeft: 2,
            // marginBottom: -2,
            // marginRight: -2
        },
        rejectStyle: {
            borderColor: "#ff1744"
        },
        disabled: {
            backgroundColor: "inherit",
            borderStyle: "dotted"
        }
    })
);

export interface IDropZoneProps {
    onFileDrop?: (file: File) => void;
    onClick?: () => void;
    name: string;
    subName?: string;
    disabled?: boolean;
    icon: React.ComponentType<SvgIconProps>;
    processing?: boolean;
}

export default function ExplorerElement(props: IDropZoneProps) {
    const {
        onFileDrop,
        onClick,
        name,
        disabled = false,
        icon,
        processing,
        subName
    } = props;
    const { getRootProps, isDragAccept, isDragReject } = useDropzone({
        disabled,
        multiple: false,
        noClick: true,
        onDropAccepted: (files) =>
            files?.length && onFileDrop && onFileDrop(files[0])
    });
    const elt = { icon };
    const classes = useStyles();
    const handleOnClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) {
            onClick();
        }
    };

    const className = classNames(classes.outRoot, {
        [classes.acceptStyle]: isDragAccept,
        [classes.rejectStyle]: isDragReject,
        [classes.disabled]: disabled
    });
    return (
        <Box
            className={classes.outRoot}
            {...(onFileDrop ? getRootProps({ className }) : undefined)}
        >
            <Box className={classes.root}>
                {!!onClick ? (
                    <Link className={classes.link} href="#" onClick={handleOnClick}>
                        <elt.icon fontSize="large" />
                        <Box display="flex" flexDirection="column">
                            <Typography>{name}</Typography>
                            {!!subName && <Typography variant="overline">{subName}</Typography>}
                        </Box>
                    </Link>
                ) : (
                    <Box className={classes.link}>
                        <elt.icon fontSize="large" />
                        <Box display="flex" flexDirection="column">
                            <Typography>{name}</Typography>
                            {!!subName && <Typography variant="overline">{subName}</Typography>}
                        </Box>
                    </Box>
                )}
            </Box>
            {processing && <LinearProgress />}
        </Box>
    );
}