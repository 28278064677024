import ChipInput from 'material-ui-chip-input';
import { useFieldEx, IFormField, ValidatorParams } from 'tools/lib/fieldHelpers';
import { ReadOnlyContext } from "./ReadOnlyContext";


const validators = {
    required: (v: string[] | undefined, isTrue?: boolean) => isTrue && (!v || (Array.isArray(v) && v.length === 0)) ? 'Required' : undefined,
    minElements: (v: string[] | undefined, min?: number) => !!min && (!v || (Array.isArray(v) && v.length < min)) ? `At least ${min} elements` : undefined,
    maxElements: (v: string[] | undefined, max?: number) => !!max && (!v || (Array.isArray(v) && v.length > max)) ? `Maximum ${max} elements` : undefined,
    minLength: (v: string[] | undefined, min?: number) => min && v && v.length && v.filter(s => s.length < min).length > 0 ? `Must be ${min} characters or more` : undefined,
    maxLength: (v: string[] | undefined, max?: number) => max && v && v.length && v.filter(s => s.length > max).length > 0 ? `Must be ${max} characters or less` : undefined,
}

export interface IFormMultiTextFieldProps extends IFormField, ValidatorParams<typeof validators> {
    fullWidth?: boolean;
}

export default function FormMultiTextField({ name, helperText, label, disabled, error, fullWidth = true, ...requestedValidations }: IFormMultiTextFieldProps) {
    const checkDirty = (o1: (string[] | undefined) = [], o2: (string[] | undefined) = []) => {
        for (const p in o1) {
            if (o1.hasOwnProperty(p)) {
                if (o1[p] !== o2[p]) {
                    return true;
                }
            }
        }
        for (const p in o2) {
            if (o2.hasOwnProperty(p)) {
                if (o1[p] !== o2[p]) {
                    return true;
                }
            }
        }
        return false;
    }
    const { error: fieldError, helperText: fieldHelperText, label: fieldLabel, onValueChanged, value } = useFieldEx<string[], typeof validators>({ name, helperText, label, required: requestedValidations.required, error, requestedValidations, validators, checkDirty });
    const onAdd = (e: string) => {
        const v = [...(value as string[])];
        if (v.indexOf(e) < 0) {
            v.push(e);
            onValueChanged(v);
        }
    };
    const onDelete = (chip: any, index: number) => {
        const v = [...(value as string[])];
        v.splice(index, 1);
        onValueChanged(v)
    }
    return <ReadOnlyContext.Consumer>
        {readOnly => <ChipInput
            readOnly={readOnly}
            id={name}
            disabled={disabled || readOnly}
            helperText={fieldHelperText}
            fullWidth={fullWidth}
            label={fieldLabel}
            error={fieldError}
            margin="dense"
            size="small"
            value={value || []}
            onAdd={onAdd}
            onDelete={onDelete}
        />}</ReadOnlyContext.Consumer>
}
