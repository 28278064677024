import * as React from "react";
import { PortfolioSummaryModel, IPortfolioTypeModel } from "proxy/apiProxy";
import { useField } from 'formik';
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IGetPortfolioSummary } from "features/Reference/slice";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';

export interface IPortfolioSelectFieldProps {
    name: string;
    label?: React.ReactNode;
    required?: boolean;
    filter?: (p: PortfolioSummaryModel) => boolean;
    onSelected?: (found: IGetPortfolioSummary) => void;
    type?: IPortfolioTypeModel;
}
export default function ManagedPortfolioSelectField({ name, label, required, onSelected, type, filter }: IPortfolioSelectFieldProps) {
    const { referenceManagedPortfolioSearch } = useReduxActions("reference");
    const { referenceManagedPortfoliosSearched: referencePortfoliosSearched, referenceManagedPortfolioSearching: referencePortfolioSearching } = useReduxSelections("reference");
    const validate = (portfolioId: number | undefined) => {
        if (!required) {
            return;
        }
        return (portfolioId ?? 0) === 0 ? "Required" : undefined;
    }
    const [, { value, error, initialValue }, { setValue }] = useField<number | undefined>({ name, validate });
    const getOptionLabel = (role: PortfolioSummaryModel) => role.name;
    label = <><SearchIcon fontSize="small" /> {label ?? "Portfolio"}: Type criteria to find a portfolio {required ? "*" : ""}</>;
    if ((initialValue || null) !== (value || null)) {
        label = <b>{label}</b>;
    }
    const handleValueSelected = (portfolio: PortfolioSummaryModel) => {
        if (onSelected) {
            onSelected({ portfolio, entities: referencePortfoliosSearched.entities });
        }
        setValue(portfolio.id);
    }
    const handleOnSearchOptions = (criterias: string) => referenceManagedPortfolioSearch({ criterias, type });
    const portfolios = filter ? referencePortfoliosSearched.portfolios.filter(filter) : referencePortfoliosSearched.portfolios;

    return <ReadOnlyContext.Consumer>{readOnly => readOnly ? null :
        <AsyncSelect onSearchOptions={handleOnSearchOptions} options={portfolios}
            getOptionLabel={getOptionLabel} searching={referencePortfolioSearching} label={label} onRequiredValueSelected={handleValueSelected} error={!!error} helperText={error} />}
    </ReadOnlyContext.Consumer>
}
