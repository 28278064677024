import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, RegisterTransactionModel, RegisterTransactionSummaryModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import RegisterTransactionData from "./RegisterTransactionData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { getRegisterTransactionTypeLabel } from "./getRegisterTransactionTypeLabel";
import { useScreenParams } from "../../tools/routing/screenRouteHooks";
import { isNumber, useNumber } from "../../tools/lib/utility";
import { useEffect } from "react";

export const registerTransactionScreen = {
    route: "/portfoliodata/registertransactions/:id" as const,
    component: RegisterTransaction,
    label: "Register Transaction"
}

function RegisterTransaction() {
    const {
        registerTransactionSave,
        registerTransactionAddSecurityInDictionary,
        registerTransactionAddRelationshipInDictionary,
        registerTransactionAddPortfolioInDictionary,
        registerTransactionDelete,
        registerTransactionLoad
    } = useReduxActions("registerTransaction");

    const { id } = useScreenParams<typeof registerTransactionScreen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (isNumber(idNum) && idNum)
            registerTransactionLoad(idNum)
        else
            //FIXME Add type checking
            registerTransactionLoad(id as (RegisterTransactionModel | RegisterTransactionSummaryModel)["type"])
    }, [id, idNum, registerTransactionLoad]);

    const {
        transactionCurrent,
        transactionLoading,
        transactionSaving,
        dictionaries
    } = useReduxSelections("registerTransaction");
    const { referenceCurrencies = {} } = useReduxSelections("reference");

    if (!transactionCurrent) {
        return <DetailPanelLoading />;
    }

    const handleSubmit = (values: RegisterTransactionModel, { setSubmitting }: FormikHelpers<RegisterTransactionModel>) => {
        registerTransactionSave(values);
        setSubmitting(false);
    }
    const handleDelete = () => {
        if (transactionCurrent?.id) {
            registerTransactionDelete(transactionCurrent.id);
        }
    }
    return <Formik onSubmit={handleSubmit} initialValues={transactionCurrent} enableReinitialize={true}
        validateOnMount={true}>{renderForm}</Formik>;

    function renderForm(formikProps: FormikProps<RegisterTransactionModel>) {
        if (!transactionCurrent) {
            return;
        }
        const { dirty, isValid, submitForm, values } = formikProps;
        const { transactionCode } = values;

        return <WriteAccess
            value={{ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId: transactionCurrent?.portfolioId ?? 0 }}>
            <DetailPanel
                isQuerying={transactionLoading || transactionSaving}
                title={`Register Transaction ${transactionCode}`}
                subTitle={getRegisterTransactionTypeLabel(values.type)}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!values.id}
                saveAllowed={{
                    feature: IFeatureModel.ManagedPortfolioWrite,
                    portfolioId: transactionCurrent?.portfolioId ?? 0
                }}
                deleteAllowed={{
                    feature: IFeatureModel.ManagedPortfolioWrite,
                    portfolioId: transactionCurrent?.portfolioId ?? 0
                }}
                onDeleteClick={handleDelete}>
                <RegisterTransactionData
                    currencies={referenceCurrencies}
                    transaction={values}
                    dictionaries={dictionaries}
                    onSecurityLoaded={registerTransactionAddSecurityInDictionary}
                    onRelationshipLoaded={registerTransactionAddRelationshipInDictionary}
                    onPortfolioLoaded={registerTransactionAddPortfolioInDictionary}
                />
            </DetailPanel></WriteAccess>
    }
}
