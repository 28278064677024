import * as React from "react";
import { IHistoricalValuesModel } from "proxy/apiProxy";
import ExtendedGrid, { IGridState, IColumnDefinition, ColumnType } from "tools/components/ExtendedGrid";
// import { ResponsiveLine } from '@nivo/line';
import {
    Chart,
    LineSeries,
    ArgumentAxis,
    ValueAxis
} from '@devexpress/dx-react-chart-material-ui';
import {
    ArgumentScale, FactoryFn
} from '@devexpress/dx-react-chart';
import { oProps } from "tools/lib/utility";
import { scaleTime } from 'd3-scale';
import { FormControlLabel, Switch, Box } from "@material-ui/core";
import FieldBox from "tools/components/FieldBox";

export interface IDataSeries {
    [key: string]: string | IColumnPresentation;
}
export interface IColumnPresentation {
    title: string;
    columnType: ColumnType;
}
export type VisualizationType = "GRID" | "CHART";
export interface IHistoricalValuesProps {
    onValueChanged?: (date: Date, type: string, value: number | undefined) => void;
    editable?: boolean;
    historicalValues?: IHistoricalValuesModel[];
    properties: IDataSeries;
    graphProperties?: IDataSeries;
    visualizationType: VisualizationType;
    onVisualizationTypeChanged?: (visualizationType: VisualizationType) => void;
}
interface IChartViewProps {
    historicalValues: IHistoricalValuesModel[];
    properties: IDataSeries;
}
function GetColumnLabel(columnProperty: IDataSeries[""]) {
    if (typeof (columnProperty) === "string") {
        return columnProperty;
    }
    else {
        return columnProperty.title;
    }
}
function GetColumnType(columnProperty: IDataSeries[""]) {
    if (typeof (columnProperty) === "string") {
        return "decimal";
    }
    else {
        return columnProperty.columnType;
    }
}
function ChartView({ historicalValues, properties }: IChartViewProps) {
    const chartData = React.useMemo(() => historicalValues.map(({ date, values }) => ({ date, ...Object.keys(properties).reduce((acc, key) => { acc[key] = values[key] as number; return acc; }, {} as any) })), [historicalValues, properties]);

    // @ts-ignore FIXME upgrade to 3.0.6
    return <Chart data={chartData}>
        <ArgumentScale factory={scaleTime as unknown as FactoryFn} />
        <ArgumentAxis />
        <ValueAxis />
        {Object.keys(properties).map(key => <LineSeries
            name={GetColumnLabel(properties[key])}
            key={key}
            valueField={key}
            argumentField={oProps<IHistoricalValuesModel>().path("date")} />)}
    </Chart>;
}
interface IGridViewProps {
    historicalValues: IHistoricalValuesModel[];
    properties: IDataSeries;
    editable?: boolean;
    onValueChanged?: (date: Date, type: string, value: number | undefined) => void;
}
function GridView({ properties, historicalValues, editable, onValueChanged }: IGridViewProps) {
    const columns: IColumnDefinition[] = React.useMemo(() => {
        return [{
            name: "date",
            title: "Date",
            getCellValue: (row: IHistoricalValuesModel) => row.date,
            columnType: "date"
        },
        ...Object.keys(properties).map(p => ({
            name: p,
            title: GetColumnLabel(properties[p]),
            getCellValue: (row: IHistoricalValuesModel) => row.values && row.values[p],
            columnType: GetColumnType(properties[p]),
            editable
        } as IColumnDefinition))]
    }, [properties, editable]);
    const state: IGridState = {
        "date": { width: 120, sortingPosition: -1 }
    };
    const getRowKey = React.useCallback((row: IHistoricalValuesModel) => (row.date && row.date.toDateString()) || "", []);
    const handleCellValueChanged = React.useCallback((rowId: number | string, columnName: string, value: string) => {
        if (!onValueChanged) {
            return;
        }
        const changed = historicalValues.find(i => getRowKey(i) === rowId);
        if (!changed) {
            return;
        }
        const newValue = Number(value);
        onValueChanged(changed.date, columnName, isNaN(newValue) ? undefined : newValue);
    }, [getRowKey, historicalValues, onValueChanged]);
    return <ExtendedGrid
        getRowId={getRowKey}
        defaultExportFileName="HistoricalValues.xlsx"
        columns={columns}
        rows={historicalValues}
        initialState={state}
        defaultColumnWidth={120}
        onCellValueChanged={handleCellValueChanged} />;
}
export default function HistoricalValues({ visualizationType, onVisualizationTypeChanged, properties, graphProperties = properties, historicalValues = [], editable, onValueChanged }: IHistoricalValuesProps) {
    const p = React.useMemo(() => {
        const newProperties = { ...properties } as IDataSeries;
        // const newGraphProperties = { ...graphProperties } as IDataSeries;
        for (const historicalValue of historicalValues) {
            for (const keyValue of Object.keys(historicalValue.values)) {
                if (typeof newProperties[keyValue] === "undefined") {
                    newProperties[keyValue] = {
                        columnType: "preciseDecimal",
                        title: keyValue
                    }
                }
                // if (typeof newGraphProperties[keyValue] === "undefined") {
                //     newGraphProperties[keyValue] = {
                //         columnType: "preciseDecimal",
                //         title: keyValue
                //     }
                // }
            }
        }
        return newProperties;
    }, [historicalValues, properties]);
    const handleHandleChangeVisualizationType = React.useCallback(() => onVisualizationTypeChanged && onVisualizationTypeChanged(visualizationType === "CHART" ? "GRID" : "CHART"), [onVisualizationTypeChanged, visualizationType])
    if (onVisualizationTypeChanged) {
        return <Box display="flex" flexDirection="column" height="100%">
            <FieldBox display="flex" flexDirection="row">
                <FormControlLabel
                    control={<Switch checked={visualizationType === "CHART"} onChange={handleHandleChangeVisualizationType} />}
                    label="Graph" />
                <Box flexGrow={1} />
            </FieldBox>
            <Box flexGrow={1} height="1px">
                {visualizationType === "CHART" && <ChartView properties={graphProperties} historicalValues={historicalValues} />}
                {visualizationType === "GRID" && <GridView properties={p} historicalValues={historicalValues} editable={editable} onValueChanged={onValueChanged} />}
            </Box>
        </Box>;
    }
    else {
        switch (visualizationType) {
            case "CHART": return <ChartView properties={graphProperties} historicalValues={historicalValues} />
            case "GRID": return <GridView properties={p} historicalValues={historicalValues} editable={editable} onValueChanged={onValueChanged} />
        }
    }
}
