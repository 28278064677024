import * as React from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Grid, IconButton } from "@material-ui/core";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import { ICountryModel, ICurrencyModel, IIndexSummaryModel } from "proxy/apiProxy";
import { SummaryField } from "../global/SummaryField";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useField } from "formik";
import CloseIcon from '@material-ui/icons/Close';
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { ScreenLink } from "tools/routing/ScreenLink";

export interface IIndexSummaryProps {
    indexes: Record<number, IIndexSummaryModel>;
    label?: string;
    currencies?: Record<number, ICurrencyModel>;
    countries?: Record<number, ICountryModel>;
    name: string;
}
export function IndexSummary({ name: fieldName, indexes, countries, currencies, label }: IIndexSummaryProps) {
    const [{ value = 0 }, , { setValue }] = useField<number | undefined>(fieldName);
    const [expanded, setExpanded] = React.useState(false);
    const handleSwitchExpand = () => setExpanded(!expanded);
    // const handleOpenClick = () => navigate("Index", { id: indexId });
    const handleSetUndefined = () => setValue(undefined);
    const index = indexes[value];
    if (!index) {
        return null;
    }
    const { id: indexId, internalCode, name, pricingFrequency, referenceCountryId, referenceCurrencyId } = indexes[value];
    return <ReadOnlyContext.Consumer>{readOnly => <Card >
        <CardHeader
            action={<>
                {!readOnly && <IconButton onClick={handleSetUndefined}><CloseIcon /></IconButton>}
                <IconButton onClick={handleSwitchExpand}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </>}
            title={name}
            subheader={label} />
        <Collapse in={expanded} timeout="auto">
            <CardContent>
                <Grid container={true} spacing={2}>
                    <Grid xs={6} item={true}>
                        <SummaryField label="Code" value={internalCode} />
                    </Grid>
                    <Grid xs={6} item={true}>
                        <SummaryField label="Pricing Frequency" value={pricingFrequency} />
                    </Grid>
                    {!!currencies && !!referenceCurrencyId && <Grid xs={6} item={true}>
                        <SummaryField label="Currency" value={currencies[referenceCurrencyId]?.isoCode} />
                    </Grid>}
                    {!!countries && !!referenceCountryId && <Grid xs={6} item={true}>
                        <SummaryField label="Country" value={countries[referenceCountryId]?.isoCode3} />
                    </Grid>}
                </Grid>
            </CardContent>
            <CardActions>
                <Button size="small"
                    component={ScreenLink}
                    destination={{ type: "Screen", key: "Index", label: "Open Index", params: { id: String(indexId) } }}
                ><OpenInBrowser />Open Index</Button>
            </CardActions>
        </Collapse>
    </Card>}
    </ReadOnlyContext.Consumer>
}

export interface IIndexSummaryReadOnlyProps {
    index: IIndexSummaryModel;
    label?: string;
    currencies?: Record<number, ICurrencyModel>;
    countries?: Record<number, ICountryModel>;
}
export function IndexSummaryReadOnly({ index: { id: indexId, internalCode, name, pricingFrequency, referenceCountryId, referenceCurrencyId }, countries, currencies, label }: IIndexSummaryReadOnlyProps) {
    const [expanded, setExpanded] = React.useState(false);
    const handleSwitchExpand = () => setExpanded(!expanded);
    // const handleOpenClick = () => navigate("Index", { id: indexId })
    return <Card >
        <CardHeader
            action={<IconButton onClick={handleSwitchExpand}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>}
            title={name}
            subheader={label} />
        <Collapse in={expanded} timeout="auto">
            <CardContent>
                <Grid container={true} spacing={2}>
                    <Grid xs={6} item={true}>
                        <SummaryField label="Code" value={internalCode} />
                    </Grid>
                    <Grid xs={6} item={true}>
                        <SummaryField label="Pricing Frequency" value={pricingFrequency} />
                    </Grid>
                    {!!currencies && !!referenceCurrencyId && <Grid xs={6} item={true}>
                        <SummaryField label="Currency" value={currencies[referenceCurrencyId]?.isoCode} />
                    </Grid>}
                    {!!countries && !!referenceCountryId && <Grid xs={6} item={true}>
                        <SummaryField label="Country" value={countries[referenceCountryId]?.isoCode3} />
                    </Grid>}
                </Grid>
            </CardContent>
            <CardActions>
                <Button size="small"
                    component={ScreenLink}
                    destination={{ type: "Screen", key: "Index", label: "Open Index", params: { id: String(indexId) } }}
                ><OpenInBrowser />Open Index</Button>
            </CardActions>
        </Collapse>
    </Card>
}
