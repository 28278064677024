import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { SpaConfigGetSpaConfig_RestApi, ISpaConfigModel } from 'proxy/apiProxy';

let config: ISpaConfigModel | undefined = undefined;

let userManager: UserManager | undefined = undefined;
export function getUserManager() {
    if (!userManager) {
        const config = getConfig()?.authentication;
        const userManagerSettings: UserManagerSettings = {
            authority: config?.authority ?? "",
            automaticSilentRenew: true,
            client_id: config?.clientId ?? "",
            loadUserInfo: false,
            redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
            response_type: 'code',
            scope: `openid profile email ${config?.audienceScope}`,
            userStore: new WebStorageStateStore({ store: window.localStorage }),
        }
        userManager = new UserManager(userManagerSettings);
    }
    return userManager;
}
export function getConfig() {
    if (config) return config;
    const request = new XMLHttpRequest();
    request.open(SpaConfigGetSpaConfig_RestApi.method, SpaConfigGetSpaConfig_RestApi.path, false);
    request.send();
    config = JSON.parse(request.responseText) as ISpaConfigModel;
    return config;
}
