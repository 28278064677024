import {
    roleRelationshipsApi,
    investorRelationshipsApi,
    counterpartyRelationshipsApi,
    RelationshipModel
} from "proxy/apiProxy";

export function getRelationshipApi(type: RelationshipModel["type"]) {
    switch (type) {
        case "CounterpartyRelationshipModel": return counterpartyRelationshipsApi;
        case "InvestorRelationshipModel": return investorRelationshipsApi;
        case "RoleRelationshipModel": return roleRelationshipsApi;
    }
}
