import * as React from "react";
import { AbsoluteReference, BindableType, Color, ColorDefinition, EqualityFilter, Filter, StrictInclusionFilter, RelativeReference, SelectionReference, NonStrictInclusionFilter, IControlBase, ExpressionReference } from "./DashboardFormattingContracts";
import { SelectionContext } from "./SelectionProvider";
import findColorBetween from "tools/lib/findColorBetween";
import { format as fnsFormat } from 'date-fns'; // https://date-fns.org/v3.6.0/docs/format
import numeral from 'numeral'; // http://numeraljs.com/

// import { Parser } from "expr-eval";
// https://docs.npmjs.com/cli/v8/configuring-npm/package-json#local-paths
const CurrentLocale = (window.navigator as any).userLanguage ?? window.navigator.language;

const humanReadableSuffixes = ["f", "a", "p", "n", "μ", "m", "", "k", "M", "G", "T", "P", "E"];

export function toHumanReadable(value: number, numSignificantDigits: number) {
    // Deal with special values
    if (!isFinite(value) || isNaN(value) || value === 0 || numSignificantDigits <= 0) {
        return value.toString();
    }

    // We deal only with positive values in the code below
    const isNegative = Math.sign(value) < 0;
    value = Math.abs(value);

    // Calculate the exponent as a multiple of 3, i.e., -6, -3, 0, 3, 6, etc.
    const exponent = Math.floor(Math.log10(value) / 3) * 3;

    // Find the correct suffix for the exponent, or fall back to scientific notation
    const indexOfSuffix = exponent / 3 + 6;
    const suffix = (indexOfSuffix >= 0 && indexOfSuffix < humanReadableSuffixes.length)
        ? humanReadableSuffixes[indexOfSuffix]
        : `·10^${exponent}`;

    // Scale the value to the exponent, then format it to the correct number of significant digits and add the suffix
    value = value * Math.pow(10, -exponent);
    const numIntegerDigits = Math.floor(Math.log10(value)) + 1;
    const numFractionalDigits = Math.min(numSignificantDigits - numIntegerDigits, 15);
    const result = value.toFixed(numFractionalDigits) + suffix;

    // Handle negatives
    return isNegative ? `-${result}` : result;
}

export function formatNumber(value: number | undefined, precision: number = 2, isPercentage: boolean = false, humanize: boolean = false) {
    if (humanize && value) {
        return toHumanReadable(value, precision);
    }
    return value?.toLocaleString(CurrentLocale, {
        useGrouping: true,
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
        style: isPercentage ? "percent" : undefined
    }) ?? "";
}
export function formatItem(value: Date | number | boolean | undefined | null, format?: string): string | null {
    if (value instanceof Date) {
        return fnsFormat(value, format ?? "yyyy-MM-dd");
    }
    else if (typeof value === "number") {
        return numeral(value).format(format ?? "0.00");
    }
    else if (typeof value === "boolean") {
        if (format) {
            const match = /^0:(.+)[|]1:(.+)$/.exec(format);
            if (match) {
                const [falseText, trueText] = match.splice(1);
                return value ? trueText : falseText;
            }
        }
        return String(value);
    }
    return null;
}

export interface IRootValueProvider {
    value: any;
    children: React.ReactNode;
}
export function RootValueProvider({ value, children }: IRootValueProvider) {
    return <RootValueContext.Provider value={value}>
        {children}
    </RootValueContext.Provider>
}
const RootValueContext = React.createContext<any>({});
export interface IRelativeValueProvider {
    value: BindableType<object> | undefined;
    children: React.ReactNode;
}
export function RelativeValueProvider({ value, children }: IRootValueProvider) {
    const getValue = useValue();
    if (typeof value === "undefined") {
        return <>{children}</>
    }
    value = getValue("any", value);
    return <RelativeValueContext.Provider value={value}>
        {children}
    </RelativeValueContext.Provider>
}
const RelativeValueContext = React.createContext<any>({});

export function isRelativeReference<T>(bound: BindableType<T>): bound is RelativeReference {
    return typeof bound === "string" && bound.split(".")[0] === "";
}
export function isAbsoluteReference<T>(bound: BindableType<T>): bound is AbsoluteReference {
    return typeof bound === "string" && bound.split(".")[0] === "@";
}
export function isSelectionReference<T>(bound: SelectionReference | AbsoluteReference | ExpressionReference | T): bound is SelectionReference {
    return typeof bound === "string" && bound.split(".")[0] === "$";
}
export function isExpressionReference<T>(bound: SelectionReference | AbsoluteReference | ExpressionReference | T): bound is ExpressionReference {
    return typeof bound === "string" && bound.split(".")[0] === "#";
}
const typeGuards = {
    "boolean": (i: any): i is boolean => typeof i === "boolean",
    "string": (i: any): i is string => typeof i === "string",
    "number": (i: any): i is number => typeof i === "number",
    "object": (i: any): i is object => typeof i === "object",
    "array": (i: any): i is object[] => Array.isArray(i),
    "date": (i: any): i is Date => i instanceof Date,
    "any": (i: any): i is any => true,
    "stringNumberDateBoolean": (i: any): i is string | number | Date | boolean => typeof i === "string" || typeof i === "number" || i instanceof Date || typeof i === "boolean",
    "stringNumber": (i: any): i is string | number => typeof i === "string" || typeof i === "number",
    "dateNumber": (i: any): i is Date | number => i instanceof Date || typeof i === "number",
    "color": (i: any): i is Color => typeof i === "string",
}

type TypeGuard<T> = T extends (i: any) => i is infer U ? U : never;
export function useValue() {
    const rootValue = React.useContext(RootValueContext);
    const relativeValue = React.useContext(RelativeValueContext);
    const selectionValue = React.useContext(SelectionContext);
    function getV<K extends keyof typeof typeGuards, T extends TypeGuard<typeof typeGuards[K]>>(type: K, bound: BindableType<T> | SelectionReference | undefined): T | undefined;
    function getV<K extends keyof typeof typeGuards, T extends TypeGuard<typeof typeGuards[K]>>(type: K, bound: BindableType<T> | SelectionReference | undefined, forceRelativeValue: object | undefined): T | undefined;
    function getV<K extends keyof typeof typeGuards, T extends TypeGuard<typeof typeGuards[K]>>(type: K, bound: T): T;
    function getV<K extends keyof typeof typeGuards, T extends TypeGuard<typeof typeGuards[K]>>(type: K, bound: BindableType<T> | SelectionReference | undefined, forceRelativeValue?: object) {
        const v = pickValue(rootValue, forceRelativeValue ?? relativeValue, selectionValue, bound);
        if (typeGuards[type](v)) {
            return v;
        } else if (type === "string" && v !== null && v !== undefined) {
            return String(v);
        }
        return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useCallback(getV, [relativeValue, rootValue, selectionValue]);
}
export type IVisibility = Pick<IControlBase<never>, "hidden" | "visible">;
export type IEnability = Pick<IControlBase<never>, "disabled" | "enabled">;
export function useExpression() {
    const rootValue = React.useContext(RootValueContext);
    const relativeValue = React.useContext(RelativeValueContext);
    const selectionValue = React.useContext(SelectionContext);
    function getV<K extends keyof typeof typeGuards, T extends TypeGuard<typeof typeGuards[K]>>(type: K, expression: string, forceRelativeValue: object | undefined): T | undefined;
    function getV<K extends keyof typeof typeGuards, T extends TypeGuard<typeof typeGuards[K]>>(type: K, expression: string): T;
    function getV<K extends keyof typeof typeGuards, T extends TypeGuard<typeof typeGuards[K]>>(type: K, expression: string, forceRelativeValue?: object) {
        const v = evaluateExpression<T>(expression, rootValue, forceRelativeValue ?? relativeValue, selectionValue);
        if (typeGuards[type](v)) {
            return v;
        }
        else if (type === "string" && v !== null) {
            return String(v);
        }
        else if (type === "boolean") {
            return !!v;
        }
        return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useCallback(getV, [relativeValue, rootValue, selectionValue]);

}
function evaluateExpression<T>(expression: string, rootValue: any, relativeValue: any, selectionValue: any): T | undefined {
    try {
        const context = {
            s: selectionValue,
            a: rootValue,
            r: relativeValue,
            f: formatItem
        };
        const params = Object.keys(context);
        const values = Object.values(context);

        // solution to get rid of the security issue: https://github.com/pegjs/pegjs/wiki/Useful-Grammars#core-pegjs
        // eslint-disable-next-line no-new-func
        const func = new Function(...params, `return ${expression}`);
        return func(...values) as T;
    } catch (error) {
        console.error(`Issue with expression "${expression}": ${error}`);
        return undefined;
    }
}
export function useVisible() {
    const rootValue = React.useContext(RootValueContext);
    const relativeValue = React.useContext(RelativeValueContext);
    const selectionValue = React.useContext(SelectionContext);
    function getV(bound: IVisibility) {
        const isHidden = typeof bound.hidden === "undefined" ? undefined : !!pickValue(rootValue, relativeValue, selectionValue, bound.hidden);
        const isHiddenValue = typeof isHidden === "undefined" ? false : isHidden;
        const isVisible = typeof bound.visible === "undefined" ? undefined : !!pickValue(rootValue, relativeValue, selectionValue, bound.visible);
        // const isVisibleValue = typeof isVisible === "undefined" ? true : isVisible;
        return isVisible ?? !isHiddenValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useCallback(getV, [relativeValue, rootValue, selectionValue]);
}
export function useEnabled() {
    const rootValue = React.useContext(RootValueContext);
    const relativeValue = React.useContext(RelativeValueContext);
    const selectionValue = React.useContext(SelectionContext);
    function getV(bound: IEnability) {
        const isDisabled = typeof bound.disabled === "undefined" ? undefined : !!pickValue(rootValue, relativeValue, selectionValue, bound.disabled);
        const isDisabledValue = typeof isDisabled === "undefined" ? false : isDisabled;
        const isEnabled = typeof bound.enabled === "undefined" ? undefined : !!pickValue(rootValue, relativeValue, selectionValue, bound.enabled);
        // const isVisibleValue = typeof isVisible === "undefined" ? true : isVisible;
        return isEnabled ?? !isDisabledValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useCallback(getV, [relativeValue, rootValue, selectionValue]);
}
export function useArrayValue() {
    const rootValue = React.useContext(RootValueContext);
    const relativeValue = React.useContext(RelativeValueContext);
    const selectionValue = React.useContext(SelectionContext);
    function getV<K extends keyof typeof typeGuards, T extends TypeGuard<typeof typeGuards[K]>>(type: K, bound: BindableType<T[]> | SelectionReference | undefined): T[] | undefined;
    function getV<K extends keyof typeof typeGuards, T extends TypeGuard<typeof typeGuards[K]>>(type: K, bound: BindableType<T[]> | SelectionReference | undefined, forceRelativeValue: object | undefined): T[] | undefined;
    function getV<K extends keyof typeof typeGuards, T extends TypeGuard<typeof typeGuards[K]>>(type: K, bound: T[]): T[];
    function getV<K extends keyof typeof typeGuards, T extends TypeGuard<typeof typeGuards[K]>>(type: K, bound: BindableType<T[]> | SelectionReference | undefined, forceRelativeValue?: object) {
        const v = pickArrayValue(rootValue, forceRelativeValue ?? relativeValue, selectionValue, bound);
        if (typeGuards[type](v)) {
            return v;
        }
        return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useCallback(getV, [relativeValue, rootValue, selectionValue]);
}

function pairValues<T>(values: T[]) {
    let previousValue: T | undefined;
    const toReturn: [T, T][] = [];
    for (const value of values) {
        if (previousValue) {
            toReturn.push([previousValue, value]);
        }
        previousValue = value;
    }
    return toReturn;
}
function isBetween(value: number, a: number, b: number) {
    return (value >= a && value <= b) || (value >= b && value <= a);
}
export function useColor() {
    const rootValue = React.useContext(RootValueContext);
    const relativeValue = React.useContext(RelativeValueContext);
    const selectionValue = React.useContext(SelectionContext);
    function getC(colorDefinition: ColorDefinition, gradientRatio?: BindableType<number>, forceRelativeValue?: object): Color | undefined {
        if (Array.isArray(colorDefinition)) {
            const gradientRatioValue = gradientRatio !== undefined ? pickValue(rootValue, forceRelativeValue ?? relativeValue, selectionValue, gradientRatio) ?? 0 : 0;
            const pairs = pairValues([...colorDefinition].sort((a, b) => a.value - b.value));
            if (!pairs.length) {
                return undefined;
            }
            const colorizationState = pairs.find(([a, b]) => isBetween(gradientRatioValue, a.value, b.value));

            if (!colorizationState) {
                if (pairs[0][0].value > gradientRatioValue) return getColorValue(pairs[0][0].color);
                else return getColorValue(pairs.at(-1)![1].color);
            }
            const distance = colorizationState[1].value - colorizationState[0].value;
            const c1 = getColorValue(colorizationState[0].color) ?? "white";
            const c2 = getColorValue(colorizationState[1].color) ?? "white";
            return findColorBetween(c1, c2, (gradientRatioValue - colorizationState[0].value) / distance);
        }
        else {
            return getColorValue(colorDefinition);
        }
        function getColorValue(colorBound: BindableType<Color>) {
            const v = pickValue(rootValue, forceRelativeValue ?? relativeValue, selectionValue, colorBound);
            if (typeGuards.color(v)) {
                return v;
            }
            return undefined;
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useCallback(getC, [relativeValue, rootValue, selectionValue]);
}

function pickValue<T = any>(rootValue: any, relativeValue: any, selectionValue: any, bound: BindableType<T> | SelectionReference | undefined): T | undefined {
    if (typeof bound === "undefined")
        return undefined;

    if (isRelativeReference(bound)) {
        return getValue(relativeValue, getPathToValue(bound));
    }
    if (isAbsoluteReference(bound)) {
        return getValue(rootValue, getPathToValue(bound));
    }
    if (isSelectionReference(bound)) {
        return getValue(selectionValue, getPathToValue(bound));
    }
    if (isExpressionReference(bound)) {
        return evaluateExpression<T>(bound.substring(2), rootValue, relativeValue, selectionValue)
    }
    return bound;
}
export type PathToValue = (string | number)[];

function getValue<T = any>(data: any, pathToValue: PathToValue): T | undefined {
    for (const pathSegment of pathToValue) {
        if (typeof (data) === "undefined" || data === null) {
            return undefined;
        }
        if (Array.isArray(data) && typeof pathSegment === "number") {
            data = data.at(pathSegment);
        }
        else {
            data = data[pathSegment];
        }
    }
    return data;
}
function pickArrayValue<T = any>(rootValue: any, relativeValue: any, selectionValue: any, bound: BindableType<T[]> | SelectionReference | undefined): T[] | undefined {
    if (typeof bound === "undefined")
        return undefined;

    if (isRelativeReference(bound)) {
        return getArrayValue(relativeValue, getPathToValue(bound));
    }
    if (isAbsoluteReference(bound)) {
        return getArrayValue(rootValue, getPathToValue(bound));
    }
    if (isSelectionReference(bound)) {
        return getArrayValue(selectionValue, getPathToValue(bound));
    }
    if (isExpressionReference(bound)) {
        return evaluateExpression<T[]>(bound.substring(2), rootValue, relativeValue, selectionValue)
    }
    return bound;
}
function getArrayValue<T = any>(data: any, pathToValue: PathToValue): T[] {
    if (!data) {
        return [];
    }
    if (!pathToValue.length) {
        return Array.isArray(data) ? data : [data];
    }
    const [firstSegment, ...nextPathToValue] = pathToValue;
    if (Array.isArray(data)) {
        if (typeof firstSegment === "number") {
            return getArrayValue(data.at(firstSegment), nextPathToValue);
        }
        else {
            return data.flatMap(d => getArrayValue(d, pathToValue));
        }
    }
    else {
        return getArrayValue(data[firstSegment], nextPathToValue);
    }
}
function isEqualityFilter(filter: string): filter is EqualityFilter {
    const equality = filter.split("=");
    if (equality.length > 1) {
        return (isRelativeReference(equality[0]) || isAbsoluteReference(equality[0]))
            && (isRelativeReference(equality[1]) || isAbsoluteReference(equality[1]));
    }
    return false;
}
function isStrictInclusionFilter(filter: string): filter is StrictInclusionFilter {
    const inclusion = filter.split(" in ");
    if (inclusion.length > 1) {
        return isRelativeReference(inclusion[0])
            && (isAbsoluteReference(inclusion[1]) || isSelectionReference(inclusion[1]));
    }
    return false;
}
function isNonStrictInclusionFilter(filter: string): filter is NonStrictInclusionFilter {
    const inclusion = filter.split(" ins ");
    if (inclusion.length > 1) {
        return isRelativeReference(inclusion[0])
            && (isAbsoluteReference(inclusion[1]) || isSelectionReference(inclusion[1]));
    }
    return false;
}
type EqualityStructure = {
    type: "equality",
    left: RelativeReference | AbsoluteReference | SelectionReference,
    right: RelativeReference | AbsoluteReference | SelectionReference,
};
type StrictInclusionStructure = {
    type: "strictInclusion",
    left: RelativeReference,
    right: AbsoluteReference | SelectionReference
};
type NonStrictInclusionStructure = {
    type: "nonStrictInclusion",
    left: RelativeReference,
    right: AbsoluteReference | SelectionReference
};
type ExpressionStructure = {
    type: "expression",
    expression: string
};
type FilterStructure = EqualityStructure | StrictInclusionStructure | NonStrictInclusionStructure | ExpressionStructure;
export function isFilter(filter: string): filter is Filter {
    return isEqualityFilter(filter) || isStrictInclusionFilter(filter) || isNonStrictInclusionFilter(filter);
}
function parseFilter(filter: Filter): FilterStructure {
    if (isExpressionReference(filter)) {
        return {
            "type": "expression",
            "expression": filter.substring(2)
        }
    }
    const equality = filter.split("=");
    if (equality.length > 1) {
        return {
            type: "equality",
            left: equality[0] as RelativeReference | AbsoluteReference,
            right: equality[1] as RelativeReference | AbsoluteReference
        }
    }
    const strictInclusion = filter.split(" in ");
    if (strictInclusion.length > 1) {
        return {
            type: "nonStrictInclusion",
            left: strictInclusion[0] as RelativeReference,
            right: strictInclusion[1] as AbsoluteReference | SelectionReference
        }
    }
    const inclusion = filter.split(" ins ");
    if (inclusion.length > 1) {
        return {
            type: "strictInclusion",
            left: inclusion[0] as RelativeReference,
            right: inclusion[1] as AbsoluteReference | SelectionReference
        }
    }
    throw new Error(`"${filter}" is not a correct filter`);
}
export function useFilter(valuesFilter: Filter | undefined) {
    const filterStructure = React.useMemo(() => {
        if (valuesFilter && isFilter(valuesFilter))
            return parseFilter(valuesFilter);
        return undefined;
    }, [valuesFilter]);
    const getValue = useValue();
    const getArrayValue = useArrayValue();
    const getExpressionValue = useExpression();
    return React.useCallback((forceRelativeValue?: object) => {
        if (!filterStructure) {
            return true;
        }
        switch (filterStructure.type) {
            case "equality": return areEqual(filterStructure.left, filterStructure.right);
            case "strictInclusion": return isIncluded(filterStructure.left, filterStructure.right, true);
            case "nonStrictInclusion": return isIncluded(filterStructure.left, filterStructure.right, false);
            case "expression": return getExpressionValue("boolean", filterStructure.expression);
        }
        function isIncluded(left: RelativeReference, right: SelectionReference | AbsoluteReference, strict: boolean) {
            const arrV = getArrayValue("any", right, forceRelativeValue);
            if (!arrV?.length) return !strict;
            else {
                const val = getValue("any", left, forceRelativeValue);
                return arrV.includes(val);
            }
        }
        function areEqual(left: RelativeReference | AbsoluteReference | SelectionReference, right: RelativeReference | AbsoluteReference | SelectionReference) {
            return getValue("any", left, forceRelativeValue) === getValue("any", right, forceRelativeValue);
        }
    }, [filterStructure, getArrayValue, getExpressionValue, getValue])
}
function getPathToValue(bound: string) {
    return bound.split(".").filter((i, idx) => idx > 0).map(tryParseSegment);
}
function tryParseSegment(segment: string): number | string {
    let parsed = Number(segment);
    return isNaN(parsed) ? segment : parsed;
}
