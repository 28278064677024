import { Fab } from "@material-ui/core";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useDrag } from "react-dnd";
import {
    dndMenuNodeType,
    ICollectedProps,
    IDropResult,
    IMenuDefinitionTreeNodeLevel1,
    IMenuDefinitionTreeNodeLevel2,
    IMyDragObjectWithType
} from "./menuTree";
import { UIEventHandler } from "react";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";

type MenuGroupSourceModelTypes = IMenuDefinitionTreeNodeLevel1 | IMenuDefinitionTreeNodeLevel2;

export interface IMenuGroupSourceProps<TModel extends MenuGroupSourceModelTypes> {
    model: TModel,
    label: string,
    onClick?: UIEventHandler<HTMLButtonElement>,
    onDrop?: UIEventHandler<HTMLButtonElement>,
}

export function MenuGroupSource<TModel extends MenuGroupSourceModelTypes>(
    {model, label, onClick}: IMenuGroupSourceProps<TModel>) {
    const [collected, drag, dragPreview] = useDrag<IMyDragObjectWithType, IDropResult, ICollectedProps>({
        item: {
            type: dndMenuNodeType,
            node: {
                expanded: false,
                children: [],
                model: model
            },
        },
        options: {dropEffect: "copy"},
    });

    return collected?.isDragging
        ? <div ref={dragPreview}/>
        : <Fab ref={drag} {...collected} size="small" variant="extended" onClick={onClick}>
            <DragIndicatorIcon/><CreateNewFolderIcon/> {label}
        </Fab>
}