import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFeatureModel, IStatisticDefinitionSetSummaryModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useCallback, useEffect } from "react";

export const statisticDefinitionSetsScreen = {
    route: "/StatisticDefinitionSets/:tab?" as const,
    component: StatisticDefinitionSets,
    label: "Statistic Definition Sets",
    tabs: {
        detail: "detail",
        definition: "definition"
    }
}

function StatisticDefinitionSets() {
    const navigate = useScreenNavigate()
    const { statisticDefinitionSetAllLoading, statisticDefinitionSets = [] } = useReduxSelections("statisticDefinitionSet");
    const { statisticDefinitionSetLoadAll } = useReduxActions("statisticDefinitionSet");

    useEffect(() => {
        statisticDefinitionSetLoadAll()
    }, [statisticDefinitionSetLoadAll]);

    const columns: IColumnDefinition[] = [
        { name: "Code", title: "Code", getCellValue: ({ code }: IStatisticDefinitionSetSummaryModel) => code, filteringEnabled: true },
        { name: "Name", title: "Name", getCellValue: ({ name }: IStatisticDefinitionSetSummaryModel) => name, filteringEnabled: true }
    ];

    const state: IGridState = {
        "Code": { width: 180 },
        "Name": { width: 280 }
    };

    const getRowKey = ({ id }: IStatisticDefinitionSetSummaryModel) => id;

    const handleRowSelect = useCallback(({ id }: IStatisticDefinitionSetSummaryModel) => navigate("StatisticDefinitionSet", { id }), [navigate])
    const handleAddNewClick = useCallback(() => navigate("StatisticDefinitionSet", { id: 0 }), [navigate])

    return (<SearchPanel title="Statistics Sets" isQuerying={statisticDefinitionSetAllLoading}
        addAllowed={IFeatureModel.StatisticDefinitionSetWrite}
        onAddClick={handleAddNewClick}>
        <ExtendedGrid
            rows={statisticDefinitionSets}
            columns={columns}
            getRowId={getRowKey}
            onRowClick={handleRowSelect}
            initialState={state} />
    </SearchPanel>);
}
