import React from "react";
import createEngine from '@projectstorm/react-diagrams';
import { TextNodeFactory } from "./TextNode";
import { JsxNodeFactory } from "./JsxNode";
import { EtlOperatorNodeFactory } from "./EtlOperatorNode";

const engine = createEngine();
engine.getNodeFactories().registerFactory(new TextNodeFactory());
engine.getNodeFactories().registerFactory(new JsxNodeFactory());
engine.getNodeFactories().registerFactory(new EtlOperatorNodeFactory());

export default React.createContext(engine);
