import { CustomScreenModel, IMacroScriptCategoryModel } from "proxy/apiProxy";
import FieldBox from "tools/components/FieldBox";
import { oProps, IDictionary } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";

export interface ICustomScreenDataProps {
    referenceMacroScriptCategories: IDictionary<IMacroScriptCategoryModel>
}

export type CustomScreenFormValues = CustomScreenModel & {
    hasUserTemplate: boolean
}

export default function CustomScreenData({ referenceMacroScriptCategories }: ICustomScreenDataProps) {
    return (
        <FieldBox display="flex" flexDirection="column">
            <FormTextField name={oProps<CustomScreenFormValues>().path("code")} label="Code" fullWidth={false} required={true} />
            <FormTextField name={oProps<CustomScreenFormValues>().path("name")} label="Name" required={true} />
            <FormSimpleSelectField name={oProps<CustomScreenFormValues>().path("categoryId")} label="Category" options={referenceMacroScriptCategories} />
            <FormTextField name={oProps<CustomScreenFormValues>().path("description")} label="Description" multiline={true} />
            <FormCheckBoxField name={oProps<CustomScreenFormValues>().path("createOnly")} label="Create only" />
            <FormCheckBoxField name={oProps<CustomScreenFormValues>().path("hasUserTemplate")} label="Has user template" />
            <FormCheckBoxField name={oProps<CustomScreenFormValues>().path("publishOnPortal")} label="Publish on portal" />
        </FieldBox>
    );
}

