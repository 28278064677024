import { Epic } from "redux-observable";
import { from, merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import {
    counterpartyRelationshipsApi,
    customScreensApi,
    CustomScreenSummaryModel,
    ICollaborationTypeModel,
    ICounterpartyRelationshipModel,
    IFrequencyTypeModel,
    IGetRelationshipModel,
    IInvestorRelationshipModel,
    investorRelationshipsApi,
    IRoleDomainModel,
    IRolePositionModel,
    IRoleRelationshipModel,
    macroScriptsApi,
    RelationshipModel,
    roleRelationshipsApi
} from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { today } from "tools/lib/utility";
import { getRelationshipApi } from "./getApi";

export const loadProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipProcessExecutionsLoad"),
        mergeMap(({ id, type }) => getRelationshipApi(type).getProcessesAsync({ id })),
        map(ActionFactories.relationship.relationshipProcessExecutionsLoaded));

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipDocumentsLoad"),
        mergeMap(({ id, type }) => getRelationshipApi(type).getDocumentsAsync({ id })),
        map(ActionFactories.relationship.relationshipDocumentsLoaded)); 

export const submitCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipCustomScreenDataSubmit"),
        mergeMap(({ type, ...payload }) => getRelationshipApi(type).submitCustomScreenDataAsync(payload)),
        map(ActionFactories.relationship.relationshipCustomScreenDataSubmitted));
export const loadRelationships: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipLoadAll"),
        mergeMap(type => getRelationshipApi(type).getAllAsync({})),
        map(ActionFactories.relationship.relationshipLoadedAll));
export const loadCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipCustomScreenDatasLoad"),
        mergeMap(({ type, id }) => getRelationshipApi(type).getLastCustomScreenDatasAsync({ id })),
        map(ActionFactories.relationship.relationshipCustomScreenDatasLoaded));

function createEmptyRelationship(type: RelationshipModel["type"]): RelationshipModel {
    switch (type) {
        case "RoleRelationshipModel": return {
            type: "RoleRelationshipModel",
            id: 0,
            permissions: [],
            portfolioIds: [],
            sicavIds: [],
            entityId: 0,
            startDate: today(),
            investorIds: [],
            fullScope: true,
            collaborationType: ICollaborationTypeModel.Collaborator,
            domain: IRoleDomainModel.Accounting,
            classifications: {},
            position: IRolePositionModel.Employee
        } as IRoleRelationshipModel;
        case "CounterpartyRelationshipModel": return {
            type: "CounterpartyRelationshipModel",
            id: 0,
            portfolioIds: [],
            sicavIds: [],
            entityId: 0,
            startDate: today(),
            processIds: [],
            investorIds: [],
            isAuthorized: false,
            fullScope: true
        } as ICounterpartyRelationshipModel;
        case "InvestorRelationshipModel": return {
            type: "InvestorRelationshipModel",
            id: 0,
            portfolioIds: [],
            sicavIds: [],
            entityId: 0,
            startDate: today(),
            processIds: [],
            classifications: {},
            investorIds: [],
            statementFrequency: IFrequencyTypeModel.Monthly,
            fullScope: true,
            investorOperations: [],
            inDefault: false
        } as IInvestorRelationshipModel;
    }
}
export const loadRelationship: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("relationship", "relationshipLoad"),
            share());

        const relationship$ = merge(
            requestedId$.pipe(
                filter(({ id }) => !!id),
                mergeMap(({ type, id }) => getRelationshipApi(type).getAsync({ id }))),
            requestedId$.pipe(
                filter(({ id }) => !id),
                map(({ type }) => ({ relationship: createEmptyRelationship(type), entities: {}, relationships: {}, portfolios: {} } as IGetRelationshipModel)))
        ).pipe(share());

        const customScreens$ = relationship$.pipe(
            mergeMap(async loadedRelationship => {
                const screenType = (function (): CustomScreenSummaryModel["type"] {
                    switch (loadedRelationship.relationship.type) {
                        case "CounterpartyRelationshipModel": return "CounterpartyCustomScreenSummaryModel";
                        case "InvestorRelationshipModel": return "InvestorCustomScreenSummaryModel";
                        case "RoleRelationshipModel": return "RoleCustomScreenSummaryModel";
                    }
                })();
                const allCustomScreens = (await customScreensApi.getAllAsync({})).filter(customScreen => customScreen.type === screenType);
                return await Promise.all(allCustomScreens.map(({ id }) => customScreensApi.getAsync({ id })));
            }),
            map(ActionFactories.relationship.relationshipCustomScreensLoaded));

        return merge(
            relationship$.pipe(map(ActionFactories.relationship.relationshipLoaded)),
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad())),
            requestedId$.pipe(
                filter(id => !!id),
                map(ActionFactories.relationship.relationshipCustomScreenDatasLoad)),
            customScreens$,
        );
    };

export const getMonitoringResultLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipMonitoringResultLoad"),
        mergeMap(macroScriptsApi.getMonitoringResultForTargetAsync),
        map(ActionFactories.relationship.relationshipMonitoringResultLoaded));

export const saveRelationshipEpic: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("relationship", "relationshipSave"),
        mergeMap(relationship => {
            switch (relationship.type) {
                case "CounterpartyRelationshipModel":
                    return from(counterpartyRelationshipsApi.saveAsync({ model: relationship }));
                case "InvestorRelationshipModel":
                    return from(investorRelationshipsApi.saveAsync({ model: relationship }));
                case "RoleRelationshipModel":
                    return from(roleRelationshipsApi.saveAsync({ model: relationship }));
            }
        }),
        map(ActionFactories.relationship.relationshipSaved));

export const deleteRoleRelationship: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("relationship", "relationshipDeleteCurrent"),
            mergeMap(({ id, type }) => getRelationshipApi(type).deleteAsync({ id }).then(() => id)),
            map(ActionFactories.relationship.relationshipDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
