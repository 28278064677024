import * as React from "react";
import { useEffect } from "react";
import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import {
    IBenchmarkTargetExposureModel,
    IFeatureModel,
    IPortfolioClosingModel,
    IPortfolioTypeModel,
    IPositionModel,
    ISecurityTypeModel,
    IUniverseScopeTypeModel,
    PortfolioModel,
    SecuritySummaryModel
} from "proxy/apiProxy";
import PortfolioData from "./PortfolioData";
import HistoricalValues, { IColumnPresentation } from "components/global/HistoricalValues";
import { GrantRequest, isNumber, oProps, useNumber } from "tools/lib/utility";
import PortfolioComposition from "./PortfolioComposition";
import PortfolioBenchmarkComposition from "./PortfolioBenchmarkComposition";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import PortfolioScriptEditor from "./PortfolioScriptEditor";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import PortfolioBenchmarkExposure from "./PortfolioBenchmarkExposure";
import { getPortfolioTypeLabel } from "./getPortfolioTypeLabel";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import CustomViewDatas from "features/Relationship/CustomViewDatas";
import PortfolioPeers from "./PortfolioPeers";
import useScopeButtonsMenu from "components/global/useScopeButtonsMenu";
import MonitoringResultGroups from "components/global/MonitoringResults";
import Closings from "./Closings";
import { IFormManagedSubFundModel, IFormPortfolioClosingModel, IFormPortfolioModel } from "./FormPortfolioModel";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DialogPanel from "tools/components/DialogPanel";
import FieldBox from "tools/components/FieldBox";
import FormTextField from "tools/fieldComponents/FormTextField";
import { Box } from "@material-ui/core";
import SecuritySelectField from "components/searchers/SecuritySelectField";
import SecuritySummary from "components/summaries/SecuritySummary";
import { IGetSecuritySummary } from "features/Reference/slice";
import { getConfig } from "lib/userManager";
import AlertDialog from "../../tools/components/AlertDialog";
import { ProcessExecutionList } from "features/ProcessExecution/ProcessExecutionList";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";
import { useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";
import { DocumentsGrid } from "features/Document/DocumentsScreen/DocumentsGrid";

export const myPortfolioScreen = {
    route: "/portfolios/myportfolios/:id/:tab?" as const,
    label: "My Portfolio",
    component: Portfolio,
    tabs: {
        detail: "details",
        complianceMacro: "complianceMacro",
        dealingMacro: "dealingMacro",
        composition: "composition",
        benchmarkComposition: "benchmarkcomposition",
        closings: "closings",
        peers: "peers",
        computations: "computations",
        historicalValues: "historicalvalues",
        customViews: "customScreens",
        targetExposures: "targetExposures",
        dashboard: "dashboard",
        documents: "documents",
        processes: "processes"
    }
}

const statisticTypeLabel = {
    MKT: "NAV",
    TNA: "Total net asset",
    SUB: "Subscription",
    RED: "Redemption",
    TER: "Total expense ratio",
    OGC: "Ongoing charges"
}
type IHistoricalValueTypeModel = keyof typeof statisticTypeLabel;

type IPortfolioDataSeries = Record<IHistoricalValueTypeModel, string | IColumnPresentation>;

function Portfolio() {

    const {
        current,
        historicalValues,
        loading,
        historicalValuesLoading,
        pricingDateLoading,
        pricingDatesLoading,
        pricingDates,
        currentPricingDate,
        historicalValuesVisualizationType,
        compositionVisualizationType,
        composition,
        benchmarkComposition,
        dictionaries,
        saving,
        compliancePolicyValidateScriptMetadata,
        compliancePolicyMacroScriptCheckResult = { errors: [] },
        dealingPolicyValidateScriptMetadata,
        dealingPolicyMacroScriptCheckResult = { errors: [] },
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        processExecutionsLoading,
        processExecutions = [],
        documentsLoading,
        documents
    } = useReduxSelections("managedPortfolio");
    const { macroScriptList } = useReduxSelections("macroScript");
    const monitoringMacros = React.useMemo(() => macroScriptList.filter(i => (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel") && i.singleScope === IUniverseScopeTypeModel.Portfolio), [macroScriptList]);
    const {
        portfolioAddSecurityInDictionary,
        portfolioAddIndexInDictionary,
        portfolioSave,
        portfolioAddEntityInDictionary,
        portfolioCompliancePolicyValidateScript,
        portfolioDealingPolicyValidateScript,
        portfolioPricingDateLoad,
        portfolioDelete,
        portfolioHistoricalValuesVisualizationTypeChange,
        portfolioCompositionVisualizationTypeChange,
        portfolioCustomScreenDataSubmit,
        portfolioMonitoringResultLoad,
        portfolioPositionSave,
        portfolioPositionDelete,
        portfolioLoad,
        portfolioPricingDatesLoad,
        portfolioProcessExecutionsLoad,
        portfolioHistoricalValuesLoad,
        portfolioDocumentsLoad,
    } = useReduxActions("managedPortfolio");
    const { macroScriptLoadAll } = useReduxActions("macroScript")
    const { general: { enableStudio } } = getConfig();
    const {
        referenceCurrencies = {},
        referenceCountries = {},
    } = useReduxSelections("reference");
    const navigate = useScreenNavigate()
    const {
        classificationTypes: securityClassificationTypes,
        classificationTypesFlat: securityClassificationTypesFlat
    } = useReduxSelections("securityClassificationType");
    const { parameters: { portfolioExtensionFields = [], subFundExtensionFields = [] } } = useReduxSelections("parameters");
    const { statisticDefinitionSets = [] } = useReduxSelections("statisticDefinitionSet");
    const isGranted = useGrants();

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof myPortfolioScreen>()
    const idNum = useNumber(id)

    useEffect(() => {
        if (isNumber(idNum)) {
            portfolioLoad(idNum)
            portfolioPricingDatesLoad(idNum)
        } else if (id && id in IPortfolioTypeModel) {
            portfolioLoad(id as IPortfolioTypeModel)
        }
        macroScriptLoadAll()
    }, [id, idNum, macroScriptLoadAll, portfolioLoad, portfolioPricingDatesLoad])

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "processes" && !processExecutions)
            portfolioProcessExecutionsLoad(idNum)
    }, [idNum, portfolioProcessExecutionsLoad, processExecutions, tabValue]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "documents" && !documentsLoading && !documents)
            portfolioDocumentsLoad(idNum)
    }, [idNum, portfolioDocumentsLoad, documents, documentsLoading, tabValue]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "historicalValues" && !historicalValues?.length)
            portfolioHistoricalValuesLoad(idNum)
    }, [historicalValues, idNum, portfolioHistoricalValuesLoad, tabValue]);

    const handleNewExecutionProcess = React.useCallback(() => {
        navigate("ProcessExecutionNew", {
            type: "PortfolioProcessExecutionModel",
            targetId: current?.id ?? 0
        });
    }, [current?.id, navigate])

    const currentForm = React.useMemo(() => {
        if (!current) {
            return undefined;
        }
        if (current.type === "DiscretionaryPortfolioModel") {
            const oput: IFormPortfolioModel = { ...current };
            return oput;
        }
        const { closings, ...formPortfolioModel } = current;
        const outputForm: IFormPortfolioModel = {
            ...formPortfolioModel,
            closings: closings.map(({ capitalCalls, distributions, ...closing }) => {
                return {
                    ...closing,
                    elements: [
                        ...capitalCalls.map(capitalCall => ({ ...capitalCall, type: "capitalCall" })),
                        ...distributions.map(distribution => ({ ...distribution, type: "distribution" })),
                    ]
                } as IFormPortfolioClosingModel;
            })
        };
        return outputForm;
    }, [current]);
    const [addingNewPosition, setAddingNewPosition] = React.useState(false);
    const handleAddPositionClick = React.useCallback(() => setAddingNewPosition(true), []);
    const handleCloseNewPosition = React.useCallback(() => setAddingNewPosition(false), []);
    const [deletingPosition, setDeletingPosition] = React.useState(-1);
    const extraReportActionButtons = useScopeButtonsMenu(IUniverseScopeTypeModel.Portfolio, currentForm?.id);
    const extraActionButtons = React.useMemo(() => {
        const ret = [...extraReportActionButtons];
        if (tabValue === "composition" && composition) {
            ret.push({
                onClick: handleAddPositionClick,
                label: "Add new position",
                icon: PlaylistAddIcon
            });
        }
        ret.push({
            label: "Add Process Execution",
            icon: NotebookCheckOutlineIcon,
            onClick: handleNewExecutionProcess,
            feature: IFeatureModel.ManagedPortfolioWrite//,
        });
        return ret;
    }, [composition, extraReportActionButtons, handleAddPositionClick, handleNewExecutionProcess, tabValue]);

    if (!currentForm) {
        return <DetailPanelLoading tabNumber={6} hasSubTitle={true} />;
    }
    const handleLoadResult = (id: number) => portfolioMonitoringResultLoad({ id, targetId: currentForm.id });
    const title = !!currentForm.id ? (currentForm.name || "") : getPortfolioTypeLabel(currentForm.type);
    const tabs: ISubMenuTabs<typeof myPortfolioScreen>[] = [{
        label: "Data",
        value: "detail"
    }, {
        label: "Benchmark",
        value: "targetExposures"
    }];
    if (currentForm?.id && !!customScreens && customScreens.length) {
        tabs.push({
            label: "Custom Data",
            value: "customViews"
        });
    }
    if (!!monitoringMacros.length) {
        tabs.push({
            label: "Dashboard",
            value: "dashboard"
        });
    }
    if (currentForm.id) {
        if (composition) {
            tabs.push({
                label: "Positions",
                value: "composition"
            });
        }
        tabs.push({
            label: "Processes",
            value: "processes"
        });
        tabs.push({
            label: "Documents",
            value: "documents"
        });
    }
    tabs.push({
        label: "Peers",
        value: "peers"
    });
    if (currentForm.type === "ManagedSubFundModel") {
        tabs.push({
            label: "Closings",
            value: "closings"
        });
    }
    if (currentForm.id) {
        tabs.push({
            label: "Hist. Series",
            value: "historicalValues"
        });
        if (benchmarkComposition) {
            tabs.push({
                label: "Bench. Positions",
                value: "benchmarkComposition"
            });
        }
    }
    if (enableStudio) {
        tabs.push({
            label: "Compliance",
            value: "complianceMacro"
        });
        tabs.push({
            label: "Dealing",
            value: "dealingMacro"
        });
    }


    const handleSecuritySelected = (securityId: number) => {
        const security = dictionaries.securities[securityId];
        if (security.type === "CashSummaryModel") {
            navigate("BankAccount", { id: securityId });
        } else if (security.type === "LoanSummaryModel") {
            navigate("Loan", { id: securityId });
        } else if (security.type === "ShareClassSummaryModel" && security.isUnderManagement) {
            navigate("MyShareClass", { id: securityId });
        } else {
            navigate("Security", { id: securityId });
        }
    }
    const handleIndexSelected = (indexId: number) => {
        navigate("Index", { id: indexId });
    }
    const getHistoricalValueTypes = () => {
        if (!currentForm?.type) {
            return {};
        }
        switch (currentForm.type) {
            case "DiscretionaryPortfolioModel":
                return {
                    MKT: statisticTypeLabel["MKT"],
                    TNA: statisticTypeLabel["TNA"],
                    SUB: statisticTypeLabel["SUB"],
                    RED: statisticTypeLabel["RED"],
                } as IPortfolioDataSeries;
            case "ManagedSubFundModel":
                return {
                    TNA: statisticTypeLabel["TNA"],
                    SUB: statisticTypeLabel["SUB"],
                    RED: statisticTypeLabel["RED"],
                    TER: statisticTypeLabel["TER"],
                    OGC: statisticTypeLabel["OGC"]
                } as IPortfolioDataSeries;
        }
    }
    const getGraphHistoricalValueTypes = () => {
        if (currentForm && currentForm.type) {
            switch (currentForm.type) {
                case "DiscretionaryPortfolioModel":
                    return {
                        // [IHistoricalValueTypeModel.MKT]: "NAV",
                        TNA: statisticTypeLabel["TNA"],
                    } as IPortfolioDataSeries;
                case "ManagedSubFundModel":
                    return {
                        TNA: statisticTypeLabel["TNA"],
                    } as IPortfolioDataSeries;
            }
        }
        return {};
    }
    const handlePositionChanged = (position: IPositionModel) => {
        if (current?.id) {
            position.portfolioId = current.id;
            portfolioPositionSave(position);
        }
    };
    const handlePositionAdd = (position: IPositionModel) => {
        if (currentPricingDate && current?.id) {
            position.date = currentPricingDate;
            position.portfolioId = current.id;
            setAddingNewPosition(false);
            portfolioPositionSave(position);
        }
    }

    const handlePositionDeleteAlert = (resp: boolean) => {
        setDeletingPosition(-1);
        if (resp) {
            if (deletingPosition !== -1) {
                portfolioPositionDelete(deletingPosition);
            }
        }
    }
    const handlePositionDelete = (positionId: number) => {
        setDeletingPosition(positionId);
    }

    const handleDelete = () => {
        if (currentForm?.id) {
            portfolioDelete(currentForm.id);
        }
    }

    const handleSubmit = (values: IFormPortfolioModel, { setSubmitting }: FormikHelpers<IFormPortfolioModel>) => {
        if (values.type !== "DiscretionaryPortfolioModel") {
            const { closings, complianceMacro, dealingMacro, ...model } = values;
            portfolioSave({
                portfolio: {
                    ...model,
                    closings: closings.map(({ elements, ...closing }) => {
                        return {
                            ...closing,
                            capitalCalls: elements.filter(i => i.type === "capitalCall"),
                            distributions: elements.filter(i => i.type === "distribution"),
                        } as IPortfolioClosingModel;
                    })
                },
                complianceMacro,
                dealingMacro
            });
        } else {
            const { complianceMacro, dealingMacro, ...model } = values;
            portfolioSave({ portfolio: model, complianceMacro, dealingMacro });
        }
        setSubmitting(false);
    }

    const historicalValueProperties = getHistoricalValueTypes();
    const graphHistoricalValueProperties = getGraphHistoricalValueTypes();

    const handleDataSubmit = (customScreenId: number, values: any) => portfolioCustomScreenDataSubmit({
        id: currentForm.id,
        customScreenId,
        values
    });
    const canEditCustomData = isGranted({
        feature: IFeatureModel.ManagedPortfolioCustomDataWrite,
        portfolioId: currentForm.id
    });


    return <Formik onSubmit={handleSubmit} initialValues={currentForm} enableReinitialize={true} validateOnMount={true}
        validate={validateForm}>{renderForm}</Formik>;

    function validateForm({ benchmarkExposures }: IFormPortfolioModel): void | object {
        const errors: Record<string, string> = {};
        const groupedExposures = benchmarkExposures.reduce((a, v, idx) => {
            let existingGroup = a[v.fromDate.toDateString()];
            if (!existingGroup) {
                existingGroup = [];
                a[v.fromDate.toDateString()] = existingGroup;
            }
            existingGroup.push({ ...v, idx });
            return a;
        }, {} as { [fromDate: string]: (IBenchmarkTargetExposureModel & { idx: number })[] });
        for (const exposureGroup of Object.values(groupedExposures).filter(i => i.length > 1)) {
            for (const { idx } of exposureGroup) {
                errors[oProps<PortfolioModel>().path("benchmarkExposures", idx, "fromDate")] = "Exposures already defined this date"
            }
        }
        return errors;
    }

    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<IFormPortfolioModel>) {
        const editGrantRequest: GrantRequest | GrantRequest[] | Record<string, GrantRequest> = [IFeatureModel.ManagedPortfolioWrite];
        const deleteGrandRequest: GrantRequest | GrantRequest[] | Record<string, GrantRequest> = [{
            feature: IFeatureModel.ManagedPortfolioWrite,
            portfolioId: currentForm?.id ?? 0
        }];
        const saveGrantRequest: GrantRequest | GrantRequest[] | Record<string, GrantRequest> = (values.type === "ManagedSubFundModel" && currentForm?.type === "ManagedSubFundModel" && values.sicavId !== currentForm.sicavId)
            ? {
                "rightOnNewSicav": { feature: IFeatureModel.ManagedPortfolioWrite, sicavId: values.sicavId ?? 0 },
                "rightOnOldSicav": { feature: IFeatureModel.ManagedPortfolioWrite, sicavId: currentForm.sicavId ?? 0 }
            }
            : [{ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId: currentForm?.id ?? 0 }];
        const canChangeSicav = (currentForm?.type === "ManagedSubFundModel" && currentForm.sicavId) ? isGranted({
            feature: IFeatureModel.ManagedPortfolioWrite,
            sicavId: currentForm.sicavId
        }) : true;
        return <WriteAccess value={editGrantRequest}>
            {isGranted(editGrantRequest) && <AddPosition
                dictionaries={dictionaries}
                isOpened={addingNewPosition}
                onCancel={handleCloseNewPosition}
                onPositionAdd={handlePositionAdd}
                onSecuritySelected={portfolioAddSecurityInDictionary} />}
            {isGranted(editGrantRequest) &&
                <AlertDialog open={deletingPosition !== -1} onClose={handlePositionDeleteAlert} title="Delete Position">
                    Do you really want to delete this Position?
                </AlertDialog>}
            <DetailPanel
                isQuerying={loading || historicalValuesLoading || pricingDateLoading || pricingDatesLoading || saving || customScreenDataLoading || customScreenDataSubmitting || processExecutionsLoading || documentsLoading}
                title={title}
                subTitle={getPortfolioTypeLabel(values.type)}
                noPadding={["dashboardAnalytics", "composition", "complianceMacro", "benchmarkComposition"].includes(tabValue ?? "")}
                canSave={dirty && isValid}
                saveAllowed={saveGrantRequest}
                deleteAllowed={deleteGrandRequest}
                onSaveClick={submitForm}
                canDelete={!!values.id}
                onDeleteClick={handleDelete}
                badge={!values.id ? "new" : undefined}
                tabs={tabs}
                tabValue={tabValue}
                actions={extraActionButtons}>
                {(tabValue === "detail") && <PortfolioData
                    portfolioExtensionFields={portfolioExtensionFields}
                    subFundExtensionFields={subFundExtensionFields}
                    onEntityLoaded={portfolioAddEntityInDictionary}
                    dictionaries={dictionaries}
                    onIndexLoaded={portfolioAddIndexInDictionary}
                    referenceCurrencies={referenceCurrencies}
                    referenceCountries={referenceCountries}
                    onSecurityLoaded={portfolioAddSecurityInDictionary}
                    onSecurityClick={handleSecuritySelected}
                    statisticDefinitionSets={statisticDefinitionSets}
                    classificationTypes={securityClassificationTypes}
                    canChangeSicav={canChangeSicav}
                    currentValues={values} />}
                {(tabValue === "peers") && <PortfolioPeers
                    peersField={oProps<IFormPortfolioModel>().path("peers")}
                    dictionaries={dictionaries}
                    onSecurityLoaded={portfolioAddSecurityInDictionary}
                    onIndexLoaded={portfolioAddIndexInDictionary}
                    onIndexClick={handleIndexSelected}
                    onSecurityClick={handleSecuritySelected} />}
                {(tabValue === "historicalValues") && <HistoricalValues
                    historicalValues={historicalValues}
                    properties={historicalValueProperties}
                    graphProperties={graphHistoricalValueProperties}
                    onVisualizationTypeChanged={portfolioHistoricalValuesVisualizationTypeChange}
                    visualizationType={historicalValuesVisualizationType} />}
                {(tabValue === "closings") &&
                    <Closings formFieldName={oProps<IFormManagedSubFundModel>().path("closings")}
                        referenceCurrencies={referenceCurrencies} currencyId={values.currencyId} />}
                {(tabValue === "composition" && composition) && <PortfolioComposition
                    dictionaries={dictionaries}
                    composition={composition}
                    classificationTypes={securityClassificationTypes}
                    classificationTypesFlat={securityClassificationTypesFlat}
                    referenceCurrencies={referenceCurrencies}
                    pricingDate={currentPricingDate}
                    pricingDates={pricingDates}
                    referenceCountries={referenceCountries}
                    onTargetSecuritySelected={handleSecuritySelected}
                    pricingDatesLoading={pricingDatesLoading}
                    onPricingDateChanged={portfolioPricingDateLoad}
                    onVisualizationTypeChanged={portfolioCompositionVisualizationTypeChange}
                    visualizationType={compositionVisualizationType}
                    editable={true}
                    onPositionChanged={handlePositionChanged}
                    onPositionDelete={handlePositionDelete} />}
                {(tabValue === "benchmarkComposition" && benchmarkComposition) && <PortfolioBenchmarkComposition
                    dictionaries={dictionaries}
                    composition={benchmarkComposition}
                    onTargetIndexSelected={handleIndexSelected}
                    classificationTypes={securityClassificationTypes}
                    classificationTypesFlat={securityClassificationTypesFlat}
                    referenceCurrencies={referenceCurrencies}
                    pricingDate={currentPricingDate}
                    pricingDates={pricingDates}
                    referenceCountries={referenceCountries}
                    onTargetSecuritySelected={handleSecuritySelected}
                    pricingDatesLoading={pricingDatesLoading}
                    onPricingDateChanged={portfolioPricingDateLoad}
                    onVisualizationTypeChanged={portfolioCompositionVisualizationTypeChange}
                    visualizationType={compositionVisualizationType} />}
                {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
                    monitoringMacros={monitoringMacros}
                    onLoadResult={handleLoadResult}
                    resultGroups={monitoringResults} />}
                {(tabValue === "complianceMacro" && enableStudio) && <PortfolioScriptEditor
                    fieldName={oProps<IFormPortfolioModel>().path("complianceMacro")}
                    metadata={compliancePolicyValidateScriptMetadata}
                    onSaveRequest={submitForm}
                    onScriptChanged={portfolioCompliancePolicyValidateScript}
                    scriptErrors={compliancePolicyMacroScriptCheckResult.errors} />}
                {(tabValue === "dealingMacro" && enableStudio) && <PortfolioScriptEditor
                    fieldName={oProps<IFormPortfolioModel>().path("dealingMacro")}
                    metadata={dealingPolicyValidateScriptMetadata}
                    onSaveRequest={submitForm}
                    onScriptChanged={portfolioDealingPolicyValidateScript}
                    scriptErrors={dealingPolicyMacroScriptCheckResult.errors} />}
                {(tabValue === "targetExposures") && <PortfolioBenchmarkExposure
                    targetExposureField={oProps<IFormPortfolioModel>().path("benchmarkExposures")}
                    referenceCountries={referenceCountries}
                    referenceCurrencies={referenceCurrencies}
                    indexes={dictionaries.indexes}
                    securities={dictionaries.securities}
                    onShareClassLoaded={portfolioAddSecurityInDictionary}
                    onIndexLoaded={portfolioAddIndexInDictionary}
                    onSecurityClick={handleSecuritySelected}
                    onIndexClick={handleIndexSelected} />}
                {(tabValue === "documents") && <DocumentsGrid
                    documents={documents ?? []}
                    dictionaries={dictionaries} />
                }
                {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) && <CustomViewDatas
                    canEdit={canEditCustomData}
                    dictionaries={dictionaries}
                    customScreenLoading={customScreenDataLoading}
                    dataSubmitting={customScreenDataSubmitting}
                    customScreens={customScreens}
                    onDataSubmit={handleDataSubmit}
                    customScreenDatas={customScreenDatas} />
                }
                {
                    (tabValue === "processes") && <ProcessExecutionList
                        processExecutions={processExecutions}
                        dictionaries={dictionaries} />
                }
            </DetailPanel >
        </WriteAccess >
    }
}

interface IAddPositionProps {
    onPositionAdd: (position: IPositionModel) => void;
    isOpened: boolean;
    onCancel: () => void;
    onSecuritySelected: (found: IGetSecuritySummary) => void;
    dictionaries: {
        securities: Record<number, SecuritySummaryModel>
    }
}

function AddPosition({ onPositionAdd, onCancel, isOpened, dictionaries, onSecuritySelected }: IAddPositionProps) {
    const emptyPosition = {} as IPositionModel;
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    return <Formik onSubmit={onPositionAdd} initialValues={emptyPosition} enableReinitialize={true}
        validateOnMount={true}>{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<IPositionModel>) {
        return <DialogPanel
            onBackClick={onCancel}
            title="Create Position"
            actions={[{ label: "Create Position", onClick: submitForm }]}
            isOpened={isOpened}>
            <FieldBox display="flex" flexDirection="column">
                <Box>
                    <SecuritySelectField name={oProps<IPositionModel>().path("securityId")}
                        onSelected={onSecuritySelected} type={ISecurityTypeModel.All} />
                    <SecuritySummary name={oProps<IPositionModel>().path("securityId")}
                        currencies={referenceCurrencies} {...dictionaries} />
                </Box>
                <FormTextField name={oProps<IPositionModel>().path("weight")} label="Weight" isPercentage />
                <FormTextField name={oProps<IPositionModel>().path("quantity")} label="Quantity" required isNumber />
                <FormTextField name={oProps<IPositionModel>().path("marketValueInPortfolioCcy")}
                    label="Market Value In Portfolio Ccy" required isNumber />
            </FieldBox>
        </DialogPanel>;
    }
}