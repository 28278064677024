import { Epic } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { dailyDataApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction, IState } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadPositions: Epic<IAnyAction, IAnyAction, IState>
    = action$ => action$.pipe(
        mapToPayload("portfolioHistoricalValue", "portfolioHistoricalValueSearch"),
        mergeMap(dailyDataApi.searchHistoricalValuesAsync),
        map(ActionFactories.portfolioHistoricalValue.portfolioHistoricalValueLoaded));
