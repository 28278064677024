import { Epic } from "redux-observable";
import { forkJoin, from, merge, Observable, of } from "rxjs";
import { filter, map, mergeMap, share, switchMap } from "rxjs/operators";
import {
    customScreensApi,
    entitiesApi,
    EntityModel,
    ICompanyModel,
    IEntityGroupModel,
    IEntityTypeModel,
    IPersonModel,
    macroScriptsApi
} from "proxy/apiProxy";
import { ActionFactories, IAnyAction, IState } from "features";
import { filterOnState, mapToPayload, withLatestFromBuffer } from "lib/rxJsUtility";
import { IBase64File, today } from "tools/lib/utility";
import { IEntityLoadedPayload } from "features/Entity/slice";

export const loadProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityProcessExecutionsLoad"),
        mergeMap(id => entitiesApi.getProcessesAsync({ id })),
        map(ActionFactories.entity.entityProcessExecutionsLoaded));

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityDocumentsLoad"),
        mergeMap(id => entitiesApi.getDocumentsAsync({ id })),
        map(ActionFactories.entity.entityDocumentsLoaded));

export const loadEntities: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityLoadAll"),
        mergeMap(entitiesApi.searchAsync),
        map(ActionFactories.entity.entityLoadedAll));

export const loadSecurityRatings: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityRatingsLoad"),
        mergeMap(id => entitiesApi.getIssuerRatingsAsync({ id })),
        map(ActionFactories.entity.entityRatingsLoaded));

function createEmptyEntity(type: IEntityTypeModel): EntityModel | null {
    switch (type) {
        case IEntityTypeModel.Person: return {
            type: "PersonModel",
            id: 0,
            internalCode: "",
            processIds: [],
            firstName: "",
            lastName: "",
            classifications: {},
            entityExtensionFieldsValues: {},
            hasPersonalPortalTheme: false
        } as IPersonModel;
        case IEntityTypeModel.Company: return {
            type: "CompanyModel",
            id: 0,
            internalCode: "",
            processIds: [],
            name: "",
            classifications: {},
            entityExtensionFieldsValues: {},
            hasPersonalPortalTheme: false,
            entitiesId: []
        } as ICompanyModel;
        case IEntityTypeModel.EntityGroup: return {
            type: "EntityGroupModel",
            id: 0,
            internalCode: "",
            entitiesId: [],
            processIds: [],
            classifications: {},
            name: "",
            entityExtensionFieldsValues: {},
            hasPersonalPortalTheme: false
        } as IEntityGroupModel;
    }
    return null;
}

export const submitCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityCustomScreenDataSubmit"),
        mergeMap(entitiesApi.submitCustomScreenDataAsync),
        map(ActionFactories.entity.entityCustomScreenDataSubmitted));
export const loadCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityCustomScreenDatasLoad"),
        mergeMap(id => entitiesApi.getLastCustomScreenDatasAsync({ id })),
        map(ActionFactories.entity.entityCustomScreenDatasLoaded));

async function getEntityAsync(id: number | IEntityTypeModel) {
    if (typeof id === "number") {
        const [loadedEntity, loadedImage] = await Promise.all([
            entitiesApi.getAsync({ id: id as number }),
            entitiesApi.getImageAsync({ id: id as number }).catch(() => undefined)]);
        return {
            ...loadedEntity,
            entityPicture: loadedImage ? {
                content: loadedImage.data,
                mimeType: loadedImage.mimeType,
                fileName: loadedImage.name
            } : undefined
        };
    }
    else {
        return { entity: createEmptyEntity(id), entities: {} } as IEntityLoadedPayload;
    }
}
export const loadEntity: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("entity", "entityLoad"),
            share());

        const entity$ = requestedId$.pipe(
            mergeMap(getEntityAsync),
            share());

        const customScreens$ = requestedId$.pipe(
            mergeMap(() => customScreensApi.getAllAsync({})),
            map(customScreens => customScreens.filter(customScreen => customScreen.type === "EntityCustomScreenSummaryModel")),
            mergeMap(async customScreens => Promise.all(customScreens.map(({ id }) => customScreensApi.getAsync({ id })))),
            map(ActionFactories.entity.entityCustomScreensLoaded));

        return merge(
            requestedId$.pipe(
                filter(id => typeof id === "number" && !!id),
                map(id => ActionFactories.entity.entityCustomScreenDatasLoad(typeof id === "number" ? id : 0))),
            customScreens$,
            entity$.pipe(map(ActionFactories.entity.entityLoaded)),
            requestedId$.pipe(filter(i => typeof i === "number"), map(id => ActionFactories.entity.entityRatingsLoad(id as number))),
        );
    };

export const getMonitoringResultLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityMonitoringResultLoad"),
        mergeMap(macroScriptsApi.getMonitoringResultForTargetAsync),
        map(ActionFactories.entity.entityMonitoringResultLoaded));

function saveEntity(
    savedEntity: EntityModel,
    imageFile: IBase64File | undefined) {
    const { id: entityId } = savedEntity;
    const merges$: Observable<IAnyAction>[] = []; // [of(savedEntity).pipe(map(ActionFactories.entity.entitySaved))];

    const toWait$: Observable<any>[] = [];

    const savedEntity$ = (function () {
        if (imageFile) {
            const toSave = {
                id: entityId,
                fileModel: {
                    data: imageFile.content,
                    mimeType: imageFile.mimeType,
                    name: imageFile.fileName
                }
            };
            return from(entitiesApi.saveImageAsync(toSave)).pipe(map(() => savedEntity));
        }
        else {
            return from(entitiesApi.deleteImageAsync({ id: savedEntity.id })).pipe(map(() => savedEntity));
        }
    })();

    merges$.push(forkJoin([savedEntity$]).pipe(
        map(([entity]) => ({ entity })),
        map(ActionFactories.entity.entitySaved)))

    if (toWait$.length) {
        return forkJoin(toWait$).pipe(mergeMap(() => merge(...merges$)));
    }
    else {
        return merge(...merges$);
    }
}

export const saveEntityEpic: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entitySave"),
        mergeMap(({ entity, imageFile }) =>
            of(entity).pipe(
                mergeMap(model => entitiesApi.saveAsync({ model })),
                mergeMap(savedEntity => saveEntity(savedEntity, imageFile)))));

export const deleteEntityEpic: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("entity", "entityDelete"),
            mergeMap(id => entitiesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.entity.entityDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }




export const loadPricingDates: Epic<IAnyAction, any, IState>
    = (action$, state$) => action$.pipe(
        mapToPayload("entity", "entityDatesLoad"),
        filterOnState(state$, (state, payload) => !!state.entity.compositionDates),
        mergeMap(id => entitiesApi.getCompositionDatesAsync({ id })),
        map(i => {
            if (!i.length) {
                return [today()];
            }
            else {
                return i;
            }
        }),
        map(ActionFactories.entity.entityDatesLoaded));

export const loadPricingDateData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityDatesLoaded"),
        map(pricingDates => {
            if (pricingDates.length === 0) {
                return;
            }
            return pricingDates.reduce((agg, v) => {
                if ((agg ? agg.getTime() : 0) - (v ? v.getTime() : 0) > 0) {
                    return agg;
                }
                return v;
            }, undefined as Date | undefined);
        }),
        filter(i => !!i),
        map(date => ActionFactories.entity.entityDateLoad(date as Date)));
export const loadPricingDate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityDateLoad"),
        withLatestFromBuffer(action$.pipe(mapToPayload("entity", "entityLoad"), filter(id => typeof id === "number"))),
        switchMap(p => {
            const [pricingDate, key] = p;
            const id = key as number;
            const portfolioComposition$ = of({ id, date: pricingDate }).pipe(
                mergeMap(i => entitiesApi.getCompositionAsync(i)));
            return merge(
                portfolioComposition$.pipe(map(() => ActionFactories.entity.entityDateLoaded(pricingDate))),
                portfolioComposition$.pipe(map(ActionFactories.entity.entityCompositionLoaded)));
        }));

export const savePosition: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityPositionSave"),
        mergeMap(position => entitiesApi.savePositionAsync({ position })),
        map(ActionFactories.entity.entityPositionSaved));
export const deletePosition: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("entity", "entityPositionDelete"),
        mergeMap(positionId => entitiesApi.deletePositionAsync({ positionId }).then(() => positionId)),
        map(ActionFactories.entity.entityPositionDeleted));