import { EntitySummaryModel, PortfolioSummaryModel, RelationshipSummaryModel } from "proxy/apiProxy";
import FieldBox from "tools/components/FieldBox";
import { useField } from 'formik';
import AsyncSelect from "tools/components/AsyncSelect";
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import BookMultipleIcon from "mdi-material-ui/BookMultiple";
import BankIcon from "mdi-material-ui/Bank";
import AccountCashIcon from "mdi-material-ui/AccountCash";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";
import { IGetEntitySummary, IGetRelationshipSummary } from "features/Reference/slice";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import SearchIcon from '@material-ui/icons/Search';
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useCallback } from "react";

export interface ISearchDefinition<Model extends { id: number }, SelectedPayload> {
    fieldName: string;
    summaries: Record<number, Model>;
    onLoaded: (selected: SelectedPayload) => void;
}

export interface IPortfolioScopeFieldProps {
    portfolioSearch?: ISearchDefinition<PortfolioSummaryModel, PortfolioSummaryModel>;
    sicavSearch?: ISearchDefinition<EntitySummaryModel, IGetEntitySummary>;
    investorSearch?: ISearchDefinition<RelationshipSummaryModel, IGetRelationshipSummary> & { entities: Record<number, EntitySummaryModel> };
    fullScopeField?: string;
    title: string;
}

export function PortfolioScopeField({ portfolioSearch, sicavSearch, investorSearch, fullScopeField, title }: IPortfolioScopeFieldProps) {
    const [, { value: portfolioIds = [] }, { setValue: setPortfolioIds }] = useField<number[]>(portfolioSearch?.fieldName ?? "portfolioIds");
    const [, { value: sicavIds = [] }, { setValue: setSicavIds }] = useField<number[]>(sicavSearch?.fieldName ?? "sicavIds");
    const [, { value: investorIds = [] }, { setValue: setInvestorIds }] = useField<number[]>(investorSearch?.fieldName ?? "investorIds");
    const [, { value: fullScope = false }] = useField<boolean>(fullScopeField ?? "fullScope");

    const navigate = useScreenNavigate()

    const { referenceManagedPortfolioSearch, referenceManagedSicavSearch, referenceRelationshipSearch } = useReduxActions("reference");
    const {
        referenceManagedPortfoliosSearched, referenceManagedPortfolioSearching,
        referenceManagedSicavsSearched, referenceManagedSicavSearching,
        referenceRelationshipsSearched, referenceRelationshipSearching } = useReduxSelections("reference");



    const handleSicavSearch = (criteria: string) => referenceManagedSicavSearch({ criterias: criteria });
    const getSicavOptionLabel = (sicav: EntitySummaryModel) => getEntityName(sicav) ?? "";
    const handleSicavSelected = (sicav: EntitySummaryModel) => {
        if (!!sicavSearch && sicavIds.indexOf(sicav.id) < 0) {
            const newSicavIds = [...sicavIds, sicav.id];
            setSicavIds(newSicavIds);
            sicavSearch.onLoaded({ entity: sicav, entities: referenceManagedSicavsSearched.entities });
        }
    };
    const handleSicavDelete = (idx: number) => {
        const newSicavIds = [...sicavIds];
        newSicavIds.splice(idx, 1);
        setSicavIds(newSicavIds);
    };
    const handleSicavNavigate = useCallback((sicavId: number) => navigate("MySicav", { id: sicavId }), [navigate])



    const handlePortfolioSearch = (criterias: string) => referenceManagedPortfolioSearch({ criterias });
    const getPortfolioOptionLabel = (portfolio: PortfolioSummaryModel) => `${portfolio?.internalCode} - ${portfolio?.name}`;
    const handlePortfolioSelected = (portfolio: PortfolioSummaryModel) => {
        if (!!portfolioSearch && portfolioIds.indexOf(portfolio.id) < 0) {
            const newPortfolioIds = [...portfolioIds, portfolio.id];
            setPortfolioIds(newPortfolioIds);
            portfolioSearch.onLoaded(portfolio);
        }
    };
    const handlePortfolioDelete = (idx: number) => {
        const newPortfolioIds = [...portfolioIds];
        newPortfolioIds.splice(idx, 1);
        setPortfolioIds(newPortfolioIds);
    };
    const handlePortfolioNavigate = useCallback((portfolioId: number) => navigate("MyPortfolio", { id: portfolioId }), [navigate])



    const handleInvestorSearch = (criterias: string) => referenceRelationshipSearch({ criterias, type: "InvestorRelationshipModel" });
    const getInvestorOptionLabel = (investor: RelationshipSummaryModel) => {
        if (!investorSearch) {
            return "";
        }
        const entity = investorSearch.entities[investor.entityId];
        if (!entity) {
            return "";
        }
        switch (entity.type) {
            case "CompanySummaryModel":
            case "SicavSummaryModel":
            case "EntityGroupSummaryModel": return `${entity.internalCode} - ${entity.name}`;
            case "PersonSummaryModel": return `${entity.internalCode} - ${entity.firstName} ${entity.lastName}`;
        }
    }
    const handleInvestorSelected = (investor: RelationshipSummaryModel) => {
        if (!!investorSearch && investorIds.indexOf(investor.id) < 0) {
            const newInvestorIds = [...investorIds, investor.id];
            setInvestorIds(newInvestorIds);
            investorSearch.onLoaded({
                relationship: investor,
                entities: referenceRelationshipsSearched.entities,
                relationships: referenceRelationshipsSearched.relationships
            });
        }
    };
    const handleInvestorDelete = (idx: number) => {
        const newInvestorIds = [...investorIds];
        newInvestorIds.splice(idx, 1);
        setInvestorIds(newInvestorIds);
    };
    const handleInvestorNavigate = useCallback((investorId: number) => navigate("Investor", { id: investorId }), [navigate])

    // const handleOpenSecurity = () => navigate({ screenKey: "Securities", sectionKey: "detail", parameters: { id } });

    //     <Button size="small" onClick={handleOpenSecurity}>
    //     <OpenInBrowser />
    //     Open Security
    //   </Button>


    const sicavSearchLabel = <><SearchIcon fontSize="small" /> Add Sicav: Type criteria to find a sicav</>
    const portfolioSearchLabel = <><SearchIcon fontSize="small" /> Add Portfolio: Type criteria to find a portfolio</>
    const investorSearchLabel = <><SearchIcon fontSize="small" /> Add Investor: Type criteria to find an investor</>

    if (!investorSearch && !sicavSearch && !portfolioSearch) {
        return null;
    }
    return <ReadOnlyContext.Consumer>{readOnly =>
        <Card>
            <CardHeader title={title} />
            <CardContent>
                {!!fullScopeField && <FormCheckBoxField name={fullScopeField} label="Everything" />}
                {((!fullScope && !!fullScopeField) || !fullScopeField) && <>
                    <List dense={true}>
                        {investorSearch && investorIds.map((id, idx) => <ListItem key={id} onClick={handleInvestorNavigate.bind(null, id)} style={{ cursor: "pointer" }}>
                            <ListItemAvatar>
                                <Avatar><AccountCashIcon /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={getInvestorOptionLabel(investorSearch.summaries[id])} secondary="Investor" />
                            <ListItemSecondaryAction>
                                {!readOnly && <IconButton edge="end" onClick={handleInvestorDelete.bind(null, idx)}>
                                    <DeleteIcon />
                                </IconButton>}
                            </ListItemSecondaryAction>
                        </ListItem>)}
                        {sicavSearch && sicavIds.map((id, idx) => <ListItem key={id} onClick={handleSicavNavigate.bind(null, id)} style={{ cursor: "pointer" }}>
                            <ListItemAvatar>
                                <Avatar><BankIcon /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={getSicavOptionLabel(sicavSearch.summaries[id])} secondary="Sicav" />
                            <ListItemSecondaryAction>
                                {!readOnly && <IconButton edge="end" onClick={handleSicavDelete.bind(null, idx)}>
                                    <DeleteIcon />
                                </IconButton>}
                            </ListItemSecondaryAction>
                        </ListItem>)}
                        {portfolioSearch && portfolioIds.map((id, idx) => <ListItem key={id} onClick={handlePortfolioNavigate.bind(null, id)} style={{ cursor: "pointer" }}>
                            <ListItemAvatar>
                                <Avatar><BookMultipleIcon /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={getPortfolioOptionLabel(portfolioSearch.summaries[id])} secondary="Portfolio" />
                            <ListItemSecondaryAction>
                                {!readOnly && <IconButton edge="end" onClick={handlePortfolioDelete.bind(null, idx)}>
                                    <DeleteIcon />
                                </IconButton>}
                            </ListItemSecondaryAction>
                        </ListItem>)}
                    </List>
                    {!readOnly && <FieldBox display="flex" flexDirection="column">
                        {sicavSearch && <AsyncSelect onSearchOptions={handleSicavSearch} options={referenceManagedSicavsSearched.all} getOptionLabel={getSicavOptionLabel} searching={referenceManagedSicavSearching} label={sicavSearchLabel} onRequiredValueSelected={handleSicavSelected} helperText="Select a sicav to add it to the scope" />}
                        {portfolioSearch && <AsyncSelect onSearchOptions={handlePortfolioSearch} options={referenceManagedPortfoliosSearched.portfolios} getOptionLabel={getPortfolioOptionLabel} searching={referenceManagedPortfolioSearching} label={portfolioSearchLabel} onRequiredValueSelected={handlePortfolioSelected} helperText="Select a portfolio to add it to the scope" />}
                        {investorSearch && <AsyncSelect onSearchOptions={handleInvestorSearch} options={referenceRelationshipsSearched.all} getOptionLabel={getInvestorOptionLabel} searching={referenceRelationshipSearching} label={investorSearchLabel} onRequiredValueSelected={handleInvestorSelected} helperText="Select an investor to add it to the scope" />}
                    </FieldBox>}
                </>}
            </CardContent>
        </Card>}
    </ReadOnlyContext.Consumer>
}
