import * as React from "react";
import { ISubFundSummaryModel } from "proxy/apiProxy";
import { useField } from 'formik';
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IGetSubFundSummary } from "features/Reference/slice";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';

export interface ISubFundSelectFieldProps {
    name: string;
    label?: React.ReactNode;
    required?: boolean;
    onSelected?: (found: IGetSubFundSummary) => void;
}
export default function SubFundSelectField({ name, label, required, onSelected }: ISubFundSelectFieldProps) {
    const { referenceSubFundSearch } = useReduxActions("reference");
    const { referenceSubFundsSearched, referenceSubFundSearching } = useReduxSelections("reference");
    const validate = (portfolioId: number | undefined) => {
        if (!required) {
            return;
        }
        return (portfolioId ?? 0) === 0 ? "Required" : undefined;
    }
    const [, { value, error, initialValue }, { setValue }] = useField<number | undefined>({ name, validate });
    const getOptionLabel = (role: ISubFundSummaryModel) => role.name;
    label = <><SearchIcon fontSize="small"/> {label ?? "SubFund"}: Type criteria to find a subfund {required ? "*" : ""}</>;
    if ((initialValue || null) !== (value || null)) {
        label = <b>{label}</b>;
    }
    const handleValueSelected = (subFund: ISubFundSummaryModel) => {
        if (onSelected) {
            onSelected({ subFund, entities: referenceSubFundsSearched.entities });
        }
        setValue(subFund.id);
    }
    const handleOnSearchOptions = (criterias: string) => referenceSubFundSearch({ criterias });
    return <ReadOnlyContext.Consumer>{readOnly => readOnly ? null :
        <AsyncSelect onSearchOptions={handleOnSearchOptions} options={referenceSubFundsSearched.subFunds}
            getOptionLabel={getOptionLabel} searching={referenceSubFundSearching} label={label} onRequiredValueSelected={handleValueSelected} error={!!error} helperText={error} />}
    </ReadOnlyContext.Consumer>
}
