import * as React from "react";
import { ICurrencyModel, PortfolioSummaryModel } from "proxy/apiProxy";
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, IconButton } from "@material-ui/core";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { SummaryField } from "../global/SummaryField";
import { getPortfolioTypeLabel } from "features/ManagedPortfolio/getPortfolioTypeLabel";
import { useField } from "formik";
import CloseIcon from '@material-ui/icons/Close';
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { ScreenLink } from "tools/routing/ScreenLink";

export interface IManagedPortfolioSummaryProps {
  portfolios: Record<number, PortfolioSummaryModel>;
  currencies: Record<number, ICurrencyModel>;
  label?: string;
  name: string;
  onDelete?: () => void;
}

export default function ManagedPortfolioSummary({ onDelete, name: fieldName, portfolios, currencies, label }: IManagedPortfolioSummaryProps) {
  const [expanded, setExpanded] = React.useState(false);
  const [{ value: portfolioId }, , { setValue }] = useField<number | undefined>(fieldName);
  if (!portfolioId) {
    return null;
  }
  const portfolio = portfolios[portfolioId];
  if (!portfolio) {
    return null;
  }
  const { id, type, name, currencyId = 0, internalCode } = portfolio;
  const { [currencyId]: currency } = currencies;
  const handleSwitchExpand = () => setExpanded(!expanded);
  const typeLabel = getPortfolioTypeLabel(type);
  // const handleOpenPortfolio = () => navigate("MyPortfolio", { id });

  const handleSetUndefined = () => {
    setValue(undefined);
    onDelete && onDelete();
  }
  return <ReadOnlyContext.Consumer>{readOnly => <Card >
    <CardHeader
      action={<>
        {!readOnly && <IconButton onClick={handleSetUndefined}><CloseIcon /></IconButton>}
        <IconButton onClick={handleSwitchExpand}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </>}
      subheader={label ?? typeLabel}
      title={name} />
    <Collapse in={expanded} timeout="auto">
      <CardContent>
        <SummaryField label="Name" value={name} />
        {!!currencyId && currency && <SummaryField label="Currency" value={`${currency.isoCode} - ${currency.name.en}`} />}
        <SummaryField label="Internal Code" value={internalCode} />
      </CardContent>
      <CardActions>
        <Button size="small"           component={ScreenLink}
          destination={{ type: "Screen", key: "MyPortfolio", label: "Open Portfolio", params: { id: String(id) } }}
>
          <OpenInBrowser />
          Open Portfolio
        </Button>
      </CardActions>
    </Collapse>
  </Card>}
  </ReadOnlyContext.Consumer>
}




export interface IManagedPortfolioSummaryReadOnlyProps {
  portfolios: Record<number, PortfolioSummaryModel>;
  currencies: Record<number, ICurrencyModel>;
  label?: string;
  portfolioId: number;
}

export function ManagedPortfolioSummaryReadOnly({ portfolioId, portfolios, currencies, label }: IManagedPortfolioSummaryReadOnlyProps) {
  const [expanded, setExpanded] = React.useState(false);
  if (!portfolioId) {
    return null;
  }
  const portfolio = portfolios[portfolioId];
  if (!portfolio) {
    return null;
  }
  const { id, type, name, currencyId = 0, internalCode } = portfolio;
  const { [currencyId]: currency } = currencies;
  const handleSwitchExpand = () => setExpanded(!expanded);
  const typeLabel = getPortfolioTypeLabel(type);
  // const handleOpenPortfolio = () => navigate("MyPortfolio", { id });

  return <Card >
    <CardHeader
      action={<>
        <IconButton onClick={handleSwitchExpand}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </>}
      subheader={label ?? typeLabel}
      title={name} />
    <Collapse in={expanded} timeout="auto">
      <CardContent>
        <SummaryField label="Name" value={name} />
        {!!currencyId && currency && <SummaryField label="Currency" value={`${currency.isoCode} - ${currency.name.en}`} />}
        <SummaryField label="Internal Code" value={internalCode} />
      </CardContent>
      <CardActions>
      <Button size="small"           component={ScreenLink}
          destination={{ type: "Screen", key: "MyPortfolio", label: "Open Portfolio", params: { id: String(id) } }}
>
          <OpenInBrowser />
          Open Portfolio
        </Button>
      </CardActions>
    </Collapse>
  </Card>
}
