import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { CSSProperties, useMemo } from "react";

const useStyle = makeStyles(theme => ({
  pane: {
    flex: 'none',
    position: 'relative',
    outline: 'none',
  },
  verticalPane: {
    width: 1,
  },
  horizontalPane: {
    height: 1,
    display: 'flex'
  }
}))

export interface IPaneProps {
  children: React.ReactNode;
  size: number | undefined;
  split?: 'vertical' | 'horizontal',
  eleRef?: React.MutableRefObject<HTMLDivElement | null>,
}
export function Pane({ children, split, eleRef, size }: IPaneProps) {
  const classes = useStyle();
  const style: CSSProperties = useMemo(
    () => ({
      flex: typeof size === "undefined" ? 1 : "none",
      width: split === "vertical" ? size : "100%",
      height: split === "horizontal" ? size : "100%",
      display:
        typeof size !== "undefined" && split === "horizontal"
          ? "flex"
          : undefined,
    }),
    [size, split]
  );
  return <div ref={eleRef} style={style} className={clsx(classes.pane, { [classes.horizontalPane]: split === 'horizontal', [classes.verticalPane]: split === "vertical" })}>
    {children}
  </div>
}
