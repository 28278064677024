import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { IGetLoanModel, ILoanModel, loansApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadLoans: Epic<IAnyAction>
    = (action$) => action$.pipe(
        mapToPayload("loan", "loanLoadAll"),
        mergeMap(() => loansApi.getAllAsync({})),
        map(ActionFactories.loan.loanLoadedAll));

export const loadLoan: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("loan", "loanLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad())),
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => loansApi.getAsync({ id })),
                map(ActionFactories.loan.loanLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    loan: { id: 0, name: "", shortName: "", covenants: [], collaterals: [], internalCode: "", borrowerId: null, lenderId: null, securityExtensionFieldsValues: {} } as unknown as ILoanModel,
                    entities: {},
                    indexes: {},
                    securities: {},
                    subFunds: {}
                } as IGetLoanModel)),
                map(ActionFactories.loan.loanLoaded)));
    };
export const saveLoan: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("loan", "loanSave"),
        mergeMap(model => loansApi.saveAsync({ model })),
        map(ActionFactories.loan.loanSaved));
export const deleteLoan: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("loan", "loanDelete"),
            mergeMap(id => loansApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.loan.loanDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("loan", "loanDocumentsLoad"),
        mergeMap(id => loansApi.getDocumentsAsync({ id })),
        map(ActionFactories.loan.loanDocumentsLoaded));
