import { ICurrencyModel, ICountryModel, IClassificationTypeModel, SecuritySummaryModel, ISubFundSummaryModel, IEntityPositionModel, IEntityModel, ISecurityTypeModel } from "proxy/apiProxy";
import { FormControlLabel, Switch, Box, Grid, Button } from "@material-ui/core";
import EntityCompositionGrid from "./EntityCompositionGrid";
import { IDictionary, oProps } from "tools/lib/utility";
import { VisualizationType } from "features/ManagedPortfolio/slice";
import EntityCompositionChart from "./EntityCompositionChart";
import { IFlatClassificationTypes } from "features/Classification/getFlatClassificationTypes";
import { Formik, FormikHelpers, FormikProps } from "formik";
import DialogPanel from "tools/components/DialogPanel";
import SecuritySelectField from "components/searchers/SecuritySelectField";
import SecuritySummary from "components/summaries/SecuritySummary";
import { IGetSecuritySummary } from "features/Reference/slice";
import FormTextField from "tools/fieldComponents/FormTextField";
import { useState } from "react";
import PricingDatesNavigator from "components/global/PricingDatesNavigator";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

export interface IPortfolioCompositionProps {
    dictionaries: {
        securities: Record<number | string, SecuritySummaryModel>;
        subFunds: Record<number | string, ISubFundSummaryModel>;
    };
    composition?: IEntityPositionModel[];
    pricingDate?: Date;
    pricingDates?: Date[];
    pricingDatesLoading: boolean;
    onPricingDateChanged: (pricingDate: Date) => void;
    referenceCurrencies: IDictionary<ICurrencyModel>;
    referenceCountries: IDictionary<ICountryModel>;
    classificationTypesFlat: IFlatClassificationTypes;
    classificationTypes: IClassificationTypeModel[];
    onTargetSecuritySelected?: (securityId: number) => void;
    onVisualizationTypeChanged: (visualizationType: VisualizationType) => void;
    visualizationType: VisualizationType;
    onSecurityLoaded: (security: IGetSecuritySummary) => void;
    entity: IEntityModel;
    onSavePosition: (position: IEntityPositionModel) => void;
    onDeletePosition: (position: IEntityPositionModel) => void;
}

const useStyle = makeStyles(theme => createStyles({
    controlsContainer: {
        display: "flex",
        gap: "1em",
        alignItems: "center",
        padding: 16,
    },
    controlsSpacer: {
        flexGrow: 1,
    },
    contentContainer: {
        flexGrow: 1,
        height: 1,
    },
    contentChartContainer: {
        margin: 18,
    },
}))

export default function EntityComposition({
    dictionaries: {
        subFunds,
        securities
    },
    composition = [],
    pricingDate,
    pricingDates,
    pricingDatesLoading,
    onPricingDateChanged,
    referenceCurrencies,
    referenceCountries,
    classificationTypesFlat,
    classificationTypes,
    onTargetSecuritySelected,
    onVisualizationTypeChanged,
    visualizationType,
    onSecurityLoaded,
    entity,
    onSavePosition,
    onDeletePosition
}: IPortfolioCompositionProps) {
    const handleHandleChangeVisualizationType = () => onVisualizationTypeChanged(visualizationType === "CHART" ? "GRID" : "CHART");
    const [entityPosition, setEntityPosition] = useState<IEntityPositionModel>();
    const handleCancelEdit = () => {
        setEntityPosition(undefined);
    }
    const handleValidateEdit = (position: IEntityPositionModel) => {
        setEntityPosition(undefined);
        onSavePosition(position);
    }
    const handleEditPosition = (position: IEntityPositionModel) => setEntityPosition(position);
    const handleAddPosition = () => pricingDate && setEntityPosition({ entityId: entity.id, date: pricingDate } as IEntityPositionModel)

    const classes = useStyle()

    return <Box display="flex" flexDirection="column" height="100%">

        <EditPosition
            position={entityPosition}
            currencies={referenceCurrencies}
            entity={entity}
            securities={securities}
            onCancel={handleCancelEdit}
            onValidate={handleValidateEdit}
            onSecurityLoaded={onSecurityLoaded} />

        <div className={classes.controlsContainer}>
            <PricingDatesNavigator onPricingDateChanged={onPricingDateChanged} pricingDatesLoading={pricingDatesLoading} pricingDate={pricingDate} pricingDates={pricingDates} allowEveryPricingDate={true} />
            <FormControlLabel
                control={<Switch checked={visualizationType === "CHART"} onChange={handleHandleChangeVisualizationType} />}
                label="Graph" />
            <div className={classes.controlsSpacer}/>
            <Button disabled={!pricingDate} onClick={handleAddPosition}>Add Position</Button>
        </div>

        <div className={clsx(classes.contentContainer,
            visualizationType === "CHART" && classes.contentChartContainer)}>

            {visualizationType === "CHART" && <EntityCompositionChart
                referenceCurrencies={referenceCurrencies}
                referenceCountries={referenceCountries}
                classificationTypesFlat={classificationTypesFlat}
                classificationTypes={classificationTypes}
                positions={composition}
                subFunds={subFunds}
                securities={securities} />}

            {visualizationType === "GRID" && <EntityCompositionGrid
                subFunds={subFunds}
                referenceCurrencies={referenceCurrencies}
                referenceCountries={referenceCountries}
                classificationTypesFlat={classificationTypesFlat}
                classificationTypes={classificationTypes}
                onTargetSecuritySelected={onTargetSecuritySelected}
                positions={composition}
                securities={securities}
                onDelete={onDeletePosition}
                onEdit={handleEditPosition} />}

        </div>
    </Box>;
}

interface IEditPositionProps {
    position?: IEntityPositionModel;
    onValidate: (position: IEntityPositionModel) => void;
    onCancel: () => void;
    currencies: IDictionary<ICurrencyModel>;
    securities: IDictionary<SecuritySummaryModel>;
    onSecurityLoaded: (security: IGetSecuritySummary) => void;
    entity: IEntityModel;
}
function EditPosition({ position, onValidate, onCancel, currencies, securities, onSecurityLoaded, entity }: IEditPositionProps) {
    const handleSubmit = (values: IEntityPositionModel, { setSubmitting }: FormikHelpers<IEntityPositionModel>) => {
        if (!position) {
            return;
        }
        onValidate(values);
        setSubmitting(false);
    }
    if (!position) {
        return null;
    }
    return <Formik onSubmit={handleSubmit} initialValues={position} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<IEntityPositionModel>) {
        return <DialogPanel
            maxWidth="sm"
            onBackClick={onCancel}
            title="Paragraph"
            isOpened={!!position}
            actions={[{
                disabled: !isValid || !dirty,
                label: "Ok",
                onClick: submitForm
            }]}>
            <PositionForm currencies={currencies} onSecurityLoaded={onSecurityLoaded} securities={securities} values={values} entity={entity} />
        </DialogPanel>;
    }
}
interface IPositionFormProps {
    currencies: IDictionary<ICurrencyModel>;
    securities: IDictionary<SecuritySummaryModel>;
    onSecurityLoaded: (security: IGetSecuritySummary) => void;
    values: IEntityPositionModel;
    entity: IEntityModel;
}
function PositionForm({ currencies, securities, onSecurityLoaded, values: { securityId }, entity: { currencyId } }: IPositionFormProps) {
    const securityCurrencyCode = (function () {
        const currencyId = securities[securityId]?.currencyId ?? 0;
        return currencies[currencyId]?.isoCode;
    })();
    const entityCurrencyCode = currencies[currencyId ?? 0]?.isoCode;
    return <Grid container spacing={1}>
        <Grid item md={12}>
            <Box display="flex" flexDirection="column">
                <SecuritySelectField required={true} name={oProps<IEntityPositionModel>().path("securityId")} onSelected={onSecurityLoaded} type={ISecurityTypeModel.All} />
                <SecuritySummary name={oProps<IEntityPositionModel>().path("securityId")} currencies={currencies} securities={securities} />
            </Box>
        </Grid>
        <Grid item md={6}>
            <FormTextField required name={oProps<IEntityPositionModel>().path("quantity")} label="Quantity" />
        </Grid>
        <Grid item md={6}>
            <FormTextField name={oProps<IEntityPositionModel>().path("investedAmount")} label="Invested Amount" adornment={entityCurrencyCode} />
        </Grid>
        <Grid item md={6}>
            <FormTextField name={oProps<IEntityPositionModel>().path("marketValueInSecurityCcy")} label="Market Value In Security Ccy" adornment={securityCurrencyCode} />
        </Grid>
        <Grid item md={6}>
            <FormTextField name={oProps<IEntityPositionModel>().path("marketValueInPortfolioCcy")} label="Market Value In Portfolio Ccy" adornment={entityCurrencyCode} />
        </Grid>
    </Grid>
}
