import * as React from "react";
import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { FormikProps, useField } from "formik";
import { DevelopmentItemModel, IFeatureModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { oProps } from "tools/lib/utility";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { ITypedMacro } from "./slice";
import ReportTemplateDevelopmentItem, { IReportTemplateDevelopmentItemRefs } from "./DevelopmentItemEditor/ReportTemplateDevelopmentItemEditor";
import QuestionnaireDevelopmentItem, { IQuestionnaireDevelopmentItemRefs } from "./DevelopmentItemEditor/QuestionnaireDevelopmentItemEditor";
import EtlMacroDevelopmentItemEditor, { IEtlDevelopmentItemRefs } from "./DevelopmentItemEditor/EtlMacroDevelopmentItemEditor";
import { Switch } from "./Switch";
import { developmentItemMenus } from "./developmentItemMenus";
import { DevelopmentItemProperties } from "./DevelopmentItemProperties";
import { developmentItemScreen, FormValues } from "./DevelopmentItem";
import { getConfig } from "lib/userManager";
import { DevelopmentItemExecution, ExecutionState } from "./DevelopmentItemRunner/DevelopmentItemExecution";
import { ScreenAnyTab } from "tools/routing/screenRouteHooks";
import DashboardLayoutDevelopmentItemEditor, { IDashboardLayoutDevelopmentItemRefs } from "./DevelopmentItemEditor/DashboardLayoutDevelopmentItemEditor";
import DatasetMacroDevelopmentItemEditor, { IDatasetDevelopmentItemRefs } from "./DevelopmentItemEditor/DatasetMacroDevelopmentItemEditor";
import DatabaseArrowDown from 'mdi-material-ui/DatabaseArrowDown'
const { general: { disableSudo } } = getConfig();

export interface IDevelopmentItemRefs extends IQuestionnaireDevelopmentItemRefs, IReportTemplateDevelopmentItemRefs, IEtlDevelopmentItemRefs, IDashboardLayoutDevelopmentItemRefs, IDatasetDevelopmentItemRefs { }
interface IDevelopmentItemFormProps extends IDevelopmentItemRefs {
    form: FormikProps<FormValues>;
    id: string;
    tab: ScreenAnyTab<typeof developmentItemScreen>;
}

export function DevelopmentItemForm({ form: { submitForm, values, isValid }, id, tab: tabValue = "detail", ...refs }: IDevelopmentItemFormProps) {
    const { developmentItemCurrent, developmentItemMetadata, developmentItemDiagnosticCurrent, developmentItemStates, developmentItemExecutionResults } = useReduxSelections("developmentItem");
    const { classificationTypes } = useReduxSelections("developmentItemClassificationType");
    const {
        developmentItemDelete,
        developmentItemValidateScript,
        developmentItemValidate
    } = useReduxActions("developmentItem");

    const handleCodeChanged = React.useCallback((macro: ITypedMacro) => {
        developmentItemValidateScript(macro);
    }, [developmentItemValidateScript]);
    const handleDeleteClick = React.useCallback(() => {
        if (developmentItemCurrent?.id) {
            developmentItemDelete(developmentItemCurrent.id);
        }
    }, [developmentItemCurrent?.id, developmentItemDelete]);

    const title = !!values.id ? values.name : "New Development Item";

    const tabs: ISubMenuTabs<typeof developmentItemScreen>[] | undefined = developmentItemMenus[values["type"]];

    const handleSaveClick = React.useCallback(() => {
        if (isValid) {
            submitForm();
        }
    }, [isValid, submitForm]);
    const handleTypeChanged = React.useCallback(() => {
        switch (values.type) {
            case "QuestionnaireDevelopmentItemModel":
                developmentItemValidate({
                    ...values,
                    templateContent: refs.questionnaireTemplateRef.current.getValue() ?? "{}",
                    onCompleteMacro: refs.questionnaireCompleteMacroRef.current.getValue(),
                    onLoadMacro: refs.questionnaireLoadMacroRef.current.getValue()
                });
                break;
            case "ReportTemplateDevelopmentItemModel":
                developmentItemValidate({
                    ...values,
                    templateContent: refs.reportTemplateRef.current.getValue() ?? "{}"
                });
                break;
            case "EtlMacroDevelopmentItemModel":
                developmentItemValidate({
                    ...values,
                    macro: refs.etlMacroRef.current.getValue()
                });
                break;
            case "DatasetMacroDevelopmentItemModel":
                developmentItemValidate({
                    ...values,
                    macro: refs.datasetMacroRef.current.getValue()
                });
                break;

            // case "DashboardLayoutDevelopmentItemModel":
            //     developmentItemValidate({
            //         ...values,
            //         macro: refs.dashboardLayoutRef.current.getValue()
            //     });
            //     break;
            default:
                developmentItemValidate(values);
                break;
        }
    }, [values, developmentItemValidate, refs.questionnaireTemplateRef, refs.questionnaireCompleteMacroRef, refs.questionnaireLoadMacroRef, refs.reportTemplateRef, refs.etlMacroRef, refs.datasetMacroRef]);
    const [{ value: type }] = useField<DevelopmentItemModel["type"]>(oProps<DevelopmentItemModel>().path("type"));
    const [onGoingExecutionState, setOnGoingExecutionState] = React.useState<ExecutionState>();
    const handleChangeExecutionState = React.useCallback((executionState: ExecutionState | undefined) => void setOnGoingExecutionState(executionState), [])

    const executeActionButtons = React.useMemo(() => {
        switch (values.type) {
            case "QuestionnaireDevelopmentItemModel":
                return [{
                    label: "Execute",
                    onClick: () => setOnGoingExecutionState(ExecutionState.FillQuestionnaireParameters),
                    icon: PlayArrowIcon,
                    disabled: developmentItemStates.questionnaireOnLoading
                }];
            case "ReportTemplateDevelopmentItemModel":
                return [{
                    label: "Generate",
                    onClick: () => setOnGoingExecutionState(ExecutionState.FillReportRunParameters),
                    icon: PlayArrowIcon,
                    disabled: developmentItemStates.reportOnGenerating
                }, {
                    label: "Get data and schema",
                    onClick: () => setOnGoingExecutionState(ExecutionState.FillReportPreviewParameters),
                    icon: DatabaseArrowDown,
                    disabled: developmentItemStates.reportOnLoading
                }];
            case "EtlMacroDevelopmentItemModel":
                return [{
                    label: "Execute",
                    onClick: () => setOnGoingExecutionState(ExecutionState.FillEtlParameters),
                    icon: PlayArrowIcon,
                    disabled: developmentItemStates.etlExecuting
                }];
            case "DatasetMacroDevelopmentItemModel":
                return [{
                    label: "Execute",
                    onClick: () => setOnGoingExecutionState(ExecutionState.FillDatasetParameters),
                    icon: PlayArrowIcon,
                    disabled: developmentItemStates.datasetLoading
                }];
            case "DashboardLayoutDevelopmentItemModel":
                return [{
                    label: "Get data and schema",
                    onClick: () => setOnGoingExecutionState(ExecutionState.FillDashboardPreviewParameters),
                    icon: DatabaseArrowDown,
                    disabled: developmentItemStates.dashboardOnLoading
                }];
        }
    }, [developmentItemStates.dashboardOnLoading, developmentItemStates.datasetLoading, developmentItemStates.etlExecuting, developmentItemStates.questionnaireOnLoading, developmentItemStates.reportOnGenerating, developmentItemStates.reportOnLoading, values.type]);
    // const handlePreviewDataRequest = React.useCallback(() => void setOnGoingExecutionState(ExecutionState.FillReportPreviewParameters), []);
    return <WriteAccess value={IFeatureModel.DevelopmentItemsWrite}>
        <DevelopmentItemExecution refs={refs} executionState={onGoingExecutionState} onChangeExecutionState={handleChangeExecutionState} />
        <DetailPanel
            tabs={tabs}
            tabValue={tabValue}
            isQuerying={developmentItemStates.loading || developmentItemStates.saving}
            title={title}
            badge={!values.id ? "new" : undefined}
            noPadding
            onSaveClick={handleSaveClick}
            canSave={isValid}
            saveAllowed={IFeatureModel.DevelopmentItemsWrite}
            deleteAllowed={IFeatureModel.DevelopmentItemsWrite}
            onDeleteClick={handleDeleteClick}
            actions={executeActionButtons}
            canDelete={!!values.id}
            saveMustBeConfirmed={!disableSudo}>
            {(tabValue === "detail") && <DevelopmentItemProperties
                onTypeChanged={handleTypeChanged}
                classificationTypes={classificationTypes} />}
            <Switch type={type}>
                <QuestionnaireDevelopmentItem type="QuestionnaireDevelopmentItemModel"
                    onCodeChanged={handleCodeChanged}
                    questionnaireCompleteMacroRef={refs.questionnaireCompleteMacroRef}
                    questionnaireLoadMacroRef={refs.questionnaireLoadMacroRef}
                    questionnaireTemplateRef={refs.questionnaireTemplateRef}
                    tabValue={tabValue}
                    diagnostics={developmentItemDiagnosticCurrent}
                    metadata={developmentItemMetadata} />
                <EtlMacroDevelopmentItemEditor type="EtlMacroDevelopmentItemModel"
                    onCodeChanged={handleCodeChanged}
                    result={developmentItemExecutionResults.etlExecutionResult}
                    etlMacroRef={refs.etlMacroRef}
                    tabValue={tabValue}
                    metadata={developmentItemMetadata}
                    diagnostics={developmentItemDiagnosticCurrent} />
                <DatasetMacroDevelopmentItemEditor type="DatasetMacroDevelopmentItemModel"
                    onCodeChanged={handleCodeChanged}
                    result={developmentItemExecutionResults.datasetResult}
                    datasetMacroRef={refs.datasetMacroRef}
                    tabValue={tabValue}
                    metadata={developmentItemMetadata}
                    diagnostics={developmentItemDiagnosticCurrent} />
                <ReportTemplateDevelopmentItem type="ReportTemplateDevelopmentItemModel"
                    onCodeChanged={handleCodeChanged}
                    reportTemplateRef={refs.reportTemplateRef}
                    // onPreviewDataRequest={handlePreviewDataRequest}
                    result={developmentItemExecutionResults.reportDatasetResult}
                    tabValue={tabValue}
                    metadata={developmentItemMetadata} />
                <DashboardLayoutDevelopmentItemEditor
                    type="DashboardLayoutDevelopmentItemModel"
                    dashboardLayoutRef={refs.dashboardLayoutRef}
                    result={developmentItemExecutionResults.dashboardDatasetResult}
                    tabValue={tabValue} />
            </Switch>
        </DetailPanel>
    </WriteAccess>;
}
