import { IMacroTypeModel, IEntityMetadataModel, IMacroModel, DevelopmentItemModel, DatasetResultModel } from "proxy/apiProxy";
import { DevelopmentItemDiagnostics, ITypedMacro } from "../slice";
import FormMacroEditor from "../Designers/FormMacroEditor";
import { EditorReferencePayload } from "../Designers/INotControllableEditorProps";
import { developmentItemScreen } from "../DevelopmentItem";
import { DatasetStructure } from "./DatasetStructure";
import { IExceptionCodeEditor } from "../Designers/MacroCodeEditor";
import { useMemo } from "react";

export interface IDatasetDevelopmentItemRefs {
    datasetMacroRef: React.MutableRefObject<EditorReferencePayload<IMacroModel>>;
}
interface IDatasetMacroDevelopmentItemEditorProps extends IDatasetDevelopmentItemRefs {
    type: DevelopmentItemModel["type"];
    tabValue: keyof typeof developmentItemScreen["tabs"] | undefined;
    metadata: Record<string | number, IEntityMetadataModel>;
    result: DatasetResultModel | undefined;
    diagnostics?: DevelopmentItemDiagnostics;
    onCodeChanged: (macro: ITypedMacro) => void;
}
export default function DatasetMacroDevelopmentItemEditor({ result, tabValue, onCodeChanged, datasetMacroRef, metadata, diagnostics }: IDatasetMacroDevelopmentItemEditorProps) {
    const error: IExceptionCodeEditor | undefined = useMemo(() => result?.type === "ExecutionErrorDatasetResultModel" ? {
        startLineNumber: result.lineNumber ?? 1,
        message: result.message
    } : undefined, [result]);
    switch (tabValue) {
        case "macro": return <FormMacroEditor
            type={IMacroTypeModel.Dataset}
            onCodeChanged={onCodeChanged}
            executing={false}
            refToGetValue={datasetMacroRef}
            diagnostics={diagnostics && diagnostics[IMacroTypeModel.Dataset]}
            metadata={metadata}
            error={error} />;
        case "structure": return <DatasetStructure
            diagnostics={diagnostics && diagnostics[IMacroTypeModel.Dataset]} />;
    }
    return null;
}
