import { Epic } from "redux-observable";
import { map, mergeMap, tap } from "rxjs/operators";
import { studioVersionsApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { saveAs } from "file-saver";

export const loadVersions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("versioning", "versionsSearch"),
        mergeMap(studioVersionsApi.getAllAsync),
        map(ActionFactories.versioning.versionsLoaded));

export const loadDelta: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("versioning", "versionsChangeLoad"),
        mergeMap(i => studioVersionsApi.getDeltaAsync(i).then(result => ({ ...i, ...result }))),
        map(ActionFactories.versioning.versionsChangeLoaded));
export const restoreVersion: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("versioning", "versionRestore"),
        mergeMap(({ versionId, type }) => studioVersionsApi.restoreVersionAsync({ versionId, versionType: type })),
        map(ActionFactories.versioning.versionRestored));
export const exportLatestVersions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("versioning", "versionsAllLatestsLoad"),
        mergeMap(() => studioVersionsApi.getFullSystemAsync()),
        tap(i => saveAs(i.blob, i.fileName)),
        map(() => ActionFactories.versioning.versionsAllLatestsLoaded()));

export const importBackup: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("versioning", "versionsImport"),
        mergeMap(model => studioVersionsApi.saveFullSystemAsync({ fileModel: model })),
        map(() => ActionFactories.versioning.versionsImported()));
