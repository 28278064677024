import { withStyles, createStyles, Paper, Box, Divider, Typography, Tooltip, IconButton, Collapse } from "@material-ui/core";
import { ICustomScreenDataModel, EntitySummaryModel, CustomScreenModel } from "proxy/apiProxy";
import { formatDateTime, toDictionary } from "tools/lib/utility";
import { MonitoringResult } from "components/global/MonitoringResults";
import React from "react";
import { SurveyRunnerComponent } from "tools/components/SurveyRunnerOld";
import EditIcon from '@material-ui/icons/Edit';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

export interface ICustomViewDatasProps {
    customScreens: CustomScreenModel[];
    customScreenDatas?: ICustomScreenDataModel[];
    customScreenLoading: boolean;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
    };
    canEdit: boolean;
    onDataSubmit: (customScreenId: number, values: any) => void;
    dataSubmitting: boolean;
}

export default function CustomViewDatas({ dictionaries, onDataSubmit, customScreens, customScreenDatas, canEdit }: ICustomViewDatasProps) {
    const customScreenDatasDico = React.useMemo(() => toDictionary(customScreenDatas, i => i.customScreenId), [customScreenDatas]);
    const [editedCustomScreenId, setEditedCustomScreenId] = React.useState<number | undefined>();
    const handleDataSubmit = (customScreenId: number, values: any) => {
        setEditedCustomScreenId(undefined);
        onDataSubmit(customScreenId, values);
    }
    return <>{customScreens.filter(i => !!i.templateContent || !!customScreenDatasDico[i.id]).map(customScreen => <CustomViewData
        canEdit={canEdit}
        onEditModeSet={setEditedCustomScreenId}
        editMode={editedCustomScreenId === customScreen.id}
        key={customScreen.id}
        customScreen={customScreen}
        onDataSubmit={handleDataSubmit}
        customScreenData={customScreenDatasDico[customScreen.id]}
        dictionaries={dictionaries} />)}</>
}

const StyledBox = withStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2)
        }
    })
)(Box);

interface ICustomViewDataProps {
    customScreen: CustomScreenModel;
    customScreenData: ICustomScreenDataModel | undefined;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
    }
    onDataSubmit?: (customScreenId: number, values: any) => void;
    editMode: boolean;
    canEdit: boolean;
    onEditModeSet: (customScreenId?: number) => void;
}
function CustomViewData({ dictionaries, customScreen, customScreenData, onDataSubmit, editMode, onEditModeSet, canEdit }: ICustomViewDataProps) {
    const { createOnly, templateContent, name, description, id } = customScreen;
    const handleDataSubmit = (values: any) => {
        if (onDataSubmit) {
            onDataSubmit(id, values);
        }
    }
    const handleEdit = () => onEditModeSet(id);
    const handleCancelEdit = () => onEditModeSet(undefined);
    return <Paper elevation={3} style={{ marginBottom: 16 }}>
        <StyledBox display="flex" flexDirection="column">
            <Box display="flex" alignItems="baseline" flexDirection="row">
                <Typography variant="h6" >{description ? <Tooltip title={description}><>{name}</></Tooltip> : <>{name}</>}</Typography>
                <Box flexGrow={1} >&nbsp;</Box>
                {(!editMode && canEdit && !!templateContent) && <IconButton onClick={handleEdit}><EditIcon /></IconButton>}{editMode && <IconButton onClick={handleCancelEdit}><CancelPresentationIcon /></IconButton>}
            </Box>
            <Box display="flex" alignItems="baseline">
                {!!customScreenData && <CustomScreenDataTitle customScreen={customScreen} customScreenData={customScreenData} dictionaries={dictionaries} />}
            </Box>
        </StyledBox>
        {(!!onDataSubmit && !!templateContent) && <Collapse in={editMode} mountOnEnter={true} unmountOnExit={true}>
            <SurveyRunnerComponent onComplete={handleDataSubmit} template={templateContent} initialValues={createOnly ? undefined : customScreenData?.values} />
        </Collapse>}
        {customScreenData && <>
            <Divider />
            <StyledBox>
                <MonitoringResult result={customScreenData.computedValues} />
            </StyledBox>
        </>}
    </Paper>
}

interface ICustomScreenDataTitleProps {
    customScreen: CustomScreenModel;
    customScreenData: ICustomScreenDataModel;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
    }
}

function CustomScreenDataTitle({ dictionaries: { entities }, customScreen: { createOnly }, customScreenData: { dateTime, filledById } }: ICustomScreenDataTitleProps) {
    const updater = !!filledById ? entities[filledById] : undefined;
    const updaterName = !!updater
        ? updater.type === "PersonSummaryModel"
            ? `${updater.firstName} ${updater.lastName}`
            : updater.name
        : undefined;
    if (updaterName)
        return <Box display="flex" alignItems="baseline"><Typography>{createOnly ? "Created" : "Updated"} at <b>{formatDateTime(dateTime)}</b> by&nbsp;<b>{updaterName}</b></Typography></Box>
    else
        return <Box display="flex" alignItems="baseline"><Typography>Processed at <b>{formatDateTime(dateTime)}</b></Typography></Box>
}

