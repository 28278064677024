import { createStyles, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ScreenLink } from "tools/routing/ScreenLink";
// import { getMenuLinkTargetLevel3 } from "./menuHooks";

const useStyles = makeStyles(theme => createStyles({
    titleHeader: {
        paddingTop: 0,
        paddingBottom: 0,
        fontWeight: "bold",
        width: "auto",
        color: theme.palette.primary.contrastText,
        height: "100%",
        gap: theme.spacing(1),
    },
    menuButton: {
        height: 40,
        objectFit: "contain",
    }
}));

export default function AppTitle() {
    const classes = useStyles();
    const { currentTenant, currentTenantImageUrl, applicationMenu: { home } } = useReduxSelections("app");
    if (home) {
        return <ListItem className={classes.titleHeader} component={ScreenLink} destination={home}>
            {!!currentTenantImageUrl &&
                <img className={classes.menuButton} alt="company logo" src={currentTenantImageUrl} />}
            {currentTenant && <ListItemText primary={currentTenant.name} />}
        </ListItem>
    }
    else {
        return <ListItem className={classes.titleHeader} button>
            {!!currentTenantImageUrl &&
                <img className={classes.menuButton} alt="company logo" src={currentTenantImageUrl} />}
            {currentTenant && <ListItemText primary={currentTenant.name} />}
        </ListItem>
    }
}
