import { IMacroTypeModel, IEntityMetadataModel, IMacroModel, DevelopmentItemModel, EtlResultModel } from "proxy/apiProxy";
import { DevelopmentItemDiagnostics, ITypedMacro } from "../slice";
import FormMacroEditor from "../Designers/FormMacroEditor";
import { StreamViewer } from "../Designers/StreamViewer";
import { EditorReferencePayload } from "../Designers/INotControllableEditorProps";
import { developmentItemScreen } from "../DevelopmentItem";
import { IExceptionCodeEditor } from "../Designers/MacroCodeEditor";
import { useMemo } from "react";

export interface IEtlDevelopmentItemRefs {
    etlMacroRef: React.MutableRefObject<EditorReferencePayload<IMacroModel>>;
}
interface IEtlMacroDevelopmentItemEditorProps extends IEtlDevelopmentItemRefs {
    type: DevelopmentItemModel["type"];
    tabValue: keyof typeof developmentItemScreen["tabs"] | undefined;
    metadata: Record<string | number, IEntityMetadataModel>;
    result: EtlResultModel | undefined
    diagnostics?: DevelopmentItemDiagnostics;
    onCodeChanged: (macro: ITypedMacro) => void;
}
export default function EtlMacroDevelopmentItemEditor({ result, tabValue, onCodeChanged, etlMacroRef, metadata, diagnostics }: IEtlMacroDevelopmentItemEditorProps) {
    const error: IExceptionCodeEditor | undefined = useMemo(() => (result?.type === "EtlExecutionErrorResultModel" || result?.type === "EtlInitializationErrorResultModel") ? {
        startLineNumber: result.lineNumber ?? 1,
        message: result.text
    } : undefined, [result]);
    switch (tabValue) {
        case "macro": return <FormMacroEditor
            type={IMacroTypeModel.Etl}
            onCodeChanged={onCodeChanged}
            executing={false}
            refToGetValue={etlMacroRef}
            diagnostics={diagnostics && diagnostics[IMacroTypeModel.Etl]}
            metadata={metadata}
            error={error} />;
        case "visualization": return <StreamViewer
            definitionStructure={diagnostics?.Etl?.description}
            counters={(result?.type === "EtlExecutionErrorResultModel" || result?.type === "EtlExecutionSuccessResultModel") ? result.counters : undefined}
            error={result?.type === "EtlExecutionErrorResultModel" ? result : undefined} />;
    }
    return null;
}
