import { TabContext, TabPanel } from "@material-ui/lab";
import MultiSelectRelatedField, { IMultiSelectRelatedFieldProps } from "./MultiSelectRelated";
import DialogPanel from "tools/components/DialogPanel";
import { Tabs, Tab } from "@material-ui/core";
import React from "react";
import { DocumentRelatedGrid } from "./DocumentRelatedGrid";

export interface IAddRelatedDialogProps extends IMultiSelectRelatedFieldProps {
    isOpened: boolean;
    onCancel: () => void;
    title?: string;
    showSummary?: boolean;
}

export default function RelatedDialog({
    onCancel,
    isOpened,
    title,
    showSummary,
    dictionaries,
    name,
    ...multiSelectRelatedFieldProps
}: IAddRelatedDialogProps) {

    const [value, setValue] = React.useState("Selection");

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    return <DialogPanel
        onBackClick={onCancel}
        title={title}
        isOpened={isOpened}>
        <TabContext value={value} >
            {showSummary && <Tabs value={value} onChange={handleChange} indicatorColor="primary">
                <Tab label="Selection" value="Selection" />
                <Tab label="List of selected" value="Summary" />
            </Tabs>}
            <TabPanel value="Selection" style={{ paddingRight: 0, paddingLeft: 0 }}>
                <MultiSelectRelatedField dictionaries={dictionaries} name={name} {...multiSelectRelatedFieldProps} />
            </TabPanel>
            <TabPanel value="Summary" style={{ paddingRight: 0, paddingLeft: 0 }}>
                {showSummary && <DocumentRelatedGrid
                    name={name}
                    dictionaries={dictionaries}
                />}
            </TabPanel>
        </TabContext>
    </DialogPanel >;
}
