import React from 'react';
import SimpleSelect from '../components/SimpleSelect';
import * as Uuid from 'uuid';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { useFieldEx, IFormField, ValidatorParams } from 'tools/lib/fieldHelpers';
import { IDictionary } from 'tools/lib/utility';
import { ReadOnlyContext } from "./ReadOnlyContext";

const validators = {
    required: (v: string | number | undefined, isTrue?: boolean) => isTrue && (typeof (v) === "undefined" || v === null || v === 0) ? "Required" : undefined,
}

export interface IFormSimpleSelectFieldProps<T> extends IFormField, ValidatorParams<typeof validators> {
    fullWidth?: boolean;
    onSelectionChanged?: (value: string | number | undefined) => void;
    options: IDictionary<T> | T[];
    getPrimaryContent?: (row: T) => React.ReactNode;
    getSecondaryContent?: (row: T) => React.ReactNode;
}

export default function FormSimpleSelectField<O>({ name, label, helperText, disabled, error = false, fullWidth = true, onSelectionChanged, options,
    getPrimaryContent,
    getSecondaryContent, ...requestedValidations }: IFormSimpleSelectFieldProps<O>) {
    const [idComponent] = React.useState(Uuid.v1());
    const { error: fieldError, helperText: fieldHelperText, label: fieldLabel, onValueChanged, value } = useFieldEx<string | number, typeof validators>({ name, helperText, label, required: requestedValidations.required, error, requestedValidations, validators });
    const handleSelectionChanged = (newValue: string | number | undefined) => {
        onValueChanged(newValue);
        if (onSelectionChanged) {
            onSelectionChanged(newValue);
        }
    }
    return <ReadOnlyContext.Consumer>
        {readOnly => <FormControl fullWidth={fullWidth} error={fieldError} >
            <InputLabel htmlFor={idComponent}>{fieldLabel}</InputLabel>
            <SimpleSelect
                readOnly={readOnly}
                id={name}
                required={requestedValidations.required}
                error={fieldError}
                value={!value ? undefined : value}
                disabled={disabled||readOnly}
                inputProps={{ id: idComponent }}
                margin="dense"
                options={options}
                getPrimaryContent={getPrimaryContent}
                getSecondaryContent={getSecondaryContent}
                onSelectionChanged={handleSelectionChanged}
                fullWidth={fullWidth} />
            {fieldHelperText && <FormHelperText error={fieldError}>{fieldHelperText}</FormHelperText>}
        </FormControl>}
    </ReadOnlyContext.Consumer>
}
