import { IDocumentSummaryModel, IDocumentModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import * as React from "react";
import { Link } from "@material-ui/core";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import { GetAllSummaries, entityLinks, relationshipLinks, portfolioLinks, securityLinks, cashMovementLinks, transactionLinks } from "../utils";
import { DashboardLink } from "tools/objectViewer/DashboardComponents";
import { ScreenLinkType } from "tools/objectViewer/DashboardFormattingContracts";

export interface IDocumentsGridProps {
    documents: (IDocumentSummaryModel | IDocumentModel)[];
    dictionaries: GetAllSummaries;
}

export function DocumentsGrid({ documents, dictionaries }: IDocumentsGridProps) {
    const { documentLoadFile } = useReduxActions("document");
    const { classificationTypes, classificationTypesFlat } = useReduxSelections("documentClassificationType");
    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, ({ classifications }: (IDocumentModel | IDocumentSummaryModel)) => classifications);

    const handleFileClick = (row: IDocumentSummaryModel | IDocumentModel) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        documentLoadFile(row.id);
    }

    const columns: IColumnDefinition[] = [
        {
            name: "alias",
            title: "Alias",
            getCellValue: (row: (IDocumentSummaryModel | IDocumentModel)) => {
                return <DashboardLink id={row.id} textValue={row.alias} referenceType={ScreenLinkType.Document} />
            },
            filteringEnabled: true,
        },
        {
            name: "name",
            title: "Filename",
            getCellValue: (row: (IDocumentSummaryModel | IDocumentModel)) => {
                return <Link href="#" onClick={handleFileClick(row)}> {row.name} </Link>
            },
            filteringEnabled: true,
        },
        {
            name: "startDate",
            title: "Start Date",
            getCellValue: (row: (IDocumentSummaryModel | IDocumentModel)) => row.startDate,
            columnType: "date",
            filteringEnabled: true,
        },
        {
            name: "expirationDate",
            title: "Expiration Date",
            getCellValue: (row: (IDocumentSummaryModel | IDocumentModel)) => row.expirationDate,
            columnType: "date",
            filteringEnabled: true,
        },
        {
            name: "entities",
            title: "Entities",
            getCellValue: (row: (IDocumentSummaryModel | IDocumentModel)) => {
                return entityLinks(row.related.entityIds ?? [], dictionaries.entities);
            },
            filteringEnabled: true,
        },
        {
            name: "relationships",
            title: "Relationships",
            getCellValue: (row: (IDocumentSummaryModel | IDocumentModel)) => {
                return relationshipLinks(row.related.relationshipIds ?? [], dictionaries.relationships, dictionaries.entities);
            },
            filteringEnabled: true,
        },
        {
            name: "portfolios",
            title: "Portfolios",
            getCellValue: (row: (IDocumentSummaryModel | IDocumentModel)) => {
                return portfolioLinks(row.related.portfolioIds ?? [], dictionaries.portfolios);
            },
            filteringEnabled: true,
        },
        {
            name: "securities",
            title: "Securities",
            getCellValue: (row: (IDocumentSummaryModel | IDocumentModel)) => {
                return securityLinks(row.related.securityIds ?? [], dictionaries.securities);
            },
            filteringEnabled: true,
        },
        {
            name: "cashMovements",
            title: "Cash Movements",
            getCellValue: (row: (IDocumentSummaryModel | IDocumentModel)) => {
                return cashMovementLinks(row.related.cashMovementIds ?? [], dictionaries.cashMovements);
            },
            filteringEnabled: true,
        },
        {
            name: "transactions",
            title: "Transactions",
            getCellValue: (row: (IDocumentSummaryModel | IDocumentModel)) => {
                return transactionLinks(row.related.transactionIds ?? [], dictionaries.transactions);
            }
        },
        ...classificationTypesColumns
    ];

    const state: IGridState = {
        "alias": { width: 320 },
        "name": { width: 320, hidden: true },
        "startDate": { width: 140 },
        "expirationDate": { width: 140 },
        "entities": { width: 200 },
        "relationships": { width: 200 },
        "portfolios": { width: 200 },
        "securities": { width: 200 },
        "cashMovements": { width: 200 },
        "transactions": { width: 200 },
        ...classificationTypesState
    };

    const getRowKey = React.useCallback((row: (IDocumentSummaryModel | IDocumentModel)) => row.id, []);

    return <ExtendedGrid
        getRowId={getRowKey}
        defaultExportFileName="Documents.xlsx"
        columns={columns}
        rows={documents}
        initialState={state}
        userCanGroup={true}
        defaultColumnWidth={120} />;
}
