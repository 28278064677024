import * as React from "react";

export function useLocalStorage<T>(key: string): [T | undefined, (v: T) => void] {
    const getStorage = React.useCallback(() => {
        const item = localStorage.getItem(key);
        if (!item)
            return;
        return JSON.parse(item) as T;
    }, [key]);
    const [innerValue, setInnerValue] = React.useState(getStorage);
    const setStorage = React.useCallback((item?: T) => {
        if (!item)
            localStorage.removeItem(key);
        setInnerValue(item);
        localStorage.setItem(key, JSON.stringify(item));
    }, [key]);
    return [innerValue, setStorage];
}
