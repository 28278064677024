import { EntitySummaryModel, EntityModel } from "proxy/apiProxy";

export function getEntityName(entity: EntitySummaryModel | EntityModel | undefined) {
    if (!entity) {
        return null;
    }
    if (entity.type === "PersonSummaryModel" || entity.type === "PersonModel") {
        return `${entity.firstName} ${entity.lastName}`;
    }
    return entity.name;
}
