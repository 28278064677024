import produce from "immer";
import {
    IDevelopmentItemTypeModel, DevelopmentItemSummaryModel, IMacroScriptErrorModel, IEntityMetadataModel, IExecutionStatusResultModel, IMacroModel, IMacroTypeModel, ITaskDefinitionStructureModel, IGetDevelopmentItemsModel, EntitySummaryModel, 
    IGetDevelopmentItemModel, DevelopmentItemModel, MacroSymbolModel, IExecuteOnLoadModel, DatasetResultModel, IExecuteQuestionnaireOnCompleteModel, IExecuteReportModel, IStudioDevelopmentItemsRunReportGenerateParameters,
    IExecuteEtlModel, IExecuteEtlResultModel, EtlResultModel, IExecuteModel, IGenerateDatasetModel
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IBase64File } from "tools/lib/utility";

export interface ITypedMacro extends IMacroModel {
    type: IMacroTypeModel
};
export type IExecuteEtl = Omit<IExecuteEtlModel, "inputFiles"> & {
    zipFileContent?: IBase64File | null,
    zipFileName?: string,
};
export interface IMacroDiagnostic {
    errors?: IMacroScriptErrorModel[];
    description?: ITaskDefinitionStructureModel;
    returnType?: MacroSymbolModel;
}

export interface IDevelopmentItemDiagnostic {
    errors: IMacroScriptErrorModel[];
    description?: ITaskDefinitionStructureModel;
    type: IMacroTypeModel;
    returnType?: MacroSymbolModel;
}
export interface DashboardExecuteOnLoad extends Omit<IGenerateDatasetModel, "datasetName"> {
    datasetName?: IGenerateDatasetModel["datasetName"];
}
export const ActionFactories = produceActionFactories({
    developmentItemDelete: (payload: number) => payload,
    developmentItemDeleted: (payload: number) => payload,
    developmentItemLoad: (payload: number) => payload,
    developmentItemNew: (payload: IDevelopmentItemTypeModel) => payload,
    developmentItemLoaded: (payload: IGetDevelopmentItemModel) => payload,
    developmentItemSave: (payload: DevelopmentItemModel) => payload,
    developmentItemSaved: (payload: DevelopmentItemModel) => payload,
    developmentItemLoadAll: () => undefined,
    developmentItemLoadedAll: (payload: IGetDevelopmentItemsModel) => payload,
    developmentItemValidateScript: (payload: ITypedMacro) => payload,
    developmentItemValidatedScript: (payload: IDevelopmentItemDiagnostic) => payload,
    developmentItemMetadataLoad: (payload: IDevelopmentItemTypeModel) => payload,
    developmentItemMetadataLoaded: (payload: Record<string | number, IEntityMetadataModel>) => payload,

    developmentItemReportTemplateGenerate: (payload: IStudioDevelopmentItemsRunReportGenerateParameters) => payload,
    developmentItemReportTemplateGenerated: () => undefined,
    developmentItemReportGenerate: (payload: IExecuteReportModel) => payload,
    developmentItemReportGenerated: () => undefined,

    developmentItemDatasetGenerate: (payload: IExecuteOnLoadModel) => payload,
    developmentItemDatasetGenerated: (payload: DatasetResultModel) => payload,

    developmentItemReportExecuteOnLoad: (payload: IGenerateDatasetModel) => payload,
    developmentItemReportExecuteOnLoaded: (payload: IDatasetResult) => payload,

    developmentItemQuestionnaireExecuteOnLoad: (payload: IExecuteOnLoadModel) => payload,
    developmentItemQuestionnaireExecuteOnLoaded: (payload: object) => payload,

    developmentItemQuestionnaireExecuteOnComplete: (payload: IExecuteQuestionnaireOnCompleteModel) => payload,
    developmentItemQuestionnaireExecuteOnCompleted: (payload: DatasetResultModel) => payload,

    developmentItemDashboardExecuteOnLoad: (payload: DashboardExecuteOnLoad) => payload,
    developmentItemDashboardExecuteOnLoaded: (payload: IDatasetResult) => payload,

    developmentItemEtlExecute: (payload: IExecuteEtl) => payload,
    developmentItemEtlExecuted: (payload: IExecuteEtlResultModel) => payload,
    developmentItemValidate: (payload: DevelopmentItemModel) => payload
});
type AllOptional<T> = {
    [P in keyof T]?: T[P];
};
export type DevelopmentItemDiagnostics = AllOptional<Record<IMacroTypeModel, IMacroDiagnostic>>;
export interface IRealTimeProcessingStates {

    listLoading: boolean;
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    questionnaireOnLoading: boolean;
    questionnaireOnCompleting: boolean;
    datasetLoading: boolean;
    dashboardOnLoading: boolean;
    reportOnLoading: boolean;
    reportOnGenerating: boolean;
    templateOnGenerating: boolean;
    etlExecuting: boolean;
    executing: boolean;
}
export interface IDatasetResult {
    result?: DatasetResultModel;
    input: IExecuteModel;
}
export interface IExecutionResultSates {
    dashboardDatasetResult?: IDatasetResult;
    questionnaireLoadedAnswers?: any;
    questionnaireLoadedResult?: DatasetResultModel;
    etlExecutionResult?: EtlResultModel;
    reportDatasetResult?: IDatasetResult;
    datasetResult?: DatasetResultModel;
}
export interface IState {
    developmentItemStates: IRealTimeProcessingStates;
    developmentItemExecutionResults: IExecutionResultSates;
    developmentItemList: (DevelopmentItemSummaryModel | DevelopmentItemModel)[];
    developmentItemCurrent?: DevelopmentItemModel;
    developmentItemDiagnosticCurrent?: DevelopmentItemDiagnostics;
    macroExecutionResult?: IExecutionStatusResultModel;
    developmentItemMetadata: Record<string | number, IEntityMetadataModel>;
    dictionaries: {
        entities: Record<number, EntitySummaryModel>;
    },
}

export const reducer = (
    state: IState = {
        developmentItemStates: {
            listLoading: false,
            loading: false,
            deleting: false,
            saving: false,
            executing: false,
            questionnaireOnLoading: false,
            questionnaireOnCompleting: false,
            reportOnLoading: false,
            reportOnGenerating: false,
            dashboardOnLoading: false,
            datasetLoading: false,
            templateOnGenerating: false,
            etlExecuting: false
        },
        developmentItemExecutionResults: {},
        developmentItemMetadata: {},
        developmentItemList: [],
        dictionaries: {
            entities: {}
        }
        // developmentItemCommunityDevelopmentItems: []
    },
    action: AnyActionOf<typeof ActionFactories>
) => produce(state, draft => {
    switch (action.type) {
        case "developmentItemEtlExecute":
            draft.developmentItemStates.etlExecuting = true;
            delete draft.developmentItemExecutionResults.etlExecutionResult;
            break;
        case "developmentItemEtlExecuted":
            draft.developmentItemExecutionResults.etlExecutionResult = action.payload.result;
            draft.developmentItemStates.etlExecuting = false;
            break;
        case "developmentItemReportTemplateGenerate":
            draft.developmentItemStates.templateOnGenerating = true;
            break;
        case "developmentItemReportTemplateGenerated":
            draft.developmentItemStates.templateOnGenerating = false;
            break;
        case "developmentItemQuestionnaireExecuteOnComplete":
            draft.developmentItemStates.questionnaireOnCompleting = true;
            delete draft.developmentItemExecutionResults.questionnaireLoadedResult;
            break;
        case "developmentItemQuestionnaireExecuteOnCompleted":
            draft.developmentItemExecutionResults.questionnaireLoadedResult = action.payload;
            draft.developmentItemStates.questionnaireOnCompleting = false;
            break;
        case "developmentItemDashboardExecuteOnLoad":
            delete draft.developmentItemExecutionResults.dashboardDatasetResult;
            draft.developmentItemStates.dashboardOnLoading = true;
            break;
        case "developmentItemDashboardExecuteOnLoaded":
            draft.developmentItemExecutionResults.dashboardDatasetResult = action.payload;
            draft.developmentItemStates.dashboardOnLoading = false;
            break;
        case "developmentItemQuestionnaireExecuteOnLoad":
            draft.developmentItemStates.questionnaireOnLoading = true;
            delete draft.developmentItemExecutionResults.questionnaireLoadedAnswers;
            break;
        case "developmentItemQuestionnaireExecuteOnLoaded":
            draft.developmentItemExecutionResults.questionnaireLoadedAnswers = action.payload;
            draft.developmentItemStates.questionnaireOnLoading = false;
            break;
        case "developmentItemReportExecuteOnLoad":
            draft.developmentItemStates.reportOnLoading = true;
            break;
        case "developmentItemReportExecuteOnLoaded":
            draft.developmentItemExecutionResults.reportDatasetResult = action.payload;
            draft.developmentItemStates.reportOnLoading = false;
            break;
        case "developmentItemDatasetGenerate":
            delete draft.developmentItemExecutionResults.datasetResult;
            draft.developmentItemStates.datasetLoading = true;
            break;
        case "developmentItemDatasetGenerated":
            draft.developmentItemExecutionResults.datasetResult = action.payload;
            draft.developmentItemStates.datasetLoading = false;
            break;
        case "developmentItemReportGenerate":
            draft.developmentItemStates.reportOnGenerating = true;
            break;
        case "developmentItemReportGenerated":
            draft.developmentItemStates.reportOnGenerating = false;
            break;
        case "developmentItemMetadataLoad":
            draft.developmentItemMetadata = {};
            // draft.developmentItemDiagnosticCurrent = {};
            break;
        case "developmentItemMetadataLoaded":
            draft.developmentItemMetadata = action.payload;
            break;
        case "developmentItemValidate":
            delete draft.developmentItemDiagnosticCurrent;
            break;
        case "developmentItemValidateScript":
            draft.macroExecutionResult = undefined;
            break;
        case "developmentItemValidatedScript":
            if (!draft.developmentItemDiagnosticCurrent) {
                draft.developmentItemDiagnosticCurrent = {};
            }
            draft.developmentItemDiagnosticCurrent[action.payload.type] = {
                description: action.payload.description,
                errors: action.payload.errors,
                returnType: action.payload.returnType
            };
            break;
        case "developmentItemLoadAll":
            draft.developmentItemStates.listLoading = true;
            break;
        case "developmentItemLoadedAll":
            draft.developmentItemStates.listLoading = false;
            draft.developmentItemList = action.payload.developmentItems;
            draft.dictionaries = {
                entities: action.payload.entities
            };
            break;
        case "developmentItemNew":
        case "developmentItemLoad":
            draft.developmentItemStates.loading = true;
            delete draft.macroExecutionResult;
            delete draft.developmentItemDiagnosticCurrent;
            break;
        case "developmentItemLoaded": {
            draft.developmentItemStates.loading = false;
            draft.developmentItemCurrent = action.payload.developmentItem;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            break;
        }
        case "developmentItemSave":
            draft.developmentItemStates.saving = true;
            break;
        case "developmentItemSaved": {
            draft.developmentItemStates.saving = false;
            if (draft.developmentItemCurrent) {
                draft.developmentItemCurrent = action.payload;
            }
            const existing = draft.developmentItemList.find(i => i.id === action.payload.id);
            if (existing) {
                Object.assign(existing, action.payload);
            }
            else {
                draft.developmentItemList.push(action.payload);
            }
            break;
        }
        case "developmentItemDelete":
            draft.developmentItemStates.deleting = true;
            break;
        case "developmentItemDeleted":
            draft.developmentItemStates.deleting = false;
            const deletedId = action.payload;
            const idx = draft.developmentItemList.findIndex(i => i.id === deletedId);
            if (idx >= 0) {
                draft.developmentItemList.splice(idx, 1);
            }
            break;
    }
});
