import { Epic } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { stressTestApi } from "proxy/apiProxy";
import { mapToPayload } from "lib/rxJsUtility";
import { ActionFactories, IAnyAction } from "features";


export const loadStressTestLastImpacts: Epic<IAnyAction>
  = action$ => action$.pipe(
    mapToPayload("stressTest", "stressTestLastImpactsLoad"),
    mergeMap(() => stressTestApi.getLastStressTestImpactsAsync()),
    map(ActionFactories.stressTest.stressTestLastImpactsLoaded));

export const loadStressTestBackTesting: Epic<IAnyAction>
  = action$ => action$.pipe(
    mapToPayload("stressTest", "stressTestBackTestingLoad"),
    mergeMap(portfolioId => stressTestApi.getStressTestBacktestingAsync({ portfolioId })),
    map(ActionFactories.stressTest.stressTestBackTestingLoaded));
