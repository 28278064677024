import { Epic, ofType } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { noteClassificationTypesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadClassifications: Epic<IAnyAction>
    = action$ =>
        merge(
            action$.pipe(ofType("applicationLoaded"), map(i => null)),
            action$.pipe(ofType("noteClassificationTypeLoadAll"), map(i => null)),
            action$.pipe(ofType("noteClassificationTypeSaved"), map(i => null)),
            action$.pipe(ofType("referenceRefreshAll"), map(i => null))
        ).pipe(
            mergeMap(i => noteClassificationTypesApi.getAllAsync({})),
            map(ActionFactories.noteClassificationType.noteClassificationTypeAllLoaded));

export const loadClassification: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("noteClassificationType", "noteClassificationTypeLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => noteClassificationTypesApi.getAsync({ id }))),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({ id: 0, name: { en: "" }, code: "", classifications: [] })))
        ).pipe(
            map(ActionFactories.noteClassificationType.noteClassificationTypeLoaded));
    };
export const saveClassification: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("noteClassificationType", "noteClassificationTypeSave"),
        mergeMap(model => noteClassificationTypesApi.saveAsync({ model })),
        map(ActionFactories.noteClassificationType.noteClassificationTypeSaved));
export const deleteClassification: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("noteClassificationType", "noteClassificationTypeDelete"),
            mergeMap(id => noteClassificationTypesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.noteClassificationType.noteClassificationTypeDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
