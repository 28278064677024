import { NodeModel, DefaultPortModel, LinkModel, DefaultLinkModel } from '@projectstorm/react-diagrams';
import { BaseModelOptions, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { AbstractReactFactory, GenerateWidgetEvent, GenerateModelEvent } from '@projectstorm/react-canvas-core';
import { DiagramEngine, PortWidget } from '@projectstorm/react-diagrams-core';
import styled from '@emotion/styled';

export interface JsxNodeModelOptions extends BaseModelOptions {
	element?: JSX.Element;
}


export class ArrowLinkPortModel extends DefaultPortModel {
	createLinkModel(): LinkModel {
		return new DefaultLinkModel({});
	}
}

export class JsxNodeModel extends NodeModel {
	element: JSX.Element;

	constructor(options: JsxNodeModelOptions = {}) {
		super({
			...options,
			type: 'jsx-node'
		});
		this.element = options.element ?? <>no title</>;
		this.addPort(new DefaultPortModel({ in: true, name: 'in' }));
		this.addPort(new ArrowLinkPortModel({ in: false, name: 'out' }));
		// this.addPort(new DefaultPortModel({ in: false, name: 'out' }));
	}


	serialize() {
		return { ...super.serialize() };
	}

	deserialize(event: DeserializeEvent<this>): void {
		super.deserialize(event);
		// this.element = event.data.element;
	}
}

export interface IJsxNodeWidgetProps {
	node: JsxNodeModel;
	engine: DiagramEngine;
}

export const TitleNode = styled.div(({ isSelected }: { isSelected: boolean }) => ({
	borderStyle: "solid",
	borderWidth: 2,
	borderColor: isSelected ? "white" : "gray",
	borderRadius: 5,
	padding: 5,
	backgroundColor: "rgba(255, 255, 255, 0.4)",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	gap: 10
}));

export const CirclePort = styled.div({
	width: 12,
	height: 12,
	margin: 2,
	borderRadius: 4,
	background: "darkgray",
	cursor: "pointer",
	": hover": {
		background: "mediumpurple"
	}

});

export function JsxNodeWidget({ node, engine }: IJsxNodeWidgetProps) {
	const inPort = node.getPort("in");
	const outPort = node.getPort("out");
	return <TitleNode isSelected={node.isSelected()}>
		{inPort && <PortWidget engine={engine} port={inPort}>
			<CirclePort />
		</PortWidget>}
		{node.element}
		{outPort && <PortWidget engine={engine} port={outPort}>
			<CirclePort />
		</PortWidget>}
	</TitleNode>
}

export class JsxNodeFactory extends AbstractReactFactory<JsxNodeModel, DiagramEngine> {
	constructor() {
		super('jsx-node');
	}

	generateModel(initialConfig: GenerateModelEvent) {
		return new JsxNodeModel();
	}

	generateReactWidget(event: GenerateWidgetEvent<JsxNodeModel>): JSX.Element {
		return <JsxNodeWidget engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
