import {Button, Card, CardActions, CardContent, CardMedia, Link, makeStyles, Typography} from "@material-ui/core";
import welcomePicture from "images/key_small.jpg"
import {useEffect} from "react";
import {getUserManager} from "../lib/userManager";
import {useAuth} from "react-oidc-context";

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: theme.shadows[24],
        width: 650,
        alignSelf: 'center'
    },
    media: {
        height: 0,
        paddingTop: "40%" // 16:10
    },
    actions: {
        display: "flex",
    }
}));

export interface IWelcomeProps {
    onRequestLogin: () => void;
    logoutUrl: string;
}

export default function Welcome({onRequestLogin, logoutUrl}: IWelcomeProps) {
    const classes = useStyles();
    const auth = useAuth();

    useEffect(() => {
        console.info("Welcome...");
        const timeout = setTimeout(async () => {
            if (!auth.isAuthenticated) {
                const userManager = getUserManager();
                console.log(`Welcome, no valid user, redirect to signing... (then go back to ${window.location.pathname})`);
                await userManager.signinRedirect({url_state: window.location.pathname});
            }
        }, 10);

        return () => clearTimeout(timeout)
    }, [auth.isAuthenticated]);


    return <Card className={classes.card}>
        <CardMedia className={classes.media} image={welcomePicture} />
        <CardContent>
            <Typography component="h5" variant="h5">
                Welcome.
            </Typography>
            <Typography component="p">
                Please authenticate.
            </Typography>
        </CardContent>
        <CardActions className={classes.actions} disableSpacing={true}>
            <Link href={logoutUrl}>
                Logout from your identity provider
            </Link>
            <p style={{ flexGrow: 1 }} />
            <Button onClick={onRequestLogin}>Authenticate</Button>
        </CardActions>
    </Card>
}
