import produce from "immer";
import { EntitySummaryModel, IGetProcessExecutionModel, IGetProcessExecutionsModel, IProcessExecutionsGetProcessExecutionQuestionnaireParameters, IProcessExecutionsGetProcessExistingExecutionQuestionnaireParameters, IQuestionnaireDevelopmentItemSummaryModel, IQuestionnaireTaskStateModel, PortfolioSummaryModel, ProcessExecutionModel, ProcessExecutionSummaryModel, RelationshipSummaryModel, SecuritySummaryModel } from "proxy/apiProxy";
import { IGetEntitySummary, IGetRelationshipSummary } from "features/Reference/slice";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { SurveyRunnerResult } from "tools/components/SurveyRunnerDialog"

export interface IState {
    processExecutionAllLoading: boolean;
    processExecutionLoading: boolean;
    processExecutions: (ProcessExecutionSummaryModel | ProcessExecutionModel)[];
    processExecutionCurrent?: ProcessExecutionModel;
    processExecutionSaving: boolean;
    processExecutionDeleting: boolean;
    dictionaries: {
        entities: Record<number, EntitySummaryModel>,
        relationships: Record<number, RelationshipSummaryModel>,
        portfolios: Record<string | number, PortfolioSummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
    };
    processTaskState: {
        loading: Record<string, boolean | undefined>;
        saving: Record<string, boolean | undefined>;
    };
    processExecutionQuestionnaires: IQuestionnaireDevelopmentItemSummaryModel[];
    processExecutionQuestionnaireDetail: Record<string, IQuestionnaireTaskStateModel>;
}
export interface NewProcessExecutionModelRef {
    targetId: number;
    type: ProcessExecutionModel["type"];
}
export interface RenewProcessExecutionModelRef {
    renewId: number;
}
export interface ExistingProcessExecutionModelRef {
    id: number;
}
export type ProcessExecutionLoadPayload = NewProcessExecutionModelRef | ExistingProcessExecutionModelRef | RenewProcessExecutionModelRef;
export function isNewProcessExecutionModelRef(payload: ProcessExecutionLoadPayload): payload is NewProcessExecutionModelRef {
    return !!(payload as NewProcessExecutionModelRef)?.targetId;
}
export function isRenewProcessExecutionModelRef(payload: ProcessExecutionLoadPayload): payload is RenewProcessExecutionModelRef {
    return !!(payload as RenewProcessExecutionModelRef)?.renewId;
}
export interface IGetFilePayload {
    id: number;
    taskCode: string;
}
export interface IProcessExecutionSavePayload {
    execution: ProcessExecutionModel;
    files: { [taskCode: string]: IProcessExecutionTaskFile | null; };
    questionnaires: { [taskCode: string]: IQuestionnaireResponse | null }
}
export interface IQuestionnaireResponse {
    content: SurveyRunnerResult;
    isCompleted: boolean;
}
export interface IProcessExecutionTaskFile {
    content: string;
    fileName: string;
    mimeType: string;
    dueDiligenceTaskCode: string;
}
export interface IExecutionQuestionnaireLoadedPayload extends IQuestionnaireTaskStateModel {
    taskCode: string;
}
export interface IProcessExecutionQuestionnaireLoadPayload extends IProcessExecutionsGetProcessExecutionQuestionnaireParameters {
    processExecutionTaskCode: string;
}
export const ActionFactories = produceActionFactories({
    processExecutionRenew: (payload: number) => payload,
    processExecutionLoad: (payload: ProcessExecutionLoadPayload) => payload,
    processExecutionLoaded: (payload: IGetProcessExecutionModel) => payload,
    processExecutionSave: (payload: IProcessExecutionSavePayload) => payload,
    processExecutionSaved: (payload: ProcessExecutionModel) => payload,
    processExecutionDelete: (payload: number) => payload,
    processExecutionDeleted: (payload: number) => payload,
    processExecutionLoadAll: () => undefined,
    processExecutionLoadedAll: (payload: IGetProcessExecutionsModel) => payload,
    processExecutionLoadedRelationship: (payload: IGetRelationshipSummary) => payload,
    processExecutionLoadedEntity: (payload: IGetEntitySummary) => payload,

    processExecutionQuestionnairesLoaded: (payload: IQuestionnaireDevelopmentItemSummaryModel[]) => payload,

    processExecutionLoadFile: (payload: IGetFilePayload & { type: ProcessExecutionModel["type"] }) => payload,
    processExecutionLoadedFile: (payload: IGetFilePayload) => payload,
    processExecutionSaveFile: (payload: string) => payload,
    processExecutionSavedFile: (payload: string) => payload,

    processExecutionReloadQuestionnaire: (payload: Omit<IProcessExecutionsGetProcessExistingExecutionQuestionnaireParameters, "getPreviousAnswers">) => payload,
    processExecutionLoadQuestionnaire: (payload: IProcessExecutionQuestionnaireLoadPayload) => payload,
    processExecutionLoadedQuestionnaire: (payload: IExecutionQuestionnaireLoadedPayload) => payload,
    processExecutionSaveQuestionnaire: (payload: string) => payload,
    processExecutionSavedQuestionnaire: (payload: string) => payload,
});

export function reducer(
    state: IState = {
        processExecutionLoading: false,
        processExecutionAllLoading: false,
        processExecutionSaving: false,
        processExecutionDeleting: false,
        processExecutions: [],
        dictionaries: {
            entities: {},
            relationships: {},
            portfolios: {},
            securities: {}
        },
        processTaskState: {
            loading: {},
            saving: {}
        },
        processExecutionQuestionnaires: [],
        processExecutionQuestionnaireDetail: {}
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "processExecutionLoadQuestionnaire":
                draft.processTaskState.loading[action.payload.processExecutionTaskCode] = true;
                break;
            case "processExecutionReloadQuestionnaire":
                draft.processTaskState.loading[action.payload.taskCode] = true;
                break;
            case "processExecutionLoadedQuestionnaire": {
                const { taskCode, ...payload } = action.payload;
                draft.processExecutionQuestionnaireDetail[taskCode] = payload;
                draft.processTaskState.loading[taskCode] = false;
                break;
            }
            case "processExecutionSaveQuestionnaire": {
                // const { taskCode, ...payload } = action.payload;
                draft.processTaskState.saving[action.payload] = true;
                // draft.questionnaireState.questionnaireContents[taskCode].responses = payload.responses;
                break;
            }
            case "processExecutionSavedQuestionnaire": {
                // const { taskCode, ...payload } = action.payload;
                // draft.questionnaireState.questionnaireContents[taskCode].result = payload;
                draft.processTaskState.saving[action.payload] = false;
                break;
            }
            case "processExecutionLoadedRelationship":
                draft.dictionaries.relationships[action.payload.relationship.id] = action.payload.relationship;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "processExecutionLoadedEntity":
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "processExecutionLoadAll":
                draft.processExecutionAllLoading = true;
                break;
            case "processExecutionLoadedAll":
                draft.processExecutionAllLoading = false;
                draft.processExecutions = action.payload.processExecutions;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                break;
            case "processExecutionLoad":
            case "processExecutionRenew":
                draft.processExecutionLoading = true;
                draft.processExecutionCurrent = undefined;
                break;
            case "processExecutionLoaded":
                draft.processExecutionLoading = false;
                draft.processExecutionCurrent = action.payload.processExecution;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.processTaskState = {
                    loading: {},
                    saving: {}
                };
                break;
            case "processExecutionSave":
                draft.processExecutionSaving = true;
                break;
            case "processExecutionSaved":
                draft.processExecutionSaving = false;
                const saved = action.payload;
                if (draft.processExecutionCurrent) {
                    draft.processExecutionCurrent = saved;
                }
                const existing = draft.processExecutions.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.processExecutions.push(saved);
                }
                break;
            case "processExecutionDelete":
                draft.processExecutionDeleting = true;
                break;
            case "processExecutionDeleted":
                draft.processExecutionDeleting = false;
                if (draft.processExecutionCurrent?.id === action.payload) {
                    delete draft.processExecutionCurrent;
                }
                const idx = draft.processExecutions.findIndex(i => i.id === action.payload);
                if (idx >= 0) {
                    draft.processExecutions.splice(idx, 1);
                }
                break;
            case "processExecutionLoadFile":
                if (draft.processExecutionCurrent) {
                    draft.processTaskState.loading[action.payload.taskCode] = true;
                }
                break;
            case "processExecutionLoadedFile":
                if (draft.processExecutionCurrent) {
                    delete draft.processTaskState.loading[action.payload.taskCode];
                }
                break;
            case "processExecutionSaveFile":
                if (draft.processExecutionCurrent) {
                    draft.processTaskState.saving[action.payload] = true;
                }
                break;
            case "processExecutionSavedFile":
                if (draft.processExecutionCurrent) {
                    delete draft.processTaskState.saving[action.payload];
                }
                break;
            case "processExecutionQuestionnairesLoaded":
                draft.processExecutionQuestionnaires = action.payload;
                break;
        }
    });
}
