import React from "react";
import 'survey-core/defaultV2.min.css';
import { SurveyModel } from "survey-core";
import { Survey } from "survey-react-ui";

export interface ISurveyRunnerComponentProps {
    template: any;
    onComplete: (values: any) => void;
    initialValues?: any;
}

/**
 * @deprecated Use SurveyRunnerDialog
 */
export function SurveyRunnerComponent({template, initialValues, onComplete}: ISurveyRunnerComponentProps) {
    const surveyModel = React.useMemo(() => new SurveyModel(typeof template === "string" ? template : JSON.stringify(template)), [template]);
    React.useEffect(() => {
        if (initialValues) {
            for (const valueName in initialValues) {
                if (Object.prototype.hasOwnProperty.call(initialValues, valueName)) {
                    const element = initialValues[valueName];
                    surveyModel.setValue(valueName, element);
                }
            }
        }
    }, [initialValues, surveyModel]);
    const handleComplete = (sender: SurveyModel) => onComplete(sender.getAllValues());
    return <Survey model={surveyModel} onComplete={handleComplete}/>;
}