// import fetch from "cross-fetch";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { createFilterOptions } from '@material-ui/lab/Autocomplete';
export interface IAsyncSelectProps<E> {
    onSearchOptions: (criteria: string) => void;
    options: E[];
    value?: E;
    label: React.ReactNode;
    getOptionLabel: (option: E) => string;
    searching?: boolean;
    onRequiredValueSelected?: (option: E) => void;
    onValueSelected?: (option: E | undefined) => void;
    fullWidth?: boolean;
    disabled?: boolean;
    error?: boolean;
    helperText?: React.ReactNode;
}
function AsyncSelect<E>({
    label,
    getOptionLabel,
    searching,
    options,
    onValueSelected,
    onRequiredValueSelected,
    onSearchOptions,
    value,
    fullWidth,
    disabled,
    error,
    helperText
}: IAsyncSelectProps<E>) {
    const [open, setOpen] = React.useState(false);
    const [stateOptions, setStateOptions] = React.useState<E[]>([]);
    React.useEffect(() => {
        setStateOptions(options);
    }, [options, setStateOptions]);

    React.useEffect(() => {
        if (!open) {
            setStateOptions([]);
        }
    }, [open, setStateOptions]);
    const handleOnOpen = () => setOpen(true);
    const handleOnClose = () => setOpen(false);
    const handleChange = (event: object, newValue: E | E[] | null, reason: string) => {
        if (Array.isArray(newValue)) {
            newValue = newValue[0];
        }
        if (onValueSelected) {
            onValueSelected(newValue ? newValue : undefined);
        }
        if (onRequiredValueSelected && newValue) {
            onRequiredValueSelected(newValue);
        }
        setStateOptions([]);
    };
    // const filterOptions = createFilterOptions<E>();
    const handleInputChange = (event: object, inputValue: string, reason: string) =>
        onSearchOptions(inputValue);
    const renderInput = (params: any) => (
        <TextField
            {...params}
            label={label}
            margin="dense"
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <>
                        {searching ? (
                            <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                    </>
                )
            }}
            error={error}
            helperText={helperText} />
    );
    const getOptionSelected = (option: any, currentValue: any) => option === currentValue;
    return (
        <Autocomplete
            style={fullWidth ? { width: "100%" } : undefined}
            open={open}
            // filterOptions={filterOptions}
            onOpen={handleOnOpen}
            onClose={handleOnClose}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            options={stateOptions}
            onChange={handleChange}
            onInputChange={handleInputChange}
            loading={searching}
            value={value ?? null}
            renderInput={renderInput}
            disabled={disabled}
        />
    );
}
export default AsyncSelect;
