import React from 'react';
import { useDropzone } from 'react-dropzone';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from 'classnames';
import { Link } from "@material-ui/core";
import { Typography, Box, Tooltip } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import * as Uuid from 'uuid';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CloseIcon from '@material-ui/icons/Close';
import { formatDateTime } from 'tools/lib/utility';

const useStyles = makeStyles(theme =>
    createStyles({
        link: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: theme.palette.text.primary,
            "& > *:not(:first-child)": {
                marginLeft: theme.spacing(1)
            }
        },
        root: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: theme.spacing(1),
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        },
        acceptStyle: {
            boxShadow: theme.shadows[6],
            borderColor: 'transparent',
            borderStyle: 'solid',
            marginTop: 2,
            marginLeft: 2,
            marginBottom: -2,
            marginRight: -2
        },
        rejectStyle: {
            borderColor: '#ff1744'
        },
        disabled: {
            backgroundColor: "inherit",
            borderStyle: 'dotted',
        }
    })
);


const useNotDisplayStyles = makeStyles(theme =>
    createStyles({
        input: {
            display: 'none',
        },
    }),
);
function InputButton(props: { disabled: boolean, onFileOpen: (file: File) => void }) {
    const [idComponent] = React.useState(Uuid.v1());
    const classes = useNotDisplayStyles();
    const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files?.length) {
            return;
        }
        const file = e.target.files[0];
        props.onFileOpen(file);
        const i = e.target;
        // yes... it IS a terrible trick
        setTimeout(() => i.value = (null as unknown as string));
    }
    return <>
        <input aria-label='Choose a file' disabled={props.disabled} className={classes.input} id={idComponent} type="file" onChange={handleUpload} />
        <label htmlFor={idComponent}>
            <Tooltip title="Choose a file">
                <IconButton disabled={props.disabled} component="span">
                    <PublishIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </label>
    </>;
}

export interface IDropZoneProps {
    onFileOpen: (file: File) => void;
    onClick?: () => void;
    onClear?: () => void;
    emptyLabel?: React.ReactNode;
    fileName?: string;
    dateTime?: Date;
    disabled?: boolean;
    minSize?: number;
}

const useEmptyStyles = makeStyles(theme =>
    createStyles({
        label: {
            display: "flex",
            flex: 1,
            justifyContent: "center"
        }
    }),
);

function EmptyFile(props: { emptyLabel?: React.ReactNode }) {
    const classes = useEmptyStyles();
    return <Typography className={classes.label}>{props.emptyLabel ?? "Drop a file here"}</Typography>;
}
export default function DropZone(props: IDropZoneProps) {
    const { onFileOpen, emptyLabel, onClick, fileName, onClear, disabled = false, minSize, dateTime } = props;
    const {
        getRootProps,
        isDragAccept,
        isDragReject
    } = useDropzone({
        disabled,
        multiple: false,
        noClick: true,
        minSize,
        onDropAccepted: files => {
            setErrors([]);
            files?.length && onFileOpen(files[0]);
        },
        onDropRejected: files => files?.length && setErrors(files.flatMap(f => f.errors.map(value => value.message) ?? []))
    });

    const [errors, setErrors] = React.useState([] as string[]);

    const classes = useStyles();
    const handleOnClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) {
            onClick();
        }
    };

    const className = classNames(classes.root, {
        [classes.acceptStyle]: isDragAccept,
        [classes.rejectStyle]: isDragReject,
        [classes.disabled]: disabled
    });
    return (
        <Box>
            <Box {...getRootProps({ className })}>
                {fileName ? (<><Link className={classes.link} href="#" onClick={handleOnClick}>
                    <InsertDriveFileIcon />{fileName}
                </Link><>{(!!dateTime) && <Typography variant="subtitle1" color="textSecondary">
                    {formatDateTime(dateTime)}
                </Typography>}</></>) : <EmptyFile emptyLabel={disabled ? <span /> : emptyLabel} />}
                {onClear && <Tooltip title="Clear"><IconButton disabled={disabled || !fileName} onClick={onClear}>
                    <CloseIcon />
                </IconButton></Tooltip>}
                <InputButton disabled={disabled} onFileOpen={onFileOpen} />
            </Box>
            {errors.map((e, i) => <Typography key={i} color="error">{e}</Typography>)}
        </Box>
    );
}