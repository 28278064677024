import { Epic } from "redux-observable";
import { merge, of } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { ActionFactories, IAnyAction } from "features";
import { IFrequencyTypeModel, IIndexModel, indexesApi } from "proxy/apiProxy";
import { mapToPayload } from "lib/rxJsUtility";

export const loadIndexes: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("indexes", "indexSearch"),
        mergeMap(() => indexesApi.getAllAsync({})),
        map(ActionFactories.indexes.indexLoadedAll));

export const loadIndexHistoricalValues: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("indexes", "indexHistoricalValuesLoad"),
        mergeMap(id => indexesApi.getHistoricalValuesAsync({ id })),
        map(ActionFactories.indexes.indexHistoricalValuesLoaded));


export const saveHistoricalValue: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("indexes", "indexHistoricalValueSave"),
        mergeMap(historicalValuePayload => of(historicalValuePayload).pipe(
            mergeMap(({ historicalValue, indexId }) => indexesApi.saveHistoricalValueAsync({ id: indexId, historicalValueSet: historicalValue })),
            map(() => ActionFactories.indexes.indexHistoricalValueSaved(historicalValuePayload)))));

export const loadIndex: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("indexes", "indexLoad"),
            share());

        const index$ = merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => indexesApi.getAsync({ id }))),
            requestedId$.pipe(
                filter(id => !id),
                map(createEmptyIndex))
        ).pipe(share());
        return merge(
            index$.pipe(map(ActionFactories.indexes.indexLoaded)),
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad())));
    };

function createEmptyIndex(): IIndexModel {
    return {
        id: 0,
        pricingFrequency: IFrequencyTypeModel.Daily,
        internalCode: "",
        name: "",
        classifications: {},
        isCurrencyRiskFree: false,
        isRateValues: false,
        dataProviderCodes: {},
    };
}

export const saveIndex: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("indexes", "indexSave"),
        mergeMap(model => indexesApi.saveAsync({ model })),
        map(ActionFactories.indexes.indexSaved));

export const deleteIndex: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("indexes", "indexDelete"),
            mergeMap(id => indexesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.indexes.indexDeleted),
            share()
        );
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
