import {
    EntitySummaryModel,
    IOversightLevelModel,
    PortfolioSummaryModel,
    ProcessExecutionModel,
    ProcessExecutionSummaryModel,
    RelationshipSummaryModel,
    SecuritySummaryModel
} from "proxy/apiProxy";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";
import * as React from "react";
import { getEnumLabels } from "tools/lib/utility";
import { Box, Chip } from "@material-ui/core";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import CertificateOutlineIcon from "mdi-material-ui/CertificateOutline";
import { getProcessExecutionTypeLabel } from "./getProcessExecutionTypeLabel";
import { notNull } from "lib/typeUtils"
import { useScreenNavigate } from "tools/routing/screenRouteHooks";

interface IProcessExecutionListProps {
    showContextualColumns?: boolean,
    processExecutions: (ProcessExecutionSummaryModel | ProcessExecutionModel)[] | undefined;
    dictionaries: {
        entities: Record<EntitySummaryModel["id"], EntitySummaryModel>;
        relationships: Record<string | number, RelationshipSummaryModel>;
        portfolios?: Record<string | number, PortfolioSummaryModel>;
        securities?: Record<string | number, SecuritySummaryModel>;
    };
}

const oversightLevelModels = getEnumLabels(IOversightLevelModel);
export function ProcessExecutionList({ processExecutions = [], dictionaries, showContextualColumns }: IProcessExecutionListProps) {
    const { classificationTypes, classificationTypesFlat } = useReduxSelections("processClassificationType");
    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, ({ classifications }: (ProcessExecutionModel | ProcessExecutionSummaryModel)) => classifications);
    const navigate = useScreenNavigate();

    const handleRowSelect = ({ id }: (ProcessExecutionSummaryModel | ProcessExecutionModel)) => navigate("ProcessExecution", { id });
    const columns: IColumnDefinition[] = React.useMemo(() => {
        const contextualColumns: IColumnDefinition[] = [
            {
                name: "type",
                title: "Target Type",
                filteringEnabled: true,
                getCellValue: (row: (ProcessExecutionSummaryModel | ProcessExecutionModel)) => getProcessExecutionTypeLabel(row, dictionaries)
            },
            {
                name: "name",
                filteringEnabled: true,
                title: "Target Name",
                getCellValue: (row: (ProcessExecutionSummaryModel | ProcessExecutionModel)) => {
                    switch (row.type) {
                        case "EntityProcessExecutionModel":
                        case "EntityProcessExecutionSummaryModel":
                            return getEntityName(dictionaries.entities[row.entityId ?? 0]);
                        case "PortfolioProcessExecutionModel":
                        case "PortfolioProcessExecutionSummaryModel":
                            const portfolio = (dictionaries.portfolios ?? {})[row.portfolioId ?? 0];
                            return portfolio?.name;
                        case "RelationshipProcessExecutionModel":
                        case "RelationshipProcessExecutionSummaryModel":
                            const relationship = dictionaries.relationships[row.relationshipId ?? 0];
                            const entity = dictionaries.entities[relationship?.entityId ?? 0]
                            return getEntityName(entity);
                        case "SecurityProcessExecutionModel":
                        case "SecurityProcessExecutionSummaryModel":
                            const security = (dictionaries.securities ?? {})[row.securityId ?? 0];
                            return security?.name;
                        default:
                            return "";
                    }
                }
            },
            {
                name: "roleTitle",
                title: "Target Role Title",
                filteringEnabled: true,
                getCellValue: (row: (ProcessExecutionSummaryModel | ProcessExecutionModel)) => {
                    switch (row.type) {
                        case "RelationshipProcessExecutionModel":
                        case "RelationshipProcessExecutionSummaryModel":
                            const relationship = dictionaries.relationships[row.relationshipId ?? 0];
                            return relationship?.title;
                        default:
                            return "";
                    }
                }
            }];

        return [...(showContextualColumns ? contextualColumns : []), {
            name: "startValidity",
            title: "Start Validity",
            getCellValue: (row: (ProcessExecutionSummaryModel | ProcessExecutionModel)) => row.startValidity,
            columnType: "date"
        }, {
            name: "endValidity",
            title: "End Validity",
            getCellValue: (row: (ProcessExecutionSummaryModel | ProcessExecutionModel)) => row.endValidity,
            columnType: "date"
        }, {
            name: "responsible",
            filteringEnabled: true,
            title: "Responsible",
            getCellValue: (row: (ProcessExecutionSummaryModel | ProcessExecutionModel)) => {
                const entitiesNames = row.responsibles
                    .map(resp => resp.responsibleId)
                    .filter(notNull)
                    .map(respId => dictionaries.relationships[respId]?.entityId)
                    .filter(notNull)
                    .map(entityId => dictionaries.entities[entityId])
                    .filter(notNull)
                    .map(entity => getEntityName(entity))
                    .filter(notNull);
                return entitiesNames.join(', ');
            },
        }, {
            name: "oversightLevel",
            title: "Oversight Level",
            getCellValue: (row: (ProcessExecutionSummaryModel | ProcessExecutionModel)) => oversightLevelModels[row.oversightLevel],
        }, {
            name: "onSite",
            title: "On Site",
            getCellValue: (row: (ProcessExecutionSummaryModel | ProcessExecutionModel)) => row.onSite,
            columnType: "boolean"
        }, {
            name: "approvedById",
            filteringEnabled: true,
            title: "Approved",
            getCellValue: (row: (ProcessExecutionSummaryModel | ProcessExecutionModel)) => row.approvedById ? <Chip size="small" label={getEntityName(dictionaries.entities[row.approvedById])} icon={<CertificateOutlineIcon />} /> : null
        },
        ...classificationTypesColumns];
    }, [classificationTypesColumns, dictionaries.entities, dictionaries.portfolios, dictionaries.relationships, dictionaries.securities, showContextualColumns]);

    const state: IGridState = {
        "startValidity": { width: 120, sortingPosition: -1 },
        "endValidity": { width: 120, sortingPosition: -2 },
        "type": { width: 150 },
        "responsible": { width: 150 },
        "approvedById": { width: 200 },
        "name": { width: 200 },
        "oversightLevel": { width: 150 },
        "onSite": { width: 100 },
        "roleTitle": { width: 200 },
        ...classificationTypesState
    };

    const getRowKey = React.useCallback((row: (ProcessExecutionSummaryModel | ProcessExecutionModel)) => row.id, []);

    return <Box display="flex" flexDirection="column" height="100%">
        <ExtendedGrid
            getRowId={getRowKey}
            defaultExportFileName="ProcessExecutions.xlsx"
            columns={columns}
            onRowClick={handleRowSelect}
            rows={processExecutions}
            initialState={state}
            userCanGroup={true}
            defaultColumnWidth={120} />
    </Box>;
}
