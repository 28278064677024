import React from "react";
import { ReadOnlyContext } from "./ReadOnlyContext";

export interface IReadOnlyProps {
    value?: boolean;
}
export default function ReadOnly({ children, value = true }: React.PropsWithChildren<IReadOnlyProps>) {
    return <ReadOnlyContext.Provider value={value}>
        {children}
    </ReadOnlyContext.Provider>
}
