import * as React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ExpandCollapseButton } from "components/global/ExpandCollapseButton";
import { Container, ItemDisplay } from "./DashboardFormattingContracts";
import { buildItemProps } from "./DashboardBox";
// https://docs.npmjs.com/cli/v8/configuring-npm/package-json#local-paths

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: theme.shadows[5],
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch"
    },
    cardHeader: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
        padding: theme.spacing(1),
        display: "flex",
        alignItems: "center"
    },
    cardContent: {
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        // padding: theme.spacing(1)
    },
    cardContentHidden: {
        visibility: "hidden"
    },
    dashboardLink: {
        textAlign: "left"
    },
    disabledLink: {
        "&:hover": {
            textDecoration: "initial",
        },
        cursor: "not-allowed",
    }
}));
export interface IHeaderContainerProps<TAsItemDisplay extends ItemDisplay = never> extends React.PropsWithChildren {
    label: string | undefined;
    collapsed: boolean | undefined;
    layout: TAsItemDisplay | undefined;
    itemDisplayType?: Container<any>["type"];
}
export function HeaderContainer<TAsItemDisplay extends ItemDisplay = never>({ children, label, collapsed = false, layout, itemDisplayType }: IHeaderContainerProps<TAsItemDisplay>) {
    const [expanded, setExpanded] = React.useState(!collapsed);
    const handleOnExpandChanged = React.useCallback((exp: boolean) => void setExpanded(exp), []);
    const styles = useStyles();
    const itemBoxProperties = React.useMemo(() => itemDisplayType ? buildItemProps(itemDisplayType, layout ?? {}) : {}, [itemDisplayType, layout])
    return <Box className={clsx(styles.card)} style={itemBoxProperties}>
        <Box className={clsx(styles.cardHeader)}>
            <Typography variant="h5">{label}</Typography>
            <Box flexGrow={1} />
            <ExpandCollapseButton expanded={expanded} onChanged={handleOnExpandChanged} />
        </Box>
        {expanded && children}
    </Box>
}
