import { useField } from 'formik';
import {
    // List, ListItem, ListItemText, ListItemSecondaryAction, 
    Switch, Grid, FormControlLabel
} from "@material-ui/core";
import { IFeatureModel } from "proxy/apiProxy";
import { getEnumLabels } from "tools/lib/utility";
import { ReadOnlyContext } from 'tools/fieldComponents/ReadOnlyContext';

interface ISelectFieldProps {
    name: string;
    readOnly: boolean;
}

const features = getEnumLabels(IFeatureModel);

function FeaturesField({ name, readOnly }: ISelectFieldProps) {
    const [, { value, initialValue }, { setValue }] = useField<IFeatureModel[]>({ name });
    const handleToggle = (feature: IFeatureModel) => {
        const currentIndex = value.indexOf(feature);
        const newValue = [...value];
        if (currentIndex >= 0) {
            newValue.splice(currentIndex, 1);
        }
        else {
            newValue.push(feature);
        }
        setValue(newValue);
    }
    return <Grid container={true} style={{ padding: 16 }}>
        {Object.keys(features).sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)).map(feature =>
            <Grid item={true} xs={3} key={feature}>
                <FormControlLabel control={<Switch
                    readOnly={readOnly}
                    edge="start"
                    checked={value.indexOf(feature as IFeatureModel) !== -1}
                    onChange={handleToggle.bind(null, feature as IFeatureModel)} />}
                    label={renderLabel(feature as IFeatureModel)} />
            </Grid>)}
    </Grid>
    function renderLabel(feature: IFeatureModel) {
        const isCurrentlyChecked = (value ?? []).indexOf(feature as IFeatureModel) !== -1;
        const isInitiallyChecked = (initialValue ?? []).indexOf(feature as IFeatureModel) !== -1;
        if (isCurrentlyChecked !== isInitiallyChecked) {
            return <b>{features[feature]}</b>;
        }
        else {
            return features[feature];
        }
    }
}

export interface IAccessManagementProps {
    formFieldName: string;
}
function AccessManagement({ formFieldName }: IAccessManagementProps) {
    return <ReadOnlyContext.Consumer>{readOnly => <FeaturesField name={formFieldName} readOnly={readOnly} />}</ReadOnlyContext.Consumer>;
}

export default AccessManagement;
