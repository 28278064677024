
import { IEntityTypeModel, EntitySummaryModel, EntityModel } from "proxy/apiProxy";
import BankIcon from "mdi-material-ui/Bank";
import ApartmentIcon from '@material-ui/icons/Apartment';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
export default function EntityIcon({ type }: { type: IEntityTypeModel | EntitySummaryModel["type"] | EntityModel["type"] }) {
    switch (type) {
        case IEntityTypeModel.Person:
        case "PersonModel":
        case "PersonSummaryModel":
            return <PersonIcon />;
        case IEntityTypeModel.Company:
        case "CompanyModel":
        case "CompanySummaryModel":
            return <ApartmentIcon />;
        case IEntityTypeModel.EntityGroup:
        case "EntityGroupModel":
        case "EntityGroupSummaryModel":
            return <GroupIcon />;
        case IEntityTypeModel.Sicav:
        case "SicavModel":
        case "SicavSummaryModel":
            return <BankIcon />;
    }
}
