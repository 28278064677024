import { ICurrencyModel, PortfolioSummaryModel, SecuritySummaryModel, TradeBookTradeModel, IOrderTypeModel, IOperationTypeModel, ITradeBookSecurityTradeModel, ITradeBookFxTradeModel, ITradeStatusModel } from "proxy/apiProxy";
import * as React from "react";
import { useField } from "formik";
import { Box, Paper } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { getEnumLabels, oProps, splitPascalCase } from "tools/lib/utility";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { isCurrencyPosition } from "./isCurrencyPosition";
import { IPosition } from "./slice";
import SecuritySelectField from "components/searchers/SecuritySelectField";
import { SummaryField } from "components/global/SummaryField";
import { IGetPortfolioSummary, IGetSecuritySummary } from "features/Reference/slice";
import ManagedPortfolioSelectField from "components/searchers/ManagedPortfolioSelectField";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import ReadOnly from "tools/fieldComponents/ReadOnly";

const orderTypes = getEnumLabels(IOrderTypeModel);
const buySellTypes = getEnumLabels(IOperationTypeModel);

const ContainerRow = React.memo(withStyles((theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "baseline",
            "& > *:not(:first-child)": {
                marginLeft: theme.spacing(2)
            },
            "& > *": {
                flexGrow: 1
            },
        }
    })
)(Box));
const StyledPaper = withStyles(theme =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        }
    })
)(Paper);

interface ITradeDetailsProps {
    dictionaries: {
        portfolios: Record<string | number, PortfolioSummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
    };
    referenceCurrencies: Record<string | number, ICurrencyModel>;
    positions: IPosition[];
    onSecurityLoaded?: (security: IGetSecuritySummary) => void;
    onPortfolioLoaded?: (found: IGetPortfolioSummary) => void;
    fullMode: boolean;
    trade: TradeBookTradeModel;
    field?: string;
}
function InnerTradeDetails({ field, trade, dictionaries, referenceCurrencies, positions, onSecurityLoaded, onPortfolioLoaded, fullMode }: ITradeDetailsProps) {
    // const tradeField = oProps<TradeBookTradeModel[]>(tradesField).path(tradeIndex);
    const { portfolios, securities } = dictionaries;
    // const [, { value: trade }] = useField<TradeBookTradeModel>(tradeField);
    const securityId: undefined | number = (trade as ITradeBookSecurityTradeModel)?.securityId;
    const security = securities[securityId ?? 0];
    const portfolio = portfolios[trade?.portfolioId ?? 0];
    const purchasedCurrencyId: undefined | number = (trade as ITradeBookFxTradeModel)?.purchasedCurrencyId;
    const readOnly = !fullMode || (trade.status !== ITradeStatusModel.New && !!trade.status);
    const targetCurrencyCode = React.useMemo(() => {
        if (trade.type === "TradeBookFxTradeModel") {
            const t = positions.find(i => isCurrencyPosition(i) && i.currencyId === purchasedCurrencyId);
            if (t && isCurrencyPosition(t)) {
                return referenceCurrencies[t?.currencyId ?? 0]?.isoCode;
            }
            return undefined;
        }
        else {
            const t = positions.find(i => !isCurrencyPosition(i) && i.securityId === securityId);
            if (t && !isCurrencyPosition(t)) {
                return referenceCurrencies[securities[t?.securityId ?? 0]?.currencyId ?? 0]?.isoCode;
            }
            return undefined;
        }
    }, [referenceCurrencies, securities, positions, purchasedCurrencyId, securityId, trade?.type]);
    return <ReadOnly value={readOnly}>
        <SummaryField label="Status" value={splitPascalCase(trade.status)} />
        {fullMode && <StyledPaper>
            <ManagedPortfolioSelectField required={true} name={oProps<TradeBookTradeModel>(field).path("portfolioId")} label="Portfolio" onSelected={onPortfolioLoaded} />
            {!!portfolio && <ContainerRow>
                <SummaryField label="Portfolio Name" value={portfolios[portfolio.id].name} />
                <SummaryField label="Portfolio Code" value={portfolios[portfolio.id].internalCode} />
            </ContainerRow>}
        </StyledPaper>}
        {trade.type === "TradeBookFxTradeModel"
            ? <FxTradeDetails referenceCurrencies={referenceCurrencies} fullMode={fullMode} field={field} />
            : <SecurityTradeDetails portfolio={portfolio} referenceCurrencies={referenceCurrencies} fullMode={fullMode} security={security} onSecurityLoaded={onSecurityLoaded} field={field} />}
        <FormMultiCulturedTextField name={oProps<TradeBookTradeModel>(field).path("comment")} label="Comment" multiline={true} />
        <FormCheckBoxField name={oProps<TradeBookTradeModel>(field).path("disabled")} label="Disabled" />
        <ContainerRow>
            <FormSimpleSelectField name={oProps<TradeBookTradeModel>(field).path("orderType")} label="Order Type" options={orderTypes} required={true} />
            <FormTextField name={oProps<TradeBookTradeModel>(field).path("limit")} label="Limit" isNumber={true} adornment={targetCurrencyCode} />
            <FormDatePickerField name={oProps<TradeBookTradeModel>(field).path("expirationDateTime")} label="Expires on" />
        </ContainerRow>
    </ReadOnly>
}
export default React.memo(InnerTradeDetails);
interface ISecurityTradeDetailsProps {
    portfolio: PortfolioSummaryModel;
    referenceCurrencies: Record<string | number, ICurrencyModel>;
    onSecurityLoaded?: (security: IGetSecuritySummary) => void;
    fullMode: boolean;
    security: SecuritySummaryModel;
    field: string | undefined;
}
function SecurityTradeDetails({ referenceCurrencies, portfolio, onSecurityLoaded, fullMode, security, field }: ISecurityTradeDetailsProps) {
    const [{ value: quantity }] = useField<number | undefined>(oProps<ITradeBookSecurityTradeModel>(field).path("quantity"));
    const [{ value: amountInPtfCcy }] = useField<number | undefined>(oProps<ITradeBookSecurityTradeModel>(field).path("amountInPtfCcy"));
    const [{ value: buySell }] = useField<IOperationTypeModel | undefined>(oProps<ITradeBookSecurityTradeModel>(field).path("buySell"));
    const ptfCurrencyCode = React.useMemo(() => referenceCurrencies[portfolio?.currencyId ?? 0]?.isoCode, [referenceCurrencies, portfolio]);
    return <>
        {fullMode && <StyledPaper>
            <SecuritySelectField required={true} name={oProps<ITradeBookSecurityTradeModel>(field).path("securityId")} onSelected={onSecurityLoaded} />
            {(!!security) && <ContainerRow>
                <SummaryField label="Security Name" value={security.name} />
                <SummaryField label="Security Code" value={security.internalCode} />
            </ContainerRow>}
        </StyledPaper>}
        <ContainerRow>
            <FormSimpleSelectField name={oProps<ITradeBookSecurityTradeModel>(field).path("buySell")} label="Buy/Sell" options={buySellTypes} required={true} />
            {(!amountInPtfCcy && (buySell !== IOperationTypeModel.TotalSell)) && <FormTextField name={oProps<ITradeBookSecurityTradeModel>(field).path("quantity")} label="Quantity" isNumber={true} />}
            {(!quantity && (buySell !== IOperationTypeModel.TotalSell)) && <FormTextField name={oProps<ITradeBookSecurityTradeModel>(field).path("amountInPtfCcy")} label="Amount" adornment={ptfCurrencyCode} isNumber={true} />}
            <FormTextField name={oProps<ITradeBookSecurityTradeModel>(field).path("targetWeight")} label="Target Weight" isPercentage={true} />
        </ContainerRow>
    </>
}
interface IFxTradeDetailsProps {
    referenceCurrencies: Record<string | number, ICurrencyModel>;
    fullMode: boolean;
    field: string | undefined;
}
function FxTradeDetails({ referenceCurrencies, fullMode, field }: IFxTradeDetailsProps) {
    const [{ value: soldCurrencyId }] = useField(oProps<ITradeBookFxTradeModel>(field).path("soldCurrencyId"));
    const [{ value: purchasedCurrencyId }] = useField(oProps<ITradeBookFxTradeModel>(field).path("purchasedCurrencyId"));
    return <>
        <ContainerRow>
            {fullMode && <CurrencySelectField name={oProps<ITradeBookFxTradeModel>(field).path("soldCurrencyId")} label="Sold Currency" />}
            <FormTextField name={oProps<ITradeBookFxTradeModel>(field).path("soldAmount")} label="Sold Amount" isNumber={true} minValue={0} adornment={referenceCurrencies[soldCurrencyId]?.isoCode} />
        </ContainerRow>
        <ContainerRow>
            {fullMode && <CurrencySelectField name={oProps<ITradeBookFxTradeModel>(field).path("purchasedCurrencyId")} label="Purchased Currency" />}
            <FormTextField name={oProps<ITradeBookFxTradeModel>(field).path("purchasedAmount")} label="Purchased Amount" isNumber={true} minValue={0} adornment={referenceCurrencies[purchasedCurrencyId]?.isoCode} />
        </ContainerRow>
    </>
}
