import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, IPortfolioMacroModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import PortfolioScriptEditor from "features/ManagedPortfolio/PortfolioScriptEditor";
import { oProps } from "tools/lib/utility";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { useEffect } from "react";

export const globalPortfolioComplianceScreen = {
    route: "/config/compliance/globalPortfolioCompliancePolicy",
    component: GlobalPortfolioCompliance,
    label: "Global Portfolio Compliance",
}

function GlobalPortfolioCompliance() {
    const {
        globalPortfolioComplianceSave,
        globalPortfolioComplianceValidateScript,
        globalPortfolioComplianceLoad
    } = useReduxActions("globalPortfolioCompliance");
    const {
        globalPortfolioComplianceMacro,
        globalPortfolioComplianceMacroLoading,
        globalPortfolioComplianceMacroSaving,
        globalPortfolioComplianceMacroValidateScriptMetadata,
        globalPortfolioComplianceMacroCheckResult = { errors: [] } } = useReduxSelections("globalPortfolioCompliance");


    useEffect(() => {
        globalPortfolioComplianceLoad()
    }, [globalPortfolioComplianceLoad]);

    const handleSubmit = (model: IPortfolioMacroModel, { setSubmitting }: FormikHelpers<IPortfolioMacroModel>) => {
        globalPortfolioComplianceSave(model);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={globalPortfolioComplianceMacro} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm }: FormikProps<IPortfolioMacroModel>) {
        const handleSaveClick = () => {
            if (isValid && dirty)
                submitForm();
        }
        return <WriteAccess value={IFeatureModel.GlobalPortfolioCompliancePolicyWrite}><DetailPanel
            isQuerying={globalPortfolioComplianceMacroLoading || globalPortfolioComplianceMacroSaving}
            title="Global Portfolio Compliance Checks"
            noPadding={true}
            saveAllowed={IFeatureModel.GlobalPortfolioCompliancePolicyWrite}
            canSave={dirty && isValid}
            onSaveClick={handleSaveClick}>
            <PortfolioScriptEditor
                fieldName={oProps<IPortfolioMacroModel>().path("script")}
                metadata={globalPortfolioComplianceMacroValidateScriptMetadata}
                onSaveRequest={handleSaveClick}
                onScriptChanged={globalPortfolioComplianceValidateScript}
                scriptErrors={globalPortfolioComplianceMacroCheckResult.errors} />
        </DetailPanel>
        </WriteAccess>
    }
}
