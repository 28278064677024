import { Epic } from "redux-observable";
import { from, merge } from "rxjs";
import { filter, map, mergeMap, share, toArray } from "rxjs/operators";
import {
    customScreensApi,
    IGetShareClassModel,
    IManagedShareClassModel,
    macroScriptsApi,
    managedShareClassesApi
} from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadSecurities: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedShareClass", "shareClassSearch"),
        mergeMap(() => managedShareClassesApi.searchAsync({})),
        map(ActionFactories.managedShareClass.shareClassLoadedAll));

export const loadSecurityHistoricalValues: Epic<IAnyAction>
    = action$ => {
        const shareClassToLoad$ = action$.pipe(
            mapToPayload("managedShareClass", "shareClassHistoricalValuesLoad"),
            mergeMap(id => managedShareClassesApi.getHistoricalValuesAsync({ id })),
            share());
        return merge(
            shareClassToLoad$.pipe(map(ActionFactories.managedShareClass.shareClassHistoricalValuesLoaded)),
            shareClassToLoad$.pipe(map(() => ActionFactories.macroScript.macroScriptLoadAll())));
    }

export const loadProcessExecutions: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedShareClass", "shareClassProcessExecutionsLoad"),
        mergeMap(id => managedShareClassesApi.getProcessesAsync({ id })),
        map(ActionFactories.managedShareClass.shareClassProcessExecutionsLoaded));

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedShareClass", "shareClassDocumentsLoad"),
        mergeMap(id => managedShareClassesApi.getDocumentsAsync({ id })),
        map(ActionFactories.managedShareClass.shareClassDocumentsLoaded));

export const submitCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedShareClass", "shareClassCustomScreenDataSubmit"),
        mergeMap(managedShareClassesApi.submitCustomScreenDataAsync),
        map(ActionFactories.managedShareClass.shareClassCustomScreenDataSubmitted));
export const loadCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedShareClass", "shareClassCustomScreenDatasLoad"),
        mergeMap(id => managedShareClassesApi.getLastCustomScreenDatasAsync({ id })),
        map(ActionFactories.managedShareClass.shareClassCustomScreenDatasLoaded));

export const loadSecurity: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("managedShareClass", "shareClassLoad"),
            share());

        const customScreens$ = requestedId$.pipe(
            mergeMap(() => customScreensApi.getAllAsync({})),
            map(customScreens => customScreens.filter(customScreen => customScreen.type === "ManagedShareClassCustomScreenSummaryModel")),
            mergeMap(customScreens => from(customScreens).pipe(
                mergeMap(({ id }) => customScreensApi.getAsync({ id })),
                toArray(),
                map(ActionFactories.managedShareClass.shareClassCustomScreensLoaded))));

        const shareClass$ = merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => managedShareClassesApi.getAsync({ id }))),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({ shareClass: createEmptyShareClass(), entities: {}, subFunds: {} } as IGetShareClassModel)))
        ).pipe(share());
        return merge(
            requestedId$.pipe(
                filter(id => typeof id === "number" && !!id),
                map(id => ActionFactories.managedShareClass.shareClassCustomScreenDatasLoad(typeof id === "number" ? id : 0))),
            customScreens$,
            shareClass$.pipe(map(ActionFactories.managedShareClass.shareClassLoaded)),
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad()))
        );
    };
export const getMonitoringResultLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedShareClass", "shareClassMonitoringResultLoad"),
        mergeMap(macroScriptsApi.getMonitoringResultForTargetAsync),
        map(ActionFactories.managedShareClass.shareClassMonitoringResultLoaded));

function createEmptyShareClass(): IManagedShareClassModel {
    return { id: 0, classifications: {}, dataProviderCodes: {}, name: "", shortName: "", internalCode: "", passports: [], shareClassExtensionFieldsValues: {}, securityExtensionFieldsValues: {} };
}
export const saveRoleRelationship: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("managedShareClass", "shareClassSave"),
        mergeMap(model => managedShareClassesApi.saveAsync({ model })),
        map(ActionFactories.managedShareClass.shareClassSaved));
export const deleteSecurity: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("managedShareClass", "shareClassDelete"),
            mergeMap(id => managedShareClassesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.managedShareClass.shareClassDeleted),
            share()
        );
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
