import * as React from "react";
import { useCallback, useEffect } from "react";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { EntityModel, EntitySummaryModel, IFeatureModel, ISicavModel, ISicavSummaryModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";

export const mySicavsScreen = {
    route: "/mysicavs",
    label: "My Sicavs",
    keywords: "management fund sicav",
    component: Sicavs
}

function Sicavs() {
    const { sicavs, dictionaries: { entities }, sicavAllLoading } = useReduxSelections("managedSicav");
    const { managedSicavLoadAll } = useReduxActions("managedSicav")

    useEffect(() => {
        managedSicavLoadAll()
    }, [managedSicavLoadAll]);

    const navigate = useScreenNavigate()
    const handleRowClick = useCallback((row: (ISicavSummaryModel | ISicavModel)) => navigate("MySicav", { id: row.id }), [])
    const handleAddNewClick = useCallback(() => navigate("MySicav", { id: 0 }), [])
    const { columns, state } = React.useMemo(() => {
        const cs = [
            { name: "InternalCode", title: "Internal Code", getCellValue: (row: (EntitySummaryModel | EntityModel)) => row.internalCode, filteringEnabled: true, positionToKeep: "left" },
            { name: "Name", title: "Name", getCellValue: (row: (EntitySummaryModel | EntityModel)) => getEntityName(row) ?? "", filteringEnabled: true }
        ] as IColumnDefinition[];
        const state = {
            "InternalCode": { width: 150 },
            "Name": { width: 280 },
        } as IGridState;

        cs.push({ name: "Issuer", title: "Issuer", getCellValue: (row: (ISicavSummaryModel | ISicavModel)) => row.issuerId && getEntityName(entities[row.issuerId]) });
        state.Issuer = { width: 280 };
        return {
            columns: cs,
            state
        };
    }, [entities]);

    const getRowKey = (row: EntitySummaryModel | EntityModel) => row.id || 0;

    return <SearchPanel title="Funds/Sicavs" isQuerying={sicavAllLoading}
        addAllowed={IFeatureModel.ManagedPortfolioWrite}
        onAddClick={handleAddNewClick}>
        <ExtendedGrid
            getRowId={getRowKey}
            columns={columns}
            rows={sicavs}
            onRowClick={handleRowClick}
            initialState={state}
            userCanGroup={true}
            defaultExportFileName={`Funds.xlsx`} />
    </SearchPanel>
}