import * as React from "react";
import produce from "immer";
import { IArrayControlBase, ItemDisplay } from "./DashboardFormattingContracts";
import { isSelectionReference, useFilter, useValue } from "./ValueProvider";
// https://docs.npmjs.com/cli/v8/configuring-npm/package-json#local-paths

export interface ISelectionContext {
    selections: Record<string, object[]>;
    updateSelections: UpdateSelectionCallback;
}
export type UpdateSelectionCallback = (path: string, value: object[] | undefined) => void;

export const SelectionContext = React.createContext<Record<string, object[]>>({});
export const UpdateSelectionContext = React.createContext<UpdateSelectionCallback>(() => { });

export function useSelection({ context, rowKey = ".Id", targetSelection, valuesFilter }: IArrayControlBase<ItemDisplay>) {
    const getValue = useValue();
    const isVisible = useFilter(valuesFilter);
    const selections = React.useContext(SelectionContext);
    const updateSelections = React.useContext(UpdateSelectionContext);
    const rows = React.useMemo(() => (getValue("array", context) ?? []).filter(row => isVisible(row)), [context, getValue, isVisible]);
    const getRowKey = React.useCallback((row: object) => getValue("stringNumber", rowKey, row) ?? 0, [getValue, rowKey]);
    const selectedRows = React.useMemo(() => {
        if (isSelectionReference(targetSelection)) {
            const rowsInSelection = selections[targetSelection.substring(2)];
            if (rowsInSelection && rowsInSelection.length) {
                return rowsInSelection;
            }
        }
        return undefined;
    }, [selections, targetSelection]);
    const onSelectionChanged = React.useCallback((newSelection: object[]) => {
        if (isSelectionReference(targetSelection)) {
            updateSelections(targetSelection.substring(2), newSelection);
        }
    }, [targetSelection, updateSelections]);

    return React.useMemo(() => ({
        selectedRows,
        onSelectionChanged: targetSelection ? onSelectionChanged : undefined,
        rows,
        getRowKey
    }), [getRowKey, onSelectionChanged, rows, selectedRows, targetSelection]);
}
export interface ISelectionProviderProps {
    defaultSelection?: any;
    children: React.ReactNode;
}
export function SelectionProvider({ children, defaultSelection }: ISelectionProviderProps) {
    const [selections, dispatch] = React.useReducer((state: Record<string, object[]>, { path, value }: { path: string, value: object[] | undefined }) => produce(state, (draft: any) => {
        if (value && value.length) draft[path] = value;
        else delete draft[path];
    }), defaultSelection ?? {});
    const updateSelections = React.useCallback<UpdateSelectionCallback>((path, value) => dispatch({ path, value }), []);

    return <UpdateSelectionContext.Provider value={updateSelections}>
        <SelectionContext.Provider value={selections}>
            {children}
        </SelectionContext.Provider>
    </UpdateSelectionContext.Provider>
}
