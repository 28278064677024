import { IMacroDiagnostic } from "../slice";
import ReactJson from "react-json-view";

interface IDatasetStructureProps {
    diagnostics?: IMacroDiagnostic;
}
export function DatasetStructure({ diagnostics }: IDatasetStructureProps) {
    if (!diagnostics) {
        return <p>Issue with macro</p>;
    }
    const { returnType } = diagnostics;
    if (!returnType) {
        return <p>no return type</p>;
    }
    return <ReactJson
        style={{ width: "100%" }}
        name="Dataset"
        collapsed={2}
        displayDataTypes={false}
        displayObjectSize={false}
        src={returnType} />
}
