import * as React from "react";
import { Container, IFlexContainer, IFlexDetailContainer, IFlexItemDisplay, IGridContainer, IGridDetailContainer, IGridItemDisplay, ITabsContainer, ItemControl, ItemDisplay } from "./DashboardFormattingContracts";
import { RelativeValueProvider, useValue, useVisible } from "./ValueProvider";
import { Item } from "./ItemComponent";
import { ContainerBox, ItemBox } from "./DashboardBox";
import { HeaderContainer } from "./HeaderContainer";
import { useSelection } from "./SelectionProvider";
import { DiagramComponent } from "./DiagramComponent";
import { AppBar, Tab, Tabs } from "@material-ui/core";
// https://docs.npmjs.com/cli/v8/configuring-npm/package-json#local-paths

export interface IContainerComponentProps<TAsItemDisplay extends ItemDisplay> {
    definition: Container<TAsItemDisplay>;
    itemDisplayType?: Container<any>["type"];
}
export function ContainerComponent<TAsItemDisplay extends ItemDisplay = never>({ definition, itemDisplayType }: IContainerComponentProps<TAsItemDisplay>) {
    const getValue = useValue();
    const getVisible = useVisible();
    const [labelValue, hiddenValue, collapsedValue]
        = React.useMemo(() => [
            getValue("string", definition.label),
            !getVisible(definition),
            getValue("boolean", definition.collapsed)],
            [getValue, definition, getVisible]);
    if (hiddenValue) {
        return null;
    }
    if (definition.label) {
        return <HeaderContainer label={labelValue} collapsed={collapsedValue} layout={definition.itemLayout} itemDisplayType={itemDisplayType}>
            <ContainerComponentContent definition={definition} itemDisplayType={itemDisplayType} />
        </HeaderContainer>
    }
    else {
        return <ContainerComponentContent definition={definition} itemDisplayType={itemDisplayType} />
    }
}
export interface IInternalContainerComponentProps<TAsItemDisplay extends ItemDisplay> {
    definition: IGridContainer<TAsItemDisplay> | IFlexContainer<TAsItemDisplay> | IGridDetailContainer<TAsItemDisplay> | IFlexDetailContainer<TAsItemDisplay>;
    itemDisplayType?: Container<any>["type"];
}
export function ContainerComponentContent<TAsItemDisplay extends ItemDisplay = never>({ definition, itemDisplayType }: IContainerComponentProps<TAsItemDisplay>) {
    switch (definition.type) {
        case "flexContainer":
        case "gridContainer":
            return <SimpleContainerComponentContent definition={definition} itemDisplayType={itemDisplayType} />
        case "flexDetailContainer":
        case "gridDetailContainer":
            return <MultipleContainerComponentContent definition={definition} itemDisplayType={itemDisplayType} />
        case "tabsContainer":
            return <TabSimpleContainerComponentContent definition={definition} itemDisplayType={itemDisplayType} />; //TODO
        case "directedGraphDetailContainer":
        case "hierarchyGraphDetailContainer":
            return <DiagramComponent definition={definition} />
    }
}

export interface IMultipleInternalContainerComponentProps<TAsItemDisplay extends ItemDisplay> {
    definition: IGridDetailContainer<TAsItemDisplay> | IFlexDetailContainer<TAsItemDisplay>;
    itemDisplayType?: Container<any>["type"];
}
export function MultipleContainerComponentContent<TAsItemDisplay extends ItemDisplay = never>({ definition }: IMultipleInternalContainerComponentProps<TAsItemDisplay>) {
    const { rows } = useSelection(definition);
    switch (definition.type) {
        case "flexDetailContainer": {
            const { items, containerLayout, type } = definition;
            return <ContainerBox type={type} layout={containerLayout}>
                {rows.map((contextValue, idx) => <RelativeValueProvider key={idx} value={contextValue}>
                    <InnerItem<IFlexItemDisplay> key={idx} containerType={type} item={items} />
                </RelativeValueProvider>)}
            </ContainerBox>
        }
        case "gridDetailContainer": {
            const { items, containerLayout, type } = definition;
            return <ContainerBox type={type} layout={containerLayout}>
                {rows.map((contextValue, idx) => <RelativeValueProvider key={idx} value={contextValue}>
                    <InnerItem<IGridItemDisplay> key={idx} containerType={type} item={items} />
                </RelativeValueProvider>)}
            </ContainerBox>
        }
    }
}
export interface ISimpleInternalContainerComponentProps<TAsItemDisplay extends ItemDisplay> {
    definition: IGridContainer<TAsItemDisplay> | IFlexContainer<TAsItemDisplay>;
    itemDisplayType?: Container<any>["type"];
}
export function SimpleContainerComponentContent<TAsItemDisplay extends ItemDisplay = never>({ definition }: ISimpleInternalContainerComponentProps<TAsItemDisplay>) {
    const getValue = useValue();
    const getVisible = useVisible();
    const contextValue = definition.context ? (getValue("any", definition.context) ?? null) : undefined;
    switch (definition.type) {
        case "flexContainer": {
            const { items, containerLayout, type } = definition;
            return <RelativeValueProvider value={contextValue}>
                <ContainerBox type={type} layout={containerLayout}>
                    {items.filter(getVisible).map((item, idx) => <InnerItem<IFlexItemDisplay> key={idx} containerType={type} item={item} />)}
                </ContainerBox>
            </RelativeValueProvider>
        }
        case "gridContainer": {
            const { items, containerLayout, type } = definition;
            return <RelativeValueProvider value={contextValue}>
                <ContainerBox type={type} layout={containerLayout}>
                    {items.filter(getVisible).map((item, idx) => <InnerItem<IGridItemDisplay> key={idx} containerType={type} item={item} />)}
                </ContainerBox>
            </RelativeValueProvider>
        }
    }
}

export interface ITabSimpleInternalContainerComponentProps<TAsItemDisplay extends ItemDisplay> {
    definition: ITabsContainer<TAsItemDisplay>;
    itemDisplayType?: Container<any>["type"];
}
export function TabSimpleContainerComponentContent<TAsItemDisplay extends ItemDisplay = never>({ definition }: ITabSimpleInternalContainerComponentProps<TAsItemDisplay>) {
    const getValue = useValue();
    const getVisible = useVisible();
    const contextValue = definition.context ? (getValue("any", definition.context) ?? null) : undefined;
    const [selectedTab, setSelectedTab] = React.useState(0);
    const handleChange = React.useCallback((event: React.ChangeEvent<{}>, newValue: any) => setSelectedTab(newValue), []);
    const { items, type, containerLayout } = definition;
    return <RelativeValueProvider value={contextValue}>
        <ContainerBox type={type} layout={containerLayout}>
            <AppBar position="static">
                <Tabs value={selectedTab} onChange={handleChange} aria-label="simple tabs example">
                    {items.filter(getVisible).map((item, idx) => <Tab label={getValue("string", item.label)} />)}
                </Tabs>
            </AppBar>
            {items.filter(getVisible).map((item, idx) => <InnerItem<IFlexItemDisplay>
                key={idx}
                containerType={type}
                item={item}
                hidden={idx !== selectedTab} />)}
        </ContainerBox>
    </RelativeValueProvider>
}



export interface IInnerItemProps<TSubItemDisplay extends ItemDisplay> {
    containerType: Container<any>["type"];
    item: ItemControl<TSubItemDisplay>;
    hidden?: boolean;
}
export function InnerItem<TSubItemDisplay extends ItemDisplay>({ containerType, item, hidden }: IInnerItemProps<TSubItemDisplay>) {
    return <ItemBox type={containerType} layout={item.itemLayout} hidden={hidden} >
        <Item item={item} />
    </ItemBox>
}
