import { ICurrencyModel, ICountryModel, IClassificationTypeModel, SecuritySummaryModel, ISubFundSummaryModel, IPositionModel, EntitySummaryModel } from "proxy/apiProxy";
import { Typography, FormControlLabel, Switch, Box } from "@material-ui/core";
import FieldBox from "tools/components/FieldBox";
import PortfolioCompositionGrid from "./PortfolioCompositionGrid";
import { IDictionary } from "tools/lib/utility";
import { VisualizationType } from "features/ManagedPortfolio/slice";
import PortfolioCompositionChart from "./PortfolioCompositionChart";
import { IFlatClassificationTypes } from "features/Classification/getFlatClassificationTypes";
import { useCallback } from "react";
import PricingDatesNavigator from "components/global/PricingDatesNavigator";

export interface IPortfolioCompositionProps {
    dictionaries: {
        securities: Record<number | string, SecuritySummaryModel>;
        subFunds: Record<number | string, ISubFundSummaryModel>;
        entities: Record<number | string, EntitySummaryModel>;
    };
    composition?: IPositionModel[];
    pricingDate?: Date;
    pricingDates?: Date[];
    pricingDatesLoading: boolean;
    onPricingDateChanged: (pricingDate: Date) => void;
    referenceCurrencies: IDictionary<ICurrencyModel>;
    referenceCountries: IDictionary<ICountryModel>;
    classificationTypesFlat: IFlatClassificationTypes;
    classificationTypes: IClassificationTypeModel[];
    onTargetSecuritySelected?: (securityId: number) => void;
    onVisualizationTypeChanged: (visualizationType: VisualizationType) => void;
    visualizationType: VisualizationType;
    editable?: boolean;
    onPositionChanged?: (position: IPositionModel) => void;
    onPositionDelete?: (positionId: number) => void;
}

export default function PortfolioComposition({
    dictionaries: {
        subFunds,
        securities,
        entities
    },
    composition,
    pricingDate,
    pricingDates,
    pricingDatesLoading,
    onPricingDateChanged,
    referenceCurrencies,
    referenceCountries,
    classificationTypesFlat,
    classificationTypes,
    onTargetSecuritySelected,
    onVisualizationTypeChanged,
    visualizationType,
    editable,
    onPositionChanged,
    onPositionDelete
}: IPortfolioCompositionProps) {
    const handleHandleChangeVisualizationType = useCallback(() => onVisualizationTypeChanged(visualizationType === "CHART" ? "GRID" : "CHART"), [onVisualizationTypeChanged, visualizationType]);

    const handlePositionChanged = useCallback((position: IPositionModel) => {
        if (onPositionChanged && pricingDate) {
            position.date = pricingDate;
            onPositionChanged(position);
        }
    }, [onPositionChanged, pricingDate]);

    if (!composition) {
        return <Typography>No composition</Typography>;
    }

    return <Box style={{display:"flex", flexDirection:"column", height:"100%"}}>
        <FieldBox display="flex" flexDirection="row" style={{ padding: 16 }}>
            <PricingDatesNavigator onPricingDateChanged={onPricingDateChanged} pricingDatesLoading={pricingDatesLoading} pricingDate={pricingDate} pricingDates={pricingDates} />
            <FormControlLabel
                control={<Switch checked={visualizationType === "CHART"} onChange={handleHandleChangeVisualizationType} />}
                label="Graph" />
            <div style={{ flexGrow: 1 }} />
        </FieldBox>
        <div style={{ flexGrow: 1, margin: visualizationType === "CHART" ? 18 : undefined }}>
            {visualizationType === "CHART" && <PortfolioCompositionChart
                referenceCurrencies={referenceCurrencies}
                referenceCountries={referenceCountries}
                classificationTypesFlat={classificationTypesFlat}
                classificationTypes={classificationTypes}
                onTargetSecuritySelected={onTargetSecuritySelected}
                positions={composition}
                subFunds={subFunds}
                securities={securities} />}
            {visualizationType === "GRID" && <PortfolioCompositionGrid
                subFunds={subFunds}
                referenceCurrencies={referenceCurrencies}
                referenceCountries={referenceCountries}
                classificationTypesFlat={classificationTypesFlat}
                classificationTypes={classificationTypes}
                onTargetSecuritySelected={onTargetSecuritySelected}
                entities={entities}
                positions={composition}
                securities={securities}
                editable={editable}
                onPositionChanged={handlePositionChanged}
                onPositionDelete={onPositionDelete} />}
        </div>
    </Box>;
}
