import { Epic } from 'redux-observable'
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { mapToPayload } from "lib/rxJsUtility";
import { IFeatureModel, parametersApi, profileAccountApi, tenantsApi } from "proxy/apiProxy";
import { Action, ActionFactories } from "./slice";
import { IAnyAction, IState } from "features";
import { base64toBlob, isGranted } from 'tools/lib/utility';
import { getTenantId, setTenantId } from 'lib/dataAccess';
import { from, merge } from 'rxjs';
import { getUserManager } from "lib/userManager";
import { convertToApplicationMenu } from './menuTools';
import importedSysAdminMenu from "./menu/sysAdminMenu.json";
import importedDefaultMenu from "./menu/defaultMenu.json";
import { IMenuDefinition } from './menu/menuContracts';
const sysAdminMenu = importedSysAdminMenu as IMenuDefinition;
const defaultMenu = importedDefaultMenu as IMenuDefinition;

export const onSigninCallback: Epic<Action> = action$ => action$.pipe(
    mapToPayload("app", "onSigninCallback"),
    mergeMap(async user => {
        const userManager = getUserManager();
        await userManager.storeUser(user);

        if (user.url_state)
            console.debug(`You will be redirected to your screen shortly ${user.url_state}`);

        const accessibleTenants = await tenantsApi.getAccessibleTenantsAsync();
        if (accessibleTenants.type === "NoTenantModel") {
            return null;
        }
        const tenants = accessibleTenants.tenants;
        let tenantId = getTenantId();
        if (!!tenantId) {
            const tenant = tenants.find(i => i.id === tenantId);
            if (!tenant) {
                setTenantId(undefined);
                tenantId = undefined;
            }
        }
        if (!tenantId) {
            if (tenants.length) {
                tenantId = tenants[0].id;
                setTenantId(tenantId);
            } else {
                return [];
            }
        }

        return await Promise.all([
            profileAccountApi.getCurrentAsync(),
            // tenantsApi.getAccessibleTenantsAsync(),
            parametersApi.getAsync(),
            profileAccountApi.getCurrentTenantAsync(),
            profileAccountApi.getCurrentTenantImageAsync()
                .then(({ data, mimeType }) => window.URL.createObjectURL(base64toBlob(data, mimeType)))
                .catch(() => undefined),
            Promise.resolve(tenants),
            Promise.resolve(user)
        ]);
    }),
    mergeMap(payload => {
        if (payload) {
            const [profile, parameters, currentTenant, currentTenantImage, accessibleTenants, user] = payload;
            const applicationLoaded = ActionFactories.applicationLoaded({
                profile,
                parameters,
                currentTenant,
                currentTenantImage,
                accessibleTenants,
                initialUrl: user.url_state
            })
            return from([applicationLoaded])
        } else {
            return from([ActionFactories.applicationLoadedWithNoTenant()]);
        }
    })
);

export const createFirstTenant: Epic<IAnyAction> = action$ => action$.pipe(
    mapToPayload("app", "firstTenantCreate"),
    mergeMap(async tenant => tenantsApi.createTenantAsync({ tenant })),
    map(({ id: tenantId }) => ActionFactories.switchTenant(tenantId)));

export const onApplicationSwitchTenant: Epic<Action> = action$ => merge(
    action$.pipe(
        mapToPayload("app", "switchTenant"),
        map(tenantId => ({ tenantId, navigate: true })))).pipe(
            mergeMap(async ({ tenantId }) => {
                const accessibleTenants = await tenantsApi.getAccessibleTenantsAsync();
                if (accessibleTenants.type === "NoTenantModel") {
                    return null;
                }
                const tenants = accessibleTenants.tenants;
                if (!!tenantId) {
                    const tenant = tenants.find(i => i.id === tenantId);
                    if (!tenant) {
                        setTenantId(undefined);
                        return [];
                    }
                }
                if (!tenantId) {
                    setTenantId(undefined);
                    return [];
                }

                setTenantId(tenantId);
                // await timeout(1000);
                return await Promise.all([
                    profileAccountApi.getCurrentAsync(),
                    // tenantsApi.getAccessibleTenantsAsync(),
                    parametersApi.getAsync(),
                    profileAccountApi.getCurrentTenantAsync(),
                    profileAccountApi.getCurrentTenantImageAsync()
                        .then(({ data, mimeType }) => window.URL.createObjectURL(base64toBlob(data, mimeType)))
                        .catch(() => undefined),
                    Promise.resolve(tenants)
                ]);
            }),
            map(payload => {
                if (payload) {
                    const [profile, parameters, currentTenant, currentTenantImage, accessibleTenants] = payload;
                    return ActionFactories.applicationLoaded({
                        profile,
                        parameters,
                        currentTenant,
                        currentTenantImage,
                        accessibleTenants,
                        initialUrl: undefined
                    });
                }
                else {
                    return ActionFactories.applicationLoadedWithNoTenant();
                }
            }));

export const menuDefinitionLoadAfterAppLoaded: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("app", "applicationLoaded"),
        map(ActionFactories.menuDefinitionLoad),
    );

export const menuDefinitionLoad: Epic<IAnyAction, IAnyAction, IState>
    = (action$, state$) => action$.pipe(
        mapToPayload("app", "menuDefinitionLoad"),
        mergeMap(parametersApi.getTenantMenuAsync),
        withLatestFrom(state$),
        map(([i, { app: { currentUser } }]) => {
            const serverMenu = i;
            let userMenu: IMenuDefinition | undefined = undefined
            if (serverMenu) {
                // First version of menu was just an array
                if (Array.isArray(serverMenu)) {
                    userMenu = {
                        home: undefined,
                        menu: serverMenu,
                        version: 1
                    }
                } else {
                    userMenu = serverMenu as IMenuDefinition // We blindly trust the server
                }
            } else {
                userMenu = makeCopy(defaultMenu);
            }
            if (!userMenu.menu) {
                userMenu.menu = [];
            }
            if (isGranted(currentUser, IFeatureModel.SysAdmin)) {
                userMenu.menu = [...userMenu.menu, ...makeCopy(sysAdminMenu).menu]
            }
            try{
                return ActionFactories.menuDefinitionLoaded(convertToApplicationMenu(userMenu));
            } catch (error) {
                console.error("Menu: Cannot convert Menu.", error);
                return ActionFactories.menuDefinitionLoaded(convertToApplicationMenu(makeCopy(defaultMenu)));
            }
        })
    );
// export const menuShouldBeReloded: Epic<IAnyAction>
//     = action$ => action$.pipe(
//     mapToPayload("app", "newMenuShouldBeLoaded"),
//     map(ActionFactories.menuDefinitionLoad)
// );
function makeCopy<T>(obj: T) {
    return JSON.parse(JSON.stringify(obj)) as T;
}
export const tenantImageLoad: Epic<Action> = action$ => action$.pipe(
    mapToPayload("app", "tenantsImageLoad"),
    withLatestFrom(action$.pipe(mapToPayload("app", "applicationLoaded"))),
    mergeMap(([, { accessibleTenants }]) => from(accessibleTenants ?? []).pipe(
        mergeMap(({ id }) => tenantsApi.getImageAsync({ id })
            .then(({ data, mimeType }) => ({ tenantId: id, imageUrl: window.URL.createObjectURL(base64toBlob(data, mimeType)) }))
            .catch(() => ({ tenantId: id }))
        ))),
    map(ActionFactories.tenantImageLoaded)
)
