import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { IFeatureModel, IUniverseScopeTypeModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import * as React from "react";
import { useEffect } from "react";
import { ISicavData } from "features/Sicav/slice";
import { oProps, useNumber } from "tools/lib/utility";
import { Chip } from "@material-ui/core";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import CustomViewDatas from "features/Relationship/CustomViewDatas";
import MonitoringResultGroups from "components/global/MonitoringResults";
import BriefcaseAccountIcon from "mdi-material-ui/BriefcaseAccount";
import EntityData from "features/Entity/EntityData";
import { ProcessExecutionList } from "features/ProcessExecution/ProcessExecutionList";
import { IActionButton } from "tools/components/FabContainer";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";
import EntityList from "features/Entity/EntityList";
import { useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";
import { isNumber } from "lodash";
import { DocumentsGrid } from "features/Document/DocumentsScreen/DocumentsGrid";

export const sicavScreen = {
    component: Sicav,
    label: "Sicav",
    route: "/sicavs/:id/:tab?" as const,
    tabs: {
        detail: "details",
        customViews: "customScreens",
        entities: "entities",
        dashboard: "dashboard",
        theming: "theming",
        processes: "processes",
        documents: "documents"
    }
}

function useSicavId() {
    const { id } = useScreenParams<typeof sicavScreen>()
    return useNumber(id);
}

function Sicav() {
    const { current, entityPicture } = useReduxSelections("sicav");
    const { sicavSave, sicavLoad, sicavProcessExecutionsLoad } = useReduxActions("sicav");
    const { macroScriptLoadAll } = useReduxActions("macroScript")

    const idNum = useSicavId();
    useEffect(() => {
        if (isNumber(idNum)) {
            sicavLoad(idNum)
            macroScriptLoadAll()
        }
    }, [idNum, macroScriptLoadAll, sicavLoad]);

    useEffect(() => {
        if (isNumber(idNum)) {
            sicavProcessExecutionsLoad(idNum)
        }
    }, [idNum, sicavProcessExecutionsLoad, sicavLoad]);

    const formValues: ISicavData | undefined = React.useMemo(() => {
        if (!current) {
            return undefined;
        }
        const ret: ISicavData = {
            sicav: current,
            imageFile: entityPicture
        };
        return ret;
    }, [current, entityPicture]);

    const handleSubmit = React.useCallback((values: ISicavData, { setSubmitting }: FormikHelpers<ISicavData>) => {
        sicavSave(values);
        setSubmitting(false);
    }, [sicavSave])

    if (!current || !formValues) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={false} />;
    }

    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true} >
        {formikProps => <SicavForm formikProps={formikProps} />}
    </Formik >;
}

function SicavForm({ formikProps: { dirty, isValid, submitForm, values: formValues } }: { formikProps: FormikProps<ISicavData> }) {
    const {
        current,
        loading,
        dictionaries,
        saving,
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        processExecutionsLoading,
        processExecutions,
        documentsLoading,
        documents
    } = useReduxSelections("sicav");
    const { macroScriptList } = useReduxSelections("macroScript");
    const monitoringMacros = React.useMemo(() => macroScriptList.filter(i => (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel") && i.singleScope === IUniverseScopeTypeModel.TargetSicav), [macroScriptList]);
    const { referenceCountries = {} } = useReduxSelections("reference");
    const { classificationTypes } = useReduxSelections("entityClassificationType");
    const {
        sicavDelete,
        sicavAddEntityInDictionary,
        sicavCustomScreenDataSubmit,
        sicavMonitoringResultLoad,
        sicavDocumentsLoad
    } = useReduxActions("sicav");
    const { parameters: { sicavExtensionFields = [] } } = useReduxSelections("parameters");

    const navigate = useScreenNavigate()
    const { id, tab: tabValue = "detail" } = useScreenParams<typeof sicavScreen>();
    const idNum = useNumber(id);

    const isGranted = useGrants();

    useEffect(() => {
        if (idNum && tabValue === "documents" && !documentsLoading && !documents)
            sicavDocumentsLoad(idNum)
    }, [idNum, sicavDocumentsLoad, documents, documentsLoading, tabValue]);

    const handleNewExecutionProcess = React.useCallback(() => {
        navigate("ProcessExecutionNew", { type: "EntityProcessExecutionModel", targetId: current?.id ?? 0 });
    }, [current?.id, navigate])

    const handleLoadResult = React.useCallback((id: number) => current && sicavMonitoringResultLoad({ id, targetId: current.id }), [current, sicavMonitoringResultLoad]);
    const title = !!current?.id ? current.name : 'New sicav';
    const handleDelete = React.useCallback(() => {
        if (current?.id) {
            sicavDelete(current.id);
        }
    }, [current?.id, sicavDelete])

    const handleEntitySelected = React.useCallback((entityId: number) => {
        const entity = dictionaries.entities[entityId];
        if (!entity) {
            return;
        }
        switch (entity.type) {
            case "PersonSummaryModel":
                navigate("Person", { id: entityId });
                break;
            case "EntityGroupSummaryModel":
                navigate("EntityGroup", { id: entityId });
                break;
            case "CompanySummaryModel":
                navigate("Company", { id: entityId });
                break;
            case "SicavSummaryModel":
                navigate("Sicav", { id: entityId });
                break;
        }
    }, [dictionaries.entities, navigate]);
    const handleDataSubmit = (customScreenId: number, values: any) => current && sicavCustomScreenDataSubmit({ id: current.id, customScreenId, values });
    const canEditCustomData = isGranted(IFeatureModel.UniverseCustomDataWrite);
    if (!current || !formValues) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={false} />;
    }

    const tabs: ISubMenuTabs<typeof sicavScreen>[] = [{
        label: "Data",
        value: "detail"
    }, {
        label: "Entities",
        value: "entities"
    }];
    if (!!monitoringMacros.length) {
        tabs.push({
            label: "Dashboard",
            value: "dashboard"
        });
    }

    if (formValues.sicav?.id && !!customScreens?.length && !formValues.sicav.isUnderManagement) {
        tabs.push({
            label: "Custom Data",
            value: "customViews"
        });
    }
    if (formValues?.sicav?.id) {
        tabs.push({
            label: "Processes",
            value: "processes"
        });
        tabs.push({
            label: "Documents",
            value: "documents"
        });
    }
    const extraActionButtons: IActionButton[] = [{
        label: "Add Process Execution",
        icon: NotebookCheckOutlineIcon,
        onClick: handleNewExecutionProcess,
        feature: IFeatureModel.UniverseDueDiligenceWrite//,
    }];
    return <DetailPanel
        endAdornment={formValues.sicav.isUnderManagement ? <Chip icon={<BriefcaseAccountIcon />} size="small" label="Under management" /> : undefined}
        isQuerying={loading || saving || customScreenDataLoading || customScreenDataSubmitting || processExecutionsLoading}
        title={title}
        subTitle="Sicav"
        canSave={dirty && isValid}
        onSaveClick={formValues.sicav.isUnderManagement ? undefined : submitForm}
        canDelete={!!current?.id}
        onDeleteClick={formValues.sicav.isUnderManagement ? undefined : handleDelete}
        badge={!current?.id ? "new" : undefined}
        tabs={tabs}
        tabValue={tabValue}
        actions={extraActionButtons}
        saveAllowed={IFeatureModel.UniverseWrite}
        deleteAllowed={IFeatureModel.UniverseDelete}>
        {(tabValue === "detail") && <WriteAccess value={IFeatureModel.UniverseWrite}> <EntityData
            sicavExtensionFields={sicavExtensionFields}
            companyExtensionFields={[]}
            personExtensionFields={[]}
            formImageFieldName={oProps<ISicavData>().path("imageFile")}
            formFieldName={oProps<ISicavData>().path("sicav")}
            classificationTypes={classificationTypes}
            dictionaries={dictionaries}
            onEntityLoaded={sicavAddEntityInDictionary}
            referenceCountries={referenceCountries} /></WriteAccess>}
        {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
            monitoringMacros={monitoringMacros}
            onLoadResult={handleLoadResult}
            resultGroups={monitoringResults} />}
        {(tabValue === "entities") && <EntityList
            dictionaries={dictionaries}
            formFieldName={oProps<ISicavData>().path("sicav")}
            onEntityLoaded={sicavAddEntityInDictionary}
            onEntitySelected={handleEntitySelected} />}
        {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length && !formValues.sicav.isUnderManagement) && <CustomViewDatas
            canEdit={canEditCustomData}
            dictionaries={dictionaries}
            customScreenLoading={customScreenDataLoading}
            dataSubmitting={customScreenDataSubmitting}
            customScreens={customScreens}
            onDataSubmit={handleDataSubmit}
            customScreenDatas={customScreenDatas} />}
        {(tabValue === "processes") && <ProcessExecutionList
            processExecutions={processExecutions}
            dictionaries={dictionaries} />}
        {(tabValue === "documents") && <DocumentsGrid
            documents={documents ?? []}
            dictionaries={dictionaries} />}
    </DetailPanel>
}
