import { KeyboardTimePicker } from '@material-ui/pickers';
import { DateType } from "@date-io/type";
import { isValidDate } from 'tools/lib/utility';
import { useFieldEx, IFormField, ValidatorParams } from 'tools/lib/fieldHelpers';
import { ReadOnlyContext } from "./ReadOnlyContext";

const validators = {
    required: (v: DateType | undefined, isTrue?: boolean) => isTrue && !v ? `Required` : undefined,
    // minValue: (v: DateType | undefined, min?: DateType) => typeof (min) !== "undefined" && v && isValidDate(v) && v < min ? `Must be after ${min}` : undefined,
    // maxValue: (v: DateType | undefined, max?: DateType) => typeof (max) !== "undefined" && v && isValidDate(v) && v > max ? `Must be before ${max}` : undefined,
    // disabledDates: (v: DateType | undefined, shouldDisableDate: (date: DateType) => boolean) => undefined
}

export interface IFormTimePickerFieldProps extends IFormField, ValidatorParams<typeof validators> {
    fullWidth?: boolean;
}

export default function FormTimePickerField({ name, helperText, label, disabled, error, fullWidth = true, ...requestedValidations }: IFormTimePickerFieldProps) {
    const { error: fieldError, helperText: fieldHelperText, label: fieldLabel, onValueChanged, value } = useFieldEx<DateType, typeof validators>({ name, helperText, label, required: requestedValidations.required, error, validators, requestedValidations });
    const onChange = (date: DateType | null, _?: string | null) => {
        if (date) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
        }
        if (!date || isValidDate(date)) {
            onValueChanged(date ?? undefined);
        }
    };
    return <ReadOnlyContext.Consumer>
        {readOnly => <KeyboardTimePicker
            readOnly={readOnly}
            id={name}
            fullWidth={fullWidth}
            autoOk={true}
            ampm={false}
            placeholder="HH:mm"
            error={fieldError}
            mask="__:__"
            helperText={fieldHelperText}
            clearable={!requestedValidations.required}
            showTodayButton={true}
            margin="dense"
            // minDate={requestedValidations.minValue}
            // maxDate={requestedValidations.maxValue}
            disabled={disabled || readOnly}
            label={fieldLabel}
            value={(value ?? "") === "" ? null : value ?? null}
            onChange={onChange} />}
    </ReadOnlyContext.Consumer>
}
