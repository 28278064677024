import { IReportTemplateModel, IReportTemplateCategoryModel } from "proxy/apiProxy";
import FieldBox from "tools/components/FieldBox";
import { oProps } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";

export interface IReportTemplateDataProps {
    referenceReportTemplateCategories: Record<number, IReportTemplateCategoryModel>
    fieldName: string
}
export default function ReportTemplateData({ referenceReportTemplateCategories, fieldName }: IReportTemplateDataProps) {
    return (
        <FieldBox display="flex" flexDirection="column">
            <FormTextField name={oProps<IReportTemplateModel>(fieldName).path("code")} label="Code" required={true} />
            <FormTextField name={oProps<IReportTemplateModel>(fieldName).path("name")} label="Name" required={true} />
            <FormSimpleSelectField name={oProps<IReportTemplateModel>(fieldName).path("categoryId")} label="Category" options={referenceReportTemplateCategories} />
            <FormTextField name={oProps<IReportTemplateModel>(fieldName).path("description")} label="Description" fullWidth={true} multiline={true} />
            <FormCheckBoxField name={oProps<IReportTemplateModel>(fieldName).path("publishOnPortal")} label="Publish on portal" />
        </FieldBox>
    );
}
