import { PortfolioSummaryModel, PortfolioModel, IPortfolioTypeModel } from "proxy/apiProxy";
import { getEnumLabels } from "tools/lib/utility";

const portfolioTypes = getEnumLabels(IPortfolioTypeModel);
export function getPortfolioTypeLabel(type: (PortfolioModel | PortfolioSummaryModel)["type"] | IPortfolioTypeModel): string;
export function getPortfolioTypeLabel(type: (PortfolioModel | PortfolioSummaryModel)["type"] | IPortfolioTypeModel | null): string | null;
export function getPortfolioTypeLabel(type: (PortfolioModel | PortfolioSummaryModel)["type"] | IPortfolioTypeModel | undefined): string | undefined;
export function getPortfolioTypeLabel(type: (PortfolioModel | PortfolioSummaryModel)["type"] | IPortfolioTypeModel | undefined | null): string | null | undefined;
export function getPortfolioTypeLabel(type: (PortfolioModel | PortfolioSummaryModel)["type"] | IPortfolioTypeModel | undefined | null) {
    if (typeof (type) === "undefined") {
        return undefined;
    }
    if (!type) {
        return null;
    }
    switch (type) {
        case "DiscretionaryPortfolioModel":
        case "DiscretionaryPortfolioSummaryModel":
        case IPortfolioTypeModel.Portfolio: return portfolioTypes[IPortfolioTypeModel.Portfolio];
        case "ManagedSubFundModel":
        case "SubFundSummaryModel":
        case IPortfolioTypeModel.SubFund: return portfolioTypes[IPortfolioTypeModel.SubFund];
    }
}