import { EntitySummaryModel, ICashMovementSummaryModel, PortfolioSummaryModel, PortfolioTransactionSummaryModel, RelationshipSummaryModel, SecuritySummaryModel } from "proxy/apiProxy";
import { getEntityName } from "tools/lib/modelUtils";
import { DashboardLink } from "tools/objectViewer/DashboardComponents";
import { ScreenLinkType } from "tools/objectViewer/DashboardFormattingContracts";

export interface GetAllSummaries {
    entities: Record<string | number, EntitySummaryModel>;
    relationships: Record<string | number, RelationshipSummaryModel>;
    portfolios: Record<string | number, PortfolioSummaryModel>;
    securities: Record<string | number, SecuritySummaryModel>;
    transactions: Record<string | number, PortfolioTransactionSummaryModel>;
    cashMovements: Record<string | number, ICashMovementSummaryModel>;
}

export function entityLinks(ids: number[], entities: Record<string | number, EntitySummaryModel>) {
    const mappedEntities = ids.map(id => entities[id]);
    const linkedEntities = mappedEntities.map(i => {
        const name = getEntityName(i) ?? "(?)";
        if (i === undefined) return name;
        switch (i.type) {
            case "PersonSummaryModel":
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.Person} />;
            case "CompanySummaryModel":
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.Company} />;
            case "EntityGroupSummaryModel":
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.EntityGroup} />;
            case "SicavSummaryModel":
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.Sicav} />;
            default:
                return name;
        }
    });
    return linksWrapper(linkedEntities);
}

export function relationshipLinks(ids: number[],
    relationships: Record<string | number, RelationshipSummaryModel>,
    entities: Record<string | number, EntitySummaryModel>) {
    const mappedRelationships = ids.map(id => relationships[id]);
    const linkedRelationships = mappedRelationships.map(i => {
        if (i === undefined) return "(?)";
        var name = `${getEntityName(entities[i.entityId])} as ${i.title}`;
        switch (i.type) {
            case "RoleRelationshipSummaryModel":
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.Role} />;
            case "InvestorRelationshipSummaryModel":
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.Investor} />;
            case "CounterpartyRelationshipSummaryModel":
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.Counterparty} />;
            default:
                return name ?? "(?)";
        }
    });
    return linksWrapper(linkedRelationships);
}

export function portfolioLinks(ids: number[], portfolios: Record<string | number, PortfolioSummaryModel>) {
    const mappedPortfolios = ids.map(id => portfolios[id]);
    const linkedPortfolios = mappedPortfolios.map(i => {
        const name = i?.name ?? "(?)";
        if (i === undefined) return name;
        switch (i.type) {
            case "DiscretionaryPortfolioSummaryModel":
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.Portfolio} />;
            case "SubFundSummaryModel":
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.SubFund} />;
            default:
                return name;
        }
    });
    return linksWrapper(linkedPortfolios);
}

export function securityLinks(ids: number[], securities: Record<string | number, SecuritySummaryModel>) {
    const mappedSecurities = ids.map(id => securities[id]);
    const linkedSecurities = mappedSecurities.map(i => {
        const name = i?.name ?? "(?)";
        if (i === undefined) return name;
        switch (i.type) {
            case "CashSummaryModel":
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.Cash} />;
            case "LoanSummaryModel":
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.Loan} />;
            case "ShareClassSummaryModel":
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.ShareClass} />;
            default:
                return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.Security} />;
        }
    });
    return linksWrapper(linkedSecurities);
}

export function cashMovementLinks(ids: number[], cashMovements: Record<string | number, ICashMovementSummaryModel>) {
    const mappedCashMovements = ids.map(id => cashMovements[id]);
    const linkedCashMovements = mappedCashMovements.map(i => {
        const name = i?.movementCode ?? "(?)";
        if (i === undefined) return name;
        return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.Movement} />;
    });
    return linksWrapper(linkedCashMovements);
}

export function transactionLinks(ids: number[], transactions: Record<string | number, PortfolioTransactionSummaryModel>) {
    const mappedTransactions = ids.map(id => transactions[id]);
    const linkedTransactions = mappedTransactions.map(i => {
        const name = i?.transactionCode ?? "(?)";
        if (i === undefined) return name;
        return <DashboardLink id={i.id} textValue={name} referenceType={ScreenLinkType.PortfolioTransaction} />;
    });
    return linksWrapper(linkedTransactions);
}

function linksWrapper(links: (string | JSX.Element)[]) {
    return <div style={{ display: "inline-flex", columnGap: "15px" }}>
        {links}
    </div>;
}

export function toTitleCase(option: string) {
    return option.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
}
