import { IParagraphDocumentElementModel } from "proxy/apiProxy";
import DialogPanel from "tools/components/DialogPanel";
import { Formik, FormikHelpers, FormikProps } from "formik";
import FieldBox from "tools/components/FieldBox";
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";
import { oProps } from "tools/lib/utility";

export interface IParagraphProps {
    paragraph?: IParagraphDocumentElementModel;
    onParagraphChanged: (paragraph: IParagraphDocumentElementModel) => void;
    onCancel: () => void;
}

interface IFormValues {
    title: Record<string | number, string>;
}
const emptyParagraph: IFormValues = { title: {} };
export default function ParagraphDialog({ onCancel, paragraph, onParagraphChanged }: IParagraphProps) {
    const handleSubmit = (values: IFormValues, { setSubmitting }: FormikHelpers<IFormValues>) => {
        if (!paragraph) {
            return;
        }
        onParagraphChanged({ ...paragraph, ...values });
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={paragraph ?? emptyParagraph} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<IFormValues>) {
        return <DialogPanel
            onBackClick={onCancel}
            title="Paragraph"
            isOpened={!!paragraph}
            actions={[{
                disabled: !isValid || !dirty,
                label: "Ok",
                onClick: submitForm
            }]}>
            <FieldBox display="flex" flexDirection="column">
                <FormMultiCulturedTextField name={oProps<IFormValues>().path("title")} label="Title" required={["en"]} />
            </FieldBox>
        </DialogPanel>;
    }
}
