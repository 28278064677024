import { screenDictionary } from "../../features/AllScreens";
import { useMatches, useNavigate, useParams } from "react-router-dom";
import { IScreenDefinition, ITabbedScreenDefinition, RouteParams } from "./screenTypes";
import { generatePath, PathParam } from "react-router";
import { useMemo } from "react";
import { splitPascalCase, toDictionary } from "tools/lib/utility";


export const typedScreenDictionary: Record<string, IScreenDefinition> = screenDictionary
export type IScreenParams<TScreen extends IScreenDefinition> = Omit<RouteParams<TScreen["route"]>, "tab"> & {
    tab?: ScreenAnyTab<TScreen>
}
export function useScreenParams<TScreen extends IScreenDefinition>(): Readonly<Partial<IScreenParams<TScreen>>> {
    const params = useParams<RouteParams<TScreen["route"]>>()
    return useMemo(() => {
        if (isTabContainer(params)) {
            return params;
        }
        return { ...params, tab: undefined as ScreenAnyTab<TScreen> | unknown };
        function isTabContainer(o: object): o is Partial<IScreenParams<TScreen>> {
            return typeof (o as { tab: ScreenAnyTab<TScreen> }).tab === "string";
        }
    }, [params]);
}



export function isTabbedScreen(screen: IScreenDefinition): screen is ITabbedScreenDefinition {
    return screen && typeof screen === "object" && "tabs" in screen && typeof screen.tabs === "object"
}

export type ScreenKey = keyof (typeof screenDictionary);
export const ScreenKeys = toDictionary(Object.keys(screenDictionary).sort((a, b) => a > b ? 1 : a < b ? -1 : 0), i => i as ScreenKey, i => splitPascalCase(i));
export const ScreenTabs = toDictionary(Object.keys(screenDictionary), i => i as ScreenKey, i => {
    const screen = screenDictionary[i as ScreenKey] as { tabs?: Record<string, string> };
    const screenTabs = screen?.tabs ?? {};
    const tabs = Object.keys(screenTabs).map(i => ({ key: i, label: screenTabs[i] })).sort(({ label: a }, { label: b }) => a > b ? 1 : a < b ? -1 : 0);
    return toDictionary(tabs, i => i.key, i => splitPascalCase(i.label));
});
export type ScreenTab<SK extends ScreenKey> = (typeof screenDictionary)[SK] extends {
    tabs: Record<string, string>
}
    ? keyof ((typeof screenDictionary)[SK]["tabs"])
    : never;

export type ScreenObjTab<Screen> = Screen extends { tabs: Record<string, string> }
    ? keyof Screen["tabs"]
    : string;

// type ScreenTabObj<SK extends ScreenKey> = (typeof screenDictionary)[SK] extends {
//     tabs: Record<string, string>
// }
//     ? ((typeof screenDictionary)[SK]["tabs"])
//     : never;


export type ScreenInputParams<SK extends ScreenKey> = Partial<Record<PathParam<typeof screenDictionary[SK]["route"]>, string | number>>
// type ScreenConditionalInputParams<SK extends ScreenKey> = Record<PathParam<typeof screenDictionary[SK]["route"]>, string | number | undefined>

export function buildScreenUrl<SK extends ScreenKey>(screenKey: SK, params?: ScreenInputParams<SK>) {
    // const screen = 
    return generatePath(typedScreenDictionary[screenKey].route, params);
}
/**@deprecated */
export function useScreenNavigate() {
    const navigate = useNavigate()
    /**@deprecated */
    return function <SK extends ScreenKey>(screenKey: SK, params?: ScreenInputParams<SK>) {
        const location = buildScreenUrl(screenKey, params)
        navigate(location)
    }
}

/**
 * @deprecated Backward compatibility hook for navigation, for modern features prefer `useScreenNavigate` or <ScreenLink>
 */
export function useNavigationNavigate() {
    const navigate = useNavigate()
    const screenKey = useScreenKey()
    const currentParams = useParams()
    return function <SK extends ScreenKey>(target?: {
        screenKey?: SK
        sectionKey?: ScreenTab<SK> | string
        parameters?: ScreenInputParams<SK>
    }) {
        if (target) {
            let { screenKey: targetScreenKey, sectionKey, parameters } = target
            let destinationScreenKey: string = targetScreenKey ?? screenKey
            if (destinationScreenKey
                && destinationScreenKey in typedScreenDictionary
                && !isTabbedScreen(typedScreenDictionary[destinationScreenKey as ScreenKey])
                && sectionKey) {
                // The screen is not tabbed, and we have a section (now tabs)
                // We can try to find the Singular version of the screen
                const singularScreenKey = destinationScreenKey
                    .replace(/ies$/, 'y')
                    .replace(/s$/, "")
                console.warn("navigationNavigate section does not exists", destinationScreenKey, sectionKey, singularScreenKey)

                if (typedScreenDictionary.hasOwnProperty(singularScreenKey)) {
                    const singularScreen = typedScreenDictionary[singularScreenKey as ScreenKey]
                    destinationScreenKey = singularScreenKey
                    if (!isTabbedScreen(singularScreen))
                        sectionKey = undefined
                }
            }
            const updatedParameters = {
                ...currentParams,
                ...parameters
            }
            // @ts-ignore
            const location = buildScreenUrl(destinationScreenKey, sectionKey, updatedParameters)
            console.warn('Deprecated use of navigationNavigate', destinationScreenKey, sectionKey, parameters, location)
            navigate(location)
        } else {
            // Backward compatibility wants to bo to parent in the default menu hierarchy
            // As a fallback just bo back in history
            navigate(-1)
            console.error('Deprecated use of navigationNavigate(undefined) originated from', screenKey)
        }
    }
}

export function buildRouteId(screenKey: string, tabKey?: string) {
    return tabKey ? `${screenKey}/${tabKey}` : screenKey
}

export function parseRouteId(routeId: string) {
    const splitRoute = routeId.split("/");
    const screenKey = splitRoute[0];
    const tabKey = splitRoute.length > 1 ? splitRoute[1] : undefined;
    return { screenKey, tabKey };
}

export function useScreenKey(): ScreenKey {
    const route = useMatches()
    const { screenKey } = parseRouteId(route[route.length - 1].id)
    return screenKey as ScreenKey
}

export function useTab<SK extends ScreenKey>(): ScreenTab<SK> | undefined {
    const route = useMatches()
    const { tabKey } = parseRouteId(route[route.length - 1].id)
    if (tabKey)
        return tabKey as ScreenTab<SK>
    return undefined
}

export type ScreenAnyTab<Screen> = Screen extends { tabs: infer U } ? keyof U : [];

