import * as React from "react";
import Editor, { OnChange, OnMount } from "@monaco-editor/react";
import { ICompletionDataModel, IMacroLanguageModel, IMacroModel, ITextModelWithPosition } from "proxy/apiProxy";
import { INotControllableEditorProps } from "./INotControllableEditorProps";
import { Box, SvgIconTypeMap, createStyles, makeStyles, withStyles } from "@material-ui/core";
import { FabDropdown } from "./FabDropdown";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import RateReviewIcon from '@material-ui/icons/RateReview';

const FloatingBoxRight = withStyles((theme) =>
    createStyles({
        root: {
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 999
        }
    })
)(Box);

// https://microsoft.github.io/monaco-editor/playground.html#interacting-with-the-editor-adding-an-action-to-an-editor-instance

export interface IMacroCodeEditorError {
    start: number;
    length: number;
    startLineNumber: number;
    startColumnNumber: number;
    endLineNumber: number;
    endColumnNumber: number;
    sourcePart: string;
    message: string;
}
export interface IExceptionCodeEditor {
    startLineNumber: number;
    // startColumnNumber?: number;
    // endLineNumber?: number;
    // endColumnNumber?: number;
    message: string;
}
export interface ICodeEditorProps extends INotControllableEditorProps<IMacroModel> {
    codeErrors?: IMacroCodeEditorError[];
    exception?: IExceptionCodeEditor;
    readOnly?: boolean;
    jsonSchema?: object;
    onCodeChanged: (v: IMacroModel) => void;
    onRequestAutoComplete?: (position: ITextModelWithPosition) => Promise<ICompletionDataModel[]>;
    onSave?: () => void;
};
const useStyles = makeStyles(theme => ({
    exception: {
        backgroundColor: "rgba(255, 0, 0, 0.3)",
        borderTop: "solid 1px red",
        borderBottom: "solid 1px red"
    }
}))
type Monaco = typeof monaco;
export default function MacroCodeEditor(props: ICodeEditorProps) {
    const classes = useStyles();
    const {
        codeErrors,
        readOnly = false,
        jsonSchema,
        onCodeChanged,
        refToGetValue,
        exception,
        onSave
        // onRequestAutoComplete
    } = props;
    const decoratorsRef = React.useRef<monaco.editor.IEditorDecorationsCollection>();
    const monacoRef = React.useRef<Monaco>();
    const modelRef = React.useRef<monaco.editor.ITextModel | null | undefined>();
    const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null | undefined>();
    const languageRef = React.useRef<IMacroLanguageModel>(refToGetValue.current.initialValue?.language ?? IMacroLanguageModel.CSharp);
    const [language, setLanguage] = React.useState<IMacroLanguageModel>(languageRef.current);
    const handleChange: OnChange = React.useCallback((newValue, ev) => {
        onCodeChanged({
            content: newValue || "",
            language
        });
        setException(undefined, decoratorsRef.current, classes.exception);
    }, [onCodeChanged, language, classes.exception]);
    React.useEffect(() => setErrors(codeErrors, modelRef.current, monacoRef.current), [codeErrors]);
    React.useEffect(() => setJsonSchema(jsonSchema, modelRef.current, monacoRef.current), [jsonSchema]);

    const setter = React.useCallback((v?: IMacroModel) => {
        editorRef.current?.setValue(v?.content ?? "");
        setException(exception, decoratorsRef.current, classes.exception);
    }, [classes.exception, exception]);
    React.useEffect(() => {
        setException(exception, decoratorsRef.current, classes.exception);
    }, [classes.exception, exception]);
    const getter = React.useCallback(() => ({ content: editorRef.current?.getValue(), language: languageRef.current } as IMacroModel), []);

    const handleEditorMounted: OnMount = React.useCallback((editor: monaco.editor.IStandaloneCodeEditor, mnco: typeof monaco) => {
        monacoRef.current = mnco;
        editorRef.current = editor;
        decoratorsRef.current = editor.createDecorationsCollection([]);
        if (onSave) {
            editor.addAction({
                id: "saveMacro",
                label: "Save Macro",
                keybindings: [
                    monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS,
                ],
                contextMenuGroupId: "1_modification",
                contextMenuOrder: 1.5,
                run: function (ed) {
                    onSave();
                },
            });
        }
        refToGetValue.current.setEditor(setter, getter);
        if (!monacoRef.current) return;
        modelRef.current = editor.getModel();
        if (modelRef.current) {
            monacoRef.current.editor.setModelLanguage(modelRef.current, getLanguageCode(languageRef.current));
        }
        if (codeErrors) {
            setErrors(codeErrors, modelRef.current, monacoRef.current);
        }
        if (jsonSchema) {
            setJsonSchema(jsonSchema, modelRef.current, monacoRef.current);
        }
    }, [onSave, refToGetValue, setter, getter, codeErrors, jsonSchema]);
    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => refToGetValue.current.unsetEditor();
    }, [refToGetValue]);
    const handleLanguageChanged = React.useCallback((newLanguage: IMacroLanguageModel) => {
        setLanguage(newLanguage);
        languageRef.current = newLanguage;
        if (modelRef.current && monacoRef.current) {
            monacoRef.current.editor.setModelLanguage(modelRef.current, getLanguageCode(newLanguage));
        }
    }, []);
    return <Box style={{ flexGrow: 1 }} >
        <FloatingBoxRight><FormFabDropDown onValueChanged={handleLanguageChanged} value={language} options={IMacroLanguageModel} icon={RateReviewIcon} /></FloatingBoxRight>
        <Editor
            options={{ readOnly, glyphMargin: true }}
            height="100%" // TODO: not great... possible to find much better
            onChange={handleChange}
            onMount={handleEditorMounted} />
    </Box>
}
function getLanguageCode(language: IMacroLanguageModel) {
    switch (language) {
        case IMacroLanguageModel.CSharp: return "csharp";
        case IMacroLanguageModel.Python: return "python";
        case IMacroLanguageModel.Javascript: return "javascript";
    }
}
function setJsonSchema(batchSchema: any, model: monaco.editor.ITextModel | null | undefined, mnco: Monaco | null | undefined) {
    if (!model || !batchSchema) {
        return;
    }
    if (!mnco) return;
    mnco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        allowComments: true,
        schemas: [{
            uri: "http://myserver/foo-schema.json", // id of the first schema
            fileMatch: [model.uri.toString()], // associate with our model
            schema: batchSchema
        }]
    });
}
function setErrors(scriptErrors: IMacroCodeEditorError[] | undefined = [], model: monaco.editor.ITextModel | null | undefined, mnco: Monaco | null | undefined) {
    if (!model) {
        return;
    }
    if (!mnco) return;
    mnco.editor.setModelMarkers(model, "MacroScriptEditor", scriptErrors.map(({ endColumnNumber, endLineNumber, startColumnNumber, startLineNumber, message }) => ({
        startLineNumber,
        startColumn: startColumnNumber,
        endLineNumber,
        endColumn: endColumnNumber,
        message,
        severity: mnco!.MarkerSeverity.Error,
    })));
}
///@ts-ignore
function setException(scriptException: IExceptionCodeEditor | undefined, decorationsCollection: monaco.editor.IEditorDecorationsCollection | undefined | null, className: string) {
    if (!decorationsCollection) return;
    if (!scriptException) {
        decorationsCollection.clear();
    }
    else {
        decorationsCollection.set([{
            range: new monaco.Range(scriptException.startLineNumber, 0, scriptException.startLineNumber, 0),
            options: {
                hoverMessage: { value: `**Exception**<br /><br/>${scriptException.message}`, supportHtml: true },
                isWholeLine: true,
                className
            }
        }]);
    }
}
interface IFormFabDropDownProps<T extends keyof any> {
    onValueChanged: (value: T) => void;
    value: T;
    options: Record<T, string>;
    icon: OverridableComponent<SvgIconTypeMap>;
}
function FormFabDropDown<T extends keyof any>({ options, icon, onValueChanged, value }: IFormFabDropDownProps<T>) {
    return <FabDropdown options={options} label={options[value]} onSelect={onValueChanged} icon={icon} />
}