import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IClassificationTypeModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ClassificationTypeDomain, getClassificationTypeDomainLabel } from "./ClassificationTypeDomain";
import { getRelatedGrants } from "./getRelatedGrants";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";

export const investorClassificationTypesScreen = {
    domain: ClassificationTypeDomain.Investor,
    useSelections: () => useReduxSelections("investorClassificationType"),
    useActions: () => {
        const { investorClassificationTypeLoadAll: classificationTypesLoadAll } = useReduxActions("investorClassificationType");
        return { classificationTypesLoadAll }
    },
    childDetailScreenKey: "InvestorClassificationType" as const,
    route: "/config/lists/investorclassificationtypes" as const,
    component: InvestorClassificationTypes,
    label: "Investor Classification"
}

function InvestorClassificationTypes() {
    return <ClassificationTypes screen={investorClassificationTypesScreen} />
}

export const roleRelationshipClassificationTypesScreen = {
    domain: ClassificationTypeDomain.RoleRelationship,
    useSelections: () => useReduxSelections("roleRelationshipClassificationType"),
    useActions: () => {
        const { roleRelationshipClassificationTypeLoadAll: classificationTypesLoadAll } = useReduxActions("roleRelationshipClassificationType");
        return { classificationTypesLoadAll }
    },
    childDetailScreenKey: "RoleRelationshipClassificationType" as const,
    route: "/config/lists/rolerelationshipclassificationtypes" as const,
    component: RoleRelationshipClassificationTypes,
    label: "Role Relationship Classification"
}

function RoleRelationshipClassificationTypes() {
    return <ClassificationTypes screen={roleRelationshipClassificationTypesScreen} />
}

export const securityClassificationTypesScreen = {
    domain: ClassificationTypeDomain.Security,
    useSelections: () => useReduxSelections("securityClassificationType"),
    useActions: () => {
        const { securityClassificationTypeLoadAll: classificationTypesLoadAll } = useReduxActions("securityClassificationType");
        return { classificationTypesLoadAll }
    },
    childDetailScreenKey: "SecurityClassificationType" as const,
    route: "/config/lists/securityclassificationtypes" as const,
    component: SecurityClassificationTypes,
    label: "Security Classification"
}

function SecurityClassificationTypes() {
    return <ClassificationTypes screen={securityClassificationTypesScreen} />
}

export const portfolioTransactionClassificationTypesScreen = {
    domain: ClassificationTypeDomain.PortfolioTransaction,
    useSelections: () => useReduxSelections("portfolioTransactionClassificationType"),
    useActions: () => {
        const { portfolioTransactionClassificationTypeLoadAll: classificationTypesLoadAll } = useReduxActions("portfolioTransactionClassificationType");
        return { classificationTypesLoadAll }
    },
    childDetailScreenKey: "PortfolioTransactionClassificationType" as const,
    route: "/portfolioTransaction/classificationtypes" as const,
    component: PortfolioTransactionClassificationTypes,
    label: "Portfolio Classification"
}

function PortfolioTransactionClassificationTypes() {
    return <ClassificationTypes screen={portfolioTransactionClassificationTypesScreen} />
}

export const movementClassificationTypesScreen = {
    domain: ClassificationTypeDomain.Movement,
    useSelections: () => useReduxSelections("movementClassificationType"),
    useActions: () => {
        const { movementClassificationTypeLoadAll: classificationTypesLoadAll } = useReduxActions("movementClassificationType");
        return { classificationTypesLoadAll }
    },
    childDetailScreenKey: "MovementClassificationType" as const,
    route: "/config/lists/movementclassificationtypes" as const,
    component: MovementClassificationTypes,
    label: "Movement Classification"
}

function MovementClassificationTypes() {
    return <ClassificationTypes screen={movementClassificationTypesScreen} />
}

export const entityClassificationTypesScreen = {
    domain: ClassificationTypeDomain.Entity,
    useSelections: () => useReduxSelections("entityClassificationType"),
    useActions: () => {
        const { entityClassificationTypeLoadAll: classificationTypesLoadAll } = useReduxActions("entityClassificationType");
        return { classificationTypesLoadAll }
    },
    childDetailScreenKey: "EntityClassificationType" as const,
    route: "/config/lists/entityclassificationtypes" as const,
    component: EntityClassificationTypes,
    label: "Entity Classification"
}

function EntityClassificationTypes() {
    return <ClassificationTypes screen={entityClassificationTypesScreen} />
}

export const noteClassificationTypesScreen = {
    domain: ClassificationTypeDomain.Note,
    useSelections: () => useReduxSelections("noteClassificationType"),
    useActions: () => {
        const { noteClassificationTypeLoadAll: classificationTypesLoadAll } = useReduxActions("noteClassificationType");
        return { classificationTypesLoadAll }
    },
    childDetailScreenKey: "NoteClassificationType" as const,
    route: "/config/lists/noteclassificationtypes" as const,
    component: NoteClassificationTypes,
    label: "Note Classification"
}

function NoteClassificationTypes() {
    return <ClassificationTypes screen={noteClassificationTypesScreen} />
}

export const processClassificationTypesScreen = {
    domain: ClassificationTypeDomain.Process,
    useSelections: () => useReduxSelections("processClassificationType"),
    useActions: () => {
        const { processClassificationTypeLoadAll: classificationTypesLoadAll } = useReduxActions("processClassificationType");
        return { classificationTypesLoadAll }
    },
    childDetailScreenKey: "ProcessDefinitionClassificationType" as const,
    route: "/config/processes/classificationtypes" as const,
    component: ProcessClassificationTypes,
    label: "Process Classification",
    keywords: "process definition classification",
}

function ProcessClassificationTypes() {
    return <ClassificationTypes screen={processClassificationTypesScreen} />
}

export const developmentItemClassificationTypesScreen = {
    domain: ClassificationTypeDomain.DevelopmentItem,
    useSelections: () => useReduxSelections("developmentItemClassificationType"),
    useActions: () => {
        const { developmentItemClassificationTypeLoadAll: classificationTypesLoadAll } = useReduxActions("developmentItemClassificationType");
        return { classificationTypesLoadAll }
    },
    childDetailScreenKey: "DevelopmentItemClassificationType" as const,
    route: "/config/lists/developmentitemclassificationtypes" as const,
    component: DevelopmentItemClassificationTypes,
    label: "Development Item Classification"
}

function DevelopmentItemClassificationTypes() {
    return <ClassificationTypes screen={developmentItemClassificationTypesScreen} />
}

export const documentClassificationTypesScreen = {
    domain: ClassificationTypeDomain.Document,
    useSelections: () => useReduxSelections("documentClassificationType"),
    useActions: () => {
        const { documentClassificationTypeLoadAll: classificationTypesLoadAll } = useReduxActions("documentClassificationType");
        return { classificationTypesLoadAll }
    },
    childDetailScreenKey: "DocumentClassificationType" as const,
    route: "/config/lists/documentclassificationtypes" as const,
    component: DocumentClassificationTypes,
    label: "Document Classification"
}

function DocumentClassificationTypes() {
    return <ClassificationTypes screen={documentClassificationTypesScreen} />
}

type ClassificationTypesScreens =
    typeof investorClassificationTypesScreen
    | typeof roleRelationshipClassificationTypesScreen
    | typeof securityClassificationTypesScreen
    | typeof movementClassificationTypesScreen
    | typeof entityClassificationTypesScreen
    | typeof noteClassificationTypesScreen
    | typeof processClassificationTypesScreen
    | typeof developmentItemClassificationTypesScreen
    | typeof documentClassificationTypesScreen
    | typeof portfolioTransactionClassificationTypesScreen;

function ClassificationTypes({ screen }: { screen: ClassificationTypesScreens }) {
    const { classificationTypes, classificationTypesLoading } = screen.useSelections();
    const { classificationTypesLoadAll } = screen.useActions();
    const navigate = useScreenNavigate();
    const relatedGrants = getRelatedGrants(screen.domain);
    const columns: IColumnDefinition[] = [
        { name: "Code", title: "Code", getCellValue: ({ code }: IClassificationTypeModel) => code, filteringEnabled: true },
        { name: "Name", title: "Name", getCellValue: ({ name }: IClassificationTypeModel) => name["en"], filteringEnabled: true }
    ];
    const state: IGridState = {
        "Code": { width: 180 },
        "Name": { width: 240 },
    }

    useEffect(() => {
        classificationTypesLoadAll()
    }, [classificationTypesLoadAll]);

    const handleRowEdit = ({ id }: IClassificationTypeModel) => navigate(screen.childDetailScreenKey, { id })
    const handleAddNew = () => navigate(screen.childDetailScreenKey, { id: 0 });

    const getRowKey = (row: IClassificationTypeModel) => row.id || 0;
    return <SearchPanel
        title={`${getClassificationTypeDomainLabel(screen.domain)} Classification Types`}
        onAddClick={handleAddNew}
        addAllowed={relatedGrants}
        isQuerying={classificationTypesLoading}>
        <ExtendedGrid
            rows={classificationTypes}
            columns={columns}
            getRowId={getRowKey}
            initialState={state}
            onRowClick={handleRowEdit} />
    </SearchPanel>
}
