import produce from "immer";
import { IPortfolioComplianceResultModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IState {
    portfoliosComplianceDates: Date[];
    portfoliosComplianceCurrentDate?: Date;
    portfoliosComplianceDatesLoading: boolean;
    portfoliosComplianceChecking: boolean;
    portfoliosComplianceChecks: IPortfolioComplianceResultModel[];
}
export const ActionFactories = produceActionFactories({
    portfoliosComplianceDatesLoad: () => undefined,
    portfoliosComplianceDatesLoaded: (payload: Date[]) => payload,
    portfoliosComplianceCheck: (payload: Date) => payload,
    portfoliosComplianceChecked: (payload: IPortfolioComplianceResultModel[]) => payload,
});

export const reducer = (
    state: IState = {
        portfoliosComplianceChecking: false,
        portfoliosComplianceDatesLoading: false,
        portfoliosComplianceDates: [],
        portfoliosComplianceChecks: []
    },
    action: AnyActionOf<typeof ActionFactories>
) => produce(state, draft => {
    switch (action.type) {
        case "portfoliosComplianceDatesLoad":
            draft.portfoliosComplianceDatesLoading = true;
            break;
        case "portfoliosComplianceDatesLoaded":
            draft.portfoliosComplianceDatesLoading = false;
            draft.portfoliosComplianceDates = action.payload;
            break;
        case "portfoliosComplianceCheck":
            draft.portfoliosComplianceCurrentDate = action.payload;
            draft.portfoliosComplianceChecking = true;
            break;
        case "portfoliosComplianceChecked":
            draft.portfoliosComplianceChecks = action.payload;
            draft.portfoliosComplianceChecking = false;
            break;
    }
});
