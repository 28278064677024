import { Grid, GridSize } from "@material-ui/core";
import { IExtensionFieldModel, IExtensionFieldTypeModel } from "proxy/apiProxy";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormTextField from "tools/fieldComponents/FormTextField";

export interface IExtensionFieldsProps {
    extensionFields: IExtensionFieldModel[];
    pathToExtensionFields: string;
    lg?: boolean | GridSize;
    md?: boolean | GridSize;
    sm?: boolean | GridSize;
    xl?: boolean | GridSize;
    xs?: boolean | GridSize;
}

export default function ExtensionFields({ extensionFields, pathToExtensionFields, lg, md, sm, xl, xs=4 }: IExtensionFieldsProps) {
    return <>
        {extensionFields.map(extensionField => <Grid item={true} lg={lg} md={md} sm={sm} xl={xl} xs={xs}>
            <ExtensionField
                key={extensionField.code}
                extensionField={extensionField}
                pathToExtensionField={`${pathToExtensionFields}.${extensionField.code}`} />
        </Grid>)}
    </>

}

interface IExtensionFieldProps {
    extensionField: IExtensionFieldModel;
    pathToExtensionField: string;
}
function ExtensionField({ extensionField, pathToExtensionField }: IExtensionFieldProps) {
    switch (extensionField.type) {
        case IExtensionFieldTypeModel.String: return <StringExtensionField extensionField={extensionField} pathToExtensionField={pathToExtensionField} />
        case IExtensionFieldTypeModel.Boolean: return <BooleanExtensionField extensionField={extensionField} pathToExtensionField={pathToExtensionField} />
        case IExtensionFieldTypeModel.Number: return <NumberExtensionField extensionField={extensionField} pathToExtensionField={pathToExtensionField} />
        case IExtensionFieldTypeModel.Date: return <DateExtensionField extensionField={extensionField} pathToExtensionField={pathToExtensionField} />
    }
}
function StringExtensionField({ extensionField: { isMandatory, label }, pathToExtensionField }: IExtensionFieldProps) {
    return <FormTextField label={label} required={isMandatory} name={pathToExtensionField} />
}
function BooleanExtensionField({ extensionField: { label, isMandatory }, pathToExtensionField }: IExtensionFieldProps) {
    return <FormCheckBoxField label={label} name={pathToExtensionField} canBeUndefined={!isMandatory} />
}
function NumberExtensionField({ extensionField: { isMandatory, label }, pathToExtensionField }: IExtensionFieldProps) {
    return <FormTextField label={label} required={isMandatory} name={pathToExtensionField} isNumber={true} />
}
function DateExtensionField({ extensionField: { isMandatory, label }, pathToExtensionField }: IExtensionFieldProps) {
    return <FormDatePickerField label={label} required={isMandatory} name={pathToExtensionField} />
}
