import produce from "immer";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetPositionsModel, PortfolioSummaryModel, IPositionModel, SecuritySummaryModel, EntitySummaryModel, ISubFundSummaryModel } from "proxy/apiProxy";
import { IDailyDataSearchPositionsParameters } from "proxy/apiProxy";
import { IGetPortfoliosSummary } from "features/Reference/slice";

export const ActionFactories = produceActionFactories({
  positionSearch: (payload: IDailyDataSearchPositionsParameters) => payload,
  positionLoaded: (payload: IGetPositionsModel) => payload,
  positionAddPortfoliosToDictionary: (payload: IGetPortfoliosSummary) => payload
});

export interface IState {
  positionsLoading: boolean;
  positions: IPositionModel[];
  dictionaries: {
    entities: Record<string | number, EntitySummaryModel>;
    portfolios: Record<string | number, PortfolioSummaryModel>;
    subFunds: Record<string | number, ISubFundSummaryModel>;
    securities: Record<string | number, SecuritySummaryModel>;
  }
}

export function reducer(
  state: IState = {
    positionsLoading: false,
    positions: [],
    dictionaries: {
      entities: {},
      portfolios: {},
      subFunds: {},
      securities: {},
    }
  },
  action: AnyActionOf<typeof ActionFactories>
): IState {
  return produce(state, draft => {
    switch (action.type) {
      case "positionSearch":
        draft.positionsLoading = true;
        break;
      case "positionLoaded":
        draft.positionsLoading = false;
        const { positions, ...dictionaries } = action.payload;
        draft.dictionaries = { ...draft.dictionaries, ...dictionaries };
        draft.positions = positions;
        break;
      case "positionAddPortfoliosToDictionary":
        draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
        draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
        break;
    }
  });
}
