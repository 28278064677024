import { IBatchModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import { useField } from "formik";
import CodeEditor from "tools/components/CodeEditor";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";


// https://developers.google.com/fonts/docs/getting_started
export interface IBatchScriptProps {
    jsonSchema: object | undefined;
    onSaveRequest: () => void;
};

export default function BatchScript({ jsonSchema, onSaveRequest }: IBatchScriptProps) {
    // const modelUri= monaco.Uri.parse("a://b/foo.json")
    const [, { value: script }, { setValue: setScript }] = useField<IBatchModel["script"]>(oProps<IBatchModel>().path("script"));
    return <ReadOnlyContext.Consumer>{readOnly => <CodeEditor
        code={script}
        readOnly={readOnly}
        onSave={onSaveRequest}
        onCodeChanged={setScript}
        jsonSchema={jsonSchema}
        language="json" />}</ReadOnlyContext.Consumer>
}
