import { FormControl, InputLabel, FormHelperText, Card, CardContent, CardHeader, Typography, ButtonGroup, IconButton, Avatar } from "@material-ui/core";
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import { IMarketDataProviderModel, IMarketDataProviderSetupModel, IParametersModel } from "proxy/apiProxy";
import * as React from "react";
import FieldBox from "tools/components/FieldBox";
import MultiSelect from "tools/components/MultiSelect";
import FormTextField from "tools/fieldComponents/FormTextField";
import { IDictionary, oProps, toDictionary } from "tools/lib/utility";
import * as Uuid from 'uuid';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
export interface IFormMultiSelectProps<V extends string | number = number, T extends any | { id?: V } = any> {
    fullWidth?: boolean;
    options: IDictionary<T> | T[];
    getValue?: (row: T) => V;
    value: V[];
    getPrimaryContent?: (row: T) => React.ReactNode;
    getSecondaryContent?: (row: T) => React.ReactNode;
    onSelectionChanged?: (value: V[]) => void;
    disabled?: boolean;
    label?: React.ReactNode;
    fieldError?: boolean;
    helperText?: React.ReactNode;
    required?: boolean;
}


export function FormMultiSelect<V extends string | number = number, T extends any | { id?: V } = any>({
    getValue,
    getPrimaryContent,
    getSecondaryContent,
    disabled,
    onSelectionChanged,
    fieldError,
    label,
    fullWidth,
    options,
    value,
    helperText,
    required }: IFormMultiSelectProps<V, T>) {
    const [idComponent] = React.useState(Uuid.v1());
    return (<FormControl fullWidth={fullWidth} error={fieldError} >
        <InputLabel htmlFor={idComponent}>{label}</InputLabel>
        <MultiSelect<V, T>
            required={required}
            error={fieldError}
            values={value as V[]}
            disabled={disabled}
            options={options}
            getValue={getValue}
            getPrimaryContent={getPrimaryContent}
            getSecondaryContent={getSecondaryContent}
            inputProps={{ id: idComponent }}
            margin="dense"
            onSelectionChanged={onSelectionChanged}
            fullWidth={fullWidth} />
        {helperText && <FormHelperText error={fieldError}>{helperText}</FormHelperText>}
    </FormControl>);
}
export interface IParametersMarketDataProvidersProps {
    parametersMarketDataProviders: IMarketDataProviderModel[];
}
export default function ParametersMarketDataProviders({ parametersMarketDataProviders }: IParametersMarketDataProvidersProps) {
    const [, { value: marketDataProviderConnectionSetups },] = useField<IParametersModel["marketDataProviderConnectionSetups"]>(oProps<IParametersModel>().path("marketDataProviderConnectionSetups"));
    const [selectedMarketDataProviderCodes, setSelectedMarketDataProviderCodes] = React.useState<string[]>([]);
    React.useEffect(() => {
        setSelectedMarketDataProviderCodes(marketDataProviderConnectionSetups
            ? marketDataProviderConnectionSetups.map(({ marketDataProviderCode }) => marketDataProviderCode)
            : []);
    }, [marketDataProviderConnectionSetups, setSelectedMarketDataProviderCodes]);

    const [marketDataProvidersDico, setMarketDataProvidersDico] = React.useState<Record<string, IMarketDataProviderModel>>({});
    React.useEffect(() => {
        setMarketDataProvidersDico(toDictionary(parametersMarketDataProviders, i => i.marketDataProviderCode));
    }, [parametersMarketDataProviders, setMarketDataProvidersDico]);

    const getValue = ({ marketDataProviderCode }: IMarketDataProviderModel) => marketDataProviderCode;
    const getName = ({ marketDataProviderName }: IMarketDataProviderModel) => marketDataProviderName;
    // onSelectionChanged
    return <FieldArray name={oProps<IParametersModel>().path("marketDataProviderConnectionSetups")}>{RenderMarketDataProviderConnectionSetups}</FieldArray>;
    function RenderMarketDataProviderConnectionSetups({ move, push, remove }: FieldArrayRenderProps) {
        const handleProviderListSelectionChanged = (newSelectedProviders: string[]) => {
            if (!marketDataProviderConnectionSetups) {
                for (const newSelectedProvider of newSelectedProviders) {
                    push({
                        marketDataProviderCode: newSelectedProvider,
                        connectionParameterValues: {}
                    } as IMarketDataProviderSetupModel);
                }
            }
            else {
                const selectedProvidersToAdd = newSelectedProviders
                    .filter(newSelectedProvider => marketDataProviderConnectionSetups.filter(({ marketDataProviderCode }) => marketDataProviderCode === newSelectedProvider).length === 0);
                const marketDataProvidersToRemove = marketDataProviderConnectionSetups.map(({ marketDataProviderCode }, idx) => newSelectedProviders.filter(newSelectedProvider => newSelectedProvider === marketDataProviderCode).length === 0 ? idx : -1)
                    .filter(i => i >= 0);
                for (const newSelectedProvider of selectedProvidersToAdd) {
                    push({
                        marketDataProviderCode: newSelectedProvider,
                        connectionParameterValues: {}
                    } as IMarketDataProviderSetupModel);
                }
                for (const dataProviderToRemove of marketDataProvidersToRemove) {
                    remove(dataProviderToRemove);
                }
            }
        };
        return <FieldBox display="flex" flexDirection="column">
            <FormMultiSelect
                label="Providers"
                options={parametersMarketDataProviders}
                getValue={getValue}
                getPrimaryContent={getName}
                fullWidth={true}
                helperText="Select providers that will be used in the system"
                value={selectedMarketDataProviderCodes}
                onSelectionChanged={handleProviderListSelectionChanged} />
            {(!marketDataProviderConnectionSetups)
                ? <Typography>No market data provider is setup</Typography>
                : marketDataProviderConnectionSetups.map(({ marketDataProviderCode }, index) => <MarketDataProviderConnectionSetup
                    onMove={move}
                    index={index}
                    key={index}
                    maxIndex={marketDataProviderConnectionSetups.length - 1}
                    marketDataProviderModel={marketDataProvidersDico[marketDataProviderCode]} />)}
        </FieldBox>;
    }
}
interface IMarketDataProviderConnectionSetupProps {
    marketDataProviderModel?: IMarketDataProviderModel;
    index: number;
    maxIndex: number;
    onMove: (from: number, to: number) => void;
}
function MarketDataProviderConnectionSetup({ marketDataProviderModel, index, onMove, maxIndex }: IMarketDataProviderConnectionSetupProps) {
    if (!marketDataProviderModel) {
        return null;
    }
    const { marketDataProviderName, connectionParameters } = marketDataProviderModel;
    const handleMoveUp = () => onMove(index, index - 1);
    const handleMoveDown = () => onMove(index, index + 1);
    return <Card>
        <CardHeader
            title={marketDataProviderName}
            avatar={<Avatar>{index + 1}</Avatar>}
            action={<ButtonGroup>
                <IconButton disabled={index >= maxIndex} onClick={handleMoveDown} >
                    <ArrowDownwardIcon />
                </IconButton>
                <IconButton disabled={index <= 0} onClick={handleMoveUp}>
                    <ArrowUpwardIcon />
                </IconButton>
            </ButtonGroup>} />
        <CardContent>
            <FieldBox display="flex" flexDirection="column">
                {!connectionParameters.length
                    ? <Typography>No parameters to provide</Typography>
                    : connectionParameters.map(connectionParameter => <FormTextField key={connectionParameter} name={oProps<IParametersModel>().path("marketDataProviderConnectionSetups", index, "connectionParameterValues", connectionParameter)} label={connectionParameter} />)
                }
            </FieldBox>
        </CardContent>
    </Card>;
}
