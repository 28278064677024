import * as React from "react";
import { DevelopmentItemModel, IClassificationTypeModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import { Grid } from "@material-ui/core";
import ClassificationsData from "components/global/ClassificationsData";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";

export interface IDevelopmentItemPropertiesProps {
    classificationTypes: IClassificationTypeModel[];
    onTypeChanged: (type: DevelopmentItemModel["type"]) => void;
}

export const types: Record<DevelopmentItemModel["type"], string> = {
    "QuestionnaireDevelopmentItemModel": "Questionnaire",
    "EtlMacroDevelopmentItemModel": "Etl Macro",
    "DashboardLayoutDevelopmentItemModel": "Dashboard Layout",
    // "FileConnectorDevelopmentItemModel": "File Connector",
    // "FileConsumerMacroDevelopmentItemModel": "File Consumer Macro",
    // "FileProducerMacroDevelopmentItemModel": "File Producer Macro",
    // "PipelineTaskDevelopmentItemModel": "Pipeline Task",
    "ReportTemplateDevelopmentItemModel": "Report Template",
    "DatasetMacroDevelopmentItemModel":"Dataset"
}

export function DevelopmentItemProperties({ classificationTypes, onTypeChanged }: IDevelopmentItemPropertiesProps) {
    const handleTypeChanged = React.useCallback((value: string | number | undefined) => onTypeChanged(value as DevelopmentItemModel["type"]), [onTypeChanged]);
    return <Grid container={true} spacing={1} style={{ margin: 16 }}>
        <Grid item={true} sm={12} md={6} lg={4}>
            <FormSimpleSelectField name={oProps<DevelopmentItemModel>().path("type")} label="Type" options={types} onSelectionChanged={handleTypeChanged} required />
        </Grid>
        <Grid item={true} sm={12} md={6} lg={4}>
            <FormTextField name={oProps<DevelopmentItemModel>().path("name")} label="Name" required={true} />
        </Grid>
        <Grid item={true} sm={12} md={12} lg={12}>
            <ClassificationsData
                panelTitle="Classifications"
                fieldName={oProps<DevelopmentItemModel>().path("classifications")}
                classificationTypes={classificationTypes} />
        </Grid>
    </Grid>;
}
