import * as React from "react";
import { useField } from "formik";
import { IDashboardLayoutDevelopmentItemModel, DevelopmentItemModel, MacroSymbolModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import { EditorReferencePayload } from "../Designers/INotControllableEditorProps";
import FormLayoutEditor from "../Designers/FormLayoutEditor";
import { developmentItemScreen } from "../DevelopmentItem"
import { IDatasetResult } from "../slice";
export interface IDashboardLayoutDevelopmentItemRefs {
    dashboardLayoutRef: React.MutableRefObject<EditorReferencePayload<string>>;
}
export interface IDashboardLayoutDevelopmentItemEditorProps extends IDashboardLayoutDevelopmentItemRefs {
    type: DevelopmentItemModel["type"];
    tabValue: keyof typeof developmentItemScreen["tabs"] | undefined;
    result: IDatasetResult | undefined;// DatasetResultModel
}
export default function DashboardLayoutDevelopmentItemEditor({ tabValue, dashboardLayoutRef, result }: IDashboardLayoutDevelopmentItemEditorProps) {
    const [{ value: id }] = useField(oProps<IDashboardLayoutDevelopmentItemModel>().path("id"));
    const dictionary: MacroSymbolModel = React.useMemo(() => (result?.result?.type !== "CompilationErrorDatasetResultModel" && result?.result?.returnType)
        ? result?.result.returnType
        : { type: "ObjectMacroSymbolModel", properties: {}, nullable: false }, [result]);
    switch (tabValue) {
        case "layout": return <FormLayoutEditor key={`dashboardLayout${id}`}
            refToGetValue={dashboardLayoutRef}
            metadata={dictionary}
            datasetResult={result?.result}
            input={result?.input} />;
    }
    return null;
}
