import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, ReportModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import ReportData from "./ReportData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import FileDownloadOutlineIcon from "mdi-material-ui/FileDownloadOutline";
import { IActionButton } from "tools/components/FabContainer";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";
import { isNumber, useNumber } from "../../tools/lib/utility";


export const reportScreen = {
    route: "/config/reporting/report/:id" as const,
    component: Report,
    label: "Report"
}

function Report() {
    const {
        reportSave,
        reportDelete,
        reportSelectReportTemplate,
        reportSelectReportDocument,
        reportGenerate,
        reportLoad
    } = useReduxActions("report");
    const navigate = useScreenNavigate();
    const {
        reportCurrent, reportLoading, reportTemplateMetadataSearchLoading, reportGenerating,
        reportParameterMetadata = [], reportReportTemplates = [], reportDocumentDefinitions = []
    } = useReduxSelections("report");

    const { id } = useScreenParams<typeof reportScreen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (isNumber(idNum))
            reportLoad(idNum)
    }, [idNum, reportLoad]);

    if (!reportCurrent) {
        return <DetailPanelLoading hasSubTitle={false} />;
    }
    const { report } = reportCurrent;

    const handleDelete = () => {
        const { report: { id } } = reportCurrent;
        if (id) {
            reportDelete(id);
        }
    }
    const handleSubmit = (values: ReportModel, { setSubmitting }: FormikHelpers<ReportModel>) => {
        reportSave(values);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={report} enableReinitialize={true}
        validateOnMount={true}>{renderForm}</Formik>;

    function renderForm(formikProps: FormikProps<ReportModel>) {
        if (!reportCurrent) {
            return;
        }
        const { dirty, isValid, submitForm, values } = formikProps;
        const { id, name } = values;
        const title = !!id ? (name) : 'New Report';

        const handleOpenReportTemplate = () => values.type === "TemplateReportModel"
            && navigate("Template", { id: values.reportTemplateId });

        const handleOpenDocumentDefinition = () => values.type === "DocumentReportModel"
            && navigate("BatchDefinition", { id: values.documentDefinitionId });

        const extraActionButtons = [
            //     {
            //     label: "Set preview data",
            //     onClick: this.handlePreviewDataRequest,
            //     icon: DatabaseExportIcon
            // }, 
            {
                label: "Generate Reports",
                onClick: () => reportGenerate(id),
                disabled: !id || reportGenerating,
                icon: FileDownloadOutlineIcon,
                feature: IFeatureModel.ReportExecute
            }] as IActionButton[];

        return <WriteAccess value={IFeatureModel.BatchWrite}>
            <DetailPanel
                isQuerying={reportLoading || reportTemplateMetadataSearchLoading || reportGenerating}
                title={title}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!id}
                onDeleteClick={handleDelete}
                actions={extraActionButtons}
                saveAllowed={IFeatureModel.BatchWrite}
                deleteAllowed={IFeatureModel.BatchWrite}
                badge={!id ? "new" : undefined}>
                <ReportData
                    parameterMetadata={reportParameterMetadata}
                    reportTemplates={reportReportTemplates}
                    onSelectReportTemplate={reportSelectReportTemplate}
                    onSelectDocumentDefinition={reportSelectReportDocument}
                    onOpenReportTemplate={handleOpenReportTemplate}
                    onOpenDocumentDefinition={handleOpenDocumentDefinition}
                    documentDefinitions={reportDocumentDefinitions} />
            </DetailPanel></WriteAccess>
    }
}
