import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import { ILoanModel, ICovenantModel, ICovenantTypeModel } from "proxy/apiProxy";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { getEnumLabels, oProps } from "tools/lib/utility";
import { Table, TableRow, TableCell, TableBody, Typography, Tooltip, IconButton, Fab } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import UpIcon from '@material-ui/icons/ArrowUpward';
import DownIcon from '@material-ui/icons/ArrowDownward';
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import FormTextField from "tools/fieldComponents/FormTextField";
const covenantTypes = getEnumLabels(ICovenantTypeModel);

export default function Covenants() {
    const [{ value: covenants }] = useField<ILoanModel["covenants"]>(oProps<ILoanModel>().path("covenants"));
    return <FieldArray name={oProps<ILoanModel>().path("covenants")} render={renderCovenants} validateOnChange={true} />;
    function renderCovenants({ push, remove, move, form: { validateForm } }: FieldArrayRenderProps): React.ReactNode {
        const handleRemove = (idx: number) => {
            if (idx < 0) {
                return;
            }
            remove(idx);
            setTimeout(() => validateForm(), 100);
        }
        const handleAdd = () => {
            push({} as ICovenantModel);
            setTimeout(() => validateForm(), 100);
        }
        const handleMove = (idx: number, newIdx: number) => move(idx, newIdx);
        return (<ReadOnlyContext.Consumer>{readOnly => <><CovenantsTable
            covenants={covenants}
            readOnly={readOnly}
            onRemove={handleRemove}
            onMove={handleMove} />
            {!readOnly && <Fab onClick={handleAdd} >
                <AddIcon />
            </Fab>
            }
        </>}</ReadOnlyContext.Consumer>);
    }
}
interface ICovenantsTableProps {
    covenants: ICovenantModel[];
    readOnly: boolean;
    onRemove: (idx: number) => void;
    onMove: (index: number, newIndex: number) => void;
}
function CovenantsTable({ covenants, readOnly, onRemove, onMove }: ICovenantsTableProps) {
    if (!covenants?.length) {
        return <Typography>No covenant</Typography>
    }
    return <Table>
        {/* <TableHead>
            <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Limit</TableCell>
                {!readOnly && <TableCell />}
            </TableRow>
        </TableHead> */}
        <TableBody>
            {covenants.map((covenant, idx) => <CovenantRow
                index={idx}
                key={idx}
                onRemove={onRemove}
                readOnly={readOnly}
                onMove={onMove}
                length={covenants.length} />)}
        </TableBody>
    </Table>
}

interface ICovenantRowProps {
    index: number;
    onRemove: (index: number) => void;
    onMove: (index: number, newIndex: number) => void;
    readOnly: boolean;
    length: number;
}
function CovenantRow({ index, onRemove, readOnly, onMove, length }: ICovenantRowProps) {
    const handleRemove = () => onRemove(index);
    const handleMoveUp = () => onMove(index, index - 1);
    const handleMoveDown = () => onMove(index, index + 1);
    return <TableRow>
        <TableCell><FormSimpleSelectField name={oProps<ILoanModel>().path("covenants", index, "type")} label="Pricing Frequency" options={covenantTypes} required={true} /></TableCell>
        <TableCell><FormTextField name={oProps<ILoanModel>().path("covenants", index, "limit")} label="Limit" isNumber={true} required={true} /></TableCell>
        {!readOnly && <>
            <TableCell align="right">
                <Tooltip title="Move Up">
                    <IconButton disabled={index <= 0} size="small" style={{ verticalAlign: "middle" }} onClick={handleMoveUp} >
                        <UpIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Move Down">
                    <IconButton disabled={index >= length - 1} size="small" style={{ verticalAlign: "middle" }} onClick={handleMoveDown} >
                        <DownIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Tooltip title="Delete">
                    <IconButton size="small" style={{ verticalAlign: "middle" }} onClick={handleRemove} >
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </>}
    </TableRow>
}
