import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React, { ReactNode, UIEventHandler, useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
    changeNodeAtPath,
    getNodeAtPath,
    insertNode,
    ReactSortableTreeProps,
    removeNodeAtPath,
    SortableTreeWithoutDndContext as SortableTree,
    TreeIndex,
    TreePath
} from "react-sortable-tree";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { MenuNodeDataDialog } from "./MenuNodeDataDialog";
import { ExtraNodeRendererProps, MenuNodeRenderer } from "./MenuNodeRenderer";
import { TargetScreenSearch } from "./TargetScreenSearch";
import {
    dndMenuNodeType,
    IMenuDefinitionTreeNodeLevel1,
    IMenuDefinitionTreeNodeLevel2, IMenuDefinitionTreeNodeLevel3,
    isTargetValid,
    ITreeItemModel,
    MenuTreeItem,
    TreeItemPayload
} from "./menuTree";
import { MenuGroupSource } from "./MenuGroupSource";
import { MenuHomeContainer, MenuHomeContainerProps } from "./MenuHomeContainer";
import clsx from "clsx"

export interface IMenuStructureProps {
    menuHome: IMenuDefinitionTreeNodeLevel3 | undefined;
    menuTree: MenuTreeItem[];
    setMenuHome: (menuMenuDefinition: IMenuDefinitionTreeNodeLevel3 | undefined) => void;
    setMenuTree: (menuMenuDefinition: MenuTreeItem[]) => void;
    stickyAction?: ReactNode;
}

type MenuTreeProps = ReactSortableTreeProps<ITreeItemModel>;
type MenuCanDropHandler = NonNullable<MenuTreeProps["canDrop"]>
type MenuCanNodeHaveChildrenHandler = NonNullable<MenuTreeProps["canNodeHaveChildren"]>
type MenuGenerateNodePropsHandler = NonNullable<MenuTreeProps["generateNodeProps"]>
type MenuOnMoveNodeHandler = NonNullable<MenuTreeProps["onMoveNode"]>

const useStyles = makeStyles((theme) => createStyles({
    container: {
        display: "flex",
        height: "100%",
        width: "100%",
        overflowY: "hidden",
        backgroundColor: theme.palette.background.default,
    },
    leftContainer: {
        height: "100%",
        flexGrow: 1,
    },
    rightContainerSources: {
        height: "100%",
        width: 400,
        display: "flex",
        flexDirection: "column",
    },
    levelGroupsSources: {
        paddingTop: theme.spacing(5),
        paddingInline: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: theme.spacing(1),
        "& button:nth-child(2)": {
            marginLeft: theme.spacing(5),
        }
    },
    treeContainer: {
        paddingBottom: theme.spacing(10),
    },
    scrollableMenuStructure: {
        height: "100%",
        overflowY: "auto",
    },
    homeContainer: {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(15),
    },
    menuTitle: {
        paddingLeft: theme.spacing(3),
    },
}))

export function MenuStructure({ menuHome, setMenuHome, menuTree, setMenuTree, stickyAction }: IMenuStructureProps) {

    const readOnly = React.useContext(ReadOnlyContext)

    const [editingNode, setEditingNode] = React.useState<{ node: MenuTreeItem } & TreePath & TreeIndex>()
    const [editingHome, setEditingHome] = React.useState<{ node: MenuTreeItem }>()

    const hideEdit = useCallback(() => {
        setEditingNode(undefined)
    }, [])
    const hideEditHome = useCallback(() => {
        setEditingHome(undefined)
    }, [])

    const canDrop = useCallback<MenuCanDropHandler>(({ node, nextParent, nextPath, prevParent }) => {
        switch (node.model.type) {
            case "level1": return !nextParent;
            case "level2": return !!nextParent && nextParent.model.type === "level1";
            default: return !!nextParent && nextParent.model.type === "level2";
        }
    }, [])

    const canNodeHaveChildren = useCallback<MenuCanNodeHaveChildrenHandler>((node) => {
        return node.model.type === "level2" || node.model.type === "level1";
    }, [])

    const getNodeKey = useCallback(({ treeIndex }: TreeIndex) => treeIndex, [])

    const handleAddNewGroupLevel1 = useCallback<UIEventHandler<HTMLButtonElement>>((e) => {
        const newTreeItem: MenuTreeItem = { expanded: true, model: { type: "level1", label: "" } };
        const { treeData, treeIndex, path } = insertNode<ITreeItemModel>({
            treeData: menuTree,
            newNode: newTreeItem,
            depth: 0,
            getNodeKey,
            minimumTreeIndex: 0,
        })
        setMenuTree(treeData)
        setEditingNode({ node: newTreeItem, path, treeIndex })
    }, [getNodeKey, menuTree, setMenuTree])

    const handleEditNode = useCallback<ExtraNodeRendererProps["onEdit"]>(({ node, path, treeIndex }) => {
        setEditingNode({ node, path, treeIndex })
    }, [])

    const handleEditedNode = useCallback((payload: TreeItemPayload) => {

        if (!!editingNode) {
            const node = getNodeAtPath<ITreeItemModel>({ treeData: menuTree, getNodeKey, path: editingNode.path })?.node;
            if (node) {
                const newNode: MenuTreeItem = { ...node, model: payload };
                const newTree = changeNodeAtPath<ITreeItemModel>({
                    treeData: menuTree,
                    getNodeKey,
                    path: editingNode.path,
                    newNode
                })
                setMenuTree(newTree)
            }
            setEditingNode(undefined)
        }
    }, [editingNode, getNodeKey, menuTree, setMenuTree])

    const handleSetHome = useCallback<MenuHomeContainerProps["setHomeNode"]>((home) => {
        setMenuHome(home);
    }, [setMenuHome])
    const handleEditHome = useCallback<MenuHomeContainerProps["onEditTarget"]>((home) => {
        setEditingHome({ node: { model: home } })
    }, [])
    const handleEditedHome = useCallback((node: TreeItemPayload) => {
        if (!!editingHome) {
            if (node.type !== "level1" && node.type !== "level2") {
                setMenuHome(node);
            }
            setEditingHome(undefined)
        }
    }, [editingHome, setMenuHome])

    const handleDeleteNode = useCallback<ExtraNodeRendererProps["onDelete"]>((path: TreePath) => {
        const newMenuTree = removeNodeAtPath({ treeData: menuTree, ...path, getNodeKey });
        setMenuTree(newMenuTree)
    }, [getNodeKey, menuTree, setMenuTree])

    const handleMoveNode = useCallback<MenuOnMoveNodeHandler>((data) => {
        // When a new item is dropped and needs editing, open the dialog.
        const model = data.node.model;
        if (!model.new)
            return
        if (model.type === "level1" || model.type === "level2" || !isTargetValid(model)) {
            setEditingNode(data);
        }
        delete model.new
    }, [])

    const generateNodeProps = useCallback<MenuGenerateNodePropsHandler>(() => {
        return {
            onEdit: handleEditNode,
            onDelete: handleDeleteNode,
            readOnly
        } as ExtraNodeRendererProps
    }, [handleDeleteNode, handleEditNode, readOnly])

    const classes = useStyles();
    return <>
        {!!editingNode &&
            <MenuNodeDataDialog data={editingNode.node.model} onChange={handleEditedNode} onCancel={hideEdit} />}
        {!!editingHome &&
            <MenuNodeDataDialog data={editingHome.node.model} onChange={handleEditedHome} onCancel={hideEditHome} />}
        <div className={classes.container}>
            {/* @ts-ignore FIXME Upgrade */}
            <DndProvider backend={HTML5Backend}>
                <div className={clsx(classes.leftContainer, classes.scrollableMenuStructure)}>
                    <div className={classes.homeContainer}>
                        <MenuHomeContainer
                            homeNode={menuHome}
                            setHomeNode={handleSetHome}
                            onEditTarget={handleEditHome} />
                    </div>
                    <Typography className={classes.menuTitle} variant="subtitle2">Menu:</Typography>
                    <div className={classes.treeContainer}>
                        <SortableTree<ITreeItemModel>
                            treeData={menuTree}
                            onChange={setMenuTree}
                            onMoveNode={handleMoveNode}
                            canDrag={!readOnly}
                            canDrop={canDrop}
                            canNodeHaveChildren={canNodeHaveChildren}
                            isVirtualized={false}
                            nodeContentRenderer={MenuNodeRenderer}
                            generateNodeProps={generateNodeProps}
                            dndType={dndMenuNodeType}
                        />
                    </div>
                </div>
                <div className={classes.rightContainerSources}>
                    <div className={classes.levelGroupsSources}>
                        <MenuGroupSource<IMenuDefinitionTreeNodeLevel1>
                            label="Level 1 group"
                            onClick={handleAddNewGroupLevel1}
                            model={{ type: "level1", label: "", new: true }} />
                        <MenuGroupSource<IMenuDefinitionTreeNodeLevel2>
                            label="Level 2 group"
                            model={{ type: "level2", label: "", icon: "", new: true }} />
                    </div>
                    <TargetScreenSearch />
                    <div>{stickyAction}</div>
                </div>
            </DndProvider>
        </div>
    </>
}
