import * as React from "react";
import {
    GridColumnExtension, SortingState, IntegratedSorting, GroupingState, IntegratedGrouping, TableColumnWidthInfo,
    SummaryState, IntegratedSummary, SummaryItem, Grouping, Sorting, IntegratedFiltering, FilteringState,
    TreeDataState, CustomTreeData, EditingState, EditingCell, ChangeSet, RowDetailState, Filter, SelectionState
} from '@devexpress/dx-react-grid';
import {
    Grid, TableHeaderRow, TableFilterRow, VirtualTable, TableGroupRow, DragDropProvider, Toolbar,
    GroupingPanel, ColumnChooser, TableColumnVisibility, TableColumnReordering, TableColumnResizing,
    TableSummaryRow, TableFixedColumns, TableTreeColumn, Table, TableInlineCellEditing, TableRowDetail, TableSelection
} from '@devexpress/dx-react-grid-material-ui';
import TableEditCell from "./TableEditCell";
import { Column } from "@devexpress/dx-react-grid";
import {
    PercentTypeProvider, PrecisePercentTypeProvider, PrecisePercent4TypeProvider, DateTypeProvider,
    DecimalTypeProvider, BooleanTypeProvider, CurrencyTypeProvider, PreciseDecimalTypeProvider,
    DateTimeTypeProvider, IntegerTypeProvider
} from "tools/components/GridFormatters";
import { ToolbarExport } from "./ToolbarExport";
import { ToolbarExportState } from "./ToolbarExportState";
import findColorBetween from "tools/lib/findColorBetween";
import { ButtonGroup, IconButton, Tooltip } from "@material-ui/core";
import { DataType } from 'csstype';
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IStoreGridState } from "features/App/slice";
import DeleteIcon from "@material-ui/icons/Delete";
import { IReferenceTypeModel } from "proxy/apiProxy";
import { toDictionary } from "tools/lib/utility";
import { useScreenKey, useTab } from "../routing/screenRouteHooks";

const Root = (props: any) => <Grid.Root {...props} style={{ height: '100%', width: '100%' }} />;

const TableMultilineHeaderCellBase = (props: any) => {
    const { style, ...restProps } = props;
    return (
        <TableHeaderRow.Cell style={{ ...style, whiteSpace: 'normal', wordWrap: 'break-word' }} {...restProps} />
    )
};

// https://github.com/DevExpress/devextreme-reactive/blob/master/packages/dx-react-grid-material-ui/src/plugins/table-inline-cell-editing.jsx
// https://devexpress.github.io/devextreme-reactive/react/grid/docs/reference/table-inline-cell-editing/
// https://codesandbox.io/s/quirky-sunset-3dr83?file=/demo.js


export type ColumnType =
    "text"
    | "date"
    | "dateTime"
    | "percentage"
    | "precisePercentage2"
    | "precisePercentage4"
    | "decimal"
    | "boolean"
    | "currency"
    | "preciseDecimal"
    | "integer"
    | "link";

export interface IButtonLinkProps {
    id: number;
    textValue: string;
}

/**
 * @deprecated this bit should not be embedded in the grid itself
 */
export interface IButtonReferenceLinkProps extends IButtonLinkProps {
    id: number;
    textValue: string;
    referenceType: IReferenceTypeModel;
}

export function isButtonLink(value: React.Component<IButtonLinkProps> | string | null): value is React.Component<IButtonLinkProps> {
    return value !== null
        && typeof value === "object"
        && "props" in value
        && "textValue" in value.props
        && "id" in value.props;
}

function buttonLinkTextOrItself(value: React.Component<IButtonLinkProps> | string) {
    return isButtonLink(value) ? value.props.textValue : value
}

// Generate a filtering function that adds support for ButtonLinks to a provided filtering function
function referenceFilteringPredicateGen(predicate: ((value: React.Component<IButtonLinkProps> | string, filter: Filter, row: any) => boolean)) {
    return (value: React.Component<IButtonLinkProps> | string, filter: Filter, row: any) => {
        const rowText = buttonLinkTextOrItself(value);
        return predicate(rowText, filter, row)
    };
}

// Filtering function supporting ButtonLinks
const referenceTextFilteringPredicate = referenceFilteringPredicateGen(IntegratedFiltering.defaultPredicate);

// Compare function supporting ButtonLinks
function referenceLinkCompare(a: any, b: any) {
    const aText = buttonLinkTextOrItself(a);
    const bText = buttonLinkTextOrItself(b);
    if (aText === bText) return 0;
    return aText < bText ? -1 : 1;
}

// Grouping criteria supporting ButtonLinks
function referenceLinkGroupingCriteria(value: any) {
    const rowText = buttonLinkTextOrItself(value)
    return { key: rowText }
}
export type ColorFunc = (row: any) => DataType.Color | undefined;
export type ColorDefinition = IColorStep[] | ColorFunc;
export interface IColumnDefinition extends Column {
    positionToKeep?: "left" | "right";
    align?: 'left' | 'right' | 'center';
    filteringEnabled?: boolean;
    wordWrapEnabled?: boolean;
    aggregationType?: "sum" | "max" | "min" | "avg" | "count";
    columnType?: ColumnType;
    getAdornment?: (row: any) => JSX.Element | null;
    backgroundColor?: ColorDefinition;
    foregroundColor?: ColorDefinition;
    editable?: boolean;
}
export interface IExtendedGridProps<TRow> {
    noStateRestoration?: boolean;
    columns: IColumnDefinition[];
    onSelectionChanged?: (row: TRow[]) => void;
    selectedRows?: TRow[];
    multiSelect?: boolean;
    onRowClick?: (row: TRow) => void;
    onRowDelete?: (row: TRow) => void;
    rows: TRow[];
    getRowId: (row: any) => number | string;
    initialState: IGridState;
    onStateChanged?: (state: IGridState) => void;
    userCanGroup?: boolean;
    defaultColumnWidth?: number;
    defaultExportFileName?: string;
    /** @deprecated use backgroundColor on IColumnDefinition */
    getCellBackground?: (row: any, columnName: string) => DataType.Color | undefined;
    /** @deprecated use foregroundColor on IColumnDefinition */
    getCellForeground?: (row: any, columnName: string) => DataType.Color | undefined;
    getCellTooltip?: (row: any, columnName: string) => string;
    getChildRows?: (currentRow: any, rootRows: any[]) => any[] | null;
    expandedRowIds?: string[] | number[];
    onExpandedRowIds?: ((expandedRowIds: React.ReactText[]) => void) | undefined;
    treeColumnName?: string;
    useMultilineHeader?: boolean;
    onCellValueChanged?: (rowId: number | string, columnName: string, value: string) => void;
    detailComponent?: React.ComponentType<{ row: TRow }>;
    expandedDetailRowIds?: (number | string)[];
    onExpandedDetailRowIdsChange?: (expandedRowIds: (number | string)[]) => void;
    stateLess?: boolean;
    showSelectionCheckbox?: boolean;
    // detailComponent?: React.ComponentType<TableRowDetail.ContentProps>;
}
export interface IColumnState {
    width?: number;
    groupingPosition?: number;
    hidden?: boolean;
    columnPosition?: number;
    sortingPosition?: number;
}
export interface IGridState {
    [key: string]: IColumnState
}
export interface IColorStep {
    color: DataType.Color;
    value: number;
}
// interface IColorizationState {
//     from: DataType.Color;
//     to: DataType.Color;
//     min: number;
//     max: number;
// };

// function SelectButton<TRow>({ row, onClick }: { row: TRow, onClick: (row: TRow) => void }) {
//     const handleClick = () => onClick(row);
//     return <Tooltip title="Select"><IconButton
//         size="small"
//         style={{ verticalAlign: "middle" }}
//         onClick={handleClick} >
//         <MoreVertIcon fontSize="small" />
//     </IconButton>
//     </Tooltip >;
// }

function DeleteButton<TRow>({ row, onClick }: { row: TRow, onClick: (row: TRow) => void }) {
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = React.useCallback((e) => {
        e.preventDefault();
        onClick(row);
    }, [onClick, row]);
    return <Tooltip title="Delete">
        <IconButton
            size="small"
            style={{ verticalAlign: "middle" }}
            onClick={handleClick}>
            <DeleteIcon fontSize="small" />
        </IconButton>
    </Tooltip>;
}


function useExtendedGridState(defaultState: IStoreGridState, stateLess: boolean | undefined): [IStoreGridState, (state: Partial<IStoreGridState>) => void] {
    const [gridStateLocal, setGridStateLocal] = React.useState(defaultState ?? {})
    const { setGridState: setGridStateRedux } = useReduxActions("app");
    const { gridStates } = useReduxSelections("app");
    const screenKey = useScreenKey();
    const tabKey: string = useTab() ?? "";
    if (!screenKey || stateLess) {
        return [gridStateLocal, (partialState: Partial<IStoreGridState>) => {
            const newGridStateLocal = { ...gridStateLocal, ...partialState } as IStoreGridState;
            setGridStateLocal(newGridStateLocal);
        }];
    }
    const reduxGridState = (function () {
        const screen = gridStates[screenKey];
        if (screen) {
            return screen[tabKey] ?? defaultState ?? {};
        }
        else {
            return defaultState;
        }
    })();
    return [reduxGridState, (partialState: Partial<IStoreGridState>) => {
        const newGridStateRedux = { ...reduxGridState, ...partialState } as IStoreGridState;
        setGridStateRedux({
            screenKey,
            sectionKey: tabKey,
            state: newGridStateRedux
        });
    }];
    // if (!reduxGridState) {
    //         return [gridStateLocal, (partialState: Partial<IStoreGridState>) => {
    //             // this is not supposed to happen
    //             const newGridStateLocal = { ...gridStateLocal, ...partialState } as IStoreGridState;
    //             setGridStateLocal(newGridStateLocal);
    //         }];
    //     }
    //     else {
    //         return [reduxGridState, (partialState: Partial<IStoreGridState>) => {
    //             const newGridStateRedux = { ...reduxGridState, ...partialState } as IStoreGridState;
    //             setGridStateRedux({
    //                 screenKey,
    //                 sectionKey,
    //                 state: newGridStateRedux
    //             });
    //         }];
    //     }
}
function pairValues<T>(values: T[]) {
    let previousValue: T | undefined;
    const toReturn: [T, T][] = [];
    for (const value of values) {
        if (previousValue) {
            toReturn.push([previousValue, value]);
        }
        previousValue = value;
    }
    return toReturn;
}
function isBetween(value: number, a: number, b: number) {
    return (value >= a && value <= b) || (value >= b && value <= a);
}
export default function ExtendedGrid<TRow>({
    selectedRows,
    getRowId,
    onRowClick,
    onRowDelete,
    getCellBackground,
    getCellForeground,
    getCellTooltip,
    onExpandedRowIds,
    getChildRows,
    columns: inputColumns,
    rows,
    initialState,
    userCanGroup,
    defaultColumnWidth,
    defaultExportFileName,
    expandedRowIds,
    treeColumnName,
    useMultilineHeader,
    onCellValueChanged,
    detailComponent,
    expandedDetailRowIds,
    onExpandedDetailRowIdsChange,
    multiSelect = false,
    stateLess,
    onSelectionChanged,
    showSelectionCheckbox = false
}: IExtendedGridProps<TRow>) {

    const { storeGridState: defaultState, gridSetup } = React.useMemo(() => {
        const columns = [...inputColumns].filter(i => !!i?.name);
        if (onRowDelete) {
            columns.unshift({
                name: "ActionsColumn",
                title: "Actions",
                getCellValue: (row: TRow) =>
                    <ButtonGroup size="small" variant="text">
                        {onRowDelete && <DeleteButton row={row} onClick={onRowDelete} />}
                    </ButtonGroup>,
                positionToKeep: "left",
            } as IColumnDefinition);
        }

        const columnDefinitions: Column[] = columns.map(cd => ({
            name: cd.name,
            title: cd.title,
            getCellValue: (row: any, columnName: string) => {
                if (!cd.getCellValue) {
                    return null;
                }
                try {
                    return cd.getCellValue(row, columnName);
                }
                catch (e) {
                    if (typeof e === "string") {
                        console.error(e);
                    } else if (e instanceof Error) {
                        console.error(e.message);
                    }
                    return null;
                }
            },
        }));

        const defaultOrder = columns
            .sort((i, j) => ((initialState[i.name] && initialState[i.name].columnPosition) || 0) - ((initialState[j.name] && initialState[j.name].columnPosition) || 0))
            .map(i => i.name);
        const defaultHiddenColumnNames = columns
            .filter(i => initialState[i.name] && initialState[i.name].hidden)
            .map(i => i.name);
        const defaultGrouping: Grouping[] = columns
            .filter(i => initialState[i.name] && initialState[i.name].groupingPosition)
            .sort((i, j) => ((initialState[i.name] && initialState[i.name].groupingPosition) || 0) - ((initialState[j.name] && initialState[j.name].groupingPosition) || 0))
            .map(i => ({ columnName: i.name }));

        const defaultSorting: Sorting[] = columns
            .filter(i => initialState[i.name] && initialState[i.name].sortingPosition)
            .map(i => ({ ...i, sortingPosition: (initialState[i.name] && initialState[i.name].sortingPosition) || 0 }))
            .sort((i, j) => Math.abs(i.sortingPosition) - Math.abs(j.sortingPosition))
            .map(i => ({ columnName: i.name, direction: i.sortingPosition > 0 ? "asc" : "desc" }));

        const defaultColumnWidths: TableColumnWidthInfo[] = columnDefinitions
            .map(i => ({
                columnName: i.name,
                width: i.name === "SelectColumn" ? 60 : (initialState[i.name]?.width || defaultColumnWidth || 130)
            }));
        for (const co in initialState) {
            if (Object.prototype.hasOwnProperty.call(initialState, co)) {
                const element = initialState[co];
                if (defaultColumnWidths.findIndex(i => i.columnName === co) < 0) {
                    defaultColumnWidths.push({
                        columnName: co,
                        width: element.width || 130
                    });
                }
            }
        }

        const columnExtension: GridColumnExtension[] = columns
            .filter(i => i.wordWrapEnabled || i.align || i.columnType === "integer" || i.columnType === "preciseDecimal" || i.columnType === "decimal" || i.columnType === "percentage" || i.columnType === "precisePercentage2" || i.columnType === "precisePercentage4" || i.columnType === "currency")
            .map(i => ({
                columnName: i.name,
                align: i.align || ((i.columnType === "integer" || i.columnType === "decimal" || i.columnType === "preciseDecimal" || i.columnType === "percentage" || i.columnType === "precisePercentage2" || i.columnType === "precisePercentage4") ? "right" : i.columnType === "boolean" ? "center" : undefined),
                wordWrapEnabled: i.wordWrapEnabled,
                test: false,
            }));
        const backgroundColorizationColumns = columns
            .filter(i => i.backgroundColor)
            .map(i => ({
                columnName: i.name,
                colorization: i.backgroundColor
            }));
        const foregroundColorizationColumns = columns
            .filter(i => i.foregroundColor)
            .map(i => ({
                columnName: i.name,
                colorization: i.foregroundColor
            }));
        const adornmentColumns = columns
            .filter(i => i.getAdornment)
            .map(i => ({
                columnName: i.name,
                getAdornment: i.getAdornment
            }));
        const editableColumns = columns.filter(i => !!i.editable).map(i => i.name);
        const groupSummaryItems: SummaryItem[] = columns
            .filter(i => i.aggregationType)
            .map(i => ({
                columnName: i.name,
                type: i.aggregationType as string
            }));
        const showSummaries = groupSummaryItems.length > 0;

        const canFilter = columns.filter(({ filteringEnabled }) => filteringEnabled ?? true).length > 0;
        const filteringColumnExtensions = columns.map(({ name: columnName, filteringEnabled }) => ({
            columnName,
            filteringEnabled: !!filteringEnabled
        } as FilteringState.ColumnExtension))

        const columnTypes = columns
            .filter(i => i.columnType && i.columnType !== "text")
            .reduce((acc, v) => {
                if (v.columnType) {
                    let cols = acc[v.columnType] || [];
                    cols = [...cols, v.name];
                    acc[v.columnType] = cols;
                }
                return acc;
            }, {} as Record<ColumnType, string[]>);
        const lc = columns.filter(i => i.positionToKeep === "left").map(i => i.name);
        const rc = columns.filter(i => i.positionToKeep === "right").map(i => i.name);

        const leftColumns = lc.length > 0 ? lc : undefined;
        const rightColumns = rc.length > 0 ? rc : undefined;

        const gridSetup = {
            columnExtension,
            backgroundColorizationColumns,
            foregroundColorizationColumns,
            adornmentColumns,
            editableColumns,
            showSummaries,
            canFilter,
            filteringColumnExtensions,
            columnTypes,
            leftColumns,
            rightColumns,
            groupSummaryItems,
            columnDefinitions
        };

        const storeGridState: IStoreGridState = {
            columnOrder: defaultOrder,
            columnWidths: defaultColumnWidths,
            expandedDetails: expandedDetailRowIds,
            expandedRows: expandedRowIds,
            filters: [],
            grouping: defaultGrouping,
            hiddenColumn: defaultHiddenColumnNames,
            sorting: defaultSorting
        };
        return { storeGridState, gridSetup };
    },
        [inputColumns, defaultColumnWidth, expandedDetailRowIds, expandedRowIds, onRowDelete, initialState]);

    const [gridState, setGridState] = useExtendedGridState(defaultState, stateLess);

    function getColor(getBackgroundColor: ColorDefinition, row: any, value: any) {
        if (Array.isArray(getBackgroundColor)) {
            const pairs = pairValues([...getBackgroundColor].sort((a, b) => a.value - b.value));
            let colorizationState = pairs.find(([a, b]) => isBetween(value, a.value, b.value));
            if (!colorizationState && pairs.length) {
                if (pairs[0][0].value > value) return pairs[0][0].color;
                else return pairs.at(-1)![1].color;
            }
            if (colorizationState) {
                const distance = colorizationState[1].value - colorizationState[0].value;
                return findColorBetween(colorizationState[0].color, colorizationState[1].color, (value - colorizationState[0].value) / distance);
            }
        }
        else if (typeof (getBackgroundColor) === "function") {
            const color = getBackgroundColor(row);
            if (color) {
                return color
            }
        }
        return;
    }
    function SmallCell({ style, ...restProps }: Table.DataCellProps & { style?: React.CSSProperties }) {
        const oldStyle = style ?? {};
        const { column, value, row, tableColumn } = restProps;
        if (!column) {
            return null;
        }
        const isPercentage = (gridSetup.columnTypes["percentage"] && gridSetup.columnTypes["percentage"].indexOf(column.name) >= 0)
            || (gridSetup.columnTypes["precisePercentage2"] && gridSetup.columnTypes["precisePercentage2"].indexOf(column.name) >= 0)
            || (gridSetup.columnTypes["precisePercentage4"] && gridSetup.columnTypes["precisePercentage4"].indexOf(column.name) >= 0);
        // foregroundColorizationColumns
        const getBackgroundColor = gridSetup.backgroundColorizationColumns.find(({ columnName }) => columnName === column.name)?.colorization;
        const getForegroundColor = gridSetup.foregroundColorizationColumns.find(({ columnName }) => columnName === column.name)?.colorization;
        const background = getCellBackground && getCellBackground(row, column.name);
        const foreground = getCellForeground && getCellForeground(row, column.name);
        const tooltip = getCellTooltip ? getCellTooltip(row, column.name) : null;
        const tooltipProps = tooltip ? { title: tooltip } : {}
        const styleObj: React.CSSProperties = { paddingTop: 6, paddingBottom: 6, background, color: foreground };
        const getAdornment = gridSetup.adornmentColumns.find(({ columnName }) => columnName === column.name)?.getAdornment;
        // const isPercentageColumns = [...gridSetup.columnTypes["percentage"], ...gridSetup.columnTypes["precisePercentage2"], ...gridSetup.columnTypes["precisePercentage4"]].includes(column.name);
        const backgroundColor = (getBackgroundColor && typeof (value) !== "undefined" && value !== null) ? getColor(getBackgroundColor, row, value) : undefined;
        if (backgroundColor && !isPercentage) {
            styleObj.background = backgroundColor;
        }
        if (getForegroundColor && typeof (value) !== "undefined" && value !== null) {
            styleObj.color = getColor(getForegroundColor, row, value);
        }
        const message = () => <></>;
        if (!!getAdornment) {
            restProps.value = <>{value}{getAdornment(row)}</>;
        }
        if (isPercentage) {
            return <VirtualTable.Cell {...restProps} {...tooltipProps} style={{ ...oldStyle, ...styleObj, padding: 0, margin: 0, paddingTop: 0, paddingBottom: 0 }} >
                <div style={{ width: `${value * 100}%`, backgroundColor: backgroundColor ?? "lightgray", textAlign: "center" }}>{restProps.children}</div>
            </VirtualTable.Cell>
        }
        else if (typeof (restProps.value) !== "undefined" && restProps.value !== null) {
            return <VirtualTable.Cell {...restProps} {...tooltipProps} style={{ ...oldStyle, ...styleObj }} />
        }
        else {
            return <VirtualTable.NoDataCell {...restProps} {...tooltipProps} style={{ ...oldStyle, ...styleObj, textAlign: tableColumn.align }} getMessage={message} />
        }
    }
    const [editingCells, setEditingCells] = React.useState<EditingCell[]>([]);
    const handleEditingCellsChange = (elts: EditingCell[]) => {
        setEditingCells(elts.filter((elt) => gridSetup.editableColumns.indexOf(elt.columnName) >= 0));
    };
    const handleCommitChanges = React.useCallback(({ changed }: ChangeSet) => {
        if (!onCellValueChanged) {
            return;
        }
        if (!changed) {
            return;
        }
        const rowId = Object.keys(changed)[0];
        if (typeof rowId === "undefined") {
            return;
        }
        const row = changed[rowId];
        if (!row) {
            return;
        }
        const changedColumn = Object.keys(row)[0];
        if (typeof changedColumn === "undefined") {
            return;
        }
        const columnValue = row[changedColumn] as string;

        onCellValueChanged(rowId, changedColumn, columnValue);
    }, [onCellValueChanged])

    // React.Component<TableRowDetailBase.ContentProps>|React.
    // ComponentClass<P> | FunctionComponent<P>
    // let tmp:React.ComponentType<TableRowDetail.ContentProps>;

    const handleSortingChange = React.useCallback((sorting: IStoreGridState["sorting"]) => setGridState({ sorting }), [setGridState]);
    const handleExpandedTreeRowIdChange = React.useCallback((expandedRows: IStoreGridState["expandedRows"]) => {
        setGridState({ expandedRows });
        if (onExpandedRowIds && expandedRows) {
            onExpandedRowIds(expandedRows);
        }
    }, [onExpandedRowIds, setGridState])
    const handleExpandedDetailRowIdChange = React.useCallback((expandedDetails: IStoreGridState["expandedDetails"]) => {
        setGridState({ expandedDetails });
        if (onExpandedRowIds && expandedDetails) {
            onExpandedRowIds(expandedDetails);
        }
    }, [onExpandedRowIds, setGridState])
    const handleFiltersChange = React.useCallback((filters: IStoreGridState["filters"]) => setGridState({ filters }), [setGridState]);
    const handleGroupingChange = React.useCallback((grouping: IStoreGridState["grouping"]) => setGridState({ grouping }), [setGridState]);
    const handleExpandedGroupsChange = React.useCallback((expandedGroups: IStoreGridState["expandedGroups"]) => {
        setGridState({ expandedGroups });
        if (onExpandedDetailRowIdsChange && expandedGroups) {
            onExpandedDetailRowIdsChange(expandedGroups);
        }
    }, [onExpandedDetailRowIdsChange, setGridState]);
    const handleColumnWidthsChange = React.useCallback((columnWidths: IStoreGridState["columnWidths"]) => setGridState({ columnWidths }), [setGridState]);
    const handleOrderChange = React.useCallback((columnOrder: IStoreGridState["columnOrder"]) => setGridState({ columnOrder }), [setGridState]);
    const handleHiddenColumnNamesChange = React.useCallback((hiddenColumn: IStoreGridState["hiddenColumn"]) => setGridState({ hiddenColumn }), [setGridState]);
    const [selectedId, setSelectedId] = React.useState<Array<number | string>>([]);
    const controlledSelectedId = React.useMemo(() => {
        if (!selectedRows) {
            return undefined;
        }
        else {
            return selectedRows.map(getRowId);
        }
    }, [selectedRows, getRowId]);
    const handleSelect = React.useCallback((selection: Array<number | string>) => {
        if (onSelectionChanged) {
            if (multiSelect) {
                setSelectedId(selection);
                const selected = toDictionary(selection, i => i, () => true);
                onSelectionChanged(rows.filter(row => selected[getRowId(row)]));
            }
            else {
                const newSelection = selection.at(-1);
                if (newSelection) {
                    setSelectedId([newSelection]);
                    const selectedRow = rows.find(row => getRowId(row) === newSelection);
                    selectedRow && onSelectionChanged([selectedRow]);

                }
                else {
                    setSelectedId([]);
                    onSelectionChanged([]);
                }
            }
        }
        else if (onRowClick) {
            const newSelection = selection.at(-1);
            if (newSelection) {
                setSelectedId([newSelection]);
                const selectedRow = rows.find(row => getRowId(row) === newSelection);
                selectedRow && onRowClick(selectedRow);
            }
            else {
                if (selectedId.length) {
                    const selectedRow = rows.find(row => getRowId(row) === selectedId[0]);
                    selectedRow && onRowClick(selectedRow);
                }
            }
        }
    }, [getRowId, multiSelect, onRowClick, onSelectionChanged, rows, selectedId])

    // Filtering, Sorting, Grouping for ButtonLinks
    const integratedColumnExtensions = React.useMemo(() => inputColumns
        .filter(i => i.columnType === "link")
        .map(i => ({
            columnName: i.name,
            predicate: referenceTextFilteringPredicate,
            compare: referenceLinkCompare,
            criteria: referenceLinkGroupingCriteria
        })), [inputColumns]);
    if (!gridState) {
        return null;
    }

    // @ts-ignore FIXME upgrade to 3.0.6
    return <Grid
        rows={rows}
        columns={gridSetup.columnDefinitions}
        getRowId={getRowId}
        rootComponent={Root}>
        {defaultExportFileName && <ToolbarExportState />}
        <DragDropProvider />
        <SortingState sorting={gridState.sorting} onSortingChange={handleSortingChange} />
        {getChildRows && <TreeDataState expandedRowIds={gridState.expandedRows} onExpandedRowIdsChange={handleExpandedTreeRowIdChange} />}
        {(!!gridSetup.editableColumns.length && onCellValueChanged) && <EditingState
            onCommitChanges={handleCommitChanges}
            editingCells={editingCells}
            onEditingCellsChange={handleEditingCellsChange} />}
        <IntegratedSorting columnExtensions={integratedColumnExtensions} />
        {detailComponent && <RowDetailState expandedRowIds={gridState.expandedDetails} onExpandedRowIdsChange={handleExpandedDetailRowIdChange} />}
        {gridSetup.canFilter && <FilteringState columnExtensions={gridSetup.filteringColumnExtensions} filters={gridState.filters} onFiltersChange={handleFiltersChange} />}
        {gridSetup.canFilter && <IntegratedFiltering columnExtensions={integratedColumnExtensions} />}
        {userCanGroup && <GroupingState grouping={gridState.grouping} onGroupingChange={handleGroupingChange} expandedGroups={gridState.expandedGroups} onExpandedGroupsChange={handleExpandedGroupsChange} />}
        {gridSetup.showSummaries && <SummaryState groupItems={gridSetup.groupSummaryItems} totalItems={[]} />}
        {userCanGroup && <IntegratedGrouping columnExtensions={integratedColumnExtensions} />}
        {gridSetup.showSummaries && <IntegratedSummary />}
        {gridSetup.columnTypes.boolean && <BooleanTypeProvider for={gridSetup.columnTypes.boolean} />}
        {gridSetup.columnTypes.percentage && <PercentTypeProvider for={gridSetup.columnTypes.percentage} />}
        {gridSetup.columnTypes.precisePercentage2 && <PrecisePercentTypeProvider for={gridSetup.columnTypes.precisePercentage2} />}
        {gridSetup.columnTypes.precisePercentage4 && <PrecisePercent4TypeProvider for={gridSetup.columnTypes.precisePercentage4} />}
        {gridSetup.columnTypes.date && <DateTypeProvider for={gridSetup.columnTypes.date} />}
        {gridSetup.columnTypes.dateTime && <DateTimeTypeProvider for={gridSetup.columnTypes.dateTime} />}
        {gridSetup.columnTypes.decimal && <DecimalTypeProvider for={gridSetup.columnTypes.decimal} />}
        {gridSetup.columnTypes.integer && <IntegerTypeProvider for={gridSetup.columnTypes.integer} />}
        {gridSetup.columnTypes.preciseDecimal && <PreciseDecimalTypeProvider for={gridSetup.columnTypes.preciseDecimal} />}
        {gridSetup.columnTypes.currency && <CurrencyTypeProvider for={gridSetup.columnTypes.currency} />}
        {getChildRows && <CustomTreeData getChildRows={getChildRows} />}
        <VirtualTable height="auto" columnExtensions={gridSetup.columnExtension} cellComponent={SmallCell} />
        {detailComponent && <TableRowDetail contentComponent={detailComponent} />}
        <TableColumnResizing columnWidths={gridState.columnWidths} onColumnWidthsChange={handleColumnWidthsChange} />
        {useMultilineHeader
            ? <TableHeaderRow cellComponent={TableMultilineHeaderCellBase} showSortingControls={true} showGroupingControls={userCanGroup} />
            : <TableHeaderRow showSortingControls={true} showGroupingControls={userCanGroup} />}
        {getChildRows && treeColumnName && <TableTreeColumn for={treeColumnName} />}
        {gridSetup.canFilter && <TableFilterRow showFilterSelector={true} />}
        <SelectionState onSelectionChange={handleSelect} selection={controlledSelectedId ?? selectedId} />
        <TableSelection
            selectByRowClick={!onRowDelete}
            highlightRow
            showSelectionColumn={showSelectionCheckbox || ((!!onSelectionChanged || !!onRowClick) && !!onRowDelete)} />
        {userCanGroup && <TableGroupRow />}
        {gridSetup.showSummaries && <TableSummaryRow />}
        <TableColumnReordering order={gridState.columnOrder} onOrderChange={handleOrderChange} />
        <TableColumnVisibility hiddenColumnNames={gridState.hiddenColumn} onHiddenColumnNamesChange={handleHiddenColumnNamesChange} />
        <Toolbar />
        {defaultExportFileName && <ToolbarExport defaultFileName={defaultExportFileName} />}
        {userCanGroup && <GroupingPanel showGroupingControls />}
        <ColumnChooser />
        {(gridSetup.rightColumns || gridSetup.leftColumns) && <TableFixedColumns leftColumns={gridSetup.leftColumns} rightColumns={gridSetup.rightColumns} />}
        {(!!gridSetup.editableColumns.length && onCellValueChanged) && <TableInlineCellEditing startEditAction="doubleClick" cellComponent={TableEditCell} />}
    </Grid>;
}
