import { EntitySummaryModel, PortfolioSummaryModel, ProcessExecutionModel, ProcessExecutionSummaryModel, RelationshipSummaryModel, SecuritySummaryModel } from "proxy/apiProxy";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";
import { getPortfolioTypeLabel } from "features/ManagedPortfolio/getPortfolioTypeLabel";
import { getEntityTypeLabel } from "features/Entity/getEntityTypeLabel";
import { getRelationshipTypeLabel } from "features/Relationship/getRelationshipTypeLabel";

export function getProcessExecutionTypeLabel(row: (ProcessExecutionSummaryModel | ProcessExecutionModel), dictionaries: {
    entities: Record<EntitySummaryModel["id"], EntitySummaryModel>;
    relationships: Record<string | number, RelationshipSummaryModel>;
    portfolios?: Record<string | number, PortfolioSummaryModel>;
    securities?: Record<string | number, SecuritySummaryModel>;
}) {
    switch (row.type) {
        case "PortfolioProcessExecutionModel":
        case "PortfolioProcessExecutionSummaryModel":
            const portfolio = (dictionaries.portfolios ?? {})[row.portfolioId ?? 0];
            return getPortfolioTypeLabel(portfolio?.type);
        case "SecurityProcessExecutionModel":
        case "SecurityProcessExecutionSummaryModel":
            const security = (dictionaries.securities ?? {})[row.securityId ?? 0];
            return getSecurityTypeLabel(security?.type);
        case "EntityProcessExecutionModel":
        case "EntityProcessExecutionSummaryModel":
            const entity = dictionaries.entities[row.entityId ?? 0];
            return getEntityTypeLabel(entity?.type);
        case "RelationshipProcessExecutionModel":
        case "RelationshipProcessExecutionSummaryModel":
            const relationship = dictionaries.relationships[row.relationshipId ?? 0];
            return getRelationshipTypeLabel(relationship?.type);
    }
}
