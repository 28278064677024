import React from 'react';
// import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { FormControlLabel, Switch } from '@material-ui/core';
import { useFieldEx, IFormField } from 'tools/lib/fieldHelpers';
import { ReadOnlyContext } from "./ReadOnlyContext";


// tslint:disable-next-line: no-empty-interface
export interface IFormSwitchFieldProps extends IFormField {

}

export default function FormSwitchField({ name, helperText, label, disabled, error, ...requestedValidations }: IFormSwitchFieldProps) {
    const checkDirty = (o1: (boolean | undefined) = false, o2: (boolean | undefined) = false) => (!!o1) !== (!!o2);

    const {
        // error: fieldError, 
        // helperText: fieldHelperText, 
        label: fieldLabel, onValueChanged, value } = useFieldEx<boolean, {}>({ name, helperText, label, error, checkDirty });
    const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => onValueChanged(checked);
    return <ReadOnlyContext.Consumer>
        {readOnly => <FormControlLabel control={<Switch
            readOnly={readOnly}
            id={name}
            disabled={disabled || readOnly}
            checked={!!value}
            onChange={onChange} />}
            label={fieldLabel} />}
    </ReadOnlyContext.Consumer>
}
