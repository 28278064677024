import * as React from "react";
import autobind from "autobind-decorator";
import Select, { SelectProps } from "@material-ui/core/Select";
import { MenuItem, ListItemText } from "@material-ui/core";
import { IDictionary } from "tools/lib/utility";

interface IOption {
    key: string;
    primaryContent: React.ReactNode;
    secondaryContent?: React.ReactNode;
    value: string | number | undefined;
}

export interface ISimpleSelectProps extends Omit<SelectProps, "value"> {
    options: IDictionary<any> | any[];
    value: string | number | undefined;
    getValue?: (row: any) => number;
    getPrimaryContent?: (row: any) => React.ReactNode;
    getSecondaryContent?: (row: any) => React.ReactNode;
    onSelectionChanged?: (value: string | number | undefined) => void;
    required?: boolean;
}

@autobind
class SimpleSelect extends React.Component<ISimpleSelectProps> {
    public render() {
        const { onChange, value, options: o, getValue, getPrimaryContent, getSecondaryContent, onSelectionChanged, ...props } = this.props;

        const options = this.tryAddEmpty(this.getOptions());
        const handleRenderValue = (v: unknown): React.ReactNode => {
            if (typeof v === "undefined") {
                return;
            }
            const option = options.find(i => i.key === v);
            if (option) {
                return option.primaryContent;
            }
            return;
        };
        const renderOption = (option: IOption) => {
            if (typeof option.value !== "undefined") {
                if (option.value === value) {
                    return <ListItemText primary={<b>{option.primaryContent}</b>} secondary={option.secondaryContent} />;
                }
                else {
                    return <ListItemText primary={option.primaryContent} secondary={option.secondaryContent} />;
                }
            }
            else {
                if (typeof value === "undefined") {
                    return <em><b>None</b></em>;
                }
                else {
                    return <em>None</em>;
                }
            }
        }
        return <Select
            {...props}
            value={typeof (value) === "undefined" ? "" : typeof (value) === "number" ? String(value) : (value ?? "")}
            onChange={this.handleChange}
            renderValue={handleRenderValue}
        >
            {options.map(option => <MenuItem key={option.key} value={option.key}>
                {renderOption(option)}
            </MenuItem>)}
        </Select>;
    }
    private tryAddEmpty(options: IOption[]): IOption[] {
        const { required = false } = this.props;
        if (!required) {
            options.unshift({
                key: "",
                primaryContent: undefined,
                value: undefined
            });
        }
        return options;
    }
    private getOptions(): IOption[] {
        const { options, getValue, getPrimaryContent, getSecondaryContent } = this.props;
        if (!options) {
            return [];
        }
        if (Array.isArray(options)) {
            return options.map(option => {
                const value = getValue ? getValue(option) : option.id;
                const key = String(value);
                return {
                    key,
                    primaryContent: getContent(option),
                    secondaryContent: getSecondaryContent ? getSecondaryContent(option) : undefined,
                    value,
                } as IOption;
            })
        }
        return Object.keys(options).map(key => {
            const parsedValue = Number(key);
            const value = isNaN(parsedValue) ? key : parsedValue;
            return ({
                key: String(key),
                primaryContent: getContent(options[key]),
                secondaryContent: getSecondaryContent ? getSecondaryContent(options[key]) : undefined,
                value
            } as IOption)
        });
        function getContent(option: any) {
            return getPrimaryContent ? getPrimaryContent(option) : typeof option === "string" ? option : option.name;
        }
    }
    private handleChange(event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) {
        const { onChange, onSelectionChanged } = this.props;
        if (onChange) {
            onChange(event, child);
        }
        if (onSelectionChanged) {
            const value = event.target.value as (string | number);
            if (value === "" || typeof value === "undefined") {
                onSelectionChanged(undefined);
            }
            else {
                const valueAsNumber = Number(value);
                if (isNaN(valueAsNumber)) {
                    onSelectionChanged(value);
                }
                else {
                    onSelectionChanged(valueAsNumber);
                }
            }
        }
    }
}

export default SimpleSelect;
