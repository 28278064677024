import produce from "immer";
import { IClassificationTypeModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import getFlatClassificationTypes, { IFlatClassificationTypes } from "./getFlatClassificationTypes";
export interface IState {
    classificationTypeLoading: boolean;
    classificationTypeSaving: boolean;
    classificationTypesLoading: boolean;
    classificationTypes: IClassificationTypeModel[];
    classificationType?: IClassificationTypeModel;
    classificationTypesFlat: IFlatClassificationTypes;
}

export const ActionFactories = produceActionFactories({
    roleRelationshipClassificationTypeLoadAll: () => undefined,
    roleRelationshipClassificationTypeAllLoaded: (payload: IClassificationTypeModel[]) => payload,
    roleRelationshipClassificationTypeLoad: (payload: number) => payload,
    roleRelationshipClassificationTypeLoaded: (payload: IClassificationTypeModel) => payload,
    roleRelationshipClassificationTypeSave: (payload: IClassificationTypeModel) => payload,
    roleRelationshipClassificationTypeSaved: (payload: IClassificationTypeModel) => payload,
    roleRelationshipClassificationTypeDelete: (payload: number) => payload,
    roleRelationshipClassificationTypeDeleted: (payload: number) => payload,
});

export function reducer(
    state: IState = {
        classificationTypesLoading: false,
        classificationTypeLoading: false,
        classificationTypeSaving: false,
        classificationTypes: [],
        classificationTypesFlat: {}
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "roleRelationshipClassificationTypeSave":
                draft.classificationTypeSaving = true;
                break;
            case "roleRelationshipClassificationTypeLoadAll":
                draft.classificationTypesLoading = true;
                break;
            case "roleRelationshipClassificationTypeLoad":
                draft.classificationTypeLoading = true;
                draft.classificationType = undefined;
                break;
            case "roleRelationshipClassificationTypeAllLoaded":
                draft.classificationTypesLoading = false;
                draft.classificationTypes = action.payload;
                draft.classificationTypesFlat = getFlatClassificationTypes(action.payload);
                break;
            case "roleRelationshipClassificationTypeLoaded":
                draft.classificationTypeLoading = false;
                draft.classificationType = action.payload;
                break;
            case "roleRelationshipClassificationTypeSaved":
                const saved = action.payload;
                draft.classificationTypeSaving = false;
                draft.classificationType = saved;
                break;
            case "roleRelationshipClassificationTypeDeleted":
                const deletedId = action.payload;
                const idx = draft.classificationTypes.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.classificationTypes.splice(idx, 1);
                }
                break;
        }
    });
}
