import { IIndexModel, IMarketDataProviderModel, IClassificationTypeModel } from "proxy/apiProxy";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FieldBox from "tools/components/FieldBox";
import { oProps } from "tools/lib/utility";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import ClassificationsData from "components/global/ClassificationsData";
import { CountrySelectField } from "components/searchers/CountrySelectField";

export interface IIndexDataProps {
    parametersMarketDataProviders: IMarketDataProviderModel[];
    classificationTypes: IClassificationTypeModel[];
}
export default function IndexData({
    parametersMarketDataProviders,
    classificationTypes
}: IIndexDataProps) {
    return <FieldBox display="flex" flexDirection="column">
        <Card >
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    Codes
                </Typography>
                <Grid container={true} spacing={1}>
                    <Grid item={true} sm={12} md={6} lg={4}>
                        <FormTextField name={oProps<IIndexModel>().path("internalCode")} label="Code" required={true} maxLength={100} />
                    </Grid>
                    {parametersMarketDataProviders.map(({ marketDataProviderCode, marketDataProviderName }) =>
                        <Grid item={true} sm={12} md={6} lg={4} key={marketDataProviderCode}>
                            <FormTextField name={oProps<IIndexModel>().path("dataProviderCodes", marketDataProviderCode)} label={marketDataProviderName} />
                        </Grid>)}
                </Grid>
            </CardContent>
        </Card>
        <Grid container={true} spacing={1}>
            <Grid item={true} sm={12} md={6} lg={4}>
                <FormTextField name={oProps<IIndexModel>().path("name")} label="Name" required={true} maxLength={250} />
            </Grid>
            <Grid item={true} sm={12} md={6} lg={4}>
                <CurrencySelectField name={oProps<IIndexModel>().path("referenceCurrencyId")} label="Currency" />
            </Grid>
            <Grid item={true} sm={12} md={6} lg={4}>
                <CountrySelectField name={oProps<IIndexModel>().path("referenceCountryId")} label="Country" />
            </Grid>
            <Grid item={true} sm={12} md={6} lg={4}>
                <FormCheckBoxField name={oProps<IIndexModel>().path("isCurrencyRiskFree")} label="Use As Ccy Risk Free" />
            </Grid>
            <Grid item={true} sm={12} md={6} lg={4}>
                <FormCheckBoxField name={oProps<IIndexModel>().path("isRateValues")} label="Rate Values" />
            </Grid>
            <Grid item={true} sm={12} md={6} lg={4}>
                <FormTextField name={oProps<IIndexModel>().path("dayCountConvention")} label="Day Count Convention" isNumber={true} />
            </Grid>
            <Grid item={true} sm={12} md={12} lg={12}>
                <ClassificationsData
                    panelTitle="Classifications"
                    fieldName={oProps<IIndexModel>().path("classifications")}
                    classificationTypes={classificationTypes} />
            </Grid>
        </Grid>
    </FieldBox>;
}
