import * as React from "react";
import { useEffect, useRef } from "react";
import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { CustomScreenModel, IFeatureModel } from "proxy/apiProxy";
import CustomScreenData, { CustomScreenFormValues } from "./CustomScreenData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { isNumber, oProps, useNumber } from "tools/lib/utility";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { getCustomScreenTypeLabel, isCustomScreenModelType } from "./getCustomScreenTypeLabel";
import MacroScriptEditor from "features/MacroScript/MacroScriptEditor";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { Box } from "@material-ui/core";
import { setLicenseKey } from "survey-core";
import { useScreenParams } from "tools/routing/screenRouteHooks";

export const customScreenScreen = {
    component: CustomScreenComponent,
    route: "/config/macros/customscreens/:id/:tab?" as const,
    tabs: {
        detail: "details",
        macro: "macro",
        template: "template"
    },
    label: "Custom Screen",
}

setLicenseKey("YjRhNWFkNWItNDMyMi00MGM0LTg3OGItYTZkZjc3MmRjMmYwOzE9MjAyNS0wNC0wMiwyPTIwMjUtMDQtMDI=");


function CustomScreenComponent() {
    const refToSurveyCreator = useRef(new SurveyCreator({
        showLogicTab: true,
        isAutoSave: true,
        showJSONEditorTab: true,
        showTranslationTab: true,
        showThemeTab: true
    }));
    const { customScreenCurrent, customScreenLoading, customScreenSaving, customScreenScriptCheckResult = { errors: [] }, customScreenScriptMetadata } = useReduxSelections("customScreen");
    const { referenceMacroScriptCategories = {} } = useReduxSelections("reference");
    const {
        customScreenSave,
        customScreenDelete,
        customScreenValidateScript,
        customScreenLoad,
        customScreenNew
    } = useReduxActions("customScreen")

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof customScreenScreen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (!customScreenCurrent && !customScreenLoading) {
            if (isNumber(idNum)) {
                customScreenLoad(idNum)
            } else if (id && isCustomScreenModelType(id)) {
                customScreenNew(id)
            }
        }
    }, [customScreenCurrent, customScreenLoad, customScreenLoading, customScreenNew, id, idNum]);

    const formValues = React.useMemo<CustomScreenFormValues | undefined>(() => {
        if (!customScreenCurrent) {
            return undefined;
        }
        const { templateContent, ...values } = customScreenCurrent;
        return {
            ...values,
            hasUserTemplate: !!templateContent,
        }

    }, [customScreenCurrent]);
    React.useEffect(() => {
        refToSurveyCreator.current.text = customScreenCurrent?.templateContent ?? "{}";
    }, [customScreenCurrent?.templateContent]);

    const handleDeleteClick = React.useCallback(() => {
        if (customScreenCurrent?.id) {
            customScreenDelete(customScreenCurrent.id);
        };
    }, [customScreenCurrent?.id, customScreenDelete]);
    const handleSubmit = React.useCallback(({ templateContent, hasUserTemplate, ...values }: CustomScreenFormValues, { setSubmitting }: FormikHelpers<CustomScreenFormValues>) => {
        customScreenSave({
            templateContent: hasUserTemplate ? refToSurveyCreator.current.text : undefined,
            ...values
        });
        setSubmitting(false);
    }, [customScreenSave])
    const handleScriptChanged = React.useCallback((script: string) => customScreenValidateScript(script), [customScreenValidateScript]);
    if (!formValues) {
        return <DetailPanelLoading tabNumber={3} hasSubTitle={false} />;
    }
    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;
    function renderForm({ submitForm, setFieldValue, values, dirty, isValid }: FormikProps<CustomScreenFormValues>) {
        const title = !!values.id ? values.name : "New Custom Screen";
        const { errors } = customScreenScriptCheckResult;
        const tabs: ISubMenuTabs<typeof customScreenScreen>[] | undefined = [{
            value: "detail",
            label: "Data",
        }];
        if (values.hasUserTemplate) {
            tabs.push({
                value: "template",
                label: "Screen design",
            });
            tabs.push({
                value: "macro",
                label: "Code Behind",
            });
        }

        const canSave = (dirty || refToSurveyCreator.current.text !== customScreenCurrent?.templateContent) && isValid
        const handleSaveClick = () => {
            if (canSave) {
                submitForm();
            }
        };
        return <WriteAccess value={IFeatureModel.CustomScreenWrite}>
            <DetailPanel
                isQuerying={customScreenLoading || customScreenSaving}
                tabs={tabs}
                title={title}
                subTitle={`${getCustomScreenTypeLabel(values.type)} Custom Screen`}
                badge={!values.id ? "new" : undefined}
                noPadding={tabValue === "macro" || tabValue === "template"}
                onSaveClick={handleSaveClick}
                canSave={canSave}
                saveAllowed={IFeatureModel.CustomScreenWrite}
                deleteAllowed={IFeatureModel.CustomScreenWrite}
                onDeleteClick={handleDeleteClick}
                canDelete={!!values.id}
                tabValue={tabValue}>
                {(tabValue === "detail") && <CustomScreenData referenceMacroScriptCategories={referenceMacroScriptCategories} />}
                {(tabValue === "macro" && values.hasUserTemplate) && <MacroScriptEditor field={oProps<CustomScreenModel>().path("afterCompleteMacro")} executing={false} onScriptChanged={handleScriptChanged} scriptErrors={errors} onSaveRequest={handleSaveClick} metadata={customScreenScriptMetadata} />}
                {(tabValue === "template" && values.hasUserTemplate) && <Box display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}>
                    <SurveyCreatorComponent creator={refToSurveyCreator.current} />
                </Box>}


                {/* <CustomScreenDesigner initialValue={formValues?.templateContent ?? "{}"} getterRef={templateGetterRef} /> */}
            </DetailPanel>
        </WriteAccess>
    }
}
