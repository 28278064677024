import produce from "immer";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IAccountBalanceModel, IGetAccountBalancesModel, PortfolioSummaryModel, EntitySummaryModel, IDailyDataSearchAccountBalancesParameters } from "proxy/apiProxy";
import { IGetPortfoliosSummary } from "../Reference/slice";

export const ActionFactories = produceActionFactories({
  accountBalancesSearch: (payload: IDailyDataSearchAccountBalancesParameters) => payload,
  accountBalancesLoaded: (payload: IGetAccountBalancesModel) => payload,
  accountBalancesAddPortfoliosToDictionary: (payload: IGetPortfoliosSummary) => payload
});

export interface IState {
  accountBalancesLoading: boolean;
  accountBalances: IAccountBalanceModel[];
  dictionaries: {
    entities: Record<string | number, EntitySummaryModel>;
    portfolios: Record<string | number, PortfolioSummaryModel>;
  }
}

export function reducer(
  state: IState = {
    accountBalancesLoading: false,
    accountBalances: [],
    dictionaries: {
      portfolios: {},
      entities: {}
    }
  },
  action: AnyActionOf<typeof ActionFactories>
): IState {
  return produce(state, draft => {
    switch (action.type) {
      case "accountBalancesSearch":
        draft.accountBalancesLoading = true;
        break;
      case "accountBalancesLoaded":
        draft.accountBalancesLoading = false;
        const { accountBalances, ...dictionaries } = action.payload;
        draft.dictionaries = dictionaries;
        draft.accountBalances = accountBalances;
        break;
      case "accountBalancesAddPortfoliosToDictionary":
        draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
        draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
        break;
    }
  });
}
