import * as React from "react";
import { IIndexSummaryModel } from "proxy/apiProxy";
import { useField } from 'formik';
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';

export interface IIndexSelectFieldProps {
    name: string;
    label?: React.ReactNode;
    required?: boolean;
    onSelected?: (found: IIndexSummaryModel) => void;
}
export function IndexSelectField({ name, label, required, onSelected }: IIndexSelectFieldProps) {
    const { referenceIndexSearch } = useReduxActions("reference");
    const { referenceIndexSearched, referenceIndexSearching } = useReduxSelections("reference");
    const validate = (indexId: number | undefined) => {
        if (!required) {
            return;
        }
        return (indexId ?? 0) === 0 ? "Required" : undefined;
    }
    const [, { value, error, initialValue }, { setValue }] = useField<number | undefined>({ name, validate });
    const getOptionLabel = (index: IIndexSummaryModel) => index.name;
    label = <><SearchIcon fontSize="small"/> {label ?? "Index"}: Type criteria to find an index {required ? "*" : ""}</>;
    if ((initialValue || null) !== (value || null)) {
        label = <b>{label}</b>;
    }
    const handleValueSelected = (index: IIndexSummaryModel) => {
        if (onSelected) {
            onSelected(index)
        }
        setValue(index.id);
    }
    return <ReadOnlyContext.Consumer>{readOnly => readOnly ? null : <AsyncSelect onSearchOptions={referenceIndexSearch} options={referenceIndexSearched}
        getOptionLabel={getOptionLabel} searching={referenceIndexSearching} label={label} onRequiredValueSelected={handleValueSelected} error={!!error} helperText={error} />}
    </ReadOnlyContext.Consumer>
}
