import { DevelopmentItemSummaryModel, DevelopmentItemModel, IDevelopmentItemTypeModel } from "proxy/apiProxy";

export function getDevelopmentItemTypeLabel(developmentItemType: DevelopmentItemModel["type"] | DevelopmentItemSummaryModel["type"] | IDevelopmentItemTypeModel): string {
    switch (developmentItemType) {
        // case IDevelopmentItemTypeModel.DashboardMacro:
        // case "DashboardMacroDevelopmentItemModel":
        // case "DashboardMacroDevelopmentItemSummaryModel":
        //     return "Dashboard Macro";
        case IDevelopmentItemTypeModel.EtlMacro:
        case "EtlMacroDevelopmentItemModel":
        case "EtlMacroDevelopmentItemSummaryModel":
            return "ETL Macro";
        // case IDevelopmentItemTypeModel.FileConnector:
        // case "FileConnectorDevelopmentItemModel":
        // case "FileConnectorDevelopmentItemSummaryModel":
        //     return "File Connector";
        // case IDevelopmentItemTypeModel.FileConsumerMacro:
        // case "FileConsumerMacroDevelopmentItemModel":
        // case "FileConsumerMacroDevelopmentItemSummaryModel":
        //     return "File Consumer";
        // case IDevelopmentItemTypeModel.FileProducerMacro:
        // case "FileProducerMacroDevelopmentItemModel":
        // case "FileProducerMacroDevelopmentItemSummaryModel":
        //     return "File Producer";
        // case IDevelopmentItemTypeModel.PipelineTask:
        // case "PipelineTaskDevelopmentItemModel":
        // case "PipelineTaskDevelopmentItemSummaryModel":
        //     return "Pipeline Task";
        case IDevelopmentItemTypeModel.Questionnaire:
        case "QuestionnaireDevelopmentItemModel":
        case "QuestionnaireDevelopmentItemSummaryModel":
            return "Questionnaire";
        case IDevelopmentItemTypeModel.ReportTemplate:
        case "ReportTemplateDevelopmentItemModel":
        case "ReportTemplateDevelopmentItemSummaryModel":
            return "Report Template";
        case IDevelopmentItemTypeModel.DashboardLayout:
        case "DashboardLayoutDevelopmentItemModel":
        case "DashboardLayoutDevelopmentItemSummaryModel":
            return "Dashboard Layout";
        case IDevelopmentItemTypeModel.DatasetMacro:
        case "DatasetMacroDevelopmentItemModel":
        case "DatasetMacroDevelopmentItemSummaryModel":
            return "Dataset";
    }
}
