import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, IReportBuildSetRequestModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IActionButton } from "tools/components/FabContainer";
import NotGranted from 'components/global/NotGranted';
import FolderDownloadIcon from "mdi-material-ui/FolderDownload"
import { oProps } from "tools/lib/utility";
import ScopeDescriptionData from "features/Report/ScopeDescriptionData";
import FormMultiSelectField from "tools/fieldComponents/FormMultiSelectField";
import { useLocalStorage } from "tools/lib/useLocalStorage";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import { useEffect, useMemo } from "react";

export const externalTemplateScreen = {
    route: "/config/reporting/externaltemplate" as const,
    component: ExternalTemplate,
    label: "External Template",
    keywords: "Export Empty Report External Template",
}

function ExternalTemplate() {
    const {
        externalTemplateBuildSetLoad,
        externalTemplateAnalyze,
        externalTemplateMetadataLoad
    } = useReduxActions("externalTemplate");
    const { metadataLoading, reportBuildSetLoading, metadata, reportTemplates = [], analysis } = useReduxSelections("externalTemplate");

    useEffect(() => {
        externalTemplateMetadataLoad()
    }, [externalTemplateMetadataLoad]);

    const isGranted = useGrants();
    const title = "Empty Report For External Edition";
    const [lastReportBuildSetRequestModel, setLastReportBuildSetRequestModel] = useLocalStorage<IReportBuildSetRequestModel>("lastReportBuildSetRequestModel");
    const request: IReportBuildSetRequestModel = useMemo(() => {
        const r = lastReportBuildSetRequestModel ?? {
            dictionaryUsage: [],
            scopeDescription: {
                subScopes: {},
                extraParameters: {},
                groupLabelExpression: "dummy"
            }
        };

        if (analysis) {
            r.dictionaryUsage = analysis?.dictionaryUsage ?? [];
            r.scopeDescription.subScopes = {};
            for (const { name: parameter } of analysis.parameters) {
                if (!r.scopeDescription.subScopes[parameter.replace("Id", "id")]) {
                    r.scopeDescription.subScopes[parameter.replace("Id", "id")] = {
                        filters: [{ path: "", values: [] }]
                    }
                }
            }
            r.templateReference = { reportTemplateId: analysis.reportTemplateId }
        }
        return { ...r };
    }, [analysis, lastReportBuildSetRequestModel]);
    if (!isGranted(IFeatureModel.ReportExecute)) {
        return <DetailPanel title={title}>
            <NotGranted />
        </DetailPanel>
    }
    const handleReportTemplateChanged = (id: number | string | undefined) => externalTemplateAnalyze(typeof (id) === "number" ? id : undefined);

    const handleSubmit = (model: IReportBuildSetRequestModel, { setSubmitting }: FormikHelpers<IReportBuildSetRequestModel>) => {
        setLastReportBuildSetRequestModel(model);
        externalTemplateBuildSetLoad(model);
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={request} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm, values: { dictionaryUsage, scopeDescription } }: FormikProps<IReportBuildSetRequestModel>) {
        const handleGetClick = () => {
            if (isValid)
                submitForm();
        }
        const extraActionButtons: IActionButton[] = [{
            icon: FolderDownloadIcon,
            label: "Get Empty Report & Data",
            onClick: handleGetClick,
            disabled: !isValid || reportBuildSetLoading || metadataLoading
        }];
        const handleGetValue = (i: string) => i;
        return <DetailPanel
            isQuerying={reportBuildSetLoading || metadataLoading}
            title={title}
            actions={extraActionButtons}>
            <FormSimpleSelectField name={oProps<IReportBuildSetRequestModel>().path("templateReference", "reportTemplateId")} label="Template" options={reportTemplates} onSelectionChanged={handleReportTemplateChanged} required={false} />
            <FormMultiSelectField name={oProps<IReportBuildSetRequestModel>().path("dictionaryUsage")} label="Dictionary Elements" options={metadata?.dictionaryUsage ?? []} getValue={handleGetValue} getPrimaryContent={handleGetValue} />
            <ScopeDescriptionData
                formFieldName={oProps<IReportBuildSetRequestModel>().path("scopeDescription")}
                parameterMetadatas={metadata?.parameters}
                hideGroupNameExpression={true} />
        </DetailPanel>
    }
}
