import produce from "immer";
import {
    DatasetResultModel, IGenerateDatasetModel, IDashboardLayoutDevelopmentItemModel, IExecuteModel, IExecuteEtlResultModel,
    IExecuteEtlDevelopmentModel, IProcessExecutionsGetProcessExistingExecutionQuestionnaireParameters,
    IProcessExecutionsSaveProcessExecutionFileParameters, IProcessExecutionsSaveProcessExecutionQuestionnaireResponseParameters,
    IQuestionnaireTaskStateModel
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IBase64File } from "tools/lib/utility";

export interface DashboardExecuteOnLoadPayload extends Omit<IGenerateDatasetModel, "datasetName" | "type"> {
    datasetName?: IGenerateDatasetModel["datasetName"];
    dashboardLayoutName: string;
}
export type IExecuteEtlDevelopment = Omit<IExecuteEtlDevelopmentModel, "inputFiles"> & {
    inputFiles?: Record<string | number, IBase64File>;
    save?: boolean;
};
export interface IExecuteEtlResult extends IExecuteEtlResultModel {
    etlName: string;
}
export interface IProcessExecutionTaskPayload {
    id: number;
    taskCode: string;
}
export const ActionFactories = produceActionFactories({
    genericDashboardLoad: (payload: DashboardExecuteOnLoadPayload) => payload,
    genericDashboardLayoutLoaded: (payload: IDashboardLayoutDevelopmentItemModel) => payload,
    genericDashboardDatasetLoad: (payload: Omit<IGenerateDatasetModel, "type">) => payload,
    genericDashboardDatasetLoaded: (payload: DatasetResultModel) => payload,

    genericDashboardEtlExecute: (payload: IExecuteEtlDevelopment) => payload,
    genericDashboardEtlExecuted: (payload: IExecuteEtlResult) => payload,

    genericDashboardProcessExecutionLoadFile: (payload: IProcessExecutionTaskPayload) => payload,
    genericDashboardProcessExecutionLoadedFile: (payload: IProcessExecutionTaskPayload) => payload,
    genericDashboardProcessExecutionSaveFile: (payload: IProcessExecutionsSaveProcessExecutionFileParameters) => payload,
    genericDashboardProcessExecutionSavedFile: (payload: IProcessExecutionTaskPayload) => payload,
    genericDashboardProcessExecutionFileDelete: (payload: IProcessExecutionTaskPayload) => payload,
    genericDashboardProcessExecutionFileDeleted: (payload: IProcessExecutionTaskPayload) => payload,

    genericDashboardProcessExecutionLoadQuestionnaire: (payload: IProcessExecutionsGetProcessExistingExecutionQuestionnaireParameters) => payload,
    genericDashboardProcessExecutionLoadedQuestionnaire: (payload: IProcessExecutionTaskPayload) => payload,
    genericDashboardProcessExecutionSaveQuestionnaire: (payload: IProcessExecutionsSaveProcessExecutionQuestionnaireResponseParameters) => payload,
    genericDashboardProcessExecutionSavedQuestionnaire: (payload: IProcessExecutionTaskPayload) => payload,
});

export interface IState {
    genericDashboardLayoutLoading: boolean;
    genericDashboardLayout?: IDashboardLayoutDevelopmentItemModel;
    genericDashboardDatasetResultLoading: boolean;
    genericDashboardDatasetResult?: DatasetResultModel;
    genericDashboardDatasetInput?: IExecuteModel;
    genericDashboardEltExecuting: Record<string, boolean>;
    genericDashboardProcessesTaskState: Record<number, Record<string, ITaskState>>;
}
export interface ITaskState {
    deleting?: boolean,
    loading?: boolean,
    saving?: boolean,
    questionnaireDetails?: IQuestionnaireTaskStateModel
}

export type Action = AnyActionOf<typeof ActionFactories>;

export const reducer = (
    state: IState = {
        genericDashboardLayoutLoading: false,
        genericDashboardDatasetResultLoading: false,
        genericDashboardEltExecuting: {},
        genericDashboardProcessesTaskState: {}
    },
    action: Action
) => produce(state, draft => {
    const setProcessTaskValue = <K extends keyof ITaskState, V extends ITaskState[K]>(processExecutionId: number, processExecutionTaskName: string, field: K, value: V) => {
        if (!draft.genericDashboardProcessesTaskState[processExecutionId]) {
            draft.genericDashboardProcessesTaskState[processExecutionId] = {};
        }
        draft.genericDashboardProcessesTaskState[processExecutionId][processExecutionTaskName][field] = value;
    }
    switch (action.type) {
        case "genericDashboardProcessExecutionLoadFile":
        case "genericDashboardProcessExecutionLoadQuestionnaire":
            setProcessTaskValue(action.payload.id, action.payload.taskCode, "loading", true);
            break;
        case "genericDashboardProcessExecutionFileDelete":
            setProcessTaskValue(action.payload.id, action.payload.taskCode, "deleting", true);
            break;
        case "genericDashboardProcessExecutionSaveQuestionnaire":
        case "genericDashboardProcessExecutionSaveFile":
            setProcessTaskValue(action.payload.id, action.payload.taskCode, "saving", true);
            break;
        case "genericDashboardProcessExecutionLoadedFile":
        case "genericDashboardProcessExecutionLoadedQuestionnaire":
            setProcessTaskValue(action.payload.id, action.payload.taskCode, "loading", false);
            break;
        case "genericDashboardProcessExecutionSavedFile":
        case "genericDashboardProcessExecutionSavedQuestionnaire":
            setProcessTaskValue(action.payload.id, action.payload.taskCode, "saving", false);
            break;
        case "genericDashboardProcessExecutionFileDeleted":
            setProcessTaskValue(action.payload.id, action.payload.taskCode, "deleting", false);
            break;
        case "genericDashboardLoad":
            draft.genericDashboardLayoutLoading = true;
            delete draft.genericDashboardLayout;
            delete draft.genericDashboardDatasetResult;
            delete draft.genericDashboardDatasetInput;
            break;
        case "genericDashboardDatasetLoad":
            draft.genericDashboardDatasetResultLoading = true;
            draft.genericDashboardDatasetInput = action.payload;
            break;
        case "genericDashboardLayoutLoaded":
            draft.genericDashboardLayoutLoading = false;
            draft.genericDashboardLayout = action.payload;
            break;
        case "genericDashboardDatasetLoaded":
            draft.genericDashboardDatasetResultLoading = false;
            draft.genericDashboardDatasetResult = action.payload;
            break;
        case "genericDashboardEtlExecute":
            draft.genericDashboardEltExecuting[action.payload.etlName] = true;
            break;
        case "genericDashboardEtlExecuted":
            draft.genericDashboardEltExecuting[action.payload.etlName] = false;
            break;
    }
});
