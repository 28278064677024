import { Box } from "@material-ui/core";
import { IRatingModel, IRatingTypeModel, IRatingValueModel } from "proxy/apiProxy";
import React from "react";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";

export interface IRatingsProps {
    ratings: IRatingModel[];
    ratingTypes: Record<number | string, IRatingTypeModel>
}

export default function Ratings({ ratings, ratingTypes }: IRatingsProps) {
    const ratingValues = React.useMemo(() => Object.keys(ratingTypes).reduce((a, ratingTypeKey) => {
        const ratingType = ratingTypes[ratingTypeKey];
        for (const ratingValue of ratingType.values) {
            a[ratingValue.id] = { type: ratingType, value: ratingValue };
        }
        return a;
    }, {} as { [valueId: number]: { value: IRatingValueModel, type: IRatingTypeModel } }), [ratingTypes]);
    const columns: IColumnDefinition[] = [{
        name: "date",
        title: "Date",
        getCellValue: (row: IRatingModel) => row.updateDate,
        columnType: "date"
    },
    {
        name: "type",
        title: "Type",
        getCellValue: ({ valueId }: IRatingModel) => ratingValues[valueId]?.type?.name,
    },
    {
        name: "category",
        title: "Category",
        getCellValue: ({ valueId }: IRatingModel) => ratingValues[valueId]?.value?.category,
    },
    {
        name: "value",
        title: "Value",
        getCellValue: ({ valueId }: IRatingModel) => ratingValues[valueId]?.value?.label,
    },
    {
        name: "investmentGrade",
        title: "Investment Grade",
        getCellValue: ({ valueId }: IRatingModel) => ratingValues[valueId]?.value?.investmentGrade,
        columnType: "boolean"
    },
    {
        name: "rank",
        title: "Rank",
        getCellValue: ({ valueId }: IRatingModel) => ratingValues[valueId]?.value?.rank,
        columnType: "decimal"
    }];
    const state: IGridState = {
        "date": { width: 120, sortingPosition: -1 },
        "type": { width: 200 },
        "category": { width: 100 },
        "value": { width: 100 },
        "investmentGrade": { width: 150 },
        "rank": { width: 100 }
    };

    const getRowKey = (row: IRatingModel) => JSON.stringify(row);

    return <Box display="flex" flexDirection="column" height="100%">
        <ExtendedGrid
            getRowId={getRowKey}
            defaultExportFileName="Ratings.xlsx"
            columns={columns}
            rows={ratings}
            initialState={state}
            defaultColumnWidth={120} />
    </Box>;
}