import { Epic } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { dailyDataApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction, IState } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadAccountBalances: Epic<IAnyAction, IAnyAction, IState>
    = action$ => action$.pipe(
        mapToPayload("accountBalance", "accountBalancesSearch"),
        mergeMap(dailyDataApi.searchAccountBalancesAsync),
        map(ActionFactories.accountBalance.accountBalancesLoaded));
