import { IMenuStructureProps, MenuStructure } from "./MenuStructure";
import { IMenuDefinition } from "features/App/menu/menuContracts";
import * as React from "react";
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import {
    IMenuDefinitionTreeNodeLevel3,
    mapFromModelToTreeItem,
    mapFromTreeItemToModel,
    MenuTreeItem
} from "./menuTree";
import { Box, Grid, Switch, Typography } from "@material-ui/core";
import CodeEditor, { ICodeEditorProps } from "tools/components/CodeEditor";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { isEqual } from "lodash";
import schema from "features/App/menu/menuContracts.json";
import { ErrorBoundary } from "react-error-boundary";

type SwitchOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;

export interface IMenuEditorProps {
    /**
     * Initial menu configuration
     */
    menuConfig: IMenuDefinition | undefined
    /**
     * Inform on change weather the menu is different from the initial menuConfig
     * To retrieve its value use your ref.
     * @see IMenuEditorRef
     */
    onChange: (dirty: boolean) => void
}

export interface IMenuEditorRef {
    getValue: () => IMenuDefinition | undefined
}

/**
 * Convert a tree back to the model
 * @param menuTree a SortableTree tree
 */
const convertTreeToModel = (home: IMenuDefinitionTreeNodeLevel3 | undefined, menuTree: MenuTreeItem[]): IMenuDefinition | undefined => {
    const menuDefinition = mapFromTreeItemToModel(menuTree)
    const newMenuConfig = Array.isArray(menuDefinition) && menuDefinition.length >= 1
        ? menuDefinition
        : undefined

    const newHome = home;
    if (!newMenuConfig && !newHome) return undefined
    return {
        menu: newMenuConfig ?? [],
        home: newHome,
        version: 2
    }
}

/**
 * "Safe" Conversion from the Json as String to the Json Model
 * The returned json is not checked to be a valid model
 * @param menuCode json string representing the model
 *
 * @see convertModelToTree
 */
const convertJsonToModel = (menuCode: string): IMenuDefinition | undefined => {
    let json = undefined;
    try {
        json = JSON.parse(menuCode)
    } catch (e) {
        console.error("MenuEditor: convertJsonToModel", e);
        return undefined
    }
    return json as IMenuDefinition
}

/**
 * "Safe" conversion from the Json Model to a Menu Home Node and a Menu Tree.
 *
 * If the json model is malformed, undefined is returned.
 *
 * @param menuModelJson a json model
 */
const convertModelToTree = (menuModelJson: IMenuDefinition): [IMenuDefinitionTreeNodeLevel3 | undefined, MenuTreeItem[]] | undefined => {
    const { menu, home } = Array.isArray(menuModelJson)
        ? { menu: menuModelJson, home: undefined }
        : menuModelJson

    let tree: MenuTreeItem[] | undefined = undefined;
    try {
        tree = mapFromModelToTreeItem(menu)
    } catch (e) {
        console.error("MenuEditor: convertModelToTree", e);
        return undefined
    }
    const homeNode: IMenuDefinitionTreeNodeLevel3 | undefined = home ? {
        ...home
    } : undefined;

    return [homeNode, tree];
}

function convertModelToJson(model: undefined | IMenuDefinition): string | undefined {
    try {
        return JSON.stringify(model, null, 4)
    } catch (e) {
        console.error("MenuEditor: convertModelToJson", e);
        return undefined
    }
}

const useStyles = makeStyles(theme => createStyles({
    selectedEditor: {
        fontStyle: "italic",
    },
    switchContainer: {
        paddingInline: theme.spacing(2),
        paddingBlock: theme.spacing(0.5),
    },
    jsonEditorContainer: {
        height: "100%",
        overflow: "hidden",
    },
    jsonEditorSwitchContainer: {
        position: "sticky",
        bottom: 0,
        padding: theme.spacing(1),
        zIndex: 999,
        display: "flex",
        justifyContent: "end",
    },
    errorFit:{
        width: "fit-content",
    }
}));


export const MenuEditor = forwardRef<IMenuEditorRef, IMenuEditorProps>((
    {
        menuConfig,
        onChange
    }: IMenuEditorProps, ref) => {

    const [initialMenuHome, initialMenuTree] = useMemo(() => {
        if (!!menuConfig) {
            const structures = convertModelToTree(menuConfig)
            if (structures) return structures
        }
        return [undefined, []]
    }, [menuConfig])
    const initialMenuCode = useMemo(() => {
        return convertModelToJson(menuConfig)
    }, [menuConfig])

    const [menuCode, setMenuCode] = useState<string>(initialMenuCode ?? "")
    const [menuTree, setMenuTree] = useState<MenuTreeItem[]>(initialMenuTree)
    const [menuHome, setMenuHome] = useState<IMenuDefinitionTreeNodeLevel3 | undefined>(initialMenuHome)

    const handleMenuHomeChange = useCallback<IMenuStructureProps["setMenuHome"]>((home) => {
        setMenuHome(home)
        onChange(!isEqual(initialMenuHome, home))
    }, [initialMenuHome, onChange]);


    const handleMenuTreeChange = useCallback<IMenuStructureProps["setMenuTree"]>((tree) => {
        setMenuTree(tree)
        onChange(!isEqual(initialMenuTree, tree))
    }, [initialMenuTree, onChange]);

    const handleMenuCodeChange = useCallback<ICodeEditorProps["onCodeChanged"]>((code) => {
        setMenuCode(code)
        onChange(initialMenuCode !== code)
    }, [initialMenuCode, onChange]);


    const [jsonEditor, setJsonEditor] = useState(false)
    useImperativeHandle(ref, () => ({
        getValue() {
            if (jsonEditor) {
                return menuCode ? convertJsonToModel(menuCode) : undefined
            } else {
                return convertTreeToModel(menuHome, menuTree)
            }
        }
    }), [jsonEditor, menuCode, menuHome, menuTree])

    const handleJsonEditorSwitch = useCallback<SwitchOnChangeHandler>((_, activateJsonEditor) => {
        if (activateJsonEditor) {
            // Convert Structural to JSON
            const model = convertTreeToModel(menuHome, menuTree)
            const code = convertModelToJson(model)
            console.log("Prepare JSON Menu Editor", "Home:", menuHome, "Tree:", menuTree, "Model:", model)
            if (code) {
                setMenuCode(code)
                setJsonEditor(activateJsonEditor)
            }
        } else {
            // Convert JSON to Structural
            let jsonModel = menuCode ? convertJsonToModel(menuCode) : undefined
            const structures = jsonModel ? convertModelToTree(jsonModel) : undefined
            console.log("Prepare Structural Menu Editor", "Model:", jsonModel, "Structures:", structures)
            if (structures) {
                const [home, tree] = structures
                setMenuTree(tree)
                setMenuHome(home)
                setJsonEditor(activateJsonEditor)

            }
        }
    }, [menuCode, menuHome, menuTree])

    const classes = useStyles()
    const jsonSwitchElement = <Grid component="label"
        container justifyContent="flex-end" alignItems="center"
        className={classes.switchContainer}>
        <Grid item className={clsx(!jsonEditor && classes.selectedEditor)}>Tree</Grid>
        <Grid item>
            <Switch checked={jsonEditor} onChange={handleJsonEditorSwitch} name="Json editor" />
        </Grid>
        <Grid item className={clsx(jsonEditor && classes.selectedEditor)}>Json</Grid>
    </Grid>;
    return jsonEditor
        ? <div className={classes.jsonEditorContainer}>
            <CodeEditor language="json" onCodeChanged={handleMenuCodeChange} code={menuCode} jsonSchema={schema} />
            <div className={classes.jsonEditorSwitchContainer}>{jsonSwitchElement}</div>
        </div>
        : <ErrorBoundary fallbackRender={({ error }) => 
            <Box padding={5}>
                <Typography variant="h5" color="error">Error in the Structural Menu Editor</Typography>
                <div className={classes.errorFit}>{jsonSwitchElement}</div>
                {error instanceof Error && <p>{error.message}</p>}
            </Box>
        }>
            <MenuStructure
                menuHome={menuHome} setMenuHome={handleMenuHomeChange}
                menuTree={menuTree} setMenuTree={handleMenuTreeChange}
                stickyAction={jsonSwitchElement} />
        </ErrorBoundary>
})