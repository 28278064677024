import { ICurrencyModel, ICountryModel, IClassificationTypeModel, SecuritySummaryModel, ISubFundSummaryModel, BenchmarkPositionModel, IIndexSummaryModel } from "proxy/apiProxy";
import { Typography, FormControlLabel, Switch, Box } from "@material-ui/core";
import FieldBox from "tools/components/FieldBox";
// import PricingDatesNavigator from "../global/PricingDatesNavigator";
import PortfolioBenchmarkCompositionGrid from "./PortfolioBenchmarkCompositionGrid";
import { IDictionary } from "tools/lib/utility";
import { VisualizationType } from "features/ManagedPortfolio/slice";
import PortfolioBenchmarkCompositionChart from "./PortfolioBenchmarkCompositionChart";
import PricingDatesNavigator from "components/global/PricingDatesNavigator";
import { IFlatClassificationTypes } from "features/Classification/getFlatClassificationTypes";
export interface IPortfolioBenchmarkCompositionProps {
    dictionaries: {
        securities: Record<number | string, SecuritySummaryModel>;
        subFunds: Record<number | string, ISubFundSummaryModel>;
        indexes: Record<number | string, IIndexSummaryModel>;
    };
    composition?: BenchmarkPositionModel[];
    pricingDate?: Date;
    pricingDates?: Date[];
    pricingDatesLoading: boolean;
    onPricingDateChanged: (pricingDate: Date) => void;
    referenceCurrencies: IDictionary<ICurrencyModel>;
    referenceCountries: IDictionary<ICountryModel>;
    classificationTypesFlat: IFlatClassificationTypes;
    classificationTypes: IClassificationTypeModel[];
    onTargetSecuritySelected?: (securityId: number) => void;
    onTargetIndexSelected?: (indexId: number) => void;
    onVisualizationTypeChanged: (visualizationType: VisualizationType) => void;
    visualizationType: VisualizationType;
}

export default function PortfolioBenchmarkComposition({
    dictionaries: {
        subFunds,
        securities,
        indexes
    },
    composition,
    pricingDate,
    pricingDates,
    pricingDatesLoading,
    onPricingDateChanged,
    referenceCurrencies,
    referenceCountries,
    classificationTypesFlat,
    classificationTypes,
    onTargetSecuritySelected,
    onVisualizationTypeChanged,
    onTargetIndexSelected,
    visualizationType
}: IPortfolioBenchmarkCompositionProps) {
    const handleHandleChangeVisualizationType = () => onVisualizationTypeChanged(visualizationType === "CHART" ? "GRID" : "CHART");

    if (!composition) {
        return <Typography>No composition</Typography>;
    }

    return <Box display="flex" flexDirection="column" height="100%">
        <FieldBox display="flex" flexDirection="row" style={{ padding: 16 }}>
            <PricingDatesNavigator onPricingDateChanged={onPricingDateChanged} pricingDatesLoading={pricingDatesLoading} pricingDate={pricingDate} pricingDates={pricingDates} />
            <FormControlLabel
                control={<Switch checked={visualizationType === "CHART"} onChange={handleHandleChangeVisualizationType} />}
                label="Graph" />
            <div style={{ flexGrow: 1 }} />
        </FieldBox>
        <div style={{ flexGrow: 1, height: 1, margin: visualizationType === "CHART" ? 18 : undefined }}>
            {visualizationType === "CHART" && <PortfolioBenchmarkCompositionChart
                referenceCurrencies={referenceCurrencies}
                referenceCountries={referenceCountries}
                classificationTypesFlat={classificationTypesFlat}
                classificationTypes={classificationTypes}
                positions={composition}
                subFunds={subFunds}
                indexes={indexes}
                securities={securities} />}
            {visualizationType === "GRID" && <PortfolioBenchmarkCompositionGrid
                indexes={indexes}
                subFunds={subFunds}
                referenceCurrencies={referenceCurrencies}
                referenceCountries={referenceCountries}
                classificationTypesFlat={classificationTypesFlat}
                classificationTypes={classificationTypes}
                onTargetSecuritySelected={onTargetSecuritySelected}
                onTargetIndexSelected={onTargetIndexSelected}
                positions={composition}
                securities={securities} />}
        </div>
    </Box>;
}
