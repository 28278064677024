import { IHistoricalValueSetModel } from "../../proxy/apiProxy";
import { Formik, FormikProps } from "formik";
import DialogPanel from "../../tools/components/DialogPanel";
import FieldBox from "../../tools/components/FieldBox";
import FormDatePickerField from "../../tools/fieldComponents/FormDatePickerField";
import FormTextField from "../../tools/fieldComponents/FormTextField";
import { IDataSeries } from "../../components/global/HistoricalValues";
import { oProps } from "tools/lib/utility";

export interface IAddHistoricalValueProps {
    onAdd: (histValue: IHistoricalValueSetModel) => void;
    isOpened: boolean;
    onCancel: () => void;
    initialHistoricalValue?: IHistoricalValueSetModel;
    typeDescriptions?: IDataSeries;
}


function hasTitleColumn(item: any): item is { title: string } {
    return !!(item as { title: string }).title;
}

export function AddHistoricalValue({ onAdd, onCancel, isOpened, initialHistoricalValue, typeDescriptions }: IAddHistoricalValueProps) {
    const emptyHistValue = {} as IHistoricalValueSetModel;
    return <Formik onSubmit={onAdd} initialValues={initialHistoricalValue ?? emptyHistValue} enableReinitialize={true}
        validateOnMount={true}>
        {({ submitForm, values }: FormikProps<IHistoricalValueSetModel>) => {
            const typeAdornment = (() => {
                if (!(values.type && typeDescriptions && typeDescriptions[values.type])) {
                    return "";
                }
                const typeDescription = typeDescriptions[values.type];
                if (typeof typeDescription === "string") {
                    return typeDescription;
                } else if (hasTitleColumn(typeDescription)) {
                    return typeDescription.title;
                }
                return "";
            })();
            return (
                <DialogPanel
                    onBackClick={onCancel}
                    title="Create Historical Value"
                    actions={[{ label: "Create Historical Value", onClick: submitForm }]}
                    isOpened={isOpened}>
                    <FieldBox display="flex" flexDirection="column">
                        <FormDatePickerField name={oProps<IHistoricalValueSetModel>().path("date")} label="Date" required />
                        <FormTextField name={oProps<IHistoricalValueSetModel>().path("type")} label="Type" required adornment={typeAdornment} />
                        <FormTextField name={oProps<IHistoricalValueSetModel>().path("value")} label="Value" required isNumber />
                    </FieldBox>
                </DialogPanel>
            )
                ;
        }
        }
    </Formik>
        ;
}