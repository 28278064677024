import { NodeModel, DefaultPortModel } from '@projectstorm/react-diagrams';
import { BaseModelOptions, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { AbstractReactFactory, GenerateWidgetEvent, GenerateModelEvent } from '@projectstorm/react-canvas-core';
import { DiagramEngine, PortWidget } from '@projectstorm/react-diagrams-core';
import styled from '@emotion/styled';

export interface TextNodeModelOptions extends BaseModelOptions {
	text?: string;
}

export class TextNodeModel extends NodeModel {
	text: string;

	constructor(options: TextNodeModelOptions = {}) {
		super({
			...options,
			type: 'title-custom-node'
		});
		this.text = options.text || 'no title';
		this.addPort(new DefaultPortModel({ in: true, name: 'in' }));
		this.addPort(new DefaultPortModel({ in: false, name: 'out' }));
	}

	serialize() {
		return { ...super.serialize(), title: this.text };
	}

	deserialize(event: DeserializeEvent<this>): void {
		super.deserialize(event);
		this.text = event.data.title;
	}
}

export interface ITextNodeWidgetProps {
	node: TextNodeModel;
	engine: DiagramEngine;
}

export const TitleNode = styled.div(({ isSelected }: { isSelected: boolean }) => ({
	borderStyle: "solid",
	borderWidth: 2,
	borderColor: isSelected ? "white" : "gray",
	borderRadius: 5,
	padding: 5,
	backgroundColor: "rgba(255, 255, 255, 0.4)",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	gap: 10
}));

export const CirclePort = styled.div({
	width: 12,
	height: 12,
	margin: 2,
	borderRadius: 4,
	background: "darkgray",
	cursor: "pointer",
	": hover": {
		background: "mediumpurple"
	}

});

export function TextNodeWidget({ node, engine }: ITextNodeWidgetProps) {
	const inPort = node.getPort("in");
	const outPort = node.getPort("out");
	return <TitleNode isSelected={node.isSelected()}>
		{inPort && <PortWidget engine={engine} port={inPort}>
			<CirclePort />
		</PortWidget>}
		<div>{node.text}</div>
		{outPort && <PortWidget engine={engine} port={outPort}>
			<CirclePort />
		</PortWidget>}
	</TitleNode>
}

export class TextNodeFactory extends AbstractReactFactory<TextNodeModel, DiagramEngine> {
	constructor() {
		super('title-custom-node');
	}

	generateModel(initialConfig: GenerateModelEvent) {
		return new TextNodeModel();
	}

	generateReactWidget(event: GenerateWidgetEvent<TextNodeModel>): JSX.Element {
		return <TextNodeWidget engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
