import { IUserTraceModel } from "proxy/apiProxy";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { FormikProps, Formik, FormikHelpers } from "formik";
import { ITracesSearchParameters } from "proxy/apiProxy";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { oProps } from "tools/lib/utility";
import FieldBox from "tools/components/FieldBox";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FormTextField from "tools/fieldComponents/FormTextField";
import ErrorIcon from '@material-ui/icons/Error';
import DotsHorizontalCircleOutlineIcon from "mdi-material-ui/DotsHorizontalCircleOutline";
import { Tooltip, withStyles } from "@material-ui/core";

export const tracesScreen = {
    component: Traces,
    route: "/config/traces",
    label: "Traces",
    keywords: "log errors track activity",
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[15],
        color: theme.palette.text.primary,
        fontSize: theme.typography.pxToRem(12),
        maxWidth: "none",
    },
}))(Tooltip);

function Traces() {
    const { tracesSearch } = useReduxActions("traces");
    const { searching, all, dictionaries } = useReduxSelections("traces");
    const { entities } = dictionaries;

    const columns: IColumnDefinition[] = [
        {
            name: "Person", title: "Person", getCellValue: ({ personId }: IUserTraceModel) => {
                if (!personId) {
                    return;
                }
                const entity = entities[personId];
                if (!entity) {
                    return;
                }
                if (entity.type === "PersonSummaryModel") {
                    return `${entity.firstName} ${entity.lastName}`;
                }
                return entity.name;
            }, aggregationType: "count", filteringEnabled: true
        },
        { name: "Date", title: "Date", getCellValue: ({ dateTime }: IUserTraceModel) => dateTime, columnType: "dateTime" },
        { name: "Method", title: "Method", getCellValue: ({ method }: IUserTraceModel) => method, filteringEnabled: true },
        { name: "Path", title: "Path", getCellValue: ({ path }: IUserTraceModel) => path, filteringEnabled: true },
        {
            name: "Parameters", title: "Parameters", getCellValue: ({ queryValues }: IUserTraceModel) => {
                if (!queryValues || !Object.keys(queryValues)) {
                    return;
                }
                return <HtmlTooltip title={<pre>{JSON.stringify(queryValues, null, 4)}</pre>} ><DotsHorizontalCircleOutlineIcon fontSize="small" /></HtmlTooltip>
            }
        },
        {
            name: "Error", title: "Error", getCellValue: ({ exception }: IUserTraceModel) => {
                if (!exception) {
                    return;
                }
                return <HtmlTooltip title={<pre>{exception}</pre>} ><ErrorIcon fontSize="small" style={{ color: "red" }} /></HtmlTooltip>
            }
        },
    ];
    const state: IGridState = {
        "Person": { width: 220 },
        "Date": { width: 180 },
        "Method": { width: 100 },
        "Path": { width: 350 },
        "Parameters": { width: 60 },
        "Error": { width: 60 },
    };

    const getRowKey = (row: IUserTraceModel) => row.id;

    const handleSubmit = (model: ITracesSearchParameters, { setSubmitting }: FormikHelpers<ITracesSearchParameters>) => {
        tracesSearch(model);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={{ lastOnly: true } as ITracesSearchParameters} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;

    function renderForm({ isValid, submitForm }: FormikProps<ITracesSearchParameters>) {
        return (
            <SearchPanel
                isQuerying={searching}
                onSearchClick={submitForm}
                searchDisabled={!isValid}
                title="Traces"
                renderSearch={(<FieldBox display="flex" flexDirection="column">
                    <FieldBox display="flex" flexDirection="row">
                        <FormTextField name={oProps<ITracesSearchParameters>().path("pathCriteria")} label="Criteria" />
                        <FormCheckBoxField name={oProps<ITracesSearchParameters>().path("onlyException")} label="Only Errors" />
                        <FormDatePickerField name={oProps<ITracesSearchParameters>().path("fromDate")} label="From" />
                        <FormDatePickerField name={oProps<ITracesSearchParameters>().path("toDate")} label="To" />
                    </FieldBox>
                </FieldBox>
                )}>
                <ExtendedGrid
                    getRowId={getRowKey}
                    columns={columns}
                    rows={all}
                    initialState={state}
                    userCanGroup={true}

                    defaultExportFileName="traces.xlsx" />
            </SearchPanel>
        );
    }
}
// https://react-pivottable.js.org/