import { Epic } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { batchesApi, batchInstancesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadBatches: Epic<IAnyAction>
    = action$ => action$.pipe(
    mapToPayload("batchInstance", "batchInstanceLoadBatches"),
    mergeMap(() => batchesApi.getAllAsync({})),
    map(ActionFactories.batchInstance.batchInstanceLoadedBatches)
);

export const onGetBatchInstanceTaskTraces: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("batchInstance", "batchInstanceTaskNodeTracesLoad"),
        mergeMap(batchInstancesApi.getTracedRowsAsync),
        map(i => ActionFactories.batchInstance.batchInstanceTaskNodeTracesLoaded())
    );

export const searchBatchInstances: Epic<IAnyAction>
    = (action$) =>
        action$.pipe(
            mapToPayload("batchInstance", "batchInstanceSearch"),
            mergeMap(batchInstancesApi.searchAsync),
            map(ActionFactories.batchInstance.batchInstancesFound));

export const loadBatchInstance: Epic<IAnyAction>
    = (action$) => action$.pipe(
        mapToPayload("batchInstance", "batchInstanceLoad"),
        mergeMap(id => batchInstancesApi.getAsync({ id })),
        map(ActionFactories.batchInstance.batchInstanceLoaded));
