import { IDocumentDefinitionModel, IClassificationTypeModel, IUniverseScopeTypeModel, IReportTemplateSummaryModel, IReportTemplateCategoryModel } from "proxy/apiProxy";
import FormTextField from "tools/fieldComponents/FormTextField";
import { getEnumLabels, oProps } from "tools/lib/utility";
import { Grid } from "@material-ui/core";
import ClassificationsData from "components/global/ClassificationsData";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";

const scopeTypeLabel = getEnumLabels(IUniverseScopeTypeModel);

export interface IDocumentDefinitionDataProps {
    reportTemplateCategories: Record<number, IReportTemplateCategoryModel>
    reportTemplates: IReportTemplateSummaryModel[];
    classificationTypes: IClassificationTypeModel[];
}
export default function DocumentDefinitionData({
    classificationTypes, reportTemplates, reportTemplateCategories
}: IDocumentDefinitionDataProps) {
    return <Grid container={true} spacing={1}>
        <Grid item={true} sm={12} md={6} lg={4}>
            <FormTextField name={oProps<IDocumentDefinitionModel>().path("code")} label="Code" required={true} maxLength={100} />
        </Grid>
        <Grid item={true} sm={12} md={6} lg={4}>
            <FormTextField name={oProps<IDocumentDefinitionModel>().path("name")} label="Name" required={true} maxLength={250} />
        </Grid>
        <Grid item={true} sm={12} md={6} lg={4}>
            <FormSimpleSelectField name={oProps<IDocumentDefinitionModel>().path("singleScope")} label="Scope" options={scopeTypeLabel} />
        </Grid>
        <Grid item={true} sm={12} md={6} lg={4}>
            <FormSimpleSelectField name={oProps<IDocumentDefinitionModel>().path("categoryId")} label="Category" options={reportTemplateCategories} />
        </Grid>
        <Grid item={true} sm={12} md={6} lg={4}>
            <FormSimpleSelectField name={oProps<IDocumentDefinitionModel>().path("reportTemplateId")} label="Template" options={reportTemplates} />
        </Grid>
        <Grid item={true} sm={12} md={6} lg={4}>
            <FormCheckBoxField name={oProps<IDocumentDefinitionModel>().path("publishOnPortal")} label="Publish on portal" />
        </Grid>


        <Grid item={true} sm={12} md={12} lg={12}>
            <ClassificationsData
                panelTitle="Classifications"
                fieldName={oProps<IDocumentDefinitionModel>().path("classifications")}
                classificationTypes={classificationTypes} />
        </Grid>
    </Grid>
}
