import DialogPanel from "tools/components/DialogPanel";
import { IFileModel } from "proxy/apiProxy";
import DropZone from "tools/components/DropZone";
import { fileToBase64, base64toBlob } from 'tools/lib/utility';
import saveAs from "file-saver";

export interface IFileSelectDialogProps {
    onFileSelected: (selectedFile?: IFileModel) => void;
    selectedFile?: IFileModel;
    isOpened: boolean;
    onClose: () => void;
    onOk: () => void;
    label: string;
    title: string;
    okLabel: string;
}
export default function FileSelectDialog({ onClose,
    onFileSelected, onOk, selectedFile,
    isOpened, label, title, okLabel }: IFileSelectDialogProps) {
    const handleFileOpen = async (file: File) => {
        const openedFile = await fileToBase64(file);
        onFileSelected({
            data: openedFile.content,
            mimeType: openedFile.mimeType,
            name: openedFile.fileName
        });
    };
    const handleFileView = () => {
        if (selectedFile?.data && selectedFile?.name) {
            const blob = base64toBlob(selectedFile.data, selectedFile.mimeType ?? "");
            saveAs(blob, selectedFile.name);
        }
    }
    const handleFileClear = () => onFileSelected(undefined);

    return <DialogPanel
        isOpened={isOpened}
        title={title}
        actions={[{ label: okLabel, onClick: onOk, disabled: !selectedFile }]}
        onBackClick={onClose}>
        <DropZone
            emptyLabel={label}
            fileName={selectedFile?.name}
            onClick={handleFileView}
            onFileOpen={handleFileOpen}
            onClear={handleFileClear} />
    </DialogPanel>;
}
