import { ContainerComponent } from "./ContainerComponent";
import { ItemControl } from "./DashboardFormattingContracts";
import { DataGridComponent } from "./DataGridComponent";
import { DataTableComponent } from "./DataTableComponent";
import { FieldComponent } from "./FieldComponent";
import { useVisible } from "./ValueProvider";

// https://docs.npmjs.com/cli/v8/configuring-npm/package-json#local-paths


export interface IItemProps {
    item: ItemControl<any>;
}

export function Item({ item }: IItemProps) {
    const getVisible = useVisible();
    if (!getVisible(item)) {
        return null;
    }
    switch (item.type) {
        case "gridContainer":
        case "flexContainer":
        case "gridDetailContainer":
        case "flexDetailContainer":
        case "hierarchyGraphDetailContainer":
        case "directedGraphDetailContainer":
        case "tabsContainer":
            return <ContainerComponent definition={item} itemDisplayType={item.itemLayout} />
        case "dataGrid":
            return <DataGridComponent item={item} />
        case "dataTable":
            return <DataTableComponent item={item} />
        case "pie":
            return null; //TODO
        default:
            return <FieldComponent item={item} />
    }
}
