import * as React from "react";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Select, { SelectProps } from "@material-ui/core/Select";
import { MenuItem, Checkbox, ListItemText, Chip } from "@material-ui/core";
import { IDictionary } from "tools/lib/utility";

interface IOption<V extends string | number> {
    key: string;
    primaryContent: React.ReactNode;
    secondaryContent?: React.ReactNode;
    value: V;
    isSelected: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chips: {
            display: "flex",
            flexWrap: "wrap"
        },
        chip: {
            margin: theme.spacing(0.25, 0.25)
        }
    }),
);

export interface IMultiSelectProps<V extends string | number = number, T extends any | { id?: V } = any> extends SelectProps {
    options: IDictionary<T> | T[];
    values: V[] | undefined;
    getValue?: (row: T) => V;
    getPrimaryContent?: (row: T) => React.ReactNode;
    getSecondaryContent?: (row: T) => React.ReactNode;
    onSelectionChanged?: (value: V[]) => void;
    // readOnly?: boolean;
}

export default function MultiSelect<V extends string | number = number, T extends any | { id?: V } = any>(myProps: IMultiSelectProps<V, T>) {
    const {
        onChange,
        values = [],
        options: inputOptions,
        getValue,
        getPrimaryContent,
        getSecondaryContent,
        onSelectionChanged,
        ...props
    } = myProps;

    const classes = useStyles();


    const valueToString = (v: V | undefined) => typeof (v) === "undefined" ? "" : JSON.stringify(v);
    const stringToValue = (s: string | undefined) => (typeof (s) === "undefined" || s === "") ? undefined : JSON.parse(s);

    const getOptions = (inputV: string[]): IOption<V>[] => {
        if (!inputOptions) {
            return [];
        }
        if (Array.isArray(inputOptions)) {
            return inputOptions.map(option => {
                const value = getValue ? getValue(option) : (option as any).id;
                const key = valueToString(value);
                return {
                    key,
                    primaryContent: getContent(option),
                    secondaryContent: getSecondaryContent ? getSecondaryContent(option) : undefined,
                    value,
                    isSelected: inputV.includes(key)
                } as IOption<V>;
            })
        }
        return Object.keys(inputOptions).map(key => ({
            key: valueToString(key as V),
            primaryContent: getContent(inputOptions[key]),
            secondaryContent: getSecondaryContent ? getSecondaryContent(inputOptions[key]) : undefined,
            value: key as V,
            row: inputOptions[key as V],
            isSelected: inputV.includes(valueToString(key as V))
        } as IOption<V>));
        function getContent(option: any) {
            return getPrimaryContent ? getPrimaryContent(option) : typeof option === "string" ? option : option.name;
        }
    }
    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
        if (onChange) {
            onChange(event, child);
        }
        if (onSelectionChanged) {
            onSelectionChanged((event.target.value as string[]).map(i => stringToValue(i)));
        }
    }

    // const handleRemoveItem = (value: V) => {
    //     const index = values.indexOf(value);
    //     const copyValues = [...values];
    //     copyValues.splice(index, 1);
    //     if (onChange) {
    //         onChange({ target: { value: copyValues.map(i => valueToString(i)) } } as React.ChangeEvent<{ name?: string; value: unknown }>, "");
    //     }
    //     if (onSelectionChanged) {
    //         onSelectionChanged(copyValues);
    //     }
    // }

    const inputValue = values.map(i => valueToString(i));
    const opts = getOptions(inputValue);
    const handleRenderValue = (value: unknown): React.ReactNode => {
        const selection = opts.map((v, idx) => ({ v, idx })).filter(i => i.v.isSelected);
        if (!selection.length) {
            return;
        }
        return <div className={classes.chips}>
            {selection.map(v => (
                <Chip
                    // onDelete={handleRemoveItem.bind(null, v.v.value)}
                    size="small"
                    key={v.v.key}
                    label={v.v.primaryContent}
                    className={classes.chip} />
            ))}
        </div>;
    }

    return <Select
        {...props}
        multiple={true}
        multiline={true}
        value={inputValue ?? [] as unknown}
        onChange={handleChange}
        renderValue={handleRenderValue}>
        {opts.map(option => <MenuItem key={option.key} value={option.key}>
            <Checkbox checked={option.isSelected} />
            <ListItemText primary={option.primaryContent} secondary={option.secondaryContent} />
        </MenuItem>)}
    </Select>;
}
