import produce from "immer";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import {
    IPersonSummaryModel, IGetSecuritiesModel, ICurrencyModel, ICountryModel, IIndexSummaryModel,
    IGetPortfoliosModel, IGetEntitiesModel, IEntityTypeModel, IGetRelationshipsModel, IReportTemplateCategoryModel,
    IMacroScriptCategoryModel, IReportTemplateSummaryModel, IProcessDefinitionSummaryModel,
    IGetBankAccountsModel, IBankAccountsGetAllParameters, ISubFundsGetAllParameters, IGetSubFundsModel,
    IManagedSicavsGetAllParameters, IGetSicavsModel, IRatingTypeModel, IBatchSummaryModel, RelationshipModel,
    ISecuritiesSearchParameters, IReportTemplatesGetAllParameters, IManagedPortfoliosSearchParameters, IGetBatchesModel,
    IGetPortfolioTransactionsModel, IGetCashMovementsModel,
    IPortfolioTransactionsSearchParameters,
    ICashMovementsSearchParameters
} from "proxy/apiProxy";
import { Unpack } from "tools/lib/utility";

export interface IRelationshipsSearchParameters {
    criterias?: string;
    type: RelationshipModel["type"]
}

export interface IReferenceEntitySearchPayload {
    criterias: string;
    type?: IEntityTypeModel;
}
export interface IGetRelationshipSummary extends Omit<IGetRelationshipsModel, "all"> {
    relationship: Unpack<IGetRelationshipsModel["all"]>;
}

export interface IGetPortfolioSummary extends Omit<IGetPortfoliosModel, "portfolios"> {
    portfolio: Unpack<IGetPortfoliosModel["portfolios"]>;
}
export interface IGetSubFundSummary extends Omit<IGetSubFundsModel, "subFunds"> {
    subFund: Unpack<IGetSubFundsModel["subFunds"]>;
}
export interface IGetPortfoliosSummary extends Omit<IGetPortfoliosModel, "portfolios"> {
    portfolios: Record<string | number, Unpack<IGetPortfoliosModel["portfolios"]>>;
}
export interface IGetSicavSummary extends Omit<IGetSicavsModel, "all"> {
    sicav: Unpack<IGetSicavsModel["all"]>;
}

export interface IGetSecuritySummary extends Omit<IGetSecuritiesModel, "securities"> {
    security: Unpack<IGetSecuritiesModel["securities"]>;
}
export interface IGetSecuritiesSummary extends Omit<IGetSecuritiesModel, "securities"> {
    securities: Record<string | number, Unpack<IGetSecuritiesModel["securities"]>>;
}
export interface IGetEntitySummary extends Omit<IGetEntitiesModel, "all"> {
    entity: Unpack<IGetEntitiesModel["all"]>;
}
export interface IGetBankAccountSummary extends Omit<IGetBankAccountsModel, "bankAccounts"> {
    bankAccount: Unpack<IGetBankAccountsModel["bankAccounts"]>;
}
export interface IGetEntitiesSummary extends Omit<IGetEntitiesModel, "entities"> {
    entities: Record<string | number, Unpack<IGetEntitiesModel["entities"]>>;
}
export interface IGetRelationshipsSummary extends Omit<IGetRelationshipsModel, "relationships"> {
    relationships: Record<string | number, Unpack<IGetRelationshipsModel["relationships"]>>;
}

export const ActionFactories = produceActionFactories({
    referenceRatingTypesLoaded: (payload: Record<string | number, IRatingTypeModel>) => payload,
    referenceCurrenciesLoaded: (payload: Record<string | number, ICurrencyModel>) => payload,
    referenceCountriesLoaded: (payload: Record<string | number, ICountryModel>) => payload,
    referenceReportTemplateCategoriesLoaded: (payload: Record<string | number, IReportTemplateCategoryModel>) => payload,
    referenceMacroScriptCategoriesLoaded: (payload: Record<string | number, IMacroScriptCategoryModel>) => payload,
    // referenceMarketDataProvidersLoaded: (payload: Record<string | number, string>) => payload,

    referenceReportTemplateSearched: (payload: IReportTemplateSummaryModel[]) => payload,
    referenceReportTemplateSearching: () => undefined,
    referenceReportTemplateSearch: (payload: IReportTemplatesGetAllParameters) => payload,

    referenceBatchSearched: (payload: IGetBatchesModel) => payload,
    referenceBatchSearching: () => undefined,
    referenceBatchSearch: (payload: string) => payload,

    referenceEntitySearch: (payload: IReferenceEntitySearchPayload) => payload,
    referenceEntitySearching: () => undefined,
    referenceEntitySearched: (payload: IGetEntitiesModel) => payload,

    referenceIndexSearch: (payload: string) => payload,
    referenceIndexSearching: () => undefined,
    referenceIndexSearched: (payload: IIndexSummaryModel[]) => payload,

    referenceRelationshipSearch: (payload: IRelationshipsSearchParameters) => payload,
    referenceRelationshipSearching: () => undefined,
    referenceRelationshipSearched: (payload: IGetRelationshipsModel) => payload,

    referenceSecuritySearch: (payload: ISecuritiesSearchParameters) => payload,
    referenceSecuritySearching: () => undefined,
    referenceSecuritySearched: (payload: IGetSecuritiesModel) => payload,

    referenceManagedPortfolioSearch: (payload: IManagedPortfoliosSearchParameters) => payload,
    referenceManagedPortfolioSearching: () => undefined,
    referenceManagedPortfolioSearched: (payload: IGetPortfoliosModel) => payload,

    referenceManagedSicavSearch: (payload: IManagedSicavsGetAllParameters) => payload,
    referenceManagedSicavSearching: () => undefined,
    referenceManagedSicavSearched: (payload: IGetSicavsModel) => payload,

    referenceSubFundSearch: (payload: ISubFundsGetAllParameters) => payload,
    referenceSubFundSearching: () => undefined,
    referenceSubFundSearched: (payload: IGetSubFundsModel) => payload,

    referenceBankAccountSearch: (payload: IBankAccountsGetAllParameters) => payload,
    referenceBankAccountSearching: () => undefined,
    referenceBankAccountSearched: (payload: IGetBankAccountsModel) => payload,

    referenceProcessDefinitionSearch: (payload: string) => payload,
    referenceProcessDefinitionSearching: () => undefined,
    referenceProcessDefinitionSearched: (payload: IProcessDefinitionSummaryModel[]) => payload,

    referencePortfolioTransactionSearch: (payload: IPortfolioTransactionsSearchParameters) => payload,
    referencePortfolioTransactionSearching: () => undefined,
    referencePortfolioTransactionSearched: (payload: IGetPortfolioTransactionsModel) => payload,

    referenceCashMovementSearch: (payload: ICashMovementsSearchParameters) => payload,
    referenceCashMovementSearching: () => undefined,
    referenceCashMovementSearched: (payload: IGetCashMovementsModel) => payload,

    referenceRefreshAll: () => undefined, // TODO: remove
});

export interface IState {
    referenceCurrencies?: Record<number | string, ICurrencyModel>;
    referenceCountries?: Record<number | string, ICountryModel>;
    referenceReportTemplateCategories?: Record<number | string, IReportTemplateCategoryModel>;
    referenceMacroScriptCategories?: Record<number | string, IMacroScriptCategoryModel>;
    ratingTypes?: Record<number | string, IRatingTypeModel>;

    referenceSecuritySearching?: boolean;
    referenceSecuritiesSearched: IGetSecuritiesModel;


    referenceShareClassSearching?: boolean;
    referenceShareClassesSearched: IGetSecuritiesModel;


    referenceEntitySearching?: boolean;
    referenceEntitiesSearched: IGetEntitiesModel;


    referencePeopleSearching?: boolean;
    referencePeopleSearched: IPersonSummaryModel[];

    referenceIndexSearching?: boolean;
    referenceIndexSearched: IIndexSummaryModel[];


    referenceRelationshipSearching?: boolean;
    referenceRelationshipsSearched: IGetRelationshipsModel;


    referenceBatchSearching?: boolean;
    referenceBatchesSearched: IBatchSummaryModel[];


    referenceBankAccountSearching?: boolean;
    referenceBankAccountsSearched: IGetBankAccountsModel;


    referenceManagedPortfolioSearching?: boolean;
    referenceManagedPortfoliosSearched: IGetPortfoliosModel;


    referenceManagedSicavSearching?: boolean;
    referenceManagedSicavsSearched: IGetSicavsModel;


    referenceSubFundSearching?: boolean;
    referenceSubFundsSearched: IGetSubFundsModel;


    referenceProcessDefinitionsSearching?: boolean;
    referenceProcessDefinitionsSearched: IProcessDefinitionSummaryModel[];


    referenceReportTemplateSearching?: boolean;
    referenceReportTemplatesSearched: IReportTemplateSummaryModel[];

    referencePortfolioTransactionSearching?: boolean;
    referencePortfolioTransactionsSearched: IGetPortfolioTransactionsModel;

    referenceCashMovementSearching?: boolean;
    referenceCashMovementsSearched: IGetCashMovementsModel;
}
export function reducer(
    state: IState = {
        referenceEntitiesSearched: {
            all: [],
            entities: {}
        },
        referenceRelationshipsSearched: {
            all: [],
            entities: {},
            relationships: {}
        },
        referenceManagedPortfoliosSearched: {
            portfolios: [],
            entities: {}
        },
        referenceManagedSicavsSearched: {
            all: [],
            entities: {}
        },
        referenceSubFundsSearched: {
            subFunds: [],
            entities: {}
        },
        referenceProcessDefinitionsSearched: [],
        referenceReportTemplatesSearched: [],
        referencePeopleSearched: [],
        referenceIndexSearched: [],
        referenceBatchesSearched: [],
        referenceSecuritiesSearched: {
            subFunds: {},
            securities: [],
            entities: {}
        },
        referenceShareClassesSearched: {
            subFunds: {},
            securities: [],
            entities: {}
        },
        referenceBankAccountsSearched: {
            bankAccounts: [],
            entities: {},
            portfolios: {}
        },
        referencePortfolioTransactionsSearched: {
            transactions: [],
            subFunds: {},
            portfolios: {},
            relationships: {},
            entities: {},
            securities: {}
        },
        referenceCashMovementsSearched: {
            cashMovements: [],
            portfolios: {},
            subFunds: {},
            bankAccounts: {},
            securities: {},
            entities: {},
        }
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "referenceBatchSearching":
                draft.referenceBatchSearching = true;
                break;
            case "referenceBatchSearched":
                draft.referenceBatchSearching = false;
                draft.referenceBatchesSearched = action.payload.batches;
                break;
            case "referenceBankAccountSearching":
                draft.referenceBankAccountSearching = true;
                break;
            case "referenceBankAccountSearched":
                draft.referenceBankAccountSearching = false;
                draft.referenceBankAccountsSearched = action.payload;
                break;
            case "referenceRelationshipSearching":
                draft.referenceRelationshipSearching = true;
                break;
            case "referenceRelationshipSearched":
                draft.referenceRelationshipSearching = false;
                draft.referenceRelationshipsSearched = action.payload;
                break;
            case "referenceSecuritySearching":
                draft.referenceSecuritySearching = true;
                break;
            case "referenceSecuritySearched":
                draft.referenceSecuritySearching = false;
                draft.referenceSecuritiesSearched = action.payload;
                break;
            case "referenceEntitySearching":
                draft.referenceEntitySearching = true;
                break;
            case "referenceEntitySearched":
                draft.referenceEntitySearching = false;
                draft.referenceEntitiesSearched = action.payload;
                break;
            case "referenceIndexSearching":
                draft.referenceIndexSearching = true;
                break;
            case "referenceIndexSearched":
                draft.referenceIndexSearching = false;
                draft.referenceIndexSearched = action.payload;
                break;
            case "referenceManagedPortfolioSearching":
                draft.referenceManagedPortfolioSearching = true;
                break;
            case "referenceManagedPortfolioSearched":
                draft.referenceManagedPortfolioSearching = false;
                draft.referenceManagedPortfoliosSearched = action.payload;
                break;
            case "referenceManagedSicavSearching":
                draft.referenceManagedSicavSearching = true;
                break;
            case "referenceManagedSicavSearched":
                draft.referenceManagedSicavSearching = false;
                draft.referenceManagedSicavsSearched = action.payload;
                break;
            case "referenceSubFundSearching":
                draft.referenceSubFundSearching = true;
                break;
            case "referenceSubFundSearched":
                draft.referenceSubFundSearching = false;
                draft.referenceSubFundsSearched = action.payload;
                break;
            case "referenceProcessDefinitionSearching":
                draft.referenceProcessDefinitionsSearching = true;
                break;
            case "referenceProcessDefinitionSearched":
                draft.referenceProcessDefinitionsSearching = false;
                draft.referenceProcessDefinitionsSearched = action.payload;
                break;
            case "referenceReportTemplateSearching":
                draft.referenceReportTemplateSearching = true;
                break;
            case "referenceReportTemplateSearched":
                draft.referenceReportTemplateSearching = false;
                draft.referenceReportTemplatesSearched = action.payload;
                break;
            case "referencePortfolioTransactionSearching":
                draft.referencePortfolioTransactionSearching = true;
                break;
            case "referencePortfolioTransactionSearched":
                draft.referencePortfolioTransactionSearching = false;
                draft.referencePortfolioTransactionsSearched = action.payload;
                break;
            case "referenceCashMovementSearching":
                draft.referenceCashMovementSearching = true;
                break;
            case "referenceCashMovementSearched":
                draft.referenceCashMovementSearching = false;
                draft.referenceCashMovementsSearched = action.payload;
                break;

            case "referenceReportTemplateCategoriesLoaded":
                draft.referenceReportTemplateCategories = action.payload;
                break;
            case "referenceMacroScriptCategoriesLoaded":
                draft.referenceMacroScriptCategories = action.payload;
                break;
            case "referenceCountriesLoaded":
                draft.referenceCountries = action.payload;
                break;
            case "referenceCurrenciesLoaded":
                draft.referenceCurrencies = action.payload;
                break;
            case "referenceRatingTypesLoaded":
                draft.ratingTypes = action.payload;
                break;
        }
    });
}
