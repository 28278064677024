import * as React from "react";
import { useReduxSelections, useReduxActions } from "tools/lib/reduxStoreAccess";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";
import { DashboardExecuteOnLoadPayload } from "./slice";
import DetailPanel from "tools/components/DetailPanel";
import { DashboardViewer } from "tools/dashboard/DashboardViewer";
import ReactJson from "react-json-view";
import { dashboardComponentsContext } from "tools/dashboardExternalComponents/DashboardComponents";
import { Box } from "@material-ui/core";
import { IMenuTargetDashboard } from "features/App/menu/menuContracts";
import { decodeInput } from "features/App/menuTools";
export const genericDashboardScreen = {
    route: "/genericDashboard/:input" as const,
    label: "GenericDashboard",
    component: GenericDashboard,
}
function GenericDashboard() {
    const { genericDashboardDatasetResultLoading,
        genericDashboardLayoutLoading,
        genericDashboardDatasetInput,
        genericDashboardDatasetResult,
        genericDashboardLayout } = useReduxSelections("genericDashboard");
    const { genericDashboardDatasetLoad, genericDashboardLoad } = useReduxActions("genericDashboard");

    const { input } = useScreenParams<typeof genericDashboardScreen>();
    const dashboardExecuteOnLoadPayload: DashboardExecuteOnLoadPayload & { label: string } | undefined = React.useMemo(() => {
        if (!input) {
            return undefined;
        }
        const decodedInput = decodeInput<IMenuTargetDashboard>(input);
        console.log("Dashboard input:", decodedInput);
        return {
            dashboardLayoutName: decodedInput.dashboardName,
            datasetName: decodedInput.datasetName,
            asOfDate: decodedInput.executionModel?.asOfDate,
            culture: decodedInput.executionModel?.culture,
            parameters: decodedInput.executionModel?.parameters,
            related: decodedInput.executionModel?.related,
            label: decodedInput.label
        };
    }, [input]);
    useEffect(() => {
        if (!dashboardExecuteOnLoadPayload) {
            return;
        }
        genericDashboardLoad(dashboardExecuteOnLoadPayload);
    }, [genericDashboardLoad, dashboardExecuteOnLoadPayload]);

    const handleRefresh = React.useCallback(() => {
        if (!dashboardExecuteOnLoadPayload?.datasetName) {
            return;
        }
        genericDashboardDatasetLoad({ ...dashboardExecuteOnLoadPayload, datasetName: dashboardExecuteOnLoadPayload.datasetName });
    }, [dashboardExecuteOnLoadPayload, genericDashboardDatasetLoad]);
    if (!dashboardExecuteOnLoadPayload || !genericDashboardLayout?.layout) {
        return null;
    }
    return <DetailPanel
        isQuerying={genericDashboardDatasetResultLoading || genericDashboardLayoutLoading}
        title={dashboardExecuteOnLoadPayload.label}
        noPadding
        onRefreshClick={handleRefresh}>
        <Box style={{ height: "100%" }}>
            {(genericDashboardDatasetResult?.type === "SuccessDatasetResultModel" || genericDashboardDatasetResult === undefined) &&
                <DashboardViewer
                    {...dashboardComponentsContext}
                    dataset={genericDashboardDatasetResult?.result}
                    layoutDefinition={genericDashboardLayout?.layout}
                    datasetInput={genericDashboardDatasetInput} />}
            {(genericDashboardDatasetResult?.type !== "SuccessDatasetResultModel" && !!genericDashboardDatasetResult) &&
                <ReactJson
                    style={{ width: 600 }}
                    name={false}
                    collapsed={1}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    src={genericDashboardDatasetResult ?? {}} />}
        </Box>
    </DetailPanel>
}
