import * as React from "react";
import { ISicavSummaryModel } from "proxy/apiProxy";
import { useField } from 'formik';
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IGetSicavSummary } from "features/Reference/slice";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';

export interface IManagedSicavSelectFieldProps {
    name: string;
    label?: React.ReactNode;
    required?: boolean;
    filter?: (s: ISicavSummaryModel) => boolean;
    onSelected?: (found: IGetSicavSummary) => void;
}
export default function ManagedSicavSelectField({ name, label, required, onSelected, filter }: IManagedSicavSelectFieldProps) {
    const { referenceManagedSicavSearch } = useReduxActions("reference");
    const { referenceManagedSicavsSearched, referenceManagedSicavSearching } = useReduxSelections("reference");
    const validate = (managedSicavId: number | undefined) => {
        if (!required) {
            return;
        }
        return (managedSicavId ?? 0) === 0 ? "Required" : undefined;
    }
    const [, { value, error, initialValue }, { setValue }] = useField<number | undefined>({ name, validate });
    const getOptionLabel = (managedsicav: ISicavSummaryModel) => managedsicav.name;
    label = <><SearchIcon fontSize="small" /> {label ?? "ManagedSicav"}: Type criteria to find a sicav {required ? "*" : ""}</>;
    if ((initialValue || null) !== (value || null)) {
        label = <b>{label}</b>;
    }
    const handleValueSelected = (sicav: ISicavSummaryModel) => {
        if (onSelected) {
            const entities = sicav.mainContactId ? { [sicav.mainContactId]: referenceManagedSicavsSearched.entities[sicav.mainContactId] } : {};
            onSelected({ sicav, entities });
        }
        setValue(sicav.id);
    }
    const handleOnSearchOptions = (criterias: string) => referenceManagedSicavSearch({ criterias });
    const sicavs = filter ? referenceManagedSicavsSearched.all.filter(filter) : referenceManagedSicavsSearched.all;
    return <ReadOnlyContext.Consumer>{readOnly => readOnly ? null :
        <AsyncSelect onSearchOptions={handleOnSearchOptions} options={sicavs}
            getOptionLabel={getOptionLabel} searching={referenceManagedSicavSearching} label={label} onRequiredValueSelected={handleValueSelected} error={!!error} helperText={error} />}
    </ReadOnlyContext.Consumer>

}
