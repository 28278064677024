import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, share, mergeMap } from "rxjs/operators";
import { bankAccountsApi, customScreensApi, IGetBankAccountModel, securitiesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadBankAccounts: Epic<IAnyAction>
    = (action$) => action$.pipe(
        mapToPayload("bankAccount", "bankAccountLoadAll"),
        mergeMap(() => bankAccountsApi.getAllAsync({})),
        map(ActionFactories.bankAccount.bankAccountLoadedAll));

export const loadBankAccount: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("bankAccount", "bankAccountLoad"),
            share()
        );

        const customScreens$ = requestedId$.pipe(
            mergeMap(() => customScreensApi.getAllAsync({})),
            map(customScreens => customScreens.filter(customScreen => customScreen.type === "SecurityInvestmentCustomScreenSummaryModel")),
            mergeMap(async customScreens => Promise.all(customScreens.map(({ id }) => customScreensApi.getAsync({ id })))),
            map(ActionFactories.security.securityCustomScreensLoaded));

        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => bankAccountsApi.getAsync({ id })),
                map(ActionFactories.bankAccount.bankAccountLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    bankAccount: { id: 0, name: "", shortName: "", securityExtensionFieldsValues: {} },
                    entities: {},
                    portfolios: {},
                } as IGetBankAccountModel)),
                map(ActionFactories.bankAccount.bankAccountLoaded)),
            requestedId$.pipe(
                filter(id => typeof id === "number" && !!id),
                map(id => ActionFactories.security.securityCustomScreenDatasLoad(typeof id === "number" ? id : 0))),
            customScreens$
        );
    };
export const saveBankAccount: Epic<IAnyAction>
    = action$ => {
        const savedElement$ = action$.pipe(
            mapToPayload("bankAccount", "bankAccountSave"),
            mergeMap(model => bankAccountsApi.saveAsync({ model })),
            map(ActionFactories.bankAccount.bankAccountSaved),
            share());
        return merge(
            savedElement$,
            savedElement$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
export const deleteBankAccount: Epic<IAnyAction>
    = (action$) => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("bankAccount", "bankAccountDelete"),
            mergeMap(id => bankAccountsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.bankAccount.bankAccountDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
export const submitCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityCustomScreenDataSubmit"),
        mergeMap(securitiesApi.submitCustomScreenDataAsync),
        map(ActionFactories.security.securityCustomScreenDataSubmitted));
export const loadCustomScreenData: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("security", "securityCustomScreenDatasLoad"),
        mergeMap(id => securitiesApi.getLastCustomScreenDatasAsync({ id })),
        map(ActionFactories.security.securityCustomScreenDatasLoaded));

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("bankAccount", "bankAccountDocumentsLoad"),
        mergeMap(id => bankAccountsApi.getDocumentsAsync({ id })),
        map(ActionFactories.bankAccount.bankAccountDocumentsLoaded));
