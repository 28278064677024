import produce from "immer";
import { IIndexModel, IHistoricalValuesModel, IIndexSummaryModel, IHistoricalValueSetModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IIndexesGetAllParameters } from "proxy/apiProxy";
export type VisualizationType = "GRID" | "CHART";

export interface IState {
    indexSaveHistoricalValueSaving: boolean;
    indexAllLoading: boolean;
    indexLoading: boolean;
    index?: IIndexModel;
    indexHistoricalValues?: IHistoricalValuesModel[];
    indexHistoricalLoading: boolean;
    indexSaving: boolean;
    indexDeleting: boolean;
    indexHistoricalValuesVisualizationType: VisualizationType;
    indexes: IIndexSummaryModel[];
}
export interface ISaveHistoricalValuePayload {
    indexId: number;
    historicalValue: IHistoricalValueSetModel;
}
export const ActionFactories = produceActionFactories({
    indexLoad: (payload: number) => payload,
    indexSearch: (payload: IIndexesGetAllParameters) => payload,
    indexLoaded: (payload: IIndexModel) => payload,
    indexSave: (payload: IIndexModel) => payload,
    indexSaved: (payload: IIndexModel) => payload,
    indexDelete: (payload: number) => payload,
    indexDeleted: (payload: number) => payload,
    indexLoadedAll: (payload: IIndexSummaryModel[]) => payload,
    indexHistoricalValuesLoad: (payload: number) => payload,
    indexHistoricalValuesLoaded: (payload: IHistoricalValuesModel[]) => payload,
    indexHistoricalValuesVisualizationTypeChanged: (payload: VisualizationType) => payload,
    indexHistoricalValueSave: (payload: ISaveHistoricalValuePayload) => payload,
    indexHistoricalValueSaved: (payload: ISaveHistoricalValuePayload) => payload,
});

export function reducer(
    state: IState = {
        indexSaveHistoricalValueSaving: false,
        indexLoading: false,
        indexAllLoading: false,
        indexSaving: false,
        indexDeleting: false,
        indexHistoricalLoading: false,
        indexHistoricalValuesVisualizationType: "GRID",
        indexes: []
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "indexSearch":
                draft.indexAllLoading = true;
                break;
            case "indexLoadedAll":
                draft.indexAllLoading = false;
                draft.indexes = action.payload;
                break;
            case "indexLoad":
                draft.indexLoading = true;
                draft.index = undefined;
                break;
            case "indexLoaded":
                draft.indexLoading = false;
                draft.index = action.payload;
                break;
            case "indexSave":
                draft.indexSaving = true;
                break;
            case "indexSaved":
                draft.indexSaving = false;
                const model = action.payload;
                draft.index = model;
                const existing = draft.indexes.find(i => i.id === model.id);
                if (existing) {
                    Object.assign(existing, model);
                }
                else {
                    draft.indexes.push(model);
                }
                break;
            case "indexHistoricalValuesVisualizationTypeChanged":
                draft.indexHistoricalValuesVisualizationType = action.payload;
                break;
            case "indexDelete":
                draft.indexDeleting = true;
                break;
            case "indexDeleted":
                draft.indexDeleting = false;
                const deletedId = action.payload;
                if (draft.index && draft.index.id === deletedId) {
                    delete draft.index;
                }
                const idx = draft.indexes.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.indexes.splice(idx, 1);
                }
                break;
            case "indexHistoricalValuesLoaded":
                draft.indexHistoricalValues = action.payload;
                draft.indexHistoricalLoading = false;
                break;
            case "indexHistoricalValuesLoad":
                draft.indexHistoricalLoading = true;
                break;



            case "indexHistoricalValueSave":
                draft.indexSaveHistoricalValueSaving = true;
                break;
            case "indexHistoricalValueSaved":
                draft.indexSaveHistoricalValueSaving = false;
                if (draft.indexHistoricalValues) {
                    const hv = draft.indexHistoricalValues.find(i => i.date === action.payload.historicalValue.date);
                    if (!!hv) {
                        if (!action.payload.historicalValue.value) {
                            delete hv.values[action.payload.historicalValue.type];
                        } else {
                            hv.values[action.payload.historicalValue.type] = action.payload.historicalValue.value;
                        }
                    } else {
                        if (action.payload.historicalValue.value != null) {
                            const newHv: IHistoricalValuesModel = {
                                date: action.payload.historicalValue.date,
                                values: {}
                            }
                            newHv.values[action.payload.historicalValue.type] = action.payload.historicalValue.value;
                            draft.indexHistoricalValues.push(newHv);
                            draft.indexHistoricalValues.sort((a, b) => a.date.getTime() - b.date.getTime());
                        }
                    }
                }
                break;
        }
    });
}
