import * as React from "react";
import { ISubFundSummaryModel, ICurrencyModel, IClassificationTypeModel, BenchmarkPositionModel, SecuritySummaryModel, ICountryModel, isSecurityInstrumentSummaryModel, isRegularSecuritySummaryModel, IIndexSummaryModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFlatClassificationTypes } from "features/Classification/getFlatClassificationTypes";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";

export interface IPortfolioBenchmarkCompositionGridProps {
    subFunds: Record<number | string, ISubFundSummaryModel>;
    securities: Record<number | string, SecuritySummaryModel>;
    indexes: Record<number | string, IIndexSummaryModel>;
    positions: BenchmarkPositionModel[];
    referenceCurrencies: Record<number | string, ICurrencyModel>;
    referenceCountries: Record<number | string, ICountryModel>;
    classificationTypes: IClassificationTypeModel[];
    classificationTypesFlat: IFlatClassificationTypes;
    onTargetSecuritySelected?: (securityId: number) => void;
    onTargetIndexSelected?: (indexId: number) => void;
}

export default function PortfolioBenchmarkCompositionGrid({
    subFunds,
    securities,
    indexes,
    positions,
    referenceCurrencies,
    classificationTypes,
    classificationTypesFlat,
    onTargetSecuritySelected,
    onTargetIndexSelected,
    referenceCountries
}: IPortfolioBenchmarkCompositionGridProps) {

    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, (p: BenchmarkPositionModel) => {
        switch (p.type) {
            case "BenchmarkSecurityPositionModel":
                const secu = securities[p.securityId];
                if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
                    const classifs = subFunds[secu.subFundId].classifications;
                    if (classifs) {
                        return classifs;
                    }
                }
                if (isSecurityInstrumentSummaryModel(secu)) {
                    return secu.classifications;
                }
                break;
            case "BenchmarkIndexPositionModel":
                const idx = indexes[p.indexId];
                return idx.classifications;
        }
        return undefined;
    });

    const columns = React.useMemo(() => ([
        {
            name: "TargetSecurityCode", title: "Sec/Idx Code", getCellValue: (p: BenchmarkPositionModel) => p.type === "BenchmarkSecurityPositionModel" ? securities[p.securityId]?.internalCode : indexes[p.indexId]?.internalCode, filteringEnabled: true
        },
        {
            name: "TargetSecurityName", title: "Sec/Idx Name", getCellValue: (p: BenchmarkPositionModel) => p.type === "BenchmarkSecurityPositionModel" ? securities[p.securityId]?.name : indexes[p.indexId]?.name, filteringEnabled: true
        },
        {
            name: "TargetSecurityType", title: "Type", getCellValue: (p: BenchmarkPositionModel) => p.type === "BenchmarkSecurityPositionModel" ? getSecurityTypeLabel(securities[p.securityId]?.type) : "Index"
        },
        ...classificationTypesColumns,
        {
            name: "Currency",
            title: "Currency",
            getCellValue: (p: BenchmarkPositionModel) => {
                switch (p.type) {
                    case "BenchmarkIndexPositionModel":
                        const bCurrencyId = indexes[p.indexId]?.referenceCurrencyId;
                        if (!bCurrencyId) {
                            return;
                        }
                        return referenceCurrencies[bCurrencyId]?.isoCode;
                    case "BenchmarkSecurityPositionModel":
                        const currencyId = securities[p.securityId]?.currencyId;
                        if (!currencyId) {
                            return;
                        }
                        return referenceCurrencies[currencyId]?.isoCode;
                }
            }, filteringEnabled: true
        },
        {
            name: "Country", title: "Country", getCellValue: (p: BenchmarkPositionModel) => {
                switch (p.type) {
                    case "BenchmarkSecurityPositionModel":
                        const secu = securities[p.securityId];
                        let countryId: number | undefined;
                        if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
                            if (subFunds[secu.subFundId].countryId) {
                                countryId = subFunds[secu.subFundId].countryId
                            }
                        }
                        else if (isRegularSecuritySummaryModel(secu)) {
                            countryId = secu.countryId;
                        }
                        if (!countryId) {
                            return undefined;
                        }
                        const country = referenceCountries[countryId];
                        if (!country) {
                            return "???";
                        }
                        return country.name?.en;
                    case "BenchmarkIndexPositionModel":
                        const bCountryId = indexes[p.indexId]?.referenceCountryId;
                        if (!bCountryId) {
                            return;
                        }
                        return referenceCurrencies[bCountryId]?.name?.en;
                }
            }, filteringEnabled: true
        },
        {
            name: "TargetSecurityIsin", title: "Isin/Code", getCellValue: (p: BenchmarkPositionModel) => {
                switch (p.type) {
                    case "BenchmarkSecurityPositionModel":
                        const secu = securities[p.securityId];
                        return isSecurityInstrumentSummaryModel(secu) ? secu.isin : undefined;
                }
                return undefined;
            }, filteringEnabled: true
        },
        { name: "Quantity", title: "Qty", getCellValue: ({ quantity: value }: BenchmarkPositionModel) => value, columnType: "decimal" },
        { name: "Weight", title: "Weight", getCellValue: ({ weight }: BenchmarkPositionModel) => weight, columnType: "precisePercentage2", aggregationType: "sum" },
        { name: "PreciseWeight", title: "Weight (precise)", getCellValue: ({ weight }: BenchmarkPositionModel) => weight, columnType: "precisePercentage4", aggregationType: "sum" },
    ] as IColumnDefinition[]), [classificationTypesColumns, securities, indexes, referenceCurrencies, referenceCountries, subFunds]);
    const state: IGridState = {
        "TargetSecurityCode": { width: 150, hidden: true },
        ...classificationTypesState,
        "TargetSecurityName": { width: 320 },
        "TargetSecurityType": { width: 120 },
        "Country": { width: 120, hidden: true },
        "Currency": { width: 130 },
        "TargetSecurityIsin": { width: 150 },
        "Quantity": { width: 180, hidden: true },
        "Weight": { width: 150 },
        "PreciseWeight": { width: 150, hidden: true },
    };

    const getRowKey = (row: BenchmarkPositionModel) => row.id;

    const handleRowSelect = (p: BenchmarkPositionModel) => {
        switch (p.type) {
            case "BenchmarkIndexPositionModel":
                if (onTargetIndexSelected) {
                    onTargetIndexSelected(p.indexId);
                }
                break;
            case "BenchmarkSecurityPositionModel":
                if (onTargetSecuritySelected) {
                    onTargetSecuritySelected(p.securityId);
                }
                break;
        }
    }

    return <ExtendedGrid
        getRowId={getRowKey}
        columns={columns}
        rows={positions}
        initialState={state}
        userCanGroup={true}
        defaultExportFileName="composition.xlsx"
        onRowClick={onTargetSecuritySelected ? handleRowSelect : undefined} />
}
