import * as React from "react";
import { IMarketDataProviderModel } from "proxy/apiProxy";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps } from "tools/lib/utility";
import { Button, Grid } from "@material-ui/core";
import { useField } from "formik";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
export interface IDataProviderCodesProps {
    formFieldName: string;
    valorFieldName: string
    wknFieldName: string
    cusipFieldName: string
    isinFieldName: string
    parametersMarketDataProviders: IMarketDataProviderModel[];
}

export default function DataProviderCodes({ formFieldName, parametersMarketDataProviders, valorFieldName, wknFieldName, cusipFieldName, isinFieldName }: IDataProviderCodesProps) {
    const [expanded, setExpanded] = React.useState(false);
    const [{ value = {} }] = useField<Record<string, string> | undefined>(formFieldName);
    const [{ value: valor = "" }] = useField<string | undefined>(valorFieldName);
    const [{ value: wkn = "" }] = useField<string | undefined>(wknFieldName);
    const [{ value: cusip = "" }] = useField<string | undefined>(cusipFieldName);
    const [{ value: isin = "" }] = useField<string | undefined>(isinFieldName);
    return <>
        {(expanded || isin !== "") && <Grid item={true} xs={3}>
            <FormTextField name={isinFieldName} label="Isin" maxLength={12} />
        </Grid>}
        {(expanded || valor !== "") && <Grid item={true} xs={3}>
            <FormTextField name={valorFieldName} label="Valor" maxLength={12} />
        </Grid>}
        {(expanded || wkn !== "") && <Grid item={true} xs={3}>
            <FormTextField name={wknFieldName} label="Wkn" maxLength={12} />
        </Grid>}
        {(expanded || cusip !== "") && <Grid item={true} xs={3}>
            <FormTextField name={cusipFieldName} label="Cusip" maxLength={12} />
        </Grid>}
        {parametersMarketDataProviders.filter(mdp => expanded || (!!value[mdp.marketDataProviderCode] && value[mdp.marketDataProviderCode] !== "")).map(({ marketDataProviderCode, marketDataProviderName }) => <Grid item={true} xs={3} key={marketDataProviderCode}>
            <FormTextField name={oProps<Record<string, string>>(formFieldName).path(marketDataProviderCode)} label={marketDataProviderName} />
        </Grid>)}
        <Grid item={true} xs={3}>
            <MoreLessButton expanded={expanded} onChanged={setExpanded} />
        </Grid>
    </>;
}

interface IExpandCollapseButtonProps {
    expanded: boolean;
    onChanged: (expanded: boolean) => void;
}
export function MoreLessButton({ expanded, onChanged }: IExpandCollapseButtonProps) {
    const handleClick = () => onChanged(!expanded);
    return <Button
        variant="contained"
        onClick={handleClick} startIcon={expanded ? <ExpandLessIcon /> : <MoreHorizIcon />} >
        {expanded ? "Show Less" : "Edit More"}
    </Button>
}
