import FormTextField from "tools/fieldComponents/FormTextField";
import { Grid, Button, TextField, Fab, Typography, Box } from "@material-ui/core";
import { useCallback, useState, useMemo } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import { useField } from "formik";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import AddIcon from '@material-ui/icons/Add';
import { oProps } from "tools/lib/utility";


export interface IStringToStringDictionaryFieldProps {
    path: string;
    label: string;
}

export function StringToStringDictionaryField({ path, label }: IStringToStringDictionaryFieldProps) {
    const [{ value: parameters = {} }, , { setValue }] = useField<Record<string, string>>(path);
    const keys = useMemo(() => Object.keys(parameters), [parameters]);
    const handleChange = useCallback((key: string, newKey: string) => {
        const { [key]: value, ...newParameters } = parameters;
        setValue({ ...newParameters, [newKey]: value }, true);
    }, [parameters, setValue]);
    const handleAdd = useCallback(() => { setValue({ ...parameters, newParameter: "value" }, true) }, [parameters, setValue]);
    const handleDelete = useCallback((key: string) => {
        const { [key]: oldValue, ...newParameters } = parameters;
        delete newParameters[key];
        setValue(newParameters, true);
    }, [parameters, setValue]);
    return <ReadOnlyContext.Consumer>
        {readOnly => <>
            <Box display="flex" gridGap={8} ><Typography variant="h6" gutterBottom>{label}</Typography>{!readOnly &&
                <Fab color="primary" aria-label="Add" onClick={handleAdd} size="small">
                    <AddIcon fontSize="small" />
                </Fab>}
            </Box>
            <Grid container>
                {keys.map(key => <StringToStringDictionaryItem
                    key={key}
                    itemKey={key}
                    readOnly={readOnly}
                    path={oProps<Record<string, string>>(path).path(key)}
                    onKeyChange={handleChange}
                    onDelete={handleDelete} />)}
            </Grid >
        </>
        }</ReadOnlyContext.Consumer>
}

interface IStringToStringDictionaryItemProps {
    readOnly: boolean;
    itemKey: string;
    onKeyChange: (key: string, newKey: string) => void;
    onDelete: (key: string) => void;
    path: string;
}
function StringToStringDictionaryItem({ readOnly, itemKey: key, onKeyChange, path, onDelete }: IStringToStringDictionaryItemProps) {
    const [currentKey, setCurrentKey] = useState(key);
    const handleKeyBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => onKeyChange(key, currentKey), [key, onKeyChange, currentKey]);
    const handleKeyChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setCurrentKey(e.target.value), [setCurrentKey]);
    const handleDelete = useCallback(() => onDelete(key), [onDelete, key]);
    return <Grid container item key={key} xs={12} spacing={1} alignItems="center">
        <Grid item xs={4}>
            <TextField
                fullWidth={true}
                disabled={readOnly}
                label="Name"
                margin="dense"
                value={currentKey}
                onChange={handleKeyChange}
                onBlur={handleKeyBlur} />
        </Grid>
        <Grid item xs={5}>
            <FormTextField name={path} label="Value" />
        </Grid>
        {!readOnly && <Grid item xs={1}>
            <Button onClick={handleDelete} size="small">
                <DeleteIcon />
            </Button>
        </Grid>}
    </Grid>;
}
