import { GetAllSummaries } from "../utils";
import FieldBox from "tools/components/FieldBox";
import { oProps } from "tools/lib/utility";
import { DocumentRelatedGrid } from "./DocumentRelatedGrid";
import { IDocumentData } from "../slice";

export interface IDocumentRelatedProps {
    dictionaries: GetAllSummaries;
}

export default function DocumentRelated({ dictionaries }: IDocumentRelatedProps) {

    return <FieldBox display="flex" flexDirection="column" style={{ width: "75%" }} padding={2}>
        <DocumentRelatedGrid
            name={oProps<IDocumentData>().path("document", "related")}
            dictionaries={dictionaries}
        />
    </FieldBox>
}
