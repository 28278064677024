import FieldBox from "tools/components/FieldBox";
import FormTextField from "tools/fieldComponents/FormTextField";
import {
    ReportModel,
    IReportTemplateSummaryModel,
    IParameterMetadataModel,
    IFileTypeModel,
    ITemplateReportModel,
    IDocumentReportModel,
    IDocumentDefinitionSummaryModel,
    IReportModel
} from "proxy/apiProxy";
import { oProps, cultures, ICultureItem, getEnumLabels } from "tools/lib/utility";
import FormMultiSelectField from "tools/fieldComponents/FormMultiSelectField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import ScopeDescriptionData from "./ScopeDescriptionData";
import { SummaryField } from "components/global/SummaryField";
import { useField } from "formik";
import { StringToStringDictionaryField } from "tools/fieldComponents/StringToStringDictionaryField";

const fileTypes = getEnumLabels(IFileTypeModel);
const reportType: Record<ReportModel["type"], string> = {
    DocumentReportModel: "Document",
    TemplateReportModel: "Template"
}

export interface IReportDataProps {
    reportTemplates: IReportTemplateSummaryModel[];
    documentDefinitions: IDocumentDefinitionSummaryModel[];
    onSelectReportTemplate: (reportTemplateId: number | undefined) => void;
    onSelectDocumentDefinition: (reportTemplateId: number | undefined) => void;
    onOpenReportTemplate: () => void;
    onOpenDocumentDefinition: () => void;
    parameterMetadata: IParameterMetadataModel[];
}

function ReportData({
                        reportTemplates,
                        documentDefinitions,
                        parameterMetadata,
                        onSelectReportTemplate,
                        onSelectDocumentDefinition
                    }: IReportDataProps) {
    const getValue = (c: ICultureItem) => c.code;
    const getPrimaryContent = (c: ICultureItem) => c.label;
    const handleReportTemplateChanged = (id: number | string | undefined) => onSelectReportTemplate(typeof (id) === "number" ? id : undefined);
    const handleDocumentDefinitionChanged = (id: number | string | undefined) => onSelectDocumentDefinition(typeof (id) === "number" ? id : undefined);
    const [{value: exportType}] = useField<ReportModel["exportType"]>(oProps<ReportModel>().path("exportType"));
    const [{value: type}] = useField<ReportModel["type"]>(oProps<ReportModel>().path("type"));
    const [{value: rtId}] = useField<ITemplateReportModel["reportTemplateId"] | undefined>(oProps<ITemplateReportModel>().path("reportTemplateId"));
    const [{value: ddId}] = useField<IDocumentReportModel["documentDefinitionId"] | undefined>(oProps<IDocumentReportModel>().path("documentDefinitionId"));
    const [{value: scopeDescription}] = useField<ReportModel["scopeDescription"]>(oProps<ReportModel>().path("scopeDescription"));
    const handleTypeChanged = (t: number | string | undefined) => {
        switch (t as ReportModel["type"]) {
            case "TemplateReportModel":
                handleReportTemplateChanged(rtId);
                break;
            case "DocumentReportModel":
                handleDocumentDefinitionChanged(ddId);
                break;
        }
    };
    // const { reportSelectReportTemplate } = useReduxActions("report");
    return (
        <FieldBox display="flex" flexDirection="column">
            <FieldBox display="flex" flexDirection="row">
                <FormTextField name={oProps<ReportModel>().path("code")} label="Code" required={true} maxLength={100}/>
                <FormTextField name={oProps<ReportModel>().path("name")} label="Name" required={true} maxLength={250}/>
                <FormSimpleSelectField name={oProps<ReportModel>().path("type")} label="Type" options={reportType}
                                       onSelectionChanged={handleTypeChanged} required={true}/>
            </FieldBox>
            <FieldBox display="flex" flexDirection="row">
                {type === "TemplateReportModel"
                    ? <FormSimpleSelectField name={oProps<ITemplateReportModel>().path("reportTemplateId")}
                                             label="Template" options={reportTemplates}
                                             onSelectionChanged={handleReportTemplateChanged} required={true}/>
                    : <FormSimpleSelectField name={oProps<IDocumentReportModel>().path("documentDefinitionId")}
                                             label="Document definitions" options={documentDefinitions}
                                             onSelectionChanged={handleDocumentDefinitionChanged} required={true}/>}
                <FormMultiSelectField name={oProps<ReportModel>().path("cultureInfos")} label="Cultures"
                                      options={cultures} getValue={getValue} getPrimaryContent={getPrimaryContent}
                                      required={true}/>
            </FieldBox>
            <ScopeDescriptionData
                formFieldName={oProps<ReportModel>().path("scopeDescription")}
                parameterMetadatas={parameterMetadata}
                afterGroup={<FormSimpleSelectField name={oProps<ReportModel>().path("exportType")} label="File Type"
                                                   options={fileTypes} required={true}/>}
                groupSummary={<SummaryField label="File Name"
                                            value={`${((scopeDescription.groupLabelExpression ?? "") === "" ? "(not defined)" : scopeDescription.groupLabelExpression)}.${exportType}`}/>}/>
            <StringToStringDictionaryField path={oProps<IReportModel>().path("parameterValues")} label="Parameters"/>
        </FieldBox>
    );
}

export default ReportData;
