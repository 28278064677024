import { useMemo } from "react";
import { useFieldEx, IFormField, ValidatorParams } from 'tools/lib/fieldHelpers';
import { ReadOnlyContext } from "./ReadOnlyContext";
import ImageSelect from 'tools/components/ImageSelect';
// import mime from "mime-types";
const validators = {
    required: (v: string | undefined, isTrue?: boolean) => isTrue && (v ?? "").length === 0 ? `Required` : undefined,
}
export interface IFileConverter<T> {
    fromFile: (fileType: File) => Promise<T>;
    toFile: (fieldType: T) => File;
}
export interface IFormSelectImageFieldProps<T = File> extends IFormField, ValidatorParams<typeof validators> {
    onClick?: () => void;
    contentName: string;
    onFileSelected?: (file: T | undefined) => void;
    converter?: IFileConverter<T>
}
export default function FormSelectImageField<T = File>({ name, helperText, label, disabled, error, onClick, contentName, onFileSelected, converter: fileConverter, ...requestedValidations }: IFormSelectImageFieldProps<T>) {
    const { helperText: fieldHelperText, label: fieldLabel, onValueChanged, value } = useFieldEx<T, typeof validators>({ name, helperText, label, required: requestedValidations.required, error, validators, requestedValidations });
    const converter: IFileConverter<T> = useMemo(() => fileConverter ?? {
        fromFile: async fileType => fileType as unknown as T,
        toFile: fileType => fileType as unknown as File
    }, [fileConverter]);
    // new File([base64toBlob(value.content, value.mimeType)], value.fileName)
    const newFile = useMemo(() => value ? converter.toFile(value) : undefined, [value, converter]);
    const handleFileOpen = async (file: File) => {
        const tmp = await converter.fromFile(file);
        //await fileToBase64(file);
        onValueChanged(tmp);
        onFileSelected && onFileSelected(tmp);
    }
    const handleFileClear = () => {
        onValueChanged(undefined);
        onFileSelected && onFileSelected(undefined);
    }
    const handleClick = () => onClick && onClick();
    return <ReadOnlyContext.Consumer>
        {readOnly => <ImageSelect
            emptyLabel={fieldHelperText ?? fieldLabel ?? undefined}
            disabled={disabled || readOnly}
            onClick={handleClick}
            onImageSelected={handleFileOpen}
            onClear={handleFileClear}
            file={newFile} />}
    </ReadOnlyContext.Consumer>
}
