import { Typography, Card, CardHeader, CardContent, IconButton, Box, makeStyles } from "@material-ui/core";
import CogsIcon from "mdi-material-ui/Cogs";
import CogIcon from "mdi-material-ui/CogClockwise";
import FileMultipleOutlineIcon from "mdi-material-ui/FileMultipleOutline";
import GraphIcon from "mdi-material-ui/Graph";
import FieldBox from "tools/components/FieldBox";
import { ITaskModel } from "proxy/apiProxy";
import { BatchInstanceStatusBadge } from "./BatchInstanceStatusBadge";
import React from "react";
import { SummaryField } from "components/global/SummaryField";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { splitPascalCase } from "tools/lib/utility";
import { ITask, isSimpleTask, isStreamTask, IStreamTask, ISimpleTask, IScript, parseScript, isEtlTask, IEtlTask } from "./batchParser";



function TaskVisualization({ task }: { task: ITask }) {
    if (isSimpleTask(task)) {
        return <SimpleTaskVisualization task={task} />;
    }
    else if (isStreamTask(task)) {
        return <StreamTaskVisualization task={task} />;
    }
    else if (isEtlTask(task)) {
        return <EtlTaskVisualization task={task} />
    }
    return null;
}

const StyledPaper = withStyles(theme =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
            "&>:first-child": {
                marginRight: theme.spacing(2)
            }
        }
    })
)(Box);
const useStyles = makeStyles(theme =>
    createStyles({
        streamedNodes: {
            display: "flex",
            "& > *": {
                padding: theme.spacing(1),
                borderTopStyle: "solid",
                borderTopWidth: 2,
                borderTopColor: "lightgray",
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
                borderBottomColor: "lightgray",
                position: "relative",
                "&:first-child": {
                    borderLeftStyle: "solid",
                    borderLeftWidth: 2,
                    borderLeftColor: "lightgray",
                    borderTopLeftRadius: theme.spacing(1),
                    borderBottomLeftRadius: theme.spacing(1),
                },
                "&:last-child": {
                    borderRightStyle: "solid",
                    borderRightWidth: 2,
                    borderRightColor: "lightgray",
                    borderTopRightRadius: theme.spacing(1),
                    borderBottomRightRadius: theme.spacing(1),
                },
                "&:not(:first-child)": {
                    paddingLeft: 35,
                    "&:before": {
                        content: '" "',
                        position: "absolute",
                        top: "50%",
                        left: 8,
                        height: "50%",
                        borderLeftStyle: "solid",
                        borderLeftWidth: 2,
                        borderLeftColor: "lightgray",
                        transform: "skew(-20deg, 0deg)"
                    },
                    "&:after": {
                        content: '" "',
                        position: "absolute",
                        top: 0,
                        left: 8,
                        height: "50%",
                        borderLeftStyle: "solid",
                        borderLeftWidth: 2,
                        borderLeftColor: "lightgray",
                        transform: "skew(20deg, 0deg)"
                    }
                }
            }
        },
    }),
);

function StreamTaskVisualization({ task: { Input = [], Pipeline = [] } }: { task: IStreamTask }) {
    const classes = useStyles();
    if (!Array.isArray(Input)) {
        Input = [Input];
    }
    if (!Array.isArray(Pipeline)) {
        Pipeline = [Pipeline];
    }
    return <div className={classes.streamedNodes}>
        <div>
            {Input.map(step => <StyledPaper key={step.Name ?? step.Type}><FileMultipleOutlineIcon /><SummaryField label={splitPascalCase(step.Type)} noUnderline={true} value={step.Name ?? splitPascalCase(step.Type)} /></StyledPaper>)}
        </div>
        {Pipeline.map(step => <StyledPaper key={step.Name ?? step.Type}><CogsIcon /><SummaryField label={splitPascalCase(step.Type)} noUnderline={true} value={step.Name ?? splitPascalCase(step.Type)} /></StyledPaper>)}
    </div>;
}
function SimpleTaskVisualization({ task: { Processor } }: { task: ISimpleTask }) {
    const classes = useStyles();
    if (!Processor) {
        return null;
    }
    return <div className={classes.streamedNodes}>
        <StyledPaper><CogIcon /><SummaryField label={splitPascalCase(Processor.Type)} noUnderline={true} value={Processor.Name ?? splitPascalCase(Processor.Type)} /></StyledPaper>
    </div>;
}
function EtlTaskVisualization({ task: { Etl } }: { task: IEtlTask }) {
    const classes = useStyles();
    if (!Etl) {
        return null;
    }
    return <div className={classes.streamedNodes}>
        <StyledPaper><CogIcon /><SummaryField label="Etl" noUnderline={true} value={splitPascalCase(Etl.EtlName)} /></StyledPaper>
    </div>;
}

function TaskAction({ id, onTaskClick }: { id: number, onTaskClick: (id: number) => void }) {
    const onClick = () => onTaskClick(id);
    return <IconButton onClick={onClick} >
        <GraphIcon />
    </IconButton>;
}
export interface IBatchScriptVisualizationProps {
    parsedScript?: IScript;
    script?: string;
    onTaskClick?: (idx: number) => void;
    executedTasks?: ITaskModel[];
};
export default function BatchScriptVisualization({ parsedScript, script, onTaskClick, executedTasks = [] }: IBatchScriptVisualizationProps) {
    const tasksExecution = React.useMemo(() => executedTasks.reduce((a, v) => { a[v.name] = v; return a; }, {} as Record<string, ITaskModel>), [executedTasks]);
    const parsed = React.useMemo(() => parsedScript ?? (!!script ? parseScript(script) : undefined), [parsedScript, script])
    const tasksSetup = React.useMemo(() => {
        if (!parsed) {
            return null;
        }
        return Object.keys(parsed)
            .filter(key => key !== "$schema")
            .map((key, idx) => ({ name: key, task: parsed[key], executedTask: tasksExecution[key] }))
    }, [tasksExecution, parsed]);
    if (!tasksSetup) {
        return <Typography>Incorrect batch</Typography>
    }
    return (<FieldBox display="flex" flexDirection="column">
        {tasksSetup.map(({ name, task, executedTask }, idx) =>
            <Card key={idx} elevation={3}>
                <CardHeader title={<>{`${idx + 1} - ${name} `}<BatchInstanceStatusBadge size="small" status={executedTask?.status} /></>} action={onTaskClick && <TaskAction id={executedTask.id} onTaskClick={onTaskClick} />} />
                <CardContent style={{ overflowX: "auto" }}>
                    <TaskVisualization task={task} />
                </CardContent>
            </Card>
        )}
    </FieldBox>);
}
