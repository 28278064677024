import { FieldFormatting, MiscFieldTypes, NumericFieldType, ScreenLinkType, TimeFieldType } from "./DashboardFormattingContracts";
import {
    ReportLink,
    DashboardLink,
    ValueResultCheckComponent,
    ValueResultDateComponent,
    ValueResultMarkdownComponent,
    ValueResultNumberComponent,
    ValueResultStringComponent
} from "./DashboardComponents";

interface IFieldDashboardRendererProps {
    value: any;
    formatting: FieldFormatting;
}
export function FieldDashboardRenderer({ value, formatting }: IFieldDashboardRendererProps) {
    const { type, label = "" } = formatting;

    switch (type) {
        case MiscFieldTypes.Boolean:
            if (typeof value !== "boolean") {
                return null;
            }
            return <ValueResultCheckComponent label={label} value={value} />;
        case NumericFieldType.Currency:
            if (typeof value !== "number") {
                return null;
            }
            return <ValueResultNumberComponent label={label} value={value} numberType="Percentage2" />;
        case TimeFieldType.Date:
            if (!(value instanceof Date)) {
                return null;
            }
            return <ValueResultDateComponent label={label} value={value} dateType="Date" />;
        case TimeFieldType.DateTime:
            if (!(value instanceof Date)) {
                return null;
            }
            return <ValueResultDateComponent label={label} value={value} dateType="DateTime" />;
        case NumericFieldType.Decimal:
            if (typeof value !== "number") {
                return null;
            }
            return <ValueResultNumberComponent label={label} value={value} numberType="Number2" />;
        case NumericFieldType.Decimal4:
            if (typeof value !== "number") {
                return null;
            }
            return <ValueResultNumberComponent label={label} value={value} numberType="Number4" />;
        case NumericFieldType.Integer:
            if (typeof value !== "number") {
                return null;
            }
            return <ValueResultNumberComponent label={label} value={value} numberType="Integer" />;
        case NumericFieldType.Percentage:
            if (typeof value !== "number") {
                return null;
            }
            return <ValueResultNumberComponent label={label} value={value} numberType="Percentage" />;
        case NumericFieldType.Percentage2:
            if (typeof value !== "number") {
                return null;
            }
            return <ValueResultNumberComponent label={label} value={value} numberType="Percentage2" />;
        case NumericFieldType.Percentage4:
            if (typeof value !== "number") {
                return null;
            }
            return <ValueResultNumberComponent label={label} value={value} numberType="Percentage4" />;
        case MiscFieldTypes.String:
            if (typeof value !== "string") {
                return null;
            }
            return <ValueResultStringComponent label={label} value={value} />;
        case MiscFieldTypes.Markdown:
            if (typeof value !== "string") {
                return null;
            }
            return <ValueResultMarkdownComponent value={value} />;
        case ScreenLinkType.Person:
        case ScreenLinkType.Company:
        case ScreenLinkType.EntityGroup:
        case ScreenLinkType.ManagedSicav:
        case ScreenLinkType.Portfolio:
        case ScreenLinkType.ShareClass:
        case ScreenLinkType.Sicav:
        case ScreenLinkType.SubFund:
        case ScreenLinkType.Security:
        case ScreenLinkType.Investor:
        case ScreenLinkType.Role:
        case ScreenLinkType.Counterparty:
        case ScreenLinkType.Index:
        case ScreenLinkType.Movement:
        case ScreenLinkType.PortfolioTransaction:
        case ScreenLinkType.Cash:
        case ScreenLinkType.ProcessExecution:
            return <DashboardLink id={value} referenceType={type} textValue={label} />
        case "report":
            const asOfDate = formatting.asOfDate instanceof Date ? formatting.asOfDate : undefined;
            return <ReportLink
                datasetName={formatting.datasetName}
                fileType={formatting.fileType}
                id={value}
                textValue={label}
                reportTemplateName={formatting.templateName}
                targetType={formatting.targetType}
                taskCode={formatting.taskCode}
                culture={formatting.culture}
                asOfDate={asOfDate} />
        default:
            return null;
    }
}
