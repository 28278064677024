import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { dailyDataApi, portfolioComplianceApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadDates: Epic<IAnyAction>
    = action$ => {
        const dates$ = action$.pipe(
            mapToPayload("portfoliosCompliance", "portfoliosComplianceDatesLoad"),
            mergeMap(() => dailyDataApi.getPricingDatesAsync()),
            share());
        return merge(
            dates$.pipe(map(ActionFactories.portfoliosCompliance.portfoliosComplianceDatesLoaded)),
            dates$.pipe(
                filter(dates => dates.length > 0),
                map(pricingDates => pricingDates.reduce((agg, v) => {
                    if ((agg ? agg.getTime() : 0) - (v ? v.getTime() : 0) > 0) {
                        return agg;
                    }
                    return v;
                }, pricingDates[0])),
                map(ActionFactories.portfoliosCompliance.portfoliosComplianceCheck)));
    }

export const checkCompliance: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("portfoliosCompliance", "portfoliosComplianceCheck"),
        mergeMap(pricingDate =>  portfolioComplianceApi.checkPortfoliosAsync({ pricingDate })),
        map(ActionFactories.portfoliosCompliance.portfoliosComplianceChecked));