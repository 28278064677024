import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { ICouponTypeModel, IFeatureModel, ILoanModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { getEnumLabels, isNumber, useNumber } from "tools/lib/utility";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import LoanData from "./LoanData";
import Collaterals from "./Collaterals";
import Covenants from "./Covenants";
import { useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";
import { DocumentsGrid } from "features/Document/DocumentsScreen/DocumentsGrid";

export const loanScreen = {
    route: "/loans/:id/:tab?" as const,
    component: Loan,
    label: "Loan",
    tabs: {
        detail: "details",
        collaterals: "collaterals",
        covenants: "covenants",
        documents: "documents"
    },
}


export const couponTypes = getEnumLabels(ICouponTypeModel);

function Loan() {
    const {
        loanCurrent, loanLoading,
        loanSaving, dictionaries,
        documentsLoading, documents
    } = useReduxSelections("loan");
    const {
        loanDelete,
        loanSave,
        loanAddEntityInDictionary,
        loanAddIndexInDictionary,
        loanAddSecurityInDictionary,
        loanLoad,
        loanDocumentsLoad
    } = useReduxActions("loan");
    const navigate = useScreenNavigate();
    const { parameters: { securityExtensionFields = [] } } = useReduxSelections("parameters");

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof loanScreen>()
    const idNum = useNumber(id);

    useEffect(() => {
        if (isNumber(idNum)) loanLoad(idNum);
    }, [idNum, loanLoad]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "documents" && !documentsLoading && !documents)
            loanDocumentsLoad(idNum)
    }, [idNum, loanDocumentsLoad, documents, documentsLoading, tabValue]);

    if (!loanCurrent) {
        return <DetailPanelLoading hasSubTitle={false} />;
    }

    const handleDelete = () => {
        if (!!loanCurrent?.id) {
            loanDelete(loanCurrent.id);
        }
    }

    const title = !!loanCurrent?.id ? loanCurrent.name : 'New loan';
    const handleSubmit = (values: ILoanModel, { setSubmitting }: FormikHelpers<ILoanModel>) => {
        loanSave(values);
        setSubmitting(false);
    }

    const handleSecuritySelected = (securityId: number) => {
        const security = dictionaries.securities[securityId];
        if (security.type === "CashSummaryModel") {
            navigate("BankAccount", { id: securityId });
        }
        else if (security.type === "ShareClassSummaryModel" && security.isUnderManagement) {
            navigate("MyShareClass", { id: securityId });
        }
        else {
            navigate("Security", { id: securityId });
        }
    }

    return <Formik onSubmit={handleSubmit} initialValues={loanCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ values: formValues, dirty, isValid, submitForm }: FormikProps<ILoanModel>) {

        const tabs: ISubMenuTabs<typeof loanScreen>[] | undefined = [{
            label: "Data",
            value: "detail"
        }, {
            label: "Collaterals",
            value: "collaterals"
        }, {
            label: "Covenants",
            value: "covenants"
        }];

        if (formValues.id) {
            tabs.push({
                label: "Documents",
                value: "documents"
            });
        }

        return <WriteAccess value={IFeatureModel.ManagedPortfolioWrite}><DetailPanel
            isQuerying={loanLoading || loanSaving}
            tabs={tabs}
            title={title}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!loanCurrent?.id}
            onDeleteClick={handleDelete}
            saveAllowed={IFeatureModel.ManagedPortfolioWrite}
            deleteAllowed={IFeatureModel.ManagedPortfolioWrite}
            badge={!loanCurrent?.id ? "new" : undefined}
            tabValue={tabValue}>
            {(tabValue === "detail") && <LoanData
                securityExtensionFields={securityExtensionFields}
                currentValues={formValues}
                loanAddEntityInDictionary={loanAddEntityInDictionary}
                loanAddIndexInDictionary={loanAddIndexInDictionary}
                indexes={dictionaries.indexes}
                entities={dictionaries.entities} />}
            {(tabValue === "collaterals") && <Collaterals
                onSecuritySelected={handleSecuritySelected}
                subFunds={dictionaries.subFunds}
                securities={dictionaries.securities}
                addSecurityInDictionary={loanAddSecurityInDictionary} />}
            {(tabValue === "covenants") && <Covenants />}
            {(tabValue === "documents") && <DocumentsGrid
                documents={documents ?? []}
                dictionaries={dictionaries} />}
        </DetailPanel>
        </WriteAccess>
    }
}
