import { createStyles, IconButton, makeStyles, Paper } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';
import { useCallback } from "react";
import { isDescendant, NodeRendererProps, TreeIndex, TreePath } from 'react-sortable-tree';
import { MenuNodeData } from "./MenuNodeData";
import { ITreeItemModel, MenuTreeItem } from "./menuTree";
import clsx from "clsx";


export interface ExtraNodeRendererProps {
    onEdit: (data: { node: MenuTreeItem } & TreePath & TreeIndex) => void;
    onDelete: (path: TreePath) => void;
    readOnly: boolean;
}

const useStyles = makeStyles((theme) => createStyles({
    dragIcon: {
        cursor: "move",
        color: "lightgray",
        zIndex: 500
    },
    nodeContainer: {
        gap: theme.spacing(1),
        alignItems: "center",
    },
    nodeDescent: {
        opacity: 0.5,
    }

}))

export function MenuNodeRenderer(props: NodeRendererProps<ITreeItemModel>) {
    const {
        scaffoldBlockPxWidth,
        toggleChildrenVisibility,
        connectDragPreview,
        connectDragSource,
        isDragging,
        canDrop,
        canDrag,
        node,
        title,
        subtitle,
        draggedNode,
        path,
        treeIndex,
        isSearchMatch,
        isSearchFocus,
        buttons,
        className,
        style,
        didDrop,
        treeId,
        isOver, // Not needed, but preserved for other renderers
        parentNode, // Needed for dndManager
        rowDirection,
        ...otherProps
    } = props;

    const { onEdit, onDelete, readOnly } = otherProps as unknown as ExtraNodeRendererProps

    const nodeContent = node.model;

    const onClickEdit = useCallback(() => onEdit({ node, path, treeIndex }), [node, onEdit, path, treeIndex])
    const onClickDelete = useCallback(() => onDelete({ path }), [onDelete, path])


    const cls = useStyles();
    if (!nodeContent) return null;

    let handle = undefined;
    if (canDrag) {
        if (typeof node.children === 'function' && node.expanded) {
            // Show a loading symbol on the handle when the children are expanded
            //  and yet still defined by a function (a callback to fetch the children)
            handle = <div className="rst__loadingHandle">
                <div className="rst__loadingCircle">
                    {[...new Array(12)].map((_, index) =>
                        <div key={index} className='rst__loadingCirclePoint' />)}
                </div>
            </div>
        } else {
            // Show the handle used to initiate a drag-and-drop
            handle = connectDragSource(
                <div className={cls.dragIcon}><DragIndicatorIcon /></div>,
                { dropEffect: 'copy' }
            );
        }
    }


    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    return <div style={{ height: '100%', paddingTop: 10 }} {...otherProps}>
        {toggleChildrenVisibility && node.children && (node.children.length > 0 || typeof node.children === 'function') && (
            <div>
                <button
                    type="button"
                    aria-label={node.expanded ? 'Collapse' : 'Expand'}
                    className={clsx(node.expanded ? 'rst__collapseButton' : 'rst__expandButton')}
                    style={{ left: -0.5 * scaffoldBlockPxWidth }}
                    onClick={() => toggleChildrenVisibility({ node, path, treeIndex })} />
                {node.expanded && !isDragging &&
                    <div style={{ width: scaffoldBlockPxWidth }} className="rst__lineChildren" />}
            </div>)}
        <Paper style={{ padding: 10 }} elevation={2}>
            {connectDragPreview(<div
                className={clsx(
                    'rst__row',
                    isLandingPadActive && !canDrop && 'rst__rowCancelPad',
                    isLandingPadActive && 'rst__rowLandingPad',
                    isSearchMatch && 'rst__rowSearchMatch',
                    isSearchFocus && 'rst__rowSearchFocus',
                    className,
                    cls.nodeContainer,
                    isDraggedDescendant && cls.nodeDescent
                )} style={style}>
                {handle}
                <MenuNodeData model={nodeContent} />
                {(!readOnly) && <>
                    <IconButton size="small" onClick={onClickEdit}><EditIcon fontSize="small" /></IconButton>
                    <IconButton size="small" onClick={onClickDelete}><CloseIcon fontSize="small" /></IconButton>
                </>}
            </div>)}
        </Paper>
    </div>
}
