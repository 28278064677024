import React from "react";
import { Theme, createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import {
	Grow,
	Breadcrumbs,
	Typography,
	Box,
	IconButton,
	Link,
	InputLabel,
	FormControl,
	FormHelperText,
	Menu,
	MenuProps
} from "@material-ui/core";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from '@material-ui/icons/Close';

const StyledMenu = withStyles((theme) => ({
	paper: {
		padding: theme.spacing(1)
	},
}))((props: MenuProps) => (
	<Menu
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'left',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'left',
		}}
		PaperProps={{
			style: {
				maxHeight: 24 * 15
			},
		}}
		{...props}
	/>
));

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		inputBox: {
			marginTop: theme.spacing(2) + 2,
			marginBottom: -4,
			minHeight: 40,
			borderBottomColor: "rgba(0, 0, 0, 0.42)",
			borderBottomStyle: "solid",
			borderBottomWidth: 1,
			paddingTop: 6,
			'&:hover': {
				borderBottomColor: "black",
				borderBottomWidth: 2,
			}
		},
		disabledInputBox: {
			marginTop: theme.spacing(2) + 2,
			marginBottom: -4,
			minHeight: 40,
			borderBottomColor: "rgba(0, 0, 0, 0.42)",
			borderBottomStyle: "dotted",
			borderBottomWidth: 1,
			paddingTop: 6,
		},
	}),
);

type KeyType = string | number;

export interface ITreeViewSelectProps<T, K extends KeyType> {
	label?: React.ReactNode;
	readOnly?: boolean;
	error?: boolean;
	helperText?: React.ReactNode;
	options: T[];
	getChildren: (element?: T) => T[];
	getPrimaryContent: (element: T) => React.ReactNode;
	getKey: (element: T) => K;
	value?: K;
	onSelectionChanged?: (element?: K) => void;
	hideClearButton?: boolean;
	disabled?: boolean;
	fullWidth?: boolean;
}

function TreeViewSelect<T, K extends KeyType>({
	hideClearButton,
	label,
	error,
	options,
	getChildren,
	getPrimaryContent,
	helperText,
	getKey,
	disabled,
	value,
	onSelectionChanged,
	readOnly,
	fullWidth
}: ITreeViewSelectProps<T, K>) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [selectedPath, setSelectedPath] = React.useState<T[] | undefined>();

	const handleExpandClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget.parentElement);
	};
	const handleClearClick = () => {
		if (onSelectionChanged) {
			onSelectionChanged(undefined);
		}
	};
	const classes = useStyles();

	React.useEffect(() => {
		const getPathToElement: (elementKeyToFind: K, currentElementPath: T[]) => T[] | undefined = (elementToFind, currentElementPath) => {
			if (currentElementPath.length && elementToFind === getKey(currentElementPath[currentElementPath.length - 1])) {
				return currentElementPath;
			}
			const childrenNodes = getChildren(currentElementPath[currentElementPath.length - 1]);
			for (const iterator of childrenNodes) {
				const newPath = getPathToElement(elementToFind, [...currentElementPath, iterator]);
				if (!!newPath) {
					return newPath;
				}
			}
			return;
		}

		if (!value && selectedPath) {
			setSelectedPath(undefined);
		}
		else {
			if (value && (!selectedPath || !selectedPath.length || getKey(selectedPath[selectedPath.length - 1]) !== value)) {
				setSelectedPath(getPathToElement(value, []))
			}
		}
	}, [options, value, selectedPath, getKey, getChildren]);
	const open = Boolean(anchorEl);
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};
	const handleTreeItemClick = (elementPath: T[], event: React.MouseEvent) => {
		event.preventDefault();
		setAnchorEl(null);
		if (onSelectionChanged) {
			onSelectionChanged(getKey(elementPath[elementPath.length - 1]));
		}
	};
	function renderChildrenNodes(parentPath: T[]) {
		const childrenNodes = getChildren(parentPath.length ? parentPath[parentPath.length - 1] : undefined);

		return childrenNodes.map(childNode => (
			<TreeItem
				key={getKey(childNode)}
				nodeId={getKey(childNode).toString()}
				label={getPrimaryContent(childNode)}
				onLabelClick={handleTreeItemClick.bind(null, [...parentPath, childNode])}
			>
				{renderChildrenNodes([...parentPath, childNode])}
			</TreeItem>
		));
	}
	function renderSelection(pathToCurrent: T[]) {
		return <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />} aria-label="breadcrumb">
			{pathToCurrent.map((cur, idx) => ((!disabled && idx < pathToCurrent.length - 1) ? <Link color="inherit" key={idx} href="#" onClick={handleTreeItemClick.bind(null, pathToCurrent.filter((c, i) => i <= idx))}>{getPrimaryContent(cur)}</Link>
				: <Typography key={idx} color="textPrimary">{getPrimaryContent(cur)}</Typography>))}
		</Breadcrumbs>;
	}
	const selectedLabel = selectedPath && selectedPath.length && renderSelection(selectedPath);
	return <>
		<FormControl fullWidth={fullWidth}>
			<InputLabel error={!!error} shrink={!!(selectedPath?.length)}>{label}</InputLabel>
			<Box display="flex" alignItems="center" className={disabled ? classes.disabledInputBox : classes.inputBox}>
				{selectedLabel}
				<input type="hidden" value={selectedPath && getKey(selectedPath[selectedPath.length - 1])} />
				{!readOnly && <>
					<Box flexGrow={1} />
					{(!hideClearButton && !!selectedPath?.length) && <IconButton disabled={disabled} onClick={handleClearClick} size="small">
						<CloseIcon fontSize="small" />
					</IconButton>}
					<IconButton disabled={disabled} onClick={handleExpandClick} size="small">
						{open ? <ArrowDropUpIcon fontSize="small" /> : <ArrowDropDownIcon fontSize="small" />}
					</IconButton>
				</>}
			</Box>
			<FormHelperText error={!!error}>{helperText}</FormHelperText>
		</FormControl>
		<StyledMenu open={open} anchorEl={anchorEl} onClose={handleCloseMenu} TransitionComponent={Grow}>
			<TreeView
				defaultExpanded={selectedPath?.map(c => getKey(c)).filter((c, i) => i < selectedPath.length - 1).map(c => c.toString())}
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpandIcon={<ChevronRightIcon />}
				selected={(value ?? "").toString()}>
				{renderChildrenNodes([])}
			</TreeView>
		</StyledMenu>
	</>
}
export default React.memo(TreeViewSelect) as <T, K extends KeyType>(props: ITreeViewSelectProps<T, K>) => React.ReactElement;