import { NodeModel, DefaultPortModel, LinkModel, DefaultLinkModel, PortModelAlignment } from '@projectstorm/react-diagrams';
import { BaseModelOptions, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { AbstractReactFactory, GenerateWidgetEvent, GenerateModelEvent } from '@projectstorm/react-canvas-core';
import { DiagramEngine, PortWidget } from '@projectstorm/react-diagrams-core';
import styled from '@emotion/styled';
import { Box } from "@material-ui/core";

import Battery20Icon from "@material-ui/icons/Battery20";
import Battery60Icon from "@material-ui/icons/Battery60";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";

import BatteryCharging20Icon from "@material-ui/icons/BatteryCharging20";
import BatteryCharging60Icon from "@material-ui/icons/BatteryCharging60";
import BatteryChargingFullIcon from "@material-ui/icons/BatteryChargingFull";

import { SummaryField } from "components/global/SummaryField";

export interface EtlOperatorNodeModelOptions extends BaseModelOptions {
	properties?: IGraphNodeProperties;
}


export class ArrowLinkPortModel extends DefaultPortModel {
	createLinkModel(): LinkModel {
		return new DefaultLinkModel({ curvyness: 10 });
	}
}

export class EtlOperatorNodeModel extends NodeModel {
	properties?: IGraphNodeProperties;

	constructor(options: EtlOperatorNodeModelOptions = {}) {
		super({
			...options,
			type: 'etl-operator-node'
		});
		this.properties = options.properties;
		this.addPort(new DefaultPortModel({ in: true, name: 'in', alignment: PortModelAlignment.TOP }));
		this.addPort(new ArrowLinkPortModel({ in: false, name: 'out', alignment: PortModelAlignment.BOTTOM }));
		// this.addPort(new DefaultPortModel({ in: false, name: 'out' }));
	}


	serialize() {
		return { ...super.serialize() };
	}

	deserialize(event: DeserializeEvent<this>): void {
		super.deserialize(event);
		// this.element = event.data.element;
	}
}

export interface IEtlOperatorNodeWidgetProps {
	node: EtlOperatorNodeModel;
	engine: DiagramEngine;
	// properties:IGraphNode
}

export const TitleNode = styled.div(({ isSelected, isErrored }: { isSelected: boolean, isErrored: boolean }) => ({
	borderStyle: "solid",
	borderWidth: 2,
	borderColor: isErrored ? "red" : isSelected ? "white" : "gray",
	borderRadius: 5,
	paddingLeft: 5,
	paddingRight: 5,
	paddingTop: 0,
	paddingBottom: 0,
	backgroundColor: "rgba(255, 255, 255, 0.8)",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "space-between",
	gap: 0
}));

export const CirclePort = styled.div({
	width: 12,
	height: 12,
	margin: -5,
	borderRadius: 4,
	background: "darkgray",
	cursor: "pointer",
	": hover": {
		background: "mediumpurple"
	}

});




// function Outer({ children }: React.PropsWithChildren<{}>) {
// 	return <div style={{ padding: 10, width: 350 }}>{children}</div>;
// }

function PerfImpactIcon({ value }: { value: 1 | 2 | 3 }) {
	switch (value) {
		case 1:
			return <BatteryCharging20Icon fontSize="small" />;
		case 2:
			return <BatteryCharging60Icon fontSize="small" />;
		case 3:
			return <BatteryChargingFullIcon fontSize="small" />;
	}
}
function MemFootPrintIcon({ value }: { value: 1 | 2 | 3 }) {
	switch (value) {
		case 1:
			return <Battery20Icon fontSize="small" />;
		case 2:
			return <Battery60Icon fontSize="small" />;
		case 3:
			return <BatteryFullIcon fontSize="small" />;
	}
}

export interface IGraphNodeProperties {
	id: string;
	name: string;
	type: string;
	memoryFootPrint: 1 | 2 | 3;
	performanceImpact: 1 | 2 | 3;
	error?: string;
	counter?: number;
}

const NodeInnerCustom = ({
	name,
	type,
	memoryFootPrint,
	performanceImpact,
	error,
	counter
}: IGraphNodeProperties) => <Box display="flex">
		<Box flexGrow={1} style={{ marginRight: 20, minWidth: 250 }}>
			<SummaryField
				noUnderline={true}
				label={type}
				value={name}
				error={!!error}
				badge={typeof counter === "undefined" ? undefined : counter.toString()} />
		</Box>
		<Box display="flex" flexDirection="column" alignItems="center">
			<PerfImpactIcon value={performanceImpact} />
			<MemFootPrintIcon value={memoryFootPrint} />
		</Box>
	</Box>

export function EtlOperatorNodeWidget({ node, engine }: IEtlOperatorNodeWidgetProps) {
	const inPort = node.getPort("in");
	const outPort = node.getPort("out");
	return <TitleNode isSelected={node.isSelected()} isErrored={!!node.properties?.error} >
		{inPort && <PortWidget engine={engine} port={inPort}>
			<CirclePort />
		</PortWidget>}
		{node.properties && <NodeInnerCustom {...node.properties} />}
		{outPort && <PortWidget engine={engine} port={outPort}>
			<CirclePort />
		</PortWidget>}
	</TitleNode>
}

export class EtlOperatorNodeFactory extends AbstractReactFactory<EtlOperatorNodeModel, DiagramEngine> {
	constructor() {
		super('etl-operator-node');
	}

	generateModel(initialConfig: GenerateModelEvent) {
		return new EtlOperatorNodeModel();
	}

	generateReactWidget(event: GenerateWidgetEvent<EtlOperatorNodeModel>): JSX.Element {
		return <EtlOperatorNodeWidget engine={this.engine as DiagramEngine} node={event.model} />;
	}
}
