import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { IMacroScriptCategoryModel, macroScriptCategoriesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadMacroScriptCategories: Epic<IAnyAction>
    = (action$) =>
        action$.pipe(
            mapToPayload("macroScriptCategory", "macroScriptCategoryLoadAll"),
            mergeMap(() => macroScriptCategoriesApi.getAllAsync({})),
            map(ActionFactories.macroScriptCategory.macroScriptCategoryLoadedAll));


export const loadMacroScriptCategory: Epic<IAnyAction>
    = (action$) => {
        const requestedId$ = action$.pipe(
            mapToPayload("macroScriptCategory", "macroScriptCategoryLoad"),
            share()
        );

        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => macroScriptCategoriesApi.getAsync({ id })),
                map(ActionFactories.macroScriptCategory.macroScriptCategoryLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({ id: 0, name: "" } as IMacroScriptCategoryModel)),
                map(ActionFactories.macroScriptCategory.macroScriptCategoryLoaded)));
    };
export const saveMacroScriptCategory: Epic<IAnyAction>
    = (action$) => action$.pipe(
        mapToPayload("macroScriptCategory", "macroScriptCategorySave"),
        mergeMap(model => macroScriptCategoriesApi.saveAsync({ model })),
        map(ActionFactories.macroScriptCategory.macroScriptCategorySaved));
export const deleteMacroScriptCategory: Epic<IAnyAction>
    = (action$) => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("macroScriptCategory", "macroScriptCategoryDelete"),
            mergeMap(id => macroScriptCategoriesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.macroScriptCategory.macroScriptCategoryDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
