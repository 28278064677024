import * as React from "react";
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
    Typography
} from "@material-ui/core";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import {
    EntitySummaryModel,
    ICompanySummaryModel,
    ICountryModel,
    IEntityGroupSummaryModel,
    IPersonSummaryModel,
    ISicavLegalFormModel,
    ISicavSummaryModel,
    ISicavTypeModel
} from "proxy/apiProxy";
import EntityIcon from "../global/EntityIcon"
import FieldBox from "tools/components/FieldBox";
import { SummaryField } from "../global/SummaryField";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { getEntityName } from "tools/lib/modelUtils";
import { useField } from "formik";
import CloseIcon from '@material-ui/icons/Close';
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { ScreenLink } from "tools/routing/ScreenLink";

const sicavLegalForm = ISicavLegalFormModel;
const sicavType = ISicavTypeModel;

export interface IEntitySummaryProps {
    entities: Record<string | number, EntitySummaryModel>;
    countries?: Record<number, ICountryModel>;
    label?: string;
    name: string;
}

function InternalEntity({ entity, countries }: { entity: EntitySummaryModel, countries?: Record<number, ICountryModel> }) {
    switch (entity.type) {
        case "CompanySummaryModel": return <InternalCompany company={entity} countries={countries} />;
        case "EntityGroupSummaryModel": return <InternalEntityGroup entityGroup={entity} countries={countries} />;
        case "PersonSummaryModel": return <InternalPerson person={entity} countries={countries} />;
        case "SicavSummaryModel": return <InternalSicav sicav={entity} countries={countries} />;
    }
}
function InternalSicav({ sicav, countries }: { sicav: ISicavSummaryModel, countries?: Record<number, ICountryModel> }) {
    return <FieldBox display="flex" flexDirection="row" noBaseline={true}>
        <FieldBox display="flex" flexDirection="column" noBaseline={true}>
            <SummaryField label="Name" value={sicav.name} />
            {sicav.legalForm && <SummaryField label="Legal form" value={sicavLegalForm[sicav.legalForm]} />}
        </FieldBox>
        <FieldBox display="flex" flexDirection="column" noBaseline={true}>
            {(countries && !!sicav.countryId) && <SummaryField label="Country" value={countries[sicav.countryId]?.name?.en} />}
            {sicav.sicavType && <SummaryField label="Type" value={sicavType[sicav.sicavType]} />}
        </FieldBox>
    </FieldBox>
}
function InternalCompany({ company, countries }: { company: ICompanySummaryModel, countries?: Record<number, ICountryModel> }) {
    return <FieldBox display="flex" flexDirection="row" noBaseline={true}>
        <FieldBox display="flex" flexDirection="column" noBaseline={true}>
            <SummaryField label="Name" value={company.name} />
            <SummaryField label="Email" value={company.email} />
            <SummaryField label="Location" value={company.location} />
        </FieldBox>
        <FieldBox display="flex" flexDirection="column" noBaseline={true}>
            <div />
            <SummaryField label="Phone Number" value={company.phoneNumber} />
            {(countries && !!company.countryId) && <SummaryField label="Country" value={countries[company.countryId]?.name?.en} />}
        </FieldBox>
    </FieldBox>
}
function InternalEntityGroup({ entityGroup, countries }: { entityGroup: IEntityGroupSummaryModel, countries?: Record<number, ICountryModel> }) {
    return <FieldBox display="flex" flexDirection="row" noBaseline={true}>
        <FieldBox display="flex" flexDirection="column" noBaseline={true}>
            <SummaryField label="Name" value={entityGroup.name} />
            <SummaryField label="Email" value={entityGroup.email} />
            <SummaryField label="Location" value={entityGroup.location} />
        </FieldBox>
        <FieldBox display="flex" flexDirection="column" noBaseline={true}>
            <div />
            <SummaryField label="Phone Number" value={entityGroup.phoneNumber} />
            {(countries && !!entityGroup.countryId) && <SummaryField label="Country" value={countries[entityGroup.countryId]?.name?.en} />}
        </FieldBox>
    </FieldBox>
}
function InternalPerson({ person, countries }: { person: IPersonSummaryModel, countries?: Record<number, ICountryModel> }) {
    return <FieldBox display="flex" flexDirection="row" noBaseline={true}>
        <FieldBox display="flex" flexDirection="column" noBaseline={true}>
            <SummaryField label="First Name" value={person.firstName} />
            <SummaryField label="Email" value={person.email} />
            <SummaryField label="Location" value={person.location} />
        </FieldBox>
        <FieldBox display="flex" flexDirection="column" noBaseline={true}>
            <SummaryField label="Last Name" value={person.lastName} />
            <SummaryField label="Phone Number" value={person.phoneNumber} />
            {(!!person.countryId && countries) && <SummaryField label="Country" value={countries[person.countryId]?.name?.en} />}
        </FieldBox>
    </FieldBox>
}
interface IEntityToRender {
    label: string;
    screenKey: "Person" | "Company" | "EntityGroup" | "Sicav" | "MySicav";
    entity: EntitySummaryModel;
}
function getEntitiesToRender(entity?: EntitySummaryModel, entities?: Record<number, EntitySummaryModel>) {
    if (!entity) {
        return [];
    }
    switch (entity.type) {
        case "PersonSummaryModel": return [{
            label: "Person",
            screenKey: "Person",
            entity
        } as IEntityToRender];
        case "CompanySummaryModel": {
            const toRender = [{
                label: "Company",
                screenKey: "Company",
                entity
            } as IEntityToRender];
            if (entity.mainContactId && entities && entities[entity.mainContactId]) {
                toRender.push({
                    label: "Main contact",
                    screenKey: "Person",
                    entity: entities[entity.mainContactId]
                });
            }
            return toRender;
        }
        case "EntityGroupSummaryModel": {
            const toRender = [{
                label: "Entity Group",
                screenKey: "EntityGroup",
                entity
            } as IEntityToRender];
            if (entity.mainContactId && entities && entities[entity.mainContactId]) {
                toRender.push({
                    label: "Main contact",
                    screenKey: "Person",
                    entity: entities[entity.mainContactId]
                });
            }
            return toRender;
        }
        case "SicavSummaryModel": {
            const toRender = [{
                label: "Sicav",
                screenKey: entity.isUnderManagement ? "MySicav" : "Sicav",
                entity
            } as IEntityToRender];
            if (entity.issuerId && entities && entities[entity.issuerId]) {
                toRender.push({
                    label: "Issuer",
                    screenKey: "Company",
                    entity: entities[entity.issuerId]
                });
            }
            if (entity.mainContactId && entities && entities[entity.mainContactId]) {
                toRender.push({
                    label: "Main contact",
                    screenKey: "Person",
                    entity: entities[entity.mainContactId]
                });
            }
            return toRender;
        }
    }
}
export default function EntitySummary({ name: fieldName, entities, countries, label }: IEntitySummaryProps) {
    const [{ value: entityId }, , { setValue }] = useField<number | undefined>(fieldName);
    const [expanded, setExpanded] = React.useState(false);
    const entitiesToRender = React.useMemo(() => getEntitiesToRender((entities && entityId) ? entities[entityId] : undefined, entities), [entityId, entities]);
    if (!entityId) {
        return null;
    }
    const entity = entities[entityId];
    if (!entity) {
        return null;
    }
    const handleSwitchExpand = () => setExpanded(!expanded);

    const handleSetUndefined = () => setValue(undefined);
    return <ReadOnlyContext.Consumer>{readOnly => <Card>
        <CardHeader
            avatar={<Avatar><EntityIcon type={entity.type} /></Avatar>}
            action={<>
                {!readOnly && <IconButton onClick={handleSetUndefined}><CloseIcon /></IconButton>}
                <IconButton onClick={handleSwitchExpand}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton></>}
            title={getEntityName(entity) ?? ""}
            subheader={label ?? entitiesToRender[0].label} />
        <Collapse in={expanded} timeout="auto">
            <CardContent>
                {entitiesToRender.map(({ label, entity }, idx) => <div key={idx}>
                    {(idx > 0) && <Typography gutterBottom={true} variant="h6" component="h2">{label}</Typography>}
                    <InternalEntity countries={countries} entity={entity} />
                </div>)}
            </CardContent>
            <CardActions>
                {entitiesToRender.map(entityToRender => <Button
                    key={entityToRender.entity.id}
                    component={ScreenLink}
                    destination={{ type: "Screen", key: entityToRender.screenKey, label: `Open ${entityToRender.label}`, params: { id: String(entityToRender.entity.id) } }}
                    size="small">
                    <OpenInBrowser />Open {entityToRender.label}
                </Button>
                    // < OpenButton key={entityToRender.entity.id} {...entityToRender} onClicked={handleOnClicked} />
                )}
            </CardActions>
        </Collapse>
    </Card>}
    </ReadOnlyContext.Consumer>
}

export interface IEntitySummaryReadOnlyProps {
    entities: Record<string | number, EntitySummaryModel>;
    countries?: Record<number, ICountryModel>;
    label?: string;
    entityId: number;
}

export function EntitySummaryReadOnly({ entityId, entities, countries, label }: IEntitySummaryReadOnlyProps) {
    const [expanded, setExpanded] = React.useState(false);
    const entitiesToRender = React.useMemo(() => getEntitiesToRender((entities && entityId) ? entities[entityId] : undefined, entities), [entityId, entities]);
    if (!entityId) {
        return null;
    }
    const entity = entities[entityId];
    if (!entity) {
        return null;
    }
    const handleSwitchExpand = () => setExpanded(!expanded);

    return <Card>
        <CardHeader
            avatar={<Avatar><EntityIcon type={entity.type} /></Avatar>}
            action={<>
                <IconButton onClick={handleSwitchExpand}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton></>}
            title={getEntityName(entity) ?? ""}
            subheader={label ?? entitiesToRender[0].label} />
        <Collapse in={expanded} timeout="auto">
            <CardContent>
                {entitiesToRender.map(({ label, entity }, idx) => <div key={idx}>
                    {(idx > 0) && <Typography gutterBottom={true} variant="h6" component="h2">{label}</Typography>}
                    <InternalEntity countries={countries} entity={entity} />
                </div>)}
            </CardContent>
            <CardActions>
                {entitiesToRender.map(entityToRender => <Button key={entityToRender.entity.id}
                    component={ScreenLink}
                    destination={{ type: "Screen", key: entityToRender.screenKey, label: `Open ${entityToRender.label}`, params: { id: String(entityToRender.entity.id) } }}
                    size="small">
                    <OpenInBrowser />Open {entityToRender.label}
                </Button>)}
            </CardActions>
        </Collapse>
    </Card>
}
