import { DashboardLayout } from "./DashboardFormattingContracts";
import { SelectionProvider } from "./SelectionProvider";
import { RelativeValueProvider, RootValueProvider } from "./ValueProvider";
import { ContainerComponentContent } from "./ContainerComponent";
import { ComponentProvider, IComponentsContext } from "./ComponentProvider";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Box, Typography } from "@material-ui/core";
import Ajv from "ajv";
import dashboardLayoutSchema from './dashboardLayout.json';
import { useMemo } from "react";
import { IExecuteModel } from "proxy/apiProxy";


// import { ReportLink } from "./DashboardComponents";
// https://docs.npmjs.com/cli/v8/configuring-npm/package-json#local-paths
export interface IDashboardViewerProps extends IComponentsContext {
    datasetInput?: IExecuteModel | undefined;
    dataset?: any;
    /**
     * will work properly if the matching json matches the type DashboardLayout
     */
    layoutDefinition: string | undefined;
}
const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}([.]\d+)?(Z|([+]\d{2}:\d{2}))?$/;

function InnerDashboardViewer({ dataset, datasetInput, layoutDefinition, ...componentsContext }: IDashboardViewerProps) {
    const validateDashboardLayout = useMemo(() => {
        const ajv = new Ajv({allowUnionTypes: true});
        ajv.addFormat("date-time", {
            type: "string",
            validate: (x) => dateFormat.test(x),
        });
        return ajv.compile<DashboardLayout>(dashboardLayoutSchema);
    }, [])
    const data = useMemo(() => ({ dataset, parameters: datasetInput }), [dataset, datasetInput]);
    const { parsedJson, parseError } = useMemo(() => {
        try {
            if (!layoutDefinition) {
                return { parsedJson: {} };
            }
            else {
                return { parsedJson: JSON.parse(layoutDefinition) };
            }
        }
        catch (error) {
            return { parseError: error };
        }
    }, [layoutDefinition]);
    if (parseError) {
        return <Box color="error.main" padding={2}>
            <Typography variant="h5">Invalid layout definition</Typography>
            <Typography variant="body1">The layout definition is not formed properly. It is not a proper JSON.</Typography>
            {parseError instanceof Error &&
                <p>{parseError.message}</p>
            }
        </Box>
    }
    if (!validateDashboardLayout(parsedJson)) {
        return <Box color="error.main" padding={2}>
            <Typography variant="h5">Invalid layout definition</Typography>
            <Typography variant="h6">The layout definition doesn't not match the expected structure.</Typography>
        </Box>
    }
    return <ComponentProvider {...componentsContext}>
        <RootValueProvider value={data}>
            <RelativeValueProvider value={data ?? null}>
                <SelectionProvider>
                    {layoutDefinition && <ContainerComponentContent definition={parsedJson} />}
                </SelectionProvider>
            </RelativeValueProvider>
        </RootValueProvider>
    </ComponentProvider>
}

function FallbackRender({ error }: FallbackProps) {
    return <Box color="error.main" padding={2}>
        <Typography variant="h5">A display error has occurred.</Typography>
        {error instanceof Error &&
            <p>{error.message}</p>
        }
    </Box>
}
export const DashboardViewer = (props: IDashboardViewerProps) =>
    <Box style={{ overflowY: "auto", height: "100%" }}>
        <ErrorBoundary FallbackComponent={FallbackRender}>
            <InnerDashboardViewer {...props} />
        </ErrorBoundary>
    </Box>
