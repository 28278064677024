import styled from "@emotion/styled";
import { Tooltip, Typography, Box } from "@material-ui/core";
import { useMemo } from "react";
import FlowGraph, { IElement, IFlowGraphProps, ILink } from "tools/diagram/FlowGraph";
import { ArrayControl, Container, FieldControl, IDirectedGraphDetailContainer, IGraphItemLayout, IHierarchyGraphDetailContainer } from "./DashboardFormattingContracts";
import { RelativeValueProvider, useArrayValue, useValue } from "./ValueProvider";
import { Item } from "./ItemComponent";

interface IDiagramComponentProps {
    definition: IDirectedGraphDetailContainer<any> | IHierarchyGraphDetailContainer<any>;
}
export function DiagramComponent({ definition }: IDiagramComponentProps) {
    const elementsGroups = useBuildGraphNodes(definition);
    return <Box style={{ height: "100%", width: "100%" }}><FlowGraph {...elementsGroups} /></Box>
}
const SectionNodeGraphContent = styled(Typography)({
    width: 300, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"
});
function useBuildGraphNodes(definition: IDirectedGraphDetailContainer<never> | IHierarchyGraphDetailContainer<never>): IFlowGraphProps {
    const getValue = useValue();
    const getArrayValue = useArrayValue();
    return useMemo(() => {
        const rows = getValue("array", definition.context);
        if (!rows) {
            return { contents: [], links: [] };
        }
        const elements = buildElements(rows, definition, getValue);
        switch (definition.type) {
            case "directedGraphDetailContainer": return {
                contents: elements,
                links: buildDirectedGraphLinks(rows, definition, getValue, getArrayValue)
            };
            case "hierarchyGraphDetailContainer": return {
                contents: elements,
                links: buildHierarchyGraphLinks(rows, definition, getValue)
            };
        }
    }, [definition, getArrayValue, getValue]);
}
function buildElements(rows: object[], { rowKey, itemLayout, items }: IDirectedGraphDetailContainer<never> | IHierarchyGraphDetailContainer<never>, getValue: ReturnType<typeof useValue>): IElement[] {
    const getRowKey = (!!rowKey)
        ? (row: any) => getValue("stringNumber", rowKey, row)
        : (row: any) => row.Id;
    if (typeof items === "string") {
        return rows.map(row => {
            const id = getRowKey(row);
            const textValue = getValue("string", items, row) ?? String(id);
            const element: IElement = {
                id,
                content: <SectionNodeGraphContent ><Tooltip title={textValue}><>{textValue}</></Tooltip></SectionNodeGraphContent>
            };
            return element;
        });

    }
    else {
        return rows.map(row => {
            const element: IElement = {
                id: getRowKey(row),
                content: <RelativeValueProvider value={row}>
                    <InnerItem itemLayout={itemLayout} item={items} />
                </RelativeValueProvider>
            };
            return element;
        });
    }
}
function buildDirectedGraphLinks(rows: object[], { rowKey, children }: IDirectedGraphDetailContainer<never>, getValue: ReturnType<typeof useValue>, getArrayValue: ReturnType<typeof useArrayValue>): ILink[] {
    const getRowKey = (!!rowKey)
        ? (row: any) => getValue("stringNumber", rowKey, row)
        : (row: any) => row.Id;
    return rows.flatMap(row => {
        const childrenValues = getArrayValue("stringNumber", children);
        if (!childrenValues) {
            return [];
        }
        const fromId = getRowKey(row);
        return childrenValues.map(toId => ({ fromId, toId }));
    })
}
function buildHierarchyGraphLinks(rows: object[], { rowKey, parent }: IHierarchyGraphDetailContainer<never>, getValue: ReturnType<typeof useValue>): ILink[] {
    const getRowKey = (!!rowKey)
        ? (row: any) => getValue("stringNumber", rowKey, row)
        : (row: any) => row.Id;
    return rows
        .map(row => {
            const toId = getRowKey(row);
            const fromId = getValue("stringNumber", parent, row);
            if (typeof fromId === "undefined") {
                return undefined;
            }
            else {
                return { fromId, toId };
            }
        })
        .filter(i => !!i);
}
export interface IItemBoxProps {
    layout: IGraphItemLayout | undefined;
    children: React.ReactNode;
}
export function ItemBox({ layout = { width: 300 }, children }: IItemBoxProps) {
    const { width } = layout;
    const boxProperties = useMemo(() => ({ width }), [width]);
    return <Box style={boxProperties}>
        {children}
    </Box>
}

export interface IInnerItemProps {
    itemLayout?: IGraphItemLayout;
    item: FieldControl<never> | Container<never> | ArrayControl<never>
}
export function InnerItem({ itemLayout, item }: IInnerItemProps) {
    return <ItemBox layout={itemLayout} >
        <Item item={item} />
    </ItemBox>
}
