import MultiCulturedTextField, { ICulturedText } from '../components/MultiCulturedTextField';
import { useFieldEx, IFormField, ValidatorParams } from 'tools/lib/fieldHelpers';
import { LanguageCode } from "tools/lib/utility";
import { ReadOnlyContext } from "./ReadOnlyContext";

const validators = {
    required: (value: (ICulturedText | undefined) = {}, cultures: (LanguageCode[] | undefined) = []) => {
        const filledCultures = Object.keys(value).filter(language => value[language]);
        const unfilledMandatoryCultures = cultures.filter(language => !filledCultures.includes(language));
        if (unfilledMandatoryCultures.length === 0) {
            return undefined;
        }
        else {
            if (unfilledMandatoryCultures.length === 1) {
                return `${unfilledMandatoryCultures[0]} translation is required`;
            }
            else {
                const lastElement = unfilledMandatoryCultures.pop();
                return `${unfilledMandatoryCultures.join(", ")} and ${lastElement} translations are required`;
            }
        }
    },
}

export interface IFormMultiCulturedTextFieldProps extends IFormField, ValidatorParams<typeof validators> {
    fullWidth?: boolean;
    multiline?: boolean;
}

export default function FormMultiCulturedTextField({ name, helperText, label, disabled, error, multiline, fullWidth = true, ...requestedValidations }: IFormMultiCulturedTextFieldProps) {
    const required = !!(requestedValidations.required ?? []).length;

    const checkDirty = (initialValue: (ICulturedText | undefined) = {}, v: (ICulturedText | undefined) = {}) => {
        const tmp: { [key: string]: { initial: string, new: string } } = {};
        for (const key in initialValue) {
            if (initialValue.hasOwnProperty(key)) {
                tmp[key] = { initial: initialValue[key] || "", new: "" }
            }
        }
        for (const key in v) {
            if (v.hasOwnProperty(key)) {
                if (tmp[key]) {
                    tmp[key].new = v[key] || "";
                }
                else {
                    tmp[key] = { initial: "", new: v[key] || "" }
                }
            }
        }
        for (const key in tmp) {
            if (tmp.hasOwnProperty(key)) {
                const element = tmp[key];
                if (element.initial !== element.new) {
                    return true;
                }
            }
        }
        return false;
    }

    const { error: fieldError, helperText: fieldHelperText, label: fieldLabel, onValueChanged, value } = useFieldEx<ICulturedText, typeof validators>({ name, helperText, label, required, error, validators, requestedValidations, checkDirty });

    return <ReadOnlyContext.Consumer>
        {readOnly => <MultiCulturedTextField
            id={name}
            readOnly={readOnly}
            multiline={multiline}
            fullWidth={fullWidth}
            helperText={fieldHelperText}
            disabled={disabled}
            label={fieldLabel}
            margin="dense"
            value={value}
            error={fieldError}
            onValueChanged={onValueChanged} />}
    </ReadOnlyContext.Consumer>;
}
