import {
    IFeatureModel,
    IOperationTypeModel,
    IPortfolioFxTransactionModel,
    IPortfolioFxTransactionSummaryModel,
    IPortfolioSecurityTransactionModel,
    IPortfolioSecurityTransactionSummaryModel,
    IPortfolioTransactionsSearchParameters,
    isSecurityInstrumentSummaryModel,
    PortfolioTransactionModel,
    PortfolioTransactionSummaryModel
} from "proxy/apiProxy";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { Formik, FormikHelpers, FormikProps } from "formik";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { getEnumLabels, IErrors, oProps } from "tools/lib/utility";
import FieldBox from "tools/components/FieldBox";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FormTextField from "tools/fieldComponents/FormTextField";
import CashUsdOutlineIcon from 'mdi-material-ui/Cash';
import CheckbookIcon from 'mdi-material-ui/Checkbook';
import ManagedPortfolioMultiSelectField from "components/searchers/ManagedPortfolioMultiSelectField";
import AddIcon from '@material-ui/icons/Add';
import { IActionButton, IMenuActionButton } from "tools/components/FabContainer";
import { getPortfolioTypeLabel } from "features/ManagedPortfolio/getPortfolioTypeLabel";
import { getPortfolioTransactionTypeLabel } from "./getPortfolioTransactionTypeLabel";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useCallback } from "react";

const operationTypes = getEnumLabels(IOperationTypeModel);

export const portfolioTransactionsScreen = {
    route: "/portfoliodata/portfoliotransactions",
    component: PortfolioTransactions,
    label: "Portfolio Transactions"
}


function PortfolioTransactions() {
    const { transactionsSearch, transactionAddPortfoliosToDictionary } = useReduxActions("portfolioTransaction");
    const { transactionsLoading, dictionaries, transactions = [] } = useReduxSelections("portfolioTransaction");
    const { portfolios, securities } = dictionaries;
    const navigate = useScreenNavigate();
    const { referenceCurrencies = {} } = useReduxSelections("reference");

    const columns: IColumnDefinition[] = [
        {
            name: "Type",
            title: "Type",
            getCellValue: ({ type }: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => getPortfolioTransactionTypeLabel(type),
            filteringEnabled: true
        },
        {
            name: "PortfolioName",
            title: "Prtf Name",
            getCellValue: ({ portfolioId }: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => portfolios[portfolioId].name,
            aggregationType: "count",
            filteringEnabled: true
        },
        {
            name: "PortfolioCode",
            title: "Prtf Code",
            getCellValue: ({ portfolioId }: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => portfolios[portfolioId].internalCode,
            filteringEnabled: true
        },
        {
            name: "PortfolioType",
            title: "Prtf Type",
            getCellValue: ({ portfolioId }: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => getPortfolioTypeLabel(portfolios[portfolioId].type),
            filteringEnabled: true
        },
        {
            name: "PortfolioCcy",
            title: "Prtf Ccy",
            getCellValue: ({ portfolioId }: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => referenceCurrencies[portfolios[portfolioId].currencyId ?? 0].isoCode,
            filteringEnabled: true
        },
        {
            name: "Code",
            title: "Transaction Code",
            getCellValue: ({ transactionCode }: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => transactionCode,
            filteringEnabled: true
        },
        {
            name: "Description",
            title: "Description",
            getCellValue: ({ description }: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => description,
            filteringEnabled: true
        },
        {
            name: "TradeDate",
            title: "Trade Date",
            getCellValue: ({ tradeDate }: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => tradeDate,
            columnType: "date",
            filteringEnabled: true
        },
        {
            name: "NavDate",
            title: "Nav Date",
            getCellValue: ({ navDate }: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => navDate,
            columnType: "date",
            filteringEnabled: true
        },
        {
            name: "Commented",
            title: "Commented",
            getCellValue: (t: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => (t.type === "PortfolioFxTransactionSummaryModel" || t.type === "PortfolioSecurityTransactionSummaryModel") ? t.hasComment : Object.keys(t.comment ?? {}).length,
            columnType: "boolean",
            filteringEnabled: true
        },
        {
            name: "Cancelled",
            title: "Cancelled",
            getCellValue: ({ canceled }: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => !!canceled,
            columnType: "boolean",
            filteringEnabled: true
        },
        {
            name: "SecurityCode",
            title: "Sec Code",
            getCellValue: ({ securityId }: IPortfolioSecurityTransactionSummaryModel | IPortfolioSecurityTransactionModel) => (!securityId) ? undefined : securities[securityId]?.internalCode,
            filteringEnabled: true
        },
        {
            name: "SecurityName",
            title: "Sec Name",
            getCellValue: ({ securityId }: IPortfolioSecurityTransactionSummaryModel | IPortfolioSecurityTransactionModel) => (!securityId) ? undefined : securities[securityId]?.name,
            filteringEnabled: true
        },
        {
            name: "SecurityCcy",
            title: "Sec Ccy",
            getCellValue: ({ securityId }: IPortfolioSecurityTransactionSummaryModel | IPortfolioSecurityTransactionModel) => (!securityId) ? undefined : referenceCurrencies[securities[securityId]?.currencyId ?? 0]?.isoCode,
            filteringEnabled: true
        },
        {
            name: "SecurityType",
            title: "Sec Type",
            getCellValue: ({ securityId }: IPortfolioSecurityTransactionSummaryModel | IPortfolioSecurityTransactionModel) => (!securityId) ? undefined : getSecurityTypeLabel(securities[securityId]?.type),
            filteringEnabled: true
        },
        {
            name: "SecurityIsin",
            title: "Sec Isin",
            getCellValue: ({ securityId }: IPortfolioSecurityTransactionSummaryModel | IPortfolioSecurityTransactionModel) => {
                if (!securityId) {
                    return undefined;
                }
                const secu = securities[securityId];
                return isSecurityInstrumentSummaryModel(secu) ? secu?.isin : undefined
            },
            filteringEnabled: true
        },
        {
            name: "OperationType",
            title: "Sec Op Type",
            getCellValue: ({ operationType }: IPortfolioSecurityTransactionSummaryModel | IPortfolioSecurityTransactionModel) => operationTypes[operationType],
            filteringEnabled: true
        },
        {
            name: "Quantity",
            title: "Qty",
            getCellValue: ({ quantity }: IPortfolioSecurityTransactionSummaryModel | IPortfolioSecurityTransactionModel) => quantity,
            columnType: "decimal",
            aggregationType: "sum",
            filteringEnabled: true
        },
        {
            name: "GrossAmountInPortfolioCcy",
            title: "Gross Amnt Ptf Ccy",
            getCellValue: ({ grossAmountInPortfolioCcy }: IPortfolioSecurityTransactionSummaryModel | IPortfolioSecurityTransactionModel) => grossAmountInPortfolioCcy,
            columnType: "decimal",
            aggregationType: "sum",
            filteringEnabled: true
        },
        {
            name: "GrossAmountInSecurityCcy",
            title: "Gross Amnt Sec Ccy",
            getCellValue: ({ grossAmountInSecurityCcy }: IPortfolioSecurityTransactionSummaryModel | IPortfolioSecurityTransactionModel) => grossAmountInSecurityCcy,
            columnType: "decimal",
            aggregationType: "sum",
            filteringEnabled: true
        },
        {
            name: "NetAmountInPortfolioCcy",
            title: "Net Amnt Ptf Ccy",
            getCellValue: ({ netAmountInPortfolioCcy }: IPortfolioSecurityTransactionSummaryModel | IPortfolioSecurityTransactionModel) => netAmountInPortfolioCcy,
            columnType: "decimal",
            aggregationType: "sum",
            filteringEnabled: true
        },
        {
            name: "NetAmountInSecurityCcy",
            title: "Net Amnt Sec Ccy",
            getCellValue: ({ netAmountInSecurityCcy }: IPortfolioSecurityTransactionSummaryModel | IPortfolioSecurityTransactionModel) => netAmountInSecurityCcy,
            columnType: "decimal",
            aggregationType: "sum",
            filteringEnabled: true
        },
        {
            name: "PriceInSecurityCcy",
            title: "Price Sec Ccy",
            getCellValue: ({ priceInSecurityCcy }: IPortfolioSecurityTransactionSummaryModel | IPortfolioSecurityTransactionModel) => priceInSecurityCcy,
            columnType: "decimal",
            filteringEnabled: true
        },
        {
            name: "SettlementDate",
            title: "Sttl Date",
            getCellValue: ({ settlementDate }: PortfolioTransactionSummaryModel) => settlementDate,
            columnType: "date",
            filteringEnabled: true
        },
        {
            name: "PurchaseAmount",
            title: "Purch Amnt",
            getCellValue: ({ purchaseAmount }: IPortfolioFxTransactionSummaryModel | IPortfolioFxTransactionModel) => purchaseAmount,
            columnType: "decimal",
            filteringEnabled: true
        },
        {
            name: "PurchaseCcy",
            title: "Purch Ccy",
            getCellValue: ({ purchaseCurrencyId }: IPortfolioFxTransactionSummaryModel | IPortfolioFxTransactionModel) => referenceCurrencies[purchaseCurrencyId]?.isoCode,
            filteringEnabled: true
        },
        {
            name: "SoldAmount",
            title: "Sold Amnt",
            getCellValue: ({ soldAmount }: IPortfolioFxTransactionSummaryModel | IPortfolioFxTransactionModel) => soldAmount,
            columnType: "decimal",
            filteringEnabled: true
        },
        {
            name: "SoldCcy",
            title: "Sold Ccy",
            getCellValue: ({ soldCurrencyId }: IPortfolioFxTransactionSummaryModel | IPortfolioFxTransactionModel) => referenceCurrencies[soldCurrencyId]?.isoCode,
            filteringEnabled: true
        },
        {
            name: "FxRate",
            title: "FxRate",
            getCellValue: ({ fxRate }: IPortfolioFxTransactionSummaryModel | IPortfolioFxTransactionModel) => fxRate,
            columnType: "decimal",
            filteringEnabled: true
        },
    ];
    const state: IGridState = {
        "TradeDate": { width: 100 },
        "Type": { width: 120, hidden: true },
        "PortfolioName": { width: 280 },
        "PortfolioCode": { width: 150, hidden: true },
        "PortfolioType": { width: 120, hidden: true },
        "PortfolioCcy": { width: 150, hidden: true },
        "Code": { width: 150 },
        "SecurityCode": { width: 150 },
        "SecurityName": { width: 280 },
        "SecurityType": { width: 120, hidden: true },
        "SecurityCcy": { width: 120 },
        "SecurityIsin": { width: 150, hidden: true },
        "OperationType": { width: 120 },
        "Quantity": { width: 180 },
        "Commented": { width: 120, hidden: true },
        "GrossAmountInPortfolioCcy": { width: 180, hidden: true },
        "GrossAmountInSecurityCcy": { width: 180 },
        "NetAmountInPortfolioCcy": { width: 180 },
        "NetAmountInSecurityCcy": { width: 180, hidden: true },
        "Description": { width: 150 },
        "NavDate": { width: 100, hidden: true },
        "Cancelled": { width: 120, hidden: true },
        "PriceInSecurityCcy": { width: 180, hidden: true },
        "SettlementDate": { width: 100, hidden: true },
        "PurchaseAmount": { width: 180, hidden: true },
        "PurchaseCcy": { width: 150, hidden: true },
        "SoldAmount": { width: 180, hidden: true },
        "SoldCcy": { width: 150, hidden: true },
        "FxRate": { width: 180, hidden: true },
    };

    const getRowKey = (row: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => row.id;

    const handleSubmit = (model: IPortfolioTransactionsSearchParameters, { setSubmitting }: FormikHelpers<IPortfolioTransactionsSearchParameters>) => {
        transactionsSearch(model);
        setSubmitting(false);
    }
    const handleValidate = (values: IPortfolioTransactionsSearchParameters) => {
        const errors: IErrors<IPortfolioTransactionsSearchParameters> = {};
        if (!values.lastOnly) {
            if (!values.from) {
                errors.from = "From date is required";
            }
            if (values.to && values.from && values.from > values.to) {
                errors.to = "Must be greater than the start of period";
            }
        }
        return errors;
    }
    const handleRowSelect = useCallback(({ id }: PortfolioTransactionSummaryModel | PortfolioTransactionModel) => navigate("PortfolioTransaction", { id }), [navigate])
    const handleAddCashTransaction = useCallback(() => navigate("PortfolioTransaction", { id: "cash" }), [navigate])
    const handleAddSecurityTransaction = useCallback(() => navigate("PortfolioTransaction", { id: "security" }), [navigate])
    const fiveYearsAgoDate = (function () {
        const date = new Date();
        return new Date(date.getFullYear() - 5, 1, 1);
    })();

    return <Formik onSubmit={handleSubmit} validate={handleValidate} initialValues={{
        lastOnly: false,
        securityRelated: true,
        fxRelated: true,
        from: fiveYearsAgoDate
    } as IPortfolioTransactionsSearchParameters} enableReinitialize={true} validateOnMount={true}>{renderForm}</Formik>;

    function renderForm({
        isValid,
        submitForm,
        values: { lastOnly }
    }: FormikProps<IPortfolioTransactionsSearchParameters>) {
        const extraActionButtons: IActionButton[] = [{
            label: "Add transaction",
            icon: AddIcon,
            feature: IFeatureModel.ManagedPortfolioWrite,
            actions: [{
                label: "Add forex transaction",
                onClick: handleAddCashTransaction,
                icon: CashUsdOutlineIcon,
            }, {
                label: "Add security transaction",
                onClick: handleAddSecurityTransaction,
                icon: CheckbookIcon,
            }]
        } as IMenuActionButton];
        return (
            <SearchPanel
                isQuerying={transactionsLoading}
                onSearchClick={submitForm}
                searchDisabled={!isValid}
                actions={extraActionButtons}
                title="Portfolio Transactions"
                subTitle="Search by portfolio and time period"
                renderSearch={(<FieldBox display="flex" flexDirection="column">
                    <FieldBox display="flex" flexGrow="1">
                        <ManagedPortfolioMultiSelectField
                            name={oProps<IPortfolioTransactionsSearchParameters>().path("portfolioIds")}
                            label="Portfolios"
                            dictionaries={dictionaries}
                            onSelected={transactionAddPortfoliosToDictionary} />
                    </FieldBox>
                    <FieldBox display="flex" flexDirection="row">
                        <FormTextField name={oProps<IPortfolioTransactionsSearchParameters>().path("criterias")}
                            label="Code" />
                        <FormCheckBoxField
                            name={oProps<IPortfolioTransactionsSearchParameters>().path("securityRelated")}
                            label="Security Transactions" />
                        <FormCheckBoxField name={oProps<IPortfolioTransactionsSearchParameters>().path("fxRelated")}
                            label="Fx Transactions" />
                    </FieldBox>
                    <FieldBox display="flex" flexDirection="row">
                        <FormCheckBoxField name={oProps<IPortfolioTransactionsSearchParameters>().path("lastOnly")}
                            label="Only Last Transactions" />
                        {(!lastOnly) && <FormDatePickerField
                            name={oProps<IPortfolioTransactionsSearchParameters>().path("from")} label="From"
                            required={true} />}
                        {(!lastOnly) &&
                            <FormDatePickerField name={oProps<IPortfolioTransactionsSearchParameters>().path("to")}
                                label="To" />}
                    </FieldBox>
                </FieldBox>
                )}>
                <ExtendedGrid
                    getRowId={getRowKey}
                    columns={columns}
                    rows={transactions}
                    onRowClick={handleRowSelect}
                    initialState={state}
                    userCanGroup={true}
                    defaultExportFileName="transactions.xlsx" />
            </SearchPanel>
        );
    }
}
