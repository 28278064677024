import * as React from "react";
import { useEffect } from "react";
import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { IFeatureModel, IManagedShareClassModel, IUniverseScopeTypeModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import HistoricalValues, { IDataSeries } from "components/global/HistoricalValues";
import ShareClassData from "./ShareClassData";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import CustomViewDatas from "features/Relationship/CustomViewDatas";
import useScopeButtonsMenu from "components/global/useScopeButtonsMenu";
import MonitoringResultGroups from "components/global/MonitoringResults";
import { ProcessExecutionList } from "features/ProcessExecution/ProcessExecutionList";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";
import { useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";
import { isNumber, useNumber } from "../../tools/lib/utility";
import { DocumentsGrid } from "features/Document/DocumentsScreen/DocumentsGrid";

export const myShareClassScreen = {
    route: "/portfolios/myshareclasses/:id/:tab?" as const,
    component: MyShareClass,
    label: "My ShareClass",
    tabs: {
        detail: "details",
        historicalValues: "historicalvalues",
        customViews: "customScreens",
        dashboard: "dashboard",
        processes: "processes",
        documents: "documents",
    },
}

function MyShareClass() {
    const {
        referenceCurrencies = {},
        referenceCountries = {}
    } = useReduxSelections("reference");
    const { classificationTypes } = useReduxSelections("securityClassificationType");
    const { parameters: { securityExtensionFields = [], shareClassExtensionFields = [] } } = useReduxSelections("parameters");
    const {
        current,
        dictionaries,
        historicalValues,
        historicalValuesVisualizationType,
        loading,
        saving,
        historicalLoading,
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        processExecutionsLoading,
        processExecutions,
        documentsLoading,
        documents
    } = useReduxSelections("managedShareClass");
    const { macroScriptList } = useReduxSelections("macroScript");
    const monitoringMacros = React.useMemo(() => macroScriptList.filter(i => (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel") && i.singleScope === IUniverseScopeTypeModel.ShareClass), [macroScriptList]);

    const {
        shareClassDelete,
        shareClassSave,
        shareClassHistoricalValuesVisualizationTypeChanged,
        shareClassAddSubFundInDictionary,
        shareClassCustomScreenDataSubmit,
        shareClassMonitoringResultLoad,
        shareClassAddIndexInDictionary,
        shareClassLoad,
        shareClassHistoricalValuesLoad,
        shareClassProcessExecutionsLoad,
        shareClassDocumentsLoad,
    } = useReduxActions("managedShareClass");
    const {
        parametersMarketDataProviders,
    } = useReduxSelections("parameters");
    const isGranted = useGrants();

    const navigate = useScreenNavigate()
    const { id, tab: tabValue = "detail" } = useScreenParams<typeof myShareClassScreen>()
    const idNum = useNumber(id)

    useEffect(() => {
        if (isNumber(idNum))
            shareClassLoad(idNum)
    }, [idNum, shareClassLoad]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "processes" && !processExecutions)
            shareClassProcessExecutionsLoad(idNum)
    }, [idNum, processExecutions, shareClassProcessExecutionsLoad, tabValue]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "documents" && !documents)
            shareClassDocumentsLoad(idNum)
    }, [documents, idNum, shareClassDocumentsLoad, tabValue]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "historicalValues" && !historicalValues)
            shareClassHistoricalValuesLoad(idNum)
    }, [historicalValues, idNum, shareClassHistoricalValuesLoad, tabValue]);

    const extraReportActionButtons = useScopeButtonsMenu(IUniverseScopeTypeModel.ShareClass, current?.id);
    const handleNewExecutionProcess = React.useCallback(() => {
        navigate("ProcessExecutionNew", { type: "SecurityProcessExecutionModel", targetId: current?.id ?? 0 });
    }, [current?.id, navigate])
    const extraActionButtons = React.useMemo(() => {
        const ret = [...extraReportActionButtons];
        ret.push({
            label: "Add Process Execution",
            icon: NotebookCheckOutlineIcon,
            onClick: handleNewExecutionProcess,
            feature: IFeatureModel.ManagedPortfolioWrite,
        });
        return ret;
    }, [extraReportActionButtons, handleNewExecutionProcess]);

    if (!current) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={true} />;
    }
    const handleLoadResult = (id: number) => shareClassMonitoringResultLoad({ id, targetId: current.id });
    const historicalValueProperties = getHistoricalValueTypes();
    const graphHistoricalValueProperties = getGraphHistoricalValueTypes();

    const parametersMarketDataProvidersInUse = parametersMarketDataProviders;

    const handleDelete = () => {
        if (current?.id) {
            shareClassDelete(current.id);
        }
    }
    const handleSubmit = (values: IManagedShareClassModel, { setSubmitting }: FormikHelpers<IManagedShareClassModel>) => {
        shareClassSave(values);
        setSubmitting(false);
    }

    const handleDataSubmit = (customScreenId: number, values: any) => shareClassCustomScreenDataSubmit({ id: current.id, customScreenId, values });
    const canEditCustomData = isGranted({ feature: IFeatureModel.ManagedPortfolioCustomDataWrite, portfolioId: current?.subFundId ?? 0 });

    return <Formik onSubmit={handleSubmit} initialValues={current} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<IManagedShareClassModel>) {
        const tabs: ISubMenuTabs<typeof myShareClassScreen>[] = [{
            label: "Data",
            value: "detail"
        }];
        if (!!monitoringMacros.length) {
            tabs.push({
                label: "Dashboard",
                value: "dashboard"
            });
        }
        if (values.id) {
            tabs.push({
                label: "Historical series",
                value: "historicalValues"
            });
            tabs.push({
                label: "Processes",
                value: "processes"
            });
            tabs.push({
                label: "Documents",
                value: "documents"
            });
        }
        if (values?.id && !!customScreens && customScreens.length) {
            tabs.push({
                label: "Custom Data",
                value: "customViews"
            });
        }
        return <WriteAccess value={{ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId: current?.subFundId ?? 0 }}><DetailPanel
            isQuerying={loading || saving || historicalLoading || customScreenDataLoading || customScreenDataSubmitting || processExecutionsLoading || documentsLoading}
            title="ShareClass"
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!values.id}
            onDeleteClick={handleDelete}
            saveAllowed={{ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId: current?.subFundId ?? 0 }}
            deleteAllowed={{ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId: current?.subFundId ?? 0 }}
            badge={!values.id ? "new" : undefined}
            tabs={tabs}
            tabValue={tabValue}
            actions={extraActionButtons}>
            {(tabValue === "detail") && <ShareClassData
                securityExtensionFields={securityExtensionFields}
                shareClassExtensionFields={shareClassExtensionFields}
                formValues={values}
                referenceCurrencies={referenceCurrencies}
                referenceCountries={referenceCountries}
                onIndexLoaded={shareClassAddIndexInDictionary}
                previousSubFundId={values.subFundId}
                onSubFundLoaded={shareClassAddSubFundInDictionary}
                dictionaries={dictionaries}
                classificationTypes={classificationTypes}
                parametersMarketDataProviders={parametersMarketDataProvidersInUse} />}
            {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
                monitoringMacros={monitoringMacros}
                onLoadResult={handleLoadResult}
                resultGroups={monitoringResults} />}
            {(tabValue === "historicalValues") && <HistoricalValues
                historicalValues={historicalValues}
                properties={historicalValueProperties}
                graphProperties={graphHistoricalValueProperties}
                onVisualizationTypeChanged={shareClassHistoricalValuesVisualizationTypeChanged}
                visualizationType={historicalValuesVisualizationType} />}
            {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) && <CustomViewDatas
                canEdit={canEditCustomData}
                dictionaries={dictionaries}
                customScreenLoading={customScreenDataLoading}
                dataSubmitting={customScreenDataSubmitting}
                customScreens={customScreens}
                onDataSubmit={handleDataSubmit}
                customScreenDatas={customScreenDatas} />}
            {(tabValue === "processes") && <ProcessExecutionList
                processExecutions={processExecutions ?? []}
                dictionaries={dictionaries} />}
            {(tabValue === "documents") && <DocumentsGrid
                documents={documents ?? []}
                dictionaries={dictionaries} />}
        </DetailPanel></WriteAccess>;
    }
}
function getGraphHistoricalValueTypes(): IDataSeries {
    return {
        MKT: "NAV",
        // "TNA": "Total net asset",
        // "TRP": "Total return price",
        // "DIV": "Dividend",
        // "NBS": "Number of shares",
        // "SRRI": "SRRI",
        // "SUB": "Subscription",
        // "RED": "Redemption",
        // "TER": "Total expense ratio",
        // "OGC": "Ongoing charges",
    };
}
function getHistoricalValueTypes(): IDataSeries {
    return {
        MKT: "NAV",
        TNA: "Total net asset",
        TRP: { title: "Adjusted price", columnType: "preciseDecimal" },
        DIV: "Dividend",
        NBS: "Number of shares",
        SRRI: "SRRI",
        SUB: "Subscription",
        RED: "Redemption",
        TER: "Total expense ratio",
        OGC: "Ongoing charges",
        FSU: "Forecasted subscription",
        FRE: "Forecasted redemption",
    };
}
