import produce from "immer";
import { IParametersModel, IMarketDataProviderModel, ITaskDefinitionStructureModel, IBatchSummaryModel, IGetParametersModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IState {
    parametersLoading: boolean;
    parameters: IParametersModel;
    dictionaries: {
        batches: Record<number | string, IBatchSummaryModel>
    }
    parametersSaving: boolean;
    parametersMarketDataImportProcessLoading: boolean;
    parametersMarketDataProviders: IMarketDataProviderModel[];
    marketDataImportProcess?: ITaskDefinitionStructureModel;
}
export const ActionFactories = produceActionFactories({
    parametersMarketDataImportProcessLoad: () => undefined,
    parametersMarketDataImportProcessLoaded: (payload: ITaskDefinitionStructureModel) => payload,
    parametersAddBatchToDictionary: (payload: IBatchSummaryModel) => payload,
    parametersLoad: () => undefined,
    parametersLoaded: (payload: IGetParametersModel) => payload,
    parametersSave: (payload: IParametersModel) => payload,
    parametersSaved: (payload: IParametersModel) => payload,
    parametersMarketProviderParametersLoaded: (payload: IMarketDataProviderModel[]) => payload,
});

export function reducer(
    state: IState = {
        parametersLoading: false,
        parametersSaving: false,
        parameters: {
            portfolioExtensionFields: [],
            securityExtensionFields: [],
            shareClassExtensionFields: []
        },
        parametersMarketDataProviders: [],
        parametersMarketDataImportProcessLoading: false,
        dictionaries: {
            batches: {}
        }
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "parametersAddBatchToDictionary":
                draft.dictionaries.batches[action.payload.id] = action.payload;
                break;
            case "parametersLoad":
                draft.parametersLoading = true;
                break;
            case "parametersLoaded":
                draft.parametersLoading = false;
                draft.parameters = action.payload.parameters;
                draft.dictionaries = {
                    batches: action.payload.batches
                }
                break;
            case "parametersSave":
                draft.parametersSaving = true;
                break;
            case "parametersSaved":
                draft.parametersSaving = false;
                draft.parameters = action.payload;
                break;
            case "parametersMarketProviderParametersLoaded":
                draft.parametersMarketDataProviders = action.payload;
                break;
            case "parametersMarketDataImportProcessLoaded":
                draft.marketDataImportProcess = action.payload;
                draft.parametersMarketDataImportProcessLoading = false;
                break;
            case "parametersMarketDataImportProcessLoad":
                draft.parametersMarketDataImportProcessLoading = true;
                break;
        }
    });
}
