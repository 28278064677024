import React from 'react';
import autobind from 'autobind-decorator';
import { CircularProgress } from '@material-ui/core';
import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor';
import 'stimulsoft-reports-js/Css/stimulsoft.designer.office2013.whiteblue.css';
import 'stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css';
import { timeout } from 'workbox-core/_private';
// import './App.css';
// const Stimulsoft = import("stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor");
export interface IReportDesignerProps {
    onPreviewDataRequest?: () => void;
    onExit?: () => void;
    onSave?: (template: string) => void;
    onSaveAs?: (name: string, template: string) => void;
    data?: any;
    template?: string;
    readOnly?: boolean;
}

interface IState {
    loading: boolean;
}

const STIMULSOFT_REPORT_DESIGNER_ID = "stimulsoftReportDesigner";
// https://admin.stimulsoft.com/documentation/classreference-js/classes/stimulsoft.designer.stidesigner.html
// https://www.stimulsoft.com/en/documentation/online/programming-manual/

// const waitAsync = (ms: number) => new Promise(res => setTimeout(res, ms));

@autobind
class ReportDesigner extends React.Component<IReportDesignerProps, IState> {
    public static DesignerInstance: Stimulsoft.Designer.StiDesigner | undefined = undefined;

    private static async setupDesigner() {
        Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHlqGYrMYOCgyJZDRydZwecgtyVDLAAgKLWgo9+QqEAHZNIieS" + 
        "9a6oI/EyGl1aJrmumDxCh27KXqc6jez3Q5WCRxXFP+vnBW+Wt8kO1b1qRFOsF1BxNavi+2tbch3UQmU8X05qgtWDVe" + 
        "6ptHvsVTiuiPRhxkDEjv9+Xab/EuYBKsj3YkH59xLGWV99dtOrHLnN7ohut08dUqGB6bYEraBfn8xkUr7TAFfn9SVS" + 
        "bSgNXjB55XsVzCd9eRS6253dK/DlmwLNoDmqNkhCzFBgxtPugRqJVbjG8Hpz9Qsl/DkvuI32bzyPoqsNu0rR+iMj2O" + 
        "JGdFsyZxXvNu+4hy8Fvo/gQXr4lwY6xaPmjahv/1GlxW+0pYyTmGj6/RiP0qXZpi6927r0pLa0ErN0Sc03b5soo/1H" + 
        "xA3O3mYctAY0sV+MCaZ9nQHbBDNRIdWvqEIW0OyiI2Ze38Hw2ZvgaSKVUC0bqobraYsdiZwmkoByFjPqRTA7/e5+px" + 
        "Zj0orHWYHUfptgsfzMF8H177EGYkmREVBulDJYr9Snjjj5ZteSXNQUiMg+ilfHVjRul4W2UG8tBbRNa3OMoYVmz02R" + 
        "lu2H1EO1Dk/cjc1pDrvVYt8q4ZbFp4bRK3y3eOQazJmblk6atPEd8BHTHjsxMZvbUfVb7PX1ch";
        const options = new Stimulsoft.Designer.StiDesignerOptions();
        options.height = "100%";
        options.appearance.showSaveDialog = true;
        options.toolbar.showFileMenu = true;
        //options.toolbar.showSaveButton = false;
        options.appearance.allowChangeWindowTitle = false;

        // options.dictionary.showAdaptersInNewConnectionForm = false;
        // options.dictionary.dataSourcesPermissions = window.Stimulsoft.Designer.StiDesignerPermissions.View;
        // options.dictionary.dataConnectionsPermissions = window.Stimulsoft.Designer.StiDesignerPermissions.View;
        // options.dictionary.dataColumnsPermissions = window.Stimulsoft.Designer.StiDesignerPermissions.View;
        // options.dictionary.dataRelationsPermissions = window.Stimulsoft.Designer.StiDesignerPermissions.View;
        // options.dictionary.businessObjectsPermissions = window.Stimulsoft.Designer.StiDesignerPermissions.View;
        // options.dictionary.variablesPermissions = window.Stimulsoft.Designer.StiDesignerPermissions.View;
        // options.dictionary.resourcesPermissions = window.Stimulsoft.Designer.StiDesignerPermissions.View;

        const designer = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);
        const stringCategory = "Strings";

        const getTranslationParamNames = ["culturedString"];
        const getTranslationParamTypes = [String];
        const getTranslationParamDescriptions = ["Fund process cultured string in JSON format"];

        Stimulsoft.Report.Dictionary.StiFunctions.removeFunction("GetTranslation");
        Stimulsoft.Report.Dictionary.StiFunctions.addFunction(stringCategory, "GetTranslation", "GetTranslation", "Get the translation from a FundProcess JSON cultured string.",
            "", String, "Translation", getTranslationParamTypes, getTranslationParamNames, getTranslationParamDescriptions, (culturedString: string) => {
                const language = "en";
                if (!culturedString) {
                    return "";
                }
                try {
                    const dico = JSON.parse(culturedString);
                    if (!dico) {
                        return "";
                    }
                    return dico[language] ?? dico.en ?? (Object.values(dico).length ? Object.values(dico)[0] : "");
                }
                catch {
                    return "";
                }
            });
        Stimulsoft.Report.Dictionary.StiFunctions.removeFunction("GetEnumValue");


        const getEnumValueParamNames = ["enumeration", "value", "translations"];
        const getEnumValueParamTypes = [String, Number, String];
        const getEnumValueParamDescriptions = ["Name of the enumeration to get the label from", "Value you want to get the label for", "The translations definitions that contains the expected label"];

        Stimulsoft.Report.Dictionary.StiFunctions.addFunction(stringCategory, "GetEnumValue", "GetEnumValue", "Get the text label of an enumeration.",
            "", String, "TextLabel", getEnumValueParamTypes, getEnumValueParamNames, getEnumValueParamDescriptions, (enumeration: string, value: number, translations: string) => {
                const language = "en";
                if (!translations) {
                    return "";
                }
                try {
                    const dico = JSON.parse(translations);
                    if (!dico) {
                        return "";
                    }
                    const enumDefinition = dico[enumeration];
                    if (!enumDefinition) {
                        return "";
                    }
                    const valueDefinition = enumDefinition[value];
                    if (!valueDefinition) {
                        return "";
                    }
                    return valueDefinition[language] ?? valueDefinition.en ?? "";
                }
                catch {
                    return "";
                }
            });

        designer.renderHtml(STIMULSOFT_REPORT_DESIGNER_ID);
        designer.report = new Stimulsoft.Report.StiReport();
        ReportDesigner.DesignerInstance = designer;
    }
    public componentDidUpdate(prevProps: Readonly<IReportDesignerProps>, prevState: Readonly<IState>) {
        this.listenEventsIfNecessary(prevProps);
        this.setPreviewDataIfNecessary(prevProps);
        this.setTemplateIfNecessary(prevProps);
        this.setReadOnlyIfNecessary(prevProps);
    }
    public render() {
        return <div id={STIMULSOFT_REPORT_DESIGNER_ID} style={{ height: "100%" }}><CircularProgress />Loading the designer...</div>;
    }
    public componentWillUnmount() {
        ReportDesigner.DesignerInstance = undefined;
    }
    public componentDidMount() {
        const { props } = this;
        (async () => {
            await ReportDesigner.setupDesigner();
            await timeout(500);
            this.setTemplate(props);
            this.listenEvents(props);
            this.setPreviewData(props);
        })();
    }
    private setReadOnlyIfNecessary({ readOnly: prevReadOnly = false }: IReportDesignerProps) {
        const { readOnly = false } = this.props;
        if (readOnly !== prevReadOnly) {
            this.setReadOnly(readOnly);
        }
    }
    private setTemplateIfNecessary(prevProps: IReportDesignerProps) {
        const { template } = this.props;
        const { template: prevTemplate } = prevProps;
        if (template !== prevTemplate) {
            this.setTemplate(this.props);
        }
    }
    private setTemplate(props: IReportDesignerProps) {
        const { template } = props;
        const { DesignerInstance: designer } = ReportDesigner;
        if (template && designer) {
            const report = new Stimulsoft.Report.StiReport();
            // https://stimulsoft.zendesk.com/hc/en-us/articles/208580305-Custom-functions-in-JavaScript
//             const db=new Stimulsoft.Report.Dictionary.StiJsonDatabase();
//             db.regData()
// report.dictionary.databases.add();
            report.load(template);
            if (designer.report.reportGuid !== report.reportGuid) {
                designer.report = report;
            }
            // designer.report = new window.Stimulsoft.Report.StiReport();
            // designer.report.load(template);
        }
    }
    private setReadOnly(readOnly: boolean) {
        // const { DesignerInstance: designer } = ReportDesigner;
        // if (designer) {
        // }
    }
    private setPreviewDataIfNecessary(prevProps: IReportDesignerProps) {
        const { data } = this.props;
        const { data: prevData } = prevProps;
        if (data !== prevData) {
            this.setPreviewData(this.props);
        }
    }
    private setPreviewData(props: IReportDesignerProps) {
        const { data } = props
        const { DesignerInstance: designer } = ReportDesigner;
        if (data && designer) {
            const dataSet = new Stimulsoft.System.Data.DataSet('DataUniverse');
            // dataSet.
            dataSet.readJson(JSON.stringify(data));
            designer.report.regData(dataSet.dataSetName, dataSet.dataSetName, dataSet);
            // designer.report.dictionary.synchronize();
        }
    }
    private listenEventsIfNecessary(prevProps: IReportDesignerProps) {
        const { onSave, onSaveAs, onPreviewDataRequest: onRequestPreviewData, onExit } = this.props;
        const { onSave: prevOnSave, onSaveAs: prevOnSaveAs, onPreviewDataRequest: prevOnRequestPreviewData, onExit: prevOnExit } = prevProps;
        if (onSave !== prevOnSave || onSaveAs !== prevOnSaveAs || onRequestPreviewData !== prevOnRequestPreviewData || onExit !== prevOnExit) {
            this.listenEvents(this.props);
        }
    }
    private listenEvents(props: IReportDesignerProps) {
        const { onSave, onSaveAs, onPreviewDataRequest, onExit } = props;
        const { DesignerInstance: designer } = ReportDesigner;
        if (!designer) {
            return;
        }
        if (onSave) {
            designer.onSaveReport = this.handleSaveReport;
        }
        if (onSaveAs) {
            designer.onSaveAsReport = this.handleSaveAsReport;
        }
        if (onPreviewDataRequest) {
            designer.onPreviewReport = this.handlePreviewReport;
        }
        if (onExit) {
            designer.onExit = this.handleExit;
        }
    }
    private handleExit() {
        const { onExit } = this.props;
        if (onExit) {
            onExit();
        }
    }
    private handleSaveReport(designer: Stimulsoft.Designer.SaveReportArgs) {
        const { onSave } = this.props;
        if (onSave) {
            const jsonReport = designer.report.saveToJsonString();
            onSave(jsonReport);
        }
    }
    private handleSaveAsReport(designer: Stimulsoft.Designer.SaveReportArgs) {
        const { onSaveAs } = this.props;
        if (onSaveAs) {
            const jsonReport = designer.report.saveToJsonString();
            onSaveAs(designer.report.reportName, jsonReport);
        }
    }
    private handlePreviewReport(designer: Stimulsoft.Designer.PreviewReportArgs) {
        const { onPreviewDataRequest, data } = this.props;
        if (onPreviewDataRequest && !data) {
            onPreviewDataRequest();
        }
    }
}
export default ReportDesigner;
