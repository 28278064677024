import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Drawer,
    Icon,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Tooltip,
    Typography
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import AppTitle from './AppTitle';
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { GpsNotFixed } from "@material-ui/icons";
import { ScreenLink } from "tools/routing/ScreenLink"
import { ICategoryMenuState, IModuleMenuState } from '../slice';
import { useMenu } from '../menuTools';

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
    drawerPaper: {
        position: 'relative',
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.sharp,
        }),
        whiteSpace: 'nowrap',
        width: drawerWidth,
        background: theme.palette.background.default,
        boxShadow: theme.shadows[5]
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
        width: theme.spacing(5),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
    },
    accordionContent: {
        padding: 0
    },
    accordionContentList: {
        width: '100%'
    },
    accordionHeading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "normal",
        color: theme.palette.primary.contrastText
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.light,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: theme.palette.primary.main,
        ...theme.mixins.toolbar,
        "& .hidden-button": {
            opacity: 0,
            transition: theme.transitions.create("opacity", {
                duration: theme.transitions.duration.standard,
                easing: theme.transitions.easing.easeInOut,
            }),
        },
        "&:hover .hidden-button": {
            opacity: 1,
        }
    },
    menuList: {
        paddingBottom: 0,
        paddingTop: 0,
    },
    menuListItemCollapsedIcon: {
        minWidth: "inherit"
    }
}));

export function ApplicationMenu() {
    const classes = useStyles();
    const { currentCategory } = useMenu();
    const { menuDrawerOpened, menuLoading } = useReduxSelections("app");
    const { closeMenuDrawer } = useReduxActions("app");

    const { applicationMenu } = useReduxSelections("app");
    const [expanded, setExpanded] = useState<string>();
    useEffect(() => {
        if (!expanded && !!currentCategory) {
            setExpanded(currentCategory?.label);
        }
    }, [currentCategory, expanded]);

    const handleSyncCurrentLocation = useCallback(() => void setExpanded(currentCategory?.label), [currentCategory?.label]);

    const handleExpandedChanged = useCallback((category: ICategoryMenuState, expanded: boolean) => setExpanded(e => expanded ? category.label : undefined), []);

    return <Drawer
        variant="permanent"
        anchor="left"
        classes={{
            paper: classNames(classes.drawerPaper, !menuDrawerOpened && classes.drawerPaperClose),
        }}
        open={menuDrawerOpened}>
        <div className={classes.toolbar}>
            <AppTitle />
            <div style={{ flexGrow: 1 }} />
            <Tooltip title="Sync Menu" aria-label="Sync menu">
                <IconButton onClick={handleSyncCurrentLocation} className="hidden-button">
                    <GpsNotFixed fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Close Menu" aria-label="Close menu">
                <IconButton onClick={closeMenuDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Tooltip>
        </div>
        {menuLoading && <LinearProgress variant="indeterminate" />}
        <Box style={{ overflowY: "auto" }}>
            {(!menuDrawerOpened) && <List component="ul" disablePadding>
                {applicationMenu.categories
                    .map((level2Item, level2Idx) => <CollapsedModuleItem
                        key={level2Idx}
                        module={level2Item.subMenus[0]} />)}
            </List>}
            {menuDrawerOpened && <List className={classes.menuList}>
                {applicationMenu.categories
                    .map((category, idx) => <CategoryMenu
                        key={idx}
                        categoryState={category}
                        expanded={expanded === category.label}
                        onExpandedChanged={handleExpandedChanged} />)}
            </List>
            }
        </Box>
    </Drawer>
}
interface ICategoryMenuProps {
    categoryState: ICategoryMenuState;
    expanded: boolean;
    onExpandedChanged: (category: ICategoryMenuState, expanded: boolean) => void;
}
function CategoryMenu({ categoryState, onExpandedChanged, expanded }: ICategoryMenuProps) {
    const classes = useStyles();

    const handleOpenLevel1 = useCallback((event: ChangeEvent<{}>, newExpanded: boolean) => {
        onExpandedChanged(categoryState, newExpanded);
    }, [categoryState, onExpandedChanged]);

    return <Accordion TransitionProps={{ unmountOnExit: true }}
        expanded={expanded}
        onChange={handleOpenLevel1}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
            <Typography className={classes.accordionHeading}>{categoryState.label}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionContent}>
            <List component="ul" disablePadding={true} className={classes.accordionContentList}>
                {categoryState.subMenus.map((module, idx) => <ExpandedModuleItem module={module} key={idx} />)}
            </List>
        </AccordionDetails>
    </Accordion>
}

interface IModuleItemProps {
    module: IModuleMenuState
}

function CollapsedModuleItem({ module }: IModuleItemProps) {
    const { label, icon, screens } = module;
    const classes = useStyles();
    const { isCurrentModule } = useMenu();
    const currentModule = useMemo(() => isCurrentModule(module), [isCurrentModule, module]);
    const first = screens.at(0);
    if (first) {
        return <Tooltip title={label} placement="right">
            <ListItem button selected={currentModule} component={ScreenLink} destination={first}>
                <ListItemIcon className={classes.menuListItemCollapsedIcon}><Icon>{icon}</Icon></ListItemIcon>
            </ListItem>
        </Tooltip>
    }
    return <Tooltip title={label} placement="right">
        <span>
            <ListItem button disabled>
                <ListItemIcon className={classes.menuListItemCollapsedIcon}><Icon>{icon}</Icon></ListItemIcon>
            </ListItem>
        </span>
    </Tooltip>
}

function ExpandedModuleItem({ module }: IModuleItemProps) {
    const { label, icon, screens } = module;
    const { isCurrentModule } = useMenu();
    const currentModule = useMemo(() => isCurrentModule(module), [isCurrentModule, module]);
    const first = screens.at(0);
    if (first) {
        return <ListItem button component={ScreenLink} destination={first} selected={currentModule}>
            <ListItemIcon><Icon>{icon}</Icon></ListItemIcon>
            <ListItemText primary={label} />
        </ListItem>
    }
    return <ListItem button disabled selected={currentModule}>
        <ListItemIcon><Icon>{icon}</Icon></ListItemIcon>
        <ListItemText primary={label} />
    </ListItem>
}
