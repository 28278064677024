import { Icon } from "@material-ui/core";
import { IMenuTargetDashboard, IMenuTargetQuestionnaire, IMenuTargetScreen } from "features/App/menu/menuContracts";
import { useMemo } from "react";
import { typedScreenDictionary } from "tools/routing/screenRouteHooks";
import {
    IMenuDefinitionTreeNodeLevel1,
    IMenuDefinitionTreeNodeLevel2,
    IMenuDefinitionTreeNodeLevel3,
    ITreeItemModel,
} from "./menuTree";
import { createStyles, makeStyles } from "@material-ui/core/styles";


export function MenuNodeData({ model }: ITreeItemModel) {
    switch (model.type) {
        case "level1": return <MenuLevel1NodeData node={model} />
        case "level2": return <MenuLevel2NodeData node={model} />
        default: return <MenuTargetNodeData node={model} />
    }
}

export function MenuLevel1NodeData({ node }: { node: IMenuDefinitionTreeNodeLevel1 }) {
    return <span>{node.label}</span>
}

export function MenuLevel2NodeData({ node }: { node: IMenuDefinitionTreeNodeLevel2 }) {
    return <span style={{ display: "flex", alignItems: "center", gap: "1em" }}><Icon>{node.icon}</Icon>{node.label}</span>
}

export function MenuTargetNodeData({ node }: { node: IMenuDefinitionTreeNodeLevel3 }) {
    switch (node.type) {
        case "Screen":
            return <MenuTargetScreenNodeData node={node} />
        case "Dashboard":
            return <MenuDashboardNodeData node={node} />
        case "Questionnaire":
            return <MenuQuestionnaireNodeData node={node} />

    }
    // return <span>{node.type} as {node.label}</span>
}

const useMenuTargetScreenNodeDataStyles = makeStyles(theme =>
    createStyles({
        targetLabel: {
            fontStyle: "italic"
        },
        targetParamsContainer: {
            paddingLeft: theme.spacing(1.5),
        },
        targetParams: {
            ...theme.typography.body2,
            display: "inline-flex",
            listStyle: "none",
            margin: "unset",
            padding: "unset",
            gap: theme.spacing(1),
        },
        targetParamKey: {
            fontStyle: "italic",
        }
    }))


export function MenuTargetScreenNodeData({ node }: { node: IMenuTargetScreen }) {
    const screen = useMemo(() => {
        return typedScreenDictionary[node.key]
    }, [node.key]);

    const classes = useMenuTargetScreenNodeDataStyles()
    return <span>
        <span>{screen.label}</span>
        {screen.label !== node.label && <span className={classes.targetLabel}> as {node.label}</span>}
        {node.params &&
            <span className={classes.targetParamsContainer}>
                <ul className={classes.targetParams}>
                    {Object.entries(node.params).map(kv =>
                        <li><span className={classes.targetParamKey}>{kv[0]}</span>{!!kv[1] && <>: {kv[1]}</>}</li>
                    )}
                </ul>
            </span>
        }
    </span>
}
export function MenuDashboardNodeData({ node }: { node: IMenuTargetDashboard }) {
    const classes = useMenuTargetScreenNodeDataStyles()
    return <span>
        <span>{node.label} </span>
        <span className={classes.targetLabel}>{node.dashboardName} layout for {`${node.datasetName} dataset` ?? "no dataset"}</span>
    </span>
}
export function MenuQuestionnaireNodeData({ node }: { node: IMenuTargetQuestionnaire }) {
    const classes = useMenuTargetScreenNodeDataStyles()
    return <span>
        <span>{node.label} </span>
        <span className={classes.targetLabel}>{node.questionnaireName} questionnaire</span>
    </span>
}
