import { Epic } from 'redux-observable'
import { map, mergeMap } from 'rxjs/operators';
import { mapToPayload } from "lib/rxJsUtility";
import { tenantsApi } from "proxy/apiProxy";
import { Action, ActionFactories } from "./slice";


export const loadLogins: Epic<Action> = action$ => action$.pipe(
    mapToPayload("tenantManager", "tenantManagerLoginsLoad"),
    mergeMap(tenantsApi.getLoginsAsync),
    map(ActionFactories.tenantManagerLoginsLoaded));

export const adminSwitch: Epic<Action> = action$ => action$.pipe(
    mapToPayload("tenantManager", "tenantManagerLoginSwitch"),
    mergeMap(async i => tenantsApi.setTenantManagersAsync({ id: i.entityId, isTenantManager: i.isAdmin }).then(() => i)),
    map(ActionFactories.tenantManagerLoginSwitched));
