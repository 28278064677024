import FieldBox from "tools/components/FieldBox";
import FormTextField from "tools/fieldComponents/FormTextField";
import { IStatisticDefinitionSetModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";

export default function StatisticDefinitionSetData() {
    return <FieldBox display="flex" flexDirection="column" >
        <FormTextField name={oProps<IStatisticDefinitionSetModel>().path("code")} label="Code" required={true} maxLength={100} />
        <FormTextField name={oProps<IStatisticDefinitionSetModel>().path("name")} label="Name" required={true} maxLength={250} />
    </FieldBox>;
}
