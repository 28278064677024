import { Epic, ofType } from "redux-observable";
import { merge, of } from "rxjs";
import { map, share, debounceTime, filter, switchMap, mergeMap } from "rxjs/operators";
import {
    managedSicavsApi, macroScriptCategoriesApi, reportTemplateCategoriesApi, referencesApi, managedPortfoliosApi, subFundsApi, indexesApi,
    securitiesApi, entitiesApi, reportTemplatesApi, bankAccountsApi, batchesApi, processDefinitionsApi, cashMovementsApi, portfolioTransactionsApi
} from "proxy/apiProxy";
import { ActionFactories, IAnyAction, IState } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { toDictionary } from "tools/lib/utility";
import { getRelationshipApi } from "features/Relationship/getApi";

export const searchCashMovements: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referenceCashMovementSearch"),
            filter(({ criterias = "" }) => criterias.length >= 2),
            debounceTime(500));
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referenceCashMovementSearching)),
            searching$.pipe(
                mergeMap(cashMovementsApi.searchAsync),
                map(ActionFactories.reference.referenceCashMovementSearched)));
    }

export const searchPortfolioTransactions: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referencePortfolioTransactionSearch"),
            filter(({ criterias = "" }) => criterias.length >= 2),
            debounceTime(500));
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referencePortfolioTransactionSearching)),
            searching$.pipe(
                mergeMap(portfolioTransactionsApi.searchAsync),
                map(ActionFactories.reference.referencePortfolioTransactionSearched)));
    }

export const searchRelationship: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referenceRelationshipSearch"),
            filter(({ criterias = "" }) => criterias.length >= 2),
            debounceTime(500),
            share());
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referenceRelationshipSearching)),
            searching$.pipe(
                switchMap(({ type, criterias }) => of({ criterias }).pipe(mergeMap(getRelationshipApi(type).getAllAsync))),
                map(ActionFactories.reference.referenceRelationshipSearched)));
    }

export const searchProcesses: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referenceProcessDefinitionSearch"),
            filter((criterias = "") => criterias.length >= 2),
            debounceTime(500));
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referenceProcessDefinitionSearching)),
            searching$.pipe(
                switchMap((criterias) => of({ criterias }).pipe(mergeMap(processDefinitionsApi.getAllAsync))),
                map(ActionFactories.reference.referenceProcessDefinitionSearched)));
    }

export const searchBatches: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referenceBatchSearch"),
            filter((criterias = "") => criterias.length >= 2),
            debounceTime(500));
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referenceBatchSearching)),
            searching$.pipe(
                mergeMap(criterias => batchesApi.getAllAsync({ criterias })),
                map(ActionFactories.reference.referenceBatchSearched)));
    }

export const searchBankAccount: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referenceBankAccountSearch"),
            filter(({ criterias = "" }) => criterias.length >= 2),
            debounceTime(500));
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referenceBankAccountSearching)),
            searching$.pipe(
                mergeMap(bankAccountsApi.getAllAsync),
                map(ActionFactories.reference.referenceBankAccountSearched)));
    }

export const searchSecurities: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referenceSecuritySearch"),
            filter(({ criterias = "" }) => criterias.length >= 2),
            debounceTime(500),
            share());
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referenceSecuritySearching)),
            searching$.pipe(
                mergeMap(securitiesApi.searchAsync),
                map(ActionFactories.reference.referenceSecuritySearched)));
    }

export const searchEntities: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referenceEntitySearch"),
            filter(({ criterias }) => criterias.length >= 2),
            debounceTime(500),
            share());
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referenceEntitySearching)),
            searching$.pipe(
                mergeMap(entitiesApi.searchAsync),
                map(ActionFactories.reference.referenceEntitySearched)));
    }

export const searchReportTemplate: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referenceReportTemplateSearch"),
            filter(({ criterias = "" }) => criterias.length >= 2),
            debounceTime(500));
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referenceReportTemplateSearching)),
            searching$.pipe(
                mergeMap(reportTemplatesApi.getAllAsync),
                map(ActionFactories.reference.referenceReportTemplateSearched)));
    }

export const searchIndexes: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referenceIndexSearch"),
            filter(criterias => criterias.length >= 2),
            debounceTime(500));
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referenceIndexSearching)),
            searching$.pipe(
                mergeMap(criterias => indexesApi.getAllAsync({ criterias })),
                map(ActionFactories.reference.referenceIndexSearched)));
    }

export const searchManagedPortfolios: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referenceManagedPortfolioSearch"),
            filter(criterias => (!!criterias.criterias) && (criterias.criterias.length >= 2)),
            debounceTime(500));
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referenceManagedPortfolioSearching)),
            searching$.pipe(
                mergeMap(managedPortfoliosApi.searchAsync),
                map(ActionFactories.reference.referenceManagedPortfolioSearched)));
    }

export const searchManagedSicavs: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referenceManagedSicavSearch"),
            filter(criterias => (!!criterias.criterias) && (criterias.criterias.length >= 2)),
            debounceTime(500));
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referenceManagedSicavSearching)),
            searching$.pipe(
                mergeMap(managedSicavsApi.getAllAsync),
                map(ActionFactories.reference.referenceManagedSicavSearched)));
    }

export const searchSubfunds: Epic<IAnyAction>
    = action$ => {
        const searching$ = action$.pipe(
            mapToPayload("reference", "referenceSubFundSearch"),
            filter(criterias => (!!criterias.criterias) && (criterias.criterias.length >= 2)),
            debounceTime(500));
        return merge(
            searching$.pipe(
                map(ActionFactories.reference.referenceSubFundSearching)),
            searching$.pipe(
                mergeMap(subFundsApi.getAllAsync),
                map(ActionFactories.reference.referenceSubFundSearched)));
    }

export const loadReferences: Epic<IAnyAction, IAnyAction, IState>
    = action$ => {
        const loadAll$ = action$.pipe(ofType("applicationLoaded"), share());
        const reportTemplateCategoriesChanged$ = action$.pipe(ofType("reportTemplateCategoryDeleted", "reportTemplateCategorySaved"));
        const macroScriptCategoriesChanged$ = action$.pipe(ofType("macroScriptCategoryDeleted", "macroScriptCategorySaved"));

        const ratingTypes$ = loadAll$.pipe(
            mergeMap(referencesApi.getAllRatingTypesAsync),
            map(ActionFactories.reference.referenceRatingTypesLoaded));
        const parameters$ = loadAll$.pipe(
            map(ActionFactories.parameters.parametersLoad));
        const currencies$ = loadAll$.pipe(
            mergeMap(referencesApi.getAllCurrenciesAsync),
            map(ActionFactories.reference.referenceCurrenciesLoaded));
        const countries$ = loadAll$.pipe(
            mergeMap(referencesApi.getAllCountriesAsync),
            map(ActionFactories.reference.referenceCountriesLoaded));

        const reportTemplateCategories$ = merge(loadAll$, reportTemplateCategoriesChanged$).pipe(
            mergeMap(() => reportTemplateCategoriesApi.getAllAsync({})),
            map(i => ActionFactories.reference.referenceReportTemplateCategoriesLoaded(toDictionary(i, e => e.id))));
        const macroScriptCategories$ = merge(loadAll$, macroScriptCategoriesChanged$).pipe(
            mergeMap(() => macroScriptCategoriesApi.getAllAsync({})),
            map(i => ActionFactories.reference.referenceMacroScriptCategoriesLoaded(toDictionary(i, e => e.id))));

        return merge(
            parameters$,
            currencies$,
            ratingTypes$,
            countries$,
            reportTemplateCategories$,
            macroScriptCategories$,
        );
    }
