import { IFeatureModel } from "proxy/apiProxy";
import { ClassificationTypeDomain } from "./ClassificationTypeDomain";

export function getRelatedGrants(domain: ClassificationTypeDomain): IFeatureModel | [IFeatureModel] {
    switch (domain) {
        case ClassificationTypeDomain.Investor: return IFeatureModel.InvestorClassificationTypeWrite;
        case ClassificationTypeDomain.RoleRelationship: return IFeatureModel.RoleRelationshipClassificationTypeWrite;
        case ClassificationTypeDomain.Movement: return IFeatureModel.ManagedPortfolioWrite;
        case ClassificationTypeDomain.Security: return IFeatureModel.SecurityClassificationTypeWrite;
        case ClassificationTypeDomain.Entity: return IFeatureModel.EntityClassificationTypeWrite;
        case ClassificationTypeDomain.Note: return IFeatureModel.GeneralNoteWrite;
        case ClassificationTypeDomain.Process: return IFeatureModel.SysAdmin;
        case ClassificationTypeDomain.DevelopmentItem: return IFeatureModel.SysAdmin;
        case ClassificationTypeDomain.Document: return IFeatureModel.DocumentWrite;
        case ClassificationTypeDomain.PortfolioTransaction: return IFeatureModel.PortfolioTransactionClassificationTypeWrite;
    }
}
