import DetailPanel from "tools/components/DetailPanel";
import { IFeatureModel, IMacroScriptCategoryModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import FieldBox from "tools/components/FieldBox";
import { isNumber, oProps, useNumber } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";

export const macroScriptCategoryScreen = {
    route: "/config/macros/categories/:id" as const,
    component: MacroScriptCategory,
    label: "Macro Category"
}

function MacroScriptCategory() {
    const { macroScriptCategoryCurrent, macroScriptCategoryLoading, macroScriptCategorySaving } = useReduxSelections("macroScriptCategory");
    const {
        macroScriptCategoryDelete,
        macroScriptCategorySave,
        macroScriptCategoryLoad
    } = useReduxActions("macroScriptCategory");
    const {id} = useScreenParams<typeof macroScriptCategoryScreen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (isNumber(idNum))
            macroScriptCategoryLoad(idNum)
    }, [idNum, macroScriptCategoryLoad]);

    if (!macroScriptCategoryCurrent) {
        return <DetailPanelLoading hasSubTitle={false} />;
    }
    const handleDelete = () => {
        if (macroScriptCategoryCurrent?.id) {
            macroScriptCategoryDelete(macroScriptCategoryCurrent.id);
        }
    }
    const handleSubmit = (values: IMacroScriptCategoryModel, { setSubmitting }: FormikHelpers<IMacroScriptCategoryModel>) => {
        macroScriptCategorySave(values);
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={macroScriptCategoryCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;

    function renderForm(formikProps: FormikProps<IMacroScriptCategoryModel>) {
        if (!macroScriptCategoryCurrent) {
            return;
        }
        const { dirty, isValid, submitForm, values: { id } } = formikProps;

        const title = !!id ? "Macro Category" : 'New Macro Category';
        return <WriteAccess value={IFeatureModel.MacroCategoryWrite}>
            <DetailPanel
                isQuerying={macroScriptCategoryLoading || macroScriptCategorySaving}
                title={title}
                canSave={dirty && isValid}
                saveAllowed={IFeatureModel.MacroCategoryWrite}
                deleteAllowed={IFeatureModel.MacroCategoryWrite}
                onSaveClick={submitForm}
                canDelete={!!macroScriptCategoryCurrent.id}
                onDeleteClick={handleDelete}
                badge={!macroScriptCategoryCurrent.id ? "new" : undefined}>
                <FieldBox display="flex" flexDirection="column">
                    <FormTextField name={oProps<IMacroScriptCategoryModel>().path("name")} label="Name" required={true} maxLength={250} />
                </FieldBox>
            </DetailPanel>
        </WriteAccess>
    }

}
