export interface IGroupedElements<T> {
    notGrouped: T[];
    groups: { categoryId: number, elements: T[] }[];
}
export function groupElements<T>(elements: T[], getCategoryId: (e: T) => number | undefined): IGroupedElements<T> {
    const { notGrouped, groups } = elements.reduce((a, v) => {
        const categoryId = getCategoryId(v);
        if (!categoryId) {
            a.notGrouped.push(v);
        }
        else {
            let group = a.groups[categoryId];
            if (!group) {
                group = [];
                a.groups[categoryId] = group;
            }
            group.push(v);
        }
        return a;
    }, { notGrouped: [], groups: {} } as { notGrouped: T[]; groups: Record<number, T[]>; });
    return { notGrouped, groups: Object.keys(groups).map(key => ({ categoryId: key as unknown as number, elements: groups[key as unknown as number] })) };
}
