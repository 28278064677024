import { Epic } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { studioDevelopmentItemsRunApi } from "proxy/apiProxy";
import { mapToPayload } from "lib/rxJsUtility";
import { merge, of } from "rxjs";
import { ActionFactories, Action } from "./slice";

export const genericScreenLoad: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("genericScreen", "genericScreenLoad"),
        mergeMap(({ questionnaireName, ...input }) => {
            const getScreenLayout$ = of(questionnaireName).pipe(
                mergeMap((questionnaireName) => studioDevelopmentItemsRunApi.getQuestionnaireTemplateAsync({ questionnaireName })),
                map(ActionFactories.genericScreenLayoutLoaded)
            );
            return merge(
                of(ActionFactories.genericScreenDatasetLoad({ ...input, questionnaireName })),
                getScreenLayout$
            );
        })
    );
export const genericScreenDatasetLoad: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("genericScreen", "genericScreenDatasetLoad"),
        mergeMap(({ questionnaireName, ...input }) => studioDevelopmentItemsRunApi.getQuestionnaireContentAsync({ questionnaireName, loadQuestionnaire: { type: "SimpleExecuteModel", ...input } })),
        map(ActionFactories.genericScreenDatasetLoaded));

export const genericScreenSave: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("genericScreen", "genericScreenSave"),
        mergeMap(async input => {

            const result = await studioDevelopmentItemsRunApi.completeQuestionnaireAsync(input);
            const { questionnaireName, executeSimpleQuestionnaireOnCompleteModel: { type, ...loadQuestionnaire } = {} } = input;

            return merge(
                of(ActionFactories.genericScreenSaved(result)),
                of(ActionFactories.genericScreenDatasetLoad({ questionnaireName, ...loadQuestionnaire })),
            )
        }),
        mergeMap(i => i)
    );
