import autobind from "autobind-decorator";
import * as React from 'react';
import { Plugin, Getter, Getters } from '@devexpress/dx-react-core';
import { Column } from "@devexpress/dx-react-grid";

export interface IToolbarExportData {
    columns: Column[];
    getCellValue: (row: any, columnName: string) => any;
    rows: any[];
}

@autobind
export class ToolbarExportState extends React.PureComponent {
    public render() {
        return (
            <Plugin name="ToolbarExportState">
                <Getter name="toolbarExportStateData" computed={this.getExportData} />
            </Plugin>
        );
    }
    private getExportData({ rows, columns, getCellValue }: Getters): IToolbarExportData {
        return { rows, columns, getCellValue } as IToolbarExportData;
    }
}
