import { IMacroScriptErrorModel } from "proxy/apiProxy";
import { useField } from "formik";
import CodeEditor from "tools/components/CodeEditor";
import { IMacroScriptMetadata } from "features/MacroScript/slice";
import { Box } from "@material-ui/core";
import ReactJson from 'react-json-view'
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";

// https://developers.google.com/fonts/docs/getting_started
export interface IPortfolioScriptEditorProps {
    onScriptChanged: (script: string) => void;
    scriptErrors: IMacroScriptErrorModel[];
    metadata: IMacroScriptMetadata;
    onSaveRequest: () => void;
    fieldName: string;
};

export default function BatchConditionalScriptEditor({ onScriptChanged, scriptErrors, onSaveRequest, metadata, fieldName }: IPortfolioScriptEditorProps) {
    const [, { value: script }, { setValue: setScript }] = useField<string | undefined>(fieldName);

    const handleCodeChanged = (code: string) => {
        setScript(code);
        onScriptChanged(code);
    }
    return (<Box display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}>
        <div style={{ height: "100%", width: "calc(100% - 400px)" }}>
            <ReadOnlyContext.Consumer>
                {readOnly => <CodeEditor
                    readOnly={readOnly}
                    code={script}
                    onSave={onSaveRequest}
                    onCodeChanged={handleCodeChanged}
                    codeErrors={scriptErrors}
                    language="csharp" />}
            </ReadOnlyContext.Consumer>
        </div>
        <div style={{ height: "100%", overflowY: "auto", width: 400 }}>
            <ReactJson
                style={{ width: 600 }}
                name="Referential"
                collapsed={1}
                displayDataTypes={false}
                displayObjectSize={false}
                src={metadata} />
        </div>
    </Box>);
}
