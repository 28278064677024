import {
    EntitySummaryModel, ICurrencyModel, ICountryModel, ISicavModel, ISicavLegalFormModel, ISicavTypeModel, IEntityTypeModel, IClassificationTypeModel
} from "proxy/apiProxy";
import FieldBox from "tools/components/FieldBox";
import { IDictionary, cultures, toDictionary, oProps, Base64FileConverter } from "tools/lib/utility";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import EntitySelectField from "components/searchers/EntitySelectField";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { IGetEntitySummary } from "features/Reference/slice";
import EntitySummary from "components/summaries/EntitySummary";
import { Box, Card, CardContent, Typography, Grid } from "@material-ui/core";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import { CountrySelectField } from "components/searchers/CountrySelectField";
import FormSelectImageField from "tools/fieldComponents/FormSelectImageField";
import ClassificationsData from "components/global/ClassificationsData";

const dicoCultures = toDictionary(cultures, i => i.code, i => i.label);
const imageFileConverter = new Base64FileConverter();

const sicavLegalForm = ISicavLegalFormModel;
const sicavType = ISicavTypeModel;

export interface IEntityDataProps {
    formFieldName?: string;
    formImageFieldName?: string;
    referenceCountries: IDictionary<ICountryModel>;
    referenceCurrencies: IDictionary<ICurrencyModel>;
    onEntityLoaded: (found: IGetEntitySummary) => void;
    classificationTypes: IClassificationTypeModel[];

    dictionaries: {
        entities: Record<number, EntitySummaryModel>;
    }
}
export default function SicavData({ classificationTypes, formFieldName, referenceCountries, referenceCurrencies, onEntityLoaded, formImageFieldName, dictionaries: { entities } }: IEntityDataProps) {
    return <FieldBox display="flex" flexDirection="column">
        <Grid container={true} spacing={1}>
            <Grid container={true} item={true} spacing={1} sm={6} md={6} lg={7} alignContent="flex-start">
                {formImageFieldName && <Grid item={true} sm={6} md={6} lg={6}>
                    <FormSelectImageField name={formImageFieldName} contentName="Picture" converter={imageFileConverter} />
                </Grid>}
                <Grid item={true} container={true} sm={6} md={6} lg={6} spacing={1}>
                    <Grid item={true} sm={12} md={12} lg={6}>
                        <FormTextField name={oProps<ISicavModel>(formFieldName).path("internalCode")} label="Internal Code" required={true} maxLength={100} />
                    </Grid>
                    <Grid item={true} sm={12} md={12} lg={6}>
                        <FormTextField name={oProps<ISicavModel>(formFieldName).path("name")} label="Name" required={true} maxLength={250} />
                    </Grid>
                    <Grid item={true} sm={12} md={12} lg={6}>
                        <FormTextField name={oProps<ISicavModel>(formFieldName).path("vatNumber")} label="VAT Number" maxLength={10} />
                    </Grid>
                    <Grid item={true} sm={12} md={12} lg={6}>
                        <FormTextField name={oProps<ISicavModel>(formFieldName).path("registrationNumber")} label="Registration Number" maxLength={100} />
                    </Grid>
                </Grid>

                <Grid item={true} sm={12} md={12} lg={6}>
                    <CurrencySelectField name={oProps<ISicavModel>(formFieldName).path("currencyId")} label="Currency" />
                </Grid>
                <Grid item={true} sm={12} md={12} lg={6}>
                    <FormSimpleSelectField name={oProps<ISicavModel>(formFieldName).path("culture")} label="Culture" options={dicoCultures} />
                </Grid>
                <Grid item={true} sm={12} md={12} lg={6}>
                    <FormTextField name={oProps<ISicavModel>(formFieldName).path("iban")} label="Iban" maxLength={34} />
                </Grid>
                <Grid item={true} sm={12} md={12} lg={6}>
                    <FormSimpleSelectField name={oProps<ISicavModel>(formFieldName).path("legalForm")} label="Legal Form" options={sicavLegalForm} />
                </Grid>
                <Grid item={true} sm={12} md={12} lg={6}>
                    <FormSimpleSelectField name={oProps<ISicavModel>(formFieldName).path("sicavType")} label="Type" options={sicavType} />
                </Grid>
                <Grid item={true} sm={12} md={12} lg={6}>
                    <FormDatePickerField name={oProps<ISicavModel>(formFieldName).path("yearEnd")} label="Year End" disableToolbar={true} />
                </Grid>
            </Grid>
        </Grid>
        <Grid container={true}>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormCheckBoxField name={oProps<ISicavModel>(formFieldName).path("regulated")} label="Regulated" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormCheckBoxField name={oProps<ISicavModel>(formFieldName).path("cssfEquivalentSupervision")} label="CSSF Equivalent Supervision" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormCheckBoxField name={oProps<ISicavModel>(formFieldName).path("discretionaryManagementLicensed")} label="Discretionary Management Licensed" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormCheckBoxField name={oProps<ISicavModel>(formFieldName).path("aifm")} label="Aifm" />
            </Grid>
        </Grid>
        <Box>
            <EntitySelectField type={IEntityTypeModel.Company} name={oProps<ISicavModel>(formFieldName).path("issuerId")} label="Issuer" onSelected={onEntityLoaded} />
            <EntitySummary name={oProps<ISicavModel>(formFieldName).path("issuerId")} countries={referenceCountries} entities={entities} label="Issuer" />
        </Box>
        <ClassificationsData
                    panelTitle="Classifications"
                    fieldName={oProps<ISicavModel>(formFieldName).path("classifications")}
                    classificationTypes={classificationTypes} />
        <Card >
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    Contact
                </Typography>
                <Grid container={true} spacing={1}>
                    <Grid container={true} item={true} spacing={1} sm={12} md={6} lg={6} alignContent="flex-start">
                        <Grid item={true} sm={12} md={12} lg={12}>
                            <FormTextField name={oProps<ISicavModel>(formFieldName).path("url")} label="Url" />
                        </Grid>
                        <Grid item={true} sm={12} md={12} lg={12}>
                            <FormTextField name={oProps<ISicavModel>(formFieldName).path("phoneNumber")} label="Phone Number" maxLength={100} />
                        </Grid>
                        <Grid item={true} sm={12} md={12} lg={12}>
                            <FormTextField name={oProps<ISicavModel>(formFieldName).path("email")} label="Email" isEmail={true} />
                        </Grid>
                    </Grid>
                    <Grid container={true} item={true} spacing={1} sm={12} md={6} lg={6} alignContent="flex-start">
                        <Grid item={true} sm={12} md={12} lg={12}>
                            <FormTextField name={oProps<ISicavModel>(formFieldName).path("streetAddress")} label="Street Address" />
                        </Grid>
                        <Grid item={true} sm={12} md={12} lg={12}>
                            <FormTextField name={oProps<ISicavModel>(formFieldName).path("zipCode")} label="Zip Code" maxLength={10} />
                        </Grid>
                        <Grid item={true} sm={12} md={12} lg={12}>
                            <FormTextField name={oProps<ISicavModel>(formFieldName).path("location")} label="Location" maxLength={250} />
                        </Grid>
                        <Grid item={true} sm={12} md={12} lg={12}>
                            <CountrySelectField name={oProps<ISicavModel>(formFieldName).path("countryId")} label="Country" />
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </FieldBox>
}
