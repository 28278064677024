import { Button, Card, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core"; import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import { ICurrencyModel } from "proxy/apiProxy";
import StickyFab from "tools/components/StickyFab";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { oProps, today } from "tools/lib/utility";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import AccountRightIcon from "mdi-material-ui/AccountArrowRight"
import AccountLeftIcon from "mdi-material-ui/AccountArrowLeftOutline"
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import FormTextField from "tools/fieldComponents/FormTextField";
import { FormClosingElement, IFormCapitalCallModel, IFormPortfolioClosingModel, IFormPortfolioDistributionModel } from "./FormPortfolioModel";

export interface IClosingsProps {
    formFieldName: string;
    referenceCurrencies: Record<number, ICurrencyModel>;
    currencyId: number | undefined;
}

export default function Closings({ formFieldName, referenceCurrencies, currencyId }: IClosingsProps) {
    const [{ value: closings }] = useField<IFormPortfolioClosingModel[]>(formFieldName);
    return <FieldArray name={formFieldName}>{renderClosings}</FieldArray>
    function renderClosings({ push, remove, form: { validateForm } }: FieldArrayRenderProps) {
        const newClosing: IFormPortfolioClosingModel = { elements: [], fromDate: today(), id: 0 };
        const handleAdd = () => {
            push(newClosing);
            setTimeout(() => validateForm(), 100);
        }
        const handleDelete = (idx: number) => {
            remove(idx);
            setTimeout(() => validateForm(), 100);
        }
        return <ReadOnlyContext.Consumer>
            {readOnly => <>
                {!closings.length && <Typography>No closing</Typography>}
                {!!closings.length && <Grid container={true} spacing={1}>
                    {closings.map((closing, index) => <Grid item={true} xs={12}><Closing
                        key={index}
                        index={index}
                        closing={closing}
                        readOnly={readOnly}
                        currency={referenceCurrencies[currencyId ?? 0]}
                        onDelete={handleDelete}
                        pathToValue={oProps<IFormPortfolioClosingModel[]>(formFieldName).path(index)} /></Grid>)}</Grid>}
                {!readOnly && <StickyFab onClick={handleAdd}>
                    <AddIcon />
                </StickyFab>}
            </>}
        </ReadOnlyContext.Consumer>
    }
}
interface IClosingProps {
    currency: ICurrencyModel | undefined;
    onDelete: (index: number) => void;
    readOnly: boolean;
    index: number;
    pathToValue: string;
    closing: IFormPortfolioClosingModel;
}
function Closing({ onDelete, currency, readOnly, index, pathToValue, closing: { fromDate, elements } }: IClosingProps) {
    const handleDelete = () => onDelete(index);
    return <Card>
        <CardContent>
            <Grid container={true} spacing={1} alignItems="center" >
                <Grid item={true} xs={3}>
                    <FormTextField label="Name" name={oProps<IFormPortfolioClosingModel>(pathToValue).path("name")} />
                </Grid>
                <Grid item={true} xs={3}>
                    <FormDatePickerField label="From Date" name={oProps<IFormPortfolioClosingModel>(pathToValue).path("fromDate")} />
                </Grid>
                {!readOnly && <Grid item={true} xs={3}>
                    <Button
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        size="small"
                        onClick={handleDelete} >Delete Closing</Button>
                </Grid>}
                <Grid item={true} xs={12}>
                    <FieldArray name={oProps<IFormPortfolioClosingModel>(pathToValue).path("elements")}>{renderElements}</FieldArray>
                </Grid>
            </Grid>
        </CardContent>
    </Card >
    function renderElements({ push, remove, form: { validateForm } }: FieldArrayRenderProps) {
        const handleAddDistribution = () => {
            push({ type: "distribution" } as IFormPortfolioDistributionModel);
            setTimeout(() => validateForm(), 100);
        }
        const handleAddCapitalCall = () => {
            push({ type: "capitalCall" } as IFormCapitalCallModel);
            setTimeout(() => validateForm(), 100);
        }
        const handleDelete = (idx: number) => {
            remove(idx);
            setTimeout(() => validateForm(), 100);
        }


        return <>
            {!elements.length && <Typography>No distribution</Typography>}
            {!!elements.length && <Table>
                <TableHead>
                    <TableRow>
                        <TableCell size="small" />
                        <TableCell size="small">Name</TableCell>
                        <TableCell size="small">Date</TableCell>
                        <TableCell size="small">Amount</TableCell>
                        <TableCell size="small" align="right" />
                        {!readOnly && <TableCell size="small" />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {elements.map((_, idx) => <DistributionOrCapitalCall
                        key={idx}
                        currency={currency}
                        index={idx}
                        onDelete={handleDelete}
                        readOnly={readOnly}
                        pathToValue={oProps<IFormPortfolioClosingModel>(pathToValue).path("elements", idx)} />)}
                </TableBody>
            </Table>}
            {!readOnly && <Grid container={true} spacing={1}>
                <Grid item={true}>
                    <Button style={{ marginTop: 8 }}
                        startIcon={<AccountLeftIcon fontSize="small" />}
                        variant="contained"
                        size="small"
                        onClick={handleAddDistribution} >Add Distribution</Button>
                </Grid>
                <Grid item={true}>
                    <Button style={{ marginTop: 8 }}
                        startIcon={<AccountRightIcon fontSize="small" />}
                        variant="contained"
                        size="small"
                        onClick={handleAddCapitalCall} >Add Capital Call</Button>
                </Grid>
            </Grid>}
        </>
    }
}
interface IDistributionOrCapitalCallProps {
    currency: ICurrencyModel | undefined;
    onDelete: (index: number) => void;
    readOnly: boolean;
    index: number;
    pathToValue: string;
}
function DistributionOrCapitalCall(props: IDistributionOrCapitalCallProps) {
    const [{ value: { type } }] = useField<FormClosingElement>(props.pathToValue);
    switch (type) {
        case "capitalCall": return <CapitalCall {...props} />
        case "distribution": return <Distribution {...props} />
    }
}
function CapitalCall({ pathToValue, onDelete, index, readOnly, currency }: IDistributionOrCapitalCallProps) {
    const handleDelete = () => onDelete(index);
    return <TableRow>
        <TableCell size="small" style={{ paddingTop: 0, paddingBottom: 0 }}><AccountRightIcon /> Capital Call </TableCell>
        <TableCell size="small" style={{ paddingTop: 0, paddingBottom: 0 }}><FormTextField name={oProps<IFormCapitalCallModel>(pathToValue).path("name")} /></TableCell>
        <TableCell size="small" style={{ paddingTop: 0, paddingBottom: 0 }}><FormDatePickerField name={oProps<IFormCapitalCallModel>(pathToValue).path("date")} required={true} /></TableCell>
        <TableCell size="small" style={{ paddingTop: 0, paddingBottom: 0 }}><FormTextField name={oProps<IFormCapitalCallModel>(pathToValue).path("amount")} required={true} isNumber={true} minValue={0} adornment={currency?.isoCode} /></TableCell>
        <TableCell size="small" style={{ paddingTop: 0, paddingBottom: 0 }} align="right"><FormCheckBoxField name={oProps<IFormCapitalCallModel>(pathToValue).path("noticeSentToInvestors")} label="Notice sent to investors" alignCheckboxToRight={true} /></TableCell>
        {!readOnly && <TableCell size="small" align="right" style={{ paddingTop: 0, paddingBottom: 0 }}><IconButton onClick={handleDelete} size="small"><DeleteIcon fontSize="small" /></IconButton> </TableCell >}
    </TableRow>
}
function Distribution({ pathToValue, onDelete, index, readOnly, currency }: IDistributionOrCapitalCallProps) {
    const handleDelete = () => onDelete(index);
    return <TableRow>
        <TableCell size="small" style={{ paddingTop: 0, paddingBottom: 0 }}><AccountLeftIcon /> Distribution</TableCell>
        <TableCell size="small" style={{ paddingTop: 0, paddingBottom: 0 }}><FormTextField name={oProps<IFormPortfolioDistributionModel>(pathToValue).path("name")} /></TableCell>
        <TableCell size="small" style={{ paddingTop: 0, paddingBottom: 0 }}><FormDatePickerField name={oProps<IFormPortfolioDistributionModel>(pathToValue).path("date")} required={true} /></TableCell>
        <TableCell size="small" style={{ paddingTop: 0, paddingBottom: 0 }}><FormTextField name={oProps<IFormPortfolioDistributionModel>(pathToValue).path("amount")} required={true} isNumber={true} minValue={0} adornment={currency?.isoCode} /></TableCell>
        <TableCell size="small" style={{ paddingTop: 0, paddingBottom: 0 }} align="right"><FormCheckBoxField name={oProps<IFormPortfolioDistributionModel>(pathToValue).path("recallable")} label="Recallable" alignCheckboxToRight={true} /></TableCell>
        {!readOnly && <TableCell size="small" align="right" style={{ paddingTop: 0, paddingBottom: 0 }}><IconButton onClick={handleDelete} size="small"><DeleteIcon fontSize="small" /></IconButton> </TableCell >}
    </TableRow>
}