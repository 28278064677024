import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { IBatchSummaryModel, IClassificationTypeModel, IParametersModel, IPeriodModel } from "proxy/apiProxy";
import FieldBox from "tools/components/FieldBox";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import { getEnumLabels, oProps } from "tools/lib/utility";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import { BatchSelectField } from "components/searchers/BatchSelectField";
import { BatchSummary } from "components/summaries/BatchSummary";
import { useField } from "formik";

export interface IParametersDataProps {
    securityClassificationTypes: IClassificationTypeModel[];
    dictionaries: {
        batches: Record<number | string, IBatchSummaryModel>
    }
    onBatchLoaded: (batch: IBatchSummaryModel) => void;
}
const periods = getEnumLabels(IPeriodModel);

export default function ParametersData({ securityClassificationTypes, dictionaries: { batches }, onBatchLoaded }: IParametersDataProps) {
    const handleGetClassificationTypePrimaryContent = (i: IClassificationTypeModel) => i.name["en"];
    const [{ value: tradesSubmitBatchId }] = useField<number | undefined>(oProps<IParametersModel>().path("tradesSubmitBatchId"));
    const [{ value: tradesReconcileBatchId }] = useField<number | undefined>(oProps<IParametersModel>().path("tradesReconcileBatchId"));
    return <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} sm={6}>
            <FieldBox display="flex" flexDirection="column">
                <CurrencySelectField name={oProps<IParametersModel>().path("defaultCurrencyId")} label="Default currency" />
                <Card>
                    <CardHeader title="Classification Types Usages" />
                    <CardContent>
                        <FieldBox display="flex" flexDirection="column">
                            <FormSimpleSelectField name={oProps<IParametersModel>().path("accountingChartClassificationTypeId")} label="Accounting Chart" options={securityClassificationTypes} getPrimaryContent={handleGetClassificationTypePrimaryContent} />
                        </FieldBox>
                    </CardContent>
                </Card>
            </FieldBox>
        </Grid>
        <Grid item={true} xs={12} sm={6}>
            <Card>
                <CardHeader title="Risk Management" />
                <CardContent>
                    <FieldBox display="flex" flexDirection="column">
                        <FormSimpleSelectField name={oProps<IParametersModel>().path("defaultClassificationTypeForRiskManagementId")} label="Default Classification Type" options={securityClassificationTypes} getPrimaryContent={handleGetClassificationTypePrimaryContent} />
                        <FormSimpleSelectField name={oProps<IParametersModel>().path("pricePeriodForRiskManagement")} label="Price Period" options={periods} />
                        <FormSimpleSelectField name={oProps<IParametersModel>().path("positionPeriodForRiskManagement")} label="Position Period" options={periods} />
                    </FieldBox>
                </CardContent>
            </Card>
        </Grid>
        <Grid item={true} xs={12} sm={6}>
            <Card>
                <CardHeader title="Dealing" />
                <CardContent>
                    <FieldBox display="flex" flexDirection="column">
                        <BatchSelectField name={oProps<IParametersModel>().path("tradesSubmitBatchId")} label="Batch that submits trades" onSelected={onBatchLoaded} />
                        {!!tradesSubmitBatchId && <BatchSummary name={oProps<IParametersModel>().path("tradesSubmitBatchId")} batch={batches[tradesSubmitBatchId]} label="Batch that submits trades" />}
                        <BatchSelectField name={oProps<IParametersModel>().path("tradesReconcileBatchId")} label="Batch that gets and reconciliates trades" onSelected={onBatchLoaded} />
                        {!!tradesReconcileBatchId && <BatchSummary name={oProps<IParametersModel>().path("tradesReconcileBatchId")} batch={batches[tradesReconcileBatchId]} label="Batch that gets and reconciliates trades" />}
                    </FieldBox>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}