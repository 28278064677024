import * as React from "react";
import { useField } from "formik";
import { IEntityMetadataModel, IReportTemplateDevelopmentItemModel, DevelopmentItemModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import { IDatasetResult, ITypedMacro } from "../slice";
import ReportDesigner from "../Designers/ReportDesigner";
import { EditorReferencePayload } from "../Designers/INotControllableEditorProps";
import { developmentItemScreen } from "../DevelopmentItem";

export interface IReportTemplateDevelopmentItemRefs {
    reportTemplateRef: React.MutableRefObject<EditorReferencePayload<string>>;
    // reportLoadMacroRef: React.MutableRefObject<EditorReferencePayload<IMacroModel>>;
}
export interface IReportTemplateDevelopmentItemEditorProps extends IReportTemplateDevelopmentItemRefs {
    type: DevelopmentItemModel["type"];
    tabValue: keyof typeof developmentItemScreen["tabs"] | undefined;
    metadata: Record<string | number, IEntityMetadataModel>;
    onCodeChanged: (macro: ITypedMacro) => void;
    onPreviewDataRequest?: () => void;
    result: IDatasetResult | undefined;// DatasetResultModel
    // previewData: any | undefined
}
export default function ReportTemplateDevelopmentItemEditor({ tabValue, onCodeChanged, onPreviewDataRequest, reportTemplateRef, metadata, result }: IReportTemplateDevelopmentItemEditorProps) {
    const [{ value: id }] = useField(oProps<IReportTemplateDevelopmentItemModel>().path("id"));
    // const dictionary: MacroSymbolModel | undefined = React.useMemo(() => (result?.result?.type !== "CompilationErrorDatasetResultModel" && result?.result?.returnType)
    //     ? result?.result?.returnType : undefined, [result]);
    // const error: IExceptionCodeEditor | undefined = React.useMemo(() => result?.type === "ExecutionErrorDatasetResultModel" ? {
    //     startLineNumber: result.lineNumber ?? 1,
    //     message: result.message
    // } : undefined, [result]);
    switch (tabValue) {
        // case "loadMacro": return <FormMacroEditor key={`loadMacro${id}`}
        //     type={IMacroTypeModel.Dataset}
        //     onCodeChanged={onCodeChanged}
        //     // field={oProps<ReportTemplateDevelopmentItem>().path("onLoadMacro")}
        //     refToGetValue={reportLoadMacroRef}
        //     executing={false}
        //     diagnostics={diagnostics && diagnostics[IMacroTypeModel.Dataset]}
        //     metadata={metadata}
        //     error={error} />;
        case "template": return <ReportDesigner key={`template${id}`}
            onPreviewDataRequest={onPreviewDataRequest}
            dataResult={result?.result?.type === "SuccessDatasetResultModel" ? result.result : undefined}
            refToGetValue={reportTemplateRef} />;
    }
    return null;
}
