import { IFeatureModel, IMacroScriptSummaryModel } from "proxy/apiProxy";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanel from "tools/components/DetailPanel";
import { Card, CardContent, Grid, TextField, Typography } from "@material-ui/core";
import ExplorerElement from './ExplorerElement';
import FolderDownloadOutlineIcon from 'mdi-material-ui/FolderDownloadOutline'
import { fileToBase64 } from "tools/lib/utility";
import React, { useEffect } from "react";
import { groupElements } from "./groupElements";

export const dashboardDropFilesScreen = {
    route: "/dashboardsDropFiles",
    component: ActionDropFiles,
    label: "Action DropFiles"
}

function ActionDropFiles() {
    const { macroScripts = [], macroScriptRunning, macroScriptsLoadingAll } = useReduxSelections("dashboard");
    const { referenceMacroScriptCategories = {} } = useReduxSelections("reference");
    const {dashboardMacroScriptExecute, dashboardMacroLoadAll} = useReduxActions("dashboard");
    const isGranted = useGrants();

    useEffect(() => {
        dashboardMacroLoadAll()
    }, [dashboardMacroLoadAll]);

    const [filter, setFilter] = React.useState("");
    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value);

    const elements = React.useMemo(() => {
        const rets: IMacroScriptSummaryModel[] =
            (isGranted(IFeatureModel.ImportExportProcessMacroExecute) ? macroScripts.filter(i => i.type === "FileProcessorMacroScriptSummaryModel") : [])
                .filter(i => i.name.toLowerCase().includes(filter.toLowerCase()))
                .sort((a, b) => a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        return rets;
    }, [filter, isGranted, macroScripts]);

    const { groups, notGrouped } = React.useMemo(() => groupElements(elements, v => v.categoryId), [elements]);

    const handleFileOpen = async (file: File, id: number) => {
        const openedFile = await fileToBase64(file);
        dashboardMacroScriptExecute({
            file: {
                data: openedFile.content,
                mimeType: openedFile.mimeType,
                name: openedFile.fileName
            },
            type: "FileProcessorMacroScriptModel",
            id
        });
    };
    return <DetailPanel title="Imports" isQuerying={macroScriptsLoadingAll}>
        <TextField value={filter} onChange={handleChangeFilter} label="Filter" />



        {groups.map(({ categoryId, elements }) => <Card key={categoryId} style={{ marginTop: 8, marginBottom: 8 }} >
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    {referenceMacroScriptCategories[categoryId].name}
                </Typography>
                <Grid container={true} spacing={1}>
                    {elements.map(macro => <ImportPanel
                        key={macro.id}
                        onFileDrop={handleFileOpen}
                        macro={macro}
                        issuing={!!macroScriptRunning[macro.id]} />)}
                </Grid>
            </CardContent>
        </Card>)}
        <Grid container={true} spacing={1}>
            {notGrouped.map(macro => <ImportPanel
                key={macro.id}
                onFileDrop={handleFileOpen}
                macro={macro}
                issuing={!!macroScriptRunning[macro.id]} />)}
        </Grid>
    </DetailPanel>
}

interface IImportPanelProps {
    issuing: boolean;
    macro: IMacroScriptSummaryModel;
    onFileDrop: (file: File, id: number) => void;
}
function ImportPanel({ onFileDrop, macro: { id, name }, issuing }: IImportPanelProps) {
    // const { [macroScriptId]: macroScript } = macroScripts;
    const handleClick = (file: File) => onFileDrop(file, id);
    return <Grid item={true} lg={4} md={6} sm={12} alignItems="stretch" style={{ display: "flex" }}>
        <ExplorerElement icon={FolderDownloadOutlineIcon} name={name} onFileDrop={handleClick} processing={issuing} />
    </Grid>
}
