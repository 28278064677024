import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IPortfolioCompositionStressTestImpactSummaryModel } from "proxy/apiProxy";
import findColorBetween from "tools/lib/findColorBetween";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";
import { useEffect } from "react";

export const stressTestingScreen = {
	route: "/stresstesting",
	component: StressTestingSummary,
	label: "Stress Testing Summary"
}

function StressTestingSummary() {

	const { stressTestLastImpacts, stressTestLastImpactsLoading } = useReduxSelections("stressTest");
	const {stressTestLastImpactsLoad} = useReduxActions("stressTest");
	const {
		classificationTypes: securityClassificationTypes,
		classificationTypesFlat: securityClassificationTypesFlat
	} = useReduxSelections("securityClassificationType");

	const { referenceCurrencies = {} } = useReduxSelections("reference");

	useEffect(() => {
		stressTestLastImpactsLoad()
	}, [stressTestLastImpactsLoad]);

	const { classificationTypesColumns } = getClassificationTypesColumnDefinitions(securityClassificationTypes, securityClassificationTypesFlat, ({ portfolioId }: IPortfolioCompositionStressTestImpactSummaryModel) => stressTestLastImpacts.portfolios[portfolioId]?.classifications);

	const columns: IColumnDefinition[] = [
		{
			name: "Portfolio",
			title: "Portfolio",
			filteringEnabled: true,
			getCellValue: ({ portfolioId }: IPortfolioCompositionStressTestImpactSummaryModel) => stressTestLastImpacts.portfolios[portfolioId].name,
		},
		{
			name: "InternalCode",
			title: "Internal Code",
			filteringEnabled: true,
			getCellValue: ({ portfolioId }: IPortfolioCompositionStressTestImpactSummaryModel) => stressTestLastImpacts.portfolios[portfolioId].internalCode,
		},
		{
			name: "Sicav",
			title: "Sicav",
			filteringEnabled: true,
			getCellValue: ({ portfolioId }: IPortfolioCompositionStressTestImpactSummaryModel) => {
				const portfolio = stressTestLastImpacts.portfolios[portfolioId];
				const sicavId = portfolio.type === "SubFundSummaryModel" ? portfolio.sicavId : undefined;
				return sicavId == null ? "" : getEntityName(stressTestLastImpacts.entities[sicavId]) ?? "";
			},
		},
		...classificationTypesColumns,
		{
			name: "Ccy",
			title: "CCY",
			filteringEnabled: true,
			getCellValue: ({ portfolioId }: IPortfolioCompositionStressTestImpactSummaryModel) => referenceCurrencies[`${stressTestLastImpacts.portfolios[portfolioId].currencyId}`].isoCode
		},
		{
			name: "PortfolioCompositionDate",
			title: "Date",
			columnType: "date",
			filteringEnabled: true,
			getCellValue: ({ portfolioCompositionDate }: IPortfolioCompositionStressTestImpactSummaryModel) => portfolioCompositionDate,
		},
		{
			name: "Tna",
			title: "TNA",
			getCellValue: ({ tna }: IPortfolioCompositionStressTestImpactSummaryModel) => `${(tna / 1000000).toFixed(2).replace(/\./g, ',')} M`
		},
		{
			name: "ScenarioCode",
			title: "Scenario Code",
			filteringEnabled: true,
			getCellValue: ({ stressTestScenarioId }: IPortfolioCompositionStressTestImpactSummaryModel) => stressTestLastImpacts.stressTestScenarios[stressTestScenarioId].code
		},
		{
			name: "Scenario",
			title: "Scenario",
			filteringEnabled: true,
			getCellValue: ({ stressTestScenarioId }: IPortfolioCompositionStressTestImpactSummaryModel) => stressTestLastImpacts.stressTestScenarios[stressTestScenarioId].name.en
		},
		{
			name: "DateFrom",
			title: "From",
			getCellValue: ({ stressTestScenarioId }: IPortfolioCompositionStressTestImpactSummaryModel) => stressTestLastImpacts.stressTestScenarios[stressTestScenarioId].dateFrom,
			columnType: "date"
		},
		{
			name: "DateTo",
			title: "To",
			getCellValue: ({ stressTestScenarioId }: IPortfolioCompositionStressTestImpactSummaryModel) => stressTestLastImpacts.stressTestScenarios[stressTestScenarioId].dateTo,
			columnType: "date"
		},
		{
			name: "ImpactPercent",
			title: "Impact (%)",
			getCellValue: ({ impact }: IPortfolioCompositionStressTestImpactSummaryModel) => impact,
			columnType: "precisePercentage2",
			aggregationType: "max"
		},
		{
			name: "BenchmarkImpactPercent",
			title: "Benchmark Impact (%)",
			getCellValue: ({ benchmarkImpact }: IPortfolioCompositionStressTestImpactSummaryModel) => benchmarkImpact,
			columnType: "precisePercentage2",
			aggregationType: "max"
		},
		{
			name: "Impact",
			title: "Impact",
			getCellValue: (({ impact, tna }) => Math.round(impact * tna)),
			columnType: "decimal",
			aggregationType: "max"
		}
	];

	const state: IGridState = {

		"Portfolio": { width: 250 },
		"InternalCode": { width: 100 },
		"Sicav": { width: 100, hidden: true },
		"Ccy": { width: 80, hidden: true },
		"PortfolioCompositionDate": { width: 80 },
		"Tna": { width: 80 },
		"ScenarioCode": { width: 100 },
		"Scenario": { width: 250 },
		"DateFrom": { width: 80, hidden: true },
		"DateTo": { width: 80, hidden: true },
		"ImpactPercent": { width: 140 },
		"BenchmarkImpactPercent": { width: 140 },
		"Impact": { width: 140 },
	}
	securityClassificationTypes.forEach((classificationType) => state[classificationType.code] = { hidden: true });

	const getRowKey = (row: IPortfolioCompositionStressTestImpactSummaryModel) => `${row.portfolioId}-${row.impact}-${row.tna}`;
	const getCellBackground = (row: IPortfolioCompositionStressTestImpactSummaryModel, columnName: string) => {
		if (columnName === "ImpactPercent") {
			return findColorBetween('white', 'red', row.impact);
		}
		return 'white';
	};
	const getCellTooltip = (row: IPortfolioCompositionStressTestImpactSummaryModel, columnName: string) => {
		if (columnName === "Scenario") {
			return stressTestLastImpacts.stressTestScenarios[row.stressTestScenarioId].description.en;
		}
		return '';
	};

	return <SearchPanel title="Stress tests" isQuerying={stressTestLastImpactsLoading}>
		<ExtendedGrid
			getRowId={getRowKey}
			columns={columns}
			rows={stressTestLastImpacts.impacts}
			initialState={state}
			getCellBackground={getCellBackground}
			getCellTooltip={getCellTooltip}
			userCanGroup={true}
			defaultExportFileName={"stresstests.xlsx"}
		/>
	</SearchPanel>
}
