import { Box } from "@material-ui/core";
import PricingDatesNavigator from "components/global/PricingDatesNavigator";
import DetailPanel from "tools/components/DetailPanel";
import FieldBox from "tools/components/FieldBox";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import PortfoliosComplianceChecks from "features/TradeDate/PortfoliosComplianceChecks";
import { useCallback, useEffect } from "react";

export const portfoliosComplianceScreen = {
    route: "/portfoliocompliance",
    component: PortfolioCompliance,
    label: "Portfolio Compliance",
}

function PortfolioCompliance() {
    const { portfoliosComplianceChecking, portfoliosComplianceDates, portfoliosComplianceDatesLoading, portfoliosComplianceChecks = [], portfoliosComplianceCurrentDate } = useReduxSelections("portfoliosCompliance");
    const { portfoliosComplianceCheck, portfoliosComplianceDatesLoad } = useReduxActions("portfoliosCompliance");

    useEffect(() => {
        portfoliosComplianceDatesLoad()
    }, [portfoliosComplianceDatesLoad]);

    const handleRefresh = useCallback(() => {
        if (portfoliosComplianceCurrentDate) {
            portfoliosComplianceCheck(portfoliosComplianceCurrentDate);
        }
    }, [portfoliosComplianceCheck, portfoliosComplianceCurrentDate]);
    return <DetailPanel
        isQuerying={portfoliosComplianceChecking}
        onRefreshClick={handleRefresh}
        title="Portfolios Compliance">
        <Box display="flex" flexDirection="column" height="100%">
            <FieldBox display="flex" flexDirection="row" style={{ padding: 16 }}>
                <PricingDatesNavigator
                    onPricingDateChanged={portfoliosComplianceCheck}
                    pricingDatesLoading={portfoliosComplianceDatesLoading}
                    pricingDate={portfoliosComplianceCurrentDate}
                    pricingDates={portfoliosComplianceDates} />
                <div style={{ flexGrow: 1 }} />
            </FieldBox>
            <div style={{ flexGrow: 1, overflowY: "auto", height: "100%", padding: 16 }}>
                <PortfoliosComplianceChecks portfoliosChecks={portfoliosComplianceChecks} />
            </div>
        </Box>
    </DetailPanel>
}