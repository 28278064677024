import { ComponentType, useCallback, useEffect, useMemo, useState } from "react";
import { Survey } from "survey-react-ui";
import { setLicenseKey, SurveyModel } from "survey-core";
import 'survey-core/defaultV2.min.css';
import DialogPanel, { IActionButton } from "./DialogPanel";
import { CompleteEvent, CompletingEvent, ValueChangedEvent } from "survey-core/typings/survey-events-api";
import { IQuestionPlainData } from "survey-core/typings/question";

setLicenseKey("YjRhNWFkNWItNDMyMi00MGM0LTg3OGItYTZkZjc3MmRjMmYwOzE9MjAyNS0wNC0wMiwyPTIwMjUtMDQtMDI=");
// https://surveyjs.answerdesk.io/ticket/details/t6616/hide-default-complete-button
export interface SurveyBeforeCompleteProps {
    onConfirm: () => void;
    onCancel: () => void;
}

export type ISurveyResults = Array<IQuestionPlainData>;
export type SurveyRunnerResult = { data: any, plainData: ISurveyResults }
export type SurveyRunnerResultHandler = (result: SurveyRunnerResult) => void

export interface ISurveyRunnerDialogProps {
    onCancel: () => void;
    cancelLabel?: string;
    onReset?: () => void;
    resetLabel?: string;
    onOk?: SurveyRunnerResultHandler;
    okLabel?: string;
    onComplete?: SurveyRunnerResultHandler;
    completeLabel?: string;
    template: any;
    initialValues?: any;
    BeforeComplete?: ComponentType<SurveyBeforeCompleteProps>;
    readOnly?: boolean;
}

type OnCompleteHandler = (sender: SurveyModel, options: CompleteEvent) => any;
type OnValueChangedHandler = (sender: SurveyModel, options: ValueChangedEvent) => any;
type EventHandler<Sender, Options = any> = (sender: Sender, options: Options) => any

const extractResults = (model: SurveyModel) => {
    const plainData = model.getPlainData({
        includeQuestionTypes: true,
        includeValues: true,
        includeEmpty: true,
        calculations: [{ propertyName: "visible" }, { propertyName: "isRequired" }]
    });
    console.log("SurveyResults", model.data, plainData);
    return { data: model.data, plainData };
}

// TODO Keep in sync with FrontEnd SurveyRunnerDialog
export function SurveyRunnerDialog(
    {
        initialValues,
        template,
        onOk,
        okLabel,
        onComplete,
        completeLabel,
        onCancel,
        cancelLabel,
        onReset,
        resetLabel,
        BeforeComplete = undefined,
        readOnly = false
    }: ISurveyRunnerDialogProps) {

    const [canShowComplete, setCanShowComplete] = useState(false);
    const [preventComplete, setPreventComplete] = useState(false);

    const handleComplete = useCallback<OnCompleteHandler>((model, options) => {
        if (onComplete) {
            onComplete(extractResults(model));
        }
    }, [onComplete]);

    const handleValueChanged = useCallback<OnValueChangedHandler>((model) => {
        setCanShowComplete(() => model.calcIsCompleteButtonVisible());
    }, []);

    const handleCompleting = useCallback<EventHandler<SurveyModel, CompletingEvent>>((model, completing) => {
        if (!!BeforeComplete) {
            completing.allow = false;
            setPreventComplete(true);
        }
    }, [BeforeComplete]);

    const surveyModel = useMemo(() => {
        const modelTemplateString = typeof template === "string" ? template : JSON.stringify(template);
        const model = new SurveyModel(modelTemplateString);
        model.onComplete.add(handleComplete);
        model.onValueChanged.add(handleValueChanged);
        model.onCompleting.add(handleCompleting);
        // model.pages.map(p=>p.visible)
        // model.onPageVisibleChanged.add((m,e)=>{
        //     e.
        // })

        if (readOnly)
            model.mode = "display";
        return model
    }, [handleComplete, handleCompleting, handleValueChanged, readOnly, template]);

    const handleCompletingConfirm = useCallback(() => {
        surveyModel.onCompleting.remove(handleCompleting);
        setPreventComplete(false);
        surveyModel.doComplete();
    }, [handleCompleting, surveyModel]);

    const handleCompletingCancel = useCallback(() => {
        setPreventComplete(false);
    }, []);

    useEffect(() => {
        const handleValueChanged: EventHandler<SurveyModel, ValueChangedEvent> = (model) => {
            setCanShowComplete(() => model.calcIsCompleteButtonVisible());
        }
        surveyModel.onValueChanged.add(handleValueChanged);
        surveyModel.onCompleting.add(handleCompleting);
        surveyModel.onComplete.add(handleComplete);

        if (readOnly)
            surveyModel.mode = "display";
        return () => surveyModel.onValueChanged.remove(handleValueChanged);
    }, [handleComplete, handleCompleting, readOnly, surveyModel, surveyModel.onComplete, surveyModel.onCompleting, surveyModel.onValueChanged]);

    const actionButtons = useMemo(() => {
        const buttons: IActionButton[] = []
        if (onReset) {
            buttons.push({
                label: resetLabel ?? "Reset",
                onClick: () => {
                    onReset()
                },
                disabled: false
            });
        }
        if (onOk) {
            buttons.push({
                label: okLabel ?? "Save",
                onClick: () => {
                    onOk(extractResults(surveyModel))
                },
                disabled: false
            });
        }
        if (onComplete) {
            buttons.push({
                label: completeLabel ?? "Submit",
                onClick: () => {
                    surveyModel.doComplete()
                },
                disabled: !canShowComplete
            });
        }
        return buttons;
    }, [onReset, onOk, onComplete, resetLabel, okLabel, surveyModel, completeLabel, canShowComplete]);


    useEffect(() => {
        if (initialValues) {
            surveyModel.data = initialValues;
        }
    }, [surveyModel, initialValues]);

    return <DialogPanel
        onBackClick={onCancel}
        closeLabel={cancelLabel}
        maxWidth="lg"
        actions={actionButtons}>
        {!!BeforeComplete && preventComplete
            && <BeforeComplete onCancel={handleCompletingCancel} onConfirm={handleCompletingConfirm} />}
        <Survey model={surveyModel} />
    </DialogPanel>
}
