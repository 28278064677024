import * as React from "react";
// import { Editor, ControlledEditorOnChange, EditorDidMount, monaco as reactMonaco } from "@monaco-editor/react";
import Editor, { OnChange, OnMount } from "@monaco-editor/react";
import { INotControllableEditorProps } from "./INotControllableEditorProps";

// https://microsoft.github.io/monaco-editor/playground.html#interacting-with-the-editor-adding-an-action-to-an-editor-instance

export interface IMacroCodeEditorError {
    start: number;
    length: number;
    startLineNumber: number;
    startColumnNumber: number;
    endLineNumber: number;
    endColumnNumber: number;
    sourcePart: string;
    message: string;
}

export interface ICodeEditorProps extends INotControllableEditorProps<string> {
    codeErrors?: IMacroCodeEditorError[];
    readOnly?: boolean;
    jsonSchema?: object;
    onCodeChanged?: (v: string) => void;
    language: string;
};
type Monaco = typeof monaco;
export default function SimpleCodeEditor(props: ICodeEditorProps) {
    const {
        codeErrors,
        readOnly = false,
        jsonSchema,
        onCodeChanged,
        refToGetValue,
        language
        // onRequestAutoComplete
    } = props;
    const monacoRef = React.useRef<Monaco>();
    const modelRef = React.useRef<monaco.editor.ITextModel | null | undefined>();
    const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor | null | undefined>();
    const handleChange: OnChange = React.useCallback((newValue, ev) => onCodeChanged && onCodeChanged(newValue || ""), [onCodeChanged]);

    React.useEffect(() => setJsonSchema(jsonSchema, modelRef.current, monacoRef.current), [jsonSchema]);

    // macroScriptsApi.getAutoCompleteScriptAsync({ textModel: { position: 1, text: "qzert" }, type: IMacroScriptTypeModel.Monitoring });
    const setter = React.useCallback((v?: string) => {
        editorRef.current?.setValue(v ?? "");
        // setLanguage(v?.language ?? IMacroLanguageModel.CSharp);
    }, []);
    const getter = React.useCallback(() => editorRef.current?.getValue(), []);
    // useNotControllableEditor(props, setter, getter);

    const handleEditorMounted: OnMount = React.useCallback((editor: monaco.editor.IStandaloneCodeEditor, mnco: typeof monaco) => {
        monacoRef.current = mnco;
        editorRef.current = editor;
        refToGetValue.current.setEditor(setter, getter);
        if (!monacoRef.current) return;
        modelRef.current = editor.getModel();
        if (modelRef.current) {
            // modelRef.current.onDidChangeLanguage()
            monacoRef.current.editor.setModelLanguage(modelRef.current, language);
        }
        // modelRef.current.getLa
        if (codeErrors) {
            setErrors(codeErrors, modelRef.current, monacoRef.current);
        }
        if (jsonSchema) {
            setJsonSchema(jsonSchema, modelRef.current, monacoRef.current);
        }
    }, [refToGetValue, codeErrors, jsonSchema, getter, setter, language]);
    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => refToGetValue.current.unsetEditor();
    }, [refToGetValue]);
    return <div style={{ flexGrow:1, height:"100%" }}>
        <Editor
            options={{ readOnly }}
            height="calc(100%)" // TODO: not great... possible to find much better
            // language={getLanguageCode(language)}
            onChange={handleChange}
            onMount={handleEditorMounted} />
    </div>
}
function setJsonSchema(batchSchema: any, model: monaco.editor.ITextModel | null | undefined, mnco: Monaco | null | undefined) {
    if (!model || !batchSchema) {
        return;
    }
    if (!mnco) return;
    mnco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        allowComments: true,
        schemas: [{
            uri: "http://myserver/foo-schema.json", // id of the first schema
            fileMatch: [model.uri.toString()], // associate with our model
            schema: batchSchema
        }]
    });
}
function setErrors(scriptErrors: IMacroCodeEditorError[] | undefined = [], model: monaco.editor.ITextModel | null | undefined, mnco: Monaco | null | undefined) {
    if (!model) {
        return;
    }
    if (!mnco) return;
    mnco.editor.setModelMarkers(model, "MacroScriptEditor", scriptErrors.map(({ endColumnNumber, endLineNumber, startColumnNumber, startLineNumber, message }) => ({
        startLineNumber,
        startColumn: startColumnNumber,
        endLineNumber,
        endColumn: endColumnNumber,
        message,
        severity: mnco!.MarkerSeverity.Error,
    })));
}
