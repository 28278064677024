/**
 * Parses a date string in the format 'YYYYMMDD' and returns a Date object.
 *
 * @param {string} str - The date string to parse. Must be in 'YYYYMMDD' format.
 * @returns {Date | undefined} The Date object representing the parsed date, or undefined if the input is invalid.
 */
export function parseDate(str: string): Date | undefined {
    if (!/^\d{8}$/.test(str)) {
        return undefined;
    }
    const [y, m, d] = [str.slice(0, 4), str.slice(4, 6), str.slice(6, 8)].map(Number);

    const date = new Date(y, m - 1, d);
    if (isNaN(date.valueOf())) {
        return undefined;
    }
    return date;
}