import produce from "immer";
import { IProcessDefinitionSummaryModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";

export interface IState {
    processDefinitionLookupAllLoading: boolean;
    processDefinitionLookups: IProcessDefinitionSummaryModel[];
}
export const ActionFactories = produceActionFactories({
    processDefinitionLookupLoad: () => undefined,
    processDefinitionLookupLoaded: (payload: IProcessDefinitionSummaryModel[]) => payload
});

export function reducer(
    state: IState = {
        processDefinitionLookupAllLoading: false,
        processDefinitionLookups: [],
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "processDefinitionLookupLoad":
                draft.processDefinitionLookupAllLoading = true;
                break;
            case "processDefinitionLookupLoaded":
                draft.processDefinitionLookupAllLoading = false;
                draft.processDefinitionLookups = action.payload;
                break;
        }
    });
}
