import produce from "immer";
import { ITenancyModel, EntitySummaryModel, IGetTenancyModel, IGetTenanciesModel, SecuritySummaryModel, ITenanciesSearchParameters } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetEntitySummary, IGetSecuritySummary } from "features/Reference/slice";
export interface IState {
    tenancyAllLoading: boolean;
    tenancies: ITenancyModel[];
    tenancyLoading: boolean;
    tenancyCurrent?: ITenancyModel;
    tenancySaving: boolean;
    tenancyDeleting: boolean;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
    }
}
export const ActionFactories = produceActionFactories({
    tenancyLoad: (payload: number) => payload,
    tenancyLoaded: (payload: IGetTenancyModel) => payload,
    tenancySave: (payload: ITenancyModel) => payload,
    tenancySaved: (payload: ITenancyModel) => payload,
    tenancyDelete: (payload: number) => payload,
    tenancyDeleted: (payload: number) => payload,
    tenancyLoadAll: (payload: ITenanciesSearchParameters) => payload,
    tenancyLoadedAll: (payload: IGetTenanciesModel) => payload,
    tenancyAddEntityInDictionary: (payload: IGetEntitySummary) => payload,
    tenancyAddSecurityInDictionary: (payload: IGetSecuritySummary) => payload,
});

export function reducer(
    state: IState = {
        tenancyLoading: false,
        tenancyAllLoading: false,
        tenancySaving: false,
        tenancyDeleting: false,
        tenancies: [],
        dictionaries: {
            entities: {},
            securities: {}
        }
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "tenancyAddEntityInDictionary":
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                break;
            case "tenancyAddSecurityInDictionary":
                draft.dictionaries.securities[action.payload.security.id] = action.payload.security;
                break;
            case "tenancyLoadAll":
                draft.tenancyAllLoading = true;
                break;
            case "tenancyLoadedAll": {
                draft.tenancyAllLoading = false;
                const { tenancies, ...dictionaries } = action.payload;
                draft.tenancies = tenancies;
                draft.dictionaries = dictionaries;
                break;
            }
            case "tenancyLoad":
                draft.tenancyLoading = true;
                draft.tenancyCurrent = undefined;
                break;
            case "tenancyLoaded": {
                draft.tenancyLoading = false;
                const { tenancy, entities, securities } = action.payload;
                draft.tenancyCurrent = tenancy;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...entities };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...securities };
                break;
            }
            case "tenancySave":
                draft.tenancySaving = true;
                break;
            case "tenancySaved":
                draft.tenancySaving = false;
                const saved = action.payload;
                draft.tenancyCurrent = saved;
                const existing = draft.tenancies.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.tenancies.push(saved);
                }
                break;
            case "tenancyDelete":
                draft.tenancyDeleting = true;
                break;
            case "tenancyDeleted":
                draft.tenancyDeleting = false;
                const deletedId = action.payload;
                if (draft.tenancyCurrent?.id === deletedId) {
                    delete draft.tenancyCurrent;
                }
                const idx = draft.tenancies.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.tenancies.splice(idx, 1);
                }
                break;
        }
    });
}
