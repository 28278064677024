import FieldBox from "tools/components/FieldBox";
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps } from "tools/lib/utility";
import { IClassificationTypeModel, IProcessDefinitionModel } from "proxy/apiProxy";
import ClassificationsData from "components/global/ClassificationsData";

export interface IProcessDefinitionDataProps {
    classificationTypes: IClassificationTypeModel[];
}

export default function ProcessDefinitionData({ classificationTypes }: IProcessDefinitionDataProps) {
    return <FieldBox display="flex" flexDirection="column">
        <FormTextField name={oProps<IProcessDefinitionModel>().path("code")} label="Code" required={true} maxLength={100} />
        <FormMultiCulturedTextField name={oProps<IProcessDefinitionModel>().path("name")} label="Name" required={["en"]} />
        <FormMultiCulturedTextField name={oProps<IProcessDefinitionModel>().path("description")} label="Description" required={["en"]} />
        <ClassificationsData
            panelTitle="Classification"
            fieldName={oProps<IProcessDefinitionModel>().path("classifications")}
            classificationTypes={classificationTypes} />
    </FieldBox>
}
