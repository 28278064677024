import { Epic, ofType } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { movementClassificationTypesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadClassifications: Epic<IAnyAction>
    = action$ =>
        merge(
            action$.pipe(ofType("applicationLoaded"), map(i => null)),
            action$.pipe(ofType("movementClassificationTypeLoadAll"), map(i => null)),
            action$.pipe(ofType("movementClassificationTypeSaved"), map(i => null)),
            action$.pipe(ofType("referenceRefreshAll"), map(i => null))
        ).pipe(
            mergeMap(i => movementClassificationTypesApi.getAllAsync({})),
            map(ActionFactories.movementClassificationType.movementClassificationTypeAllLoaded));

export const loadClassification: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("movementClassificationType", "movementClassificationTypeLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => movementClassificationTypesApi.getAsync({ id }))),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({ id: 0, name: { en: "" }, code: "", classifications: [] })))
        ).pipe(
            map(ActionFactories.movementClassificationType.movementClassificationTypeLoaded));
    };
export const saveClassification: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("movementClassificationType", "movementClassificationTypeSave"),
        mergeMap(model => movementClassificationTypesApi.saveAsync({ model })),
        map(ActionFactories.movementClassificationType.movementClassificationTypeSaved));
export const deleteClassification: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("movementClassificationType", "movementClassificationTypeDelete"),
            mergeMap(id => movementClassificationTypesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.movementClassificationType.movementClassificationTypeDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
