import { DragObjectWithType } from "react-dnd";
import {
    ILevel1MenuItem,
    ILevel2MenuItem,
    IMenuDefinition,
    IMenuDefinitionNode,
    IMenuTarget
} from "features/App/menu/menuContracts";
import { TreeItem } from "react-sortable-tree";

export const dndMenuNodeType = "dndMenuNodeType";

export interface IDropResult {
}

export interface ICollectedProps {
    isDragging: boolean;
}


export interface IMyDragObjectWithType extends DragObjectWithType {
    node: MenuTreeItem;
}




export interface IMenuDefinitionTreeNodeLevel1 extends Omit<ILevel1MenuItem, "children"> {
    type: "level1";
    new?: boolean;
}

export interface IMenuDefinitionTreeNodeLevel2 extends Omit<ILevel2MenuItem, "children"> {
    type: "level2";
    new?: boolean;
}

export type IMenuDefinitionTreeNodeLevel3 = IMenuTarget & {
    new?: boolean;
}

export type TreeItemPayload = IMenuDefinitionTreeNodeLevel1 | IMenuDefinitionTreeNodeLevel2 | IMenuDefinitionTreeNodeLevel3;
export type ITreeItemModel = {
    model: TreeItemPayload;
}

export type MenuTreeItem = TreeItem<ITreeItemModel>

export function mapFromModelToTreeItem(menu: IMenuDefinition["menu"]): MenuTreeItem[] {
    return menu.map(child => mapLevel1toInternal(child))
}
function mapLevel1toInternal(menuNode: ILevel1MenuItem): MenuTreeItem {
    return {
        model: { ...menuNode, type: "level1" },
        expanded: true,
        children: menuNode.children.map(mapLevel2toInternal)
    }
}
function mapLevel2toInternal(menuNode: ILevel2MenuItem): MenuTreeItem {
    return {
        model: { ...menuNode, type: "level2" },
        expanded: true,
        children: menuNode.children.map(mapLeafToInternal)
    }
}
function mapLeafToInternal(node3: IMenuTarget): MenuTreeItem {
    return {
        model: { ...node3 },
        expanded: true,
    }
}


export function mapFromTreeItemToModel(treeNodes: MenuTreeItem[]) {
    return treeNodes.map(mapFromTreeItemToModelInternal) as IMenuDefinition["menu"]
}

export function mapFromTreeItemToModelInternal(treeNode: MenuTreeItem): IMenuDefinitionNode {
    const { model } = treeNode;
    switch (model.type) {
        case "level1": return {
            label: model.label,
            children: getChildren(treeNode.children).map(mapFromTreeItemToModelInternal)
        } as ILevel1MenuItem;
        case "level2": return {
            label: model.label,
            icon: model.icon,
            children: getChildren(treeNode.children).map(mapFromTreeItemToModelInternal)
        } as ILevel2MenuItem;
        default: return { ...model }
    }
}

function getChildren(children: MenuTreeItem["children"]): Array<MenuTreeItem> {
    if (!children) return []
    if (typeof children === "function") throw new Error("Children function not implemented")
    return children
}

export function isTargetValid(target: IMenuTarget) {
    switch (target.type) {
        case "Screen":
            return !target.params || Object.values(target.params).every(param => param)
        case "Dashboard":
            // case "Questionnaire":
            // case "MonitoringMacroCall":
            return true;//FIXME Implement
        case "Questionnaire":
            return true;//FIXME Implement
    }
}
