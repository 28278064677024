import { IFeatureModel, IReportTemplateSummaryModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import SearchPanel from "tools/components/SearchPanel";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IconButton, Tooltip } from "@material-ui/core";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useCallback, useEffect } from "react";

export const reportTemplatesScreen = {
    route: "/config/reporting/templates",
    component: ReportTemplates,
    label: "Report Templates",
    keywords: "Designer Stimulsoft",
}

function ReportTemplates() {
    const navigate = useScreenNavigate();
    const { referenceReportTemplateCategories = {} } = useReduxSelections("reference");
    const { reportTemplateList = [], reportTemplateListLoading } = useReduxSelections("reportTemplate");
    const { reportTemplateLoadAll } = useReduxActions("reportTemplate");

    useEffect(() => {
        reportTemplateLoadAll()
    }, [reportTemplateLoadAll]);

    const isGranted = useGrants();
    const columns: IColumnDefinition[] = [
        {
            name: "Code", title: "Code",
            getCellValue: ({ code }: IReportTemplateSummaryModel) => code, filteringEnabled: true
        },
        {
            name: "Name", title: "Name",
            getCellValue: ({ name }: IReportTemplateSummaryModel) => name, filteringEnabled: true
        },
        {
            name: "Category", title: "Category",
            getCellValue: ({ categoryId = 0 }: IReportTemplateSummaryModel) => referenceReportTemplateCategories[categoryId]?.name, filteringEnabled: true
        },
        {
            name: "Scope", title: "Scope",
            getCellValue: ({ singleScope }: IReportTemplateSummaryModel) => singleScope,
        },
        {
            name: "Version", title: "Version",
            getCellValue: ({ publishedVersion }: IReportTemplateSummaryModel) => publishedVersion,
            columnType: "integer"
        },
        {
            name: "PublishOnPortal",
            title: "Publish on portal",
            getCellValue: (row: IReportTemplateSummaryModel) => row.publishOnPortal,
            columnType: "boolean",
            positionToKeep: "left"
        },
        {
            name: "LastUpdate", title: "Last Update",
            getCellValue: ({ updatedOn }: IReportTemplateSummaryModel) => updatedOn,
            columnType: "dateTime"
        }
    ];
    const state: IGridState = {
        "Code": { width: 180 },
        "Name": { width: 200 },
        "Category": { width: 200 },
        "Version": { width: 120 },
        "LastUpdate": { width: 180 },
        "Scope": { width: 180 }
    }
    const getRowKey = (row: IReportTemplateSummaryModel) => row.id || 0;

    const handleAddNewClick = useCallback(() => navigate("Template", { id: 0 }), [navigate])
    const handleRowDuplicate = useCallback(({ id = 0 }: IReportTemplateSummaryModel) => navigate("Template", { id: `duplicate${id}` }), [navigate])
    const handleRowEdit = useCallback(({ id }: IReportTemplateSummaryModel) => navigate("Template", { id }), [navigate])

    if (isGranted(IFeatureModel.ReportTemplateWrite)) {
        columns.unshift({
            name: "Duplicate", title: "Duplicate",
            getCellValue: (id: IReportTemplateSummaryModel) => <Tooltip title="duplicate"><IconButton size="small" onClick={handleRowDuplicate.bind(null, id)}>
                <ControlPointDuplicateIcon fontSize="small" />
            </IconButton></Tooltip>,
            positionToKeep: "left"
        });
        state["Duplicate"] = {
            width: 80
        };
    }
    return (<SearchPanel
        title="Report Templates"
        addAllowed={IFeatureModel.ReportTemplateWrite}
        isQuerying={reportTemplateListLoading}
        onAddClick={handleAddNewClick}>
        <ExtendedGrid
            rows={reportTemplateList}
            columns={columns}
            getRowId={getRowKey}
            onRowClick={handleRowEdit}
            initialState={state}
            userCanGroup={true} />
    </SearchPanel>);
}
