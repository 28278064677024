import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";
import { IFeatureModel, ISubFundModel, ISubFundSummaryModel } from "proxy/apiProxy";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";

export const subFundsScreen = {
    route: "/securitymanagement/subfunds",
    component: SubFunds,
    label: "Sub-funds",
    keywords: "subfund",
}

function SubFunds() {
    const {
        all: portfolios = [],
        dictionaries: { entities },
        allLoading: portfolioAllLoading
    } = useReduxSelections("subFund");
    const { subfundLoadAll } = useReduxActions("subFund")
    const navigate = useScreenNavigate()

    useEffect(() => {
        subfundLoadAll({})
    }, [subfundLoadAll]);

    const columns: IColumnDefinition[] = [
        { name: "Name", title: "Name", getCellValue: ({ name = "" }: ISubFundModel | ISubFundSummaryModel) => name, filteringEnabled: true },
        { name: "InternalCode", title: "Internal Code", getCellValue: ({ internalCode = "" }: ISubFundModel | ISubFundSummaryModel) => internalCode, filteringEnabled: true },
        { name: "Sicav", title: "Fund/Sicav", getCellValue: ({ sicavId }: ISubFundModel | ISubFundSummaryModel) => sicavId && getEntityName(entities[sicavId]), filteringEnabled: true },
        { name: "UnderManagement", title: "Under Management", getCellValue: (sf: ISubFundModel | ISubFundSummaryModel) => sf.isUnderManagement, columnType: "boolean", filteringEnabled: true }
    ];
    const state: IGridState = {
        "Name": { width: 240 },
        "InternalCode": { width: 180 },
        "Sicav": { width: 240 },
        "UnderManagement": { width: 180 }
    }
    const getRowKey = (row: ISubFundModel | ISubFundSummaryModel) => row.id || 0;
    const handleRowClick = (row: ISubFundModel | ISubFundSummaryModel) => {
        //TODO Do we want? row.isUnderManagement => screenKey: "MyPortfolios"
        navigate("SubFund", { id: row.id });
    }

    const handleAddClick = () => navigate("SubFund", { id: 0 });

    return <SearchPanel title={"Sub-funds"} isQuerying={portfolioAllLoading}
        addAllowed={IFeatureModel.UniverseWrite}
        onAddClick={handleAddClick}>
        <ExtendedGrid
            rows={portfolios}
            columns={columns}
            getRowId={getRowKey}
            onRowClick={handleRowClick}
            initialState={state}
            userCanGroup={true} />
    </SearchPanel>
}
