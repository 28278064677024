import { Formik, FormikHelpers, FormikProps } from "formik";
import { ICurrencyModel, IOperationTypeModel, ITradeBookFxTradeModel, ITradeBookSecurityTradeModel, PortfolioSummaryModel, SecuritySummaryModel, TradeBookTradeModel } from "proxy/apiProxy";
import DialogPanel from "tools/components/DialogPanel";
import TradeDetails from "./TradeDetails";
import { IPosition } from "./slice";
import { IGetPortfolioSummary, IGetSecuritySummary } from "features/Reference/slice";

interface ITradeDetailEditDialogProps {
    tradeBookTrade?: TradeBookTradeModel;
    onSubmit: (tradeBookTrade: TradeBookTradeModel) => void;
    onCancel: () => void;

    dictionaries: {
        portfolios: Record<string | number, PortfolioSummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
    };
    referenceCurrencies: Record<string | number, ICurrencyModel>;
    positions: IPosition[];
    onSecurityLoaded?: (security: IGetSecuritySummary) => void;
    onPortfolioLoaded?: (found: IGetPortfolioSummary) => void;
}
export function TradeDetailEditDialog({ tradeBookTrade, onCancel, onSubmit, dictionaries, referenceCurrencies, positions, onSecurityLoaded, onPortfolioLoaded }: ITradeDetailEditDialogProps) {
    const handleSubmit = (values: TradeBookTradeModel, { setSubmitting }: FormikHelpers<TradeBookTradeModel>) => {
        onSubmit(values);
        setSubmitting(false);
    };
    if (!tradeBookTrade) {
        return null;
    }
    return <Formik onSubmit={handleSubmit} initialValues={tradeBookTrade} enableReinitialize={true} validateOnMount={true} validate={validateTradeBookTradeModel}>{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<TradeBookTradeModel>) {
        return <DialogPanel
            onBackClick={onCancel}
            title="Edit Trade"
            isOpened={!!tradeBookTrade}
            actions={[{
                disabled: !isValid,
                label: "Ok",
                onClick: submitForm
            }]}>
            <TradeDetails
                dictionaries={dictionaries}
                referenceCurrencies={referenceCurrencies}
                positions={positions}
                onSecurityLoaded={onSecurityLoaded}
                onPortfolioLoaded={onPortfolioLoaded}
                trade={values}
                fullMode={true} />
        </DialogPanel>;
    }
}
function validateTradeBookTradeModel(trade: TradeBookTradeModel) {
    switch (trade.type) {
        case "TradeBookSecurityTradeModel": {
            const { buySell, amountInPtfCcy, quantity, securityId, portfolioId } = trade;
            const errors: {
                [p in keyof ITradeBookSecurityTradeModel]?: string;
            } = {};
            if (buySell !== IOperationTypeModel.TotalSell) {
                if (typeof amountInPtfCcy !== "number" && typeof quantity !== "number") {
                    errors.amountInPtfCcy = errors.quantity = "Either amount or quantity must be specified";
                }
                else if (typeof amountInPtfCcy === "number" && typeof quantity === "number") {
                    errors.amountInPtfCcy = errors.quantity = "Amount and quantity can't be specified together";
                }
            }
            if (!securityId) {
                errors.securityId = "Required";
            }
            if (!buySell) {
                errors.buySell = "Required";
            }
            if (!portfolioId) {
                errors.portfolioId = "Required";
            }
            return Object.values(errors).filter(i => !!i).length ? errors : undefined;
        }
        case "TradeBookFxTradeModel": {
            const { soldCurrencyId, soldAmount, purchasedCurrencyId, purchasedAmount, portfolioId } = trade;
            const errors: {
                [p in keyof ITradeBookFxTradeModel]?: string;
            } = {};
            if (!soldCurrencyId) {
                errors.soldCurrencyId = "Required";
            }
            if (!purchasedCurrencyId) {
                errors.purchasedCurrencyId = "Required";
            }
            if (typeof soldAmount !== "number" && typeof purchasedAmount !== "number") {
                errors.soldAmount = "Sold or purchase amount is required";
                errors.purchasedAmount = "Sold or purchase amount is required";
            }
            if (!portfolioId) {
                errors.portfolioId = "Required";
            }
            return Object.values(errors).filter(i => !!i).length ? errors : undefined;
        }
    }
}
