import { Formik, FormikHelpers, FormikProps } from 'formik';
import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { ClassificationTypeData } from "./ClassificationTypeData";
import ClassificationsData from "./ClassificationsData";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ClassificationTypeDomain, getClassificationTypeDomainLabel } from "./ClassificationTypeDomain";
import { getRelatedGrants } from "./getRelatedGrants";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { useEffect, useMemo } from "react";
import { IEditableClassificationType, ITreeItem } from "./treeTypes";
import { IClassificationWithChildrenModel } from "proxy/apiProxy";
import {
    useScreenParams,
} from "tools/routing/screenRouteHooks";
import { useNumber, isNumber } from "tools/lib/utility";


const classificationTypeTabs = {
    detail: "details" as const,
    classifications: "classifications" as const,
}

export const securityClassificationTypeScreen = {
    domain: ClassificationTypeDomain.Security,
    useSelections: () => useReduxSelections("securityClassificationType"),
    useActions: () => {
        const {
            securityClassificationTypeSave: classificationTypeSave,
            securityClassificationTypeDelete: classificationTypeDelete,
            securityClassificationTypeLoad: classificationTypeLoad,
        } = useReduxActions("securityClassificationType")
        return { classificationTypeSave, classificationTypeDelete, classificationTypeLoad }
    },
    parentListScreenKey: "SecurityClassificationTypes" as const,
    route: "/config/lists/securityclassificationtypes/:id/:tab?" as const,
    label: "Security Classification Type",
    component: SecurityClassificationType,
    tabs: classificationTypeTabs,
}

function SecurityClassificationType() {
    return <ClassificationType screen={securityClassificationTypeScreen} />
}
export const portfolioTransactionClassificationTypeScreen = {
    domain: ClassificationTypeDomain.PortfolioTransaction,
    useSelections: () => useReduxSelections("portfolioTransactionClassificationType"),
    useActions: () => {
        const {
            portfolioTransactionClassificationTypeSave: classificationTypeSave,
            portfolioTransactionClassificationTypeDelete: classificationTypeDelete,
            portfolioTransactionClassificationTypeLoad: classificationTypeLoad,
        } = useReduxActions("portfolioTransactionClassificationType")
        return { classificationTypeSave, classificationTypeDelete, classificationTypeLoad }
    },
    parentListScreenKey: "PortfolioTransactionClassificationTypes" as const,
    route: "/portfolioTransaction/classificationtypes/:id/:tab?" as const,
    label: "Portfolio Transaction Classification Type",
    component: PortfolioTransactionClassificationType,
    tabs: classificationTypeTabs,
}

function PortfolioTransactionClassificationType() {
    return <ClassificationType screen={portfolioTransactionClassificationTypeScreen} />
}

export const investorClassificationTypeScreen = {
    domain: ClassificationTypeDomain.Investor,
    useSelections: () => useReduxSelections("investorClassificationType"),
    useActions: () => {
        const {
            investorClassificationTypeSave: classificationTypeSave,
            investorClassificationTypeDelete: classificationTypeDelete,
            investorClassificationTypeLoad: classificationTypeLoad,
        } = useReduxActions("investorClassificationType")
        return { classificationTypeSave, classificationTypeDelete, classificationTypeLoad }
    },
    parentListScreenKey: "InvestorClassificationTypes" as const,
    route: "/config/lists/investorclassificationtypes/:id/:tab?" as const,
    label: "Investor Classification Type",
    component: InvestorClassificationType,
    tabs: classificationTypeTabs,
}

function InvestorClassificationType() {
    return <ClassificationType screen={investorClassificationTypeScreen} />
}

export const roleRelationshipClassificationTypeScreen = {
    domain: ClassificationTypeDomain.RoleRelationship,
    useSelections: () => useReduxSelections("roleRelationshipClassificationType"),
    useActions: () => {
        const {
            roleRelationshipClassificationTypeSave: classificationTypeSave,
            roleRelationshipClassificationTypeDelete: classificationTypeDelete,
            roleRelationshipClassificationTypeLoad: classificationTypeLoad,
        } = useReduxActions("roleRelationshipClassificationType");
        return { classificationTypeSave, classificationTypeDelete, classificationTypeLoad }
    },
    parentListScreenKey: "RoleRelationshipClassificationTypes" as const,
    route: "/config/lists/roleclassificationtypes/:id/:tab?" as const,
    label: "Role Relationship Classification Type",
    component: RoleRelationshipClassificationType,
    tabs: classificationTypeTabs,
}

function RoleRelationshipClassificationType() {
    return <ClassificationType screen={roleRelationshipClassificationTypeScreen} />
}

export const movementClassificationTypeScreen = {
    domain: ClassificationTypeDomain.Movement,
    useSelections: () => useReduxSelections("movementClassificationType"),
    useActions: () => {
        const {
            movementClassificationTypeSave: classificationTypeSave,
            movementClassificationTypeDelete: classificationTypeDelete,
            movementClassificationTypeLoad: classificationTypeLoad,
        } = useReduxActions("movementClassificationType");
        return { classificationTypeSave, classificationTypeDelete, classificationTypeLoad }
    },
    parentListScreenKey: "MovementClassificationTypes" as const,
    route: "/config/lists/movementclassificationtypes/:id/:tab?" as const,
    label: "Movement Classification Type",
    component: MovementClassificationType,
    tabs: classificationTypeTabs,
}

function MovementClassificationType() {
    return <ClassificationType screen={movementClassificationTypeScreen} />
}

export const entityClassificationTypeScreen = {
    domain: ClassificationTypeDomain.Entity,
    useSelections: () => useReduxSelections("entityClassificationType"),
    useActions: () => {
        const {
            entityClassificationTypeSave: classificationTypeSave,
            entityClassificationTypeDelete: classificationTypeDelete,
            entityClassificationTypeLoad: classificationTypeLoad,
        } = useReduxActions("entityClassificationType");
        return { classificationTypeSave, classificationTypeDelete, classificationTypeLoad }
    },
    parentListScreenKey: "EntityClassificationTypes" as const,
    route: "/config/lists/entityclassificationtypes/:id/:tab?" as const,
    label: "Entity Classification Type",
    component: EntityClassificationType,
    tabs: classificationTypeTabs,
}

function EntityClassificationType() {
    return <ClassificationType screen={entityClassificationTypeScreen} />
}

export const noteClassificationTypeScreen = {
    domain: ClassificationTypeDomain.Note,
    useSelections: () => useReduxSelections("noteClassificationType"),
    useActions: () => {
        const {
            noteClassificationTypeSave: classificationTypeSave,
            noteClassificationTypeDelete: classificationTypeDelete,
            noteClassificationTypeLoad: classificationTypeLoad,
        } = useReduxActions("noteClassificationType");
        return { classificationTypeSave, classificationTypeDelete, classificationTypeLoad }
    },
    parentListScreenKey: "NoteClassificationTypes" as const,
    route: "/config/lists/noteclassificationtypes/:id/:tab?" as const,
    label: "Note Classification Type",
    component: NoteClassificationType,
    tabs: classificationTypeTabs,
}

function NoteClassificationType() {
    return <ClassificationType screen={noteClassificationTypeScreen} />
}

export const processClassificationTypeScreen = {
    domain: ClassificationTypeDomain.Process,
    useSelections: () => useReduxSelections("processClassificationType"),
    useActions: () => {
        const {
            processClassificationTypeSave: classificationTypeSave,
            processClassificationTypeDelete: classificationTypeDelete,
            processClassificationTypeLoad: classificationTypeLoad,
        } = useReduxActions("processClassificationType");
        return { classificationTypeSave, classificationTypeDelete, classificationTypeLoad }
    },
    parentListScreenKey: "ProcessDefinitionClassificationType" as const,
    route: "/config/processes/classificationtypes/:id/:tab?" as const,
    label: "Process Classification Type",
    component: ProcessClassificationType,
    tabs: classificationTypeTabs,
}

function ProcessClassificationType() {
    return <ClassificationType screen={processClassificationTypeScreen} />
}

export const developmentItemClassificationTypeScreen = {
    domain: ClassificationTypeDomain.DevelopmentItem,
    useSelections: () => useReduxSelections("developmentItemClassificationType"),
    useActions: () => {
        const {
            developmentItemClassificationTypeSave: classificationTypeSave,
            developmentItemClassificationTypeDelete: classificationTypeDelete,
            developmentItemClassificationTypeLoad: classificationTypeLoad,
        } = useReduxActions("developmentItemClassificationType");
        return { classificationTypeSave, classificationTypeDelete, classificationTypeLoad }
    },
    parentListScreenKey: "DevelopmentItemClassificationTypes" as const,
    route: "/config/lists/developmentitemclassificationtypes/:id/:tab?" as const,
    label: "Development Item Classification Type",
    component: DevelopmentItemClassificationType,
    tabs: classificationTypeTabs,
}

function DevelopmentItemClassificationType() {
    return <ClassificationType screen={developmentItemClassificationTypeScreen} />
}

export const documentClassificationTypeScreen = {
    domain: ClassificationTypeDomain.Document,
    useSelections: () => useReduxSelections("documentClassificationType"),
    useActions: () => {
        const {
            documentClassificationTypeSave: classificationTypeSave,
            documentClassificationTypeDelete: classificationTypeDelete,
            documentClassificationTypeLoad: classificationTypeLoad,
        } = useReduxActions("documentClassificationType");
        return { classificationTypeSave, classificationTypeDelete, classificationTypeLoad }
    },
    parentListScreenKey: "DocumentClassificationTypes" as const,
    route: "/config/lists/documentclassificationtypes/:id" as const,
    label: "Document Classification Type",
    component: DocumentClassificationType,
    tabs: classificationTypeTabs,
}

function DocumentClassificationType() {
    return <ClassificationType screen={documentClassificationTypeScreen} />
}

type ClassificationTypeScreen =
    typeof securityClassificationTypeScreen
    | typeof investorClassificationTypeScreen
    | typeof roleRelationshipClassificationTypeScreen
    | typeof movementClassificationTypeScreen
    | typeof entityClassificationTypeScreen
    | typeof noteClassificationTypeScreen
    | typeof processClassificationTypeScreen
    | typeof developmentItemClassificationTypeScreen
    | typeof documentClassificationTypeScreen
    | typeof portfolioTransactionClassificationTypeScreen;

function ClassificationType({ screen }: { screen: ClassificationTypeScreen }) {
    const {
        classificationTypeLoading,
        classificationTypeSaving,
        classificationType,
    } = screen.useSelections();
    const {
        classificationTypeSave,
        classificationTypeDelete,
        classificationTypeLoad,
    } = screen.useActions()

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof screen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (isNumber(idNum)) {
            classificationTypeLoad(idNum)
        }
    }, [classificationTypeLoad, idNum]);


    const editableClassificationType: IEditableClassificationType | undefined = useMemo(() => {
        if (!classificationType) {
            return;
        }
        const { classifications, ...data } = classificationType;
        return {
            ...data,
            classifications: classifications.map(mapFromModelToTreeItem)
        };
    }, [classificationType]);

    if (!editableClassificationType) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={false} />;
    }
    const relatedGrants = getRelatedGrants(screen.domain);

    const tabs: ISubMenuTabs<ClassificationTypeScreen>[] | undefined = [{
        label: "Data",
        value: "detail"
    }, {
        label: "Classifications",
        value: "classifications"
    }];

    const handleDeleteType = () => {
        if (classificationType && classificationType.id) {
            classificationTypeDelete(classificationType.id);
        }
    }
    const handleSubmit = (values: IEditableClassificationType, { setSubmitting }: FormikHelpers<IEditableClassificationType>) => {
        const { classifications, ...data } = values;
        const newModel = {
            ...data,
            classifications: classifications.map(mapFromTreeItemToModel)
        };
        classificationTypeSave(newModel);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={editableClassificationType} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>

    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<IEditableClassificationType>) {
        return <WriteAccess value={relatedGrants}><DetailPanel
            isQuerying={classificationTypeLoading || classificationTypeSaving}
            tabs={tabs}
            tabValue={tabValue}
            badge={!values.id ? "new" : undefined}
            title={`${getClassificationTypeDomainLabel(screen.domain)} Classification Type`}
            canSave={dirty && isValid}
            saveAllowed={relatedGrants}
            deleteAllowed={relatedGrants}
            onSaveClick={submitForm}
            canDelete={!!values.id}
            onDeleteClick={handleDeleteType}>
            {(tabValue === "detail") && <ClassificationTypeData />}
            {(tabValue === "classifications") && <ClassificationsData />}
        </DetailPanel></WriteAccess>
    }
}

function mapFromModelToTreeItem({ classifications, ...model }: IClassificationWithChildrenModel): ITreeItem {
    const { code, name } = model;
    return { title: code, subtitle: name ? name["en"] : "-", children: classifications.map(mapFromModelToTreeItem), model };
}
function mapFromTreeItemToModel({ children, expanded, ...item }: ITreeItem): IClassificationWithChildrenModel {
    return { ...item.model, classifications: ((children ?? []) as ITreeItem[]).map(mapFromTreeItemToModel) };
}
