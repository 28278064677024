import produce from "immer";
import { IClassificationTypeModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import getFlatClassificationTypes, { IFlatClassificationTypes } from "./getFlatClassificationTypes";

export interface IState {
    classificationTypeLoading: boolean;
    classificationTypeSaving: boolean;
    classificationTypesLoading: boolean;
    classificationTypes: IClassificationTypeModel[];
    classificationType?: IClassificationTypeModel;
    classificationTypesFlat: IFlatClassificationTypes;
}

export const ActionFactories = produceActionFactories({
    documentClassificationTypeLoadAll: () => undefined,
    documentClassificationTypeAllLoaded: (payload: IClassificationTypeModel[]) => payload,
    documentClassificationTypeLoad: (payload: number) => payload,
    documentClassificationTypeLoaded: (payload: IClassificationTypeModel) => payload,
    documentClassificationTypeSave: (payload: IClassificationTypeModel) => payload,
    documentClassificationTypeSaved: (payload: IClassificationTypeModel) => payload,
    documentClassificationTypeDelete: (payload: number) => payload,
    documentClassificationTypeDeleted: (payload: number) => payload,
});

export function reducer(
    state: IState = {
        classificationTypesLoading: false,
        classificationTypeLoading: false,
        classificationTypeSaving: false,
        classificationTypes: [],
        classificationTypesFlat: {}
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "documentClassificationTypeSave":
                draft.classificationTypeSaving = true;
                break;
            case "documentClassificationTypeLoadAll":
                draft.classificationTypesLoading = true;
                break;
            case "documentClassificationTypeLoad":
                draft.classificationTypeLoading = true;
                draft.classificationType = undefined;
                break;
            case "documentClassificationTypeAllLoaded":
                draft.classificationTypesLoading = false;
                draft.classificationTypes = action.payload;
                draft.classificationTypesFlat = getFlatClassificationTypes(action.payload);
                break;
            case "documentClassificationTypeLoaded":
                draft.classificationTypeLoading = false;
                draft.classificationType = action.payload;
                break;
            case "documentClassificationTypeSaved":
                const saved = action.payload;
                draft.classificationTypeSaving = false;
                draft.classificationType = saved;
                break;
            case "documentClassificationTypeDeleted":
                const deletedId = action.payload;
                const idx = draft.classificationTypes.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.classificationTypes.splice(idx, 1);
                }
                break;
        }
    });
}
