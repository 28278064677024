import * as React from "react";
import { IMacroScriptSummaryModel, IMonitoringMacroCallSummaryModel, IMacroScriptCategoryModel, IFeatureModel } from "proxy/apiProxy";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanel from "tools/components/DetailPanel";
import { Grid, Card, CardContent, Typography, TextField } from "@material-ui/core";
import NotGranted from 'components/global/NotGranted';
import ExplorerElement from './ExplorerElement';
import FileCertificateOutlineIcon from 'mdi-material-ui/FileCertificateOutline'
import { groupElements } from "./groupElements";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";

export const dashboardsScreen = {
    route: "/dashboardsResult",
    component: Dashboards,
    label: "Dashboards"
}

function Dashboards() {
    const { dashboards, dictionaries, dashboardResultLoadingAll } = useReduxSelections("dashboard");
    const { dashboardMonitoringLoadAll } = useReduxActions("dashboard");
    const { referenceMacroScriptCategories = {} } = useReduxSelections("reference");

    useEffect(() => {
        dashboardMonitoringLoadAll()
    }, [dashboardMonitoringLoadAll]);


    const navigate = useScreenNavigate();
    const isGranted = useGrants();
    const [filter, setFilter] = React.useState("");
    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value);
    const { groups, notGrouped } = React.useMemo(() => groupElements(dashboards
        .filter(i => i.name.toLowerCase().includes(filter.toLowerCase()))
        , v => dictionaries.macroScripts[v.macroScriptId]?.categoryId), [dashboards, dictionaries.macroScripts, filter]);
    const handleSelectDashboard = React.useCallback((id: number) => {
        navigate("Dashboard", { id });
    }, [navigate]);
    if (!isGranted(IFeatureModel.MacroExecutionSetupAndDashboardExecute)) {
        return <NotGranted />;
    }
    if (!dashboards?.length) {
        return <Typography>No dashboard</Typography>
    }
    return <DetailPanel title="Dashboards" isQuerying={dashboardResultLoadingAll}>
        <TextField value={filter} onChange={handleChangeFilter} label="Filter" />
        {groups.map(({ categoryId, elements }) => <Card key={categoryId} style={{ marginTop: 8, marginBottom: 8 }} >
            <CardContent>
                <Typography gutterBottom={true} variant="h5" component="h2">
                    {referenceMacroScriptCategories[categoryId].name}
                </Typography>
                <Grid container={true} spacing={1}>
                    {elements.map(dashboard => <DashboardPanel
                        key={dashboard.id}
                        onClick={handleSelectDashboard}
                        dictionaries={dictionaries}
                        macroCall={dashboard}
                        macroScriptCategories={referenceMacroScriptCategories} />)}
                </Grid>
            </CardContent>
        </Card>)}

        <Grid container={true} spacing={1}>
            {notGrouped.map(dashboard => <DashboardPanel
                key={dashboard.id}
                onClick={handleSelectDashboard}
                dictionaries={dictionaries}
                macroCall={dashboard}
                macroScriptCategories={referenceMacroScriptCategories} />)}
        </Grid>
    </DetailPanel>
}
interface IDashboardPanelProps {
    macroCall: IMonitoringMacroCallSummaryModel;
    dictionaries: {
        macroScripts: Record<number, IMacroScriptSummaryModel>;
    };
    macroScriptCategories: Record<number | string, IMacroScriptCategoryModel>;
    onClick: (macroCallId: number) => void;
}

function DashboardPanel({ onClick, macroCall, dictionaries, macroScriptCategories }: IDashboardPanelProps) {
    const { id, name } = macroCall;
    const macroScriptId = macroCall.macroScriptId;
    const macroScriptCategory = macroScriptId ? macroScriptCategories[dictionaries.macroScripts[macroScriptId].categoryId ?? 0] : undefined;
    // const { [macroScriptId]: macroScript } = macroScripts;
    const handleClick = React.useCallback(() => onClick(id), [id, onClick]);
    return <Grid item={true} lg={4} md={6} sm={12} alignItems="stretch" style={{ display: "flex" }}>
        <ExplorerElement icon={FileCertificateOutlineIcon} name={name} subName={macroScriptCategory?.name} onClick={handleClick} />
    </Grid>
}
