import { useFieldEx, IFormField, ValidatorParams } from 'tools/lib/fieldHelpers';
import DropZone from "tools/components/DropZone";
import { useField } from 'formik';
import { fileToBase64, IBase64File } from 'tools/lib/utility';
import { ReadOnlyContext } from "./ReadOnlyContext";

const validators = {
    required: (v: string | undefined, isTrue?: boolean) => isTrue && (v ?? "").length === 0 ? `Required` : undefined,
    maxLength: (v: string | undefined, max?: number) => undefined,
    minLength: (v: string | undefined, min?: number) => undefined,
}
export interface IFormDropZoneProps extends IFormField, ValidatorParams<typeof validators> {
    onClick?: () => void;
    contentName: string;
    dateTime?: string;
    emptyValue?: undefined | null | IBase64File
}
export default function FormDropZoneField({ name, helperText, label, disabled, error, onClick, contentName, emptyValue, minLength, dateTime: dateTimeField, ...requestedValidations }: IFormDropZoneProps) {
    const { helperText: fieldHelperText, label: fieldLabel, onValueChanged, value } = useFieldEx<string, typeof validators>({ name, helperText, label, required: requestedValidations.required, error, validators, requestedValidations });
    const [{ value: dateTime }] = useField<Date | undefined>(dateTimeField ?? "_");
    const [, , { setValue: setFileValue }] = useField<IBase64File | null | undefined>(contentName);
    const handleFileOpen = async (file: File) => {
        setFileValue(await fileToBase64(file));
        onValueChanged(file.name);
    }
    const handleFileClear = () => {
        setFileValue(emptyValue);
        onValueChanged(undefined);
    }
    return <ReadOnlyContext.Consumer>
        {readOnly =>
            <DropZone
                emptyLabel={fieldHelperText ?? fieldLabel ?? undefined}
                disabled={disabled || readOnly}
                fileName={value}
                dateTime={dateTime}
                onClick={onClick}
                onFileOpen={handleFileOpen}
                onClear={handleFileClear}
                minSize={minLength}
            />}
    </ReadOnlyContext.Consumer>
}
