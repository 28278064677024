import { IStatisticDefinitionSetModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import { useField } from "formik";
import CodeEditor from "tools/components/CodeEditor";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";


// https://developers.google.com/fonts/docs/getting_started
export interface IStatisticDefinitionSetDefinitionProps {
    jsonSchema: object | undefined;
    onSaveRequest: () => void;
};

export default function StatisticDefinitionSetDefinition({ jsonSchema, onSaveRequest }: IStatisticDefinitionSetDefinitionProps) {
    // const modelUri= monaco.Uri.parse("a://b/foo.json")
    const [, { value: script }, { setValue: setDefinitions }] = useField<IStatisticDefinitionSetModel["definitions"]>(oProps<IStatisticDefinitionSetModel>().path("definitions"));
    return <ReadOnlyContext.Consumer>
        {readOnly => <CodeEditor
            readOnly={readOnly}
            code={script}
            onSave={onSaveRequest}
            onCodeChanged={setDefinitions}
            jsonSchema={jsonSchema}
            language="json" />}
    </ReadOnlyContext.Consumer>
}
