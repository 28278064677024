import { Chip /*Badge*/, Box, IconButton, Tooltip, withStyles, createStyles } from "@material-ui/core";
import { KeyboardDatePicker } from '@material-ui/pickers';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { LocaleDateFormat, today, isValidDate } from "tools/lib/utility";
import { DateType } from "@date-io/type";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useCallback } from "react";

const StyledChip = withStyles(theme =>
    createStyles({
        root: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        label: {
            paddingLeft: 0,
            paddingRight: 0
        }
    })
)(Chip);

export interface IPricingDatesNavigatorProps {
    pricingDates?: Date[];
    pricingDate?: Date;
    allowEveryPricingDate?: boolean;
    pricingDatesLoading: boolean;
    onPricingDateChanged: (pricingDate: Date) => void;
    label?: string;
}

export default function PricingDatesNavigator({ label = "As of date", pricingDates = [], pricingDate = today(), onPricingDateChanged, allowEveryPricingDate = false }: IPricingDatesNavigatorProps) {
    const handleLeftDateClick = useCallback(() => {
        if (allowEveryPricingDate) {
            const newDate = new Date(pricingDate);
            newDate.setDate(newDate.getDate() - 1);
            onPricingDateChanged(newDate);
        }
        else if (!!pricingDates.length) {
            const lastDateLower = (function () {
                for (let index = pricingDates.length - 1; index >= 0; index--) {
                    const element = pricingDates[index];
                    if (element.getTime() < pricingDate.getTime()) {
                        return element;
                    }
                }
                return pricingDates[0];
            })();
            onPricingDateChanged(lastDateLower);
        }
    }, [allowEveryPricingDate, onPricingDateChanged, pricingDate, pricingDates]);
    const handleRightDateClick = useCallback(() => {
        if (allowEveryPricingDate) {
            const newDate = new Date(pricingDate);
            newDate.setDate(newDate.getDate() + 1);
            onPricingDateChanged(newDate);
        }
        else if (!!pricingDates.length) {
            const lastDateLower = (function () {
                for (let index = 0; index < pricingDates.length; index++) {
                    const element = pricingDates[index];
                    if (element.getTime() > pricingDate.getTime()) {
                        return element;
                    }
                }
                return pricingDates[pricingDates.length - 1];
            })();
            onPricingDateChanged(lastDateLower);
        }
    }, [allowEveryPricingDate, onPricingDateChanged, pricingDate, pricingDates]);
    const handleChange = useCallback((date: DateType | null, value?: string | null) => {
        if (date) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            if (isValidDate(date)) {
                onPricingDateChanged(date);
            }
        }
    }, [onPricingDateChanged]);
    const handleShouldDisableDate = useCallback((date: MaterialUiPickersDate) => {
        if (date) {
            return !pricingDates.find(i => i.getDate() === date.getDate() && i.getMonth() === date.getMonth() && i.getFullYear() === date.getFullYear());
        }
        return true;
    }, [pricingDates]);
    const renderDay = useCallback((date: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean, dayComponent: JSX.Element) => {
        if (!date) {
            return dayComponent;
        }
        if (dayInCurrentMonth && pricingDates.find(i => i.getDate() === date.getDate() && i.getMonth() === date.getMonth() && i.getFullYear() === date.getFullYear())) {
            return <StyledChip label={dayComponent} /> //<Badge color="primary" variant="dot"></Badge>;
        }
        return dayComponent;
    }, [pricingDates])
    return <Box>
        <Tooltip title="Previous pricing date">
            <IconButton disabled={pricingDates[0] >= pricingDate && !allowEveryPricingDate} onClick={handleLeftDateClick} >
                <ChevronLeftIcon />
            </IconButton>
        </Tooltip>
        <KeyboardDatePicker
            label={label}
            autoOk={true}
            showTodayButton={allowEveryPricingDate}
            placeholder={LocaleDateFormat}
            format={LocaleDateFormat}
            animateYearScrolling={true}
            value={pricingDate}
            margin="dense"
            renderDay={renderDay}
            onChange={handleChange}
            shouldDisableDate={allowEveryPricingDate ? undefined : handleShouldDisableDate} />
        <Tooltip title="Next pricing date">
            <IconButton disabled={pricingDates[pricingDates.length - 1] <= pricingDate && !allowEveryPricingDate} onClick={handleRightDateClick} >
                <ChevronRightIcon />
            </IconButton>
        </Tooltip>
    </Box>
}
