import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import BatchInstanceData from "./BatchInstanceData";
import BatchInstanceTaskDetail from "./BatchInstanceTaskDetail";
import { formatDateTime, isNumber, useNumber } from "tools/lib/utility";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { useCallback, useEffect, useMemo } from "react";
import { parseScript } from "../Batch/batchParser";
import BatchScriptVisualization from "../Batch/BatchScriptVisualization";
import { ScreenTab, useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";
import { useSearchParams } from "react-router-dom";

export const batchInstanceScreen = {
    route: "/config/batches/history/:id/:tab?" as const,
    component: BatchInstance,
    label: "Batch Execution",
    tabs: {
        detail: "details",
        tasks: "tasks"
    },
}

function BatchInstance() {
    const { batchInstanceCurrent, batchInstanceLoading } = useReduxSelections("batchInstance");
    const { batchInstanceLoad } = useReduxActions("batchInstance");

    const navigate = useScreenNavigate()

    const [searchParams, setSearchParams] = useSearchParams();
    const taskIdParam = searchParams.get("taskId");
    const taskId = useNumber(taskIdParam)

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof batchInstanceScreen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (isNumber(idNum))
            batchInstanceLoad(idNum)
    }, [batchInstanceLoad, idNum]);

    const parsedScript = useMemo(() => {
        if (!batchInstanceCurrent?.executedScript) return undefined;
        return parseScript(batchInstanceCurrent.executedScript);
    }, [batchInstanceCurrent?.executedScript])

    const selectedTask = useMemo(() => {
        if (!batchInstanceCurrent || !taskId) return undefined;
        const { tasks } = batchInstanceCurrent;
        const task = tasks.find(task => task.id === taskId);
        if (!task) return undefined;
        return task;
    }, [taskId, batchInstanceCurrent]);

    const handleRefresh = useCallback(() => {
        if (batchInstanceCurrent?.id) {
            batchInstanceLoad(batchInstanceCurrent.id);
        }
    }, [batchInstanceCurrent?.id, batchInstanceLoad])

    const handleTaskClick = useCallback((taskId: number) => {
        if (batchInstanceCurrent?.id) {
            navigate("BatchExecution", { id: batchInstanceCurrent?.id, tab: "tasks" })
            setSearchParams({ "taskId": `${taskId}` });
        }
    }, [batchInstanceCurrent?.id, navigate, setSearchParams])

    const handleBatchClick = useCallback(() => {
        const batchDefinitionId = batchInstanceCurrent?.batch?.id;
        if (batchDefinitionId) {
            navigate("BatchDefinition", { id: batchDefinitionId })
        }
    }, [batchInstanceCurrent?.batch?.id, navigate])

    const handleTabValueChanged = useCallback((tabValue: ScreenTab<"BatchExecution">) => {
        if (batchInstanceCurrent?.id) {
            setSearchParams({});
            navigate("BatchExecution", { id: batchInstanceCurrent?.id, tab: tabValue });
        }
    }, [batchInstanceCurrent?.id, navigate, setSearchParams])

    if (!batchInstanceCurrent) {
        return <DetailPanelLoading tabNumber={2} />;
    }

    const title = `${batchInstanceCurrent.batch?.name ?? batchInstanceCurrent.comment ?? ""} - ${formatDateTime(batchInstanceCurrent.executionDateTime)}`;
    let tabs: ISubMenuTabs<typeof batchInstanceScreen>[] = [{
        value: "detail",
        label: "Data"
    }, {
        value: "tasks",
        label: "Tasks"
    }];

    if (!!selectedTask) {
        // A display-only tab not for the router
        tabs.push({
            // @ts-ignore
            value: "task",
            label: `${selectedTask.name} execution plan`
        });
    }

    const isTaskTab = tabValue === "tasks" && !!selectedTask;
    const isTasksTab = tabValue === "tasks" && !isTaskTab;
    const tabDisplayValue = isTaskTab ? "task" : tabValue
    return (
        <DetailPanel
            isQuerying={batchInstanceLoading}
            onRefreshClick={handleRefresh}
            title={title}
            subTitle={batchInstanceCurrent.type}
            tabs={tabs}
            tabValue={tabDisplayValue}
            noPadding={isTaskTab}
            onTabValueChanged={handleTabValueChanged}>
            {tabValue === "detail" &&
                <BatchInstanceData batchInstance={batchInstanceCurrent} onBatchClick={handleBatchClick} />}
            {isTasksTab &&
                <BatchScriptVisualization parsedScript={parsedScript} executedTasks={batchInstanceCurrent.tasks}
                    onTaskClick={handleTaskClick} />}
            {isTaskTab && <BatchInstanceTaskDetail task={selectedTask} />}
        </DetailPanel>
    );
}
