import * as React from "react";
import { withStyles, createStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { Box, ClickAwayListener, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Tooltip } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AlertDialog from "./AlertDialog";
import AddIcon from '@material-ui/icons/Add';
import UndoIcon from '@material-ui/icons/Undo';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useGrants } from "tools/lib/reduxStoreAccess";
import { GrantRequest } from "tools/lib/utility";

const FabContainerBox = withStyles(theme =>
    createStyles({
        root: {
            display: "flex",
            height: 0,
            left: 0,
            right: 0,
            overflowY: "visible",
            zIndex: 1200,
            justifyContent: "flex-end",
            alignItems: "center",
            alignContent: "center",
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            gap:theme.spacing(2)
        },
    })
)(Box);

export interface ISubMenuActionButton {
    icon?: React.ComponentType<SvgIconProps>;
    label: string;
    onClick: () => void;
    disabled?: boolean;
    feature?: GrantRequest | GrantRequest[] | Record<string, GrantRequest>
}
export interface ISimpleActionButton {
    icon: React.ComponentType<SvgIconProps>;
    label: string;
    onClick: () => void;
    disabled?: boolean;
    feature?: GrantRequest | GrantRequest[] | Record<string, GrantRequest>
}
export type IActionButton = ISimpleActionButton | IMenuActionButton;
export interface IMenuActionButton {
    icon:  React.ComponentType<SvgIconProps>;
    label: string;
    disabled?: boolean;
    actions: ISubMenuActionButton[];
    feature?: GrantRequest | GrantRequest[] | Record<string, GrantRequest>
}
function isSimpleActionButton(actionButton: IActionButton): actionButton is ISimpleActionButton {
    return !!(actionButton as ISimpleActionButton).onClick;
}

export interface IActionButtonProps {
    action: IActionButton;
}
export function ActionButton({ action }: IActionButtonProps) {
    if (isSimpleActionButton(action)) {
        return <SimpleActionButton action={action} />
    }
    else {
        return <MenuActionButton action={action} />
    }
}

interface ISimpleActionButtonProps {
    action: ISimpleActionButton;
}
function SimpleActionButton({ action }: ISimpleActionButtonProps) {
    if (action.disabled) {
        return <Fab color="primary" onClick={action.onClick} disabled={action.disabled}>
            <action.icon />
        </Fab>;
    }
    else {
        return (<Tooltip title={action.label}>
            <Fab color="primary" onClick={action.onClick} disabled={action.disabled}>
                <action.icon />
            </Fab>
        </Tooltip>);
    }
}
export interface IMenuActionButtonProps {
    action: IMenuActionButton;
}
export function MenuActionButton({ action }: IMenuActionButtonProps) {
    const [menuOpened, setMenuOpened] = React.useState(false);
    const anchorRef = React.useRef(null);
    const isGranted = useGrants();
    const handleSwitchMenu = () => setMenuOpened(prev => !prev);

    const subActions = React.useMemo(() => action.actions.filter(i => !i.feature || isGranted(i.feature)), [action, isGranted]);

    const handleCloseMenu = () => setMenuOpened(false);

    const handleOnSubMenuClick = (subAction: ISubMenuActionButton) => {
        setMenuOpened(false);
        subAction.onClick();
    }
    if (!subActions.length) {
        return null;
    }
    return <>
        <Tooltip title={action.label} aria-label={action.label}>
            <Fab color="primary" aria-label={action.label} ref={anchorRef} onClick={handleSwitchMenu} disabled={action.disabled || action.actions.filter(a => !a.disabled).length === 0}>
                <action.icon />
            </Fab>
        </Tooltip>
        <Popper open={menuOpened} placement="bottom-end" anchorEl={anchorRef.current} transition={true} disablePortal={true} style={{ zIndex: 999999 }}>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: (placement === 'bottom' || placement === 'bottom-end' || placement === 'bottom-start') ? 'right top' : 'right bottom' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleCloseMenu}>
                            <MenuList>
                                {subActions.map((subAction, idx) => <SubMenuItem key={idx} subAction={subAction} onClick={handleOnSubMenuClick} />)}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>
}
interface ISubMenuItemProps {
    subAction: ISubMenuActionButton;
    onClick: (subAction: ISubMenuActionButton) => void;
}
function SubMenuItem({ subAction, onClick }: ISubMenuItemProps) {
    const handleClick = () => onClick(subAction);
    return <MenuItem dense={true} onClick={handleClick} disabled={subAction.disabled}>
        {(!!subAction.icon) && <ListItemIcon>
            <subAction.icon />
        </ListItemIcon>}
        <ListItemText primary={subAction.label} />
    </MenuItem>;
}

export interface IFabContainerProps {
    onSaveClick?: () => void;
    onResetClick?: () => void;
    canSave?: boolean;
    saveAllowed?: GrantRequest | GrantRequest[] | Record<string, GrantRequest>;
    deleteAllowed?: GrantRequest | GrantRequest[] | Record<string, GrantRequest>;
    addAllowed?: GrantRequest | GrantRequest[] | Record<string, GrantRequest>;
    onDeleteClick?: () => void;
    canDelete?: boolean;
    actions?: IActionButton[];
    onSearchClick?: () => void;
    onRefreshClick?: () => void;
    onAddClick?: () => void;
    // renderExtraButtons?: React.ReactNode;
    refreshDisabled?: boolean;
    addDisabled?: boolean;
    searchDisabled?: boolean;
    resetDisabled?: boolean;
    saveMustBeConfirmed?: boolean;
}


function getActionButtons({
    onSaveClick,
    onDeleteClick,
    actions,
    canDelete,
    canSave,
    onSearchClick,
    onRefreshClick,
    onAddClick,
    refreshDisabled,
    addDisabled,
    searchDisabled,
    saveAllowed,
    deleteAllowed,
    addAllowed,
    onResetClick,
    resetDisabled
}: IFabContainerProps,
    isGranted: (features: GrantRequest | GrantRequest[] | Record<string, GrantRequest>) => boolean,
    requestDelete: () => void,
    requestSave: () => void) {
    const abs: IActionButton[] = [...(actions ?? []).filter(i => isSimpleActionButton(i) || i.actions.length > 0)];
    if (onAddClick && (!addAllowed || isGranted(addAllowed))) {
        abs.push({
            label: "Add",
            icon: AddIcon,
            onClick: onAddClick,
            disabled: addDisabled,
        });
    }
    if (onRefreshClick) {
        abs.push({
            label: "Refresh",
            icon: RefreshIcon,
            onClick: onRefreshClick,
            disabled: refreshDisabled,
        });
    }
    if (onResetClick) {
        abs.push({
            label: "Reset",
            icon: UndoIcon,
            onClick: onResetClick,
            disabled: resetDisabled,
        });
    }
    if (onSearchClick) {
        abs.push({
            label: "Search",
            icon: SearchIcon,
            onClick: onSearchClick,
            disabled: searchDisabled
        });
    }
    if (onDeleteClick && (typeof deleteAllowed === "undefined" || isGranted(deleteAllowed))) {
        abs.push({
            icon: DeleteIcon,
            label: "Delete",
            onClick: requestDelete,
            disabled: !canDelete
        })
    }
    if (onSaveClick && (typeof saveAllowed === "undefined" || isGranted(saveAllowed))) {
        abs.push({
            icon: SaveIcon,
            label: "Save",
            onClick: requestSave,
            disabled: !canSave
        })
    }
    return abs;
}
export function getButtonNumber(props: IFabContainerProps): number {
    return getActionButtons(props, () => true, () => { }, () => { }).length;
}
export default function FabContainer(props: IFabContainerProps) {
    const { onDeleteClick, onSaveClick, saveMustBeConfirmed } = props;
    const [deleteDialogOpened, setDeleteDialogOpened] = React.useState(false);
    const [saveDialogOpened, setSaveDialogOpened] = React.useState(false);
    const requestDeleteDialog = () => setDeleteDialogOpened(true);
    const requestSaveDialog = React.useCallback(() => {
        if (saveMustBeConfirmed) { setSaveDialogOpened(true); }
        else { onSaveClick && onSaveClick(); }
    }, [onSaveClick, saveMustBeConfirmed]);
    const handleCloseDeleteDialog = (response: boolean) => {
        setDeleteDialogOpened(false);
        if (onDeleteClick && response) {
            onDeleteClick();
        }
    }
    const handleCloseSaveDialog = (response: boolean) => {
        setSaveDialogOpened(false);
        if (onSaveClick && response) {
            onSaveClick();
        }
    }
    const isGranted = useGrants();
    const actionButtons = React.useMemo(() => getActionButtons(props, isGranted, requestDeleteDialog, requestSaveDialog), [props, isGranted, requestSaveDialog]);



    return <>
        <FabContainerBox>
            {actionButtons.filter(i => !i.feature || isGranted(i.feature)).map((action, idx) => <ActionButton key={idx} action={action} />)}
        </FabContainerBox>
        <AlertDialog open={deleteDialogOpened} onClose={handleCloseDeleteDialog} title="Delete">
            Do you really want to delete this item?
        </AlertDialog>
        <AlertDialog open={saveDialogOpened} onClose={handleCloseSaveDialog} title="Save">
            Do you really want to save this item?
        </AlertDialog>
    </>
}
