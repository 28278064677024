import produce from "immer";
import {
    CustomScreenModel,
    CustomScreenSummaryModel,
    IBankAccountModel,
    IBankAccountSummaryModel,
    ICustomScreenDataModel,
    IDocumentSummaryModel,
    IGetBankAccountModel,
    IGetBankAccountsModel,
    IGetCustomScreensDataModel,
    IGetDocumentsModel,
    ISecuritiesSubmitCustomScreenDataParameters
} from "proxy/apiProxy";
import { AnyActionOf, produceActionFactories } from "tools/lib/store";
import { IGetEntitySummary, IGetPortfolioSummary } from "features/Reference/slice";
import { GetAllSummaries } from "features/Document/utils";

export interface IState {
    bankAccountAllLoading: boolean;
    bankAccounts: IBankAccountSummaryModel[];
    bankAccountLoading: boolean;
    bankAccountCurrent?: IBankAccountModel;
    bankAccountSaving: boolean;
    bankAccountDeleting: boolean;
    dictionaries: GetAllSummaries & {
        customScreens: Record<string | number, CustomScreenSummaryModel>;
    }

    customScreens?: CustomScreenModel[];
    customScreenDatas?: ICustomScreenDataModel[];
    customScreenDataSubmitting: boolean;
    customScreenDataLoading: boolean;

    documents?: IDocumentSummaryModel[];
    documentsLoading: boolean;
}
export const ActionFactories = produceActionFactories({
    bankAccountLoad: (payload: number) => payload,
    bankAccountLoaded: (payload: IGetBankAccountModel) => payload,
    bankAccountSave: (payload: IBankAccountModel) => payload,
    bankAccountSaved: (payload: IBankAccountModel) => payload,
    bankAccountDelete: (payload: number) => payload,
    bankAccountDeleted: (payload: number) => payload,
    bankAccountLoadAll: () => undefined,
    bankAccountLoadedAll: (payload: IGetBankAccountsModel) => payload,
    bankAccountAddEntityInDictionary: (payload: IGetEntitySummary) => payload,
    bankAccountAddPortfolioInDictionary: (payload: IGetPortfolioSummary) => payload,

    securityCustomScreensLoaded: (payload: CustomScreenModel[]) => payload,
    securityCustomScreenDatasLoad: (payload: number) => payload,
    securityCustomScreenDatasLoaded: (payload: IGetCustomScreensDataModel) => payload,
    securityCustomScreenDataSubmit: (payload: ISecuritiesSubmitCustomScreenDataParameters) => payload,
    securityCustomScreenDataSubmitted: (payload: ICustomScreenDataModel) => payload,

    bankAccountDocumentsLoad: (payload: number) => payload,
    bankAccountDocumentsLoaded: (payload: IGetDocumentsModel) => payload,
});

export function reducer(
    state: IState = {
        bankAccountLoading: false,
        bankAccountAllLoading: false,
        bankAccountSaving: false,
        bankAccountDeleting: false,
        bankAccounts: [],
        dictionaries: {
            entities: {},
            portfolios: {},
            customScreens: {},
            securities: {},
            relationships: {},
            cashMovements: {},
            transactions: {}
        },
        customScreenDataLoading: false,
        customScreenDataSubmitting: false,
        documentsLoading: false,
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "bankAccountDocumentsLoad":
                draft.documentsLoading = true;
                break;
            case "bankAccountDocumentsLoaded":
                draft.documentsLoading = false;
                draft.documents = action.payload.documents;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
                draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
                break;

            case "bankAccountAddEntityInDictionary":
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                break;
            case "bankAccountAddPortfolioInDictionary":
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios };
                draft.dictionaries.portfolios[action.payload.portfolio.id] = action.payload.portfolio;
                break;
            case "bankAccountLoadAll":
                draft.bankAccountAllLoading = true;
                break;
            case "bankAccountLoadedAll": {
                draft.bankAccountAllLoading = false;
                draft.bankAccounts = action.payload.bankAccounts;
                draft.dictionaries = {
                    portfolios: action.payload.portfolios,
                    entities: action.payload.entities,
                    customScreens: {},
                    securities: {},
                    relationships: {},
                    cashMovements: {},
                    transactions: {}
                }
                break;
            }
            case "bankAccountLoad":
                draft.bankAccountLoading = true;
                draft.bankAccountCurrent = undefined;
                draft.customScreens = undefined;
                draft.customScreenDatas = undefined;
                draft.documents = undefined;
                break;
            case "bankAccountLoaded": {
                draft.bankAccountLoading = false;
                const { bankAccount, entities } = action.payload;
                draft.bankAccountCurrent = bankAccount;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...entities };
                break;
            }
            case "bankAccountSave":
                draft.bankAccountSaving = true;
                break;
            case "bankAccountSaved":
                draft.bankAccountSaving = false;
                const saved = action.payload;
                draft.bankAccountCurrent = saved;
                const existing = draft.bankAccounts.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.bankAccounts.push(saved);
                }
                break;
            case "bankAccountDelete":
                draft.bankAccountDeleting = true;
                break;
            case "bankAccountDeleted":
                draft.bankAccountDeleting = false;
                const deletedId = action.payload;
                if (draft.bankAccountCurrent?.id === deletedId) {
                    delete draft.bankAccountCurrent;
                }
                const idx = draft.bankAccounts.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.bankAccounts.splice(idx, 1);
                }
                break;
            case "securityCustomScreensLoaded":
                draft.customScreens = action.payload;
                break;
            case "securityCustomScreenDatasLoad":
                draft.customScreenDataLoading = true;
                break;
            case "securityCustomScreenDatasLoaded":
                draft.customScreenDataLoading = false;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.customScreens = { ...draft.dictionaries.customScreens, ...action.payload.customScreens };
                draft.customScreenDatas = action.payload.customScreenDatas;
                break;
            case "securityCustomScreenDataSubmit":
                draft.customScreenDataSubmitting = true;
                break;
            case "securityCustomScreenDataSubmitted":
                if (!draft.customScreenDatas) {
                    draft.customScreenDatas = [];
                }
                const customScreenData = draft.customScreenDatas.find(i => i.customScreenId === action.payload.customScreenId);
                if (!!customScreenData) {
                    Object.assign(customScreenData, action.payload);
                }
                else {
                    draft.customScreenDatas.push(action.payload);
                }
                draft.customScreenDataSubmitting = false;
                break;
        }
    });
}
