import * as React from "react";
import { Avatar, Button, Card, CardActions, CardHeader, Collapse, IconButton } from "@material-ui/core";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { EntitySummaryModel, ICounterpartyTypeModel, RelationshipSummaryModel } from "proxy/apiProxy";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { getEnumLabels } from "tools/lib/utility";
import { getEntityName } from "tools/lib/modelUtils";
import { useField } from "formik";
import CloseIcon from '@material-ui/icons/Close';
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { roleCollaborationTypes, roleDomains } from "features/Relationship/FormInterface";
import { ScreenLink } from "tools/routing/ScreenLink";
import { IMenuTarget } from "features/App/menu/menuContracts";

const counterpartyTypes = getEnumLabels(ICounterpartyTypeModel);

export interface IRelationshipSummaryProps {
    entities: Record<string | number, EntitySummaryModel>;
    relationships: Record<string | number, RelationshipSummaryModel>;
    name: string;
}

export default function RelationshipSummary({
    relationships,
    entities,
    name: fieldName
}: IRelationshipSummaryProps) {
    const [expanded, setExpanded] = React.useState(false);
    const [{ value: relationshipId }, , { setValue }] = useField<number | undefined>(fieldName);
    const relationship = relationships[relationshipId ?? 0] as RelationshipSummaryModel | undefined;
    const entityId = relationship?.entityId;
    const entity = entities[entityId ?? 0] as EntitySummaryModel | undefined;

    const avatar = React.useMemo(() => {
        if (!relationship) {
            return undefined;
        }
        switch (relationship.type) {
            case "RoleRelationshipSummaryModel":
                return <Avatar><LocalOfferIcon /></Avatar>;
            case "CounterpartyRelationshipSummaryModel":
                if (relationship.isAuthorized) {
                    return <Avatar><VerifiedUserIcon /></Avatar>;
                }
                break;
        }
        return;
    }, [relationship]);

    const title = React.useMemo(() => {
        if (!relationship) {
            return undefined;
        }
        const entity = entities[relationship.entityId] ?? {};
        const entityName = getEntityName(entity) ?? "";
        return entityName;
    }, [entities, relationship]);

    const subTitle = React.useMemo(() => {
        if (!relationship) {
            return undefined;
        }
        switch (relationship.type) {
            case "RoleRelationshipSummaryModel":
                return `${roleDomains[relationship.domain]}-${roleCollaborationTypes[relationship.collaborationType]}`;
            case "CounterpartyRelationshipSummaryModel":
                if (relationship.counterpartyType) {
                    return counterpartyTypes[relationship.counterpartyType];
                }
                return "Counterparty";
            default:
                return "Investor";
        }
    }, [relationship]);

    const handleSwitchExpand = React.useCallback(() => setExpanded(expanded => !expanded), []);
    const relationshipDestination: IMenuTarget | undefined = React.useMemo(() => {
        if (!relationship) {
            return undefined;
        }
        return {
            type: "Screen",
            key: (function () {
                switch (relationship.type) {
                    case "RoleRelationshipSummaryModel": return "ServiceProvidersCollaborator";
                    case "CounterpartyRelationshipSummaryModel": return "Counterparty";
                    case "InvestorRelationshipSummaryModel": return "Investor";
                }
            })(),
            label: "Open Relationship",
            params: { id: String(relationship.id) }
        }
    }, [relationship])
    const entityDestination: IMenuTarget | undefined = React.useMemo(() => {
        if (!entity) {
            return undefined;
        }
        return {
            type: "Screen",
            key: (function () {
                switch (entity.type) {
                    case "PersonSummaryModel": return "Person";
                    case "EntityGroupSummaryModel": return "EntityGroup";
                    case "CompanySummaryModel": return "Company";
                    case "SicavSummaryModel": return "Sicav";
                }
            })(),
            label: "Open Entity",
            params: { id: String(entity.id) }
        }
    }, [entity])
    // if (!relationshipId) {
    //     return null;
    // }
    if (!relationship) {
        return null;
    }
    const handleSetUndefined = () => setValue(undefined);
    return <ReadOnlyContext.Consumer>{readOnly => <Card >
        <CardHeader
            avatar={avatar}
            action={<>
                {!readOnly && <IconButton onClick={handleSetUndefined}><CloseIcon /></IconButton>}
                <IconButton onClick={handleSwitchExpand}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </>}
            title={title}
            subheader={subTitle} />
        <Collapse in={expanded} timeout="auto">
            <CardActions>
                {relationshipDestination && <Button size="small"
                    component={ScreenLink}
                    destination={relationshipDestination}
                ><OpenInBrowser />Open Relationship
                </Button>}
                {entityDestination && <Button size="small" component={ScreenLink}
                    destination={entityDestination}><OpenInBrowser />Open Entity</Button>}
            </CardActions>
        </Collapse>
    </Card>}
    </ReadOnlyContext.Consumer>
}







export interface IRelationshipSummaryReadOnlyProps {
    entities: Record<string | number, EntitySummaryModel>;
    relationships: Record<string | number, RelationshipSummaryModel>;
    relationshipId: number;
    label: string;
}

export function RelationshipSummaryReadOnly({
    relationships,
    entities,
    relationshipId
}: IRelationshipSummaryReadOnlyProps) {
    const [expanded, setExpanded] = React.useState(false);
    const relationship = relationships[relationshipId ?? 0] as RelationshipSummaryModel | undefined;
    const entityId = relationship?.entityId;
    const entity = entities[entityId ?? 0] as EntitySummaryModel | undefined;

    const avatar = React.useMemo(() => {
        if (!relationship) {
            return undefined;
        }
        switch (relationship.type) {
            case "RoleRelationshipSummaryModel":
                return <Avatar><LocalOfferIcon /></Avatar>;
            case "CounterpartyRelationshipSummaryModel":
                if (relationship.isAuthorized) {
                    return <Avatar><VerifiedUserIcon /></Avatar>;
                }
                break;
        }
        return;
    }, [relationship]);

    const title = React.useMemo(() => {
        if (!relationship) {
            return undefined;
        }
        const entity = entities[relationship.entityId] ?? {};
        const entityName = getEntityName(entity) ?? "";
        return entityName;
    }, [entities, relationship]);

    const subTitle = React.useMemo(() => {
        if (!relationship) {
            return undefined;
        }
        switch (relationship.type) {
            case "RoleRelationshipSummaryModel":
                return `${roleDomains[relationship.domain]}-${roleCollaborationTypes[relationship.collaborationType]}`;
            case "CounterpartyRelationshipSummaryModel":
                if (relationship.counterpartyType) {
                    return counterpartyTypes[relationship.counterpartyType];
                }
                return "Counterparty";
            default:
                return "Investor";
        }
    }, [relationship]);

    const handleSwitchExpand = React.useCallback(() => setExpanded(expanded => !expanded), []);
    const relationshipDestination: IMenuTarget | undefined = React.useMemo(() => {
        if (!relationship) {
            return undefined;
        }
        return {
            type: "Screen",
            key: (function () {
                switch (relationship.type) {
                    case "RoleRelationshipSummaryModel": return "ServiceProvidersCollaborator";
                    case "CounterpartyRelationshipSummaryModel": return "Counterparty";
                    case "InvestorRelationshipSummaryModel": return "Investor";
                }
            })(),
            label: "Open Relationship",
            params: { id: String(relationship.id) }
        }
    }, [relationship])
    const entityDestination: IMenuTarget | undefined = React.useMemo(() => {
        if (!entity) {
            return undefined;
        }
        return {
            type: "Screen",
            key: (function () {
                switch (entity.type) {
                    case "PersonSummaryModel": return "Person";
                    case "EntityGroupSummaryModel": return "EntityGroup";
                    case "CompanySummaryModel": return "Company";
                    case "SicavSummaryModel": return "Sicav";
                }
            })(),
            label: "Open Entity",
            params: { id: String(entity.id) }
        }
    }, [entity])
    if (!relationship) {
        return null;
    }
    return <Card >
        <CardHeader
            avatar={avatar}
            action={<IconButton onClick={handleSwitchExpand}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>}
            title={title}
            subheader={subTitle} />
        <Collapse in={expanded} timeout="auto">
            <CardActions>
                {relationshipDestination && <Button size="small"
                    component={ScreenLink}
                    destination={relationshipDestination}
                ><OpenInBrowser />Open Relationship
                </Button>}
                {entityDestination && <Button size="small" component={ScreenLink}
                    destination={entityDestination}><OpenInBrowser />Open Entity</Button>}
            </CardActions>
        </Collapse>
    </Card>
}
