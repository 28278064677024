import produce from "immer";
import { IDocumentDefinitionModel, IDocumentDefinitionsGenerateDocumentParameters, IDocumentDefinitionSummaryModel, IReportTemplateSummaryModel, IUniverseScopeTypeModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IDocumentDefinitionsGetAllParameters } from "proxy/apiProxy";
export type VisualizationType = "GRID" | "CHART";
export interface IDocumentDefinitionGenerate extends IDocumentDefinitionsGenerateDocumentParameters {
    scopeType: IUniverseScopeTypeModel;
}

export interface IState {
    documentDefinitionAllLoading: boolean;
    documentDefinitionLoading: boolean;
    documentDefinition?: IDocumentDefinitionModel;
    documentDefinitionSaving: boolean;
    documentDefinitionDeleting: boolean;
    documentDefinitions: IDocumentDefinitionSummaryModel[];
    documentDefinitionReportTemplates?: IReportTemplateSummaryModel[];
    generating: { [scope: string]: boolean }
}
export const ActionFactories = produceActionFactories({
    documentDefinitionLoad: (payload: number) => payload,
    documentDefinitionLoadAll: (payload: IDocumentDefinitionsGetAllParameters) => payload,
    documentDefinitionLoaded: (payload: IDocumentDefinitionModel) => payload,
    documentDefinitionSave: (payload: IDocumentDefinitionModel) => payload,
    documentDefinitionSaved: (payload: IDocumentDefinitionModel) => payload,
    documentDefinitionDelete: (payload: number) => payload,
    documentDefinitionDeleted: (payload: number) => payload,
    documentDefinitionLoadedAll: (payload: IDocumentDefinitionSummaryModel[]) => payload,
    documentDefinitionReportTemplateLoadedAll: (payload: IReportTemplateSummaryModel[]) => payload,
    documentDefinitionGenerate: (payload: IDocumentDefinitionGenerate) => payload,
    documentDefinitionGenerated: (payload: IUniverseScopeTypeModel) => payload
});

export function reducer(
    state: IState = {
        documentDefinitionLoading: false,
        documentDefinitionAllLoading: false,
        documentDefinitionSaving: false,
        documentDefinitionDeleting: false,
        documentDefinitions: [],
        generating: {}
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "documentDefinitionGenerate":
                draft.generating[action.payload.scopeType] = true;
                break;
            case "documentDefinitionGenerated":
                draft.generating[action.payload] = false;
                break;
            case "documentDefinitionReportTemplateLoadedAll":
                draft.documentDefinitionReportTemplates = action.payload;
                break;
            case "documentDefinitionLoadAll":
                draft.documentDefinitionAllLoading = true;
                break;
            case "documentDefinitionLoadedAll":
                draft.documentDefinitionAllLoading = false;
                draft.documentDefinitions = action.payload;
                break;
            case "documentDefinitionLoad":
                draft.documentDefinitionLoading = true;
                draft.documentDefinition = undefined;
                break;
            case "documentDefinitionLoaded":
                draft.documentDefinitionLoading = false;
                draft.documentDefinition = action.payload;
                break;
            case "documentDefinitionSave":
                draft.documentDefinitionSaving = true;
                break;
            case "documentDefinitionSaved":
                draft.documentDefinitionSaving = false;
                const model = action.payload;
                draft.documentDefinition = model;
                const existing = draft.documentDefinitions.find(i => i.id === model.id);
                if (existing) {
                    Object.assign(existing, model);
                }
                else {
                    draft.documentDefinitions.push(model);
                }
                break;
            case "documentDefinitionDelete":
                draft.documentDefinitionDeleting = true;
                break;
            case "documentDefinitionDeleted":
                draft.documentDefinitionDeleting = false;
                const deletedId = action.payload;
                if (draft.documentDefinition && draft.documentDefinition.id === deletedId) {
                    delete draft.documentDefinition;
                }
                const idx = draft.documentDefinitions.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.documentDefinitions.splice(idx, 1);
                }
                break;
        }
    });
}
