import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { useReduxSelections, useReduxActions } from "tools/lib/reduxStoreAccess";
import { IProcessDefinitionSummaryModel, IProcessDefinitionModel } from "proxy/apiProxy";
import DialogPanel from "tools/components/DialogPanel";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import { useEffect } from "react";

export interface IProcessDefinitionLookupsProps {
    onSelected: (processDefinition: IProcessDefinitionSummaryModel) => void;
    onCancel: () => void;
}
export function ProcessDefinitionLookups({ onSelected, onCancel }: IProcessDefinitionLookupsProps) {
    const { classificationTypes, classificationTypesFlat } = useReduxSelections("processClassificationType");
    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, ({ classifications }: (IProcessDefinitionModel | IProcessDefinitionSummaryModel)) => classifications);
    const { processDefinitionLookups = [], processDefinitionLookupAllLoading } = useReduxSelections("processDefinitionLookup");
    const { processDefinitionLookupLoad } = useReduxActions("processDefinitionLookup");
    useEffect(() => void processDefinitionLookupLoad(), [processDefinitionLookupLoad]);
    const columns: IColumnDefinition[] = [
        { name: "Code", title: "Code", getCellValue: ({ code }: IProcessDefinitionSummaryModel) => code, filteringEnabled: true },
        { name: "Name", title: "Name", getCellValue: ({ name }: IProcessDefinitionSummaryModel) => name["en"] ?? name.en, filteringEnabled: true },
        ...classificationTypesColumns
    ];
    const state: IGridState = {
        "Code": { width: 280 },
        "Name": { width: 280 },
        ...classificationTypesState
    };

    const getRowKey = ({ id }: (IProcessDefinitionSummaryModel)) => id;

    return <DialogPanel
        onBackClick={onCancel}
        maxWidth="lg"
        title="Process definitions"
        isQuerying={processDefinitionLookupAllLoading}
        isOpened={true}>
        <ExtendedGrid
            getRowId={getRowKey}
            columns={columns}
            rows={processDefinitionLookups}
            onRowClick={onSelected}
            initialState={state}
            userCanGroup={true}
            defaultExportFileName="Processes.xlsx" />
    </DialogPanel>;
}