import { Epic } from "redux-observable";
import { map, mergeMap, tap } from "rxjs/operators";
import { processExecutionsApi, studioDevelopmentItemsRunApi } from "proxy/apiProxy";
import { mapToPayload } from "lib/rxJsUtility";
import { merge, of } from "rxjs";
import { ActionFactories, Action } from "./slice";
import { base64toBlob, toDictionary } from "tools/lib/utility";
import saveAs from "file-saver";

export const genericDashboardLoad: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("genericDashboard", "genericDashboardLoad"),
        mergeMap((input) => {
            const getDashboardLayout$ = of({ name: input.dashboardLayoutName }).pipe(
                mergeMap(studioDevelopmentItemsRunApi.getDashboardLayoutAsync),
                map(ActionFactories.genericDashboardLayoutLoaded)
            );
            if (input.datasetName) {
                return merge(
                    of(ActionFactories.genericDashboardDatasetLoad({ ...input, datasetName: input.datasetName })),
                    getDashboardLayout$
                );
            }
            else {
                return getDashboardLayout$;
            }
        })
    );
export const genericDashboardEtlExecute: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("genericDashboard", "genericDashboardEtlExecute"),
        mergeMap(({ inputFiles, ...input }) => {
            const files = inputFiles ? Object.keys(inputFiles).map(k => {
                const zipFileContent = inputFiles[k];
                return {
                    connector: k,
                    file: {
                        data: zipFileContent.content,
                        mimeType: zipFileContent.mimeType,
                        name: zipFileContent.fileName
                    }
                };
            }) : []
            return studioDevelopmentItemsRunApi
                .etlExecuteAsync({ body: { ...input, inputFiles: toDictionary(files, i => i.connector, i => i.file) } })
                .then(r => ({ ...r, etlName: input.etlName, save: !!input.save }));
        }),
        tap(({ save, file: { data, mimeType, name } }) => {
            if (save && data && mimeType && name) {
                const blob = base64toBlob(data, mimeType);
                saveAs(blob, name);
            }
        }),
        map(ActionFactories.genericDashboardEtlExecuted)
    );
export const genericDashboardDatasetLoad: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("genericDashboard", "genericDashboardDatasetLoad"),
        mergeMap(i => studioDevelopmentItemsRunApi.datasetLoadAsync({ body: { ...i, type: "GenerateDatasetModel" } })),
        map(ActionFactories.genericDashboardDatasetLoaded));




export const saveDueDilFile: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("genericDashboard", "genericDashboardProcessExecutionSaveFile"),
        mergeMap(i => processExecutionsApi.saveProcessExecutionFileAsync(i).then(() => i)),
        map(ActionFactories.genericDashboardProcessExecutionSavedFile));

export const deleteDueDilFile: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("genericDashboard", "genericDashboardProcessExecutionFileDelete"),
        mergeMap(i => processExecutionsApi.deleteProcessExecutionFileAsync(i).then(() => i)),
        map(ActionFactories.genericDashboardProcessExecutionFileDeleted));

export const loadDueDilFile: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("genericDashboard", "genericDashboardProcessExecutionLoadFile"),
        mergeMap(parameters => processExecutionsApi.getProcessExecutionFileAsync(parameters)
            .then(fileResponse => ({ fileResponse, parameters }))),
        tap(({ fileResponse: { blob, fileName } }) => saveAs(blob, fileName)),
        map(i => ActionFactories.genericDashboardProcessExecutionLoadedFile(i.parameters)));

export const loadQuestionnaire: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("genericDashboard", "genericDashboardProcessExecutionLoadQuestionnaire"),
        mergeMap(parameters => processExecutionsApi.getProcessExistingExecutionQuestionnaireAsync(parameters)
            .then(state => ({ parameters, state }))),
        map(({ parameters: { id: processId, taskCode }, state: taskState }) => ActionFactories.genericDashboardProcessExecutionLoadedQuestionnaire({ id: processId, taskCode })));

export const saveQuestionnaire: Epic<Action>
    = action$ => action$.pipe(
        mapToPayload("genericDashboard", "genericDashboardProcessExecutionSaveQuestionnaire"),
        mergeMap(parameters => processExecutionsApi.saveProcessExecutionQuestionnaireResponseAsync(parameters)
            .then(task => ({ task, parameters }))),
        map(({ parameters: { id: processId }, task }) => ActionFactories.genericDashboardProcessExecutionSavedQuestionnaire({ id: processId, taskCode: task.code })));
