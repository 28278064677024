import { ITaskModel } from "proxy/apiProxy";
import DataStreamVisualization from "components/global/DataStreamVisualization";
import { Box } from "@material-ui/core";
export interface IBatchInstanceTaskDetailProps {
    task: ITaskModel;
};
export default function BatchInstanceTaskDetail({ task: { definitionStructure: { nodes, links }, result: { counters, error } = { counters: {} } } }: IBatchInstanceTaskDetailProps) {

    return <Box display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}><DataStreamVisualization
        links={links}
        nodes={nodes}
        counters={counters}
        error={error} /></Box>
}
