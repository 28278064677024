import * as React from "react";
import { useEffect } from "react";
import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { Formik, FormikHelpers, FormikProps } from "formik";
import {
    IFeatureModel,
    IFileModel,
    IMacroScriptModel,
    IMacroScriptTypeModel,
    ITextModelWithPosition,
    MacroScriptModel,
    studioMacroScriptsApi
} from "proxy/apiProxy";
import MacroScriptData from "./MacroScriptData";
import MacroScriptEditor from "./MacroScriptEditor";
import MacroScriptVisualEtl from "./MacroScriptVisualEtl";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { isNumber, oProps, useNumber } from "tools/lib/utility";
import FileSelectDialog from "../../components/global/FileSelectDialog";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { IActionButton } from "tools/components/FabContainer";
import { getMacroScriptTypeLabel } from "./getMacroScriptTypeLabel";
import { getConfig } from "lib/userManager";
import { useScreenParams } from "tools/routing/screenRouteHooks";

export const macroScriptScreen = {
    route: "/config/macros/scripts/:id/:tab?" as const,
    label: "Macro",
    component: MacroScript,
    tabs: {
        detail: "details",
        macro: "macro",
        visualetl: "visual"
    },
}


const { general: { disableSudo } } = getConfig();

function getRelatedWriteFeature(type: IMacroScriptTypeModel | MacroScriptModel["type"]): IFeatureModel {
    switch (type) {
        case IMacroScriptTypeModel.DataProcessor:
        case "DataProcessorMacroScriptModel":
            return IFeatureModel.DataProcessorMacroWrite;
        case IMacroScriptTypeModel.FileProcessor:
        case "FileProcessorMacroScriptModel":
            return IFeatureModel.ImportExportProcessMacroWrite;
        case IMacroScriptTypeModel.FileRetriever:
        case "FileRetrieverMacroScriptModel":
            return IFeatureModel.ImportExportProcessMacroWrite;
        case IMacroScriptTypeModel.MarketDataSelector:
        case "MarketDataSelectorMacroScriptModel":
            return IFeatureModel.MarketDataSelectorMacroWrite;
        case IMacroScriptTypeModel.Monitoring:
        case "MonitoringMacroScriptModel":
            return IFeatureModel.MonitoringMacroWrite;
        case IMacroScriptTypeModel.SubMacro:
        case "SubMacroScriptModel":
            return IFeatureModel.SubMacroWrite;
    }
}
function getRelatedDeleteFeature(type: IMacroScriptTypeModel | MacroScriptModel["type"]): IFeatureModel {
    switch (type) {
        case IMacroScriptTypeModel.DataProcessor:
        case "DataProcessorMacroScriptModel":
            return IFeatureModel.DataProcessorMacroDelete;
        case IMacroScriptTypeModel.FileProcessor:
        case "FileProcessorMacroScriptModel":
            return IFeatureModel.ImportExportProcessMacroDelete;
        case IMacroScriptTypeModel.FileRetriever:
        case "FileRetrieverMacroScriptModel":
            return IFeatureModel.ImportExportProcessMacroDelete;
        case IMacroScriptTypeModel.MarketDataSelector:
        case "MarketDataSelectorMacroScriptModel":
            return IFeatureModel.MarketDataSelectorMacroDelete;
        case IMacroScriptTypeModel.Monitoring:
        case "MonitoringMacroScriptModel":
            return IFeatureModel.MonitoringMacroDelete;
        case IMacroScriptTypeModel.SubMacro:
        case "SubMacroScriptModel":
            return IFeatureModel.SubMacroWrite;
    }
}
function getRelatedExecuteFeature(type: IMacroScriptTypeModel | MacroScriptModel["type"]): IFeatureModel | undefined {
    switch (type) {
        case IMacroScriptTypeModel.DataProcessor:
        case "DataProcessorMacroScriptModel":
            return IFeatureModel.DataProcessorMacroExecute;
        case IMacroScriptTypeModel.FileProcessor:
        case "FileProcessorMacroScriptModel":
            return IFeatureModel.ImportExportProcessMacroExecute;
        case IMacroScriptTypeModel.FileRetriever:
        case "FileRetrieverMacroScriptModel":
            return IFeatureModel.ImportExportProcessMacroExecute;
    }
    return;
}
function getRelatedMacroType(type: MacroScriptModel["type"]): IMacroScriptTypeModel {
    switch (type) {
        case "DataProcessorMacroScriptModel": return IMacroScriptTypeModel.DataProcessor;
        case "FileProcessorMacroScriptModel": return IMacroScriptTypeModel.FileProcessor;
        case "FileRetrieverMacroScriptModel": return IMacroScriptTypeModel.FileRetriever;
        case "MarketDataSelectorMacroScriptModel": return IMacroScriptTypeModel.MarketDataSelector;
        case "MonitoringMacroScriptModel": return IMacroScriptTypeModel.Monitoring;
        case "SubMacroScriptModel": return IMacroScriptTypeModel.SubMacro;
    }
}

function isMacroScriptTypeModel(value: string): value is IMacroScriptTypeModel {
    return value in IMacroScriptTypeModel
}

function MacroScript() {
    const [macroInputFileModel, setMacroInputFileModel] = React.useState<IFileModel | undefined>();
    const [showMacroInputFileDialog, setShowMacroInputFileDialog] = React.useState<{ id: number, type: MacroScriptModel["type"] } | undefined>();

    const { macroScriptCurrent, macroScriptLoading, macroScriptSaving, macroScriptCheckResult = { errors: [] }, macroScriptMetadata, macroExecutionResult, macroScriptExecuting } = useReduxSelections("macroScript");
    const { referenceMacroScriptCategories = {} } = useReduxSelections("reference");
    const {
        macroScriptSave,
        macroScriptDelete,
        macroScriptExecute,
        macroScriptValidateScript,
        macroScriptNew,
        macroScriptLoad
    } = useReduxActions("macroScript")

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof macroScriptScreen>()
    const idNum = useNumber(id)

    useEffect(() => {
        if (isNumber(idNum))
            macroScriptLoad(idNum)
        else if (id && isMacroScriptTypeModel(id))
            macroScriptNew(id)
    }, [id, idNum, macroScriptLoad, macroScriptNew]);

    const isGranted = useGrants();
    if (!macroScriptCurrent) {
        return <DetailPanelLoading tabNumber={3} hasSubTitle={false} />;
    }

    const handleShowMacroInputFileDialog = (id: number, type: MacroScriptModel["type"]) => setShowMacroInputFileDialog({ id, type });
    const handleHideMacroInputFileDialog = () => setShowMacroInputFileDialog(undefined);
    const handleDeleteClick = () => {
        if (macroScriptCurrent?.id) {
            macroScriptDelete(macroScriptCurrent.id);
        }
    }
    const handleSubmit = (values: MacroScriptModel, { setSubmitting }: FormikHelpers<MacroScriptModel>) => {
        macroScriptSave(values);
        setSubmitting(false);
    }
    const macroType = macroScriptCurrent.type;
    const handleExecute = () => {
        const { id } = macroScriptCurrent;
        if (id) {
            if (macroType === "DataProcessorMacroScriptModel" || macroType === "FileRetrieverMacroScriptModel") {
                macroScriptExecute({ id, type: macroType });
            }
            else if (macroType === "FileProcessorMacroScriptModel") {
                handleShowMacroInputFileDialog(id, macroType);
            }
        }
    }
    const handleRequestAutoComplete = (position: ITextModelWithPosition) => studioMacroScriptsApi.getAutoCompleteScriptAsync({ type: getRelatedMacroType(macroType), textModel: position });

    const handleScriptChanged = (script: string) => {
        if (macroType !== "SubMacroScriptModel") {
            macroScriptValidateScript({ script, type: macroType });
        }
    }
    const handleMacroFileSelected = () => {
        if (macroInputFileModel && showMacroInputFileDialog && showMacroInputFileDialog.type === "FileProcessorMacroScriptModel") {
            macroScriptExecute({
                file: macroInputFileModel,
                type: showMacroInputFileDialog.type,
                id: showMacroInputFileDialog.id
            });
            setShowMacroInputFileDialog(undefined);
        }
    }
    return <Formik onSubmit={handleSubmit} initialValues={macroScriptCurrent} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;
    function renderForm({ submitForm, setFieldValue, values, dirty, isValid }: FormikProps<MacroScriptModel>) {
        const title = !!values.id ? values.name : "New Macro";
        const { errors, structure } = macroScriptCheckResult;
        const tabs: ISubMenuTabs<typeof macroScriptScreen>[] | undefined = [{
            value: "detail",
            label: "Macro",
        }, {
            value: "macro",
            label: "Data",
        }];
        const extraActionButtons: IActionButton[] = [];
        if (structure) {
            tabs.push({
                label: "Execution Plan",
                value: "visualetl"
            });
            if (macroScriptCurrent?.id) {
                let relatedExecFeature = getRelatedExecuteFeature(macroType);
                if (relatedExecFeature && isGranted(relatedExecFeature)) {
                    extraActionButtons.push({
                        label: "Execute",
                        onClick: handleExecute,
                        icon: PlayArrowIcon,
                        disabled: macroScriptExecuting
                    });
                }
            }
        }
        const handleSaveClick = () => {
            if (dirty && isValid) {
                submitForm();
            }
        };
        return <WriteAccess value={getRelatedWriteFeature(macroType)}>
            <FileSelectDialog
                title="Choose a file to be submitted to the macro"
                label="Drop the file to import here"
                isOpened={!!showMacroInputFileDialog}
                onClose={handleHideMacroInputFileDialog}
                onFileSelected={setMacroInputFileModel}
                selectedFile={macroInputFileModel}
                onOk={handleMacroFileSelected}
                okLabel="Execute" />
            <DetailPanel
                tabs={tabs}
                tabValue={tabValue}
                isQuerying={macroScriptLoading || macroScriptSaving}
                title={title}
                subTitle={getMacroScriptTypeLabel(macroType)}
                badge={!values.id ? "new" : undefined}
                noPadding={tabValue === "detail" || tabValue === "visualetl"}
                onSaveClick={handleSaveClick}
                canSave={dirty && isValid}
                saveAllowed={getRelatedWriteFeature(macroType)}
                deleteAllowed={getRelatedDeleteFeature(macroType)}
                onDeleteClick={handleDeleteClick}
                canDelete={!!values.id}
                actions={extraActionButtons}
                saveMustBeConfirmed={!disableSudo && !!(["DataProcessorMacroScriptModel", "FileRetrieverMacroScriptModel", "FileProcessorMacroScriptModel"].find(i => i === macroType))}
            >
                {(tabValue === "macro") && <MacroScriptData referenceMacroScriptCategories={referenceMacroScriptCategories} />}
                {(tabValue === "detail") && <MacroScriptEditor
                    field={oProps<IMacroScriptModel>().path("script")}
                    executing={macroScriptExecuting}
                    onScriptChanged={handleScriptChanged}
                    scriptErrors={errors}
                    onSaveRequest={handleSaveClick}
                    onRequestAutoComplete={handleRequestAutoComplete}
                    metadata={macroScriptMetadata}
                    error={macroExecutionResult?.error} />}
                {(tabValue === "visualetl" && !!structure) && <MacroScriptVisualEtl definitionStructure={structure} counters={macroExecutionResult?.counters} error={macroExecutionResult?.error} />}
            </DetailPanel>
        </WriteAccess>
    }
}
