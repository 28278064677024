import { IFeatureModel, IFileTypeModel, IUniverseScopeTypeModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import FileChartIcon from "mdi-material-ui/FileChart";
import SettingsIcon from '@material-ui/icons/Settings';
import { IActionButton } from "tools/components/FabContainer";
import React from "react";

export default function useScopeButtonsMenu(scope: IUniverseScopeTypeModel, currentId: number | undefined, readOnly?: boolean) {
    const { reportTemplateList, generating } = useReduxSelections("reportTemplate");
    const { documentDefinitions, generating: defGenerating } = useReduxSelections("documentDefinition");
    const { reportTemplateGenerate } = useReduxActions("reportTemplate");
    const { documentDefinitionGenerate } = useReduxActions("documentDefinition");
    const { macroScriptList, macroScriptExecuting } = useReduxSelections("macroScript");
    const { macroScriptExecute } = useReduxActions("macroScript");
    const extraActionButtons: IActionButton[] | undefined = React.useMemo(() => {
        if (!currentId) {
            return [];
        }
        const menus: IActionButton[] = [];
        if (reportTemplateList.length) {
            menus.push({
                label: "Reports & Exports",
                icon: FileChartIcon,
                feature: IFeatureModel.ReportExecute,
                actions: [
                    ...reportTemplateList.filter(i => i.singleScope === scope).flatMap(i => ([{
                        label: i.name,
                        onClick: () => reportTemplateGenerate({ id: i.id, scopeType: scope, targetId: currentId }),
                        disabled: !!generating[scope]
                    }, {
                        label: `${i.name} (xls)`,
                        onClick: () => reportTemplateGenerate({ id: i.id, scopeType: scope, targetId: currentId, fileType: IFileTypeModel.Xls }),
                        disabled: !!generating[scope]
                    }])),
                    ...documentDefinitions.filter(i => i.singleScope === scope).flatMap(i => ([{
                        label: i.name,
                        onClick: () => documentDefinitionGenerate({ id: i.id, scopeType: scope, targetId: currentId }),
                        disabled: !!defGenerating[scope]
                    }, {
                        label: `${i.name} (xls)`,
                        onClick: () => documentDefinitionGenerate({ id: i.id, scopeType: scope, targetId: currentId, fileType: IFileTypeModel.Xls }),
                        disabled: !!defGenerating[scope]
                    }])),
                ]
            });
        }
        if (macroScriptList.length && !readOnly) {
            menus.push({
                label: "Macros",
                icon: SettingsIcon,
                feature: IFeatureModel.DataProcessorMacroExecute,
                actions: macroScriptList.filter(i => i.type === "DataProcessorMacroScriptModel" && i.singleScope === scope).map(i => ({
                    label: i.name,
                    onClick: () => macroScriptExecute({ type: "DataProcessorMacroScriptModel", id: i.id, targetId: currentId }),
                    disabled: macroScriptExecuting
                }))
            });
        }
        return menus;
    }, [currentId, reportTemplateList, macroScriptList, readOnly, documentDefinitions, scope, generating, reportTemplateGenerate, defGenerating, documentDefinitionGenerate, macroScriptExecuting, macroScriptExecute]);
    return extraActionButtons;
}
