import { Epic } from "redux-observable";
import { debounceTime, map, mergeAll, mergeMap, share, tap } from "rxjs/operators";
import {
    DevelopmentItemModel,
    studioDevelopmentItemsRunApi,
    IDevelopmentItemTypeModel,
    IEntityMetadataModel,
    IGetDevelopmentItemModel,
    IMacroLanguageModel,
    IMacroTypeModel,
    studioDevelopmentItemsApi,
    IGenerateDatasetModel
} from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { merge } from "rxjs";
import { DashboardExecuteOnLoad, IDevelopmentItemDiagnostic, ITypedMacro } from "./slice";
import { getConfig } from "lib/userManager";
import { saveAs } from "file-saver";
import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor';
import { base64toBlob } from "tools/lib/utility";

function createNewDevelopmentItemInstance(type: IDevelopmentItemTypeModel): DevelopmentItemModel {
    switch (type) {
        case IDevelopmentItemTypeModel.Questionnaire: return {
            type: "QuestionnaireDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {},
            templateContent: "{}",
            onLoadMacro: { content: "return new {};\n", language: IMacroLanguageModel.CSharp },
            onCompleteMacro: {
                content: "using FundProcess.Pms.Services.Studio;\n"
                    + "using FundProcess.Pms.Services.Studio.SurveyScorer;\n\n"
                    + "return Context.ComputeSurveyScore(new() { EmptyAsMaxScore = true }).ToJson();\n",
                language: IMacroLanguageModel.CSharp
            }
        }
        case IDevelopmentItemTypeModel.ReportTemplate: return {
            type: "ReportTemplateDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {},
            templateContent: new Stimulsoft.Report.StiReport().saveToJsonString(),
            // onLoadMacro: { content: "return new {};", language: IMacroLanguageModel.CSharp }
        }
        case IDevelopmentItemTypeModel.EtlMacro: return {
            type: "EtlMacroDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {}
        }
        // case IDevelopmentItemTypeModel.DashboardMacro: return {
        //     type: "DashboardMacroDevelopmentItemModel",
        //     name: "",
        //     id: 0,
        //     classifications: {}
        // }
        case IDevelopmentItemTypeModel.DashboardLayout: return {
            type: "DashboardLayoutDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {}
        }
        case IDevelopmentItemTypeModel.DatasetMacro: return {
            type: "DatasetMacroDevelopmentItemModel",
            name: "",
            id: 0,
            classifications: {}
        }
        // case IDevelopmentItemTypeModel.FileConsumerMacro: return {
        //     type: "FileConsumerMacroDevelopmentItemModel",
        //     name: "",
        //     id: 0,
        //     classifications: {}
        // }
        // case IDevelopmentItemTypeModel.FileConnector: return {
        //     type: "FileConnectorDevelopmentItemModel",
        //     name: "",
        //     id: 0,
        //     classifications: {}
        // }
        // case IDevelopmentItemTypeModel.FileProducerMacro: return {
        //     type: "FileProducerMacroDevelopmentItemModel",
        //     name: "",
        //     id: 0,
        //     classifications: {}
        // }
        // case IDevelopmentItemTypeModel.PipelineTask: return {
        //     type: "PipelineTaskDevelopmentItemModel",
        //     name: "",
        //     id: 0,
        //     classifications: {}
        // }
    }
}

const { general: { enableStudio } } = getConfig();

export const developmentItemEtlExecute: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemEtlExecute"),
        mergeMap(({ zipFileContent, zipFileName, ...body }) => studioDevelopmentItemsApi.etlOnExecuteAsync({
            body: {
                ...body,
                inputFiles: zipFileContent ? {
                    data: zipFileContent.content,
                    mimeType: zipFileContent.mimeType,
                    name: zipFileContent.fileName
                } : undefined
            }
        })),
        tap(({ result, file: { data, mimeType, name } }) => {
            if (data && mimeType && name) {
                const blob = base64toBlob(data, mimeType);
                saveAs(blob, name);
            }
        }),
        map(ActionFactories.developmentItem.developmentItemEtlExecuted));

export const reportGenerate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemReportTemplateGenerate"),
        mergeMap(i => studioDevelopmentItemsRunApi.reportGenerateAsync(i)),
        tap(({ blob, fileName }) => saveAs(blob, fileName)),
        map(ActionFactories.developmentItem.developmentItemReportTemplateGenerated));

export const developmentItemQuestionnaireExecuteOnLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemQuestionnaireExecuteOnLoad"),
        mergeMap(body => studioDevelopmentItemsApi.questionnaireOnLoadAsync({ body })),
        map(ActionFactories.developmentItem.developmentItemQuestionnaireExecuteOnLoaded));

export const developmentItemReportExecuteOnLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemReportExecuteOnLoad"),
        mergeMap((input) => studioDevelopmentItemsRunApi.datasetLoadAsync({ body: input }).then(result => ({
            result,
            input
        }))),
        map(ActionFactories.developmentItem.developmentItemReportExecuteOnLoaded));

export const developmentItemReportGenerate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemReportGenerate"),
        mergeMap(body => studioDevelopmentItemsApi.reportOnGenerateAsync({ body })),
        tap(({ blob, fileName }) => saveAs(blob, fileName)),
        map(ActionFactories.developmentItem.developmentItemReportGenerated));

export const developmentItemQuestionnaireExecuteOnComplete: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemQuestionnaireExecuteOnComplete"),
        mergeMap(body => studioDevelopmentItemsApi.questionnaireOnCompleteAsync({ body })),
        map(ActionFactories.developmentItem.developmentItemQuestionnaireExecuteOnCompleted));
function isGenerateDatasetModel(input: DashboardExecuteOnLoad): input is IGenerateDatasetModel {
    return !!input.datasetName;
}
export const developmentItemDashboardExecuteOnComplete: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemDashboardExecuteOnLoad"),
        mergeMap(async (input) => isGenerateDatasetModel(input)
            ? await studioDevelopmentItemsRunApi.datasetLoadAsync({ body: input }).then(result => ({ result, input }))
            : { input }),
        map(ActionFactories.developmentItem.developmentItemDashboardExecuteOnLoaded));

export const developmentItemDatasetGenerate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemDatasetGenerate"),
        mergeMap(body => studioDevelopmentItemsApi.datasetOnLoadAsync({ body })),
        map(ActionFactories.developmentItem.developmentItemDatasetGenerated));

export const loadDevelopmentItems: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemLoadAll"),
        mergeMap(() => enableStudio ? studioDevelopmentItemsApi.getAllAsync({}) : Promise.resolve({ developmentItems: [], entities: {} })),
        map(ActionFactories.developmentItem.developmentItemLoadedAll));

export const deleteDevelopmentItem: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("developmentItem", "developmentItemDelete"),
            mergeMap(id => studioDevelopmentItemsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.developmentItem.developmentItemDeleted));
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }

async function validateMacroAsync(macro: ITypedMacro): Promise<IDevelopmentItemDiagnostic>
async function validateMacroAsync(macro: ITypedMacro | undefined): Promise<IDevelopmentItemDiagnostic | undefined>
async function validateMacroAsync(macro: ITypedMacro | undefined | null): Promise<IDevelopmentItemDiagnostic | undefined | null> {
    if (!macro) {
        return undefined;
    }
    if ((macro.content ?? "").trimEnd() === "") {
        return { errors: [], type: macro.type };
    }
    const { type, content, language } = macro;
    const macroModel = { content, language };
    const { errors, returnType } = await studioDevelopmentItemsApi.checkAsync({ type, macroModel });
    const etlDefinition = type === IMacroTypeModel.Etl
        ? await studioDevelopmentItemsApi.describeEtlAsync({ type, macroModel })
        : undefined;
    return { errors, type, description: etlDefinition, returnType };
}
export const loadDevelopmentItem: Epic<IAnyAction>
    = action$ => {
        const loadDevelopmentItem$ = action$.pipe(
            mapToPayload("developmentItem", "developmentItemLoad"),
            mergeMap(id => studioDevelopmentItemsApi.getAsync({ id })),
            share());

        const loadedDevelopmentItem$ = loadDevelopmentItem$.pipe(
            map(ActionFactories.developmentItem.developmentItemLoaded));

        const metadata$ = loadDevelopmentItem$.pipe(
            mergeMap(studioDevelopmentItemsApi.getDatabaseStructureAsync),
            map(ActionFactories.developmentItem.developmentItemMetadataLoaded));

        const checks$ = loadDevelopmentItem$.pipe(
            map(i => i.developmentItem),
            map(ActionFactories.developmentItem.developmentItemValidate)
        );

        return merge(
            loadedDevelopmentItem$,
            checks$,
            metadata$);
    };
export const developmentItemValidate: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemValidate"),
        map(i => {
            switch (i.type) {
                case "QuestionnaireDevelopmentItemModel": return [
                    { ...(i.onLoadMacro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.QuestionnaireOnLoad } as ITypedMacro,
                    { ...(i.onCompleteMacro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.QuestionnaireOnComplete } as ITypedMacro,
                ];
                // case "ReportTemplateDevelopmentItemModel": return [{ ...(i.onLoadMacro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.Dataset } as ITypedMacro];
                case "EtlMacroDevelopmentItemModel": return [{ ...(i.macro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.Etl } as ITypedMacro];
                // case "DashboardMacroDevelopmentItemModel": return [{ ...(i.macro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.Dataset } as ITypedMacro];
                case "DatasetMacroDevelopmentItemModel": return [{ ...(i.macro ?? { content: "", language: IMacroLanguageModel.CSharp }), type: IMacroTypeModel.Dataset } as ITypedMacro];
                default: return [];
            }
        }),
        mergeMap(async i => {
            const tmp = i.map(async m => {
                if ((m.content ?? "").trimEnd().length) {
                    return await validateMacroAsync(m)
                }
                return { errors: [], type: m.type } as IDevelopmentItemDiagnostic;
            });
            return Promise.all(tmp);
        }),
        mergeAll(),
        map(ActionFactories.developmentItem.developmentItemValidatedScript)
    )
export const checkScript: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemValidateScript"),
        debounceTime(2000),
        mergeMap(i => validateMacroAsync(i)),
        // map(ActionFactories.app.dummy));
        map(ActionFactories.developmentItem.developmentItemValidatedScript));

// export const executeScript: Epic<IAnyAction>
//     = action$ => {
//         const execution$ = action$.pipe(mapToPayload("developmentItem", "developmentItemExecute"), share());
//         const dataProcessorExecution$ = execution$.pipe(
//             filter(i => i.type === "DataProcessorDevelopmentItemModel"),
//             mergeMap(r => developmentItemsApi.executeDataProcessorMacroAsync({ id: r.id, scopeId: r.type === "DataProcessorDevelopmentItemModel" ? r.targetId : undefined })),
//             map(ActionFactories.developmentItem.developmentItemExecuted));

//         const fileProviderExecution$ = execution$.pipe(
//             filter(i => i.type === "FileRetrieverDevelopmentItemModel"),
//             mergeMap(({ id }) => developmentItemsApi.executeFileProviderMacroAsync({ id })),
//             tap(({ file }) => {
//                 if (file && file.data && file.mimeType && file.name) {
//                     const blob = base64toBlob(file.data, file.mimeType);
//                     saveAs(blob, file.name);
//                 }
//             }),
//             map(({ result }) => ActionFactories.developmentItem.developmentItemFileProviderExecuted(result)));
//         const fileProcessorExecution$ = execution$.pipe(
//             map(i => {
//                 if (i.type === "FileProcessorDevelopmentItemModel") {
//                     return i;
//                 }
//                 return undefined;
//             }),
//             onlyNotNull(),
//             mergeMap(({ id, file }) => developmentItemsApi.executeFileProcessorMacroAsync({ id, fileModel: file })),
//             map(ActionFactories.developmentItem.developmentItemExecuted));
//         return merge(
//             dataProcessorExecution$,
//             fileProviderExecution$,
//             fileProcessorExecution$
//         );
//     }

export const newDevelopmentItem: Epic<IAnyAction>
    = action$ => {
        const type$ = action$.pipe(
            mapToPayload("developmentItem", "developmentItemNew"), share());

        const metadata$ = merge(
            type$.pipe(
                // filter(type => type !== IDevelopmentItemTypeModel.SubMacro),
                mergeMap(type => studioDevelopmentItemsApi.getDatabaseStructureAsync())),
            type$.pipe(
                // filter(type => type === IDevelopmentItemTypeModel.SubMacro),
                map(() => ({} as Record<string | number, IEntityMetadataModel>))))
            .pipe(map(ActionFactories.developmentItem.developmentItemMetadataLoaded));
        return merge(
            type$.pipe(
                map(type => ({ developmentItem: createNewDevelopmentItemInstance(type), entities: {} } as IGetDevelopmentItemModel)),
                map(ActionFactories.developmentItem.developmentItemLoaded)),
            metadata$);
    }
export const saveDevelopmentItem: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("developmentItem", "developmentItemSave"),
        mergeMap(model => studioDevelopmentItemsApi.saveAsync({ model })),
        map(i => ActionFactories.developmentItem.developmentItemSaved(i)));
