import { Grid, Icon, Link } from "@material-ui/core";
import { Formik, FormikProps } from "formik";
import { FormikConfig } from "formik/dist/types";
import { useCallback, useMemo } from "react";
import DialogPanel from "tools/components/DialogPanel";
import FormTextField from "tools/fieldComponents/FormTextField";
import { IMenuDefinitionTreeNodeLevel1, IMenuDefinitionTreeNodeLevel2, IMenuDefinitionTreeNodeLevel3, TreeItemPayload } from "./menuTree";
import { IMenuTarget, IMenuTargetDashboard, IMenuTargetQuestionnaire, IMenuTargetScreen } from "features/App/menu/menuContracts";
import { ScreenLink } from "tools/routing/ScreenLink";
import { oProps } from "tools/lib/utility";
import { StringToStringDictionaryField } from "tools/fieldComponents/StringToStringDictionaryField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import { ScreenKeys, ScreenTabs } from "tools/routing/screenRouteHooks";

export interface IMenuNodeDataDialogProps {
    data: TreeItemPayload,
    onChange: (node: TreeItemPayload) => void,
    onCancel: () => void,
}

export function MenuNodeDataDialog({ data, onChange, onCancel }: IMenuNodeDataDialogProps) {
    const handleSubmit = useCallback<FormikConfig<TreeItemPayload>["onSubmit"]>((values, { setSubmitting }) => {
        onChange(values);
        setSubmitting(false);
    }, [onChange])

    return <Formik onSubmit={handleSubmit} initialValues={data} enableReinitialize={true} validateOnMount={true}>
        {formProps => <MenuNodeDataDialogForm form={formProps} onCancel={onCancel} />}
    </Formik>;

}
interface IMenuNodeDataDialogFormProps {
    form: FormikProps<TreeItemPayload>;
    onCancel: () => void;
}
const leafTypes: Record<IMenuDefinitionTreeNodeLevel3["type"], string> = {
    Dashboard: "Dashboard",
    // MonitoringMacroCall: "Monitoring Screen",
    // Questionnaire: "Questionnaire",
    Screen: "Application Screen",
    Questionnaire: "Generic Screen"
}

const nodeTypeLabel: Record<TreeItemPayload["type"], string> = {
    level1: "Group Level 1",
    level2: "Group Level 2",
    ...leafTypes
}
function MenuNodeDataDialogForm({ form: { dirty, isValid, submitForm, values }, onCancel }: IMenuNodeDataDialogFormProps) {
    const actions = useMemo(() => [{
        disabled: !isValid && !dirty,
        label: "Ok",
        onClick: submitForm
    }], [isValid, submitForm, dirty]);

    return <DialogPanel
        onBackClick={onCancel}
        title={nodeTypeLabel[values.type]}
        isOpened={!!values}
        actions={actions}>
        <Grid container spacing={1}>
            <DetailsField values={values} />
        </Grid>
    </DialogPanel>
}
interface IDetailsFieldProps {
    values: TreeItemPayload,
    path?: string
}

function DetailsField({ values, path }: IDetailsFieldProps) {
    switch (values.type) {
        case "level1":
            return <Grid item xs={6} >
                <FormTextField name={oProps<IMenuDefinitionTreeNodeLevel1>().path("label")} label="Label" required />
            </Grid>
        case "level2":
            return <>
                <Grid item xs={6} >
                    <FormTextField name={oProps<IMenuDefinitionTreeNodeLevel2>().path("label")} label="Label" required />
                </Grid>
                <Grid item xs={6} >
                    <FormTextField name={oProps<IMenuDefinitionTreeNodeLevel2>(path).path("icon")} label="Icon" adornment={<Icon>{values.icon}</Icon>} />
                    <p><Link href="https://fonts.google.com/icons?icon.size=24&icon.color=%235f6368&icon.set=Material+Icons" target="_blank">Available Icons</Link>, copy the <em>Icon Name</em>.</p>
                </Grid>
            </>
        default: return <>
            <Grid item xs={6} >
                <FormSimpleSelectField name={oProps<IMenuDefinitionTreeNodeLevel3>().path("type")} label="Type" options={leafTypes} required />
            </Grid>
            <LeafDetailsField values={values} path={path} />
        </>;
    }
}
interface ILeafDetailsFieldProps {
    values: IMenuTarget,
    path?: string
}
function LeafDetailsField({ values, path }: ILeafDetailsFieldProps) {
    switch (values.type) {
        case "Screen":
            return <>
                <Grid item xs={6} >
                    <FormTextField name={oProps<IMenuTargetScreen>().path("label")} label="Label" required />
                </Grid>
                <Grid item xs={6} >
                    <FormSimpleSelectField name={oProps<IMenuTargetScreen>(path).path("key")} label="Screen" options={ScreenKeys} required />
                </Grid>
                <Grid item xs={6} >
                    <FormSimpleSelectField name={oProps<IMenuTargetScreen>(path).path("tab")} label="Tab" options={ScreenTabs[values.key]} />
                </Grid>
                <Grid item xs={12} >
                    <StringToStringDictionaryField label="Parameters" path={oProps<IMenuTargetScreen>(path).path("params")} />
                </Grid>
                <Grid item xs={6} >
                    <Link component={ScreenLink} destination={values}>
                        {values.label}
                    </Link>
                </Grid>
            </>
        case "Dashboard":
            return <>
                <Grid item xs={6} >
                    <FormTextField name={oProps<IMenuTargetDashboard>().path("label")} label="Label" required />
                </Grid>
                <Grid item xs={6} >
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("dashboardName")} label="Layout" required />
                    {/* <FormSimpleSelectField name={oProps<IMenuTargetDashboard>().path("dashboardName")} label="Layout" options={ScreenKeys} required /> */}
                </Grid>
                <Grid item xs={6} >
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("datasetName")} label="Dataset" />
                </Grid>
                <Grid item xs={6} >
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("executionModel", "asOfDate")} label="As Of" isNumber />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("executionModel", "culture")} label="Culture" />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("executionModel", "related", "entityCode")} label="Entity Code" />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("executionModel", "related", "entityId")} label="Entity Id" isNumber />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("executionModel", "related", "portfolioCode")} label="Portfolio Code" />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("executionModel", "related", "portfolioId")} label="Portfolio Id" isNumber />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("executionModel", "related", "securityCode")} label="Security Code" />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("executionModel", "related", "securityId")} label="Security Id" isNumber />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("executionModel", "related", "relationshipId")} label="Relationship Id" isNumber />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("executionModel", "related", "processExecutionId")} label="Process Execution Id" isNumber />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetDashboard>(path).path("executionModel", "related", "processExecutionTaskCode")} label="Process Execution Task Code" />
                </Grid>
                <Grid item xs={12} >
                    <StringToStringDictionaryField path={oProps<IMenuTargetDashboard>(path).path("executionModel", "parameters")} label="Parameters" />
                </Grid>
                <Grid item xs={6} >
                    <Link component={ScreenLink} destination={values}>
                        {values.label}
                    </Link>
                </Grid>
            </>
        case "Questionnaire":
            return <>
                <Grid item xs={6} >
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>().path("label")} label="Label" required />
                </Grid>
                <Grid item xs={6} >
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>(path).path("questionnaireName")} label="Questionnaire" required />
                    {/* <FormSimpleSelectField name={oProps<IMenuTargetDashboard>().path("dashboardName")} label="Layout" options={ScreenKeys} required /> */}
                </Grid>
                <Grid item xs={6} >
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>(path).path("executionModel", "asOfDate")} label="As Of" isNumber />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>(path).path("executionModel", "culture")} label="Culture" />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>(path).path("executionModel", "related", "entityCode")} label="Entity Code" />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>(path).path("executionModel", "related", "entityId")} label="Entity Id" isNumber />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>(path).path("executionModel", "related", "portfolioCode")} label="Portfolio Code" />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>(path).path("executionModel", "related", "portfolioId")} label="Portfolio Id" isNumber />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>(path).path("executionModel", "related", "securityCode")} label="Security Code" />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>(path).path("executionModel", "related", "securityId")} label="Security Id" isNumber />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>(path).path("executionModel", "related", "relationshipId")} label="Relationship Id" isNumber />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>(path).path("executionModel", "related", "processExecutionId")} label="Process Execution Id" isNumber />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField name={oProps<IMenuTargetQuestionnaire>(path).path("executionModel", "related", "processExecutionTaskCode")} label="Process Execution Task Code" />
                </Grid>
                <Grid item xs={12} >
                    <StringToStringDictionaryField path={oProps<IMenuTargetQuestionnaire>(path).path("executionModel", "parameters")} label="Parameters" />
                </Grid>
                <Grid item xs={6} >
                    <Link component={ScreenLink} destination={values}>
                        {values.label}
                    </Link>
                </Grid>
            </>
    }
}
