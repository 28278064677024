import { Pane } from './Pane';
import { Resizer } from './Resizer';
import { CSSProperties, Children, useCallback, useEffect, useMemo, useRef, useState } from 'react';

function unFocus(document: Document, window: Window & typeof globalThis) {
  const selection = document.getSelection() ?? window.getSelection();
  if (!selection) return;
  try {
    selection.empty();
    selection.removeAllRanges()
  } finally { }
}

function getDefaultSize(defaultSize: number | undefined, minSize: number | undefined, maxSize: number | undefined, draggedSize?: number) {
  if (typeof draggedSize === 'number') {
    const min = typeof minSize === 'number' ? minSize : 0;
    const max =
      typeof maxSize === 'number' && maxSize >= 0 ? maxSize : Infinity;
    return Math.max(min, Math.min(max, draggedSize));
  }
  if (defaultSize !== undefined) {
    return defaultSize;
  }
  return minSize;
}

function removeNullChildren(children: React.ReactNode | React.ReactNode[]) {
  return Children.toArray(children).filter(c => c);
}

export interface ISplitPaneProps {
  children: React.ReactNode;
  allowResize?: boolean,
  primary?: 'first' | 'second',
  minSize?: number,
  maxSize?: number,
  defaultSize?: number,
  size?: number,
  split?: 'vertical' | 'horizontal',
  onDragStarted?: () => void,
  onDragFinished?: (size: number) => void,
  onChange?: (size: number) => void,
  onResizerClick?: () => void,
  onResizerDoubleClick?: () => void,
  style?: CSSProperties
}

export function SplitPane({
  allowResize = true,
  children,
  onResizerClick,
  onResizerDoubleClick,
  onDragStarted,
  minSize = 50,
  maxSize,
  size: sizeProps,
  primary = 'first',
  split = 'vertical',
  style: styleProps,
  onChange,
  onDragFinished,
  defaultSize }: ISplitPaneProps) {

  const initialSize = useMemo(() => sizeProps !== undefined
    ? sizeProps
    : getDefaultSize(defaultSize, minSize, maxSize), [defaultSize, maxSize, minSize, sizeProps]);

  const [pane1SizeState, setPanel1SizeState] = useState<number | undefined>(primary === 'first' ? initialSize : undefined);
  const [pane2SizeState, setPanel2SizeState] = useState<number | undefined>(primary === 'second' ? initialSize : undefined);
  const [draggedSizeState, setDraggedSizeState] = useState<number | undefined>();
  const [sizeState, setSizeState] = useState(sizeProps);
  const [activeState, setActiveState] = useState(false);
  const [positionState, setPositionState] = useState<number>(0);

  const splitPaneRef = useRef<HTMLDivElement>(null);
  const pane1Ref = useRef<HTMLDivElement>(null);
  const pane2Ref = useRef<HTMLDivElement>(null);
  const notNullChildren = removeNullChildren(children);

  const style: CSSProperties = useMemo(() => ({
    display: 'flex',
    flex: 1,
    height: '100%',
    outline: 'none',
    overflow: 'hidden',
    MozUserSelect: 'text',
    WebkitUserSelect: 'text',
    msUserSelect: 'text',
    userSelect: 'text',
    ...styleProps,
    ...(split === 'vertical'
      ? {
        flexDirection: 'row',
        left: 0,
        right: 0
      }
      : {
        bottom: 0,
        flexDirection: 'column',
        minHeight: '100%',
        top: 0,
        width: '100%'
      })
  }), [split, styleProps]);

  const resizeStart = useCallback((clientX: number, clientY: number) => {
    if (!allowResize) return;
    unFocus(document, window);
    onDragStarted && onDragStarted();
    setActiveState(true);
    setPositionState(split === 'vertical' ? clientX : clientY);
  }, [allowResize, onDragStarted, split]);

  const resizeMove = useCallback((clientX: number, clientY: number) => {
    if (!allowResize || !activeState || !pane1Ref.current || !pane2Ref.current || !splitPaneRef.current) {
      return;
    }
    unFocus(document, window);
    const pane1 = primary === 'first' ? pane1Ref.current : pane2Ref.current;
    const pane2 = primary === 'second' ? pane1Ref.current : pane2Ref.current;

    const pane1BoundingClientRect = pane1.getBoundingClientRect();
    const current = split === 'vertical' ? clientX : clientY;
    const size = split === 'vertical' ? pane1BoundingClientRect.width : pane1BoundingClientRect.height;
    const positionDelta = positionState - current;
    const pane1Order = Number(window.getComputedStyle(pane1).order);
    const pane2Order = Number(window.getComputedStyle(pane2).order);
    const sizeDelta = (primary === 'first' && pane1Order < pane2Order) ? -positionDelta : positionDelta;

    let newMaxSize = maxSize;
    if (maxSize !== undefined && maxSize <= 0) {
      const splitPane = splitPaneRef.current;
      const splitPaneBoundingClientRect = splitPane.getBoundingClientRect();
      const sizeChange = split === 'vertical' ? splitPaneBoundingClientRect.width : splitPaneBoundingClientRect.height
      newMaxSize = sizeChange + maxSize
    }

    let newSize = size - sizeDelta;

    if (newSize < minSize) {
      newSize = minSize;
    }
    else if (newMaxSize !== undefined && newSize > newMaxSize) {
      newSize = newMaxSize;
    }
    else {
      setPositionState(positionState - positionDelta);
    }

    if (onChange) onChange(newSize);

    setDraggedSizeState(newSize);
    if (primary === 'first') setPanel1SizeState(newSize);
    else setPanel2SizeState(newSize);

  }, [activeState, allowResize, maxSize, minSize, onChange, positionState, primary, split]);

  const resizeEnd = useCallback(() => {
    if (allowResize && activeState && draggedSizeState !== undefined) {
      onDragFinished && onDragFinished(draggedSizeState);
      setActiveState(false);
    }
  }, [activeState, allowResize, draggedSizeState, onDragFinished]);
  const handleMouseMove = useCallback((event: MouseEvent) => resizeMove(event.clientX, event.clientY), [resizeMove]);
  const handleMouseUp = useCallback((event: MouseEvent) => resizeEnd(), [resizeEnd]);
  const handleMouseDown = useCallback((event: MouseEvent) => resizeStart(event.clientX, event.clientY), [resizeStart]);
  const handleTouchMove = useCallback((event: TouchEvent) => resizeMove(event.touches[0].clientX, event.touches[0].clientY), [resizeMove]);
  const handleTouchStart = useCallback((event: TouchEvent) => resizeStart(event.touches[0].clientX, event.touches[0].clientY), [resizeStart]);
  const handleTouchEnd = useCallback((event: TouchEvent) => resizeEnd(), [resizeEnd]);

  useEffect(() => {
    if (sizeState !== sizeProps || sizeProps !== undefined) {
      const newSize =
        sizeProps !== undefined
          ? sizeProps
          : getDefaultSize(defaultSize, minSize, maxSize, draggedSizeState);

      if (sizeProps !== undefined) {
        setDraggedSizeState(newSize);
      }

      setPanel1SizeState(primary === 'first' ? newSize : undefined);
      setPanel2SizeState(primary === 'second' ? newSize : undefined);

      setSizeState(sizeProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('touchmove', handleTouchMove);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
    }
  }, [handleMouseMove, handleMouseUp, handleTouchMove]);

  return <div ref={splitPaneRef} style={style}>
    <Pane eleRef={pane1Ref} split={split} size={pane1SizeState}>
      {notNullChildren[0]}
    </Pane>
    <Resizer
      onClick={onResizerClick}
      onDoubleClick={onResizerDoubleClick}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      key="resizer"
      split={split} />
    <Pane eleRef={pane2Ref} split={split} size={pane2SizeState}>
      {notNullChildren[1]}
    </Pane>
  </div>
}
