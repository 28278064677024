import SearchPanel from "tools/components/SearchPanel";
import { IFeatureModel, IManagedShareClassModel, IShareClassSummaryModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { Formik, FormikHelpers, FormikProps } from "formik";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps, toList } from "tools/lib/utility";
import { Grid } from "@material-ui/core";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import ClassificationsData, { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import { useScreenNavigate } from "../../tools/routing/screenRouteHooks";
import { useCallback } from "react";

export const myShareClassesScreen = {
    route: "/portfolios/myshareclasses",
    component: MyShareClasses,
    label: "My ShareClasses",
    keywords: "management",
}

interface ISearchSecurityForm {
    criterias?: string;
    classifications?: {
        [key: string]: number;
        [key: number]: number;
    };
}

function MyShareClasses() {
    const {
        referenceCurrencies = {},
        referenceCountries = {},
    } = useReduxSelections("reference");
    const {
        all: securities,
        allLoading: securityAllLoading,
        dictionaries: {
            subFunds
        }
    } = useReduxSelections("managedShareClass");
    const { shareClassSearch } = useReduxActions("managedShareClass");
    const {
        classificationTypes,
        classificationTypesFlat
    } = useReduxSelections("securityClassificationType");
    const navigate = useScreenNavigate()
    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, (secu: IShareClassSummaryModel) => secu.classifications);

    const columns: IColumnDefinition[] = [
        { name: "Name", title: "Name", getCellValue: (row: IShareClassSummaryModel) => row.name, positionToKeep: "left", filteringEnabled: true },
        { name: "InternalCode", title: "Internal Code", getCellValue: (row: IShareClassSummaryModel) => row.internalCode, filteringEnabled: true },
        { name: "Currency", title: "Currency", getCellValue: (row: IShareClassSummaryModel) => row.currencyId && referenceCurrencies[row.currencyId]?.isoCode },
        {
            name: "Country", title: "Country", getCellValue: (secu: IShareClassSummaryModel) => {
                let countryId: number | undefined;
                if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
                    if (subFunds[secu.subFundId].countryId) {
                        countryId = subFunds[secu.subFundId].countryId
                    }
                }
                if (!countryId) {
                    return undefined;
                }
                const country = referenceCountries[countryId];
                if (!country) {
                    return "???";
                }
                return country.name?.en;
            }
        },
        { name: "Isin", title: "Isin", getCellValue: (secu: IShareClassSummaryModel) => secu.isin, filteringEnabled: true },
        ...classificationTypesColumns
    ];
    const state: IGridState = {
        "Name": { width: 280 },
        "InternalCode": { width: 150 },
        "Currency": { width: 130 },
        "Country": { width: 170 },
        "Isin": { width: 150 },
        "Type": { width: 120 },
        ...classificationTypesState
    };
    const handleRowSelect = useCallback((row: IShareClassSummaryModel | IManagedShareClassModel) =>
        navigate("MyShareClass", { id: row.id }),
        [navigate])

    const handleAddNewClick = useCallback(() => navigate("MyShareClass", { id: 0 }),
        [navigate])

    const getRowKey = (row: IShareClassSummaryModel | IManagedShareClassModel) => row.id || 0;
    const handleSubmit = ({ classifications, ...values }: ISearchSecurityForm, { setSubmitting }: FormikHelpers<ISearchSecurityForm>) => {
        const classificationIds = toList(classifications);
        shareClassSearch({ ...values, classificationIds });
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={{} as ISearchSecurityForm} enableReinitialize={true} validateOnMount={true}  >{renderForm}</Formik>;

    function renderForm({ isValid, submitForm }: FormikProps<ISearchSecurityForm>) {
        return <SearchPanel
            isQuerying={securityAllLoading}
            addAllowed={IFeatureModel.ManagedPortfolioWrite}
            onSearchClick={submitForm}
            searchDisabled={!isValid}
            subTitle="Search shareclasses"
            title="ShareClasses"
            onAddClick={handleAddNewClick}
            renderSearch={<Grid container={true} spacing={1}>
                <Grid item={true} sm={12} md={6} lg={4}>
                    <FormTextField name={oProps<ISearchSecurityForm>().path("criterias")} label="Keywords (name, ISIN, internal code)" />
                </Grid>
                <Grid item={true} sm={12} md={12} lg={12}>
                    <ClassificationsData panelTitle="Classifications" fieldName={oProps<ISearchSecurityForm>().path("classifications")} classificationTypes={classificationTypes} />
                </Grid>
            </Grid>}>
            <ExtendedGrid
                rows={securities}
                columns={columns}
                getRowId={getRowKey}
                initialState={state}
                onRowClick={handleRowSelect}
                defaultExportFileName="ShareClasses.xlsx"
                userCanGroup={true} />
        </SearchPanel>
    }
}
