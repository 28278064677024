import { Epic } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";
import { processDefinitionsApi } from "proxy/apiProxy";

export const processDefinitionLookupLoad: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processDefinitionLookup", "processDefinitionLookupLoad"),
        mergeMap(() => processDefinitionsApi.getAllAsync({})),
        map(ActionFactories.processDefinitionLookup.processDefinitionLookupLoaded));
