import produce from "immer";
import { ICurrencyModel, IFxValueModel, IHistoricalValueModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export type VisualizationType = "GRID" | "CHART";

export interface IFxRateSourcePayload {
    targetCurrency: ICurrencyModel;
    historicalValues: IHistoricalValueModel[];
}

export interface IState {
    fxSaveHistoricalValueSaving: boolean;
    currentTargetCurrency?: IFxRateSourcePayload;
    fxRatesHistoricalLoading: boolean;
    fxRatesHistoricalValuesVisualizationType: VisualizationType;
}
export interface ISaveHistoricalValuePayload {
    currencyToId: number;
    fxValue: IFxValueModel;
}
export const ActionFactories = produceActionFactories({
    fxRatesHistoricalValuesLoad: (payload: number) => payload,
    fxRatesHistoricalValuesLoaded: (payload: IFxRateSourcePayload) => payload,
    fxRatesHistoricalValuesVisualizationTypeChanged: (payload: VisualizationType) => payload,
    fxRatesHistoricalValueSave: (payload: ISaveHistoricalValuePayload) => payload,
    fxRatesHistoricalValueSaved: (payload: ISaveHistoricalValuePayload) => payload,
});
export function reducer(
    state: IState = {
        fxSaveHistoricalValueSaving: false,
        fxRatesHistoricalLoading: false,
        fxRatesHistoricalValuesVisualizationType: "GRID"
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "fxRatesHistoricalValuesLoaded":
                draft.currentTargetCurrency = action.payload;
                draft.fxRatesHistoricalLoading = false;
                break;
            case "fxRatesHistoricalValuesLoad":
                draft.currentTargetCurrency = undefined;
                draft.fxRatesHistoricalLoading = true;
                break;
            case "fxRatesHistoricalValuesVisualizationTypeChanged":
                draft.fxRatesHistoricalValuesVisualizationType = action.payload;
                break;

            case "fxRatesHistoricalValueSave":
                draft.fxSaveHistoricalValueSaving = true;
                break;
            case "fxRatesHistoricalValueSaved":
                draft.fxSaveHistoricalValueSaving = false;
                if (draft.currentTargetCurrency) {
                    const hvIndex = draft.currentTargetCurrency.historicalValues.findIndex(i => i.date === action.payload.fxValue.date);
                    if (hvIndex >= 0) {
                        const rateFromReferenceCurrency = action.payload.fxValue.rateFromReferenceCurrency;
                        if (!rateFromReferenceCurrency) {
                            draft.currentTargetCurrency.historicalValues.splice(hvIndex, 1);
                        }
                        else {
                            draft.currentTargetCurrency.historicalValues[hvIndex].value = rateFromReferenceCurrency;
                        }
                    }
                }
                break;
        }
    });
}
