import * as React from "react";
import { IExecuteModel, IExecuteReportModel, IFileTypeModel, IGenerateDatasetModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { DevelopmentItemParametersDialog } from "./DevelopmentItemParametersDialog";
import DialogPanel from "tools/components/DialogPanel";
import { SurveyRunnerDialog, SurveyRunnerResultHandler } from "tools/components/SurveyRunnerDialog";
import { IDevelopmentItemRefs } from "../DevelopmentItemForm";
import { Grid } from "@material-ui/core";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import { oProps } from "tools/lib/utility";
import ReactJson from "react-json-view";
import { DashboardRunner } from "tools/objectViewer/DashboardRunner";
import FormTextField from "tools/fieldComponents/FormTextField";
import { IExecuteEtl } from "../slice";
import FormDropZoneField from "tools/fieldComponents/FormDropZoneField";
export interface IDevelopmentItemExecutionProps {
    executionState: ExecutionState | undefined;
    onChangeExecutionState: (executionState: ExecutionState | undefined) => void;
    refs: IDevelopmentItemRefs;
}
export enum ExecutionState {
    FillDatasetParameters,
    ShowDatasetResult,
    FillQuestionnaireParameters,
    FillQuestionnaireAnswers,
    ShowQuestionnaireResult,
    FillEtlParameters,
    FillDashboardPreviewParameters,
    FillReportPreviewParameters,
    FillReportRunParameters,
}
export function DevelopmentItemExecution({ executionState, onChangeExecutionState, refs: { questionnaireLoadMacroRef, questionnaireTemplateRef, questionnaireCompleteMacroRef, reportTemplateRef, etlMacroRef, datasetMacroRef } }: IDevelopmentItemExecutionProps) {
    const {
        developmentItemQuestionnaireExecuteOnLoad,
        developmentItemReportExecuteOnLoad,
        developmentItemReportGenerate,
        developmentItemQuestionnaireExecuteOnComplete,
        developmentItemDashboardExecuteOnLoad,
        developmentItemDatasetGenerate,
        developmentItemEtlExecute
    } = useReduxActions("developmentItem");
    const { developmentItemExecutionResults } = useReduxSelections("developmentItem");
    const [questionnaireTemplate, setQuestionnaireTemplate] = React.useState<string>();
    const [executionParameters, setExecutionParameters] = React.useState<IExecuteModel>({});
    const handleQuestionnaireCompleted = React.useCallback<SurveyRunnerResultHandler>((response) => {
        const onCompleteMacroValue = questionnaireCompleteMacroRef.current.getValue();
        if (executionParameters && onCompleteMacroValue) {
            developmentItemQuestionnaireExecuteOnComplete({
                type: "ExecuteQuestionnaireOnCompleteModel",
                questionnaireTemplate: questionnaireTemplateRef.current.getValue() ?? "{}",
                responseData: response.data,
                responsePlainData: response.plainData,
                ...onCompleteMacroValue,
                ...executionParameters
            });
            onChangeExecutionState(ExecutionState.ShowQuestionnaireResult);
        }
    }, [developmentItemQuestionnaireExecuteOnComplete, executionParameters, onChangeExecutionState, questionnaireCompleteMacroRef, questionnaireTemplateRef]);
    const handleCloseExecution = React.useCallback(() => onChangeExecutionState(undefined), [onChangeExecutionState]);
    const handleValidatedParameters = React.useCallback((executeModel: IExecuteModel) => {
        switch (executionState) {
            case ExecutionState.FillQuestionnaireParameters: {
                const loadMacro = questionnaireLoadMacroRef.current.getValue();
                if (loadMacro) {
                    developmentItemQuestionnaireExecuteOnLoad({ ...executeModel, type: "ExecuteOnLoadModel", ...loadMacro });
                    setQuestionnaireTemplate(questionnaireTemplateRef.current.getValue() ?? "{}");
                    onChangeExecutionState(ExecutionState.FillQuestionnaireAnswers);
                }
                break;
            }
            case ExecutionState.FillReportRunParameters: {
                const template = reportTemplateRef.current.getValue() ?? "{}";
                const executeReportModel = (executeModel as Omit<IExecuteReportModel, "template" | "type">);
                developmentItemReportGenerate({ ...executeReportModel, template, type: "ExecuteReportModel" });
                onChangeExecutionState(undefined);
                break;
            }
            case ExecutionState.FillReportPreviewParameters: {
                const generateDatasetModel = (executeModel as Omit<IGenerateDatasetModel, "type">);
                developmentItemReportExecuteOnLoad({ ...generateDatasetModel, type: "GenerateDatasetModel" });
                onChangeExecutionState(undefined);
                break;
            }
            case ExecutionState.FillEtlParameters: {
                const etlMacro = etlMacroRef.current.getValue();
                if (etlMacro) {
                    developmentItemEtlExecute({ ...executeModel, type: "ExecuteEtlModel", ...etlMacro });
                }
                onChangeExecutionState(undefined);
                break;
            }
            case ExecutionState.FillDatasetParameters: {
                // developmentItemDatasetGenerate
                const datasetMacro = datasetMacroRef.current.getValue();
                if (datasetMacro) {
                    developmentItemDatasetGenerate({ ...executeModel, type: "ExecuteOnLoadModel", ...datasetMacro });
                    onChangeExecutionState(ExecutionState.ShowDatasetResult);
                }
                break;
            }
            case ExecutionState.FillDashboardPreviewParameters: {
                const generateDatasetModel = (executeModel as Omit<IGenerateDatasetModel, "type">);
                developmentItemDashboardExecuteOnLoad({ ...generateDatasetModel, type: "GenerateDatasetModel" });
                onChangeExecutionState(undefined);
                break;
            }
        }
        setExecutionParameters(executeModel);
    }, [datasetMacroRef, developmentItemDashboardExecuteOnLoad, developmentItemDatasetGenerate, developmentItemEtlExecute, developmentItemQuestionnaireExecuteOnLoad, developmentItemReportExecuteOnLoad, developmentItemReportGenerate, etlMacroRef, executionState, onChangeExecutionState, questionnaireLoadMacroRef, questionnaireTemplateRef, reportTemplateRef]);
    if (typeof executionState === "undefined") {
        return null;
    }
    switch (executionState) {
        case ExecutionState.FillDashboardPreviewParameters:
            return <DevelopmentItemParametersDialog initialParameters={executionParameters} onCancel={handleCloseExecution} onValidated={handleValidatedParameters} >
                <Grid item xs={12}>
                    <FormTextField name={oProps<IGenerateDatasetModel>().path("datasetName")} label="Dataset name" />
                </Grid>
            </DevelopmentItemParametersDialog>
        case ExecutionState.FillDatasetParameters:
            return <DevelopmentItemParametersDialog initialParameters={executionParameters} onCancel={handleCloseExecution} onValidated={handleValidatedParameters} />
        case ExecutionState.FillEtlParameters:
            return <DevelopmentItemParametersDialog initialParameters={executionParameters} onCancel={handleCloseExecution} onValidated={handleValidatedParameters} >
                <Grid item xs={12}>
                    <FormDropZoneField
                        name={oProps<IExecuteEtl>().path("zipFileName")}
                        contentName={oProps<IExecuteEtl>().path("zipFileContent")}
                        label="Drop the zip file here"
                        emptyValue={null}
                        minLength={1} />
                </Grid>
            </DevelopmentItemParametersDialog>
        case ExecutionState.FillQuestionnaireParameters:
            return <DevelopmentItemParametersDialog initialParameters={executionParameters} onCancel={handleCloseExecution} onValidated={handleValidatedParameters} />
        case ExecutionState.FillQuestionnaireAnswers:
            return <SurveyRunnerDialog onCancel={handleCloseExecution} template={questionnaireTemplate} initialValues={developmentItemExecutionResults.questionnaireLoadedAnswers} onComplete={handleQuestionnaireCompleted} />
        case ExecutionState.FillReportPreviewParameters:
            return <DevelopmentItemParametersDialog initialParameters={executionParameters} onCancel={handleCloseExecution} onValidated={handleValidatedParameters} >
                <Grid item xs={6}>
                    <FormTextField name={oProps<IGenerateDatasetModel>().path("datasetName")} label="Dataset name" required />
                </Grid>
            </DevelopmentItemParametersDialog>
        case ExecutionState.FillReportRunParameters:
            return <DevelopmentItemParametersDialog initialParameters={executionParameters} onCancel={handleCloseExecution} onValidated={handleValidatedParameters} >
                <Grid item xs={6}>
                    <FormTextField name={oProps<IExecuteReportModel>().path("datasetName")} label="Dataset name" />
                </Grid>
                <Grid item xs={6}>
                    <FormSimpleSelectField name={oProps<IExecuteReportModel>().path("exportType")} label="Export type" options={IFileTypeModel} required />
                </Grid>
            </DevelopmentItemParametersDialog>
        case ExecutionState.ShowDatasetResult:
            return <DialogPanel onBackClick={handleCloseExecution} maxWidth="lg">
                <ReactJson
                    style={{ width: 600 }}
                    name={false}
                    collapsed={1}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    src={developmentItemExecutionResults.datasetResult ?? {}} />
                {/* {developmentItemExecutionResults.datasetResult?.type === "SuccessDatasetResultModel" &&
                    <DashboardRunner value={developmentItemExecutionResults.datasetResult?.result ?? {}} />}
                {developmentItemExecutionResults.datasetResult?.type !== "SuccessDatasetResultModel" &&
                    <ReactJson
                        style={{ width: 600 }}
                        name={false}
                        collapsed={1}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        src={developmentItemExecutionResults.datasetResult ?? {}} />} */}
            </DialogPanel>
        case ExecutionState.ShowQuestionnaireResult:
            return <DialogPanel onBackClick={handleCloseExecution} maxWidth="lg">
                {developmentItemExecutionResults.questionnaireLoadedResult?.type === "SuccessDatasetResultModel" &&
                    <DashboardRunner value={developmentItemExecutionResults.questionnaireLoadedResult?.result ?? {}} />}
                {developmentItemExecutionResults.questionnaireLoadedResult?.type !== "SuccessDatasetResultModel" &&
                    <ReactJson
                        style={{ width: 600 }}
                        name={false}
                        collapsed={1}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        src={developmentItemExecutionResults.questionnaireLoadedResult ?? {}} />}
            </DialogPanel>
    }
}
