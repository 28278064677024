import {
    EntitySummaryModel, INonPersonEntityModel
} from "proxy/apiProxy";
import { Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, Typography, Radio } from "@material-ui/core";
import FieldBox from "tools/components/FieldBox";
import { oProps } from "tools/lib/utility";
import { IGetEntitySummary } from "features/Reference/slice";
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { useCallback } from "react";
import EntitySearch from "components/searchers/EntitySearch";
import CloseIcon from '@material-ui/icons/Close';
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import { getEntityTypeLabel } from "./getEntityTypeLabel";

export interface IEntityListProps {
    formFieldName: string;
    onEntityLoaded: (found: IGetEntitySummary) => void;
    onEntitySelected: (id: number) => void;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
    }
}
export default function EntityList({ formFieldName, dictionaries, onEntityLoaded, onEntitySelected }: IEntityListProps) {
    const [, { value: mainContactId }, { setValue: setMainContactIdValue }] = useField<INonPersonEntityModel["mainContactId"]>(oProps<INonPersonEntityModel>(formFieldName).path("mainContactId"));
    const entitiesIdField = oProps<INonPersonEntityModel>(formFieldName).path("entitiesId");
    const [{ value: entitiesId }] = useField<INonPersonEntityModel["entitiesId"]>(entitiesIdField);
    return <FieldArray name={oProps<INonPersonEntityModel>(formFieldName).path("entitiesId")} validateOnChange={true} >
        {arrayProps => <EntitiesArray
            arrayProps={arrayProps}
            dictionaries={dictionaries}
            entitiesId={entitiesId ?? []}
            onEntityLoaded={onEntityLoaded}
            onEntitySelected={onEntitySelected}
            onSetMainContactId={setMainContactIdValue}
            mainContactId={mainContactId} />}
    </FieldArray>
}
interface IEntitiesArrayProps {
    arrayProps: FieldArrayRenderProps;
    entitiesId: number[];
    onEntityLoaded: (found: IGetEntitySummary) => void;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
    };
    onEntitySelected: (id: number) => void;
    onSetMainContactId: (entityId: number) => void;
    mainContactId?: number;
}
function EntitiesArray({
    arrayProps: { push, remove, form: { validateForm } }, entitiesId, onEntityLoaded, dictionaries, onEntitySelected, onSetMainContactId, mainContactId }: IEntitiesArrayProps) {
    const handleEntitySelected = (found: IGetEntitySummary) => {
        onEntityLoaded(found);
        push(found.entity.id);
    }
    const handleRemove = (positionIndex: number) => {
        remove(positionIndex);
        setTimeout(() => validateForm(), 0);
    }

    return (<ReadOnlyContext.Consumer>
        {readOnly => <>
            {!entitiesId.length && <Typography>No entity</Typography>}
            {!!entitiesId.length && <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell>Is Main Contact</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Internal Code</TableCell>
                        <TableCell>Name</TableCell>
                        {!readOnly && <TableCell />}
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {entitiesId.map((entityId, idx) => <EntityLine
                        key={entityId}
                        entityId={entityId}
                        dictionaries={dictionaries}
                        onClick={onEntitySelected}
                        onDelete={handleRemove}
                        positionIndex={idx}
                        readOnly={readOnly}
                        mainContactId={mainContactId}
                        onSetMainContact={onSetMainContactId}
                    />)}
                </TableBody>
            </Table>}
            {!readOnly && <FieldBox display="flex" flexDirection="row">
                <EntitySearch label="Add an entity..." onSelected={handleEntitySelected} />
            </FieldBox>}
        </>}
    </ReadOnlyContext.Consumer>);
}
interface IEntityLineProps {
    entityId: number;
    mainContactId?: number;
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
    }
    onClick: (entityId: number) => void;
    onDelete: (positionIndex: number) => void;
    positionIndex: number;
    readOnly: boolean;
    onSetMainContact: (entityId: number) => void;
}
function EntityLine({
    entityId,
    mainContactId,
    dictionaries: { entities },
    onClick,
    onDelete,
    positionIndex,
    readOnly,
    onSetMainContact
}: IEntityLineProps) {
    const handleClick = useCallback(() => onClick(entityId), [entityId, onClick]);
    const handleDelete = useCallback(() => onDelete(positionIndex), [onDelete, positionIndex]);
    const handleSetMainContact = useCallback(() => onSetMainContact(entityId), [entityId, onSetMainContact]);
    const entity = entities[entityId];

    if (!entity) {
        return null;
    }
    return <TableRow>
        <TableCell><Radio readOnly={readOnly} disabled={readOnly} checked={entityId === mainContactId} onChange={handleSetMainContact} /></TableCell>
        <TableCell>{getEntityTypeLabel(entity.type)}</TableCell>
        <TableCell>{entity.internalCode}</TableCell>
        <TableCell>{entity.type === "PersonSummaryModel" ? `${entity.firstName} ${entity.lastName}` : entity.name}</TableCell>
        {!readOnly && <TableCell align="right">
            <Tooltip title="Unlink">
                <IconButton
                    size="small"
                    aria-label="Unlink"
                    style={{ verticalAlign: "middle" }}
                    onClick={handleDelete} >
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </TableCell>}
        <TableCell align="right">
            <Tooltip title="Open">
                <IconButton
                    size="small"
                    aria-label="Open"
                    style={{ verticalAlign: "middle" }}
                    onClick={handleClick} >
                    <OpenInBrowser />
                </IconButton>
            </Tooltip>
        </TableCell>
    </TableRow>
}