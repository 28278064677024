import * as React from "react";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Paper, LinearProgress, Tooltip, Fab } from "@material-ui/core";
import FabContainer, { IFabContainerProps, getButtonNumber } from "./FabContainer";
import styled from '@emotion/styled';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import { ScreenObjTab } from "../routing/screenRouteHooks";
import { useCurrentScreenMenuState } from "features/App/menuTools";
import { Badge, IconButton, Tab, Tabs, Typography } from "@material-ui/core";
import ListAltIcon from '@material-ui/icons/ListAlt';
import { IScreenMenuState } from "features/App/slice";
import { ScreenLink } from "tools/routing/ScreenLink";

const DetailWrapper = styled.div(({ fullScreen = false }: { fullScreen?: boolean }) => {
    if (!fullScreen) {
        return {
            position: "relative",
            width: "100%",
            height: "100%",
            borderRadius: 5,
            // borderStyle: "solid",
            // borderWidth: 1,
            // borderColor: "lightgray"
        };
    }
    else {
        return {
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1250,
            right: 0,
            bottom: 0
        };
    }
})

const useStyles = makeStyles(theme => createStyles({
    detailTitle: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        gridColumnGap: theme.spacing(1),
        alignItems: "center",
        display: "flex"
    },
    subDetailTitle: {
        padding: 0,
        gridColumnGap: theme.spacing(2),
        alignItems: "baseline",
        display: "flex"
    },
    tabContainer: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        display: "flex",
        flexWrap: "nowrap",
    },
    detailPaper: {
        height: "100%",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column"
    },
    detailContent: {
        padding: theme.spacing(2),
        flexGrow: 1,
        overflowY: "auto"
    },
    detailContentNoPadding: {
        padding: 0,
        flexGrow: 1,
        overflowY: "auto"
    },
}));

export interface IHeaderTitleProps {
    title: string;
    endAdornment?: React.ReactNode;
    badge?: string;
    subTitle?: string;
    screen?: IScreenMenuState;
    tabs?: ISubMenuTabs[];
    tabValue?: any;
    spaceAfterTabs?: number;
    onTabValueChanged?: (value: any) => void;
    // actionButtons?: IFabContainerProps;
}

function TitleLine({ title, badge, endAdornment }: {
    title: IHeaderTitleProps["title"];
    badge?: IHeaderTitleProps["badge"];
    endAdornment?: IHeaderTitleProps["endAdornment"];
}) {
    if (!title) return <></>;

    let titleContent = <Typography variant="h6" color="inherit" noWrap>{title}</Typography>;

    if (badge) {
        titleContent = <Badge badgeContent={badge} color="secondary">{titleContent}</Badge>;
    }

    if (endAdornment) {
        titleContent = <Box display="flex" alignItems="baseline" gridGap={8}>{titleContent}{endAdornment}</Box>;
    }

    return titleContent;
}

function HeaderTitle({ badge, title, subTitle, screen, tabs, tabValue, onTabValueChanged, spaceAfterTabs, endAdornment }: IHeaderTitleProps) {
    const classes = useStyles();
    const handleTabChanged = (_event: React.ChangeEvent<{}>, newValue: any) => {
        if (onTabValueChanged) {
            onTabValueChanged(newValue);
        }
    }

    return <Box display="flex" flexDirection="column">
        <Box className={classes.detailTitle} >
            {(!!screen?.parent) &&
                <IconButton component={ScreenLink} destination={screen?.parent}> <ListAltIcon /> </IconButton>
            }
            <Box className={classes.subDetailTitle}>
                <TitleLine title={title} badge={badge} endAdornment={endAdornment} />
                {(!!subTitle) && <Typography variant="subtitle1" color="inherit" noWrap={true}>
                    {subTitle}
                </Typography>}
            </Box>
        </Box>
        {(tabs && screen) && <Box className={classes.tabContainer}>
            <Tabs style={{ flexGrow: 1 }}
                value={tabValue}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleTabChanged}>
                {tabs.map((tab, idx) => (<Tab component={ScreenLink} destination={{ ...screen, tab: tab.value ? String(tab.value) : undefined }} key={idx} {...tab} />))}
            </Tabs>
            {!!spaceAfterTabs && <div style={{ width: spaceAfterTabs }} />}
        </Box>
        }
    </Box>;
}

const TopRightDiv = styled.div({
    position: "absolute",
    top: 8,
    right: 8,
    zIndex: 9999999
});


export interface ISubMenuTabs<Screen = any> {
    icon?: string | React.ReactElement;
    label: React.ReactNode;
    value: ScreenObjTab<Screen>;
}

export interface IDetailContainerProps extends IFabContainerProps {
    endAdornment?: React.ReactNode;
    title?: string;
    badge?: string;
    subTitle?: string;
    // onBackClick?: () => void;
    tabs?: ISubMenuTabs[];
    tabValue?: any;
    noPadding?: boolean;
    onTabValueChanged?: (value: any) => void;
    // actionButtons?: IFabContainerProps;
    isQuerying?: boolean;
}

function InnerDetailPanel({ onTabValueChanged, badge, children, title, subTitle
    , tabs, tabValue, isQuerying, endAdornment, noPadding = false, ...actionButtons }: React.PropsWithChildren<IDetailContainerProps>) {
    const currentScreen = useCurrentScreenMenuState ();

    const [fullScreen, setFullScreen] = React.useState(false);
    const handleTabChanged = (newValue: any) => {
        if (onTabValueChanged) {
            onTabValueChanged(newValue);
        }
    }
    const handleFullScreenSwitch = () => setFullScreen(v => !v);
    const actionButtonCount = React.useMemo(() => getButtonNumber(actionButtons), [actionButtons]);
    const classes = useStyles();

    return <DetailWrapper fullScreen={fullScreen}><Paper className={classes.detailPaper}>
        <TopRightDiv>
            <Tooltip title="Switch to full screen">
                <Fab size="small" style={{ width: 25, height: 25, minHeight: 25 }} onClick={handleFullScreenSwitch}>
                    <SettingsOverscanIcon fontSize="small" style={{ fontSize: "1rem" }} />
                </Fab>
            </Tooltip>
        </TopRightDiv>
        <DetailProgress isQuerying={isQuerying} />
        <HeaderTitle
            title={title ?? currentScreen?.label ?? ""}
            badge={badge}
            subTitle={subTitle}
            screen={currentScreen}
            tabs={tabs}
            tabValue={tabValue}
            endAdornment={endAdornment}
            onTabValueChanged={handleTabChanged}
            spaceAfterTabs={80 * actionButtonCount + 10}
        />
        {(!!Object.values(actionButtons).filter(i => i).length) && <FabContainer {...actionButtons} />}
        <Box className={noPadding ? classes.detailContentNoPadding : classes.detailContent} flexGrow="1" overflow="auto" height="100%" position="relative">
            {children}
        </Box>
    </Paper>
    </DetailWrapper>
}
const DetailPanel = React.memo(InnerDetailPanel);
export default DetailPanel;
interface IDetailProgressProps {
    isQuerying: boolean | undefined;
}
function DetailProgress({ isQuerying }: IDetailProgressProps) {
    return <LinearProgress hidden={!isQuerying} variant="query" />;
}
