import tradeDates from "immer";
import {
    EntitySummaryModel, IPortfolioComplianceResultModel, PortfolioSummaryModel, SecuritySummaryModel,
    IPositionModel, IGetPositionsModel, ISubFundSummaryModel, ITradeDateSummaryModel, ITradeDateModel, IGetTradeDateModel, ITradeStatusModel, IGetPortfoliosModel, TradeBookTradeModel, ITradeDatesGetGenerationParameters, IGetComputedTrades
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetSecuritySummary } from "features/Reference/slice";

export type IPosition = ICurrencyPosition | IPositionModel;
export interface ICurrencyPosition extends Omit<IPositionModel, "securityId"> {
    currencyId: number;
}
export interface IComposition {
    positions: IPosition[];
    cash: number;
    aum: number;
}
export interface IComplianceChecksFailed {
    failureDetail: string;
}
export interface IState {
    all?: ITradeDateSummaryModel[];
    dictionaries: {
        entities: Record<string | number, EntitySummaryModel>;
        portfolios: Record<string | number, PortfolioSummaryModel>;
        subFunds: Record<string | number, ISubFundSummaryModel>;
        securities: Record<string | number, SecuritySummaryModel>;
    };
    portfolios: PortfolioSummaryModel[];
    current?: ITradeDateModel; // & { guid?: string };
    compositions: {
        [portfolioId: number]: IComposition;
    };
    allLoading: boolean;
    currentLoading: boolean;
    saving: boolean;
    checking: boolean;
    checks?: IPortfolioComplianceResultModel[] | IComplianceChecksFailed;
    submitting: boolean;
    gettingConfirmations: boolean;
    tradesGenerating: boolean;
    tradesGenerated?: TradeBookTradeModel[];
}

export const ActionFactories = produceActionFactories({
    tradeDateAllLoad: () => undefined,
    tradeDateAllLoaded: (payload: ITradeDateSummaryModel[]) => payload,
    tradeDateSubmit: (payload: Date) => payload,
    tradeDateSubmitted: (payload: Date) => payload,
    tradeDateConfirmationsGet: (payload: Date) => payload,
    tradeDateConfirmationsGot: (payload: Date) => payload,
    tradeDateLoad: (payload: Date) => payload,
    tradeDateLoaded: (payload: IGetTradeDateModel) => payload,
    tradeDateGenerationLoad: (payload: ITradeDatesGetGenerationParameters) => payload,
    tradeDateGenerationLoaded: (payload: IGetComputedTrades) => payload,
    tradeDateCheck: (payload: Date) => payload,
    tradeDateChecked: (payload: IPortfolioComplianceResultModel[] | IComplianceChecksFailed) => payload,
    tradeDateSave: (payload: ITradeDateModel) => payload,
    tradeDateSaved: (payload: ITradeDateModel) => payload,
    tradeDateAddSecurityInDictionary: (payload: IGetSecuritySummary) => payload,
    tradeDateCompositionsLoaded: (payload: IGetPositionsModel) => payload,
    tradePortfoliosLoaded: (payload: IGetPortfoliosModel) => payload,
});
// const tradeStatusWeight: Record<ITradeStatusModel, number> = {
//     [ITradeStatusModel.New]: 0,
//     [ITradeStatusModel.CheckedAndSending]: 1,
//     [ITradeStatusModel.CheckedAndSent]: 2,
//     [ITradeStatusModel.Reconciling]: 3,
//     [ITradeStatusModel.Reconciled]: 4,
//     [ITradeStatusModel.Settled]: 5,
// }
// export function getTradeStatus(trades: TradeBookTradeModel[]) {
//     return trades.reduce((a, v) => {
//         a[v.status] = true;
//         return a;
//     }, {} as { [status: string]: boolean })
// }
export const reducer = (
    state: IState = {
        all: [],
        dictionaries: {
            entities: {},
            portfolios: {},
            securities: {},
            subFunds: {}
        },
        compositions: {},
        allLoading: false,
        tradesGenerating: false,
        checking: false,
        currentLoading: false,
        saving: false,
        submitting: false,
        gettingConfirmations: false,
        portfolios: []
    },
    action: AnyActionOf<typeof ActionFactories>
) => tradeDates(state, draft => {
    switch (action.type) {
        case "tradeDateGenerationLoad":
            draft.tradesGenerating = true;
            break;
        case "tradeDateGenerationLoaded":
            draft.tradesGenerated = action.payload.trades;
            draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
            draft.tradesGenerating = false;
            break;
        case "tradePortfoliosLoaded":
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.portfolios = action.payload.portfolios;
            break;
        case "tradeDateSubmit":
            draft.submitting = true;
            break;
        case "tradeDateSubmitted":
            draft.submitting = false;
            break;
        case "tradeDateConfirmationsGet":
            draft.gettingConfirmations = true;
            break;
        case "tradeDateConfirmationsGot":
            draft.gettingConfirmations = false;
            break;
        case "tradeDateAllLoad":
            draft.allLoading = true;
            break;
        case "tradeDateAllLoaded":
            draft.dictionaries = {
                entities: {},
                portfolios: {},
                securities: {},
                subFunds: {}
            };
            draft.allLoading = false;
            draft.all = action.payload;
            break;
        case "tradeDateLoad":
            draft.currentLoading = true;
            draft.current = undefined;
            draft.tradesGenerated = undefined;
            draft.checks = undefined;
            break;
        case "tradeDateLoaded":
            draft.currentLoading = false;
            draft.current = action.payload.tradeBookData;
            // draft.current.guid = Uuid.v1();
            draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
            draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
            break;
        case "tradeDateAddSecurityInDictionary":
            draft.dictionaries.securities[action.payload.security.id] = action.payload.security;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
            break;
        case "tradeDateCheck":
            draft.checking = true;
            break;
        case "tradeDateChecked":
            draft.checking = false;
            draft.checks = action.payload;
            break;
        case "tradeDateSave":
            draft.saving = true;
            break;
        case "tradeDateSaved":
            draft.saving = false;
            draft.tradesGenerated = undefined;
            draft.current = action.payload;
            if (draft.all) {
                const tradeDate: ITradeDateSummaryModel = {
                    date: action.payload.date,
                    statuses: action.payload.trades.reduce((a, v) => {
                        a[v.status] = (a[v.status] ?? 0) + 1;
                        return a;
                    }, {} as Record<ITradeStatusModel, number>)
                    // nbTrades: action.payload.trades.length,
                    // status: getTradeStatus(action.payload.trades)
                };
                draft.all.push(tradeDate);
            }
            break;
        case "tradeDateCompositionsLoaded":
            const { positions, ...dictionaries } = action.payload;
            draft.dictionaries.securities = { ...draft.dictionaries.securities, ...dictionaries.securities };
            draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...dictionaries.portfolios };
            draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...dictionaries.subFunds };
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...dictionaries.entities };
            draft.compositions = positions
                .sort(({ securityId: a }, { securityId: b }) => draft.dictionaries.securities[a]?.name > draft.dictionaries.securities[b]?.name ? 1 : -1)
                .reduce((a, v) => {
                    const key = v.portfolioId;
                    // if(dictionaries.portfolios[key].)
                    let ts = a[key];
                    if (!ts) {
                        ts = {
                            positions: [],
                            aum: 0,
                            cash: 0,
                        };
                        a[key] = ts;
                    };
                    const security = draft.dictionaries.securities[v.securityId];
                    ts.aum += v.marketValueInPortfolioCcy;
                    if (security.type === "CashSummaryModel") {
                        ts.cash += v.marketValueInPortfolioCcy;
                        //TODO: check if it is a bank account as well
                        if (security.currencyId && v.marketValueInSecurityCcy) {
                            let cashPosition = ts.positions.filter(i => (i as ICurrencyPosition)?.currencyId === security.currencyId)[0] as ICurrencyPosition | undefined;
                            if (!cashPosition) {
                                const { securityId, ...currencyCash } = v;
                                cashPosition = { ...currencyCash, currencyId: security.currencyId };
                                ts.positions.push(cashPosition);
                            }
                            else {
                                cashPosition.marketValueInSecurityCcy = (cashPosition.marketValueInSecurityCcy ?? 0) + (v.marketValueInSecurityCcy ?? 0);
                                cashPosition.weight = (cashPosition.weight ?? 0) + (v.weight ?? 0);
                                cashPosition.marketValueInSecurityCcy = (cashPosition.marketValueInSecurityCcy ?? 0) + (v.marketValueInSecurityCcy ?? 0);
                                cashPosition.quantity = (cashPosition.quantity ?? 0) + (v.quantity ?? 0);
                                cashPosition.bookCostInPortfolioCcy = (cashPosition.bookCostInPortfolioCcy ?? 0) + (v.bookCostInPortfolioCcy ?? 0);
                                cashPosition.bookCostInSecurityCcy = (cashPosition.bookCostInSecurityCcy ?? 0) + (v.bookCostInSecurityCcy ?? 0);
                            }
                        }
                    }
                    else {
                        ts.positions.push(v);
                    }
                    return a;
                }, {} as Record<number, IComposition>);
            break;
    }
});
