import { InvestorOperationModel } from "proxy/apiProxy";

// const allInvestorOperationTypes = {
//     ...InvestorOperationTypes,
//     [IInvestorOperationTypeModel.Cash]: { type: IInvestorOperationTypeModel.Cash,² label: "Cash", parentKey: "Regular" }
// }
export function getInvestorOperationTypeLabel(type: (InvestorOperationModel)["type"]): string;
export function getInvestorOperationTypeLabel(type: (InvestorOperationModel)["type"] | null): string | null;
export function getInvestorOperationTypeLabel(type: (InvestorOperationModel)["type"] | undefined): string | undefined;
export function getInvestorOperationTypeLabel(type: (InvestorOperationModel)["type"] | undefined | null): string | null | undefined;
export function getInvestorOperationTypeLabel(type: (InvestorOperationModel)["type"] | undefined | null): string | null | undefined {
    if (typeof (type) === "undefined") {
        return undefined;
    }
    if (!type) {
        return null;
    }
    switch (type) {
        case "CommitmentModel": return "Commitment";
        case "DrawdownModel":return "Drawdown";
    }
}
