import styled from '@emotion/styled';
import { Box, Paper } from "@material-ui/core";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { IMenuTargetScreen } from "features/App/menu/menuContracts";
import { useMemo } from "react";
import { useDrag } from "react-dnd";
import { ScreenKey } from "tools/routing/screenRouteHooks";
import { IScreenDefinition } from "tools/routing/screenTypes";
import { MenuTargetScreenNodeData } from "./MenuNodeData";
import {
    ICollectedProps,
    IDropResult,
    IMenuDefinitionTreeNodeLevel3,
    IMyDragObjectWithType,
    dndMenuNodeType
} from "./menuTree";

export const NodePaper = styled(Paper)({
    margin: 10,
    padding: 10,
    cursor: "move"
});

export function convertMenuTargetScreen(screenKey: ScreenKey, screenDef: IScreenDefinition): IMenuTargetScreen {

    // Extract dynamic segments from the route and initialize them with empty strings
    const params = (screenDef.route.match(/:\w+/g) || [])
        .reduce((acc, param) => {
            const key = param.substring(1); // Remove the colon
            acc[key] = "";
            return acc;
        }, {} as Record<string, string>);

    return {
        type: "Screen",
        label: screenDef.label,
        key: screenKey,
        params: params
    };
}

export interface IMenuTargetSourceProps {
    screenKey: string,
    screenDef: IScreenDefinition
}

export function MenuTargetSource({ screenKey, screenDef }: IMenuTargetSourceProps) {

    const screenTarget = useMemo(() => {
        return convertMenuTargetScreen(screenKey as ScreenKey, screenDef)
    }, [screenDef, screenKey]);

    const [collected, drag, dragPreview] = useDrag<IMyDragObjectWithType, IDropResult, ICollectedProps>({
        item: {
            type: dndMenuNodeType,
            node: {
                expanded: false,
                children: [],
                model: { ...screenTarget, new: true } as IMenuDefinitionTreeNodeLevel3
            },
        },
        options: { dropEffect: "copy" }
    });

    return collected?.isDragging
        ? <div ref={dragPreview} />
        : <NodePaper ref={drag} elevation={2} {...collected}>
            <Box display="flex" alignItems="center" gridGap={5}>
                <div style={{ cursor: "move", color: "lightgray" }}><DragIndicatorIcon /></div>
                <MenuTargetScreenNodeData node={screenTarget} />
            </Box>
        </NodePaper>
}
