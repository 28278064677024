import * as React from "react";
import { isRelativeReference, useColor, useValue, useVisible } from "./ValueProvider";
import { BindableType, FieldControl, FieldSorting, IGridArrayControl, RelativeReference } from "./DashboardFormattingContracts";
import { toDictionary } from "tools/lib/utility";
import { FieldContentComponent } from "./FieldComponent";
import { useSelection } from "./SelectionProvider";
import ExtendedGrid, { IColumnDefinition, IColumnState, IGridState } from "tools/components/ExtendedGrid";

export interface IDataGridComponentProps {
    item: IGridArrayControl<any>;
}
export function DataGridComponent({ item }: IDataGridComponentProps) {
    const { items, sorts: initialSorts, groups: initialGroups, fixedLeft, fixedRight } = item;
    const { rows, getRowKey, onSelectionChanged, selectedRows } = useSelection(item);
    const state = useDataGridState(initialSorts, items, initialGroups);
    const columns = useDataGridColumns(items, fixedLeft, fixedRight);
    const getValue = useValue();

    return <ExtendedGrid
        getRowId={getRowKey}
        columns={columns}
        onSelectionChanged={onSelectionChanged}
        selectedRows={selectedRows}
        rows={rows}
        initialState={state}
        multiSelect={item.multiSelect}
        userCanGroup
        stateLess
        showSelectionCheckbox
        defaultExportFileName={`${getValue("string", item?.label) ?? "export"}.xlsx`} />
}
function useDataGridState(initialSorts: FieldSorting | FieldSorting[] | undefined, items: FieldControl<never>[], initialGroups: RelativeReference[] | RelativeReference | undefined): IGridState {
    const getVisible = useVisible();
    const sorts = React.useMemo(() => {
        let sortsArray = initialSorts ?? [];
        if (!Array.isArray(sortsArray)) {
            sortsArray = [sortsArray];
        }
        const sortDefinitions = sortsArray
            .map((sort, index) => {
                const [field, direction] = getSortDefinition(sort);
                return { field, sort: (index + 1) * direction };
            })
            .filter(f => isRelativeReference(f.field));
        return toDictionary(sortDefinitions, i => i.field, i => i.sort);
    }, [initialSorts]);
    // const [sorts, onSortsChanged] = useSorts(initialSorts, items);
    const hiddens = React.useMemo(() => new Set(items.filter(c => !getVisible(c)).map(i => i.value)), [getVisible, items]);
    const groups = React.useMemo(() => {
        const grps = initialGroups ?? [];
        return (Array.isArray(grps) ? grps : [grps]).map((grp, idx) => ({ key: grp, idx: -(idx + 1) }));
    }, [initialGroups]);
    return React.useMemo(() => toDictionary(
        items.filter(i => isRelativeReference(i.value)),
        ({ value }, index) => value as string,
        ({ value, width }, index) => {
            // const columnPosition = (formatting?.positions ?? []).findIndex(p => p === key);
            return {
                hidden: hiddens.has(value),
                columnPosition: index + 1,
                sortingPosition: sorts[value as string],
                groupingPosition: groups.find(p => p.key === value as string)?.idx,
                width
            } as IColumnState;
        }), [groups, hiddens, items, sorts]);
}
function wrapItems(items: BindableType<string>[] | BindableType<string> | number | undefined): BindableType<string>[] | number | undefined {
    if (typeof items === "number") {
        return items;
    }
    else if (items) {
        if (Array.isArray(items)) {
            return items;
        }
        else {
            return [items];
        }
    }
    else {
        return undefined;
    }
}
function useDataGridColumns(items: FieldControl<never>[], fixedLeft: BindableType<string>[] | BindableType<string> | number | undefined, fixedRight: BindableType<string>[] | BindableType<string> | number | undefined): IColumnDefinition[] {
    const getColor = useColor();
    const getValue = useValue();


    if (fixedLeft && typeof fixedLeft !== "number") {
        fixedLeft = [];
    }
    else if (fixedLeft && !Array.isArray(fixedLeft) && typeof fixedLeft !== "number") {
        fixedLeft = [fixedLeft];
    }

    if (fixedRight && typeof fixedRight !== "number") {
        fixedRight = [];
    }
    else if (fixedRight && !Array.isArray(fixedRight) && typeof fixedRight !== "number") {
        fixedRight = [fixedRight];
    }



    // const getVisible = useVisible();
    return React.useMemo(() => {
        const fL = wrapItems(fixedLeft);
        const fR = wrapItems(fixedRight);



        return items
            // .filter(i => getVisible(i))
            .map((i, idx) => {
                const positionToKeep = (function () {
                    if (typeof fL === "number") {
                        if (idx < fL) return "left;"
                        return undefined;
                    }
                    else if (typeof fR === "number") {
                        if (idx >= (items.length - fR)) return "left;"
                        return undefined;
                    }
                    return (fL ?? []).includes(String(i.value))
                        ? "left"
                        : (fR ?? []).includes(String(i.value))
                            ? "right"
                            : undefined
                })()
                const getCellValue: IColumnDefinition["getCellValue"] = (function () {
                    switch (i.type) {
                        case "boolean": return (row: object) => getValue("boolean", i.value, row);
                        case "date": return (row: object) => getValue("date", i.value, row);
                        case "dateTime": return (row: object) => getValue("date", i.value, row);
                        case "string":
                            return (row: object) => getValue("string", i.value, row);
                        case "number":
                        case "percentage":
                            return (row: object) => getValue("number", i.value, row);
                        default:
                            return (row: object) => <FieldContentComponent item={i} row={row} />;
                    }
                })();
                const columnType: IColumnDefinition["columnType"] = (function () {
                    switch (i.type) {
                        case "boolean": return "boolean";
                        case "date": return "date";
                        case "dateTime": return "dateTime";
                        case "string": return "text";
                        case "number":
                            switch (i.precision) {
                                case 0: return "integer";
                                case 2: return "decimal";
                                case 4: return "preciseDecimal";
                                default: return "decimal";
                            }
                        case "percentage":
                            switch (i.precision) {
                                case 0: return "percentage";
                                case 2: return "precisePercentage2";
                                case 4: return "precisePercentage4";
                                default: return "percentage";
                            }
                        default: return undefined;
                    }
                })();
                const backgroundColor: IColumnDefinition["backgroundColor"] = i.backgroundColor
                    ? (row: object) => getColor(i.backgroundColor!, i.backgroundColorRatio, row)
                    : undefined;
                const foregroundColor: IColumnDefinition["foregroundColor"] = i.foregroundColor
                    ? (row: object) => getColor(i.foregroundColor!, i.foregroundColorRatio, row)
                    : undefined;
                return {
                    positionToKeep,
                    name: i.value,
                    title: getValue("string", i.label),
                    getCellValue,
                    filteringEnabled: true,
                    columnType,
                    backgroundColor,
                    foregroundColor
                } as IColumnDefinition;
            });
    }, [fixedLeft, fixedRight, getColor, getValue, items]);
}

function getSortDefinition(sort: FieldSorting) {
    const [field, isDesc] = sort.split(" ");
    return [field, isDesc === "desc" ? -1 : 1] as [string, number];
}
