import { IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles(theme => ({
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
}));

export interface IExpandCollapseButtonProps {
    expanded: boolean;
    onChanged: (expanded: boolean) => void;
}
export function ExpandCollapseButton({ expanded, onChanged }: IExpandCollapseButtonProps) {
    const classes = useStyles();
    const handleClick = () => onChanged(!expanded);
    return <IconButton
        className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
        })}
        onClick={handleClick}>
        <ExpandMoreIcon />
    </IconButton>;
}
