import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import {
    DataTypeProvider,
    DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { formatPercentage, formatPrecisePercentage, formatDecimal, formatCurrency, formatDate, formatPreciseDecimal, formatDateTime, formatInteger } from "tools/lib/utility";

const numericAvailableFilterOperations: string[] = [
    'greaterThanOrEqual', 'greaterThan', 'equal', 'notEqual',
    'lessThan', 'lessThanOrEqual',
];

const PercentFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
    if (props.value !== null && typeof (props.value) !== "undefined") {
        const value = props.value as number;
        // return (<div style={{ width: "100%" }}><div style={{ width: `${value * 100}%`, backgroundColor: "lightgray", textAlign: "center" }}>{formatPercentage(value)}</div></div>);
        return (<span>{formatPercentage(value)}</span>);
    }
    return null;
};

export const IntegerTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider formatterComponent={IntegerFormatter} availableFilterOperations={numericAvailableFilterOperations} {...props} />;

const IntegerFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
    if (props.value !== null && typeof (props.value) !== "undefined") {
        const value = props.value as number;
        return (<span>{formatInteger(value)}</span>);
    }
    return null;
};

export const PercentTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider formatterComponent={PercentFormatter} availableFilterOperations={numericAvailableFilterOperations} {...props} />;
const PrecisePercentFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
    if (props.value !== null && typeof (props.value) !== "undefined") {
        const value = props.value as number;
        // return (<div style={{ width: "100%" }}><div style={{ width: `${value * 100}%`, backgroundColor: "lightgray", textAlign: "center" }}>{formatPrecisePercentage(value)}</div></div>);
        return (<span>{formatPrecisePercentage(value)}</span>);
    }
    return null;
};

export const PrecisePercentTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider formatterComponent={PrecisePercentFormatter} availableFilterOperations={numericAvailableFilterOperations} {...props} />;

const PrecisePercent4Formatter = (props: DataTypeProvider.ValueFormatterProps) => {
    if (props.value !== null && typeof (props.value) !== "undefined") {
        const value = props.value as number;
        // return (<div style={{ width: "100%" }}><div style={{ width: `${value * 100}%`, backgroundColor: "lightgray", textAlign: "center" }}>{formatPrecisePercentage(value, 4)}</div></div>);
        return (<span>{formatPrecisePercentage(value, 4)}</span>);
    }
    return null;
};

export const PrecisePercent4TypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider formatterComponent={PrecisePercent4Formatter} availableFilterOperations={numericAvailableFilterOperations} {...props} />;

const DecimalFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
    if (props.value !== null && typeof (props.value) !== "undefined") {
        const value = props.value as number;
        return (<span>{formatDecimal(value)}</span>);
    }
    return null;
};

const PreciseDecimalFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
    if (props.value !== null && typeof (props.value) !== "undefined") {
        const value = props.value as number;
        return (<span>{formatPreciseDecimal(value)}</span>);
    }
    return null;
};

const CurrencyFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
    if (props.value !== null && typeof (props.value) !== "undefined") {
        let value = props.value.value as number;
        const currency = props.value.currency as string
        const dimension = value < 1000 ? "" : value < 1000000 ? "K" : "M";
        value = value < 1000 ? value : value < 1000000 ? value / 1000 : value / 1000000;
        return (<span>{formatCurrency(value, currency)}{dimension}</span>);
    }
    return null;
};
export const DecimalTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider formatterComponent={DecimalFormatter} availableFilterOperations={numericAvailableFilterOperations} {...props} />;
export const PreciseDecimalTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider formatterComponent={PreciseDecimalFormatter} availableFilterOperations={numericAvailableFilterOperations} {...props} />;
export const CurrencyTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider formatterComponent={CurrencyFormatter} availableFilterOperations={numericAvailableFilterOperations} {...props} />;

const DateFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
    if (props.value !== null && typeof (props.value) !== "undefined") {
        if (props.value instanceof Date) {
            const date = props.value as Date;
            return (<span>{formatDate(date)}</span>);
        }
        else {
            return (<span>{props.value}</span>);
        }
    }
    return null;
};

export const DateTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;
const DateTimeFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
    if (props.value !== null && typeof (props.value) !== "undefined") {
        const date = props.value as Date;
        return (<span>{formatDateTime(date)}</span>);
    }
    return null;
};

export const DateTimeTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider formatterComponent={DateTimeFormatter} {...props} />;

const BooleanFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
    if (props.value) {
        const val = props.value as boolean;
        if (val) {
            return <CheckBoxOutlinedIcon fontSize="small" />;
        }
        else {
            return null;
        }
    }
    return null;
};

export const BooleanTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />;
