// import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import TextField from '@material-ui/core/TextField';
// import { useFieldEx, IFormField, ValidatorParams } from 'tools/lib/fieldHelpers';
// import { isValidCron } from 'cron-validator'
// import { InputAdornment } from '@material-ui/core';
// import { ReadOnlyContext } from "./ReadOnlyContext";
// // import PercentIcon from "mdi-material-ui/Percent"

// const validators = {
//     required: (v: string | undefined, isTrue?: boolean) => isTrue && (v ?? "").length === 0 ? `Required` : undefined,
//     maxLength: (v: string | undefined, max?: number) => max && v && v.length > max ? `Must be ${max} characters or less` : undefined,
//     minLength: (v: string | undefined, min?: number) => min && v && v.length < min ? `Must be ${min} characters or more` : undefined,
//     isPercentage: (v: string | undefined, isTrue?: boolean) => isTrue && v && isNaN(Number(v)) ? 'Must be a number' : undefined,
//     isNumber: (v: string | undefined, isTrue?: boolean) => isTrue && v && isNaN(Number(v)) ? 'Must be a number' : undefined,
//     isEmail: (v: string | undefined, isTrue?: boolean) => isTrue && v && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v) ? 'Invalid email address' : undefined,
//     isAlphaNumeric: (v: string | undefined, isTrue?: boolean) => isTrue && v && /[^a-zA-Z0-9 ]/i.test(v) ? 'Only alphanumeric characters' : undefined,
//     length: (v: string | undefined, length?: number) => length && v && v.length !== length ? `Must be ${length} characters long` : undefined,
//     isCron: (v: string | undefined, isTrue?: boolean) => isTrue && v && typeof (v) === "string" && !isValidCron(v) ? "Invalid cron expression" : undefined,
//     minValue: (v: string | undefined, min?: number) => typeof (min) !== "undefined" && !isNaN(Number(v)) && Number(v) < min ? `Must be above ${min}` : undefined,
//     maxValue: (v: string | undefined, max?: number) => typeof (max) !== "undefined" && !isNaN(Number(v)) && Number(v) > max ? `Must be below ${max}` : undefined,
// }

// export interface IFormTextFieldProps extends IFormField, ValidatorParams<typeof validators> {
//     fullWidth?: boolean;
//     multiline?: boolean;
//     adornment?: React.ReactNode;
//     updateOnBlur?: boolean
// }

// function getDecimalSeparator() {
//     const numberWithDecimalSeparator = 1.1;
//     return Intl.NumberFormat()
//         ?.formatToParts(numberWithDecimalSeparator)
//         ?.find(part => part.type === 'decimal')
//         ?.value ?? ".";
// }
// function FormTextField({ adornment, name, helperText, label, disabled, error, updateOnBlur = false, fullWidth = true, multiline, ...requestedValidations }: IFormTextFieldProps) {

//     const { error: fieldError, helperText: fieldHelperText, label: fieldLabel, onValueChanged, value } = useFieldEx<string | number, typeof validators>({ name, helperText, label, required: requestedValidations.required, error, validators, requestedValidations });

//     const [decimalSeparator] = React.useState(getDecimalSeparator());


//     const fieldValue = useMemo(() => {
//         if (requestedValidations.isPercentage && typeof (value) === "number" && !isNaN(value)) {
//             return String(Number((value * 100).toFixed(14))); // run `console.log(100*0.07)` in any javascript to see the reason of this horror
//         }
//         else {
//             return typeof (value) === "number" && !isNaN(value) ? String(value) : (value ?? "");
//         }
//     }, [requestedValidations.isPercentage, value]);
//     const [textBoxContent, setTextboxContent] = useState(String(fieldValue ?? ""));
//     useEffect(() => setTextboxContent(String(fieldValue ?? "")), [fieldValue]);

//     const handleChangeForBlur = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setTextboxContent(e.target.value), []);
//     const handleChangeForUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
//         const targetValue = e.target.value;
//         if (requestedValidations.isNumber) {
//             const numberValue = Number(targetValue);
//             if (!isNaN(numberValue) && targetValue[targetValue.length - 1] !== decimalSeparator) {
//                 if ((targetValue ?? "") !== "") {
//                     onValueChanged(numberValue);
//                 }
//                 else {
//                     onValueChanged(undefined);
//                 }
//             }
//             // else if (!isNaN(numberValue) && (e.target.value ?? "") === "" && e.target.value[e.target.value.length - 1] !== decimalSeparator) {
//             //     onValueChanged(undefined);
//             // }
//             else {
//                 onValueChanged(targetValue);
//             }
//         }
//         else if (requestedValidations.isPercentage) {
//             const numberValue = Number(targetValue);
//             if (!isNaN(numberValue) && (targetValue ?? "") !== "" && targetValue[targetValue.length - 1] !== decimalSeparator) {
//                 onValueChanged(numberValue / 100);
//             }
//             else {
//                 onValueChanged(targetValue);
//             }
//         }
//         else {
//             onValueChanged(targetValue);
//         }
//     }, [decimalSeparator, onValueChanged, requestedValidations.isNumber, requestedValidations.isPercentage]);
//     const handleBlur = useCallback(() => {

//         if (requestedValidations.isNumber) {
//             const numberValue = Number(textBoxContent);
//             if (!isNaN(numberValue) && textBoxContent[textBoxContent.length - 1] !== decimalSeparator) {
//                 if ((textBoxContent ?? "") !== "") {
//                     onValueChanged(numberValue);
//                     setTextboxContent(String(numberValue));
//                 }
//                 else {
//                     onValueChanged(undefined);
//                 }
//             }
//             // else if (!isNaN(numberValue) && (e.target.value ?? "") === "" && e.target.value[e.target.value.length - 1] !== decimalSeparator) {
//             //     onValueChanged(undefined);
//             // }
//             else {
//                 onValueChanged(textBoxContent);
//             }
//         }
//         else if (requestedValidations.isPercentage) {
//             const numberValue = Number(textBoxContent);
//             if (!isNaN(numberValue) && (textBoxContent ?? "") !== "" && textBoxContent[textBoxContent.length - 1] !== decimalSeparator) {
//                 onValueChanged(numberValue / 100);
//                 setTextboxContent(String(numberValue));
//             }
//             else {
//                 onValueChanged(textBoxContent);
//             }
//         }
//         else {
//             onValueChanged(textBoxContent);
//         }
//     }, [decimalSeparator, onValueChanged, requestedValidations.isNumber, requestedValidations.isPercentage, textBoxContent]);
//     if (requestedValidations.isPercentage) {
//         adornment = "%";//<PercentIcon/>;
//     }

//     return <ReadOnlyContext.Consumer>
//         {readOnly => <TextField
//             InputProps={{
//                 endAdornment: !!adornment ? <InputAdornment position="end">{adornment}</InputAdornment> : undefined,
//                 disabled: readOnly || disabled,
//                 readOnly,
//             }}
//             id={name}
//             multiline={multiline}
//             fullWidth={fullWidth}
//             helperText={fieldHelperText}
//             disabled={disabled}
//             label={fieldLabel}
//             margin="dense"
//             value={textBoxContent}
//             onBlur={updateOnBlur ? handleBlur : undefined}
//             error={fieldError}
//             onChange={updateOnBlur ? handleChangeForBlur : handleChangeForUpdate} />}
//     </ReadOnlyContext.Consumer>
// }
// export default FormTextField;



import React, { useCallback, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import { useFieldEx, IFormField, ValidatorParams } from 'tools/lib/fieldHelpers';
import { isValidCron } from 'cron-validator'
import { InputAdornment } from '@material-ui/core';
import { ReadOnlyContext } from "./ReadOnlyContext";
// import PercentIcon from "mdi-material-ui/Percent"

const validators = {
    required: (v: string | undefined, isTrue?: boolean) => isTrue && (v ?? "").length === 0 ? `Required` : undefined,
    maxLength: (v: string | undefined, max?: number) => max && v && v.length > max ? `Must be ${max} characters or less` : undefined,
    minLength: (v: string | undefined, min?: number) => min && v && v.length < min ? `Must be ${min} characters or more` : undefined,
    isPercentage: (v: string | undefined, isTrue?: boolean) => isTrue && v && isNaN(Number(v)) ? 'Must be a number' : undefined,
    isNumber: (v: string | undefined, isTrue?: boolean) => isTrue && v && isNaN(Number(v)) ? 'Must be a number' : undefined,
    isEmail: (v: string | undefined, isTrue?: boolean) => isTrue && v && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v) ? 'Invalid email address' : undefined,
    isAlphaNumeric: (v: string | undefined, isTrue?: boolean) => isTrue && v && /[^a-zA-Z0-9 ]/i.test(v) ? 'Only alphanumeric characters' : undefined,
    length: (v: string | undefined, length?: number) => length && v && v.length !== length ? `Must be ${length} characters long` : undefined,
    isCron: (v: string | undefined, isTrue?: boolean) => isTrue && v && typeof (v) === "string" && !isValidCron(v) ? "Invalid cron expression" : undefined,
    minValue: (v: string | undefined, min?: number) => typeof (min) !== "undefined" && !isNaN(Number(v)) && Number(v) < min ? `Must be above ${min}` : undefined,
    maxValue: (v: string | undefined, max?: number) => typeof (max) !== "undefined" && !isNaN(Number(v)) && Number(v) > max ? `Must be below ${max}` : undefined,
}

export interface IFormTextFieldProps extends IFormField, ValidatorParams<typeof validators> {
    fullWidth?: boolean;
    multiline?: boolean;
    adornment?: React.ReactNode;
    onChanged?: (newValue: string) => void;
}

function getDecimalSeparator() {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat()
        ?.formatToParts(numberWithDecimalSeparator)
        ?.find(part => part.type === 'decimal')
        ?.value ?? ".";
}
function FormTextField({ adornment, name, helperText, label, disabled, error, fullWidth = true, multiline, onChanged, ...requestedValidations }: IFormTextFieldProps) {
    const { error: fieldError, helperText: fieldHelperText, label: fieldLabel, onValueChanged, value } = useFieldEx<string | number, typeof validators>({ name, helperText, label, required: requestedValidations.required, error, validators, requestedValidations });
    const [decimalSeparator] = React.useState(getDecimalSeparator());

    const fieldValue = useMemo(() => {
        if (requestedValidations.isPercentage && typeof (value) === "number" && !isNaN(value)) {
            return String(Number((value * 100).toFixed(14))); // run `console.log(100*0.07)` in any javascript to see the reason of this horror
        }
        else {
            return typeof (value) === "number" && !isNaN(value) ? String(value) : (value ?? "");
        }
    }, [requestedValidations.isPercentage, value]);

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (requestedValidations.isNumber) {
            const numberValue = Number(e.target.value);
            if (!isNaN(numberValue) && e.target.value[e.target.value.length - 1] !== decimalSeparator) {
                if ((e.target.value ?? "") !== "") {
                    onValueChanged(numberValue);
                }
                else {
                    onValueChanged(undefined);
                }
            }
            // else if (!isNaN(numberValue) && (e.target.value ?? "") === "" && e.target.value[e.target.value.length - 1] !== decimalSeparator) {
            //     onValueChanged(undefined);
            // }
            else {
                onValueChanged(e.target.value);
            }
        }
        else if (requestedValidations.isPercentage) {
            const numberValue = Number(e.target.value);
            if (!isNaN(numberValue) && (e.target.value ?? "") !== "" && e.target.value[e.target.value.length - 1] !== decimalSeparator) {
                onValueChanged(numberValue / 100);
            }
            else {
                onValueChanged(e.target.value);
            }
        }
        else {
            onChanged && onChanged(e.target.value);
            onValueChanged(e.target.value);
        }
    }, [decimalSeparator, onValueChanged, requestedValidations.isNumber, requestedValidations.isPercentage, onChanged]);
    const finalAdornment = useMemo(() => {
        if (requestedValidations.isPercentage) {
            return "%";//<PercentIcon/>;
        }
        return adornment;
    }, [adornment, requestedValidations.isPercentage]);
    return <ReadOnlyContext.Consumer>
        {readOnly => <TextField
            InputProps={{
                endAdornment: !!finalAdornment ? <InputAdornment position="end">{finalAdornment}</InputAdornment> : undefined,
                disabled: readOnly,
                readOnly
            }}
            id={name}
            multiline={multiline}
            fullWidth={fullWidth}
            helperText={fieldHelperText}
            disabled={disabled}
            label={fieldLabel}
            margin="dense"
            value={fieldValue}
            error={fieldError}
            onChange={onChange} />}
    </ReadOnlyContext.Consumer>
}
export default FormTextField;