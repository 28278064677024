import { DependencyList, useRef, useEffect } from "react";

export function useInterval(action: () => void, interval: number, deps: DependencyList) {
    const timerRef = useRef<ReturnType<typeof setInterval>>();
    useEffect(() => {
        action();
        if (typeof timerRef.current === "number") {
            clearInterval(timerRef.current);
        }
        const timer = setInterval(action, interval);
        timerRef.current = timer;
        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval, action, ...deps]);
}
