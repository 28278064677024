import produce from "immer";
import { IMacroScriptTypeModel, MacroScriptSummaryModel, MacroScriptModel, IMacroScriptCheckResultModel, IFileModel, IEntityMetadataModel, IExecutionStatusResultModel, IDataProcessorMacroScriptModel, IFileProcessorMacroScriptModel, IFileRetrieverMacroScriptModel, ISubMacroScriptModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
// import { IGitFile } from "tools/lib/octokitHelper";

export interface IMacroScriptLoadedPayload {
    macroScript: MacroScriptModel,
    macroScriptCheckResult: IMacroScriptCheckResultModel
}
export interface IMacroScriptValidateScript {
    script: string;
    type: Exclude<MacroScriptModel, ISubMacroScriptModel>["type"];
}
export interface IDataProcessorMacroScriptExecuteScript {
    id: number;
    targetId?: number;
    type: IDataProcessorMacroScriptModel["type"];
}
export interface IFileProcessorMacroScriptExecuteScript {
    id: number;
    type: IFileProcessorMacroScriptModel["type"];
    file: IFileModel;
}
export interface IFileRetrieverMacroScriptExecuteScript {
    id: number;
    type: IFileRetrieverMacroScriptModel["type"];
}
export interface IMacroScriptMetadata {
    [key: string]: IEntityMetadataModel;
    [key: number]: IEntityMetadataModel;
}

export function mapType(type: MacroScriptModel["type"]): IMacroScriptTypeModel {
    switch (type) {
        case "DataProcessorMacroScriptModel": return IMacroScriptTypeModel.DataProcessor;
        case "FileProcessorMacroScriptModel": return IMacroScriptTypeModel.FileProcessor;
        case "FileRetrieverMacroScriptModel": return IMacroScriptTypeModel.FileRetriever;
        case "MarketDataSelectorMacroScriptModel": return IMacroScriptTypeModel.MarketDataSelector;
        case "MonitoringMacroScriptModel": return IMacroScriptTypeModel.Monitoring;
        case "SubMacroScriptModel": return IMacroScriptTypeModel.SubMacro;
    }
}
export function mapTypeForNew(type: IMacroScriptTypeModel): MacroScriptModel["type"] {
    switch (type) {
        case IMacroScriptTypeModel.DataProcessor: return "DataProcessorMacroScriptModel";
        case IMacroScriptTypeModel.FileProcessor: return "FileProcessorMacroScriptModel";
        case IMacroScriptTypeModel.FileRetriever: return "FileRetrieverMacroScriptModel";
        case IMacroScriptTypeModel.MarketDataSelector: return "MarketDataSelectorMacroScriptModel";
        case IMacroScriptTypeModel.Monitoring: return "MonitoringMacroScriptModel";
        case IMacroScriptTypeModel.SubMacro: return "SubMacroScriptModel";
    }
}

export type IMacroScriptExecuteScript = IDataProcessorMacroScriptExecuteScript | IFileProcessorMacroScriptExecuteScript | IFileRetrieverMacroScriptExecuteScript;
export const ActionFactories = produceActionFactories({
    macroScriptDelete: (payload: number) => payload,
    macroScriptDeleted: (payload: number) => payload,
    macroScriptLoad: (payload: number) => payload,
    macroScriptNew: (payload: IMacroScriptTypeModel) => payload,
    macroScriptLoaded: (payload: IMacroScriptLoadedPayload) => payload,
    macroScriptSave: (payload: MacroScriptModel) => payload,
    macroScriptExecute: (payload: IMacroScriptExecuteScript) => payload,
    macroScriptExecuted: (payload: IExecutionStatusResultModel) => payload,
    macroScriptFileProviderExecuted: (payload: IExecutionStatusResultModel) => payload,
    macroScriptSaved: (payload: MacroScriptModel) => payload,
    macroScriptLoadAll: () => undefined,
    macroScriptLoadedAll: (payload: MacroScriptSummaryModel[]) => payload,
    macroScriptValidateScript: (payload: IMacroScriptValidateScript) => payload,
    macroScriptValidatedScript: (payload: IMacroScriptCheckResultModel) => payload,
    macroScriptMetadataLoad: (payload: IMacroScriptTypeModel) => payload,
    macroScriptMetadataLoaded: (payload: IMacroScriptMetadata) => payload
    // macroScriptLoadedCommunityMacroScripts: (payload: IGitFile[]) => payload,
    // macroScriptLoadCommunityMacroScript: (payload: string) => payload,
    // macroScriptLoadedCommunityMacroScript: (payload: string) => payload,
});
export interface IState {
    macroScriptListLoading: boolean;
    macroScriptLoading: boolean;
    macroScriptSaving: boolean;
    macroScriptDeleting: boolean;
    macroScriptList: (MacroScriptSummaryModel | MacroScriptModel)[];
    macroScriptCurrent?: MacroScriptModel;
    macroScriptCheckResult?: IMacroScriptCheckResultModel;
    macroExecutionResult?: IExecutionStatusResultModel;
    macroScriptMetadata: IMacroScriptMetadata;
    macroScriptExecuting: boolean;
    // macroScriptCommunityMacroScripts: IGitFile[];
    // macroScriptLastCommunityScript?: string;
}
// IShareClassMacroScriptDictionary
export const reducer = (
    state: IState = {
        macroScriptListLoading: false,
        macroScriptLoading: false,
        macroScriptDeleting: false,
        macroScriptSaving: false,
        macroScriptExecuting: false,
        macroScriptMetadata: {},
        macroScriptList: []
        // macroScriptCommunityMacroScripts: []
    },
    action: AnyActionOf<typeof ActionFactories>
) => produce(state, draft => {
    switch (action.type) {
        case "macroScriptMetadataLoad":
            draft.macroScriptMetadata = {};
            break;
        case "macroScriptMetadataLoaded":
            draft.macroScriptMetadata = action.payload;
            break;
        case "macroScriptFileProviderExecuted":
            draft.macroExecutionResult = action.payload;
            draft.macroScriptExecuting = false;
            break;
        case "macroScriptExecuted":
            draft.macroExecutionResult = action.payload;
            draft.macroScriptExecuting = false;
            break;
        case "macroScriptExecute":
            draft.macroScriptExecuting = true;
            break;
        // case "macroScriptLoadCommunityMacroScript":
        //     draft.macroScriptLoading = true;
        //     break;
        // case "macroScriptLoadedCommunityMacroScript":
        //     draft.macroScriptLoading = false;
        //     if (draft.macroScriptCurrent) {
        //         draft.macroScriptCurrent.script = action.payload;
        //     }
        //     break;
        // case "macroScriptLoadedCommunityMacroScripts":
        //     draft.macroScriptCommunityMacroScripts = action.payload;
        //     break;
        case "macroScriptValidateScript":
            draft.macroExecutionResult = undefined;
            break;
        case "macroScriptValidatedScript":
            draft.macroScriptCheckResult = action.payload;
            break;
        case "macroScriptLoadAll":
            draft.macroScriptListLoading = true;
            break;
        case "macroScriptLoadedAll":
            draft.macroScriptListLoading = false;
            draft.macroScriptList = action.payload;
            break;
        case "macroScriptNew":
        case "macroScriptLoad":
            draft.macroScriptLoading = true;
            draft.macroScriptCheckResult = undefined;
            draft.macroExecutionResult = undefined;
            break;
        case "macroScriptLoaded": {
            draft.macroScriptLoading = false;
            draft.macroScriptCurrent = action.payload.macroScript;
            draft.macroScriptCheckResult = action.payload.macroScriptCheckResult;
            break;
        }
        case "macroScriptSave":
            draft.macroScriptSaving = true;
            break;
        case "macroScriptSaved": {
            draft.macroScriptSaving = false;
            if (draft.macroScriptCurrent) {
                draft.macroScriptCurrent = action.payload;
            }
            const existing = draft.macroScriptList.find(i => i.id === action.payload.id);
            if (existing) {
                Object.assign(existing, action.payload);
            }
            else {
                draft.macroScriptList.push(action.payload);
            }
            break;
        }
        case "macroScriptDelete":
            draft.macroScriptDeleting = true;
            break;
        case "macroScriptDeleted":
            draft.macroScriptDeleting = false;
            const deletedId = action.payload;
            const idx = draft.macroScriptList.findIndex(i => i.id === deletedId);
            if (idx >= 0) {
                draft.macroScriptList.splice(idx, 1);
            }
            break;
    }
});

