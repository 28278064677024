import { Epic as portfolioTrades } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { dailyDataApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadTrades: portfolioTrades<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("portfolioTrades", "tradeSearch"),
        mergeMap(dailyDataApi.searchPortfolioTradesAsync),
        map(ActionFactories.portfolioTrades.tradeLoaded));
