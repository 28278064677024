import produce from "immer";
import { IProcessDefinitionModel, IProcessDefinitionSummaryModel, IQuestionnaireDevelopmentItemSummaryModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";

export interface IState {
    processDefinitionAllLoading: boolean;
    processDefinitionLoading: boolean;
    processDefinitions: (IProcessDefinitionSummaryModel | IProcessDefinitionModel)[];
    processDefinitionCurrent?: IProcessDefinitionModel;
    processDefinitionSaving: boolean;
    processDefinitionDeleting: boolean;
    processDefinitionQuestionnaires: IQuestionnaireDevelopmentItemSummaryModel[]
}
export const ActionFactories = produceActionFactories({
    processDefinitionLoad: (payload: number) => payload,
    processDefinitionLoaded: (payload: IProcessDefinitionModel) => payload,
    processDefinitionSave: (payload: IProcessDefinitionModel) => payload,
    processDefinitionSaved: (payload: IProcessDefinitionModel) => payload,
    processDefinitionDelete: (payload: number) => payload,
    processDefinitionDeleted: (payload: number) => payload,
    processDefinitionLoadAll: () => undefined,
    processDefinitionLoadedAll: (payload: IProcessDefinitionSummaryModel[]) => payload,
    processDefinitionQuestionnaireLoaded: (payload: IQuestionnaireDevelopmentItemSummaryModel[]) => payload
});

export function reducer(
    state: IState = {
        processDefinitionLoading: false,
        processDefinitionAllLoading: false,
        processDefinitionSaving: false,
        processDefinitionDeleting: false,
        processDefinitions: [],
        processDefinitionQuestionnaires: []
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "processDefinitionLoadAll":
                draft.processDefinitionAllLoading = true;
                break;
            case "processDefinitionLoadedAll":
                draft.processDefinitionAllLoading = false;
                draft.processDefinitions = action.payload;
                break;
            case "processDefinitionLoad":
                draft.processDefinitionLoading = true;
                draft.processDefinitionCurrent = undefined;
                break;
            case "processDefinitionLoaded":
                draft.processDefinitionLoading = false;
                draft.processDefinitionCurrent = action.payload;
                break;
            case "processDefinitionSave":
                draft.processDefinitionSaving = true;
                break;
            case "processDefinitionSaved":
                draft.processDefinitionSaving = false;
                const saved = action.payload;
                if (draft.processDefinitionCurrent) {
                    draft.processDefinitionCurrent = saved;
                }
                const existing = draft.processDefinitions.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.processDefinitions.push(saved);
                }
                break;
            case "processDefinitionDelete":
                draft.processDefinitionDeleting = true;
                break;
            case "processDefinitionDeleted":
                draft.processDefinitionDeleting = false;
                if (draft.processDefinitionCurrent?.id === action.payload) {
                    delete draft.processDefinitionCurrent;
                }
                const idx = draft.processDefinitions.findIndex(i => i.id === action.payload);
                if (idx >= 0) {
                    draft.processDefinitions.splice(idx, 1);
                }
                break;
            case "processDefinitionQuestionnaireLoaded":
                draft.processDefinitionQuestionnaires = action.payload;
                break;
        }
    });
}
