import { Epic } from "redux-observable";
import { filter, map, mergeMap, share, tap } from "rxjs/operators";
import {
    IEntityMetadataModel,
    IMacroScriptCheckResultModel,
    IMacroScriptTypeModel,
    ISubMacroScriptModel,
    MacroScriptModel,
    macroScriptsApi,
    studioMacroScriptsApi
} from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload, onlyNotNull } from "lib/rxJsUtility";
import { base64toBlob } from "tools/lib/utility";
import { combineLatest, merge } from "rxjs";
import { IMacroScriptLoadedPayload, mapType } from "./slice";
import saveAs from "file-saver";

function createNewMacroScriptInstance(type: IMacroScriptTypeModel): MacroScriptModel {
    switch (type) {
        case IMacroScriptTypeModel.DataProcessor:
            return {
                type: "DataProcessorMacroScriptModel",
                id: 0,
                code: "",
                name: "",
                description: "",
                script: "",
                publishedVersion: 0,
            };
        case IMacroScriptTypeModel.FileProcessor:
            return {
                type: "FileProcessorMacroScriptModel",
                id: 0,
                code: "",
                name: "",
                description: "",
                script: "",
                publishedVersion: 0,
            };
        case IMacroScriptTypeModel.FileRetriever:
            return {
                type: "FileRetrieverMacroScriptModel",
                id: 0,
                code: "",
                name: "",
                description: "",
                script: "",
                publishedVersion: 0,
            };
        case IMacroScriptTypeModel.MarketDataSelector:
            return {
                type: "MarketDataSelectorMacroScriptModel",
                id: 0,
                code: "",
                name: "",
                description: "",
                script: "",
                publishedVersion: 0,
            };
        case IMacroScriptTypeModel.SubMacro:
            return {
                type: "SubMacroScriptModel",
                id: 0,
                code: "",
                name: "",
                description: "",
                script: "",
                publishedVersion: 0,
            };
        case IMacroScriptTypeModel.Monitoring:
            return {
                type: "MonitoringMacroScriptModel",
                id: 0,
                code: "",
                name: "",
                description: "",
                script: "",
                publishedVersion: 0,
                publishOnPortal: false
            };
    }
}

export const loadMacroScripts: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("macroScript", "macroScriptLoadAll"),
        mergeMap(() => macroScriptsApi.getAllAsync({})),
        map(ActionFactories.macroScript.macroScriptLoadedAll));

export const deleteMacroScript: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("macroScript", "macroScriptDelete"),
            mergeMap(id => studioMacroScriptsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.macroScript.macroScriptDeleted)
        );
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate({screenKey: "Macros"}))));
    }
export const loadMacroScript: Epic<IAnyAction>
    = action$ => {
        const macroScript$ = action$.pipe(
            mapToPayload("macroScript", "macroScriptLoad"),
            mergeMap(id => studioMacroScriptsApi.getAsync({ id })),
            share());

        const ruleCheckResult$ = merge(
            macroScript$.pipe(
                filter(({ type }) => type !== "SubMacroScriptModel"),
                mergeMap(({ script, type }) => studioMacroScriptsApi.checkScriptAsync(({ type: mapType(type as Exclude<MacroScriptModel, ISubMacroScriptModel>["type"]), textModel: { text: script } })))),
            macroScript$.pipe(
                filter(({ type }) => type === "SubMacroScriptModel"),
                map(() => ({ errors: [] } as IMacroScriptCheckResultModel))
            )
        );
        const metadata$ = merge(
            macroScript$.pipe(
                filter(({ type }) => type !== "SubMacroScriptModel"),
                mergeMap(({ type }) => studioMacroScriptsApi.getUniverseStructureAsync({ type: mapType(type as Exclude<MacroScriptModel, ISubMacroScriptModel>["type"]) }))),
            macroScript$.pipe(
                filter(({ type }) => type === "SubMacroScriptModel"),
                map(() => ({} as Record<string | number, IEntityMetadataModel>))
            ),
        ).pipe(map(ActionFactories.macroScript.macroScriptMetadataLoaded));

        return merge(
            combineLatest([macroScript$, ruleCheckResult$]).pipe(
                map(([macroScript, macroScriptCheckResult]) => ({ macroScript, macroScriptCheckResult })),
                map(ActionFactories.macroScript.macroScriptLoaded)),
            metadata$);
    };
export const checkScript: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("macroScript", "macroScriptValidateScript"),
        mergeMap(({ script, type }) => studioMacroScriptsApi.checkScriptAsync({ type: mapType(type), textModel: { text: script } })),
        map(ActionFactories.macroScript.macroScriptValidatedScript));

export const executeScript: Epic<IAnyAction>
    = action$ => {
        const execution$ = action$.pipe(mapToPayload("macroScript", "macroScriptExecute"), share());
        const dataProcessorExecution$ = execution$.pipe(
            filter(i => i.type === "DataProcessorMacroScriptModel"),
            mergeMap(r => macroScriptsApi.executeDataProcessorMacroAsync({ id: r.id, scopeId: r.type === "DataProcessorMacroScriptModel" ? r.targetId : undefined })),
            map(ActionFactories.macroScript.macroScriptExecuted));

        const fileProviderExecution$ = execution$.pipe(
            filter(i => i.type === "FileRetrieverMacroScriptModel"),
            mergeMap(({ id }) => macroScriptsApi.executeFileProviderMacroAsync({ id })),
            tap(({ file }) => {
                if (file && file.data && file.mimeType && file.name) {
                    const blob = base64toBlob(file.data, file.mimeType);
                    saveAs(blob, file.name);
                }
            }),
            map(({ result }) => ActionFactories.macroScript.macroScriptFileProviderExecuted(result)));
        const fileProcessorExecution$ = execution$.pipe(
            map(i => {
                if (i.type === "FileProcessorMacroScriptModel") {
                    return i;
                }
                return undefined;
            }),
            onlyNotNull(),
            mergeMap(({ id, file }) => macroScriptsApi.executeFileProcessorMacroAsync({ id, fileModel: file })),
            map(ActionFactories.macroScript.macroScriptExecuted));
        return merge(
            dataProcessorExecution$,
            fileProviderExecution$,
            fileProcessorExecution$
        );
    }

export const newMacroScript: Epic<IAnyAction>
    = action$ => {
        const type$ = action$.pipe(
            mapToPayload("macroScript", "macroScriptNew"), share());

        const metadata$ = merge(
            type$.pipe(
                filter(type => type !== IMacroScriptTypeModel.SubMacro),
                mergeMap(type => studioMacroScriptsApi.getUniverseStructureAsync({ type }))),
            type$.pipe(
                filter(type => type === IMacroScriptTypeModel.SubMacro),
                map(() => ({} as Record<string | number, IEntityMetadataModel>))))
            .pipe(map(ActionFactories.macroScript.macroScriptMetadataLoaded));
        return merge(
            type$.pipe(
                map(type => ({ macroScript: createNewMacroScriptInstance(type), macroScriptCheckResult: { errors: [] } } as IMacroScriptLoadedPayload)),
                map(ActionFactories.macroScript.macroScriptLoaded)),
            metadata$);
    }
export const saveMacroScript: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("macroScript", "macroScriptSave"),
        mergeMap(model => studioMacroScriptsApi.saveAsync({ model })),
        map(ActionFactories.macroScript.macroScriptSaved));
