import * as React from "react";
import {
    IScopeDescriptionModel,
    ISubScopeModel,
    IParameterMetadataModel,
    IScopeFilterModel,
    ISearchMetadataModel
} from "proxy/apiProxy";
import {
    Card,
    CardContent,
    CardHeader,
    Tooltip,
    IconButton,
    Divider,
    Button,
    Avatar,
    Collapse,
    FormControlLabel,
    Switch,
    withStyles,
    createStyles,
    Grid
} from "@material-ui/core";
import FieldBox from "tools/components/FieldBox";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps } from "tools/lib/utility";
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import FormTreeViewSelectField from "tools/fieldComponents/FormTreeViewSelectField";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FormMultiTextField from "tools/fieldComponents/FormMultiTextField";
import InboxMultipleOutlineIcon from "mdi-material-ui/InboxMultipleOutline";
import InboxOutlineIcon from "mdi-material-ui/InboxOutline";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";

export interface IScopeDescriptionDataProps {
    formFieldName?: string;
    afterGroup?: React.ReactNode;
    groupSummary?: React.ReactNode;
    parameterMetadatas?: IParameterMetadataModel[]
    hideGroupNameExpression?: boolean;
}

export default function ScopeDescriptionData({
                                                 afterGroup,
                                                 formFieldName,
                                                 parameterMetadatas = [],
                                                 groupSummary,
                                                 hideGroupNameExpression = false
                                             }: IScopeDescriptionDataProps) {
    // const [{ value: scopeDescription }] = useField<IScopeDescriptionModel>(formFieldName);
    const [{value: subScopes}] = useField<IScopeDescriptionModel["subScopes"]>(oProps<IScopeDescriptionModel>(formFieldName).path("subScopes"));
    return <ReadOnlyContext.Consumer>
        {readOnly => <><FieldBox display="flex" flexDirection="row">
            <FormDatePickerField name={oProps<IScopeDescriptionModel>(formFieldName).path("asOfDate")} label="As of"/>
            <FormTextField name={oProps<IScopeDescriptionModel>(formFieldName).path("relativeAsOfDate")}
                           label="Relative to as of" isNumber={true}/>
        </FieldBox>
            <Grid container={true} spacing={2}>
                {parameterMetadatas.map(reportParameterMetadata => <Grid item={true} xs={6}> <SubScope
                    key={reportParameterMetadata.name}
                    readOnly={readOnly}
                    formFieldName={formFieldName}
                    parameterMetadata={reportParameterMetadata}
                    subScope={subScopes[reportParameterMetadata.name]}/></Grid>)}
            </Grid>
            {!hideGroupNameExpression && <>
                <FieldBox display="flex" flexDirection="row">
                    <FormTextField name={oProps<IScopeDescriptionModel>(formFieldName).path("groupLabelExpression")}
                                   label="Group Name Expression" required={true} maxLength={250}/>
                    {afterGroup}
                </FieldBox>
                {groupSummary}
            </>}
        </>}
    </ReadOnlyContext.Consumer>
}

interface ISubScopeProps {
    parameterMetadata: IParameterMetadataModel;
    subScope?: ISubScopeModel;
    formFieldName?: string;
    readOnly: boolean;
}

interface IInternalNode {
    path: string;
    name: string;
    type: string;
    children: IInternalNode[];
}

const StyledCard = withStyles(theme =>
    createStyles({
        root: {
            // marginTop: theme.spacing(2),
            boxShadow: theme.shadows["2"]
        }
    })
)(Card);

function SubScope({
                      readOnly,
                      parameterMetadata: {isMany, metadata, name: parameterName},
                      subScope,
                      formFieldName
                  }: ISubScopeProps) {

    const {filters} = subScope ?? {filters: []};
    const [internalTree, setInternalTree] = React.useState<IInternalNode[]>([]);
    const [, , {setValue: setSubScopeValue}] = useField<ISubScopeModel | undefined>(oProps<IScopeDescriptionModel>(formFieldName).path("subScopes", parameterName));
    React.useEffect(() => {
        const prepareChildren: (parentKey?: string[], parent?: ISearchMetadataModel) => IInternalNode[]
            = (parentKey = [], parent) => ((parent ? parent.subLevels : metadata.subLevels) ?? []).map(child => {
            const key = [...parentKey, child.name];
            return {
                path: JSON.stringify(key),
                name: child.name,
                type: child.type,
                children: prepareChildren(key, child)
            };
        });
        setInternalTree(prepareChildren());
    }, [metadata]);

    // const filtersFieldName = oProps<IScopeDescriptionModel>(formFieldName).path("subScopes", parameterName, "filters");
    const {name: metadataName} = metadata;
    const getChildren = (element?: IInternalNode) => !element ? internalTree : element.children;
    const getPrimaryContent = (element: IInternalNode) => element.name;
    const getKey = (element: IInternalNode) => element.path;
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, isActive: boolean) => {
        setSubScopeValue(isActive ? ({filters: []} as ISubScopeModel) : undefined);
    }
    return <StyledCard>
        <CardHeader
            avatar={<Avatar>{isMany ? <InboxMultipleOutlineIcon/> : <InboxOutlineIcon/>}</Avatar>}
            title={`${metadataName}${isMany ? " (Multiple)" : ""}`}
            subheader={parameterName}/>
        <CardContent>
            <FormControlLabel
                control={<Switch readOnly={readOnly} checked={!!subScope} onChange={handleChange} name="checkedA"/>}
                label="Active"
            />
            <Collapse in={!!subScope} timeout="auto" unmountOnExit={true}>
                <FieldBox display="flex" flexDirection="column">
                    {isMany && <FormTreeViewSelectField
                        name={oProps<IScopeDescriptionModel>(formFieldName).path("subScopes", parameterName, "grouping")}
                        label="Group by"
                        getChildren={getChildren}
                        getPrimaryContent={getPrimaryContent}
                        getKey={getKey}
                        options={internalTree}/>}
                    <FieldArray
                        name={oProps<IScopeDescriptionModel>(formFieldName).path("subScopes", parameterName, "filters")}
                        validateOnChange={true}>
                        {RenderFilters}
                    </FieldArray>
                </FieldBox>
            </Collapse>
        </CardContent>
    </StyledCard>;

    function RenderFilters({push, remove, form}: FieldArrayRenderProps): React.ReactNode {
        // formFieldName: string, filters: IScopeFilterModel[], nodes: IInternalNode[], 
        const getChildren = (element?: IInternalNode) => !element ? internalTree : element.children;
        const getPrimaryContent = (element: IInternalNode) => element.name;
        const getKey = (element: IInternalNode) => element.path;
        const handlePush = () => {
            push({path: "", values: []});
            setTimeout(() => form.validateForm(), 200);
        }
        const handleRemove = (idx: number) => {
            remove(idx);
            setTimeout(() => form.validateForm(), 200);
        }
        return <>
            {filters && filters.map((filter, idx) => <FilterElement
                readOnly={readOnly}
                key={idx}
                index={idx}
                onRemove={handleRemove}
                filter={filter}
                formFieldName={formFieldName}
                parameterName={parameterName}
                getChildren={getChildren}
                getKey={getKey}
                getPrimaryContent={getPrimaryContent}
                internalTree={internalTree}/>)}
            {!readOnly && <FieldBox display="flex" flexDirection="row">
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={handlePush}>Add a filter</Button>
            </FieldBox>}
        </>;
    }
}

interface IFilterElementProps {
    filter: IScopeFilterModel;
    index: number;
    onRemove: (index: number) => void;
    formFieldName?: string;
    parameterName: string;
    getChildren: (element?: IInternalNode) => IInternalNode[];
    getPrimaryContent: (element: IInternalNode) => string;
    getKey: (element: IInternalNode) => string;
    internalTree: IInternalNode[];
    readOnly: boolean;
}

function FilterElement({
                           readOnly,
                           filter,
                           parameterName,
                           index: idx,
                           onRemove,
                           formFieldName,
                           getChildren,
                           getPrimaryContent,
                           getKey,
                           internalTree
                       }: IFilterElementProps) {
    const handleRemove = () => onRemove(idx);
    return <FieldBox style={{alignItems: "center"}} key={idx} display="flex" flexDirection="row">
        <FormTreeViewSelectField
            name={oProps<IScopeDescriptionModel>(formFieldName).path("subScopes", parameterName, "filters", idx, "path")}
            label="Value to filter" required={true} getChildren={getChildren} getPrimaryContent={getPrimaryContent}
            getKey={getKey} options={internalTree}/>
        <FormMultiTextField
            name={oProps<IScopeDescriptionModel>(formFieldName).path("subScopes", parameterName, "filters", idx, "values")}
            label="Accepted values" required={true}/>
        {!readOnly && <Tooltip title="Delete filter">
            <IconButton
                size="small"
                aria-label="Unlink"
                style={{verticalAlign: "middle"}}
                onClick={handleRemove}>
                <DeleteIcon/>
            </IconButton>
        </Tooltip>}
        <Divider/>
    </FieldBox>
}