import DetailPanel from "tools/components/DetailPanel";
import HistoricalValue from "components/global/HistoricalValue";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { IFeatureModel } from "proxy/apiProxy";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";
import { isNumber, useNumber } from "tools/lib/utility";

export const fxRateScreen = {
    route: "/marketdata/fxrates/:id" as const,
    component: FxRate,
    label: "Fx Rate",
}

function FxRate() {
    const {
        referenceCurrencies,
    } = useReduxSelections("reference");
    const {
        currentTargetCurrency,
        fxRatesHistoricalLoading,
        fxRatesHistoricalValuesVisualizationType
    } = useReduxSelections("fxRates");
    const {
        fxRatesHistoricalValuesVisualizationTypeChanged,
        fxRatesHistoricalValueSave,
        fxRatesHistoricalValuesLoad,
    } = useReduxActions("fxRates");
    const {
        parameters: {defaultCurrencyId}
    } = useReduxSelections("parameters");

    const {id} = useScreenParams<typeof fxRateScreen>()
    const idNum = useNumber(id)
    useEffect(() => {
        if (isNumber(idNum))
            fxRatesHistoricalValuesLoad(idNum)
    }, [fxRatesHistoricalValuesLoad, idNum]);

    const isGranted = useGrants();
    const handleValueChanged = (date: Date, value: number | undefined) => {
        if (currentTargetCurrency) {
            fxRatesHistoricalValueSave({
                currencyToId: currentTargetCurrency.targetCurrency.id,
                fxValue: {
                    date,
                    rateFromReferenceCurrency: value
                }
            });
        }
    }
    if (!currentTargetCurrency || !referenceCurrencies) {
        return <DetailPanelLoading hasSubTitle={true}/>;
    }
    const {historicalValues, targetCurrency: {isoCode: targetIsoCode}} = currentTargetCurrency;
    return <DetailPanel
        isQuerying={fxRatesHistoricalLoading}
        title="Fx Rates"
        subTitle={`${referenceCurrencies[defaultCurrencyId ?? 0]?.isoCode} to ${targetIsoCode}`}>
        <HistoricalValue
            historicalValues={historicalValues}
            onVisualizationTypeChanged={fxRatesHistoricalValuesVisualizationTypeChanged}
            visualizationType={fxRatesHistoricalValuesVisualizationType}
            editable={isGranted(IFeatureModel.MarketDataWrite)}
            onValueChanged={handleValueChanged}
        />
    </DetailPanel>;
}
