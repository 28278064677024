import { Epic, ofType } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { processClassificationTypesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadClassifications: Epic<IAnyAction>
    = action$ =>
        merge(
            action$.pipe(ofType("applicationLoaded"), map(i => null)),
            action$.pipe(ofType("processClassificationTypeLoadAll"), map(i => null)),
            action$.pipe(ofType("processClassificationTypeSaved"), map(i => null)),
            action$.pipe(ofType("referenceRefreshAll"), map(i => null))
        ).pipe(
            mergeMap(() => processClassificationTypesApi.getAllAsync({})),
            map(ActionFactories.processClassificationType.processClassificationTypeAllLoaded));

export const loadClassification: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("processClassificationType", "processClassificationTypeLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => processClassificationTypesApi.getAsync({ id }))),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({ id: 0, name: { en: "" }, code: "", classifications: [] })))
        ).pipe(
            map(ActionFactories.processClassificationType.processClassificationTypeLoaded));
    };
export const saveClassification: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("processClassificationType", "processClassificationTypeSave"),
        mergeMap(model => processClassificationTypesApi.saveAsync({ model })),
        map(ActionFactories.processClassificationType.processClassificationTypeSaved));
export const deleteClassification: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("processClassificationType", "processClassificationTypeDelete"),
            mergeMap(id => processClassificationTypesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.processClassificationType.processClassificationTypeDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
