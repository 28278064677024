import { ICompletionDataModel, IMacroScriptErrorModel, IResultErrorModel, ITextModelWithPosition } from "proxy/apiProxy";
import { toDictionary } from "tools/lib/utility";
import { useField } from "formik";
import CodeEditor from "tools/components/CodeEditor";
import RED from '@material-ui/core/colors/red';
import {
    CircularProgress,
    Box,
    createStyles,
    Fab,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    withStyles,
    TextField,
    makeStyles, Typography
} from "@material-ui/core";
import ReactJson from 'react-json-view'
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import ErrorIcon from '@material-ui/icons/Error';
import { SummaryField } from "components/global/SummaryField";
import { useCallback, useMemo, useState } from "react";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { IMacroScriptMetadata } from "./slice";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export interface IMacroScriptEditorProps {
    onScriptChanged: (script: string) => void;
    scriptErrors: IMacroScriptErrorModel[];
    metadata: IMacroScriptMetadata;
    error?: IResultErrorModel;
    onSaveRequest: () => void;
    executing: boolean;
    field:string;
    onRequestAutoComplete?: (position: ITextModelWithPosition) => Promise<ICompletionDataModel[]>
}

const FloatingBox = withStyles((theme) =>
    createStyles({
        root: {
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 999
        }
    })
)(Box);

const FabContainerBox = withStyles(theme =>
    createStyles({
        root: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            width: 0,
            left: 0,
            right: 0,
            justifyContent: "center",
            overflowY: "visible",
            zIndex: 1200,
            alignItems: "center",
            alignContent: "center",
            gap: theme.spacing(2)
        },
    })
)(Box);

const useStyles = makeStyles((theme) => ({
    errorRoot: {
        width: '100%',
        backgroundColor: RED[50],
    },
    zeroMinWidth: {minWidth: 0},
    errorIcon: {
        color: RED[600],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexShrink: 1,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "100%",
    },
    accordSummary: {
        "& .MuiAccordionSummary-content": {width: "100%",},
    },
}))

export default function MacroScriptEditor({
                                              field,
                                              onScriptChanged,
                                              scriptErrors,
                                              onSaveRequest,
                                              metadata,
                                              error,
                                              executing,
                                              onRequestAutoComplete
                                          }: IMacroScriptEditorProps) {
    const [, {value: script}, {setValue: setScript}] = useField<string>(field);
    const classes = useStyles();
    const [metadataExpanded, setMetadataExpanded] = useState(true);
    const handleCodeChanged = (code: string) => {
        setScript(code);
        onScriptChanged(code);
    }
    const handleSwitchExpand = useCallback(() => {
        setMetadataExpanded(v => !v);
    }, []);
    return (
        <Box display="flex" flexDirection="row" style={{height: "100%", width: "100%"}}>
        <div style={{height: "100%", width: metadataExpanded ? "calc(100% - 400px)" : "100%"}}>
            {(!!error) &&
                <Accordion className={classes.errorRoot}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.accordSummary}>
                        <ErrorIcon className={classes.errorIcon}/>
                        <Typography noWrap className={classes.heading}>
                            Error in {error.nodeName}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid style={{width: "100%"}}>
                            <SummaryField label="Node" value={error.nodeName} noUnderline breakAll/>
                            <SummaryField label="On Line" value={error.lineNumber} noUnderline/>
                            <SummaryField label="Error" value={error.text} noUnderline breakAll/>
                            <SummaryField label="StackTrace" value={error.stackTrace} noUnderline breakAll/>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            {executing && <FloatingBox><Fab size="small"><CircularProgress size={16}/></Fab></FloatingBox>}
            <ReadOnlyContext.Consumer>
                {readOnly => <CodeEditor
                    readOnly={readOnly}
                    code={script}
                    onSave={onSaveRequest}
                    onCodeChanged={handleCodeChanged}
                    codeErrors={scriptErrors}
                    language="csharp"
                    onRequestAutoComplete={onRequestAutoComplete}/>}
            </ReadOnlyContext.Consumer>
        </div>
        <FabContainerBox>
            <Fab color="primary" size="small" onClick={handleSwitchExpand}>
                {metadataExpanded ? <ChevronRightIcon fontSize="small"/> : <ChevronLeftIcon fontSize="small"/>}
            </Fab>
        </FabContainerBox>
        {metadataExpanded && <MetadataTree metadata={metadata}/>}
    </Box>);
}

interface IMetadataTreeProps {
    metadata: IMacroScriptMetadata;
}

function MetadataTree({ metadata }: IMetadataTreeProps) {
    const [filter, setFilter] = useState("");
    const handleChangeFilter = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value), []);
    const filteredMetadata = useMemo(() => {
        if (!filter || filter === "") {
            return metadata;
        }
        return toDictionary(Object.values(metadata).filter(i => (i.name ?? "").toLowerCase().includes(filter.toLowerCase())), i => i.name ?? "");
    }, [metadata, filter]);
    return <Box display="flex" flexDirection="column" width={400}>
        <TextField value={filter} onChange={handleChangeFilter} label="Filter" />
        <div style={{ height: "100%", overflowY: "auto", width: 400 }}>
            <ReactJson
                style={{ width: 600 }}
                name="Referential"
                collapsed={1}
                displayDataTypes={false}
                displayObjectSize={false}
                src={filteredMetadata} />
        </div>
    </Box>
}