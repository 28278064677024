import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { cashMovementsApi, documentsApi, IDocumentModel, IGetCashMovementModel } from "proxy/apiProxy";
import { ActionFactories, IAnyAction, IState } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadCashMovements: Epic<IAnyAction, IAnyAction, IState>
    = action$ => action$.pipe(
        mapToPayload("cashMovement", "cashMovementsSearch"),
        mergeMap(cashMovementsApi.searchAsync),
        map(ActionFactories.cashMovement.cashMovementsLoadedAll));

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("cashMovement", "cashMovementDocumentsLoad"),
        mergeMap(id => cashMovementsApi.getDocumentsAsync({ id })),
        map(ActionFactories.cashMovement.cashMovementDocumentsLoaded));

export const loadCashMovement: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("cashMovement", "cashMovementLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => cashMovementsApi.getAsync({ id })),
                map(ActionFactories.cashMovement.cashMovementLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    cashMovement: {
                        classifications: {}
                    },
                    entities: {},
                    portfolios: {},
                    securities: {}
                } as IGetCashMovementModel)),
                map(ActionFactories.cashMovement.cashMovementLoaded)));
    };

export const saveCashMovement: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("cashMovement", "cashMovementSave"),
        mergeMap(async ({ cashMovement, file }) => {
            const savedCashMovement = await cashMovementsApi.saveAsync({ model: cashMovement });

            let document: IDocumentModel | undefined;

            if (file) {
                const filename = file?.fileName || "Cash Movement Document";
                const documentModel = {
                    id: 0,
                    alias: filename,
                    name: filename,
                    classifications: {},
                    related: {
                        entityIds: [],
                        relationshipIds: [],
                        portfolioIds: [],
                        securityIds: [],
                        transactionIds: [],
                        cashMovementIds: [savedCashMovement.id]
                    },
                    metadata: {}
                };

                document = await documentsApi.saveAsync({ model: documentModel });

                const { content, mimeType, fileName } = file;
                if (content) {
                    await documentsApi.saveFileAsync({
                        id: document.id,
                        fileModel: { data: content, mimeType, name: fileName }
                    });
                } else {
                    // FIXME Properly handle empty files with a warning and not brutally reject them
                    console.error(`File ${fileName} has no content. Not saving it.`);
                }
            }
            else if (file === null) {
                console.error(`File cannot be deleted`);
            }

            return { cashMovement: savedCashMovement, document };
        }),
        map(ActionFactories.cashMovement.cashMovementSaved));

export const deleteCashMovement: Epic<IAnyAction>
    = (action$) => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("cashMovement", "cashMovementDelete"),
            mergeMap(id => cashMovementsApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.cashMovement.cashMovementDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };
