import * as React from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, makeStyles } from "@material-ui/core";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IFileTypeModel } from "proxy/apiProxy";
import { IComponentsContext, IDashboardLinkProps, IExecuteEtlProps, IProcessTaskFileProps, IProcessTaskQuestionnaireProps, IQuestionnaireProps, IReportLinkProps, IScreenLinkProps } from "../dashboard/ComponentProvider";
import { ScreenLink } from "tools/routing/ScreenLink";
import { ScreenKey } from "tools/routing/screenRouteHooks";
import { ScreenLinkType } from "../dashboard/DashboardFormattingContracts";
import { IMenuTargetDashboard, IMenuTargetScreen } from "features/App/menu/menuContracts";
import ExplorerElement from "./ExplorerElement";
import FolderDownloadOutlineIcon from 'mdi-material-ui/FolderDownloadOutline'
import clsx from "clsx";
import { fileToBase64 } from "tools/lib/utility";
import { SurveyBeforeCompleteProps, SurveyRunnerDialog, SurveyRunnerResultHandler } from "tools/components/SurveyRunnerDialog";
import DropZone from "tools/components/DropZone";

const useStyles = makeStyles(theme => ({
    // card: {
    //     boxShadow: theme.shadows[5],
    //     borderRadius: theme.shape.borderRadius,
    //     display: "flex",
    //     flexDirection: "column",
    //     alignItems: "stretch"
    // },
    // cardHeader: {
    //     borderTopLeftRadius: theme.shape.borderRadius,
    //     borderTopRightRadius: theme.shape.borderRadius,
    //     backgroundColor: theme.palette.primary.main,
    //     color: theme.palette.text.primary,
    //     padding: theme.spacing(1),
    //     display: "flex",
    //     alignItems: "center"
    // },
    // cardContent: {
    //     borderBottomLeftRadius: theme.shape.borderRadius,
    //     borderBottomRightRadius: theme.shape.borderRadius,
    //     flexGrow: 1,
    //     flexDirection: "column",
    //     display: "flex",
    //     "&>*": {
    //         flexGrow: 1
    //     },
    //     overflowX: "auto"
    // },
    // cardContentHidden: {
    //     visibility: "hidden"
    // },
    dashboardLink: {
        textAlign: "left",
        fontSize: "1.2em"
    },
    disabledLink: {
        "&:hover": {
            textDecoration: "initial",
        },
        cursor: "not-allowed",
    }
}));
// export interface ICollapsiblePanelProps {
//     children: React.ReactNode;
//     collapsedByDefault: boolean;
//     title?: string;
//     gridArea?: string;
// }
// export function CollapsiblePanel({ children, collapsedByDefault, title, gridArea }: ICollapsiblePanelProps) {
//     const [expanded, setExpanded] = React.useState(!collapsedByDefault);
//     const styles = useStyles();
//     const handleOnExpandChanged = React.useCallback((exp: boolean) => void setExpanded(exp), []);
//     return <Box className={clsx(styles.card)} gridArea={gridArea}>
//         <Box className={clsx(styles.cardHeader)}>
//             <Typography variant="h5">{title}</Typography>
//             <Box flexGrow={1} />
//             <ExpandCollapseButton expanded={expanded} onChanged={handleOnExpandChanged} />
//         </Box>
//         {expanded && <Box className={clsx(styles.cardContent)}>
//             <div>{children}</div>
//         </Box>}
//         {/* <Box className={clsx(styles.cardContent, !expanded && styles.cardContentHidden)}>
//             <div>{children}</div>
//         </Box> */}
//     </Box>;
// }













// export type IMonitoringResultValueDateTypeModel = "Date" | "DateTime";


// export type ISentimentModel = "Neutral" | "Positive" | "Warning" | "Negative";

// export type IProgressionLevelModel = "Still" | "Raise" | "LargeRaise" | "LargeDrop" | "Drop";

// export type IMonitoringResultValueNumberTypeModel = "Number2" | "Number4" | "Integer" | "Percentage" | "Percentage2" | "Percentage4";








// interface IDirectionComponentProps {
//     direction?: IProgressionLevelModel
//     sentiment?: ISentimentModel;
// }
// function getSentimentColor(sentiment: ISentimentModel | undefined): DataType.Color | undefined {
//     switch (sentiment) {
//         case "Negative": return "red";
//         case "Warning": return "orange";
//         case "Positive": return "green";
//     }
//     return;
// }
// function DirectionComponent({ direction, sentiment }: IDirectionComponentProps) {
//     if (typeof (direction) === "undefined") {
//         return null;
//     }

//     const sentimentColor = getSentimentColor(sentiment);

//     switch (direction) {
//         case "LargeRaise": return <ArrowUpBoldIcon style={{ color: sentimentColor }} />;
//         case "Raise": return <TrendingUpIcon style={{ color: sentimentColor }} />;
//         case "Still": return <TrendingFlatIcon style={{ color: sentimentColor }} />;
//         case "Drop": return <TrendingDownIcon style={{ color: sentimentColor }} />;
//         case "LargeDrop": return <ArrowDownBoldIcon style={{ color: sentimentColor }} />;
//     }
// }


// function getNumberTextValue(value: number | undefined, numberType: IMonitoringResultValueNumberTypeModel): string {
//     switch (numberType) {
//         case "Integer": return formatInteger(value);
//         case "Number2": return formatDecimal(value);
//         case "Number4": return formatPreciseDecimal(value);
//         case "Percentage": return formatPercentage(value);
//         case "Percentage2": return formatPrecisePercentage(value);
//         case "Percentage4": return formatPrecisePercentage(value, 4);
//     }
// }


// export interface ICheckComponentProps {
//     checked?: boolean
// }
// export function CheckComponent({ checked }: ICheckComponentProps) {
//     if (typeof (checked) === "undefined") {
//         return null;
//     }
//     if (checked) {
//         return <CheckBoxOutlinedIcon />;
//     }
//     return <CheckBoxOutlineBlankOutlinedIcon />;
// }
// export interface IValueResultNumberComponentProps {
//     numberType: IMonitoringResultValueNumberTypeModel;
//     value?: number;
//     sentiment?: ISentimentModel;
//     direction?: IProgressionLevelModel;
//     label: string;
// }
// export function ValueResultNumberComponent({ label, value, direction, sentiment, numberType }: IValueResultNumberComponentProps) {
//     const textValue = getNumberTextValue(value, numberType);

//     return <SummaryField
//         label={label}
//         value={textValue}
//         rightAlign={true}
//         color={getSentimentColor(sentiment)}
//         startAdornment={(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />} />
// }
// export interface IValueResultCheckComponentProps {
//     value?: boolean;
//     sentiment?: ISentimentModel;
//     direction?: IProgressionLevelModel;
//     label: string;

// }
// export function ValueResultCheckComponent({ label, value, direction, sentiment }: IValueResultCheckComponentProps) {
//     return <SummaryField
//         label={null}
//         value={label}
//         color={getSentimentColor(sentiment)}
//         startAdornment={<>
//             {(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
//             {<CheckComponent checked={value} />}
//         </>
//         }
//     />
// }
// function getDateTimeTextValue(value: Date | undefined, dateType: IMonitoringResultValueDateTypeModel): string {
//     switch (dateType) {
//         case "Date": return formatDate(value);
//         case "DateTime": return formatDateTime(value);
//     }
// }

// export interface IValueResultDateComponentProps {
//     dateType: IMonitoringResultValueDateTypeModel;
//     value?: Date;
//     sentiment?: ISentimentModel;
//     direction?: IProgressionLevelModel;
//     label: string;
// }
// export function ValueResultDateComponent({ label, value, direction, sentiment, dateType }: IValueResultDateComponentProps) {
//     const textValue = getDateTimeTextValue(value, dateType);

//     return <SummaryField
//         label={label}
//         value={textValue}
//         color={getSentimentColor(sentiment)}
//         startAdornment={(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
//     />
// }
// export interface IValueResultStringComponentProps {
//     value?: string;
//     sentiment?: ISentimentModel;
//     direction?: IProgressionLevelModel;
//     label: string;
// }
// export function ValueResultStringComponent({ label, value, direction, sentiment }: IValueResultStringComponentProps) {
//     return <SummaryField
//         label={label}
//         value={value}
//         color={getSentimentColor(sentiment)}
//         startAdornment={(typeof (direction) !== "undefined") && <DirectionComponent direction={direction} sentiment={sentiment} />}
//     />
// }
// export interface IValueResultMarkdownComponentProps {
//     value: string;
// }
// export function ValueResultMarkdownComponent({ value }: IValueResultMarkdownComponentProps) {
//     return <MarkDown markDown={value} />
// }
// interface IMarkDownProps {
//     markDown: string;
// }
// function MarkDown({ markDown }: IMarkDownProps) {
//     const converter = new showdown.Converter();
//     converter.setFlavor("github")
//     const htmlText = converter.makeHtml(markDown);
//     return <div className="markdown-body">{ReactHtmlParser(htmlText)}</div>
// }

// function getScreenKey(referenceType: ScreenLinkType): ScreenKey {
//     switch (referenceType) {
//         case ScreenLinkType.Person:
//             return "Person";
//         case ScreenLinkType.Company:
//             return "Company";
//         case ScreenLinkType.EntityGroup:
//             return "EntityGroup";
//         case ScreenLinkType.ManagedSicav:
//             return "MySicav";
//         case ScreenLinkType.Portfolio:
//             return "MyPortfolio";
//         case ScreenLinkType.ShareClass:
//             return "MyShareClass";
//         case ScreenLinkType.Sicav:
//             return "Sicav";
//         case ScreenLinkType.SubFund:
//             return "SubFund";
//         case ScreenLinkType.Security:
//             return "Security";
//         case ScreenLinkType.Investor:
//             return "Investor";
//         case ScreenLinkType.Role:
//             return "ServiceProvidersCollaborator";
//         case ScreenLinkType.Counterparty:
//             return "Counterparty";
//         case ScreenLinkType.Index:
//             return "Index";
//         case ScreenLinkType.Movement:
//             return "CashMovement";
//         case ScreenLinkType.PortfolioTransaction:
//             return "PortfolioTransaction";
//         case ScreenLinkType.Cash:
//             return "BankAccount";
//         case ScreenLinkType.ProcessExecution:
//             return "ProcessExecution";
//     }
// }

// export interface IScreenLinkProps {
//     id: number | undefined;
//     textValue: string;
//     referenceType: ScreenLinkType;
// }

// export function DashboardLink({ id, textValue, referenceType }: IScreenLinkProps) {
//     return <Link component={ScreenLink}
//         screenKey={getScreenKey(referenceType)}
//         params={{ id }}>
//         {textValue}
//     </Link>;
// }


// export interface IFileLinkProps {
//     id: number | undefined;
//     textValue: string;
//     referenceType: FileLinkType;
// }
// export function FileLink({ id, textValue, referenceType }: IFileLinkProps) {
//     const { cashMovementLoadFile } = useReduxActions("cashMovement");
//     const { entityFileContentLoad } = useReduxActions("entity");
//     const { portfolioFileContentLoad } = useReduxActions("managedPortfolio");
//     const { securityFileContentLoad } = useReduxActions("security");
//     // const { processExecutionLoadFile } = useReduxActions("processExecution");

//     const handleClick = React.useCallback((event: React.MouseEvent) => {
//         event.preventDefault();
//         if (!id) return
//         switch (referenceType) {
//             case FileLinkType.CashMovementFile:
//                 cashMovementLoadFile(id);
//                 break;
//             case FileLinkType.EntityDocumentFile:
//                 entityFileContentLoad({ fileId: id });
//                 break;
//             case FileLinkType.PortfolioDocumentFile:
//                 portfolioFileContentLoad({ fileId: id });
//                 break;
//             case FileLinkType.SecurityDocumentFile:
//                 securityFileContentLoad({ fileId: id });
//                 break;
//             // case "processExecutionTaskFile":
//             //     processExecutionLoadFile({ fileId: id });
//             //     break;
//         }
//     }, [cashMovementLoadFile, entityFileContentLoad, id, portfolioFileContentLoad, securityFileContentLoad, referenceType]);

//     const style = useStyles();
//     return <Link
//         className={clsx(style.dashboardLink, !id ? style.disabledLink : null)}
//         component="button"
//         onClick={handleClick}>
//         {textValue}
//     </Link>;
// }

function LinkToDashboard({ layoutName, datasetName, asOfDate, culture, parameters, target, textValue, disabled, screenTitle, color, backgroundColor }: IDashboardLinkProps) {
    const style = useStyles();
    const destination = React.useMemo(() => {
        const ret: IMenuTargetDashboard = {
            type: "Dashboard",
            dashboardName: layoutName,
            datasetName,
            executionModel: {
                asOfDate: typeof asOfDate === "number" ? new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + asOfDate) : asOfDate,
                culture,
                parameters,
                related: target
            },
            label: screenTitle ?? textValue
        };
        return ret;
    }, [asOfDate, culture, datasetName, layoutName, parameters, screenTitle, target, textValue])
    return <Link
        style={{ color, backgroundColor }}
        component={ScreenLink} destination={destination} className={clsx({ [style.disabledLink]: disabled }, style.dashboardLink)}>
        {textValue}
    </Link>;
}
function DashboardScreenLink({ textValue, params, target, tab, disabled, color, backgroundColor }: IScreenLinkProps) {
    const style = useStyles();
    const destination = React.useMemo(() => {
        const ret: IMenuTargetScreen = {
            type: "Screen",
            key: getScreenKey(target),
            label: textValue,
            params,
            tab
        };
        return ret;
    }, [params, tab, target, textValue]);
    return <Link
        style={{ color, backgroundColor }}
        component={ScreenLink} destination={destination} className={clsx({ [style.disabledLink]: disabled }, style.dashboardLink)}>
        {textValue}
    </Link>;
}
function getScreenKey(target: ScreenLinkType): ScreenKey {
    switch (target) {
        case ScreenLinkType.Person: return "Person";
        case ScreenLinkType.People: return "People";
        case ScreenLinkType.Company: return "Company";
        case ScreenLinkType.Companies: return "Companies";
        case ScreenLinkType.EntityGroup: return "EntityGroup";
        case ScreenLinkType.EntityGroups: return "EntityGroups";
        case ScreenLinkType.ManagedSicav: return "MySicav";
        case ScreenLinkType.ManagedSicavs: return "MySicavs";
        case ScreenLinkType.Portfolio: return "MyPortfolio";
        case ScreenLinkType.Portfolios: return "MyPortfolios";
        case ScreenLinkType.ShareClass: return "MyShareClass";
        case ScreenLinkType.ShareClasses: return "MyShareClasses";
        case ScreenLinkType.Sicav: return "Sicav";
        case ScreenLinkType.Sicavs: return "Sicavs";
        case ScreenLinkType.SubFund: return "SubFund";
        case ScreenLinkType.SubFunds: return "SubFunds";
        case ScreenLinkType.Security: return "Security";
        case ScreenLinkType.Securities: return "Securities";
        case ScreenLinkType.Investor: return "Investor";
        case ScreenLinkType.Investors: return "Investors";
        case ScreenLinkType.Role: return "ServiceProvidersCollaborator";
        case ScreenLinkType.Roles: return "ServiceProvidersCollaborators";
        case ScreenLinkType.Counterparty: return "Counterparty";
        case ScreenLinkType.Counterparties: return "Counterparties";
        case ScreenLinkType.Index: return "Index";
        case ScreenLinkType.Indexes: return "Indexes";
        case ScreenLinkType.Movement: return "CashMovement";
        case ScreenLinkType.Movements: return "CashMovements";
        case ScreenLinkType.PortfolioTransaction: return "PortfolioTransaction";
        case ScreenLinkType.PortfolioTransactions: return "PortfolioTransactions";
        case ScreenLinkType.BankAccount: return "BankAccount";
        case ScreenLinkType.BankAccounts: return "BankAccounts";
        case ScreenLinkType.ProcessExecution: return "ProcessExecution";
        case ScreenLinkType.ProcessExecutions: return "ProcessExecutions";
        case ScreenLinkType.Dashboard: return "Dashboard";
        case ScreenLinkType.Loan: return "Loan";
        case ScreenLinkType.Document: return "Document";
    }
}

function ReportLink({ textValue, templateName: reportTemplateName, datasetName, fileType, parameters, culture, asOfDate, target, color, backgroundColor, disabled }: IReportLinkProps) {
    const { developmentItemReportTemplateGenerate } = useReduxActions("developmentItem");

    const handleClick = React.useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        developmentItemReportTemplateGenerate({
            body: {
                datasetName,
                templateName: reportTemplateName,
                // exportType:fileType
                exportType: getFileTypeModel(fileType) ?? IFileTypeModel.Pdf,
                parameters,
                related: target,
                type: "GenerateReportModel",
                culture,
                asOfDate: computeAsOfDate(asOfDate)
            }
        });
    }, [developmentItemReportTemplateGenerate, datasetName, reportTemplateName, fileType, parameters, target, asOfDate, culture]);

    const style = useStyles();
    return <Link
        style={{ color, backgroundColor }}
        className={clsx({ [style.disabledLink]: disabled }, style.dashboardLink)}
        component="button"
        onClick={handleClick}>
        {textValue}
    </Link>;
}
function computeAsOfDate(asOfDate: Date | number | undefined) {
    return asOfDate instanceof Date
        ? asOfDate
        : typeof asOfDate === "number"
            ? new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + asOfDate)
            : undefined
}
export type FileType = "pdf" | "docx" | "xlsx" | "xls" | "html" | "png" | "rtf" | "svg" | "jpg" | "pptx" | "txt";
function getFileTypeModel(fileType: FileType): IFileTypeModel;
function getFileTypeModel(fileType?: FileType): IFileTypeModel | undefined;
function getFileTypeModel(fileType?: FileType): IFileTypeModel | undefined {
    switch (fileType) {
        case "pdf": return IFileTypeModel.Pdf;
        case "docx": return IFileTypeModel.Docx;
        case "xlsx": return IFileTypeModel.Xlsx;
        case "html": return IFileTypeModel.Html;
        case "png": return IFileTypeModel.Png;
        case "rtf": return IFileTypeModel.Rtf;
        case "svg": return IFileTypeModel.Svg;
        case "jpg": return IFileTypeModel.Jpg;
        case "pptx": return IFileTypeModel.Pptx;
        case "txt": return IFileTypeModel.Txt;
        case "xls": return IFileTypeModel.Xls;
    }
    return undefined;
}

function ExecuteEtl({ textValue, noDownload, etlName, asOfDate, parameters, target, inputConnector, culture, disabled }: IExecuteEtlProps) {
    const { genericDashboardEtlExecute } = useReduxActions("genericDashboard");
    const { genericDashboardEltExecuting } = useReduxSelections("genericDashboard");
    const noUploadRunElt = React.useCallback(() => {
        genericDashboardEtlExecute({
            etlName,
            asOfDate: computeAsOfDate(asOfDate),
            culture,
            type: "ExecuteEtlDevelopmentModel",
            parameters,
            related: target,
            save: !noDownload
        })
    }, [asOfDate, culture, etlName, genericDashboardEtlExecute, noDownload, parameters, target]);
    const uploadRunElt = React.useCallback(async (file: File) => {
        if (inputConnector) {
            const openedFile = await fileToBase64(file);
            genericDashboardEtlExecute({
                etlName,
                asOfDate: computeAsOfDate(asOfDate),
                culture,
                type: "ExecuteEtlDevelopmentModel",
                parameters,
                related: target,
                save: !noDownload,
                inputFiles: { [inputConnector]: openedFile }
            });
        }
    }, [asOfDate, culture, etlName, genericDashboardEtlExecute, inputConnector, noDownload, parameters, target]);
    if (!inputConnector) {
        return <ExplorerElement disabled={disabled} icon={FolderDownloadOutlineIcon} name={textValue} processing={genericDashboardEltExecuting[etlName]} onClick={noUploadRunElt} />
    }
    else {
        return <ExplorerElement disabled={disabled} icon={FolderDownloadOutlineIcon} name={textValue} processing={genericDashboardEltExecuting[etlName]} onFileDrop={uploadRunElt} />
    }
}
function useTask(processExecutionId: number, processExecutionTaskName: string) {
    const { genericDashboardProcessesTaskState } = useReduxSelections("genericDashboard");
    const tmp = genericDashboardProcessesTaskState[processExecutionId];
    if (!tmp) {
        return {};
    }
    return tmp[processExecutionTaskName];
}
function ProcessTakFileContent({ processExecutionId, taskCode, textValue, disabled, currentFileName }: IProcessTaskFileProps) {
    const {
        genericDashboardProcessExecutionLoadFile,
        genericDashboardProcessExecutionSaveFile,
        genericDashboardProcessExecutionFileDelete
    } = useReduxActions("genericDashboard");
    const { deleting, loading, saving } = useTask(processExecutionId, taskCode);
    const isBusy = deleting || loading || saving

    const handleClick = React.useCallback(() => genericDashboardProcessExecutionLoadFile({ taskCode, id: processExecutionId }), [genericDashboardProcessExecutionLoadFile, taskCode, processExecutionId]);
    const handleDelete = React.useCallback(() => genericDashboardProcessExecutionFileDelete({ taskCode, id: processExecutionId }), [genericDashboardProcessExecutionFileDelete, taskCode, processExecutionId]);
    const handleSave = React.useCallback(async (file: File) => {
        const openedFile = await fileToBase64(file);
        genericDashboardProcessExecutionSaveFile({
            id: processExecutionId,
            taskCode: taskCode,
            fileModel: {
                data: openedFile.content,
                mimeType: openedFile.mimeType,
                name: openedFile.fileName
            },
        });
    }, [genericDashboardProcessExecutionSaveFile, processExecutionId, taskCode]);

    return <DropZone
        emptyLabel={textValue}
        disabled={disabled || isBusy}
        fileName={currentFileName}
        onClick={handleClick}
        onFileOpen={handleSave}
        onClear={handleDelete} />
}
function ProcessTaskQuestionnaireComponent({ processExecutionId, taskCode, textValue, disabled }: IProcessTaskQuestionnaireProps) {
    const { deleting, loading, saving, questionnaireDetails } = useTask(processExecutionId, taskCode);
    const isBusy = deleting || loading || saving
    const { genericDashboardProcessExecutionLoadQuestionnaire, genericDashboardProcessExecutionSaveQuestionnaire } = useReduxActions("genericDashboard");
    const { responses, template } = questionnaireDetails ?? {}
    const [isSurveyOpened, setIsSurveyOpened] = React.useState<boolean>(false);

    const handleReset = React.useCallback(() => {
        genericDashboardProcessExecutionLoadQuestionnaire({ getPreviousAnswers: false, taskCode, id: processExecutionId });
        setIsSurveyOpened(true);
    }, [genericDashboardProcessExecutionLoadQuestionnaire, processExecutionId, taskCode]);

    const handleOpenQuestionnaire = React.useCallback(() => {
        genericDashboardProcessExecutionLoadQuestionnaire({ getPreviousAnswers: true, taskCode, id: processExecutionId });
        setIsSurveyOpened(true);
    }, [genericDashboardProcessExecutionLoadQuestionnaire, processExecutionId, taskCode]);

    const handleCloseCancel = React.useCallback(() => {
        setIsSurveyOpened(false);
    }, []);

    const handleComplete = React.useCallback<SurveyRunnerResultHandler>((values) => {
        genericDashboardProcessExecutionSaveQuestionnaire({ taskCode, response: values, complete: true, id: processExecutionId });
        setIsSurveyOpened(false);
    }, [genericDashboardProcessExecutionSaveQuestionnaire, processExecutionId, taskCode]);

    const handleSave = React.useCallback<SurveyRunnerResultHandler>((values) => {
        genericDashboardProcessExecutionSaveQuestionnaire({ taskCode, response: values, complete: false, id: processExecutionId });
        console.log("Saved")
    }, [genericDashboardProcessExecutionSaveQuestionnaire, processExecutionId, taskCode]);

    // Keep in sync with ProcessExecutionService.IsTaskEditableOnPortal
    if (disabled) {
        return <Box>
            {isSurveyOpened && <SurveyRunnerDialog
                template={template} initialValues={responses}
                onCancel={handleCloseCancel}
                cancelLabel="Close"
                readOnly={true} />}
            <Box display="flex" justifyContent="space-evenly">
                <Button onClick={handleOpenQuestionnaire} disabled={isBusy}
                    endIcon={(isBusy) && <CircularProgress size={16} />}>
                    {textValue}
                </Button>
            </Box>
        </Box>
    }
    else {
        return <Box>
            {(!!template && !loading && isSurveyOpened) &&
                <SurveyRunnerDialog
                    template={template} initialValues={responses}
                    onCancel={handleCloseCancel} cancelLabel="Discard"
                    onComplete={handleComplete} completeLabel="Submit"
                    onOk={handleSave} okLabel="Save"
                    onReset={handleReset} resetLabel="Reset"
                    BeforeComplete={ConfirmAnswerCompleteDialog}
                />}
            <Box display="flex" justifyContent="space-evenly">
                <Button onClick={handleOpenQuestionnaire} disabled={isBusy}
                    endIcon={(isBusy) && <CircularProgress size={16} />}>
                    {textValue}
                </Button>
            </Box>
        </Box>
    }
}
function ConfirmAnswerCompleteDialog({ onConfirm, onCancel }: SurveyBeforeCompleteProps) {
    return <Dialog open={true}>
        <DialogTitle>Confirm final submission?</DialogTitle>
        <DialogContent>
            <DialogContentText>
                You are about to complete and submit your questionnaire.
                Once submitted, you will not be able to edit your responses.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel} color="primary">
                Let me review
            </Button>
            <Button onClick={onConfirm} color="primary" autoFocus>
                Complete and Submit
            </Button>
        </DialogActions>
    </Dialog>
}

function QuestionnaireComponent({ questionnaireName, textValue, asOfDate, backgroundColor, color, culture, disabled, getPreviousAnswers, parameters, target }: IQuestionnaireProps) {
    return null;
}
export const dashboardComponentsContext: IComponentsContext = {
    reportLinkComponent: ReportLink,
    screenLinkComponent: DashboardScreenLink,
    dashboardLinkComponent: LinkToDashboard,
    executeEtlComponent: ExecuteEtl,
    processTaskFileComponent: ProcessTakFileContent,
    processTaskQuestionnaireComponent: ProcessTaskQuestionnaireComponent,
    questionnaireComponent: QuestionnaireComponent
}