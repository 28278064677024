import * as React from "react";
import { IBatchSummaryModel } from "proxy/apiProxy";
import { useField } from 'formik';
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import SearchIcon from '@material-ui/icons/Search';
export interface IBatchSelectFieldProps {
    name: string;
    label?: React.ReactNode;
    required?: boolean;
    onSelected?: (found: IBatchSummaryModel) => void;
}
export function BatchSelectField({ name, label, required, onSelected }: IBatchSelectFieldProps) {
    const { referenceBatchSearch } = useReduxActions("reference");
    const { referenceBatchesSearched, referenceBatchSearching } = useReduxSelections("reference");
    const validate = (batchId: number | undefined) => {
        if (!required) {
            return;
        }
        return (batchId ?? 0) === 0 ? "Required" : undefined;
    }
    const [, { value, error, initialValue }, { setValue }] = useField<number | undefined>({ name, validate });
    const getOptionLabel = (batch: IBatchSummaryModel) => batch.name;
    label = <><SearchIcon fontSize="small"/> {label ?? "Batch"}: Type criteria to find a batch {required ? "*" : ""}</>;
    if ((initialValue || null) !== (value || null)) {
        label = <b>{label}</b>;
    }
    const handleValueSelected = (batch: IBatchSummaryModel) => {
        if (onSelected) {
            onSelected(batch)
        }
        setValue(batch.id);
    }
    return <ReadOnlyContext.Consumer>{readOnly => readOnly ? null : <AsyncSelect onSearchOptions={referenceBatchSearch} options={referenceBatchesSearched}
        getOptionLabel={getOptionLabel} searching={referenceBatchSearching} label={label} onRequiredValueSelected={handleValueSelected} error={!!error} helperText={error} />}
    </ReadOnlyContext.Consumer>
}
