import { Epic, ofType } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { roleRelationshipClassificationTypesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadClassifications: Epic<IAnyAction>
    = action$ =>
        merge(
            action$.pipe(ofType("applicationLoaded"), map(i => null)),
            action$.pipe(ofType("roleRelationshipClassificationTypeLoadAll"), map(i => null)),
            action$.pipe(ofType("roleRelationshipClassificationTypeSaved"), map(i => null)),
            action$.pipe(ofType("referenceRefreshAll"), map(i => null))
        ).pipe(
            mergeMap(() => roleRelationshipClassificationTypesApi.getAllAsync({})),
            map(ActionFactories.roleRelationshipClassificationType.roleRelationshipClassificationTypeAllLoaded));

export const loadClassification: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("roleRelationshipClassificationType", "roleRelationshipClassificationTypeLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => roleRelationshipClassificationTypesApi.getAsync({ id }))),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({ id: 0, name: { en: "" }, code: "", classifications: [] })))
        ).pipe(
            map(ActionFactories.roleRelationshipClassificationType.roleRelationshipClassificationTypeLoaded));
    };
export const saveClassification: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("roleRelationshipClassificationType", "roleRelationshipClassificationTypeSave"),
        mergeMap(model => roleRelationshipClassificationTypesApi.saveAsync({ model })),
        map(ActionFactories.roleRelationshipClassificationType.roleRelationshipClassificationTypeSaved));
export const deleteClassification: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("roleRelationshipClassificationType", "roleRelationshipClassificationTypeDelete"),
            mergeMap(id => roleRelationshipClassificationTypesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.roleRelationshipClassificationType.roleRelationshipClassificationTypeDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    }
