import { Epic } from "redux-observable";
import { merge, of } from "rxjs";
import { map, mergeMap, share, switchMap } from "rxjs/operators";
import { fxRatesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload, withLatestFromBuffer } from "lib/rxJsUtility";

export const loadCurrencyHistoricalValues: Epic<IAnyAction>
    = action$ => {
        const valuesLoad$ = action$.pipe(
            mapToPayload("fxRates", "fxRatesHistoricalValuesLoad"),
            share());
        const historicalValues$ = valuesLoad$.pipe(
            withLatestFromBuffer(action$.pipe(mapToPayload("reference", "referenceCurrenciesLoaded"))),
            switchMap(([currencyToId, currencies]) => of(({ currencyToId })).pipe(
                mergeMap(i => fxRatesApi.getHistoricalValuesAsync(i)),
                map(historicalValues => ActionFactories.fxRates.fxRatesHistoricalValuesLoaded({ historicalValues, targetCurrency: currencies[currencyToId] })))));

        return merge(
            historicalValues$,
            valuesLoad$.pipe(map(() => ActionFactories.parameters.parametersLoad())));
    }

export const saveHistoricalValue: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("fxRates", "fxRatesHistoricalValueSave"),
        mergeMap(historicalValuePayload => of(historicalValuePayload).pipe(
            mergeMap(({ currencyToId, fxValue }) => fxRatesApi.saveHistoricalValueAsync({ currencyToId, historicalValueSet: fxValue })),
            map(() => ActionFactories.fxRates.fxRatesHistoricalValueSaved(historicalValuePayload)))));
