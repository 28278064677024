import * as React from "react";
import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import {
    IFeatureModel,
    IRelationshipSummaryModel,
    IRoleRelationshipModel,
    IRoleRelationshipSummaryModel
} from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { plural } from "pluralize";
import { getEntityName } from "tools/lib/modelUtils";
import { getRelationshipTypeLabel } from "./getRelationshipTypeLabel";
import { roleCollaborationTypes, roleDomains, rolePositions } from "./FormInterface";
import { getEntityTypeLabel } from "features/Entity/getEntityTypeLabel";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useCallback, useEffect } from "react";

function isRoleRelationshipSummary(r: IRoleRelationshipSummaryModel | IRoleRelationshipModel): r is IRoleRelationshipModel {
    return !!(r as IRoleRelationshipModel)?.permissions;
}


export const investorRelationshipsScreen = {
    component: InvestorRelationships,
    route: "/investors" as const,
    label: "Investor Relationships",
    type: "InvestorRelationshipModel" as const,
    childDetailScreenKey: "Investor" as const,
}

function InvestorRelationships() {
    return <Relationships screen={investorRelationshipsScreen} />
}

export const counterpartyRelationshipsScreen = {
    component: CounterpartyRelationships,
    route: "/counterparties" as const,
    label: "Counterparty Relationships",
    type: "CounterpartyRelationshipModel" as const,
    childDetailScreenKey: "Counterparty" as const,
}

function CounterpartyRelationships() {
    return <Relationships screen={counterpartyRelationshipsScreen} />
}

export const roleRelationshipsScreen = {
    component: RoleRelationships,
    route: "/collaborators" as const,
    label: "Role Relationships",
    type: "RoleRelationshipModel" as const,
    childDetailScreenKey: "ServiceProvidersCollaborator" as const,
}

function RoleRelationships() {
    return <Relationships screen={roleRelationshipsScreen} />
}

type RelationshipsScreens =
    typeof investorRelationshipsScreen
    | typeof counterpartyRelationshipsScreen
    | typeof roleRelationshipsScreen

function Relationships({ screen }: { screen: RelationshipsScreens }) {
    const navigate = useScreenNavigate();
    const { allLoading, all, dictionaries: { entities } } = useReduxSelections("relationship");
    const { relationshipLoadAll } = useReduxActions("relationship");

    useEffect(() => {
        relationshipLoadAll(screen.type)
    }, [relationshipLoadAll, screen.type]);

    const { columns, state } = React.useMemo(() => {
        const specificColumns = (function () {
            switch (screen.type) {
                case "RoleRelationshipModel":

                    return [
                        { name: "CollaborationType", title: "Collaboration Type", getCellValue: (r: IRoleRelationshipSummaryModel) => r.collaborationType && roleCollaborationTypes[r.collaborationType], filteringEnabled: true },
                        { name: "Admin", title: "Admin", getCellValue: (r: IRoleRelationshipSummaryModel | IRoleRelationshipModel) => isRoleRelationshipSummary(r) ? r.permissions.includes(IFeatureModel.SysAdmin) : r.isAdmin, columnType: "boolean" },
                        { name: "Domain", title: "Domain", getCellValue: (r: IRoleRelationshipSummaryModel) => r.domain && roleDomains[r.domain], filteringEnabled: true },
                        { name: "Position", title: "Position", getCellValue: (r: IRoleRelationshipSummaryModel) => r.position && rolePositions[r.position], filteringEnabled: true },
                    ] as IColumnDefinition[];
                default: return [];
            }
        })();
        const specificStates = (function () {
            switch (screen.type) {
                case "RoleRelationshipModel":
                    return {
                        "CollaborationType": { width: 150 },
                        "Admin": { width: 50, hidden: true },
                        "Domain": { width: 200 },
                        "Position": { width: 150 },
                    } as IGridState;
                default: return {
                    "Process": { width: 200 }
                };
            }
        })();
        return {
            columns: [
                { name: "Entity", title: "Entity", getCellValue: ({ entityId }: IRelationshipSummaryModel) => getEntityName(entities[entityId]) ?? "", positionToKeep: "left", filteringEnabled: true },
                { name: "InternalCode", title: "Internal Code", getCellValue: ({ entityId }: IRelationshipSummaryModel) => entities[entityId]?.internalCode, positionToKeep: "left", filteringEnabled: true },
                { name: "Type", title: "Type", getCellValue: ({ entityId }: IRelationshipSummaryModel) => getEntityTypeLabel(entities[entityId].type) },
                { name: "Title", title: "Title", getCellValue: (r: IRoleRelationshipSummaryModel) => r.title, filteringEnabled: true },
                ...specificColumns,
                { name: "StartDate", title: "Start Date", getCellValue: ({ startDate }: IRelationshipSummaryModel) => startDate, columnType: "date" },
                { name: "EndDate", title: "End Date", getCellValue: ({ endDate }: IRelationshipSummaryModel) => endDate, columnType: "date" },
            ] as IColumnDefinition[],
            state: {
                "Entity": { width: 280 },
                "InternalCode": { width: 200 },
                "Type": { width: 120, hidden: true },
                "Title": { width: 200 },
                ...specificStates,
                "StartDate": { width: 150 },
                "EndDate": { width: 150 },
            } as IGridState
        };
    }, [entities, screen.type]);

    const getRowKey = ({ id }: IRelationshipSummaryModel) => id;

    const handleRowSelect = useCallback(({ id }: IRelationshipSummaryModel) => navigate(screen.childDetailScreenKey, { id }), [navigate, screen.childDetailScreenKey])

    const handleAddNewClick = useCallback(() => navigate(screen.childDetailScreenKey, { id: 0 }), [navigate, screen.childDetailScreenKey])

    return <SearchPanel title={`${plural(getRelationshipTypeLabel(screen.type))}`} isQuerying={allLoading}
        onAddClick={handleAddNewClick}>
        <ExtendedGrid
            getRowId={getRowKey}
            columns={columns}
            rows={all}
            onRowClick={handleRowSelect}
            initialState={state}
            userCanGroup={true}
            defaultExportFileName={`${screen.type.replace("Model", "s")}.xlsx`} />
    </SearchPanel>;
}
