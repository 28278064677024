import { ICurrencyModel, IEntityPositionModel, ICountryModel, IClassificationTypeModel, ISubFundSummaryModel, SecuritySummaryModel, RegularSecuritySummaryModel, isRegularSecuritySummaryModel } from "proxy/apiProxy";
import { formatPrecisePercentage } from "tools/lib/utility";
import { CardHeader, CardContent, Card, Grid } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { GridSize } from "@material-ui/core/Grid";
import SimplePieChart, { ISimplePieChartArgs } from "tools/components/SimplePieChart";
import { IFlatClassificationTypes } from "features/Classification/getFlatClassificationTypes";
import { getSecurityTypeLabel } from "features/Security/getSecurityTypeLabel";

export interface IEntityCompositionGridProps {
    subFunds: Record<number | string, ISubFundSummaryModel>;
    securities: Record<number | string, SecuritySummaryModel>;
    positions: IEntityPositionModel[];
    referenceCountries: Record<number | string, ICountryModel>;
    referenceCurrencies: Record<number | string, ICurrencyModel>;
    classificationTypesFlat: IFlatClassificationTypes;
    classificationTypes: IClassificationTypeModel[];
}

interface IChartDefinition<T> extends Omit<ISimplePieChartArgs<T>, "rows"> {
    label: string;
}

interface ICompositionChartArgs<T> {
    rows: T[];
    definition: IChartDefinition<T>;
}

function CompositionChart<T>({ definition: { label, ...definition }, rows }: ICompositionChartArgs<T>) {
    return <Card>
        <CardHeader title={label} />
        <CardContent style={{ height: 300 }}>
            <SimplePieChart {...definition} rows={rows} getSliceLabel={formatPrecisePercentage} />
        </CardContent>
    </Card>;
}

export default function EntityCompositionChart({
    subFunds,
    securities,
    positions,
    referenceCountries,
    referenceCurrencies,
    classificationTypesFlat,
    classificationTypes
}: IEntityCompositionGridProps) {
    const layoutSizes: Partial<Record<Breakpoint, boolean | GridSize>> = { xl: 4, lg: 4, md: 6, sm: 6, xs: 12 };
    // {...layoutSizes}
    const getChartDefinitions = () => {
        const getValue = ({ weight = 0 }: IEntityPositionModel) => weight;
        return [
            {
                label: "Type",
                getArgument: ({ securityId }) => getSecurityTypeLabel(securities[securityId].type),
                getValue
            } as IChartDefinition<IEntityPositionModel>,
            {
                label: "Currency",
                getArgument: ({ securityId }: IEntityPositionModel) => {
                    const currencyId = securities[securityId].currencyId;
                    if (!currencyId) {
                        return undefined;
                    }
                    return referenceCurrencies[currencyId]?.isoCode ?? "???";
                },
                getValue,
            },
            {
                label: "Country",
                getArgument: ({ securityId }: IEntityPositionModel) => {
                    const secu = securities[securityId];
                    let countryId: number | undefined;
                    if (secu.type === "ShareClassSummaryModel" && secu.subFundId) {
                        if (subFunds[secu.subFundId].countryId) {
                            countryId = subFunds[secu.subFundId].countryId
                        }
                    }
                    else if (isRegularSecuritySummaryModel(secu)) {
                        countryId = secu.countryId;
                    }
                    if (!countryId) {
                        return undefined;
                    }
                    const country = referenceCountries[countryId];
                    if (!country) {
                        return "???";
                    }
                    return country.name?.en;
                },
                getValue,
            },
            ...classificationTypes.map(classificationType => ({
                label: classificationType.name["en"],
                getArgument: ({ securityId }) => {
                    const classifications = (securities[securityId] as RegularSecuritySummaryModel).classifications;
                    if (!classifications) {
                        return;
                    }
                    const classificationId = classifications[classificationType.id];
                    const classificationPath = classificationTypesFlat[classificationType.id][classificationId];
                    if (!classificationPath) {
                        return;
                    }
                    return classificationPath[0].name["en"];
                },
                getValue
            } as IChartDefinition<IEntityPositionModel>)),
        ];
    }
    const chartDefinitions = getChartDefinitions();
    return <Grid container={true} spacing={3} style={{ height: "100%", overflowY: "auto" }}>
        {chartDefinitions.filter(chartDefinition => positions.some(chartDefinition.getArgument)).map((chartDefinition, idx) => <Grid key={idx} {...layoutSizes} item={true}>
            <CompositionChart rows={positions} definition={chartDefinition} />
        </Grid>)}
    </Grid>;
}
