import * as React from "react";
import {
    TableInlineCellEditing as TableInlineCellEditingBase,
} from '@devexpress/dx-react-grid'; import classNames from "clsx";
import Input from "@material-ui/core/Input";
import TableCell from "@material-ui/core/TableCell";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { StandardProps } from "@material-ui/core";
const useStyle = makeStyles(theme => createStyles({
    cell: {
        padding: theme.spacing(1),
        // NOTE: without the TableEditColumn first EditCell changes size
        // (because first TableCell and EditCell have different paddings)
        "&:first-child": {
            paddingLeft: theme.spacing(3)
        }
    },
    inputRoot: {
        width: "100%"
    },
    disabledInput: {
        color: theme.palette.action.disabled,
        "&:before": {
            borderBottom: "1px dotted",
            borderBottomColor: theme.palette.action.disabled
        },
        "&&:hover:before": {
            borderBottom: "1px dotted",
            borderBottomColor: theme.palette.action.disabled
        },
        "&:after": {
            borderBottom: "0px"
        }
    },
    inputRight: {
        textAlign: "right"
    },
    inputCenter: {
        textAlign: "center"
    }
}));

export default function TableEditCell({
    column,
    value,
    onValueChange,
    style,
    children,
    row,
    tableRow,
    tableColumn,
    editingEnabled,
    className,
    autoFocus,
    onBlur,
    onFocus,
    onKeyDown,
    ...restProps
}: React.PropsWithChildren<TableInlineCellEditingBase.CellProps> & StandardProps<TableInlineCellEditingBase.CellProps, any>) {
    const classes=useStyle();
    const inputClasses = classNames({
        [classes.inputRight]: tableColumn && tableColumn.align === "right",
        [classes.inputCenter]: tableColumn && tableColumn.align === "center"
    });
    // const patchedChildren = children
    //     ? React.cloneElement(children, {
    //         autoFocus,
    //         onBlur,
    //         onFocus,
    //         onKeyDown
    //     })
    //     : children;

    const [tmp, setTmp] = React.useState(value);
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.key === "Enter") {
            onValueChange(tmp);
            //console.log(row, column);
        }
        setTimeout(() => onKeyDown(e), 0);
    };

    return (
        <TableCell
            className={classNames(classes.cell, className)}
            style={style}
            {...restProps}
        >
            <Input
                className={classNames({
                    [classes.inputRoot]: true,
                    [classes.disabledInput]: !editingEnabled
                })}
                classes={{ input: inputClasses }}
                value={tmp}
                readOnly={!editingEnabled}
                onChange={(e) => setTmp(e.target.value)}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={handleKeyDown}
            />
        </TableCell>
    );
};

// EditCellBase.propTypes = {
//   column: PropTypes.object,
//   row: PropTypes.any,
//   tableRow: PropTypes.object,
//   tableColumn: PropTypes.object,
//   value: PropTypes.any,
//   onValueChange: PropTypes.func,
//   style: PropTypes.object,
//   classes: PropTypes.object.isRequired,
//   editingEnabled: PropTypes.bool,
//   children: PropTypes.node,
//   className: PropTypes.string,
//   autoFocus: PropTypes.bool,
//   onBlur: PropTypes.func,
//   onFocus: PropTypes.func,
//   onKeyDown: PropTypes.func
// };

// EditCellBase.defaultProps = {
//     column: undefined,
//     row: undefined,
//     tableRow: undefined,
//     tableColumn: undefined,
//     value: "",
//     style: null,
//     children: undefined,
//     className: undefined,
//     editingEnabled: true,
//     autoFocus: false,
//     onValueChange: () => { },
//     onBlur: () => { },
//     onFocus: () => { },
//     onKeyDown: () => { }
// };

// export const EditCell = withStyles(styles, { name: "EditCell" })(EditCellBase);
