/// This following IS the version that should be used, but the grid 
/// of dev express (ungrouping feature) doesn't like for some reason
/// so we keep on using the deprecated way to bootstrap the react app
// // import * as React from "react";
// // import DateFnsUtils from '@date-io/date-fns';
// // import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// // import { Provider } from "react-redux";
// // import App from "./layout/App";
// // import epics from './features/AllEpics';
// // import * as reducers from './features';
// // import reportWebVitals from './reportWebVitals';
// // import { combineReducers, configureStore } from "@reduxjs/toolkit";
// // import registerServiceWorker from './registerServiceWorker';
// // import "core-js";
// // import { combineEpics, createEpicMiddleware } from 'redux-observable';
// // import { enableMapSet } from "immer"
// // import { createRoot } from 'react-dom/client';
// // // Maps are used in navigation slice, for example.
// // // https://immerjs.github.io/immer/installation#pick-your-immer-version
// // enableMapSet()


// // const initialState: reducers.IState = {} as reducers.IState;

// // const appReducer = combineReducers({
// //     ...reducers.reducer,
// // });

// // const rootReducer: typeof appReducer = (state, action: reducers.IAnyAction) => {
// //     if (action.type === "switchTenant") {
// //         // return appReducer(emptyState, { type: "applicationLoad", payload: action.payload });
// //         return appReducer(initialState, { type: "dummy" });
// //     } else {
// //         return appReducer(state, action);
// //     }
// // }

// // const epicMiddleware = createEpicMiddleware();
// // const store = configureStore<reducers.IState, reducers.IAnyAction>({
// //     reducer: rootReducer,
// //     preloadedState: initialState,
// //     devTools: process.env.NODE_ENV !== 'production',
// //     middleware: [epicMiddleware]// (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(epicMiddleware)
// // });

// // epicMiddleware.run(combineEpics(...epics));
// // const container = document.getElementById("root") as HTMLElement;
// // const RootApp = <React.StrictMode>
// //     <Provider store={store}>
// //         <MuiPickersUtilsProvider utils={DateFnsUtils}>
// //             <App />
// //         </MuiPickersUtilsProvider>
// //     </Provider>
// // </React.StrictMode>


// // const root = createRoot(container)
// // root.render(RootApp);

// // registerServiceWorker();

// // // If you want to start measuring performance in your app, pass a function
// // // to log results (for example: reportWebVitals(console.log))
// // // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// // reportWebVitals();

import * as React from "react";
import * as ReactDOM from "react-dom";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Provider} from "react-redux";
import App from "./layout/App";
import epics from './features/AllEpics';
import * as reducers from './features';
import reportWebVitals from './reportWebVitals';
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import registerServiceWorker from './registerServiceWorker';
import "core-js";
import {combineEpics, createEpicMiddleware} from 'redux-observable';
import {enableMapSet} from "immer"

// Maps are used in navigation slice, for example.
// https://immerjs.github.io/immer/installation#pick-your-immer-version
enableMapSet()


const initialState = {};

const appReducer = combineReducers({
    ...reducers.reducer,
});


const rootReducer: typeof appReducer = (state, action: reducers.IAnyAction) => {
    if (action.type === "switchTenant") {
        // return appReducer(emptyState, { type: "applicationLoad", payload: action.payload });
        return appReducer(undefined, {type: "dummy"});
    } else {
        return appReducer(state, action);
    }
}

const epicMiddleware = createEpicMiddleware();
const store = configureStore<reducers.IState, reducers.IAnyAction>({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [epicMiddleware]
});

epicMiddleware.run(combineEpics(...epics));

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <App/>
            </MuiPickersUtilsProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root") as HTMLElement
);

registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

