import {
    ICurrencyModel,
    PortfolioSummaryModel,
} from "proxy/apiProxy";
import * as React from "react";
import { formatDecimal, IDictionary, oProps } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, TypographyTypeMap } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { IComposition } from "./slice";

const ContainerBox = React.memo(withStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2)
        }
    })
)(Box));

const LightTypography = React.memo(withStyles((theme) =>
    createStyles({
        root: {
            color: theme.palette.text.secondary
        }
    })
)(Typography)) as OverridableComponent<TypographyTypeMap>;

export interface ITradeDateCashFlowDataProps {
    portfolios: PortfolioSummaryModel[];
    referenceCurrencies: IDictionary<ICurrencyModel>;
    compositions: { [portfolioId: number]: IComposition };
    cashFlowsField: string;
}
function TradeDateCashFlowData({ referenceCurrencies, portfolios, compositions, cashFlowsField }: ITradeDateCashFlowDataProps) {
    const [filter, setFilter] = React.useState<string>("");
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value);
    return <ContainerBox>
        <Table stickyHeader={true} size="small">
            <TableHead>
                <TableRow>
                    <TableCell><TextField label="Portfolio" value={filter} onChange={handleChange} /></TableCell>
                    <TableCell align="right">Aum</TableCell>
                    <TableCell align="right">Cash</TableCell>
                    <TableCell>Net Cash Flow</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {portfolios
                    .map((portfolio, idx) => ({ portfolio: portfolio, idx }))
                    .filter(({ portfolio: { name, internalCode } }) => filter === "" || name.toUpperCase().includes(filter.toUpperCase()) || internalCode.toUpperCase().includes(filter.toUpperCase()))
                    .map(({ portfolio: { id, name, currencyId, internalCode }, idx }) => {
                        const currency = referenceCurrencies[currencyId ?? 0]?.isoCode;
                        return <TableRow key={id}>
                            <TableCell>
                                <Typography component="span">{name}</Typography>&nbsp;<LightTypography variant="overline" component="span">{internalCode}</LightTypography>
                            </TableCell>
                            <TableCell align="right">{formatDecimal(compositions[id]?.aum)} {currency}</TableCell>
                            <TableCell align="right">{formatDecimal(compositions[id]?.cash)} {currency}</TableCell>
                            <TableCell>
                                <FormTextField name={oProps<Record<string | number, number>>(cashFlowsField).path(id)} isNumber={true} adornment={currency} />
                            </TableCell>
                        </TableRow>;
                    })}
            </TableBody>
        </Table>
    </ContainerBox>;
}

export default React.memo(TradeDateCashFlowData);
