import { ISubMenuTabs } from "tools/components/DetailPanel";
import { DevelopmentItemModel } from "proxy/apiProxy";
import { developmentItemScreen } from "./DevelopmentItem";

export const developmentItemMenus: Record<DevelopmentItemModel["type"], ISubMenuTabs<typeof developmentItemScreen>[]> = {
    "EtlMacroDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }, {
            value: "macro",
            label: "Macro"
        }, {
            value: "visualization",
            label: "Visualization"
        }
    ], "QuestionnaireDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }, {
            value: "loadMacro",
            label: "Load Macro"
        }, {
            value: "questionnaire",
            label: "Questionnaire"
        }, {
            value: "completeMacro",
            label: "Complete Macro"
        }
        // ], "FileConnectorDevelopmentItemModel": [
        //     {
        //         value: "detail",
        //         label: "Properties"
        //     }
        // ], "FileConsumerMacroDevelopmentItemModel": [
        //     {
        //         value: "detail",
        //         label: "Properties"
        //     }, {
        //         value: "macro",
        //         label: "Macro"
        //     }, {
        //         value: "visualization",
        //         label: "Visualization"
        //     }
        // ], "FileProducerMacroDevelopmentItemModel": [
        //     {
        //         value: "detail",
        //         label: "Properties"
        //     }, {
        //         value: "macro",
        //         label: "Macro"
        //     }, {
        //         value: "visualization",
        //         label: "Visualization"
        //     }
    ], "DashboardLayoutDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }, {
            value: "layout",
            label: "Layout"
        }
    ], "DatasetMacroDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }, {
            value: "macro",
            label: "Macro"
        }, {
            value: "structure",
            label: "Structure"
        }
    ], "ReportTemplateDevelopmentItemModel": [
        {
            value: "detail",
            label: "Properties"
        }, {
            value: "template",
            label: "Template"
        }
    ]
};
