import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFeatureModel, IMacroScriptCategoryModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useCallback, useEffect } from "react";

export const macroScriptCategoriesScreen = {
    route: "/config/macros/categories" as const,
    component: MacroScriptCategories,
    label: "Macro Script Categories"
}

function MacroScriptCategories() {

    const { macroScriptCategories = [], macroScriptCategoryAllLoading } = useReduxSelections("macroScriptCategory");
    const { macroScriptCategoryLoadAll } = useReduxActions("macroScriptCategory");
    const navigate = useScreenNavigate();

    useEffect(() => {
        macroScriptCategoryLoadAll()
    }, [macroScriptCategoryLoadAll]);

    const columns: IColumnDefinition[] = [
        { name: "Name", title: "Name", getCellValue: ({ name }: IMacroScriptCategoryModel) => name, filteringEnabled: true }
    ];
    const state: IGridState = {
        "Name": { width: 280 }
    };

    const getRowKey = useCallback((row: IMacroScriptCategoryModel) => row.id || 0, [])
    const handleRowClick = useCallback((row: IMacroScriptCategoryModel) => navigate("MacroScriptCategory", { id: row.id }), [navigate])

    const handleAddNewClick = useCallback(() => navigate("MacroScriptCategory", { id: 0 }), [navigate])

    return (<SearchPanel title="Macro Script Categories"
        onAddClick={handleAddNewClick}
        addAllowed={IFeatureModel.MacroCategoryWrite}
        isQuerying={macroScriptCategoryAllLoading}>
        <ExtendedGrid
            rows={macroScriptCategories}
            columns={columns}
            getRowId={getRowKey}
            onRowClick={handleRowClick}
            initialState={state} />
    </SearchPanel>);
}
