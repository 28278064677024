import * as React from "react";
import { SecuritySummaryModel, ISecurityTypeModel, ISubFundSummaryModel } from "proxy/apiProxy";
import AsyncSelect from "tools/components/AsyncSelect";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { IGetSecuritySummary } from "features/Reference/slice";
import SearchIcon from '@material-ui/icons/Search';

export interface ISecuritySearchProps {
    label?: React.ReactNode;
    type?: ISecurityTypeModel;
    onSelected?: (found: IGetSecuritySummary) => void;
    onlyUnderManagement?: boolean;
    filter?: (s: SecuritySummaryModel) => boolean;
}
export default function SecuritySearch({ label, onSelected, type, onlyUnderManagement, filter }: ISecuritySearchProps) {
    const { referenceSecuritySearch } = useReduxActions("reference");
    const { referenceSecuritiesSearched, referenceSecuritySearching } = useReduxSelections("reference");
    const getOptionLabel = (role: SecuritySummaryModel) => role.name;
    label = <><SearchIcon fontSize="small"/> {label ?? type}: Type criteria to find a security</>;
    const handleValueSelected = (security: SecuritySummaryModel) => {
        if (onSelected) {
            const subFunds: Record<string | number, ISubFundSummaryModel> = {};
            if (security.type === "ShareClassSummaryModel" && security.subFundId) {
                const subfund = referenceSecuritiesSearched.subFunds[security.subFundId];
                subFunds[security.subFundId] = subfund;
            }
            onSelected({ security, entities: {}, subFunds });
        }
    }
    const handleOnSearchOptions = (criterias: string) => referenceSecuritySearch({ criterias, type, onlyUnderManagement });
    const securities = filter ? referenceSecuritiesSearched.securities.filter(filter) : referenceSecuritiesSearched.securities;
    return <AsyncSelect onSearchOptions={handleOnSearchOptions} fullWidth={true} options={securities}
        getOptionLabel={getOptionLabel} searching={referenceSecuritySearching} label={label} onRequiredValueSelected={handleValueSelected} />;
}
