import { Formik, FormikHelpers, FormikProps } from "formik";
import { IFeatureModel, IParametersModel } from "proxy/apiProxy";
import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import ParametersData from "./ParametersData";
import ParametersMarketDataProviders from "./ParametersMarketDataProviders";
import MacroScriptVisualEtl from "features/MacroScript/MacroScriptVisualEtl";
import NotGranted from 'components/global/NotGranted';
import ExtensionFields from "./ExtensionFields";
import JsonScriptEditor from "./JsonScriptEditor";
import { oProps } from "tools/lib/utility";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { IMenuEditorProps, IMenuEditorRef, MenuEditor } from "./menuConfig/MenuEditor";

export const parametersScreen = {
    route: "/config/general/:tab?" as const,
    component: Parameters,
    label: "Parameters",
    keywords: "global setting market data menu",
    tabs: {
        main: "main",
        menu: "menu",
        portfolioExtendedFields: "portfolioExtendedFields",
        securityExtendedFields: "securityExtendedFields",
        shareClassExtendedFields: "shareClassExtendedFields",
        subFundExtendedFields: "subFundExtendedFields",
        personExtendedFields: "personExtendedFields",
        companyExtendedFields: "companyExtendedFields",
        sicavExtendedFields: "sicavExtendedFields",
        portfolioTransactionExtendedFields: "portfolioTransactionExtendedFields",
        dataproviders: "dataproviders",
        dataprovidersprocess: "dataprovidersprocess",
        theme: "theme",
    },
}

function Parameters() {
    const {
        parameters,
        parametersSaving,
        parametersLoading,
        parametersMarketDataProviders,
        marketDataImportProcess,
        dictionaries
    } = useReduxSelections("parameters");
    const {
        parametersSave,
        parametersAddBatchToDictionary,
        parametersLoad,
        parametersMarketDataImportProcessLoad
    } = useReduxActions("parameters");
    // const {newMenuShouldBeLoaded} = useReduxActions("app");
    const { classificationTypes: securityClassificationTypes } = useReduxSelections("securityClassificationType");

    useEffect(() => {
        parametersLoad()
        parametersMarketDataImportProcessLoad()
    }, [parametersLoad, parametersMarketDataImportProcessLoad]);

    const { tab: tabValue = "main" } = useScreenParams<typeof parametersScreen>();

    const menuRef = useRef<IMenuEditorRef>(null);
    const [dirtyMenuTree, setDirtyMenuTree] = useState(false)
    const handleMenuChange = useCallback<IMenuEditorProps["onChange"]>((dirty) => {
        setDirtyMenuTree(dirty)
    }, []);

    const handleSubmit = useCallback((values: IParametersModel, { setSubmitting }: FormikHelpers<IParametersModel>) => {
        let parametersValues = values
        const newMenuConfig = menuRef.current?.getValue()
        parametersSave({ ...parametersValues, menuConfig: newMenuConfig })
        setSubmitting(false)

        // if (dirtyMenuTree) {
        //     newMenuShouldBeLoaded()
        // }
        setDirtyMenuTree(false)
    }, [parametersSave])

    const isGranted = useGrants();
    const title = "Global Parameters";
    if (!isGranted(IFeatureModel.GeneralSettingsWrite)) {
        return <DetailPanel title={title}>
            <NotGranted />
        </DetailPanel>
    }

    return <Formik onSubmit={handleSubmit} initialValues={parameters} enableReinitialize={true}
        validateOnMount={true}>{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm }: FormikProps<IParametersModel>) {
        const tabs: ISubMenuTabs<typeof parametersScreen>[] = [{
            label: "General",
            value: "main"
        }, {
            label: "Menu",
            value: "menu"
        }, {
            label: "Portfolio Ext. Fields",
            value: "portfolioExtendedFields"
        }, {
            label: "Security Ext. Fields",
            value: "securityExtendedFields"
        }, {
            label: "Targ. SubFund Ext. Fields",
            value: "subFundExtendedFields"
        }, {
            label: "Share Class Ext. Fields",
            value: "shareClassExtendedFields"
        }, {
            label: "Person Ext. Fields",
            value: "personExtendedFields"
        }, {
            label: "Company Ext. Fields",
            value: "companyExtendedFields"
        }, {
            label: "Sicav Ext. Fields",
            value: "sicavExtendedFields"
        }, {
            label: "Port. Tran. Ext. Fields",
            value: "portfolioTransactionExtendedFields"
        }, {
            label: "Data Providers",
            value: "dataproviders"
        }, {
            label: "Theme",
            value: "theme"
        }];
        if (marketDataImportProcess) {
            tabs.push({
                label: "Data Providers Process",
                value: "dataprovidersprocess"
            });
        }
        return <DetailPanel
            isQuerying={parametersSaving || parametersLoading}
            tabs={tabs}
            title={title}
            canSave={(dirty || dirtyMenuTree) && isValid}
            onSaveClick={submitForm}
            tabValue={tabValue}
            noPadding={tabValue === "theme" || tabValue === "menu"}>
            {(tabValue === "main") && <ParametersData
                onBatchLoaded={parametersAddBatchToDictionary}
                dictionaries={dictionaries}
                securityClassificationTypes={securityClassificationTypes} />}
            {(tabValue === "menu") &&
                <MenuEditor menuConfig={parameters.menuConfig} onChange={handleMenuChange} ref={menuRef} />}
            {(tabValue === "portfolioExtendedFields") && <ExtensionFields
                extensionFieldsField="portfolioExtensionFields" />}
            {(tabValue === "securityExtendedFields") && <ExtensionFields
                extensionFieldsField="securityExtensionFields" />}
            {(tabValue === "shareClassExtendedFields") && <ExtensionFields
                extensionFieldsField="shareClassExtensionFields" />}
            {(tabValue === "subFundExtendedFields") && <ExtensionFields
                extensionFieldsField="subFundExtensionFields" />}
            {(tabValue === "personExtendedFields") && <ExtensionFields
                extensionFieldsField="personExtensionFields" />}
            {(tabValue === "companyExtendedFields") && <ExtensionFields
                extensionFieldsField="companyExtensionFields" />}
            {(tabValue === "sicavExtendedFields") && <ExtensionFields
                extensionFieldsField="sicavExtensionFields" />}
            {(tabValue === "portfolioTransactionExtendedFields") && <ExtensionFields
                extensionFieldsField="portfolioTransactionExtensionFields" />}
            {(tabValue === "dataproviders") && <ParametersMarketDataProviders
                parametersMarketDataProviders={parametersMarketDataProviders} />}
            {(tabValue === "dataprovidersprocess" && !!marketDataImportProcess) &&
                <MacroScriptVisualEtl definitionStructure={marketDataImportProcess} />}
            {(tabValue === "theme") &&
                <JsonScriptEditor fieldName={oProps<IParametersModel>().path("themeConfig")} language="json" />}
        </DetailPanel>
    }
}