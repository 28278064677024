import { alpha, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useCallback } from "react";

export interface IResizerProps {
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void,
  onDoubleClick?: (event: MouseEvent) => void,
  onMouseDown: (event: MouseEvent) => void,
  onTouchStart: (event: TouchEvent) => void,
  onTouchEnd: (event: TouchEvent) => void,
  split?: 'vertical' | 'horizontal'
}

const useStyle = makeStyles(theme => ({
  pane: {
    background: theme.palette.primary.dark,
    opacity: 0.7,
    zIndex: 1,
    boxSizing: "border-box",
    backgroundClip: "padding-box",
    "&:hover": {
      transition: "all 0.5s ease",
    },
  },
  verticalPane: {
    width: 7,
    margin: "0 -3px",
    borderLeftColor: alpha(theme.palette.primary.dark, 0),
    borderRightColor: alpha(theme.palette.primary.dark, 0),
    borderLeft: "3px solid",
    borderRight: "3px solid",
    cursor: "col-resize",
    "&:hover": {
      borderLeft: "3px solid",
      borderLeftColor: alpha(theme.palette.primary.dark, 0.7),
      borderRight: "3px solid",
      borderRightColor: alpha(theme.palette.primary.dark, 0.7),
    }
  },
  horizontalPane: {
    height: 7,
    margin: "-3px 0",
    borderTopColor: alpha(theme.palette.primary.dark, 0),
    borderBottomColor: alpha(theme.palette.primary.dark, 0),
    borderTop: "3px solid",
    borderBottom: "3px solid",
    cursor: "row-resize",
    width: "100%",
    "&:hover": {
      borderTop: "3px solid",
      borderTopColor: alpha(theme.palette.primary.dark, 0.7),
      borderBottom: "3px solid",
      borderBottomColor: alpha(theme.palette.primary.dark, 0.7),
    }
  }
}))

export function Resizer({
  onClick, onDoubleClick, onMouseDown, onTouchStart, onTouchEnd, split
}: IResizerProps) {
  const classes = useStyle();
  const handleTouchStart = useCallback((event: React.TouchEvent<HTMLSpanElement>) => {
    event.preventDefault();
    onTouchStart(event.nativeEvent);
  }, [onTouchStart]);
  const handleTouchEnd = useCallback((event: React.TouchEvent<HTMLSpanElement>) => {
    event.preventDefault();
    onTouchEnd(event.nativeEvent);
  }, [onTouchEnd]);
  const handleClick = useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    onClick!(event);
  }, [onClick]);
  const handleMouseDown = useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    onMouseDown!(event.nativeEvent);
  }, [onMouseDown]);
  const handleDoubleClick = useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    onDoubleClick!(event.nativeEvent);
  }, [onDoubleClick]);
  return <span
    role="presentation"
    className={clsx(classes.pane, { [classes.horizontalPane]: split === 'horizontal', [classes.verticalPane]: split === "vertical" })}
    onMouseDown={handleMouseDown}
    onTouchStart={handleTouchStart}
    onTouchEnd={handleTouchEnd}
    onClick={onClick ? handleClick : undefined}
    onDoubleClick={onClick ? handleDoubleClick : undefined} />
}