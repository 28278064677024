import React from "react";
import { useGrants } from "tools/lib/reduxStoreAccess";
import { GrantRequest } from "tools/lib/utility";
import { ReadOnlyContext } from "./ReadOnlyContext";

export interface IReadWriteProps {
    value?: GrantRequest | GrantRequest[] | Record<string, GrantRequest>;
}
export default function WriteAccess({ children, value = [] }: React.PropsWithChildren<IReadWriteProps>) {
    const isGranted = useGrants();
    // if (typeof value === "boolean") {
    //     return <ReadOnlyContext.Provider value={!value}>
    //         {children}
    //     </ReadOnlyContext.Provider>
    // }
    return <ReadOnlyContext.Provider value={!isGranted(value)}>
        {children}
    </ReadOnlyContext.Provider>
}
