import SearchPanel from "tools/components/SearchPanel";
import { IDocumentDefinitionSummaryModel, IFeatureModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getClassificationTypesColumnDefinitions } from "components/global/ClassificationsData";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";

export const documentDefinitionsScreen = {
    route: "/documentDefinitions",
    component: DocumentDefinitions,
    label: "Document Definitions",
    keywords: "document",
}

function DocumentDefinitions() {
    const {
        documentDefinitions,
        documentDefinitionAllLoading
    } = useReduxSelections("documentDefinition");
    const { documentDefinitionLoadAll } = useReduxActions("documentDefinition");
    const {
        classificationTypes,
        classificationTypesFlat
    } = useReduxSelections("noteClassificationType");

    const navigate = useScreenNavigate();
    useEffect(() => {
        documentDefinitionLoadAll({})
    }, [documentDefinitionLoadAll]);


    const { classificationTypesColumns, classificationTypesState } = getClassificationTypesColumnDefinitions(classificationTypes, classificationTypesFlat, ({ classifications }: IDocumentDefinitionSummaryModel) => classifications);

    const columns: IColumnDefinition[] = [
        { name: "Name", title: "Name", getCellValue: (row: IDocumentDefinitionSummaryModel) => row.name, positionToKeep: "left", filteringEnabled: true },
        { name: "Code", title: "Code", getCellValue: (row: IDocumentDefinitionSummaryModel) => row.code, filteringEnabled: true },
        { name: "Scope", title: "Scope", getCellValue: ({ singleScope }: IDocumentDefinitionSummaryModel) => singleScope, },
        { name: "Version", title: "Version", getCellValue: (row: IDocumentDefinitionSummaryModel) => row.publishedVersion, },
        {
            name: "PublishOnPortal",
            title: "Publish on portal",
            getCellValue: (row: IDocumentDefinitionSummaryModel) => row.publishOnPortal,
            columnType: "boolean",
            positionToKeep: "left"
        },
        { name: "LastUpdate", title: "Last update", getCellValue: (row: IDocumentDefinitionSummaryModel) => row.updatedOn, columnType: "dateTime" },
        ...classificationTypesColumns
    ];

    const state: IGridState = {
        "Code": { width: 150 },
        "Label": { width: 200 },
        "Scope": { width: 180 },
        "Version": { width: 120 },
        "LastUpdate": { width: 220 },
        ...classificationTypesState
    };


    const handleAddNewClick = () => navigate("DocumentDefinition", { id: 0 });
    const handleRowSelect = (row: IDocumentDefinitionSummaryModel) => navigate("DocumentDefinition", { id: row.id });

    const getRowKey = (row: IDocumentDefinitionSummaryModel) => row.id || 0;
    return <SearchPanel
        isQuerying={documentDefinitionAllLoading}
        title="Document definitions"
        onAddClick={handleAddNewClick}
        addAllowed={IFeatureModel.MarketDataWrite}>
        <ExtendedGrid
            rows={documentDefinitions}
            columns={columns}
            getRowId={getRowKey}
            initialState={state}
            onRowClick={handleRowSelect}
            defaultExportFileName="DocumentDefinitions.xlsx"
            userCanGroup={true} />
    </SearchPanel>
}
