import { ILoanModel, EntitySummaryModel, IIndexSummaryModel, IExtensionFieldModel } from "proxy/apiProxy";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import { oProps } from "tools/lib/utility";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";
import EntitySummary from "components/summaries/EntitySummary";
import EntitySelectField from "components/searchers/EntitySelectField";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import { Box, Grid } from "@material-ui/core";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import { IGetEntitySummary } from "features/Reference/slice";
import { couponTypes } from "./Loan";
import { IndexSelectField } from "components/searchers/IndexSelectField";
import { IndexSummary } from "components/summaries/IndexSummary";
import ExtensionFields from "features/ManagedPortfolio/ExtensionFields";

export interface ILoanDataProps {
    currentValues: ILoanModel;
    loanAddEntityInDictionary: (payload: IGetEntitySummary) => void;
    loanAddIndexInDictionary: (payload: IIndexSummaryModel) => void,
    entities: Record<string | number, EntitySummaryModel>;
    indexes: Record<string | number, IIndexSummaryModel>;
    securityExtensionFields: IExtensionFieldModel[];
}
export default function LoanData({ currentValues, loanAddEntityInDictionary, loanAddIndexInDictionary, entities, indexes, securityExtensionFields }: ILoanDataProps) {
    const { referenceCountries = {}, referenceCurrencies = {} } = useReduxSelections("reference");
    return <Grid container={true} spacing={1}>
        <Grid item={true} md={3}>
            <FormTextField name={oProps<ILoanModel>().path("internalCode")} label="Internal Code" required={true} />
        </Grid>
        <Grid item={true} md={5}>
            <FormTextField name={oProps<ILoanModel>().path("name")} label="Name" required={true} />
        </Grid>
        <Grid item={true} md={4}>
            <FormTextField name={oProps<ILoanModel>().path("shortName")} label="Short Name" />
        </Grid>


        <Grid item={true} md={4}>
            <CurrencySelectField name={oProps<ILoanModel>().path("currencyId")} label="Currency" />
        </Grid>
        <Grid item={true} md={4}>
            <FormTextField name={oProps<ILoanModel>().path("nominal")} label="Nominal" isNumber={true} adornment={referenceCurrencies[currentValues.currencyId ?? 0]?.isoCode} />
        </Grid>
        <Grid item={true} md={4}>
            <FormTextField name={oProps<ILoanModel>().path("interestRate")} label="Interest Rate" isPercentage={true} />
        </Grid>


        <Grid item={true} md={4}>
            <FormSimpleSelectField name={oProps<ILoanModel>().path("interestRateCouponType")} label="Interest Rate Coupon Type" options={couponTypes} />
        </Grid>
        <Grid item={true} md={4}>
            <FormDatePickerField name={oProps<ILoanModel>().path("maturityDate")} label="Maturity Date" />
        </Grid>
        <Grid item={true} md={4}>
            <FormDatePickerField name={oProps<ILoanModel>().path("date")} label="Date" />
        </Grid>
        <ExtensionFields extensionFields={securityExtensionFields} pathToExtensionFields={oProps<ILoanModel>().path("securityExtensionFieldsValues")} />


        <Grid item={true} md={6}>
            <Box display="flex" flexDirection="column">
                <IndexSelectField name={oProps<ILoanModel>().path("interestRateReferenceIndexId")} onSelected={loanAddIndexInDictionary} />
                <IndexSummary name={oProps<ILoanModel>().path("interestRateReferenceIndexId")} label="Interest Rate Reference Index" countries={referenceCountries} indexes={indexes} />
            </Box>
        </Grid>


        <Grid container={true} item={true} md={12} spacing={1}>
            <Grid item={true} md={6}>
                <Box display="flex" flexDirection="column">
                    <EntitySelectField name={oProps<ILoanModel>().path("borrowerId")} onSelected={loanAddEntityInDictionary} required={true} />
                    <EntitySummary name={oProps<ILoanModel>().path("borrowerId")} label="Borrower" countries={referenceCountries} entities={entities} />
                </Box>
            </Grid>
            <Grid item={true} md={6}>
                <Box display="flex" flexDirection="column">
                    <EntitySelectField name={oProps<ILoanModel>().path("lenderId")} onSelected={loanAddEntityInDictionary} required={true} />
                    <EntitySummary name={oProps<ILoanModel>().path("lenderId")} label="Lender" countries={referenceCountries} entities={entities} />
                </Box>
            </Grid>
        </Grid>
    </Grid>;
}
