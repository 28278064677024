import produce from "immer";
import { IReportBuildSetRequestModel, IReportTemplateAnalysisModel, IReportTemplateSummaryModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
export interface IState {
    metadataLoading: boolean;
    metadata?: IReportTemplateAnalysisModel;
    reportBuildSetLoading: boolean;
    reportTemplates?: IReportTemplateSummaryModel[];
    analysis?: IReportTemplateAnalysisModel & { reportTemplateId: number };
}
export const ActionFactories = produceActionFactories({
    externalTemplateMetadataLoad: () => undefined,
    externalTemplateMetadataLoaded: (payload: IReportTemplateAnalysisModel) => payload,
    externalTemplateBuildSetLoad: (payload: IReportBuildSetRequestModel) => payload,
    externalTemplateLoadedAll: (payload: IReportTemplateSummaryModel[]) => payload,
    externalTemplateBuildSetLoaded: () => undefined,
    externalTemplateAnalyze: (payload: number | undefined) => payload,
    externalTemplateAnalyzed: (payload: (IReportTemplateAnalysisModel & { reportTemplateId: number }) | undefined) => payload
});

export function reducer(
    state: IState = {
        metadataLoading: false,
        reportBuildSetLoading: false,
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "externalTemplateMetadataLoad":
                draft.metadataLoading = true;
                break;
            case "externalTemplateMetadataLoaded":
                draft.metadata = action.payload;
                draft.metadataLoading = false;
                break;
            case "externalTemplateBuildSetLoad":
                draft.reportBuildSetLoading = true;
                break;
            case "externalTemplateBuildSetLoaded":
                draft.reportBuildSetLoading = false;
                break;
            case "externalTemplateLoadedAll":
                draft.reportTemplates = action.payload;
                break;
            case "externalTemplateAnalyze":
                delete draft.analysis;
                break;
            case "externalTemplateAnalyzed":
                draft.analysis = action.payload;
                break;
        }
    });
}
