import * as React from "react";
import { Box, Paper } from "@material-ui/core";
import 'react-sortable-tree/style.css';
import SortableTree, {
    isDescendant, TreeItem, NodeData, FullTree, OnMovePreviousAndNextLocation,
    ExtendedNodeData, OnVisibilityToggleData, NodeRendererProps
    , TreeNode, TreeIndex, NodeRenderer, OnDragPreviousAndNextLocation
} from 'react-sortable-tree';
import { IconButton, Tooltip } from "@material-ui/core";
import classnames from "classnames";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import styled from '@emotion/styled';

export interface INodeAction<TRow> {
    onClick: (row: TRow, parentRow?: TRow) => void;
    icon: React.ReactElement<any>;
    label?: string;
    isVisible?: (row: TRow) => boolean;
}
export interface IDefaultNodeDefinition<TRow> {
    actions?: INodeAction<TRow>[];
    getRowName?: (row: TRow) => React.ReactNode;
    getRowContent?: (row: TRow) => React.ReactNode | undefined;
}
export interface IRowMovedEventArgs<TRow> {
    row: TRow,
    parentRow: TRow | undefined,
    previousParentRow: TRow | undefined,
    targetPosition: number,
    fromOutside: boolean
}

export type OnRowMovedEventHandler<TRow> = (args: IRowMovedEventArgs<TRow>) => void;

export interface IMuiSortableTreeWithChildrenProps<TRow> {
    onRowMoved?: OnRowMovedEventHandler<TRow>;
    keyForUpdate?: any;
    allExpanded?: boolean;
    getKey?: (row: TRow) => number | string;
    canDrag?: ((row: TRow) => boolean) | boolean;
    canDrop?: ((row: TRow, targetRow: TRow | undefined) => boolean) | boolean;
    getChildren: (row?: TRow) => TRow[] | undefined;
    shouldCopyOnOutsideDrop?: boolean;
    dndType?: string;
    readOnly?: boolean;
    nodeDefinition: NodeRenderer | IDefaultNodeDefinition<TRow>
}

export interface ITreeItem<TRow> extends TreeItem {
    id: number;
    row: TRow;
    parentNode?: ITreeItem<TRow>;
    fromOutside?: boolean;
}
function getRowIndex<TRow>(treeItems: ITreeItem<TRow>[], nodeId: number): number {
    let index = -1;
    seekIndex(treeItems);
    return index;
    function seekIndex(items: ITreeItem<TRow>[]): boolean {
        for (const treeItem of items) {
            index++;
            if (treeItem.id === nodeId) {
                return true;
            }
            if (treeItem.expanded) {
                if (seekIndex(treeItem.children as ITreeItem<TRow>[])) {
                    return true;
                }
            }
        }
        return false;
    }
}
export default function MuiSortableTreeWithChildren<TRow>({
    onRowMoved,
    nodeDefinition,
    keyForUpdate,
    allExpanded,
    canDrag,
    canDrop,
    getChildren,
    shouldCopyOnOutsideDrop,
    dndType,
    readOnly = false
}: IMuiSortableTreeWithChildrenProps<TRow>) {
    const { actions, getRowName, getRowContent, nodeContentRenderer } = React.useMemo(() => {
        if (typeof nodeDefinition === "object") {
            return { ...nodeDefinition, nodeContentRenderer: undefined };
        } else {
            return { nodeContentRenderer: nodeDefinition, actions: undefined, getRowName: undefined, getRowContent: undefined };
        }
    }, [nodeDefinition]);
    const createTreeData = React.useCallback((expandedRows: TRow[]) => {
        let id = 0;
        function mapRowToNode(row: TRow, parentNode?: ITreeItem<TRow>): ITreeItem<TRow> {
            const childrenRows = getChildren(row);
            const node: ITreeItem<TRow> = {
                title: getRowName ? getRowName(row) : "",
                subtitle: getRowContent ? getRowContent(row) : undefined,
                expanded: allExpanded || expandedRows.includes(row),
                row,
                id: id++,
                parentNode
            };
            node.children = childrenRows ? childrenRows.map(c => mapRowToNode(c, node)) : undefined;
            return node;
        }
        return (getChildren() || []).map(c => mapRowToNode(c, undefined));
    }, [allExpanded, getChildren, getRowContent, getRowName])
    const handleCanDrag = (data: ExtendedNodeData) => {
        if (readOnly) {
            return false;
        }
        else if (typeof canDrag === "boolean") {
            return canDrag;
        }
        else if (canDrag) {
            const isDraggable = canDrag as ((row: TRow) => boolean);
            return isDraggable((data.node as ITreeItem<TRow>).row);
        }
        return true;
    }
    const handleCanDrop = (data: OnDragPreviousAndNextLocation & NodeData) => {
        if (readOnly) {
            return false;
        }
        else if (typeof canDrop === "boolean") {
            return canDrop;
        }
        else if (canDrop) {
            const isDroppable = canDrop as ((row: TRow, targetRow: TRow | undefined) => boolean);
            return isDroppable((data.node as ITreeItem<TRow>).row, (data.nextParent as (ITreeItem<TRow> | undefined))?.row);
        }
        return true;
    }
    const handleGenerateNodeProps = (evt: ExtendedNodeData) => {
        if (!actions) {
            return {};
        }
        const node = evt.node as ITreeItem<TRow>;
        return {
            buttons: actions.filter(action => !action.isVisible || action.isVisible(node.row as TRow)).map((action, idx) =>
            (<Tooltip key={idx} title={action.label || ""}><IconButton
                size="small"
                aria-label={action.label || ""}
                style={{ verticalAlign: "middle" }}
                onClick={action.onClick.bind(null, node.row as TRow, node.parentNode && node.parentNode.row)} >
                {action.icon}
            </IconButton></Tooltip>))
        };
    }
    const setExpanded = (expandedRows: TRow[], expanded: boolean, ...rows: TRow[]) => {
        for (const row of rows) {
            if (expanded) {
                if (!expandedRows.includes(row as TRow)) {
                    expandedRows.push(row as TRow);
                }
            }
            else {
                const idx = expandedRows.indexOf(row as TRow);
                if (idx >= 0) {
                    expandedRows.splice(idx, 1);
                }
            }
        }
    }
    const expandNodeTillRoot = (treeNode: ITreeItem<TRow>) => {
        const foundRows: TRow[] = [];

        for (let parentNode: ITreeItem<TRow> | undefined = treeNode; parentNode; parentNode = (parentNode.parentNode) as (ITreeItem<TRow> | undefined)) {
            foundRows.push(parentNode.row as TRow);
        }
        setExpanded(expandedRows, true, ...foundRows);
        setExpandedRows([...expandedRows]);
    }
    const handleMoveNode = (args: NodeData & FullTree & OnMovePreviousAndNextLocation) => {
        if (!onRowMoved) {
            return;
        }
        // console.log("=================args IN======================")
        // console.log(args);
        const { node: n, nextParentNode: npn, treeData, nextTreeIndex } = args;
        const node = n as ITreeItem<TRow>
        const row = node.row as TRow;
        const nextParentNode = npn as ITreeItem<TRow> | undefined;
        if (!nextParentNode) {
            const moveArgs: IRowMovedEventArgs<TRow> = { row, parentRow: undefined, previousParentRow: node.parentNode && node.parentNode.row, targetPosition: nextTreeIndex, fromOutside: node.fromOutside ?? false };
            // console.log("=================args OUT======================");
            // console.log(moveArgs);
            onRowMoved(moveArgs);
        }
        else {
            const parentIndex = getRowIndex(treeData as ITreeItem<TRow>[], nextParentNode.id);
            const newRow = nextParentNode.row as TRow;
            expandNodeTillRoot(nextParentNode);
            const moveArgs: IRowMovedEventArgs<TRow> = { row, parentRow: newRow, previousParentRow: node.parentNode && node.parentNode.row, targetPosition: nextTreeIndex - parentIndex - 1, fromOutside: node.fromOutside ?? false };
            // console.log("=================args OUT======================");
            // console.log(moveArgs);
            onRowMoved(moveArgs);
        }
    }
    const handleOnChange = (treeData: TreeItem[]) => setTreeData(treeData as ITreeItem<TRow>[]);
    const handleVisibilityToggle = ({ node, expanded }: OnVisibilityToggleData) => {
        const row = (node as ITreeItem<TRow>).row;
        setExpanded(expandedRows, expanded, row);
        setExpandedRows([...expandedRows]);
    }
    const handleGetNodeKey = (nd: TreeNode & TreeIndex) => (nd.node as ITreeItem<TRow>).id;
    const [expandedRows, setExpandedRows] = React.useState<TRow[]>([]);
    const [treeData, setTreeData] = React.useState(createTreeData(expandedRows));
    //@ts-ignore:disable-next-line
    React.useEffect(() => setTreeData(createTreeData(expandedRows)), [createTreeData, expandedRows, keyForUpdate])
    return <SortableTree
        isVirtualized={false}
        treeData={treeData}
        onChange={handleOnChange}
        getNodeKey={handleGetNodeKey}
        nodeContentRenderer={nodeContentRenderer ?? NoteNodeRenderer}
        onVisibilityToggle={handleVisibilityToggle}
        onMoveNode={handleMoveNode}
        generateNodeProps={handleGenerateNodeProps}
        canDrag={handleCanDrag}
        canDrop={handleCanDrop}
        shouldCopyOnOutsideDrop={shouldCopyOnOutsideDrop}
        dndType={dndType} />;
}

const NodeContainer = styled(Paper)({
    padding: 10,
});

function NoteNodeRenderer(props: NodeRendererProps) {
    const {
        scaffoldBlockPxWidth,
        toggleChildrenVisibility,
        connectDragPreview,
        connectDragSource,
        isDragging,
        canDrop,
        canDrag,
        node,
        title,
        subtitle,
        draggedNode,
        path,
        treeIndex,
        isSearchMatch,
        isSearchFocus,
        buttons = [],
        className,
        style,
        didDrop,
        treeId,
        isOver, // Not needed, but preserved for other renderers
        parentNode, // Needed for dndManager
        rowDirection,
        ...otherProps
    } = props;
    const nodeTitle = title || node.title;
    const nodeSubtitle = subtitle || node.subtitle;
    // const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;
    let handle = null;
    if (canDrag) {
        if (typeof node.children === 'function' && node.expanded) {
            // Show a loading symbol on the handle when the children are expanded
            //  and yet still defined by a function (a callback to fetch the children)
            handle = <div className="rst__loadingHandle">
                <div className="rst__loadingCircle">
                    {[...new Array(12)].map((_, index) => <div key={index} className={classnames('rst__loadingCirclePoint')} />)}
                </div>
            </div>
        } else {
            // Show the handle used to initiate a drag-and-drop
            handle = connectDragSource(<div style={{ cursor: "move", color: "lightgray", zIndex: 99999 }}><DragIndicatorIcon /></div>, { dropEffect: 'copy' });
        }
    }

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    return <div style={{ height: '100%', paddingTop: 10 }} {...otherProps}>
        {toggleChildrenVisibility && node.children && (node.children.length > 0 || typeof node.children === 'function') && (
            <div>
                <button
                    type="button"
                    aria-label={node.expanded ? 'Collapse' : 'Expand'}
                    className={classnames(node.expanded ? 'rst__collapseButton' : 'rst__expandButton')}
                    style={{ left: -0.5 * scaffoldBlockPxWidth }}
                    onClick={() => toggleChildrenVisibility({ node, path, treeIndex, })} />
                {node.expanded && !isDragging && <div style={{ width: scaffoldBlockPxWidth }} className={classnames('rst__lineChildren')} />}
            </div>)}
        <NodeContainer elevation={2}>
            {connectDragPreview(<div
                className={classnames(
                    'rst__row',
                    isLandingPadActive && 'rst__rowLandingPad',
                    isLandingPadActive && !canDrop && 'rst__rowCancelPad',
                    isSearchMatch && 'rst__rowSearchMatch',
                    isSearchFocus && 'rst__rowSearchFocus',
                    className
                )}
                style={{ opacity: isDraggedDescendant ? 0.5 : 1, alignItems: node.subtitle ? "center" : "baseline", gap: 5, ...style, minWidth: 250 }}>
                {handle}
                <Box display="flex" flexDirection="column" width="100%">
                    <span className={classnames('rst__rowTitle', node.subtitle && 'rst__rowTitleWithSubtitle')}>
                        {typeof nodeTitle === 'function' ? nodeTitle({ node, path, treeIndex, }) : nodeTitle}
                    </span>

                    {nodeSubtitle && (
                        <span className="rst__rowSubtitle">
                            {typeof nodeSubtitle === 'function' ? nodeSubtitle({ node, path, treeIndex, }) : nodeSubtitle}
                        </span>
                    )}
                </Box>
                <div className="rst__rowToolbar">
                    {buttons.map((btn, index) => (
                        <div key={index} className="rst__toolbarButton">
                            {btn}
                        </div>
                    ))}
                </div>
            </div>)}
        </NodeContainer>
    </div>
}
